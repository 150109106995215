@if (formFieldSignal(); as formField) {
    @if (formField.IsReadOnly) {
        <input class="h-10" [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0" readonly [value]="formField.Value" [ngClass]="{'hidden' : formField.IsHidden}">
    } @else {
        @if(errorState?.Message; as errorMessage){
            <div class=" flex justify-center items-center absolute top-0 right-12 z-[2] h-full">
                <bizz-tooltip class=" text-red-400 cursor-pointer" [text]="errorMessage"></bizz-tooltip>
            </div>
        }
        <kendo-combobox
                class="h-10"
                [class.error]="errorState != null"
                [data]="formField.EnumList"
                [textField]="'Text'"
                [valueField]="'Value'"
                [valuePrimitive]="true"
                [ngClass]="{'hidden' : formField.IsHidden}"
                [readonly]="readOnlyOverride || formField.IsReadOnly"
                [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0"
                [adaptiveMode]="'auto'"
                [formControl]="formControl">
        </kendo-combobox>
    }
}