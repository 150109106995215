import { Component, Input } from '@angular/core';
import { LoaderComponent } from '../../../../../../shared/components/ui/loader/loader.component';

@Component({
  selector: 'bizz-view-stack-skeleton-element',
  standalone: true,
  imports: [
    LoaderComponent
  ],
  templateUrl: './view-stack-skeleton-element.component.html',
  styleUrl: './view-stack-skeleton-element.component.scss'
})
export class ViewStackSkeletonElementComponent {

  @Input() public bgClass: string = 'bg-widget';
  @Input() public borderClass: string = 'border-form-control';
  @Input() public customHeightClass = 'h-9';
}
