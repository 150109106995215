import { environment } from '../../../environments/environment';

export function validateEnvironment(): void {
  if (Number.isNaN(environment.port)) {
    throw new Error('NG_APP_PORT is not valid');
  }

  if (environment.apiUri == '') {
    throw new Error('NG_APP_API_URI is not set');
  }

  if (environment.idpUri == '') {
    throw new Error('NG_APP_IDENTITY_PROVIDER_URI is not set');
  }

  if (environment.apiAiUri == '' && environment.production) {
    throw new Error('NG_APP_API_AI_URI is not set');
  }

  if (environment.deploymentType == '') {
    throw new Error('NG_APP_DEPLOYMENT_TYPE is not set');
  }

  if (environment.gojsLicenseKey == '') {
    throw new Error('NG_APP_GOJS_LICENSE_KEY is not set');
  }

  if (environment.deploymentType === 'onPremise' && environment.tenant == '') {
    throw new Error('NG_APP_TENANT is not set');
  }

}