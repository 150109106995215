<div class="mt-5 flex gap-2 rounded bg-section p-4 sm:p-5">
    <input class="mr-1 h-6 w-auto lg:h-8" type="radio" id="specificDay" name="monthly" value="specificDay"
           [checked]="state.subTab =='specificDay'" kendoRadioButton (click)="state.subTab = 'specificDay'"/>
    <div class="grid w-full grid-cols-3 gap-2 lg:inline-flex lg:items-center">
        <p class="col-span-1 w-full lg:w-auto lg:whitespace-nowrap">{{ 'OnThe' | translate }}</p>
        <kendo-combobox class="col-span-2 w-full lg:max-w-32" [clearButton]="false" [data]="monthWeeks"
                        [readonly]="readOnly"
                        [value]="cronGenService.monthDayDisplay(state.specificDay.day)" [valuePrimitive]="true"
                        [disabled]="state.subTab !=='specificDay'"
                        (valueChange)="daysChange($event,'specificDay')"></kendo-combobox>
        <p class="col-span-1 w-full lg:w-auto lg:whitespace-nowrap">{{ 'ofEvery' | translate }}</p>
        <kendo-combobox class="col-span-2 w-full lg:max-w-32" [clearButton]="false"
                        [readonly]="readOnly"
                        [data]="this.cronGenService.dropDownOptions.months" [value]="state.specificDay.months"
                        [valuePrimitive]="true" [disabled]="state.subTab !=='specificDay'"
                        (valueChange)="monthsChange($event,'specificDay')"></kendo-combobox>
        <p class="col-span-1 w-full lg:w-auto lg:whitespace-nowrap">{{ 'month_sAt' | translate }}</p>
        <bizz-cron-gen-time-select
                class="col-span-2 w-full lg:max-w-32"
                [readOnly]="readOnly"
                [state]="{datetime: state.specificDay.datetime, hours: state.specificDay.hours, minutes: state.specificDay.minutes, seconds: state.specificDay.seconds, hourType: state.specificDay.hourType}"
                [disabled]="state.subTab !=='specificDay'"
                (stateChange)="cronGenTimeChange($event,'specificDay')"></bizz-cron-gen-time-select>
    </div>

</div>
<div class="mt-5 flex gap-2 rounded bg-section p-4 sm:p-5">
    <input class="mr-1 h-6 w-auto lg:h-8" type="radio" id="specificWeekDay" name="monthly" value="specificWeekDay"
           [checked]="state.subTab =='specificWeekDay'" kendoRadioButton (click)="state.subTab = 'specificWeekDay'"/>
    <div class="grid w-full grid-cols-3 gap-2 lg:inline-flex lg:items-center">
        <p class="col-span-1 w-full lg:w-auto lg:whitespace-nowrap">{{ 'OnThe' | translate }}</p>
        <kendo-combobox class="col-span-1 w-full lg:max-w-32" [clearButton]="false" [data]="firstLast"
                        [value]="cronGenService.monthWeekLookups[state.specificWeekDay.monthWeek]"
                        [readonly]="readOnly"
                        [valuePrimitive]="true"
                        [disabled]="state.subTab !=='specificWeekDay'"
                        (valueChange)="monthWeekChange($event)"></kendo-combobox>
        <kendo-combobox class="col-span-1 w-full lg:max-w-32" [clearButton]="false" [data]="helpers.weekdaysArray"
                        [value]="cronGenService.dayLookups[state.specificWeekDay.day]" [valuePrimitive]="true"
                        [readonly]="readOnly"
                        [disabled]="state.subTab !=='specificWeekDay'"
                        (valueChange)="daysChange($event,'specificWeekDay')"></kendo-combobox>
        <p class="col-span-1 w-full lg:w-auto lg:whitespace-nowrap">{{ 'ofEvery' | translate }}</p>
        <kendo-combobox class="col-span-2 w-full lg:max-w-32" [clearButton]="false"
                        [data]="this.cronGenService.dropDownOptions.months" [value]="state.specificWeekDay.months"
                        [readonly]="readOnly"
                        [valuePrimitive]="true" [disabled]="state.subTab !=='specificWeekDay'"
                        (valueChange)="monthsChange($event,'specificWeekDay')"></kendo-combobox>
        <p class="col-span-1 w-full lg:w-auto lg:whitespace-nowrap">{{ 'month_sAt' | translate }}</p>
        <bizz-cron-gen-time-select
                class="col-span-2 w-full lg:max-w-32"
                [readOnly]="readOnly"
                [state]="{datetime: state.specificWeekDay.datetime, hours: state.specificWeekDay.hours, minutes: state.specificWeekDay.minutes, seconds: state.specificWeekDay.seconds, hourType: state.specificWeekDay.hourType}"
                [disabled]="(state.subTab !=='specificWeekDay')"
                (stateChange)="cronGenTimeChange($event,'specificWeekDay')"></bizz-cron-gen-time-select>
    </div>

</div>