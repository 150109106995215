import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ViewStackService } from 'src/app/shared/services/view-stack/view-stack.service';
import { PermissionsParams } from '../../interfaces/permissions-params.interface';
import { ViewStackLoadedComponent } from 'src/app/shared/interfaces/view-stack-loaded-component';
import { PermissionsComponent } from '../permissions/permissions.component';

@Component({
  selector: 'bizz-view-stack-permissions',
  standalone: true,
  imports: [PermissionsComponent],
  templateUrl: './view-stack-permissions.component.html',
  styleUrl: './view-stack-permissions.component.scss'
})
export class ViewStackPermissionsComponent implements ViewStackLoadedComponent<PermissionsParams> , OnInit, OnDestroy {

  public id: string;
  public data: PermissionsParams;
  private destroy = new Subject<void>();
  
  public constructor(private viewStackService: ViewStackService) {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public hasUnsavedChanges(): boolean {
    return false;
  }

  public removeFromViewStack(): void {
    this.viewStackService.removeLastItemFromStack();
  }
}

