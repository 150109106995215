import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, from, mergeMap, Observable, take } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { checkUrlForProxy } from '../../functions/check-url-for-proxy';
import { SignalRService } from '../../signalR/signal-r.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public constructor(private authService: AuthService, private signalrService: SignalRService) {
  }

  // intercept requests to add the access token or refresh if expired
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.authService.isLoggedIn) {
      if (checkUrlForProxy(request.url) && this.authService.rawAccessToken) {
        if (this.authService.tokenExpired) {
          return from(this.authService.refreshTokenViaIdentityServer()).pipe(take(1), mergeMap(() => {
            return next.handle(this.setAuthorizationHeader(request));
          }));
        } else {
          return next.handle(this.setAuthorizationHeader(request));
        }
      }
    } else {
      return EMPTY;
    }

    return next.handle(request);
  }

  private setAuthorizationHeader(request: HttpRequest<unknown>): HttpRequest<unknown> {
    if (this.signalrService.connectionId) {
      request = request.clone({ headers: request.headers.append('SignalRConnectionId', this.signalrService.connectionId) });
    }

    if (this.signalrService.aiConnectionId) {
      request = request.clone({ headers: request.headers.append('SignalRAiConnectionId', this.signalrService.aiConnectionId) });
    }

    if (this.signalrService.idpConnectionId) {
      request = request.clone({ headers: request.headers.append('SignalRIdpConnectionId', this.signalrService.idpConnectionId) });
    }

    if (this.authService.rawAccessToken) {
      return request.clone({
        headers: request.headers.append('Authorization', 'Bearer ' + this.authService.rawAccessToken)
      });
    } else {
      console.warn('Tried to set Authorization header but access token was null');
      return request;
    }
  }
}
