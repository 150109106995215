import { Component, OnInit } from '@angular/core';
import { ExtensionDisplayType } from '../../../../../../models/ts/extension-display-type.model';
import { NgIf } from '@angular/common';
import { ExtensionIconComponent } from '../../../ui/icon/extension-icon/extension-icon.component';
import { CellActionType } from '../../../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { TitleFunctionType } from '../../../../../../models/ts/title-function-type.model';
import { VersionStateType } from 'src/models/ts/version-state-type.model';
import { GridContextPermissions } from 'src/app/features/bizzmine/widgets/collection-list-widget/classes/grid-context-permissions';
import { DirectEditMode } from 'src/models/ts/direct-edit-mode.model';

/**
 * Cell containing a file extension icon and text
 * Can contain both or either.
 */
@Component({
  selector: 'bizz-grid-cell-title',
  templateUrl: './grid-cell-title.component.html',
  styleUrls: ['./grid-cell-title.component.scss'],
  imports: [
    ExtensionIconComponent,
    NgIf
  ],
  standalone: true
})
export class GridCellTitleComponent extends GridCellBaseComponent<string> implements OnInit {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly ExtensionDisplayType = ExtensionDisplayType;
  public linkEnabled = false;

  public ngOnInit(): void {
    this.apply();
  }

  private apply(): void {
    const titleFunction = this.column.TitleFunctionType;
    switch (titleFunction) {
      case TitleFunctionType.ViewOnline:
        this.linkEnabled = this.metaData.listOptions.DirectEditMode !== DirectEditMode.Disabled && this.metaData.listOptions.DirectEditAdminConsented && !GridContextPermissions.hideDownload(this.data);
        break;
      case TitleFunctionType.Download:
        this.linkEnabled = !GridContextPermissions.hideDownload(this.data);
        break;
      case TitleFunctionType.ConsultRecord:
        this.linkEnabled = <boolean><unknown>(this.data['Permissions']?.CanReadProperties || this.data['Permissions']?.CanReadDocPDF) && (this.data.CanReadDrafts || this.data.DocumentStatus == VersionStateType.Active || this.data.DocumentStatus === undefined);
        break;
      default:
        break;
    }
  }

  public override onDataChanged(): void {
    this.apply();
  }

  public titleAction(mouseEvent: MouseEvent): void {
    const titleFunction = this.column.TitleFunctionType;
    switch (titleFunction) {
      case TitleFunctionType.ViewOnline:
        this.onCellAction.emit({ mouseEvent: mouseEvent, action: CellActionType.VIEW_ONLINE, data: { data: this.data } });
        break;
      case TitleFunctionType.Download:
        this.onCellAction.emit({ mouseEvent: mouseEvent, action: CellActionType.DOWNLOAD_FILE, data: { data: this.data } });
        break;
      case TitleFunctionType.ConsultRecord:
        this.onCellAction.emit({ mouseEvent: mouseEvent, action: CellActionType.CONSULT_RECORD, data: { data: this.data } });
        break;
      default:
        break;
    }
  }
}
