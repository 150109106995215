import { Component, DestroyRef, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AdditionalModes, OrgChartComponent, OrgChartView } from '../org-chart.component';
import { TranslatePipe } from '../../../../shared/pipes/translate/translate.pipe';
import { IconComponent } from '../../../../shared/components/ui/icon/icon.component';
import { BehaviorSubject } from 'rxjs';
import { OrgChartItem } from '../interfaces/org-chart-item';
import { OrganizationChartItemType } from '../../../../../models/ts/organization-chart-item-type.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Modal wrapper for OrgChartComponent.
 */
@Component({
  selector: 'bizz-org-chart-modal',
  templateUrl: './org-chart-modal.component.html',
  styleUrls: ['./org-chart-modal.component.scss'],
  imports: [
    TranslatePipe,
    OrgChartComponent,
    IconComponent
  ],
  standalone: true
})
export class OrgChartModalComponent {

  public selectedItems$: BehaviorSubject<Array<OrgChartItem>> = new BehaviorSubject<Array<OrgChartItem>>([]);
  public validSelection = true;

  public constructor(private dialogRef: DialogRef,
                     @Inject(DIALOG_DATA) public data: OrgChartModalData,
                     private destroyRef: DestroyRef) {
    this.dialogRef.disableClose = true;
    this.selectedItems$.next(data.items);
    this.selectedItems$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((selections) => this.validate(selections));
  }

  public close(): void {
    this.selectedItems$.getValue();
    this.dialogRef.close(this.selectedItems$.getValue());
  }

  public validate(selection: Array<OrgChartItem>) : void {
    this.validSelection = true;
    if(this.data.min > 0) {
      this.validSelection = this.validSelection && selection.length >= this.data.min;
    }
    if(this.data.max > 0) {
      this.validSelection = this.validSelection && selection.length <= this.data.max;
    }
  }

  public cancel(): void {
    this.selectedItems$.next(this.data.items);
    this.dialogRef.close(this.data.items);
  }
}

export interface OrgChartModalData {
  items: Array<OrgChartItem>,
  min: number,
  max: number,
  allowedItemTypes: Array<OrganizationChartItemType>,
  excludedUserIds: Array<number>
  additionalModes: Array<{ additionMode: AdditionalModes, data: any }>,
  activeView: OrgChartView,
  excludeExternalUsers: boolean
}
