import { ComponentRef, Type, ViewContainerRef } from '@angular/core';

/**
 * Creates a component in the specified view container.
 * The view container is cleared before the component is created.
 * Returns a ComponentRef with the newly created component.
 * @param viewContainer
 * @param component
 */
export function createViewContainerComponent<T>(viewContainer: ViewContainerRef, component: Type<T>): ComponentRef<T> {
  if (viewContainer) {
    viewContainer.clear();
    return viewContainer.createComponent(component);
  } else {
    throw new Error('ViewContainerRef is undefined');
  }
}