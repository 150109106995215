import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import { AuthService } from '../../../core/services/auth/auth.service';
import { BrandLogoComponent } from 'src/app/shared/components/brand-logo/brand-logo.component';
import { LoaderComponent } from '../../../shared/components/ui/loader/loader.component';

@Component({
  selector: 'bizz-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [BrandLogoComponent, LoaderComponent]
})
export class LoginComponent implements OnInit {
  private authService = inject(AuthService)
  private route = inject(ActivatedRoute)

  public ngOnInit(): void {
    this.authService.isInitializedObservable.pipe(filter(isInitialized => isInitialized)).subscribe({
      next: () => {
        if (this.authService.checkOAuthQueryParams(this.route.snapshot.queryParams)) {
          this.authService.tryLogin().then();
        } else {
          this.authService.initLogin();
        }
      }
    });
  }
}
