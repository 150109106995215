import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserSettingsDto } from '../../../../models/ts/user-settings-dto.model';

export const userSettingsActions = createActionGroup({
  source: 'User Settings',
  events: {
    fetchUserSettings: emptyProps(),
    fetchUserSettingsWithOverRules: props<{ themeId?: number | undefined, language? : number | undefined }>(),
    userSettingsFetched: props<{ userSettings: UserSettingsDto }>(),
    setAnonymousPortalLanguage: props<{language: number | undefined}>(),
    setAnonymousPortalTheme: props<{theme: number | undefined}>()
  }
});
