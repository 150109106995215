import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { PopoverModule, PopoverShowOption } from '@progress/kendo-angular-tooltip';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'bizz-popper',
  templateUrl: './popper.component.html',
  styleUrls: ['./popper.component.scss'],
  imports: [
    NgClass,
    PopoverModule,
    IconComponent,
  ],
  standalone: true,
})
export class PopperComponent {

  @Output() click = new EventEmitter<void>();

  public customHover = false;

  /**
   * The title of the popover.
   */
  @Input({ required: true }) public title: string;

  /**
   * Optional content of the popover.
   * Will change the styling of the popover.
   */
  @Input() public content: string;

  /**
   * The event on which the Popover will be shown
   * The supported values are:
   * click (default) — The Popover will be shown when its anchor element is clicked.
   * hover — The Popover will be shown when its anchor element is hovered.
   * focus — The Popover will be shown when its anchor element is focused.
   * none — The Popover will not be shown on user interaction.
   * It could be rendered via the Popover API methods.
   */
  @Input() public showOn: PopoverShowOption = 'hover';

  /**
   * The width of the popover.
   * Defaults to fit-content.
   */
  @Input() public width: number | string = 'fit-content';

}
