import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ControlOptions } from '../../interfaces/control-options.interface';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';

/**
 * Represents a control that allows the user to select a value from a list of options.
 */
@Component({
  selector: 'bizz-shared-combobox-control',
  standalone: true,
  imports: [CommonModule, ComboBoxModule, ReactiveFormsModule, TranslatePipe],
  templateUrl: './combobox-control.component.html',
  styleUrls: ['./combobox-control.component.scss']
})
export class SharedComboboxControlComponent  {
  @Input() control: FormControl;
  @Input() options: ControlOptions;

  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public onValueChange(value: any): void {
    this.onChange.emit(value);
  }


}