import { Component, EventEmitter, Output } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { SidebarService } from '../../services/sidebar/sidebar.service';
import { IconComponent } from '../ui/icon/icon.component';

@Component({
  selector: 'bizz-expand-collapse-icon',
  templateUrl: './expand-collapse-icon.html',
  styleUrls: ['./expand-collapse-icon.scss'],
  imports: [
    NgIf,
    AsyncPipe,
    IconComponent,
    NgClass,
  ],
  standalone: true,
})
export class ExpandCollapaseIcon {
  public expanded = this.sidebarService.getObservable();
  @Output() public toggleExpanded = new EventEmitter<void>();
  public countButtonState = 0;

  public changeExpandState(): void {
    this.toggleExpanded.emit();
    if (this.countButtonState <= 0) {
      this.countButtonState++;
    } else {
      this.countButtonState = 0;
    }

  }

  public constructor(
    private sidebarService: SidebarService,
  ) {
  }
}
