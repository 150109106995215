@if (formFieldSignal(); as formField) {
    @if(errorState?.Message; as errorMessage){
        <div class="absolute right-4 top-0 z-[2] flex h-full items-center justify-center">
            <bizz-tooltip class="cursor-pointer text-red-400" [text]="errorMessage"></bizz-tooltip>
        </div>
    }
    @if(formControl.errors?.['NaN']){
        <div class="absolute right-4 top-0 z-[2] flex h-full items-center justify-center">
            <bizz-tooltip class="cursor-pointer text-red-400" [text]="'Only (negative) numbers, your thousand separator \'.\' and your comma separator \',\' are accepted input values.'"></bizz-tooltip>
        </div>
    }
    @if(formField.ProtectedFieldType == ProtectedFieldType.Size){
        <input [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0" #input class="h-10" [ngClass]="{'hidden' : formField.IsHidden}" readonly
               id="{{formField.Id}}" type="text"  [value]="formControl.value | bytes">
    }@else{
        <kendo-numerictextbox #inputKendo [class.error]="errorState != null || formControl.errors != null" class="h-10"
                              [ngClass]="{'hidden' : formField.IsHidden,
                                'hide-input-text': hasDisplayStyle && !inputHasFocus   }"
                                (focus)="onFocus()"
                                (blur)="onBlur()"
                              [placeholder]="formField.Placeholder"
                              [min]="formField.MinValue" [max]="formField.MaxValue" [readonly]="readOnlyOverride || formField.IsReadOnly"
                              [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0"
                              [format]="format(formField)" [formControl]="formControl" [spinners]="false">
        </kendo-numerictextbox>
        @if(!inputHasFocus && hasDisplayStyle && (formControl.value != null && formControl.value != '')) {
            <span class="color-preview"
            [ngClass]="{'hidden' : formField.IsHidden, 'opacity-70': formField.IsReadOnly}"
            [ngStyle]="{ 'background-color': backgroundColor, 'color': foregroundColor }">
                {{formControl.value | decimal: formField.MaxDecimals }}
            </span>
        }
       
    }
}
