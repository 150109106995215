import {
  HeaderNotificationsState,
  initialHeaderNotificationsStoreState
} from './features/header-notifications/header-notifications-state';
import { FormsState } from './features/forms/forms-state';
import { formsAdapter } from './features/forms/forms-reducer';
import {
  HeaderRemindersState,
  initialHeaderRemindersStoreState
} from './features/header-reminders/header-reminders-state';
import { HeaderTasksState, initialHeaderTasksStoreState } from './features/header-tasks/header-tasks-state';
import { initialUserSettingsStoreState, UserSettingsState } from './features/user-settings/user-settings-state';
import { initialWorkspaceState, WorkspaceSidebarState } from './features/workspace-sidebar/workspace-sidebar-state';
import { FlowStatusState } from './features/flow-status/flow-status-state';
import { flowStatusAdapter } from './features/flow-status/flow-status-reducer';
import { initialTrainingStoreState } from './features/training/initial-training-store-state';
import { TrainingStoreState } from './features/training/training-state';
import { CollectionState } from './features/collections/collections-state';
import { collectionsAdapter } from './features/collections/collections-reducer';


export interface RootState {
  headerNotifications: HeaderNotificationsState;
  forms: FormsState;
  flowStatus: FlowStatusState;
  headerTasks: HeaderTasksState;
  headerReminders: HeaderRemindersState;
  userSettings: UserSettingsState;
  workspace: WorkspaceSidebarState;
  training: TrainingStoreState;
  collections: CollectionState;
}

export const initialRootState: RootState = {
  headerNotifications: initialHeaderNotificationsStoreState,
  forms: formsAdapter.getInitialState(),
  flowStatus: flowStatusAdapter.getInitialState(),
  headerTasks: initialHeaderTasksStoreState,
  headerReminders: initialHeaderRemindersStoreState,
  userSettings: initialUserSettingsStoreState,
  workspace: initialWorkspaceState,
  training: initialTrainingStoreState,
  collections: collectionsAdapter.getInitialState(),
};
