import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MonitorDto } from '../../../../models/ts/monitor-dto.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MonitorApiService {

  constructor(private http: HttpClient) { }

  /***
   * Returns the monitor data for the given collection, instance and version.
   * @param collectionsID  he ID of the collection.
   * @param instancesID The ID of the instance.
   * @param versionsID The ID of the version.
   * @returns {Observable<MonitorDto>} The monitor data.
   */
  public getMonitorData(collectionsID: number, instancesID: number, versionsID: number): Observable<MonitorDto> {
    return this.http.get<MonitorDto>(`api/monitor/collection/${collectionsID}/instance/${instancesID}/version/${versionsID}`);
  }
}
