import { Pipe, PipeTransform } from '@angular/core';
import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';
import { FormControl, FormGroup } from '@angular/forms';

/**
 * Pipe to get a fields corresponding formcontrol from given formgroup<br>
 */
@Pipe({
  name: 'fieldFormControl',
  standalone: true,
  pure: true
})
export class FieldFormControlPipe implements PipeTransform {

  /**
   * Pipe to get a fields corresponding formcontrol from given formgroup<br>
   * Intended for use in FormComponent.
   * @param field
   * @param form
   */
  transform(field: CollectionFormField, form: FormGroup): FormControl {
    return form.controls[field.Id.toString()] as FormControl;
  }

}
