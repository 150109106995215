import { Pipe, PipeTransform } from '@angular/core';
import { TwoFactorAuthenticationMethod } from '../../../../models/ts/two-factor-authentication-method.model';
import { ElectronicSignatureSigningType } from '../../../../models/ts/electronic-signature-signing-type.model';
import { UserSettingsIndexDto } from '../../../../models/ts/user-settings-index-dto.model';

/**
 * Two Fa pipe is used for handling 2-factor authentication.
 */

@Pipe({
  name: 'twoFactor',
  standalone: true,
  pure: true
})
export class TwoFactorPipe implements PipeTransform {

  transform(index: UserSettingsIndexDto): boolean {
    switch (true) {
      case (index.TwoFactorAuthenticationMethods.some(function (m) {
        return m.Method == TwoFactorAuthenticationMethod.Code && m.IsActive;
      }) != undefined && index.UserTwoFactorAuthenticationMethod == TwoFactorAuthenticationMethod.Code && (index.TwoFactorSecret && index.TwoFactorSecret.length > 0)):
        return true;
      case (index.ElectronicSignatureSigningType == ElectronicSignatureSigningType.Device && (index.TwoFactorSecret && index.TwoFactorSecret.length > 0)):
        return true;
      default:
        return false;
    }
  }
}
