import { Component, inject, Inject, ViewContainerRef } from '@angular/core';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { EditOnlineApiServiceService } from '../../../../../api/bizzmine/edit-online/edit-online-api-service.service';
import { combineLatestWith, finalize, lastValueFrom, map, of, switchMap, take, tap } from 'rxjs';
import { EditorModule } from '@progress/kendo-angular-editor';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { CollectionListApiService } from '../../../../../api/bizzmine/collection-list/collection-list-api.service';
//import { FileUploadComponent } from '../file-upload.component';
import { MediaCommitDto } from '../../../../../../models/ts/media-commit-dto.model';
import {
  FileUploadEditOnlineModalComponentData,
  FileUploadEditOnlineModalComponentMode
} from 'src/models/ts/file-upload-edit-online-modal-component-mode';
import { EditOnlineErrorModalComponent } from '../edit-online-error-modal/edit-online-error-modal.component';
import { FileUploadService, FormDataWithMeta } from '../../../../../core/services/file-upload/file-upload.service';
import { CollectionFormFileUploadApiService } from '../../../../../api/bizzmine/collection-form-file-upload/collection-form-file-upload-api.service';
import { Observable } from 'rxjs/internal/Observable';
import { FileInfoModel } from '../../../../../../models/ts/file-info-model.model';

@Component({
  selector: 'bizz-file-upload-edit-online-modal',
  standalone: true,
  imports: [
    IconComponent,
    TranslatePipe,
    EditorModule,
    ToolBarModule
  ],
  templateUrl: './file-upload-edit-online-modal.component.html',
  styleUrl: './file-upload-edit-online-modal.component.scss'
})
export class FileUploadEditOnlineModalComponent {

  public openedOneDriveWindow: WindowProxy | null;
  public errorMessage: string | undefined;
  public authenticated = false;
  public authWindow: any | undefined;
  public checkInterval: any | undefined;
  public isLoading = false;
  public authUrl: string | undefined;
  public oneDriveKey: string | undefined;
  public viewContainerRef = inject(ViewContainerRef);
  public lastModified: string | null;
  public loading = false;
  public hasCheckedAuth = false;
  protected readonly FileUploadEditOnlineModalComponentMode = FileUploadEditOnlineModalComponentMode;

  public constructor(public dialogRef: DialogRef,
                     public dialog: Dialog,
                     @Inject(DIALOG_DATA) public data: FileUploadEditOnlineModalComponentData,
                     private editOnlineApiService: EditOnlineApiServiceService,
                     private collectionListApiService: CollectionListApiService,
                     private fileUploadService: FileUploadService,
                     private collectionFormFileUploadApiService: CollectionFormFileUploadApiService) {
    this.isLoading = true;
    this.checkAuthentication();
  }

  public loginAndCheckForResponse(): void {
    window.open(this.authUrl, '_blank');
    this.checkInterval = setInterval(() => {
      this.checkAuthentication();
    }, 3000);
  }

  public uploadFile(file: File): Promise<MediaCommitDto> {
    return new Promise<MediaCommitDto>((resolve, error) => {
      const upload = this.fileUploadService.uploadFiles({
        upload$: (formData) => this.collectionFormFileUploadApiService.uploadFileFromFormData(formData),
        files: [file],
        chunkSize: 1024 * 1024,
        maxConcurrentUploads: 4,
        preValidation$(formData: FormDataWithMeta): Observable<FormDataWithMeta> {
          return of(formData);
        }
      })[0];
      upload.cancel = (): void => {
        error('Upload cancelled');
      };
      upload.onError = (err): void => {
        error(err);
      };

      upload.onCompleted = (): void => {
        if (upload.fileChunkUpload == null) {
          error('Upload failed');
        }
        const chunk = upload.fileChunkUpload!;
        const mediaCommitDto: MediaCommitDto = {
          FileName: this.data.filename,
          LastModifiedDate: chunk.lastModifiedDate.toString(),
          Identifier: upload.fileId,
          Type: chunk.type,
          Size: chunk.size,
          ChunkIDs: chunk.chunkIds,
          MediasID: 0,
          CollectionsID: this.data.fromGrid!.collectionId,
          ContentType: '',
          MimeValidation: '',
          VersionsID: this.data.fromGrid!.versionId
        };

        lastValueFrom(this.collectionFormFileUploadApiService.commitFile(mediaCommitDto)
          .pipe(switchMap(mediaId => {
            return this.collectionFormFileUploadApiService.updateDocumentOnly(
              {
                collectionId: this.data.fromGrid!.collectionId,
                versionId: this.data.fromGrid!.versionId,
                instanceId: this.data.fromGrid!.instanceId,
                checkInData : {
                  Name: this.fileInfo?.FileName ?? '',
                  Extension: this.fileInfo?.Extension ?? '',
                  Size: mediaCommitDto.Size,
                  File: mediaId,
                  ReasonModification: this.data.modificationreason,
                  CollectionsID: this.data.fromGrid!.collectionId,
                  InstancesID: this.data.fromGrid!.instanceId,
                  VersionsID: this.data.fromGrid!.versionId,
                  Checkin: true
                }
              }
            ).pipe(combineLatestWith(of(mediaId)), map(([_, id]) => id))
          }))
        )
          .then((response) => {

            mediaCommitDto.MediasID = response;
            resolve(mediaCommitDto);
          }).catch((err) => {
          error(err);
        });
      };
    });

  }

  public openOnline(): void {
    this.editOnlineApiService.checkOutOnlineMedia({
      mode: this.data.mode,
      mediaId: this.data.mediaId,
      doCheckoutCheckin: this.data.docheckoutcheckin,
      fileName: this.data.filename
    })
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (response.OneDriveID != 'error') {
            this.oneDriveKey = response.OneDriveID;
            this.lastModified = response.LastModified;
            this.openedOneDriveWindow = window.open(response.Url, '_blank');
          } else {
            this.errorMessage = response.Url;
          }
        },
        error: () => {
          if (this.data.closeModalMethod) {
            this.data.closeModalMethod();
          }
        }
      });
  }

  public finishEditOnline(): void {
    this.loading = true;

    if (this.openedOneDriveWindow == null) {
      this.cancelEditOnline(false);
    }

    if (this.data.modificationreason === null || this.data.modificationreason?.trim() === '' && this.data.docheckoutcheckin) {
      this.errorMessage = 'ModificationReasonIsRequired';

      this.loading = false;
    } else {
      if (this.data.finishMethod) {
        this.data.finishMethod({
          modificationReasonFromOnlineEdit: this.data.modificationreason,
          openedOneDriveWindow: this.openedOneDriveWindow,
          mediasID: this.data.mediaId,
          oneDriveKey: this.oneDriveKey!,
          lastModified: (this.lastModified ?? '')
        });
      }
    }
  }

  public cancelAuthentication(): void {
    if (this.checkInterval != null) {
      clearInterval(this.checkInterval);
      this.checkInterval = null;
    }

    this.data.closeModalMethod();
  }

  public cancelEditOnline(undoCheckout: boolean): void {
    if (this.oneDriveKey != null && this.oneDriveKey.trim() !== '') {
      this.loading = true;

      if (this.openedOneDriveWindow != null) {
        this.openedOneDriveWindow.close();
      }

      this.editOnlineApiService.removeCheckOutOnlineMedia({
        oneDriveKey: this.oneDriveKey!,
        mediasId: this.data.mediaId,
        mode: this.data.mode,
        undoCheckout: undoCheckout
      }).pipe(take(1),
        finalize(() => this.loading = false))
        .subscribe({
          next: () => {
            this.loading = false;
            if (this.data.closeModalMethod) {
              this.data.closeModalMethod();
            }
          }
        });
    } else {
      if (this.data.closeModalMethod) {
        this.data.closeModalMethod();
      }
    }
  }
  public fileInfo: FileInfoModel;
  public checkAuthentication(): void {
    this.editOnlineApiService.authentication()
      .pipe(
        take(1),
        tap((response) => {
          this.authenticated = response.AuthenticationResult.IsAuthenticated;
          this.authUrl = response.LoginUrl;
          this.hasCheckedAuth = true;

          if (this.authenticated) {
            if (this.data.mode == FileUploadEditOnlineModalComponentMode.View) {
              if (this.data.closeModalMethod != null) {
                this.data.closeModalMethod();
              }
            }
            if (this.checkInterval != null) {
              clearInterval(this.checkInterval);
              this.checkInterval = null;
            }


            if (this.data.fromGrid != null) {
              // from grid item to we must first fetch some data to be compatible with the system
              this.editOnlineApiService.retrieveMedia(this.data.fromGrid).pipe(take(1),
                tap(response => {
                  this.fileInfo = response;
                  this.data.mediaId = this.fileInfo.MediasID;
                  this.data.filename = this.fileInfo.FileName + this.fileInfo.Extension;
                }),
                switchMap(() => this.collectionListApiService.getModificationReason({
                  collectionId: this.data.fromGrid!.collectionId,
                  instanceId: this.data.fromGrid!.instanceId,
                  versionId: this.data.fromGrid!.versionId
                })),
                tap((reason) => {
                  this.data.modificationreason = reason;
                  this.openOnline();
                }))
                .subscribe({
                  next:() => this.hasCheckedAuth = true,
                  error:() => this.hasCheckedAuth = true,
                  complete:() => this.hasCheckedAuth = true,
                });
            } else {
              this.openOnline();
            }

          } else if (!this.authenticated && response.AuthenticationResult.ErrorMessage?.trim()?.length > 0) {
            if (this.checkInterval != null) {
              clearInterval(this.checkInterval);
              this.checkInterval = null;
            }
            this.dialog.open(EditOnlineErrorModalComponent,
              {
                data: {
                  title: 'Onedrive_ReauthenticationRequired',
                  subText: response.AuthenticationResult.ErrorMessage,
                  message: 'Onedrive_ReauthenticationRequired_Message'
                }
              }).closed
              .pipe(take(1)).subscribe();


          }
        }),
        finalize(() => this.isLoading = false)
      )
      .subscribe();
  }

}
