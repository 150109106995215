@if (exam.Result.SessionAnswerResults.length > 0 &&
exam.Result.SessionAnswerResults.length >= this.reportConfig$.value!.currentIndex &&
exam.Result.SessionAnswerResults[this.reportConfig$.value!.currentIndex]; as question) {

    <div class="contents h-full w-full gap-5">
        <div class="mx-auto flex w-full flex-row gap-5 px-5 pt-4 text-center">
            <div class="w-full py-4 text-center font-title font-bold">
                {{ 'Q' + (reportConfig$.value.currentIndex + 1) }}
                <span [innerHtml]="question.Question | safe : 'html'"></span>
            </div>
        </div>
        <div class="flex h-full w-full flex-col gap-5 overflow-auto">
            <div>
                <p class="mb-2">{{ 'CorrectAnswer' | translate }}:</p>
                <div class="flex w-full flex-1 items-center gap-5 rounded border-2 border-success bg-success/50 p-2.5 text-sm">
                    <button class="ml-5 max-h-9 max-w-9 rounded-full bg-button bg-success p-2.5 text-white">
                        <bizz-icon class="size-4" iconName="check"></bizz-icon>
                    </button>
                    <div class="mr-5 w-full text-start font-sans"
                         [innerHtml]="question.CorrectAnswer | safe : 'html'"></div>

                </div>
            </div>
            <div>
                <div>
                    <p class="mb-2">{{ 'YourAnswer' | translate }}:</p>
                    <div class="flex w-full flex-1 items-center gap-5 rounded border-2 p-2.5 text-sm"
                         [ngClass]="{'bg-success/50 border-success' : question.Score > 0, 'bg-danger/50 border-danger': question.Score <= 0 }">
                        <button class="ml-5 max-h-9 max-w-9 rounded-full bg-button p-2.5 text-white"
                                [ngClass]="{'!bg-success' : question.Score > 0, '!bg-danger' : question.Score <= 0}">
                            @if (question.Score > 0) {
                                <bizz-icon class="size-4" iconName="check"></bizz-icon>
                            } @else {
                                <bizz-icon class="size-4" iconName="xmark"></bizz-icon>
                            }
                        </button>
                        <div class="mr-5 w-full text-start font-sans"
                             [innerHtml]="question.Answer | safe : 'html'"></div>

                    </div>
                </div>
            </div>
        </div>
    </div>


}

<div class="flex max-h-fit w-full justify-end">
    <button class="save" (click)="nextReportQuestion()">
        {{ "Next" | translate }}
        <bizz-icon class="h-4 w-4"
                   iconName="caret-right"></bizz-icon>
    </button>
</div>