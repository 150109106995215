import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Store } from '@ngrx/store';
import { userSettingsFeature } from '../../../store/features/user-settings/user-settings-feature';
import { TranslationInfoDto } from '../../../../models/ts/translation-info-dto.model';
import { BizzMineLocalStorageService } from '../../../shared/services/localStorage/bizzmine-local-storage.service';
import { forkJoin, map, Observable, take } from 'rxjs';
import { appActions } from '../../../store/features/app/app-actions';
import { LanguageDto } from '../../../../models/ts/language-dto.model';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  public constructor(private http: HttpClient, private authService: AuthService, private store$: Store, private localStorage: BizzMineLocalStorageService) {
  }

  private get userLanguage(): number {
    return this.store$.selectSignal(userSettingsFeature.selectAnonymousPortalUserLanguage)() ?? this.store$.selectSignal(userSettingsFeature.selectUserLanguagesID)();
  }

  private get companyLanguage(): number {
    return this.store$.selectSignal(userSettingsFeature.selectAnonymousPortalUserLanguage)() ?? this.store$.selectSignal(userSettingsFeature.selectCompanyLanguagesID)();
  }

  public translate(key: string): string {
    const translation = this.getTranslations(this.userLanguage ?? this.companyLanguage).find(t => t.Key == key)?.Value;
    if (translation) {
      return translation;
    }else{
      console.log(`** Translation Service: missing translation for key: ${key}! **`);
      return key;
    }
  }

  public kendoFilterTranslate(key: string): string | undefined {
    return this.getTranslations(this.userLanguage ?? this.companyLanguage).find(t => t.Key == key)?.Value ?? undefined;
  }

  /**
   * Returns the correct translation based on the user or user's
   * company language.
   * @param translationInfo object containing the languages
   * @param isSettings is the translation for the settings pages
   * @param translationFieldValuesIndex index of the translation field values
   */
  public translateInfo(translationInfo: TranslationInfoDto,
                       isSettings = false,
                       translationFieldValuesIndex = 0): string {
    const userLanguageIndex = this.getLanguageIndex(translationInfo, this.userLanguage);
    const companyLanguageIndex = this.getLanguageIndex(translationInfo, null);
    // On Settings part retrieve the Company Language his Index,
    // else the User Language his Index
    if (isSettings) {
      return translationInfo.Languages[companyLanguageIndex]
        .TranslationFieldValues[translationFieldValuesIndex].Value;
    } else {
      const value = translationInfo.Languages[userLanguageIndex]
        .TranslationFieldValues[translationFieldValuesIndex].Value;
      // If no Translations are found for the User his Language,
      // then return the Company Language translations
      if (!value) {
        return translationInfo.Languages[companyLanguageIndex]
          .TranslationFieldValues[translationFieldValuesIndex].Value;
      } else {
        return value;
      }
    }
  }

  /**
   * Gets the Language Index of the Company if languageID is not set or the user
   * language if given.
   * @param translationInfo object containing the languages
   * @param languagesID
   */
  public getLanguageIndex(translationInfo: TranslationInfoDto, languagesID: number | null): number | never {
    for (let i = 0; i < translationInfo.Languages.length; i++) {
      // Return Company Language his Index
      if (translationInfo.Languages[i].IsDefault && languagesID == null) {
        return i;
      }
      // Return User Language his Index
      else if (translationInfo.Languages[i].LanguageID == languagesID) {
        return i;
      }
    }
    throw new Error('Language Index was not found!');
  }

  /**
   * Fetches the json files for gives language id's and stores them in localStorage.
   * Updates the appStore with loading state.
   * @param {number[]} languageIds
   */
  public storeTranslations(languageIds: Set<number>): void {
    this.store$.dispatch(appActions.translationsLoading());
    forkJoin(Array.from(languageIds.values()).map(id => {
      return this.http.get(`assets/translations/${id}.json`).pipe(take(1), map(json => {
        return { id: id, json: json };
      }));
    })).subscribe({
      next: languages => {
        languages.forEach(language => {
          this.localStorage.setItem(`BizzMineTranslations${language.id}`, JSON.stringify(language.json))
        })
      },
      error: error => {
        console.error("Failed to load languages", error);
        this.store$.dispatch(appActions.translationsLoaded());
      },
      complete: () => {
        this.store$.dispatch(appActions.translationsLoaded());
      }
    });
  }
  private getTranslations(languageId: number): Translation[] {
    const translations = this.localStorage.getItem(`BizzMineTranslations${languageId}`);
    return translations ? JSON.parse(translations) : [];
  }

  public getLanguageOptions(tenant: string): Observable<Array<LanguageDto>> {
    return this.http.get<Array<LanguageDto>>('settings/language/options', {
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'x-tenant': tenant
      }
    });
  }
}

export type Translation = { Key: string, Value: string };
