import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardBand } from '../../../../models/ts/dashboard-band.model';
import { ChartWidgetData } from '../../../../models/ts/chart-widget-data.model';
import { HtmlWidgetData, RssWidgetData } from '../../../../models/ts/rss-widget-data.model';
import { SchedulerWidgetData } from '../../../../models/ts/scheduler-widget-data.model';
import { CollectionInfoWidgetData } from '../../../../models/ts/collection-info-widget-data.model';
import { DocumentTreeWidgetData } from '../../../../models/ts/document-tree-widget-data.model';
import { SkillMatrixWidgetData } from '../../../../models/ts/skill-matrix-widget-data.model';
import { TableViewWidgetData } from '../../../../models/ts/table-view-widget-data.model';
import { TrainingQueueWidgetData } from '../../../../models/ts/training-queue-widget-data.model';
import { GapAnalysisMatrixWidgetData } from '../../../../models/ts/gap-analysis-matrix-widget-data.model';

export type WidgetData = ChartWidgetData | HtmlWidgetData | SchedulerWidgetData | RssWidgetData | CollectionInfoWidgetData | DocumentTreeWidgetData | SkillMatrixWidgetData | TableViewWidgetData | TrainingQueueWidgetData | GapAnalysisMatrixWidgetData;

@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {

  constructor(private http: HttpClient) {
  }

  public getDashboard(workspaceID: number, workspaceItemID: number): Observable<DashboardBand[]> {
    return this.http.get<DashboardBand[]>(`api/dashboard/${workspaceItemID}/workspace/${workspaceID}`);
  }

  public getDashboardWidget(workspaceItemID: number, widgetID: number, workspaceItemsWidgetID: number): Observable<WidgetData> {
    return this.http.get<WidgetData>(`api/dashboard/${workspaceItemID}/widget/${widgetID}/workspaceitemwidget/${workspaceItemsWidgetID}`);
  }
}
