/**
 * Keeps track of the location of a form field within a CollectionForm.
 *  - accordionId: the id of the accordion in the form
 *  - rowIndex: the index of the row in the accordion
 *  - containerId: the id of the container in the row
 *  - fieldId: the id of the field in the container
 */
export class FormFieldLocation {
  constructor(public accordionId: number, public rowIndex: number, public containerId: number, public fieldId: number) {
  }
}
