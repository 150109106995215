import {
  Component,
  ElementRef,
  EventEmitter, forwardRef,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ToolBarToolComponent } from '@progress/kendo-angular-toolbar';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { IconComponent } from '../../../../../../../../shared/components/ui/icon/icon.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { SliderModule } from '@progress/kendo-angular-inputs';
import { NgClass } from '@angular/common';
import { TranslatePipe } from '../../../../../../../../shared/pipes/translate/translate.pipe';

export enum CustomCanvasActions{
  RevertImage = 1,
  RemoveStrokes = 2,
  RemoveImage = 3,
  RemoveAll = 4
}
@Component({
  selector: 'bizz-custom-canvas-actions',
  standalone: true,
  imports: [
    ButtonModule,
    IconComponent,
    PopupModule,
    SliderModule,
    NgClass,
    TranslatePipe
  ],
  templateUrl: './custom-canvas-actions.component.html',
  styleUrl: './custom-canvas-actions.component.scss',
  providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => CustomCanvasActionsComponent) }]
})
export class CustomCanvasActionsComponent  extends ToolBarToolComponent {
  @ViewChild('toolbarTemplate', { static: true }) public override toolbarTemplate: TemplateRef<any>;
  @ViewChild('popupTemplate', { static: true }) public override popupTemplate: TemplateRef<any>;
  @Output() public valueChange = new EventEmitter<number>();
  @Input({required: true}) public actions: Array<CustomCanvasActions>;

  protected readonly CustomCanvasActions = CustomCanvasActions;

  @HostListener("document:click", ["$event"])
  public documentClick(event: KeyboardEvent): void {
    if(event && event.target){
      if (!this.contains(event.target)) {
        this.show = false;
      }
    }

  }

  @ViewChild("anchor", { read: ElementRef }) public anchor: ElementRef;
  @ViewChild("popup", { read: ElementRef }) public popup: ElementRef;

  @Output() public revertImage = new EventEmitter<void>();
  @Output() public removeStrokes = new EventEmitter<void>();
  @Output() public removeImage = new EventEmitter<void>();
  @Output() public removeAll = new EventEmitter<void>();
  private contains(target: EventTarget): boolean {
    return (
      this.anchor?.nativeElement?.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }
  public constructor() {
    super();
  }

  public containsAction(action: CustomCanvasActions): boolean {
    return this.actions.includes(action);
  }


  public show = false;
  public onToggle(): void {
    this.show = !this.show;
  }
}
