// used in SignalR as a type aka - must be class(typed) not interface
export class CompleteStepMessageCallBackSignalrMessage {
  request: string;
  state: CompleteStepMessageCallBackState;
  type: string;
  item?: unknown;
}

export enum CompleteStepMessageCallBackState {
  Undefined,
  Success,
  Error
}

export class CompleteStepMessageJobResult<TRequestClass,TItemClass>{
  request: TRequestClass;
  item?: TItemClass;
  state: CompleteStepMessageCallBackState;
}

export class ManualExamEnrollmentRequest {
  TrainingAppId: number;
  ExamVersionsID: number;
}

export interface CompleteStepMessage{
  versionsID: number;
}