import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../../core/services/translation/translation.service';

@Pipe({
  name: 'translate',
  standalone: true
})
export class TranslatePipe implements PipeTransform {

  public constructor(private translationService: TranslationService) {
  }

  /**
   * Returns the translated value of the given key if it exists.
   * @param key
   */
  public transform(key: string): string {
    return this.translationService.translate(key);
  }

}
