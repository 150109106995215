import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KENDO_TEXTAREA, RadioButtonModule } from '@progress/kendo-angular-inputs';
import { SharedFilterModule } from '@progress/kendo-angular-grid';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { CollectionForm } from '../../../../../../models/ts/collection-form.model';
import { AssessmentApiService } from '../../../../../api/bizzmine/assessment/assessment-api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectFormLockState } from 'src/app/store/features/forms/forms-selectors';
import { Store } from '@ngrx/store';
import { ReadAndUnderstoodDto } from 'src/models/ts/read-and-understood-dto.model';
import { ReadAndUnderstoodType } from 'src/models/ts/read-and-understood-type.model';

@Component({
  selector: 'bizz-form-read-and-understood',
  standalone: true,
  imports: [CommonModule, TranslatePipe, IconComponent, RadioButtonModule, SharedFilterModule, KENDO_TEXTAREA, ReactiveFormsModule],
  templateUrl: './form-read-and-understood.component.html',
  styleUrls: ['./form-read-and-understood.component.scss'],
})
export class FormReadAndUnderstoodComponent implements OnInit {
  @Input({ required: true }) public formId: string;
  @Input({ required: true }) public formData: CollectionForm;
  @Output() public readAndUnderstoodChange: EventEmitter<ReadAndUnderstoodDto> = new EventEmitter<ReadAndUnderstoodDto>();

  public ReadAndUnderstoodType = ReadAndUnderstoodType;
  public readAndUnderstoodForm: FormGroup;
  public constructor(private fb: FormBuilder, 
    private assessmentApiService: AssessmentApiService, 
    private dialog: Dialog, 
    private store$: Store,
    private destroyRef: DestroyRef) {

  }

  public ngOnInit(): void {
    if(this.formData.DocumentProperties.ReadAndUnderstood){
      this.readAndUnderstoodForm = this.initAssessmentForm(this.formData.DocumentProperties.ReadAndUnderstood);
    }else{
      throw new Error('Read and Understood data is missing');
    }
    this.readAndUnderstoodForm.valueChanges.pipe(debounceTime(300), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.emitReadAndUnderstoodChange();
    });

    this.store$.select(selectFormLockState(this.formId)).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (locked) => locked ? this.readAndUnderstoodForm.disable() : this.readAndUnderstoodForm.enable()
    });
  }

  public emitReadAndUnderstoodChange(): void {
    this.readAndUnderstoodChange.emit(this.readAndUnderstoodForm.getRawValue() as ReadAndUnderstoodDto);
  }

  private initAssessmentForm(readAndUnderstood: ReadAndUnderstoodDto): FormGroup {
    return this.fb.group({
      RejectReason: new FormControl(readAndUnderstood.RejectReason),
      Result: new FormControl(readAndUnderstood.Result),
      Active: new FormControl(readAndUnderstood.Active),
    });
  }
}
