import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'bizz-alert',
  standalone: true,
  imports: [
    IconComponent
  ],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent {
  @Input({ required: true }) public type: 'message' | 'info' | 'warning' | 'error' | 'success';
  @Input({ required: true }) public message: string;

  public get icon(): string {
    switch (this.type) {
      case 'info':
        return 'circle-info';
      case 'warning':
        return 'triangle-exclamation';
      case 'error':
        return 'circle-exclamation';
      case 'success':
        return 'circle-check';
      default:
        return '';
    }
  }
}
