import { Component } from '@angular/core';
import { CellActionType } from '../../../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';
import { IconComponent } from '../../../ui/icon/icon.component';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';

@Component({
  selector: 'bizz-grid-cell-training-queue-actions',
  standalone: true,
  imports: [
    IconComponent
  ],
  templateUrl: './grid-cell-training-queue-actions.component.html',
  styleUrl: './grid-cell-training-queue-actions.component.scss'
})
export class GridCellTrainingQueueActionsComponent extends GridCellBaseComponent<unknown> {

  public checked(): void {
    this.onCellAction.emit({ action: CellActionType.TRAINING_QUEUE_SELECTED, data: { data: this.data } });
  }

  public delete(): void {
    this.onCellAction.emit({ action: CellActionType.TRAINING_QUEUE_DELETE, data: { data: this.data } });
  }

  public recover(): void {
    this.onCellAction.emit({ action: CellActionType.TRAINING_QUEUE_RECOVER, data: { data: this.data } });
  }
}
