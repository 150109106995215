import { Pipe, PipeTransform } from '@angular/core';
import { DecimalService } from '../../services/filter/decimal-service';

@Pipe({
  name: 'decimal',
  standalone: true
})
export class DecimalPipe implements PipeTransform {

  public constructor(private decimalService: DecimalService) {

  }

  public transform(value: string, decimals: number): string {
    if (!decimals)
      return value;
    if (isNaN(parseFloat(value)))
      return '';

    value = this.decimalService.roundDecimals(parseFloat(value), decimals);
    return this.decimalService.getFormattedValue(value);
  }

}
