<kendo-grid [kendoGridBinding]="tasksInternal"
            class="text-start text-xs font-normal text-list-row w-full"
            [autoSize]="true"
            #flowStatusGrid>
    <ng-template kendoGridNoRecordsTemplate #noRecords>
        <p> {{ 'NoResultsFound' | translate }}</p>
    </ng-template>

    <!-- Mobile single column-->
    <kendo-grid-column [media]="'(max-width: ' + MAX_MOBILE_WIDTH + 'px)'"
                       [autoSize]="false"
                       class="text-xs" headerClass="hidden">
        <ng-template kendoGridCellTemplate let-dataItem>
            <bizz-flow-status-tasks-grid-mobile-column [dataItem]="dataItem"
                                                       [taskType]="taskType"
                                                       (showAssessmentDetails)="showAssessmentDetails.emit(dataItem)"
                                                       (showTaskDetails)="taskDetails.emit(dataItem)">
            </bizz-flow-status-tasks-grid-mobile-column>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Status" [media]="'(min-width: ' + (MAX_MOBILE_WIDTH + 1) + 'px)'"
                       [autoSize]="false"
                       class="text-xs" [width]="taskType == FlowStatusTaskType.Detailed ? 100 : 150"
                       [minResizableWidth]="taskType == FlowStatusTaskType.Detailed ? 100 : 150"
                       [headerClass]="taskType == FlowStatusTaskType.Detailed ? 'justify-center' : ''">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="word-wrap flex items-center gap-2.5 mx-1"
                 [ngClass]="{'justify-center' : !dataItem.TaskStateTypeText }">
                <bizz-status-icon [statusType]="dataItem.TaskStateType"></bizz-status-icon>
                {{ dataItem.TaskStateTypeText }}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="StepsName" [title]="'Step' | translate"
                       [media]="'(min-width: ' + (MAX_MOBILE_WIDTH + 1) + 'px)'"
                       class="text-xs">
    </kendo-grid-column>
    @if (taskType !== FlowStatusTaskType.Detailed) {
        <kendo-grid-column field="Subject" [title]="'Subject' | translate"
                           [media]="'(min-width: ' + (MAX_MOBILE_WIDTH + 1) + 'px)'"
                           class="text-xs"></kendo-grid-column>
    }

    <kendo-grid-column [title]="'DueDate' | translate"
                       [media]="'(min-width: ' + TAILWIND_LG_BR + 'px)'"
                       class="text-xs">
        <ng-template kendoGridCellTemplate let-dataItem>
            @if (dataItem.DueDate !== null) {
                <span>{{ dataItem.DueDate | date:'short' }}</span>
            } @else {
                <span>-</span>
            }
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [title]="'Scheduled' | translate"
                       [media]="'(min-width: ' + TAILWIND_LG_BR + 'px)'"
                       class="text-xs">
        <ng-template kendoGridCellTemplate let-dataItem>
            @if (dataItem.TimedStepDate !== null) {
                <span>{{ dataItem.TimedStepDate | date:'short' }}</span>
            } @else {
                <span>-</span>
            }
        </ng-template>
    </kendo-grid-column>
    @if (taskType !== FlowStatusTaskType.Completed) {
        <kendo-grid-column [title]="'AssignedTo' | translate"
                           [media]="'(min-width: ' + (MAX_MOBILE_WIDTH + 1) + 'px)'"
                           [class]="'text-xs'" [headerClass]="'text-xs'">
            <ng-template kendoGridCellTemplate let-dataItem>
                @if (dataItem.TaskAssignedToValues.length > 0) {
                    <bizz-org-chart-select [selection]="dataItem.TaskAssignedToValues"
                                           [readonly]="true"></bizz-org-chart-select>
                }
                @if (taskType == FlowStatusTaskType.Detailed) {
                    <span> {{ dataItem.AssignedTo }}</span>
                }
            </ng-template>
        </kendo-grid-column>
    }
    @if (taskType !== FlowStatusTaskType.Pending) {
        <kendo-grid-column [title]="'CompletedBy' | translate"
                           [media]="'(min-width: ' + (MAX_MOBILE_WIDTH + 1) + 'px)'"
                           class="text-xs">
            <ng-template kendoGridCellTemplate let-dataItem>
                <bizz-flow-status-tasks-grid-completed-by-column
                        [task]="dataItem">
                </bizz-flow-status-tasks-grid-completed-by-column>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [title]="'CompletedOn' | translate"
                           [media]="'(min-width: ' + (MAX_MOBILE_WIDTH + 1) + 'px)'"
                           class="text-xs">
            <ng-template kendoGridCellTemplate let-dataItem>
                @if (dataItem.CompletedOn !== null) {
                    <span>{{ dataItem.CompletedOn | date:'short' }}</span>
                } @else {
                    <span>-</span>
                }
            </ng-template>
        </kendo-grid-column>
    }
    @if (taskType !== FlowStatusTaskType.Detailed) {
        <kendo-grid-column title=" " [media]="'(min-width: ' + (MAX_MOBILE_WIDTH + 1) + 'px)'">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex items-start justify-end gap-1.5 min-h-[36px]">
                    @if (dataItem.ViewAssessments) {
                        <button class="default whitespace-nowrap text-sm leading-4"
                                (click)="showAssessmentDetails.emit(dataItem)">
                            {{ 'ViewAssessments' | translate }}
                        </button>
                    }
                    @if (!dataItem.Autocompleted) {
                        <button class="default whitespace-nowrap text-sm leading-4"
                                (click)="taskDetails.emit(dataItem)">
                            {{ 'Details' | translate }}
                            <bizz-icon iconName="eye" class="h-4 w-4"></bizz-icon>
                        </button>
                    }
                </div>
            </ng-template>
        </kendo-grid-column>
    }
</kendo-grid>