<div (click)="open(notification.ID)" class="group flex w-full flex-col justify-between rounded-md px-3 py-2 cursor-pointer transition-all hover:bg-blue-200/30"
     [class]="{ 'bg-button-disabled/75' : notification.Read == ReadType.Read, 'bg-button-hover/75' : notification.Read != ReadType.Read }">
    <span class="flex flex-row items-center justify-between gap-6">
        <h4 class="truncate font-semibold text-button group-hover:text-blue-400">
            {{notification.Subject | notificationFilterPipe}}
        </h4>
            @if(notification.Read == ReadType.Unread){
                <bizz-icon
                        class="iconHeight text-button hover:text-blue-400"
                        [hover]="true"
                        (click)="read(notification.ID);$event.stopPropagation()"
                        iconName="eye-slash">
                </bizz-icon>
            }@else{
                <bizz-icon
                        class="iconHeight text-button hover:text-blue-400"
                        [hover]="true"
                        (click)="unread(notification.ID);$event.stopPropagation()"
                        iconName="eye">
                </bizz-icon>
            }
  </span>

  <span class="flex flex-row items-center justify-between gap-6">
    <p class="mb-1.5 text-xs text-form-header">{{notification.Body | notificationFilterPipe}}</p>
     <bizz-icon
       *ngIf="notification.Read == ReadType.Unread"
       class="iconHeight invisible text-button"
       [hover]="true"
       iconName="envelope">
    </bizz-icon>
  </span>

  <div class="flex w-full justify-between text-xs">
    <!--<span class="ml-auto">{{dateDifference(notification.Date)}}</span>-->
    <span class="ml-auto text-light">{{calculateDateStrings(notification.Date, language)}}</span>
  </div>
</div>
