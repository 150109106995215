import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { FileHistoryModalModel } from './file-history-modal-model';
import { FileHistoryDto } from 'src/models/ts/file-history-dto.model';
import { CollectionListDataInstance } from 'src/app/shared/interfaces/collection-list-data-instance';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../ui/icon/icon.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { ExtensionIconComponent } from '../../ui/icon/extension-icon/extension-icon.component';
import { UtcDateTimePipe } from 'src/app/shared/pipes/dates/utc-date-time.pipe';
import { BytesPipe } from 'src/app/shared/pipes/bytes/bytes.pipe';
import { ArchiveApiService } from 'src/app/api/bizzmine/archive/archive-api.service';


@Component({
  selector: 'bizz-file-history-modal',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslatePipe, ExtensionIconComponent, UtcDateTimePipe, BytesPipe],
  templateUrl: './file-history-modal.component.html',
  styleUrl: './file-history-modal.component.scss'
})
export class FileHistoryModalComponent {
  protected dataItem: CollectionListDataInstance;
  protected model: FileHistoryDto[];

  public constructor(
      private archiveApiService: ArchiveApiService,
      public dialogRef: DialogRef,
      @Inject(DIALOG_DATA) public fileHistoryData: FileHistoryModalModel,
  ) {
      this.dataItem = fileHistoryData.dataItem;
      this.model = fileHistoryData.history;
  }

  public download ():void {
    this.archiveApiService.downloadFile(this.dataItem);
  }
}
