import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { FlowStatusTaskDto } from '../../../../../../models/ts/flow-status-task-dto.model';
import { Store } from '@ngrx/store';
import { selectUserSettingsFeature } from '../../../../../store/root-state-selectors';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { EditorModule } from '@progress/kendo-angular-editor';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { formsActions } from '../../../../../store/features/forms/forms-actions';
import { DatetimePipe } from 'src/app/shared/pipes/dates/datetime.pipe';

@Component({
  selector: 'bizz-task-instance-dialog',
  standalone: true,
  imports: [
    AsyncPipe,
    EditorModule,
    IconComponent,
    NgTemplateOutlet,
    TooltipModule,
    TranslatePipe,
    DatetimePipe
  ],
  templateUrl: './task-instance-dialog.component.html',
  styleUrl: './task-instance-dialog.component.scss'
})
export class TaskInstanceDialogComponent {
  private items: Array<FlowStatusTaskDto> = [];
  public userSettings = this.store$.selectSignal(selectUserSettingsFeature);
  public myTasks: Array<GroupedFlowStatusTaskDto> = [];
  public foundTasks: Array<GroupedFlowStatusTaskDto> = [];
  public showMyTasks = true;
  public showFoundTasks = true;


  public constructor(@Inject(DIALOG_DATA) public data: {data: Array<FlowStatusTaskDto>, collectionId: number, formId? : string | undefined},
                     private dialogRef: DialogRef,
                     private store$: Store) {

    this.items = data?.data ?? [];
    this.myTasks = this.groupByFlowStatus(this.items.filter((task: FlowStatusTaskDto) => task.AssignedToID === this.userSettings().UserID) ?? []);
    this.foundTasks = this.groupByFlowStatus(this.items.filter((task: FlowStatusTaskDto) => task.AssignedToID !== this.userSettings().UserID) ?? []);

  }

  private groupByFlowStatus = (flowStatus: FlowStatusTaskDto[]): Array<GroupedFlowStatusTaskDto> => {
    const grouped = flowStatus.reduce((acc, flowStatus) => {
      const groupKey = `${flowStatus.StepsName}-${flowStatus.Subject}-${flowStatus.DueDate}`; // Combine keys with "-" and format DueDate

      acc[groupKey] = (acc[groupKey] || []).concat(flowStatus);
      return acc;
    }, {} as Record<string, FlowStatusTaskDto[]>);
    const result: Array<GroupedFlowStatusTaskDto> = [];
    for (const group in grouped) {
      result.push({
        StepsName: grouped[group][0].StepsName,
        Subject: grouped[group][0].Subject,
        Tasks: grouped[group],
        DueDate: grouped[group][0].DueDate
      });
    }
    return result;
  };

  public close(): void {
    this.dialogRef.close();
  }

  public executeTask(task: FlowStatusTaskDto): void {
    this.store$.dispatch(formsActions.executePendingSteps({
      formId: this.data.formId,
      pendingSteps: [task],
      collectionId: this.data.collectionId,
      executorSelected: true
    }));
    this.close();
  }

}

export interface GroupedFlowStatusTaskDto {
  StepsName: string;
  Subject: string;
  DueDate: string | null;
  Tasks: FlowStatusTaskDto[];
}
