export enum ProtectedCollectionType {
	None = 0,
	Users = 1,
	Functions = 2,
	Departments = 3,
	Roles = 4,
	DepartmentDepartments = 5,
	DepartmentFunctions = 6,
	DepartmentUsers = 7,
	FunctionDepartments = 8,
	FunctionFunctions = 9,
	FunctionUsers = 10,
	RoleDepartments = 11,
	RoleFunctions = 12,
	RoleUsers = 13,
	LinkTrainingSkill = 21,
	LinkTrainingExam = 22,
	LinkTrainingDocument = 23,
	LinkDocumentSkill = 24,
	LinkDocumentExam = 25,
	Skills = 26,
	Exams = 27,
	Trainings = 28,
	SkillGrades = 29,
	TrainingSessions = 30,
	LinkTrainingTrainingsessions = 31,
	SkillCategories = 32,
	LinkSkillSkillCategory = 33,
	TrainingCategories = 34,
	LinkTrainingTrainingCategory = 35,
	LinkDocumentTraining = 36,
	TrnDocumentCollection = 37,
	Costs = 38,
	LinkTrainingCosts = 39,
	LinkTrainingSessionCosts = 40,
	Trainers = 41,
	LinkTrainingTrainers = 42,
	TrainingSessionsLessons = 43,
	LinkTrainingSessionLessons = 44,
	LinkTrainingSessionsTrainingsTrainers = 45,
	TrainingAttachments = 46,
	LinkTrainingTrainingAttachments = 47,
	LinkTrainingSessionTrainingAttachments = 48,
	ExamSessions = 49,
	ExamCategories = 50,
	LinkExamsExamCategories = 51,
	LinkExamSessionsExams = 54,
	ExamQuestionsAndAnswers = 55,
	LinkQuestionsAndAnswersExamSession = 56,
	LinkExamsSkills = 57,
	LinkTrainingSessionSkill = 58,
	LinkTrainingSessionExam = 59
}