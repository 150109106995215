<div class="modal-container justify-between md:justify-normal">
  <div class="modal-close-container">
    <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
               (click)="dialogRef.close()"
               iconName="xmark" [hover]="true">
    </bizz-icon>
  </div>
    <div class="modal-body">
        <div class="modal-header">
          <h1>
            {{'RestartWorkflow' | translate}}</h1>
        </div>
        <hr class="my-5" />
        <div class="h-full w-full bg-transparent">
            <label class="mb-2 inline-block bg-transparent text-sm text-default">{{ 'RestartReason' | translate }}
                <span class="ml-0.5 text-red-400">*</span>
            </label>
            <kendo-textarea
                  class="border-form-control !bg-form-control p-1 text-default focus:border-form-control-focus"
                  [rows]="5"
                  resizable="vertical"
                  [(value)]="restartReason"
                  fillMode="solid"
                  rounded="large">
            </kendo-textarea>
      </div>
  </div>
  <div class="modal-buttons">
    <button class="default" (click)="dialogRef.close()">{{'Cancel' | translate }}</button>
    <button class="delete" (click)="restart()" [disabled]="restartReason == ''">
      {{'RestartWorkflow' | translate}}
    </button>
  </div>
</div>
