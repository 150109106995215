import { Component } from '@angular/core';
import { CheckBoxModule } from '@progress/kendo-angular-inputs';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgClass } from '@angular/common';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import {TooltipComponent} from "../../../../../../shared/components/ui/tooltip/tooltip.component";

/**
 * Represent a control that allows the user to select a boolean value with a checkbox.
 */
@Component({
  selector: 'bizz-checkbox-control',
  standalone: true,
    imports: [CheckBoxModule, ReactiveFormsModule, NgClass, AsyncPipe, TooltipComponent],
  templateUrl: './checkbox-control.component.html',
  styleUrls: ['./checkbox-control.component.scss']
})
export class CheckboxControlComponent extends BaseFormControlComponent {
  protected override focus(): void {
    // Add your implementation here
  }
}
