import * as go from 'gojs';

/**
 * Also defined in an extension class of the gojs lib, but i've not found a way to import it
 */
export class FlowStatusShapes {

  private static DEFAULT_CORNER_RADIUS = 10;

  private static getCornerRadius(shape: go.Shape, w: number, h: number): number {
    let p1 = FlowStatusShapes.DEFAULT_CORNER_RADIUS;  // default corner size
    if (shape !== null) {
      const param1 = shape.parameter1;
      if (!isNaN(param1) && param1 >= 0)
        p1 = param1;  // can't be negative or NaN
    }
    p1 = Math.min(p1, w / 2);
    p1 = Math.min(p1, h / 3);  // limit by whole height or by half height?
    return p1;
  }

  /**
   * Defines custom shapes for rectangles with rounded corners
   * Overwrite the radius with parameter1
   */
  public static defineCustomRoundShapes(): void {
    go.Shape.defineFigureGenerator('RoundedTopRectangle', (shape, w, h) => {
      const cRad = FlowStatusShapes.getCornerRadius(shape, w, h);
      const geo = new go.Geometry();
      // a single figure consisting of straight lines and quarter-circle arcs
      geo.add(new go.PathFigure(0, cRad)
        .add(new go.PathSegment(go.PathSegment.Arc, 180, 90, cRad, cRad, cRad, cRad))
        .add(new go.PathSegment(go.PathSegment.Line, w - cRad, 0))
        .add(new go.PathSegment(go.PathSegment.Arc, 270, 90, w - cRad, cRad, cRad, cRad))
        .add(new go.PathSegment(go.PathSegment.Line, w, h))
        .add(new go.PathSegment(go.PathSegment.Line, 0, h).close()));
      // don't intersect with two top corners when used in an "Auto" Panel
      geo.spot1 = new go.Spot(0, 0, 0.3 * cRad, 0.3 * cRad);
      geo.spot2 = new go.Spot(1, 1, -0.3 * cRad, 0);
      return geo;
    });
    go.Shape.defineFigureGenerator('RoundedBottomRectangle', (shape, w, h) => {
      // this figure takes one parameter, the size of the corner
      const cRad = FlowStatusShapes.getCornerRadius(shape, w, h);
      const geo = new go.Geometry();
      // a single figure consisting of straight lines and quarter-circle arcs
      geo.add(new go.PathFigure(0, 0)
        .add(new go.PathSegment(go.PathSegment.Line, w, 0))
        .add(new go.PathSegment(go.PathSegment.Line, w, h - cRad))
        .add(new go.PathSegment(go.PathSegment.Arc, 0, 90, w - cRad, h - cRad, cRad, cRad))
        .add(new go.PathSegment(go.PathSegment.Line, cRad, h))
        .add(new go.PathSegment(go.PathSegment.Arc, 90, 90, cRad, h - cRad, cRad, cRad).close()));
      // don't intersect with two bottom corners when used in an "Auto" Panel
      geo.spot1 = new go.Spot(0, 0, 0.3 * cRad, 0);
      geo.spot2 = new go.Spot(1, 1, -0.3 * cRad, -0.3 * cRad);
      return geo;
    });
    go.Shape.defineFigureGenerator('RoundedLeftRectangle', function(shape, w, h) {
      const cRad = FlowStatusShapes.getCornerRadius(shape, w, h);
      const geo = new go.Geometry();
      geo.add(new go.PathFigure(w, 0)
        .add(new go.PathSegment(go.PathSegment.Line, w, h))
        .add(new go.PathSegment(go.PathSegment.Line, cRad, h))
        .add(new go.PathSegment(go.PathSegment.Arc, 90, 90, cRad, h - cRad, cRad, cRad))
        .add(new go.PathSegment(go.PathSegment.Line, 0, cRad))
        .add(new go.PathSegment(go.PathSegment.Arc, 180, 90, cRad, cRad, cRad, cRad).close()));
      // don't intersect with two top corners when used in an "Auto" Panel
      geo.spot1 = new go.Spot(0, 0, 0.3 * cRad, 0.3 * cRad);
      geo.spot2 = new go.Spot(1, 1, -0.3 * cRad, 0);
      return geo;
    });
    go.Shape.defineFigureGenerator('RoundedRightRectangle', function(shape, w, h) {
      const p1 = FlowStatusShapes.getCornerRadius(shape, w, h);
      const geo = new go.Geometry();
      geo.add(new go.PathFigure(0, 0)
        .add(new go.PathSegment(go.PathSegment.Line, w - p1, 0))
        .add(new go.PathSegment(go.PathSegment.Arc, 270, 90, w - p1, p1, p1, p1))
        .add(new go.PathSegment(go.PathSegment.Line, w, h - p1))
        .add(new go.PathSegment(go.PathSegment.Arc, 0, 90, w - p1, h - p1, p1, p1))
        .add(new go.PathSegment(go.PathSegment.Line, 0, h).close()));
      // don't intersect with two bottom corners when used in an "Auto" Panel
      geo.spot1 = new go.Spot(0, 0, 0.3 * p1, 0);
      geo.spot2 = new go.Spot(1, 1, -0.3 * p1, -0.3 * p1);
      return geo;
    });
    go.Shape.defineFigureGenerator('RoundedTopRightRectangle', function(shape, w, h) {
      const cRad = FlowStatusShapes.getCornerRadius(shape, w, h);
      const geo = new go.Geometry();
      // a single figure consisting of straight lines and quarter-circle arcs
      geo.add(new go.PathFigure(0, 0)
        .add(new go.PathSegment(go.PathSegment.Line, w - cRad, 0))
        .add(new go.PathSegment(go.PathSegment.Arc, 270, 90, w - cRad, cRad, cRad, cRad))
        .add(new go.PathSegment(go.PathSegment.Line, w, h))
        .add(new go.PathSegment(go.PathSegment.Line, 0, h).close()));
      // don't intersect with two bottom corners when used in an "Auto" Panel
      geo.spot1 = new go.Spot(0, 0, 0.3 * cRad, 0);
      geo.spot2 = new go.Spot(1, 1, -0.3 * cRad, -0.3 * cRad);
      return geo;
    });
    go.Shape.defineFigureGenerator('RoundedTopLeftRectangle', function(shape, w, h) {
      const cRad = FlowStatusShapes.getCornerRadius(shape, w, h);
      const geo = new go.Geometry();
      geo.add(new go.PathFigure(cRad, 0)
        .add(new go.PathSegment(go.PathSegment.Line, w, 0))
        .add(new go.PathSegment(go.PathSegment.Line, w, h))
        .add(new go.PathSegment(go.PathSegment.Line, 0, h))
        .add(new go.PathSegment(go.PathSegment.Line, 0, cRad))
        .add(new go.PathSegment(go.PathSegment.Arc, 180, 90, cRad, cRad, cRad, cRad).close()));
      // don't intersect with two top corners when used in an "Auto" Panel
      geo.spot1 = new go.Spot(0, 0, 0.3 * cRad, 0.3 * cRad);
      geo.spot2 = new go.Spot(1, 1, -0.3 * cRad, 0);
      return geo;
    });
    go.Shape.defineFigureGenerator('RoundedBottomRightRectangle', function(shape, w, h) {
      const cRad = FlowStatusShapes.getCornerRadius(shape, w, h);
      const geo = new go.Geometry();
      // a single figure consisting of straight lines and quarter-circle arcs
      geo.add(new go.PathFigure(0, 0)
        .add(new go.PathSegment(go.PathSegment.Line, w, 0))
        .add(new go.PathSegment(go.PathSegment.Line, w, h - cRad))
        .add(new go.PathSegment(go.PathSegment.Arc, 0, 90, w - cRad, h - cRad, cRad, cRad))
        .add(new go.PathSegment(go.PathSegment.Line, 0, h).close()));
      // don't intersect with two bottom corners when used in an "Auto" Panel
      geo.spot1 = new go.Spot(0, 0, 0.3 * cRad, 0);
      geo.spot2 = new go.Spot(1, 1, -0.3 * cRad, -0.3 * cRad);
      return geo;
    });
  }
}