import { table } from 'console';
import { TableFieldDataType } from 'src/models/ts/table-field-data-type.model';
import { FormMailingListComponent } from '../components/controls/form-mailing-list/form-mailing-list.component';

export function fieldDataTypeIsFocusable(tableFieldDataType: TableFieldDataType): boolean {
  // Check if the field is focusable
  return tableFieldDataType === TableFieldDataType.AlphaNumeric ||
  tableFieldDataType === TableFieldDataType.Numeric ||
  tableFieldDataType === TableFieldDataType.Email ||
  tableFieldDataType === TableFieldDataType.DatePicker ||
  tableFieldDataType === TableFieldDataType.TimePicker ||
  tableFieldDataType === TableFieldDataType.DateTimePicker ||
  tableFieldDataType === TableFieldDataType.Memo ||
  tableFieldDataType === TableFieldDataType.Combobox ||
  tableFieldDataType === TableFieldDataType.OrganizationChartUnitSelector ||
  tableFieldDataType === TableFieldDataType.HyperLink ||
  tableFieldDataType === TableFieldDataType.OrganizationChartUnitSelectorForTrnApp ||
  tableFieldDataType === TableFieldDataType.EnumList;

}