import { Component, DestroyRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { MediaType } from 'src/models/ts/media-type.model';
import { ExtensionIconComponent } from '../../../ui/icon/extension-icon/extension-icon.component';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { CollectionListApiService } from 'src/app/api/bizzmine/collection-list/collection-list-api.service';
import { BehaviorSubject, filter, take, tap } from 'rxjs';
import { ReportSettings } from 'src/models/ts/report-settings.model';
import { WordReports } from './word-reports.interface';
import { LoaderComponent } from '../../../ui/loader/loader.component';
import { CompleteStepMessageCallBackState } from 'src/models/ts/complete-step-message';
import { SignalRService } from 'src/app/core/signalR/signal-r.service';
import { HttpResponse } from '@angular/common/http';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { AlertService } from 'src/app/features/bizzmine/alerts/alert.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface GenerateWordReportRequest {
  ReportGuid: string;
}

@Component({
  selector: 'bizz-from-word-template',
  standalone: true,
  imports: [
    TranslatePipe,
    ExtensionIconComponent,
    DropDownListModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    LoaderComponent

  ],
  templateUrl: './from-word-template.component.html',
  styleUrl: './from-word-template.component.scss'
})
export class FromWordTemplateComponent implements OnInit {
  public readonly MediaType = MediaType;
  public selectedFormat: MediaType | null;
  public activeReportDetails: WordReports;
  public isLoading: boolean = true;
  @Input() public isGenerating: boolean = false;
  @Input() public submit: BehaviorSubject<boolean>;
  @Output() public onError = new EventEmitter<void>();
  @Output() public onSelectedFormat = new EventEmitter<MediaType | null>();

  public reportForm: FormGroup;
  public reports: WordReports[];
  public constructor(public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: { collectionId: number, instanceId: number, versionId: number, listId: number, folderId: number, reportSettings: ReportSettings },
    private fb: FormBuilder,
    private collectionListApiService: CollectionListApiService,
    private signalRService: SignalRService,
    private downloadService: DownloadService,
    private alertService: AlertService,
    private destroyRef: DestroyRef
  ) {


  }

  public ngOnInit(): void {
    this.submit.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (submit: boolean) => {
        if (submit) {
          this.generateWordReport();
        }
      }
    })
    this.collectionListApiService.wordReports(this.data.reportSettings.WordTemplateReportSettings.TemplateCollectionsID)
      .pipe(take(1))
      .subscribe({
        next: (data: WordReports[]) => {

          if (data?.length) {
            this.reports = data;
            this.activeReportDetails = data[0];
            this.reportForm = this.buildForm(this.activeReportDetails);
            if (!(this.activeReportDetails.AllowPdfReport && this.activeReportDetails.AllowDocxReport)) {
              this.selectedFormat = this.checkIfAllowedFormat(MediaType.WordReportDocx) ? MediaType.WordReportDocx : MediaType.WordReportPdf;
              this.onSelectedFormat.emit(this.selectedFormat);
            }
            this.isLoading = false;
          } else {
            //No reports
            this.isLoading = false;
          }
        }
      })
  }

  private buildForm(data: WordReports): FormGroup {
    return this.fb.group({
      ActiveReport: new FormControl(data.FileName),
      CanPrint: new FormControl(data.Security.CanPrint),
      CanEdit: new FormControl(data.Security.CanEdit),
      CanCopy: new FormControl(data.Security.CanCopy),
    });
  }

  public setActiveReport(report: WordReports): void {
    this.activeReportDetails = report;
    this.reportForm = this.buildForm(report);
    if (!(this.activeReportDetails.AllowPdfReport && this.activeReportDetails.AllowDocxReport)) {
      this.selectedFormat = this.checkIfAllowedFormat(MediaType.WordReportDocx) ? MediaType.WordReportDocx : MediaType.WordReportPdf;
    } else {
      this.selectedFormat = null;
    }
    this.onSelectedFormat.emit(this.selectedFormat);
  }

  public checkIfAllowedFormat(format: MediaType): boolean {
    return this.activeReportDetails.AllowPdfReport && format === MediaType.WordReportPdf ||
      this.activeReportDetails.AllowDocxReport && format === MediaType.WordReportDocx;
  }

  public setSelectedFormat(mediaType: MediaType): void {
    this.selectedFormat = mediaType;
    this.onSelectedFormat.emit(this.selectedFormat);
  }

  public generateWordReport(): void {
    //set the values of the form to the active report details 
    this.activeReportDetails.Security.CanPrint = this.reportForm.value.CanPrint;
    this.activeReportDetails.Security.CanEdit = this.reportForm.value.CanEdit;
    this.activeReportDetails.Security.CanCopy = this.reportForm.value.CanCopy;
    //start conversion if data is selected
    this.collectionListApiService.generateWordReport(this.data.collectionId, this.data.instanceId, this.data.versionId, this.activeReportDetails).subscribe({
      next: (guid: string) => {
        this.listenOncallBackMessage(guid);
      }
    })
  }

  public listenOncallBackMessage(guid: string): void {
    this.signalRService
      .callBackMessageWithRequest<GenerateWordReportRequest, void>('GenerateWordReportRequest')
      .pipe(
        filter((update) => update.request.ReportGuid == guid),
        take(1),
        tap((update) => {
          switch (update.state) {
            case CompleteStepMessageCallBackState.Success:
              if (this.selectedFormat != null) {
                this.collectionListApiService.downloadReport(this.data.collectionId, this.data.instanceId, this.data.versionId, guid, this.selectedFormat, this.activeReportDetails)
                  .subscribe({
                    next: (data: HttpResponse<ArrayBuffer>) => {
                      if (data && data.body) {
                        this.downloadService.startDownload(data.body, data.headers.get('x-filename') ?? '');
                      }
                    }
                  });
                this.isLoading = false;
                this.dialogRef.close();
              }else{
                this.alertService.setAlert({
                  type: 'warning',
                  title: 'Please select a format.',
                  content: [],
                  icon: 'exclamation-triangle',
                  dismissable: true,
                  timed: true,
                  timer: 3000,
                });
              }
              break;
            case CompleteStepMessageCallBackState.Error:
              this.alertService.setAlert({
                type: 'error',
                title: 'Error while generating the report. Please try again later.',
                content: [],
                icon: 'exclamation-triangle',
                dismissable: true,
                timed: true,
                timer: 3000,
              });
              this.isLoading = false;
              this.onError.emit();
              break;
            default:
              break;
          }
        })
      ).subscribe();
  }
}
