/* eslint-disable @typescript-eslint/no-var-requires */
export const environment = {
  appVersion: '6.0.' + require('../../package.json').version.substring(0, 4),
  debug: false,
  production: true,
  port: process.env["NG_APP_PORT"] ?? "",
  deploymentType: process.env["NG_APP_DEPLOYMENT_TYPE"] ?? "",
  idpUri: process.env["NG_APP_IDENTITY_PROVIDER_URI"] ?? "",
  legacyUri: process.env["NG_APP_LEGACY_URI"] ?? "",
  apiUri: process.env["NG_APP_API_URI"] ?? "",
  apiAiUri: process.env["NG_APP_API_AI_URI"] ?? "",
  tenant: process.env["NG_APP_TENANT"] ?? "",
  sentryDsn: process.env["NG_APP_SENTRY_DSN"] ?? "",
  sentryTraceSampleRate: process.env["NG_APP_SENTRY_TRACE_SAMPLE_RATE"] ?? "",
  sentryReplaySampleRate: process.env["NG_APP_SENTRY_REPLAY_SAMPLE_RATE"] ?? "",
  sentryReplayErrorsSampleRate: process.env["NG_APP_SENTRY_REPLAY_ERRORS_SAMPLE_RATE"] ?? "",
  sentryTracePropagationTargets: process.env["NG_APP_SENTRY_TRACE_PROPAGATION_TARGETS"] ?? "",
  sentryEnabled: process.env["NG_APP_SENTRY_ENABLED"] ?? "false",
  gojsLicenseKey: process.env["NG_APP_GOJS_LICENSE_KEY"] ?? "",
};
