import { Injectable, signal } from '@angular/core';
import { AlertOptions } from './classes/alert-options';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public alerts = signal<Array<AlertOptions>>([]);

  constructor() { }

  public setAlert(alert: AlertOptions): void {
    const newAlert = new AlertOptions(alert);
    this.alerts.update(alerts => [...alerts, newAlert]);
  }

  public dismissAlert(alertId: string): void {
    this.alerts.set(this.alerts().filter((alert)=> alert.id !== alertId));
  }

  public dismissAlertType(alertType: 'success' | 'error' | 'warning' | 'info'): void {
    this.alerts.set(this.alerts().filter((alert)=> alert.type !== alertType));
  }

  public clearAlerts(): void {
    this.alerts.set([]);
  }
}
