import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';

/**
 * Displays a font awesome arrow based on the given direction.
 */
@Component({
  selector: 'bizz-toggle-arrow',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './toggle-arrow.component.html',
  styleUrls: ['./toggle-arrow.component.scss'],
})
export class ToggleArrowComponent {

  @Input({ required: true }) public direction: 'up' | 'down' = 'down';
}
