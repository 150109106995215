import { Component, Inject } from '@angular/core';
import { TranslatePipe } from "../../../../pipes/translate/translate.pipe";
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { IconComponent } from "../../../ui/icon/icon.component";
import { ThemeSelectorModalModel } from './classes/theme-selector-modal-model';
import { ThemePreviewIconFullComponent } from "./components/theme-preview-icon-full/theme-preview-icon-full.component";
import { ThemeDto } from 'src/models/ts/theme-dto.model';
import { ThemeService } from 'src/app/core/services/theme/theme.service';
import { ThemePreviewIconSimpleComponent } from "./components/theme-preview-icon-simple/theme-preview-icon-simple.component";

@Component({
    selector: 'bizz-theme-selector-modal',
    standalone: true,
    templateUrl: './theme-selector-modal.component.html',
    styleUrl: './theme-selector-modal.component.scss',
    imports: [TranslatePipe, IconComponent, ThemePreviewIconFullComponent, ThemePreviewIconSimpleComponent]
})
export class ThemeSelectorModalComponent {
  public themes: ThemeDto[] = [];
  public selectedTheme?: ThemeDto = undefined;
  public showFullPreviewIcons: boolean = false;
  public constructor(public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public modelData: ThemeSelectorModalModel,
    private readonly themeService: ThemeService) {
      this.dialogRef.disableClose = true;
      this.themes = modelData.themes;
      this.selectedTheme = modelData.currentTheme;
      this.showFullPreviewIcons = modelData.showFullPreviewIcons;
      this.modelData.applyStyle = modelData.applyStyle;
  }

  public changeTheme(theme: ThemeDto): void {
    if (theme && this.modelData.applyStyle) {
      this.themeService.applyCssThemeAndBranding(theme);
    }
  }

  public onThemeSelected(theme:ThemeDto):void {
    this.selectedTheme = theme;
    this.changeTheme(theme);
  }

  public closeModal(event: MouseEvent, theme?: ThemeDto): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close(theme);
  }
}
