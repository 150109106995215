import { ChangeDetectorRef, Directive, EventEmitter, inject, Input, Output } from '@angular/core';
import { DynamicCellComponent } from '../grid-cell/grid-cell.component';
import { CellActionType } from '../../../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';
import { CellActionData } from '../../../../../features/bizzmine/widgets/collection-list-widget/interfaces/cell-action-data';
import { CollectionListDataInstanceProperties } from '../../../../interfaces/collection-list-data-instance';
import { GridColumnBase } from 'src/app/shared/classes/list/grid-column-base';
import { GridContextPermissionsMetadata } from '../../../../../features/bizzmine/widgets/collection-list-widget/interfaces/grid-context-permissions-metadata';

/**
 * Abstract cell class defining the common inputs of a cell.
 */
@Directive()
export abstract class GridCellBaseComponent<T> implements DynamicCellComponent<T> {

  public changeDetectorRef = inject(ChangeDetectorRef);

  @Input() public column: GridColumnBase;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _data: CollectionListDataInstanceProperties & { [key: string]: T; } | unknown;

  @Input({ required: true })
  public set data(value: CollectionListDataInstanceProperties & { [key: string]: T; }) {
    this._data = value;
    this.onDataChanged();
  }

  public get data(): CollectionListDataInstanceProperties & { [key: string]: T; } {
    return this._data as CollectionListDataInstanceProperties & { [key: string]: T; };
  }

  @Input() public value: T | undefined;
  @Input() public metaData: GridContextPermissionsMetadata;

  @Output() public onCellAction = new EventEmitter<{ action: CellActionType, data: CellActionData, mouseEvent?: MouseEvent }>();

  protected onDataChanged(): void {
  }

}