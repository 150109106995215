@if (iconLibrary == IconLibrary.FontAwesome || iconLibrary == 'FontAwesome') {
    <!-- <svg class="block h-full w-full fill-current" [ngClass]="{'cursor-pointer': hover}">
        <use [attr.href]="'../../../../../assets/icons/fontawesome/sprites/' + iconStyle + '.svg#' + iconName"/>
    </svg> -->
    <i class="block fa-{{iconStyle}} fa-{{iconName}} bizzIcon" 
    [ngStyle]="{'font-style': '100%'}"
     [ngClass]="{'cursor-pointer': hover}"></i>
} @else {
    <svg class="block h-full w-full fill-current" [ngClass]="{'cursor-pointer': hover}">
        <use [attr.href]="'../../../../../assets/icons/svg/' + (iconLibrary | iconLibName) + '.svg#' + iconName"/>
    </svg>
}