import moment from 'moment-timezone';

/**
 * Function for calculating how much time is between two dates.
 */

/*export function dateDifference(date: string): string {
  const otherDate = new Date(date);
  const today = new Date();
  const diffTime = Math.abs(today.getTime() - otherDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
  const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
  const diffMonths = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 30));
  const diffWeeks = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 7));

  let dateString = '';
  if (diffYears > 0) {
    dateString = `${diffYears} year(s) ago`;
  } else if (diffMonths > 0) {
    const remainingDays = diffDays - (diffMonths * 30);
    const roundedMonths = remainingDays >= 15 ? diffMonths + 1 : diffMonths;
    dateString = `${roundedMonths} month(s) ago`;
  } else if (diffWeeks > 0) {
    dateString = `${diffWeeks} week(s) ago`;
  } else if (diffDays > 0) {
    dateString = `${diffDays} day(s) ago`;
  } else if (diffHours > 0) {
    dateString = `${diffHours} hour(s) ago`;
  } else {
    dateString = `${diffMinutes} minute(s) ago`;
  }
  return dateString;
}*/


export function calculateDateStrings (popupItem: string, language: string): string{
  moment.locale(language);
  const currentDateTime = moment();
  const dateTime = moment(popupItem);
  popupItem = currentDateTime.to(dateTime);

  return popupItem;
}
