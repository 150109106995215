@if (formFieldSignal(); as formField) {
    @if(errorState?.Message; as errorMessage){
        <div class="absolute right-4 top-0 z-[1] flex h-full items-center justify-center">
            <bizz-tooltip class="cursor-pointer text-red-400" [text]="errorMessage"></bizz-tooltip>
        </div>
    }
    <input #input class="h-10"
           [ngClass]="{'hidden' : formField.IsHidden}"
           [class.error]="errorState != null"
           [readOnly]="readOnlyOverride || formField.IsReadOnly"
           [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0"
           [formControl]="formControl"
           id="{{formField.Id}}"
           placeholder="{{formField.Placeholder}}"
           type="text">
}