import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TrnExamDto } from '../../../../models/ts/trn-exam-dto.model';
import { TrnExamQuestion } from '../../../../models/ts/trn-exam-question.model';
import { AuthService } from '../../../core/services/auth/auth.service';
import { TrnUserSkillGradeDto } from '../../../../models/ts/trn-user-skill-grade-dto.model';
import { TrainingDto } from 'src/models/ts/training-dto.model';
import { TrainingAppQueueResult } from '../../../../models/ts/training-app-queue-result.model';
import { ListDesignGridOptionsDto } from '../../../../models/ts/list-design-grid-options-dto.model';
import { GridDataDto } from '../../../../models/ts/grid-data-dto.model';
import { GridSearchFilterSearchDto } from '../../../../models/ts/grid-search-filter-search-dto.model';
import { TrnTrainingQueuePermissionsDataDto } from '../../../../models/ts/trn-training-queue-permissions-data-dto.model';
import { OrgChartItem } from '../../../features/bizzmine/org-chart/interfaces/org-chart-item';
import { TrnSkillGradeDto } from '../../../../models/ts/trn-skill-grade-dto.model';

@Injectable({
  providedIn: 'root'
})
export class TrainingApiService {
  public isLoading$ = new BehaviorSubject<boolean>(false);
  private baseUrl = 'api/trainingapps';

  public constructor(private http: HttpClient, private authService: AuthService) {
  }

  public getExamDetails(request: { collectionsID: number, versionsID: number }): Observable<TrnExamDto> {
    return this.enableLoader(this.http.get<TrnExamDto>(`${this.baseUrl}/collectionsid/${request.collectionsID}/exam/${request.versionsID}`));
  }

  public getTraining(request: {
    collectionId: number,
    instanceId: number,
    versionId: number
  }) : Observable<TrainingDto>
  {
    return this.http.get<TrainingDto>(`api/trainingapps/collectionsid/${request.collectionId}/training/${request.instanceId}/version/${request.versionId}`)
  }
  public getTrainees(request: {trainingId: number, trainingAppId: number}) : Observable<Array<OrgChartItem>>{
    return this.http.get<Array<OrgChartItem>>(`api/trainingapps/${request.trainingAppId}/training/${request.trainingId}/gettrainnees`);
  }

  public getTrainingForWidget(request: {
    trainingAppId: number,
    version: number
  }) : Observable<TrainingDto>{
    return this.http.get<TrainingDto>(`api/trainingapps/${request.trainingAppId}/training/version/${request.version}`);
  }

  public addUsersToTrainingSession(intent : {
    users: Array<number>,
    trainingAppId: number,
    trainingId: number,
    sessionId: number
  }) : Observable<void>{
    return this.http.post<void>(`api/trainingapps/${intent.trainingAppId}/training/${intent.trainingId}/sessions/${intent.sessionId}/users`, intent.users)
  }

  public restoreQueueItem(intent: {
    trainingAppId: number,
    id: number
  }) : Observable<void>{
    return this.http.post<void>(`api/trainingapps/${intent.trainingAppId}/queue/restore`, [intent.id]);
  }

  public removeQueueItem(intent: {
    trainingAppId: number,
    id: number
  }) : Observable<void>{
    return this.http.post<void>(`api/trainingapps/${intent.trainingAppId}/queue/remove`, [intent.id]);
  }

  public manualExamEnrollment(intent: { trainingAppID: number, versionsID: number }): Observable<number> {
    return this.enableLoader(this.http.post<number>(`${this.baseUrl}/${intent.trainingAppID}/manualexamenrollment/${intent.versionsID}`, undefined));
  }

  public isExamReady(request: { trainingAppID: number, versionsID: number }): Observable<boolean> {
    return this.enableLoader(this.http.get<boolean>(`${this.baseUrl}/${request.trainingAppID}/isexamready/${request.versionsID}`));
  }

  public hasAiLicense(): Observable<boolean> {
    return this.enableLoader(this.http.get<boolean>(`${this.baseUrl}/ailicense`));
  }

  public getGeneratedQuestionsWithAi(intent: {
    collectionsId: number,
    examVersionId: number,
    examName: string,
    amountOfQuestions: number,
    amountOfAnswers: number
  }): Observable<Array<TrnExamQuestion>> {
    return this.enableLoader(
      this.http.get<Array<TrnExamQuestion>>('api/ai/exambuilder?collectionsID=' + intent.collectionsId + '&examVersionsID=' + intent.examVersionId + '&subject=' + intent.examName + '&amountOfQuestions=' + intent.amountOfQuestions + '&amountOfAnswers=' + intent.amountOfAnswers)
    );
  }

  public getTrainingAppQueueOptions(request: {
    trainingAppId: number
  }) : Observable<ListDesignGridOptionsDto>{
    return this.http.get<ListDesignGridOptionsDto>(`api/trainingapps/${request.trainingAppId}/queue/options`);

  }

  public getTrainingAppQueueData(request: {
    trainingAppId: number,
    query: GridSearchFilterSearchDto
  }) : Observable<GridDataDto>{
    return this.http.post<GridDataDto>(`api/trainingapps/${request.trainingAppId}/queue/0`, request.query);

  }

  public getTrainingAppPermissions(request: {
    trainingAppId: number,
    widgetId: number
  }) : Observable<TrnTrainingQueuePermissionsDataDto>{
    return this.http.post<TrnTrainingQueuePermissionsDataDto>(`api/trainingapps/${request.trainingAppId}/gettrainingqueuepermissions/${request.widgetId}`,null);

  }

  public getTrainee(request: {
    trainingAppId : number,
    skillVersionId: number,
    userId: number
  }) : Observable<TrnUserSkillGradeDto>{
    return this.enableLoader(this.http.get<TrnUserSkillGradeDto>(`api/trainingapps/${request.trainingAppId}/skill/${request.skillVersionId}/user/${request.userId}`));
  }

  public getSkillGrades(request: {
    trainingAppId : number,
  }) : Observable<Array<TrnSkillGradeDto>>{
    return this.enableLoader(this.http.get<Array<TrnSkillGradeDto>>(`api/trainingapps/${request.trainingAppId}/skillgrades`));
  }


  public getExam(request: {
    trainingAppID: number,
    versionsID: number,
    createIfNonExist: boolean
  }): Observable<TrnExamDto> {
    return this.enableLoader(this.http.get<TrnExamDto>(`${this.baseUrl}/getExam/${request.trainingAppID}/${request.versionsID}/${request.createIfNonExist ? 'true' : 'false'}`));
  }

  public commitQuestionWithAnswer(intent: { trainingAppID: number, versionsID: number, question: TrnExamQuestion }) {
    return this.enableLoader(this.http.post<TrnExamQuestion>(`${this.baseUrl}/${intent.trainingAppID}/examsession/${intent.versionsID}`, intent.question));
  }

  public subscribeToTraining(intent : {
    trainingAppId: number,
    trainingId: number,
    sessionId: number
  }) : Observable<TrainingAppQueueResult> {
    return this.http.post<TrainingAppQueueResult>(`api/trainingapps/${intent.trainingAppId}/addselftoqueue/${intent.trainingId}/preferredsession/${intent.sessionId}`, null);
  }

  private enableLoader<T>(observable: Observable<T>): Observable<T> {
    this.isLoading$.next(true);
    return observable.pipe(
      finalize(() => {
        this.isLoading$.next(false);
      }),
      // explicitly mapping to type T only because lint gives error if i dont -.-
      map((value) => <T>(value))
    );
  }
}