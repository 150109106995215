import { Component, Inject } from '@angular/core';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { FilterMenuModule } from '@progress/kendo-angular-grid';
import { IconComponent } from '../../../../shared/components/ui/icon/icon.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TranslatePipe } from '../../../../shared/pipes/translate/translate.pipe';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { TrainingDto } from '../../../../../models/ts/training-dto.model';
import { DatetimePipe } from '../../../../shared/pipes/dates/datetime.pipe';
import { TimePipe } from '../../../../shared/pipes/dates/time.pipe';
import { UtcDatePipe } from '../../../../shared/pipes/dates/utc-date.pipe';
import { SafePipe } from 'safe-pipe';
import { Store } from '@ngrx/store';
import { formsActions } from '../../../../store/features/forms/forms-actions';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs';
import { ProtectedFieldType } from '../../../../../models/ts/protected-field-type.model';
import { selectFormFieldByPredicate } from '../../../../store/features/forms/forms-selectors';

;

@Component({
  selector: 'bizz-training-subscribe-modal',
  standalone: true,
  imports: [
    AsyncPipe,
    FilterMenuModule,
    IconComponent,
    NgTemplateOutlet,
    TooltipModule,
    TranslatePipe,
    DatetimePipe,
    TimePipe,
    UtcDatePipe,
    SafePipe,
    NgClass
  ],
  providers: [UtcDatePipe],
  templateUrl: './training-subscribe-modal.component.html',
  styleUrl: './training-subscribe-modal.component.scss'
})
export class TrainingSubscribeModalComponent {

  public selectedSession: TrainingDto | null = null;
  public TrainingSubscribeModalComponentMode = TrainingSubscribeModalComponentMode;

  public constructor(@Inject(DIALOG_DATA) public data: {
                       data: TrainingDto,
                       enableNoPreference: boolean,
                       enableCreateNewSession: boolean,
                       mode: TrainingSubscribeModalComponentMode,
                       trainingSessionsCollectionsId?: number,
                       checkedUsers?: any[]
                     },
                     public dialogRef: DialogRef,
                     private datePipe: UtcDatePipe,
                     private store$: Store,
                     private actions$: Actions) {
  }

  public isSameDate(date1: Date, date2: Date): boolean {
    return this.datePipe.transform(date1) === this.datePipe.transform(date2);
  }

  public close(): void {
    this.dialogRef.close(this.data.enableNoPreference ? this.selectedSession : this.selectedSession ?? undefined);
  }

  public createNewSession(): void {
    if (this.data.trainingSessionsCollectionsId) {
      this.actions$.pipe(ofType(formsActions.formFetched), take(1)).subscribe(({ form }) => {
        const trainingNameField = this.store$.selectSignal(selectFormFieldByPredicate(form.id,formField =>formField.ProtectedFieldType == ProtectedFieldType.TrainingName))();
        if(trainingNameField != undefined){
          this.store$.dispatch(formsActions.lookupChanged({
            formId: form.id,
            lookupFieldId: trainingNameField.Id,
            lookupItem: {
              Text: this.data.data.Name,
              CrossLinkInstancesID: this.data.data.CrossLinkCollectionsIDToTrainingSessions,
              InstancesID: this.data.data.InstancesID,
              VersionsID: this.data.data.ID,
              OriginalChildInstancesID: this.data.data.InstancesID,
              LinkedToParentVersions: [],
              GuidChecksum: undefined
            }
          }))
        }
        this.close();
      });
      this.store$.dispatch(formsActions.getFormByCollectionId({ collectionId: this.data.trainingSessionsCollectionsId,
      addToViewStack: true }));
    }
  }
}

export enum TrainingSubscribeModalComponentMode {
  Subscribe,
  Queue
}


