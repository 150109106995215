import { inject, Injectable } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { NotificationDetailDto } from '../../../../../models/ts/notification-detail-dto.model';
import { HeaderNotificationDetailModalComponent } from '../../../components/modals/header/header-notification-detail/header-notification-detail-modal.component';
import { HeaderNotificationDetailModalData } from '../../../components/modals/header/header-notification-detail/header-notification-detail-modal-data';
import { headerNotificationsActions } from '../../../../store/features/header-notifications/header-notifications-actions';
import { HeaderPopupBaseService } from '../header-popup-base-service';
import { NotificationApiService } from '../../../../api/bizzmine/notification/notification-api.service';
import { take } from 'rxjs';

//TODO: this service is also used for opening notifications with detail-button in notifications-overview

/**
 * Header Notification Service is used for handling the opening of notification detail component.
 */

@Injectable({
  providedIn: 'root'
})
export class HeaderNotificationsService extends HeaderPopupBaseService {

  protected fetchAction = headerNotificationsActions.fetchNotifications();
  private notificationApiService = inject(NotificationApiService);
  private dialog = inject(Dialog);

  public openNotification(id: number): void {
    this.notificationApiService.getNotification(id).pipe(take(1)).subscribe({
      next: notification => {
        const dialogRef = this.dialog.open<NotificationDetailDto>(HeaderNotificationDetailModalComponent, { data: { notificationDetail: notification } as HeaderNotificationDetailModalData });

        dialogRef.closed.subscribe({
          next: () => {
            this.readNotification(id);
          }
        });
      }
    });
  }

  public readNotification(id: number): void {
    this.store$.dispatch(headerNotificationsActions.readNotification({ id }));
  }

  public refreshNotifications(): void {
    this.store$.dispatch(headerNotificationsActions.fetchNotifications());
  }
}
