import { Languages } from '../enums/languages';

export const LANGUAGE_LOCALE_IDS: { [language: number]: string } = {
  [Languages.English]: 'en',
  [Languages.Dutch]: 'nl',
  [Languages.French]: 'fr',
  [Languages.Swedish]: 'sv',
  [Languages.Lithuanian]: 'lt',
  [Languages.German]: 'de',
  [Languages.Portuguese]: 'pt',
  [Languages.Italian]: 'it',
  [Languages.Spanish]: 'es',
  [Languages.Polish]: 'pl',
  [Languages.Russian]: 'ru',
  [Languages.Turkish]: 'tr',
  [Languages.Chinese]: 'zh-cn',
  [Languages.Czech]: 'cs',
  [Languages.Slovak]: 'sk',
  [Languages.Romanian]: 'ro',
  [Languages.Hungarian]: 'hu',
  [Languages.Bulgarian]: 'bg',
  [Languages.Danish]: 'da'
};