import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BizzMineLocalStorageService implements Storage {
  public clear(): void {
    if (navigator.cookieEnabled) {
      return localStorage.clear()
    }
  }

  public getItem(key: string): string | null {
    return this.get(key) ?? null;
  }

  public setItem(key: string, value: string): void {
    this.set(key, value);
  }

  public removeItem(key: string): void {
    this.remove(key);
  }

  public key(index: number): string | null {
    if (navigator.cookieEnabled) {
      localStorage.key(index);
    }
    return null;
  }

  public length = navigator.cookieEnabled ? localStorage.length : 0;

  public get(key: string): string | null {
    if (navigator.cookieEnabled) {
      return  localStorage.getItem(key);
    }
    return null;
  }

  public set(key: string, value: string): void {
    if (navigator.cookieEnabled) {
      localStorage.setItem(key, value);
    }
  }

  public remove(key: string): void {
    if (navigator.cookieEnabled) {
      localStorage.removeItem(key);
    }
  }
}


