<div class="modal-container justify-between md:justify-normal">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="dialogRef.close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1>
                {{ 'Edit' | translate }} {{ model.PublicVersion['Title']?.Value }} ?</h1>
            <p class="text-center text-sm font-normal">{{ 'Info_ThereAreMultipleVersionsOfThisDocument' | translate }}
                <br/>
                {{ 'WhichVersionWouldYouLikeToEdit' | translate }}
            </p>
        </div>
        <hr class="my-5"/>
        <div class="flex flex-col md:flex-row justify-between self-stretch gap-4 mb-4">
            <!-- Current version -->
            <button class="version-button flex items-center w-full"
                    (click)="edit($event, VersionType.Public)">
                <div class="accent absolute left-0 top-0 h-full w-[7px] rounded-bl rounded-tl bg-green-500"></div>
                <div class="flex items-center">
                    <bizz-icon class="ml-auto mr-4 block h-8 w-8 text-default md:ml-0"
                               iconName="file-circle-check">
                    </bizz-icon>
                    <div class="flex flex-col gap-1">
                        <h2 class="flex justify-start">
                            {{ 'PublicVersion' | translate }}
                            <span class="absolute right-3 top-3 items-center justify-center gap-2.5 rounded-md bg-button px-2 py-1 text-center text-[14px] font-bold leading-none text-default">
                                {{ model.PublicVersion['Version']?.Value }}
                            </span>
                        </h2>
                        <div class="flex flex-wrap gap-1.5">
                            <p class="text-xs">
                                <b>{{ 'VersionDate' | translate }}</b>
                                {{ model.PublicVersion['VersionDate']?.Value | datetime }}
                            </p>
                            @if (model.PublicVersion['ArchiveDate']?.Value) {
                                <p class="text-danger">
                                    <b>{{ 'WillBeArchivedOn' | translate }})</b>
                                    {{ model.PublicVersion['ArchiveDate']?.Value | datetime }}
                                </p>
                            }
                        </div>
                    </div>
                </div>
            </button>
            <!-- Draft version -->
            <button class="version-button flex items-center w-full"
                    (click)="edit($event, VersionType.Draft)">
                <div class="accent absolute left-0 top-0 h-full w-[7px] rounded-bl rounded-tl bg-blue-500"></div>
                <div class="flex items-center">
                    <bizz-icon class="ml-auto mr-4 block h-8 w-8 text-default md:ml-0"
                               iconName="file-pen">
                    </bizz-icon>
                    <h2 class="flex flex-col gap-1">
                        {{ 'DraftVersion' | translate }}
                    </h2>
                    <span class="absolute right-3 top-3 items-center justify-center gap-2.5 rounded-md bg-button px-2 py-1 text-center text-[14px] font-bold leading-none text-default">
                        {{ model.DraftVersion['Version']?.Value }}
                    </span>
                </div>
            </button>
        </div>
    </div>
</div>
