import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectableSettings, TreeViewModule } from '@progress/kendo-angular-treeview';
import { FilterMenuModule, GridModule } from '@progress/kendo-angular-grid';
import { ButtonGroupModule, ButtonModule } from '@progress/kendo-angular-buttons';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { OrgChartTreeComponent } from './org-chart-tree/org-chart-tree.component';
import { OrgChartListComponent } from './org-chart-list/org-chart-list.component';
import { IconComponent } from '../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../shared/pipes/translate/translate.pipe';
import { GridOrganizationGroupSearchDto } from '../../../../models/ts/grid-organization-group-search-dto.model';
import { BehaviorSubject } from 'rxjs';
import { OrgChartItem } from './interfaces/org-chart-item';
import { ORG_CHART_ITEM_TYPE_ICONS } from './constants/org-chart-item-type-icons';
import { OrganizationChartItemType } from '../../../../models/ts/organization-chart-item-type.model';
import { TrackChangesGridComponent } from '../track-changes/components/track-changes-grid/track-changes-grid.component';
import { OrgChartTraineeListComponent } from './org-chart-trainee-list/org-chart-trainee-list.component';
import { SearchComponent } from '../../../shared/components/ui/search/search.component';

export enum OrgChartView {
  TREE = 'TREE',
  USERS = 'USERS',
  ALL = 'ALL',
  TRAINEES = 'TRAINEES'
}

export enum AdditionalModes {
  TRAINING_QUEUE,
}

/**
 * Represents the Organization Chart. Contains a Tree and a List view. Search functionality is also included.
 * @see OrgChartTreeComponent
 * @see OrgChartListComponent
 */
@Component({
  selector: 'bizz-org-chart',
  standalone: true,
  imports: [
    CommonModule,
    TreeViewModule,
    GridModule,
    ButtonGroupModule,
    ButtonModule,
    MultiSelectModule,
    OrgChartTreeComponent,
    OrgChartListComponent,
    FilterMenuModule,
    IconComponent,
    TranslatePipe,
    TrackChangesGridComponent,
    OrgChartTraineeListComponent,
    SearchComponent
  ],
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart.component.scss']
})
export class OrgChartComponent implements OnInit {
  public activeView: OrgChartView = OrgChartView.TREE;
  public search: Partial<GridOrganizationGroupSearchDto> = {};
  public filterGroups: Array<OrganizationChartItemType> = [];
  @Input() public selectedItems$ = new BehaviorSubject<Array<OrgChartItem>>([]);
  @Input() public maxSelectedItems = 0;
  @Input() public minSelectedItems = 0;
  @Input() public exactlyOneItem = false;
  @Input() public allowedItemTypes: Array<OrganizationChartItemType> = [OrganizationChartItemType.User, OrganizationChartItemType.Department, OrganizationChartItemType.Function];
  @Input() public excludedUserIds: Array<number> = [];
  @Input() public excludeExternalUsers = true;
  @Input() public additionalModes: Array<{ additionMode: AdditionalModes, data: any }> = [];
  public selectableSettings: SelectableSettings = {
    mode: 'multiple',
    enabled: true
  };
  protected readonly ORG_CHART_ITEM_TYPE_ICONS = ORG_CHART_ITEM_TYPE_ICONS;
  protected readonly OrganizationChartItemType = OrganizationChartItemType;
  protected readonly AdditionalModes = AdditionalModes;
  protected readonly OrgChartView = OrgChartView;

  @Input()
  public set defaultView(value: OrgChartView) {
    if (value) this.activeView = value;
  }

  public ngOnInit(): void {
    if (this.maxSelectedItems === 1) {
      this.exactlyOneItem = true;
      this.selectableSettings.mode = 'single';
    }
  }

  public selectView(view: OrgChartView): void {
    this.activeView = view;
    this.search.Search = '';
  }

  public valueChanged(event: any): void {
    this.selectedItems$.next(event);
  }

  public getTrainingRequestData(): { trainingId: number, trainingAppId: number } {
    const modeData = this.additionalModes.find(m => m.additionMode === AdditionalModes.TRAINING_QUEUE);
    return modeData?.data;
  }

  public hasAnyAdditionalMode(): boolean {
    return this.additionalModes != null && this.additionalModes.length > 0;
  }

  public hasAdditionalMode(mode: AdditionalModes): boolean {
    if (this.additionalModes == null) return false;
    return this.additionalModes.some(m => m.additionMode == mode);
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onOpen(event: any): void {
    event.preventDefault();
  }

  public toggleFilterGroup(group: OrganizationChartItemType): void {
    if (this.isFilterGroupSelected(group)) {
      this.filterGroups = this.filterGroups.filter(g => g !== group);
    } else {
      this.filterGroups.push(group);
      this.filterGroups = [...this.filterGroups];
    }
  }

  public searchTermChange(search: string): void {
    if (search !== undefined) {
      if (this.activeView === OrgChartView.TREE) this.activeView = OrgChartView.USERS;
      this.search.Search = search;
    }
  }

  public isFilterGroupSelected(group: OrganizationChartItemType): boolean {
    return this.filterGroups?.includes(group) ?? false;
  }
}
