import { Component, forwardRef, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { EditorModule } from '@progress/kendo-angular-editor';
import { IconComponent } from '../../../ui/icon/icon.component';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { CollectionListApiService } from '../../../../../api/bizzmine/collection-list/collection-list-api.service';
import { map, take, tap } from 'rxjs';
import { GridComponent } from '../../../grid/grid.component';
import { AsyncPipe } from '@angular/common';
import { GridOptions } from '../../../../classes/list/grid-options';
import { InstanceSelectionListComponent } from '../../../../../features/bizzmine/form/components/lookup-search-modal/instance-selection-list/instance-selection-list/instance-selection-list.component';
import { WidgetsType } from '../../../../../../models/ts/widgets-type.model';
import { CollectionType } from '../../../../../../models/ts/collection-type.model';
import { TableViewWidgetData } from '../../../../../../models/ts/table-view-widget-data.model';
import { LookupData } from '../../../../services/lookup/lookup.service';

@Component({
  selector: 'bizz-file-upload-select-template-dialog',
  standalone: true,
  imports: [
    EditorModule,
    IconComponent,
    ToolBarModule,
    TranslatePipe,
    forwardRef(() => GridComponent),
    AsyncPipe,
    InstanceSelectionListComponent
  ],
  templateUrl: './file-upload-select-template-dialog.component.html',
  styleUrl: './file-upload-select-template-dialog.component.scss'
})
export class FileUploadSelectTemplateDialogComponent {
  public widgetData: TableViewWidgetData;
  public lookupData: LookupData;
  public selection: Array<any> | undefined;

  public constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: { viewListId: number },
    private collectionListApiService: CollectionListApiService
  ) {
    this.collectionListApiService.getListOptionsByListId(data.viewListId).pipe(
      take(1),
      tap(options => {
        options.GridOptions.selectable = {
          enabled: true,
          checkboxOnly: true,
          mode: 'single',
          cell: false,
          drag: false
        };
        this.widgetData = {
          ID: 0,
          ShowCaption: false,
          Caption: '',
          WidgetsType: WidgetsType.ListOfCollectionItems,
          CollectionsID: 0,
          CollectionsType: CollectionType.ControlledDocument,
          ListOptions: options,
          FilterList: [],
          CanExport: false,
          CanAccessTrashCan: false,
          CanCreate: false,
          CanSetPermissions: false,
          DisableCreateNewInstances: true,
          CssClass: ''
        };

        this.lookupData = {
          listId: data.viewListId,
          linkedLevel: 1,
          viewsId: 0
        } as LookupData;
      }),
      map(found => new GridOptions(found.GridOptions))
    ).subscribe();


  }

  public onSelectionChange($event: Array<any>): void {
    this.selection = $event;
  }

  public close(): void {
    if (this.selection && this.selection.length > 0) {
      this.dialogRef.close(this.selection[0]);
    } else {
      this.dialogRef.close();
    }
  }
}
