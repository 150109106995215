import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { userSettingsFeature } from '../../../store/features/user-settings/user-settings-feature';
import { UserType } from '../../../../models/ts/user-type.model';
import { selectExternalAccess, selectForm } from '../../../store/features/forms/forms-selectors';
import { FormType } from '../../../../models/ts/form-type.model';
import { CollectionFormExternalAccessDto } from '../../../../models/ts/collection-form-external-access-dto';

@Injectable({
  providedIn: 'root'
})
export class ExternalUserService {
  private store$ = inject(Store);

  private userType = this.store$.selectSignal(userSettingsFeature.selectUserType);

  public externalAccess(formId: string, viewDataSource: number): CollectionFormExternalAccessDto | undefined {
    const preCheckResult = this.preCheckResult(formId);
    if (preCheckResult != undefined) {
      return undefined;
    }
    return this.store$.selectSignal(selectExternalAccess(formId, viewDataSource))();
  }

  public isLookupAllowedExtForm(formId: string, viewDataSource: number): boolean {
    const preCheckResult = this.preCheckResult(formId);
    if (preCheckResult != undefined) {
      return preCheckResult;
    }

    const externalAccess = this.externalAccess(formId, viewDataSource);
    return externalAccess?.AllowLookup ?? false;
  }

  public isCreateRecordAllowedExtForm(formId: string, viewDataSource: number): boolean {
    const preCheckResult = this.preCheckResult(formId);
    if (preCheckResult != undefined) {
      return preCheckResult;
    }

    const externalAccess = this.externalAccess(formId, viewDataSource);
    return externalAccess?.AllowCreateRecord ?? false;
  }

  private preCheckResult(formId: string): boolean | undefined {
    if (this.userType() != UserType.ExternalUser && this.userType() != UserType.AnonymousUser) {
      return true;
    }

    const form = this.store$.selectSignal(selectForm(formId))()?.data;

    if (form == undefined) {
      return false;
    }
    if (form.FormType != FormType.ExternalForm) {
      return true;
    }
    return undefined;
  }
}
