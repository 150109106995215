import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { AuditLogItem } from '../../interfaces/auditlog.interface';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { ModalComponent } from 'src/app/shared/components/modals/modal/modal.component';

@Component({
  selector: 'bizz-auditlog-property-history',
  standalone: true,
  imports: [TranslatePipe, ModalComponent],
  templateUrl: './auditlog-property-history.component.html',
  styleUrl: './auditlog-property-history.component.scss'
})
export class AuditLogPropertyHistoryComponent {
  public constructor(@Inject(DIALOG_DATA) public auditLogItem: AuditLogItem) {
  }

}
