import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeDto } from 'src/models/ts/theme-dto.model';
import { ThemePreviewIconFullComponent } from "../modals/user-settings-modal/theme-selector-modal/components/theme-preview-icon-full/theme-preview-icon-full.component";
import { TranslatePipe } from "../../pipes/translate/translate.pipe";
import { ThemePreviewIconSimpleComponent } from "../modals/user-settings-modal/theme-selector-modal/components/theme-preview-icon-simple/theme-preview-icon-simple.component";

@Component({
    selector: 'bizz-theme-selector-button',
    standalone: true,
    templateUrl: './theme-selector-button.component.html',
    styleUrl: './theme-selector-button.component.scss',
    imports: [ThemePreviewIconFullComponent, TranslatePipe, ThemePreviewIconSimpleComponent]
})
export class ThemeSelectorButtonComponent {

  @Input() public theme: ThemeDto | null = null;
  @Input() public showFullPreview: boolean = false;
  @Output() public click: EventEmitter<MouseEvent> = new EventEmitter();

  public onClick(event: MouseEvent): void {
    event.stopPropagation();
    this.click.emit(event);
  }
}
