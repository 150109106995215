import { Component, Input } from '@angular/core';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { ViewStackSkeletonElementComponent } from '../view-stack-skeleton-element/view-stack-skeleton-element.component';

@Component({
  selector: 'bizz-view-stack-skeleton-accordion',
  standalone: true,
  imports: [
    IconComponent,
    ViewStackSkeletonElementComponent
  ],
  templateUrl: './view-stack-skeleton-accordion.component.html',
  styleUrl: './view-stack-skeleton-accordion.component.scss'
})
export class ViewStackSkeletonAccordionComponent {

  @Input() public config: ViewStackSkeletonAccordionConfig = {
    bgElementClass: 'bg-widget',
    borderClass: 'border-form-control',
    formHeaderBgClass: 'bg-form-accent',
    elements: [
      {colSpan: 2},
      {colSpan: 1},
      {colSpan: 1},
      {colSpan: 4},
      {colSpan: 3}
    ]
  };

}

export class ViewStackSkeletonAccordionConfig {
  public bgElementClass: string;
  public borderClass: string;
  public formHeaderBgClass: string;
  public elements: Array<ViewStackSkeletonElementConfig> = [];
}

export class ViewStackSkeletonElementConfig {
  public colSpan: number = 1;
  public customHeightClass?: string;
}
