import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../../../../ui/icon/icon.component';
import { TranslatePipe } from '../../../../../../pipes/translate/translate.pipe';
import { DatetimePipe } from '../../../../../../pipes/dates/datetime.pipe';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { CompareBaseModalComponent } from '../../compare-base-modal.component';
import { ExtensionIconComponent } from 'src/app/shared/components/ui/icon/extension-icon/extension-icon.component';
import { VersionType } from 'src/app/shared/enums/version-type';
import { take } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { DownloadService } from '../../../../../../../core/services/download/download.service';


/**
 * Modal that offers the user the choice to download the draft or published and/or pdf
 * version of an instance.
 */
@Component({
  selector: 'bizz-download-draft-published-or-pdf-modal',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslatePipe, DatetimePipe, TextAreaModule, ExtensionIconComponent],
  templateUrl: './download-draft-published-or-pdf-modal.component.html',
  styleUrls: ['../../compare-base-modal.component.scss'],
})

export class DownloadDraftPublishedOrPdfModal extends CompareBaseModalComponent {
  private buildDownloadUrl = (versionType: VersionType, docType: 'file' | 'pdffile', versionId: number): string => {
    if (!this.data.CollectionsID || !this.data.VersionsID) {
      throw new Error('CollectionsID, InstanceID are required to download a file');    }

    return `api/collection/${this.data.CollectionsID}/instance/${this.data.ID}/version/${versionId}/${docType}`;
  }

  private buildFileName = (versionType: VersionType, docType: 'file' | 'pdffile'): string | undefined => {
    const model = versionType === VersionType.Draft ? this.model['DraftVersion'] : this.model['PublicVersion'];
    const {Title: title, Extension: extension} = model;

    if (!title || !title.Value || !extension || !extension.Value) {
      return undefined;
    }

    return `${title.Value}${docType === 'pdffile' ? '.pdf' : extension.Value}`;
  }

  public download(versionType: VersionType, docType: 'file' | 'pdffile'): void {
    const versiondId_Keymap = 'VersionsID';
    const version = versionType == VersionType.Public ? this.model.PublicVersion[versiondId_Keymap]?.Value: this.model.DraftVersion[versiondId_Keymap]?.Value;
    const url = this.buildDownloadUrl(versionType, docType, +<any>version);
    this.http.get(
      url,
      {  observe:'response', responseType: 'arraybuffer' })
    .pipe(take(1))
    .subscribe((response: HttpResponse<ArrayBuffer>) => {
      if(response.body != null && response.body.byteLength > 0) {
        const headers = response.headers;
        const fileNameFromHeader = headers.get('x-filename');
        const fileName = DownloadService.cleanupFileName(fileNameFromHeader);
        this.downloadService.startDownload(response.body, fileName);
      }
    }); 
    
    this.dialogRef.close();
  }

  public getFileExtension (versionType: VersionType): string | undefined {
    if (versionType === VersionType.Draft && typeof this.model['DraftVersion']['Extension']?.Value === 'string') {
      return this.model['DraftVersion']['Extension']?.Value;
    } else if (versionType === VersionType.Public && typeof this.model['PublicVersion']['Extension']?.Value === 'string') {
      return this.model['PublicVersion']['Extension']?.Value;
    }

    return;
  }
}
