<div class="modal-container justify-between md:justify-normal"
     [class.modal-xs]="model.mode !== DialogModalModelMode.confirmation">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="dialogRef.close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
                {{ model.title | translate }}</h1>
            @if (model.message && model.mode !== DialogModalModelMode.simple) {
                <p class="text-center text-sm font-normal">
                    {{ model.message | translate }}
                </p>
            }
        </div>
        <hr class="my-5"/>
        @if (model.message && model.mode == DialogModalModelMode.simple) {
            <div class="flex gap-4 sm:h-[12svh] sm:items-center justify-center">
                @if (model.buttons === DialogModalButtons.Delete) {
                    <bizz-alert type="error" message="{{ model.message | translate }}"></bizz-alert>
                } @else {
                    <p class="text-center text-base font-normal">
                        {{ model.message | translate }}
                    </p>
                }
            </div>
        }
        @if (model.mode === DialogModalModelMode.confirmation) {
            <div>
                <label class="mb-2 inline-block text-sm text-default">
                    <!-- TODO: check the styling of this:-->
                    <bizz-icon class="ml-auto block h-8 w-8 text-default md:ml-0"
                               iconName="triangle-exclamation"></bizz-icon>
                    {{ 'ThisActionNeedsConfirmation' | translate }}
                    {{ 'PleaseTypeTheWord' | translate }}
                    {{ model.confirmationtext }}
                    {{ 'BelowInOrderToEnableConfirmButton' | translate }}
                    <span class="ml-0.5 text-red-400">*</span>
                </label>

                <kendo-textarea
                        class="p-1"
                        [rows]="1"
                        [(value)]="enteredConfirmText"
                        fillMode="solid"
                        rounded="large"
                        placeholder="{{model.confirmationtext}}"
                ></kendo-textarea>
            </div>
        }
    </div>
    <div class="modal-buttons">
        @if (model.showCancelButton) {
            <button class="default" (click)="dialogRef.close(DialogModalButtons.Cancel)">
                {{ 'Cancel' | translate }}
            </button>
        }
        @switch (model.buttons) {
            @case (DialogModalButtons.Ok) {
                <button class="save min-w-16" (click)="dialogRef.close(DialogModalButtons.Ok)"
                        [disabled]="(model.mode === DialogModalModelMode.confirmation && enteredConfirmText != model.confirmationtext)">
                    {{ 'Ok' | translate }}
                </button>
            }
            @case (DialogModalButtons.Confirm) {
                <button class="save" (click)="dialogRef.close(DialogModalButtons.Confirm)"
                        [disabled]="(model.mode === DialogModalModelMode.confirmation && enteredConfirmText != model.confirmationtext)">
                    {{ 'Confirm' | translate }}
                </button>
            }
            @case (DialogModalButtons.Delete) {
                <button class="delete" (click)="dialogRef.close(DialogModalButtons.Delete)"
                        [disabled]="(model.mode === DialogModalModelMode.confirmation && enteredConfirmText != model.confirmationtext)">
                    {{ 'Delete' | translate }}
                </button>
            }
        }
    </div>
</div>
