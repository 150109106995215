import { TranslationService } from './../../../../core/services/translation/translation.service';
import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstanceStatusType } from '../../../../../models/ts/instance-status-type.model';
import { TaskStateType } from '../../../../../models/ts/task-state-type.model';
import { IconComponent } from '../icon/icon.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TranslatePipe } from "../../../pipes/translate/translate.pipe";

@Component({
  selector: 'bizz-status-icon',
  standalone: true,
  imports: [CommonModule, IconComponent, TooltipModule, TranslatePipe],
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent{

  @Input({ required: true }) public statusType: InstanceStatusType | TaskStateType;
  @Input() public statusText: string = '';

  @Input() public size: number = 16;

}
