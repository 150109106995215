import { Component } from '@angular/core';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ToggleArrowComponent } from '../../../../../shared/components/ui/toggle-arrow/toggle-arrow.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { DatePipe } from '@angular/common';
import { FieldFormControlPipe } from '../../../../../shared/pipes/form/field-form-control/field-form-control.pipe';
import { FormAccordionHeaderComponent } from '../form-accordion-header/form-accordion-header.component';
import { FormAssessmentComponent } from '../form-assessment/form-assessment.component';
import { FormControlComponent } from '../controls/form-control/form-control.component';
import { FormElementComponent } from '../form-element/form-element.component';
import { FormLockComponent } from '../form-lock/form-lock.component';
import { FormReadAndUnderstoodComponent } from '../form-read-and-understood/form-read-and-understood.component';
import { FormTrainingappAssessmentComponent } from '../form-trainingapp-assessment/form-trainingapp-assessment.component';
import { LoaderComponent } from '../../../../../shared/components/ui/loader/loader.component';
import { SchedulerPanelComponent } from '../scheduler-panel/scheduler-panel.component';
import { SharedModule } from '@progress/kendo-angular-dropdowns';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ValidationSummaryComponent } from '../../../../../shared/components/errors/validation-summary/validation-summary.component';
import {
  ViewStackSkeletonAccordionComponent,
  ViewStackSkeletonAccordionConfig,
  ViewStackSkeletonElementConfig
} from './view-stack-skeleton-accordion/view-stack-skeleton-accordion.component';

@Component({
  selector: 'bizz-view-stack-skeleton-loader',
  standalone: true,
  imports: [
    IconComponent,
    PopupModule,
    ToggleArrowComponent,
    TranslatePipe,
    DatePipe,
    FieldFormControlPipe,
    FormAccordionHeaderComponent,
    FormAssessmentComponent,
    FormControlComponent,
    FormElementComponent,
    FormLockComponent,
    FormReadAndUnderstoodComponent,
    FormTrainingappAssessmentComponent,
    LoaderComponent,
    SchedulerPanelComponent,
    SharedModule,
    TooltipModule,
    ValidationSummaryComponent,
    ViewStackSkeletonAccordionComponent
  ],
  templateUrl: './view-stack-skeleton-loader.component.html',
  styleUrl: './view-stack-skeleton-loader.component.scss'
})
export class ViewStackSkeletonLoaderComponent {

  public readonly config: Array<Array<ViewStackSkeletonElementConfig>> = [
    [{ colSpan: 2 },
      { colSpan: 1 },
      { colSpan: 1 },
      {
        colSpan: 4,
        customHeightClass: 'h-40'
      },
      { colSpan: 1 },
      { colSpan: 3 },
      { colSpan: 4 }
    ], [{ colSpan: 2 },
      { colSpan: 1 },
      { colSpan: 1 },
      { colSpan: 3 },
      { colSpan: 1 }],
    []
  ];

  public getAccordionConfig(elements: Array<ViewStackSkeletonElementConfig>): ViewStackSkeletonAccordionConfig {
    return {
      bgElementClass: 'bg-widget',
      elements: elements,
      borderClass: 'border-form-control',
      formHeaderBgClass: 'bg-divider-handle/30'
    };
  }
}
