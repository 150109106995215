import { Component, computed, HostListener, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { BaseFormControlComponent } from '../../../../classes/base-form-control.component';
import { NgClass } from '@angular/common';
import { TrnScoreEvaluation, TrnScoreSkillGrade } from '../../../../../../../../models/ts/trn-score-evaluation.model';
import { NouisliderComponent } from 'ng2-nouislider';
import { FormsModule } from '@angular/forms';
import { BreakpointEnum } from '../../../../../../../shared/enums/pixel-breakpoints';
import * as noUiSlider from 'nouislider';
import { DomSanitizer } from '@angular/platform-browser';
import { selectFormLockState } from '../../../../../../../store/features/forms/forms-selectors';
import { asyncScheduler, debounceTime } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'bizz-exam-score-evalutation-control',
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    NouisliderComponent
  ],
  templateUrl: './exam-score-evalutation-control.component.html',
  styleUrl: './exam-score-evalutation-control.component.scss'
})

export class ExamScoreEvalutationControlComponent extends BaseFormControlComponent implements OnInit {
  private _isLocked: boolean;
  public isLocked = computed(() => {
    if (this.formId != null) {
      const result = this.store$.selectSignal(selectFormLockState(this.formId))();
      this._isLocked = result;
      asyncScheduler.schedule(() => this.renderSlider());
      return result;
    }
    return false;
  });
  public trnScoreEvaluation: TrnScoreEvaluation;
  public skillGrades: Array<TrnScoreSkillGrade>;
  public scores: Array<number> = [];
  public tooltips: Array<{ to: () => string }> = [];
  public orientation = 'horizontal'; // "vertical" or "horizontal"
  public direction = 'ltr';
  public config = {
    orientation: this.orientation
  };
  @ViewChild('slider', { static: true }) private sliderElement: any;
  private sliderRef: noUiSlider.API;

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.applySliderOrientation(event.target.innerWidth);
  }

  public constructor(private sanitizer: DomSanitizer) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.refreshValue();
    this.formControl.valueChanges.pipe(
      debounceTime(300),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe({
      next: () => {
        this.refreshValue();
      }
    });
  }

  public hasEmitInitValue = false;

  private refreshValue(): void {
    this.trnScoreEvaluation = structuredClone(<TrnScoreEvaluation>this.formFieldSignal()?.Value);
    this.skillGrades = structuredClone(this.trnScoreEvaluation?.SkillGrades?.slice()
      ?.sort((a, b) => a.ID - b.ID))
      ?.map(el =>{return {...el, Score: el.From}});
    this.applySliderContext(this.skillGrades);
    this.renderSlider();
    this.applySliderOrientation(window.innerWidth);
    if(!this.hasEmitInitValue && this.trnScoreEvaluation.SkillGrades.every(s => s.ExamScoreSkillGradeID == 0 || s.To ==0)) {
      this.hasEmitInitValue = true;
      const scores = this.trnScoreEvaluation.SkillGrades.filter(t => t.Name !== 'None' && t.Active).map(x => x.Score);
      this.update(scores);
      this.emitChangedValue();
    }
  }

  private emitChangedValue(): void {
    const newValue = structuredClone(<TrnScoreEvaluation>this.formFieldSignal()?.Value);
    newValue.SkillGrades = this.skillGrades;
    this.formControl.setValue(newValue);
  }

  public update($event: Array<number>): void {
    $event = $event.sort((a, b) => a - b);
    const skillGrades: Array<TrnScoreSkillGrade> = [];
    let currentActiveIndex = 0;
    this.skillGrades.forEach(el => {
      if (el.Name == 'None') {
        const inActiveSkill = { ...el, Score: 0, From: 0, To: ($event[0] -1)};
        skillGrades.push(inActiveSkill);
      }
      else if (!el.Active) {
        skillGrades.push(el);
      } else {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const newScore = $event[currentActiveIndex];
        const skill = { ...el, Score: newScore, From: newScore };
        skill.To = $event[currentActiveIndex + 1] ? ($event[currentActiveIndex + 1] -1) :100;
        skillGrades.push(skill);
        currentActiveIndex++;
      }
    });
    this.skillGrades = skillGrades;
    this.applySliderContext(this.skillGrades);
  }
  public queueRender = false;
  public toggleSkill(id: number): void {
    const found = this.skillGrades.find(el => el.ID === id);
    if(found?.Name == 'None'){
      return;
    }
    if (this.sliderRef != null) {
      this.sliderRef.off('end');
      this.sliderRef.off('slide');
      this.sliderRef.destroy();
    }
    if(found?.Active && this.skillGrades.filter(x => x.Name !== 'None' && x.Active).length <= 1){
      //setting this skill to inactive would result in no skill being active - do not allow this
      return;
    }
    const skillGrades: Array<TrnScoreSkillGrade> = [];
    this.skillGrades.forEach((el) => {
      const current = { ...el };
      if (current.ID === id) {
        current.Active = !current.Active;
      }
      skillGrades.push(current);
    });
    this.skillGrades = skillGrades; //.correctChangedActivityOnRecords(id,this.skillGrades);
    const changes = this.correctChangedActivityOnRecords(id);
    if(changes){
      this.queueRender = true;
      this.update(changes);
    }
    this.applySliderContext(this.skillGrades, false);
    this.renderSlider();
    this.emitChangedValue();
  }

  private correctChangedActivityOnRecords(id: number): Array<number> | undefined {
    const actives = this.skillGrades.filter(x => x.Active && x.Name != 'None');
    const numbers: Array<number> = [];
    actives.forEach((x, index) => {
      if(x.ID == id) {
        if(index == 0){
          numbers.push(x.From)
        } else {
          const previous = actives[index - 1]?.From;
          const next = actives[index + 1]?.From ?? 100;
          const avg = Math.trunc(((next ?? previous) - (previous)) /2);
          numbers.push(avg + previous);
        }
      } else {
        numbers.push(x.From);
      }

    });
    return numbers;
  }

  private applySliderOrientation(width: number): void {
    if (width < BreakpointEnum.LG) {
      this.orientation = 'vertical';
    } else {
      this.orientation = 'horizontal';
    }
    if (this.orientation != this.config.orientation) {
      this.config.orientation = this.orientation;
      this.renderSlider();
    }
  }

  private applySliderContext(skillGrades: Array<TrnScoreSkillGrade>, updateSlider : boolean = true): void {
    // for some reason i must create a new value for this as trnScoreEvaluation is "frozen" in "strict mode"
    const filteredSkillGrades = this.applyFilter(skillGrades);
    this.tooltips = this.applyTooltips(filteredSkillGrades);
    this.scores = this.applyScores(filteredSkillGrades);
    if(updateSlider && this.sliderRef != null){
      if(this.queueRender){
        this.renderSlider();
        this.queueRender = false;
      } else {
        this.sliderRef.updateOptions({...this.sliderRef.options, tooltips: this.tooltips, start: this.scores}, false);
      }

    }
  }

  private renderSlider(): void {
    if (this.sliderRef != null) {
      this.sliderRef.off('end');
      this.sliderRef.off('slide');
      this.sliderRef.destroy();
    }
    this.sliderRef = noUiSlider.create(this.sliderElement.nativeElement, this.getNoUiSliderOptions());
    //https://refreshless.com/nouislider/events-callbacks/ - events
    this.sliderRef.on('end', (values, handle, unencoded) => {
      this.emitChangedValue();
    });
    this.sliderRef.on('slide', (values, handle, unencoded) => {
      this.update(unencoded);
    });
    if(this.formFieldSignal()?.IsReadOnly == true || this._isLocked){
      this.sliderRef.disable();
    } else {
      this.sliderRef.enable();
    }
  }

  private getNoUiSliderOptions(): noUiSlider.Options {
    return {
      orientation: this.orientation,
      direction: this.direction,
      start: this.scores,
      tooltips: this.tooltips,
      step: 1,
      margin: 1,
      range: {
        'min': 0,
        'max': 100
      },
      pips: {
        mode: 'steps',
        values: [0, 20, 40, 60, 80, 100],
        density: 4,
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        filter: function (value: number, type: any) {
          return value % 20 ? -1 : 1;
        }
      }
    } as noUiSlider.Options;
  }

  private applyFilter(skillGrades: Array<TrnScoreSkillGrade>): Array<TrnScoreSkillGrade> {
    return skillGrades.filter(grade => grade.Active && grade.Name !== 'None');
  }

  private applyScores(skillGrades: Array<TrnScoreSkillGrade>): Array<number> {
    return skillGrades.map(grade => grade.Score);
  }

  private applyTooltips(skillGrades: Array<TrnScoreSkillGrade>): Array<{ to: () => string }> {
    // setting the value to filteredSkillGrades to be used in the template
    const tooltips: Array<{ to: () => string }> = [];
    this.tooltips = tooltips;
    skillGrades.forEach((grade, index) => {
      tooltips.push({
        to: () => {
          const max = index === skillGrades.length - 1 ? 100 : skillGrades[index + 1].Score - 1;
          return this.sanitizer.sanitize(SecurityContext.HTML,
             '<p class="font-bold">' + grade.Name + '</p><p>from ' + skillGrades[index].Score + ' to ' + max + '</p>' ) ?? '';
        }
      });
    });
    return tooltips;
  }

  protected override focus(): void {
    // Add your implementation here
  }
}
