<div class="modal-container modal-lg justify-between md:justify-normal">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="dialogRef.close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
                {{ "TrackChanges" | translate }}</h1>
        </div>
        @if(data$ | async; as data){
            <bizz-grid class="bizz-grid flex h-full w-full flex-col overflow-hidden" [currentPage]="0"
                       [gridOptions]="gridOptions" [gridData]="data.Data" [loading]="false"
                       [totalItems]="data.TotalRecords"></bizz-grid>
        }

    </div>
    <div class="modal-buttons">
            <button class="default" (click)="dialogRef.close()">
                {{ 'Cancel' | translate }}
            </button>
    </div>
</div>
