<div class="modal-container !h-auto">
    <div class="modal-close-container">
        <bizz-icon [iconLibrary]="'FontAwesome'" [iconName]="'fa-xmark'" (click)="dialogRef.close(false)"></bizz-icon></div>
    <div class="modal-body !min-h-0">

        <div class="modal-header">
            <h1>{{"Warning" | translate}}</h1>
            <h2>{{"TheContentDoesNotMatchExtension" | translate}}</h2>
        </div>
        <p>MimeType: {{data.validationResponse.MimeType}}</p>
        <p>Extension: {{data.validationResponse.Extension}}</p>
    </div>
    <div class="modal-buttons p-4">
        <button class="button default" (click)="dialogRef.close(false)">{{"Cancel" | translate}}</button>
        <button class="button save" (click)="dialogRef.close(true)">{{"Upload" | translate}}</button>
    </div>
</div>