<div class="mt-5 flex gap-2 rounded bg-section p-4 sm:p-5">
    <input class="mr-1 h-6 w-auto lg:h-8" type="radio" id="specificMonthDay" name="yearly" value="specificMonthDay"
           [checked]="state.subTab =='specificMonthDay'" kendoRadioButton (click)="state.subTab = 'specificMonthDay'"/>

    <div class="grid w-full grid-cols-3 gap-2 lg:inline-flex lg:items-center">
        <p class="col-span-1 w-full lg:w-auto lg:whitespace-nowrap">{{ 'Every' | translate }}</p>
        <kendo-combobox class="col-span-2 w-full lg:max-w-32" [clearButton]="false" [data]="helpers.monthsArray"
                        [readonly]="readOnly"
                        [value]="helpers.monthsArray[state.specificMonthDay.month-1]" [valuePrimitive]="true"
                        [disabled]="state.subTab !=='specificMonthDay'"
                        (valueChange)="monthsChange($event, 'specificMonthDay')"></kendo-combobox>

        <p class="col-span-1 w-full lg:w-auto lg:whitespace-nowrap">{{ 'OnThe' | translate }}</p>
        <kendo-combobox class="col-span-2 w-full lg:max-w-32" [clearButton]="false"
                        [readonly]="readOnly"
                        [data]="cronGenService.dropDownOptions.monthWeeksFull"
                        [value]="cronGenService.monthDayDisplay(state.specificMonthDay.day)" [valuePrimitive]="true"
                        [disabled]="state.subTab !=='specificMonthDay'"
                        (valueChange)="daysChange($event, 'specificMonthDay')"></kendo-combobox>

        <p class="col-span-1 w-full lg:w-auto lg:whitespace-nowrap">{{ 'at' | translate }}</p>
        <bizz-cron-gen-time-select class="col-span-2 w-full lg:max-w-32"
                                   [state]="{datetime: state.specificMonthDay.datetime, hours: state.specificMonthDay.hours, minutes: state.specificMonthDay.minutes, seconds: state.specificMonthDay.seconds, hourType: state.specificMonthDay.hourType}"
                                   [disabled]="state.subTab !=='specificMonthDay'"
                                   [readOnly]="readOnly"
                                   (stateChange)="cronGenTimeChange($event,'specificMonthDay')"></bizz-cron-gen-time-select>
    </div>
</div>

<div class="mt-5 flex gap-2 rounded bg-section p-4 sm:p-5">

    <input class="mr-1 h-6 w-auto lg:h-8" type="radio" id="specificMonthWeek" name="yearly" value="specificMonthWeek"
           [checked]="state.subTab =='specificMonthWeek'" kendoRadioButton
           [disabled]="readOnly"
           (click)="state.subTab = 'specificMonthWeek'"/>

    <div class="grid w-full grid-cols-3 gap-2 lg:inline-flex lg:items-center">
        <p class="col-span-1 w-full lg:w-auto lg:whitespace-nowrap">{{ 'OnThe' | translate }}</p>
        <kendo-combobox class="col-span-1 w-full lg:max-w-32" [clearButton]="false"
                        [data]="cronGenService.dropDownOptions.monthDaysWithLastsFull"
                        [value]="cronGenService.monthWeekLookups[state.specificMonthWeek.monthWeek]"
                        [readonly]="readOnly"
                        [valuePrimitive]="true"
                        [disabled]="state.subTab !=='specificMonthWeek'"
                        (valueChange)="monthWeekChange($event)"></kendo-combobox>
        <kendo-combobox class="col-span-1 w-full lg:max-w-32" [clearButton]="false" [data]="helpers.weekdaysArray"
                        [readonly]="readOnly"
                        [value]="cronGenService.dayLookups[state.specificMonthWeek.day]" [valuePrimitive]="true"
                        [disabled]="state.subTab !=='specificMonthWeek'"
                        (valueChange)="daysChange($event, 'specificMonthWeek')"></kendo-combobox>
        <p class="col-span-1 w-full lg:w-auto lg:whitespace-nowrap">{{ 'of' | translate }}</p>
        <kendo-combobox class="col-span-2 w-full lg:max-w-32" [clearButton]="false" [data]="helpers.monthsArray"
                        [value]="helpers.monthsArray[state.specificMonthWeek.month-1]" [valuePrimitive]="true"
                        [readonly]="readOnly"
                        [disabled]="state.subTab !=='specificMonthWeek'"
                        (valueChange)="monthsChange($event,'specificMonthWeek')"></kendo-combobox>
        <p class="col-span-1 w-full lg:w-auto lg:whitespace-nowrap">{{ 'at' | translate }}</p>
        <bizz-cron-gen-time-select
                class="col-span-2 w-full lg:max-w-32"
                [readOnly]="readOnly"
                [state]="{datetime: state.specificMonthWeek.datetime, hours: state.specificMonthWeek.hours, minutes: state.specificMonthWeek.minutes, seconds: state.specificMonthWeek.seconds, hourType: state.specificMonthWeek.hourType}"
                [disabled]="state.subTab !=='specificMonthWeek'"
                (stateChange)="cronGenTimeChange($event,'specificMonthWeek')"></bizz-cron-gen-time-select>


    </div>

</div>