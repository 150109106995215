@if (question$ | async; as question) {
<div class="modal-container justify-between text-base text-widget md:justify-normal">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0" (click)="close()" iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body w-full overflow-hidden">
        <div class="modal-header">
            <h1 class="text-center text-lg text-widget">
                {{ ((question.ID != null && question.ID > 0) ? "EditQuestion" : "AddQuestion") | translate}}
            </h1>
        </div>
            <hr class="mx-10 pb-2" />

        <div class="flex flex-col gap-5">
            <!-- TODO: Add Translation -->
            <h3 class="font-bold">{{'Question' | translate}}</h3>
            <div class="w-full gap-4">

                <kendo-editor
                    class="kendo-toolbar_hidden focus-within:kendo-toolbar_show group h-28 max-h-[200px] w-full min-w-full max-w-full"
                    [ngClass]="hasQuestionErrors ? 'border-2 border-danger' : ''" [iframe]="false" [resizable]="true"
                    [(ngModel)]="question.Caption">
                </kendo-editor>
                <label class="text-danger" [hidden]="!hasQuestionErrors">
                    {{ 'Error_QuestionModal' | translate }}
                </label>
                <div class="mt-4 flex flex-row gap-5 font-bold">
                    <!-- TODO: Add Translation -->
                    <h3>{{'Required' | translate}}</h3>
                    <input type="checkbox" kendoCheckBox [(ngModel)]="question.IsRequired">
                </div>

            </div>

        </div>
        <hr class="my-4" />
        <div class="flex flex-col overflow-auto">

            <div class="flex h-full w-full flex-col gap-4 overflow-hidden">
                <div class="sticky w-full font-bold">
                    <div class="flex w-full justify-between text-base">
                        <h3 class="font-bold">{{ "Answers" | translate }}</h3>
                        <button class="save round ml-0.5 h-5 w-5 drop-shadow-sm sm:flex sm:h-8 sm:w-8" type="button" kendoTooltip
                            [title]="'AddAnswer' | translate" (click)="addAnswer()">
                            <bizz-icon iconName="plus" class="size-5" ></bizz-icon>
                        </button>
                    </div>
                </div>

                <div class="flex h-full w-full flex-col items-start overflow-x-hidden"
                    [ngClass]="hasAnswerErrors ? 'border-danger' : ''">
                    @if(question.Answers.length > 0){
                    <div class="sticky w-full font-bold">
                        <div class="flex w-full justify-between text-base">
                            <p> {{ 'Content' | translate }}</p>
                            <p class="w-20 text-center">{{ 'Score' | translate }}</p>
                        </div>
                        <hr />
                    </div>
                    }
                    <div class="mb-5 flex w-full flex-col gap-4">
                        @for (answer of question.Answers; track $index) {
                        @if (answer.State != UpdateDeleteState.Delete) {
                        <div class="flex w-full flex-row content-between justify-between gap-2.5 text-base max-w-full">
                            <kendo-editor class="kendo-toolbar_hidden focus-within:kendo-toolbar_show group min-w-[calc(100%_-_95px)]"
                                [iframe]="false" [resizable]="true" [(ngModel)]="answer.Caption">
                            </kendo-editor>
                            <div class="btn-group actions flex flex-col gap-2.5">
                                <div class="text-lg">
                                    <kendo-numerictextbox
                                        class="w-20 focus-within:w-20 h-8 [&>input]:text-center [&>*>button]:rounded-none kendo-numerictextbox-spinner-hidden focus-within:kendo-numerictextbox-spinner-show"
                                        [spinners]="true" [step]="1" [(ngModel)]="answer.Score" [format]="'#.####'">
                                    </kendo-numerictextbox>
                                </div>
                                <button class="default h-8 w-20" type="button" (click)="delete($index)">
                                    <bizz-icon iconName="trash-can" class="m-auto h-3.5 w-3.5 text-danger"></bizz-icon>
                                </button>
                            </div>
                        </div>
                        }
                        }

                    </div>
                    <label class="text-danger" [hidden]="!hasAnswerErrors">
                        {{ 'Error_AnswersQuestionModal' | translate }}
                    </label>
                </div>

            </div>
        </div>

    </div>
    <div class="modal-buttons">
        <button class="default" type="button" (click)="close()">{{ 'Cancel' | translate }}</button>
        <button class="save" type="button" (click)="saveQuestion()">
            <p> {{ 'Save' | translate }}</p>
        </button>
    </div>
</div>

}