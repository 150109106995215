import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AsyncPipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { GridModule, PageChangeEvent, PagerModule, SharedFilterModule } from '@progress/kendo-angular-grid';
import { SkeletonModule } from '@progress/kendo-angular-indicators';
import { TableViewWidgetData } from '../../../../../models/ts/table-view-widget-data.model';
import { GridSearchFilterSearchDto } from '../../../../../models/ts/grid-search-filter-search-dto.model';
import { Store } from '@ngrx/store';
import { formsActions } from '../../../../store/features/forms/forms-actions';
import { StateType } from '../../../../../models/ts/state-type.model';
import { GridCellComponent } from '../../../../shared/components/grid/cells/grid-cell/grid-cell.component';
import { SafePipe } from 'safe-pipe';
import { CollectionType } from '../../../../../models/ts/collection-type.model';
import { ListDesignGridOptionsDto } from '../../../../../models/ts/list-design-grid-options-dto.model';
import { GridComponent } from '../../../../shared/components/grid/grid.component';
import { GridFilterDto } from '../../../../../models/ts/grid-filter-dto.model';
import { CellActionType } from './classes/cell-action-type';
import { CellActionData } from './interfaces/cell-action-data';
import { ContextMenuComponent } from '../context-menu/context-menu.component';
import { GroupDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { GridGroupSortDto } from 'src/models/ts/grid-group-sort-dto.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { filter, map, of, shareReplay, switchMap, take, tap } from 'rxjs';
import { CollectionListApiService } from '../../../../api/bizzmine/collection-list/collection-list-api.service';
import { IconComponent } from '../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../shared/pipes/translate/translate.pipe';
import { FilterComponent } from '../../../../shared/components/ui/filter-component/filter.component';
import { ToggleArrowComponent } from '../../../../shared/components/ui/toggle-arrow/toggle-arrow.component';
import { DynamicallyLoadedComponent } from '../../../../shared/interfaces/dynamically-loaded-component';
import { GridOptions } from '../../../../shared/classes/list/grid-options';
import { CollectionListDataInstance } from '../../../../shared/interfaces/collection-list-data-instance';
import { UserType } from 'src/models/ts/user-type.model';
import { userSettingsFeature } from '../../../../store/features/user-settings/user-settings-feature';
import { SearchFilterGroupDto } from 'src/models/ts/search-filter-group-dto.model';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ExtensionIconComponent } from '../../../../shared/components/ui/icon/extension-icon/extension-icon.component';
import { FilterItemType } from '../../../../../models/ts/filter-item-type.model';
import { Dialog } from '@angular/cdk/dialog';
import { ReportTileListDto } from 'src/models/ts/report-tile-list-dto.model';
import { HttpResponse } from '@angular/common/http';
import { GridColumnBase } from 'src/app/shared/classes/list/grid-column-base';
import { EditOnlineService } from '../../../../core/services/file-upload/edit-online.service';
import { GridService } from './services/grid/grid.service';
import { WidgetRefreshListener } from '../widget-refresh-listener';
import { SearchFilterOutputDto } from '../../../../../models/ts/search-filter-output-dto.model';
import { LookupData } from '../../../../shared/services/lookup/lookup.service';
import { Align, KENDO_POPUP } from '@progress/kendo-angular-popup';
import { ReportModalComponent } from 'src/app/shared/components/modals/report-modal/report-modal.component';

@Component({
  selector: 'bizz-collection-list-widget',
  templateUrl: './collection-list-widget.component.html',
  styleUrls: ['./collection-list-widget.component.scss'],
  imports: [
    IconComponent,
    GridModule,
    NgForOf,
    GridCellComponent,
    SafePipe,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    SkeletonModule,
    NgIf,
    TranslatePipe,
    PagerModule,
    FilterComponent,
    GridComponent,
    AsyncPipe,
    SharedFilterModule,
    ToggleArrowComponent,
    ContextMenuComponent,
    ExtensionIconComponent,
    NgClass,
    KENDO_POPUP,
  ],
  standalone: true
})
export class CollectionListWidgetComponent extends WidgetRefreshListener implements OnInit, DynamicallyLoadedComponent<TableViewWidgetData> {

  @ViewChild('mobileActionsAnchor')
  public mobileAnchor: ElementRef;
  @ViewChild('anchor')
  public anchor: ElementRef;
  /**
   * Filter data
   */
  @Input() public filterData: GridFilterDto | undefined;
  /**
   * Readonly mode
   */
  @Input() public readOnly = false;

  @Input() public lookupData: LookupData;

  /**
   * Widget data
   * Is actually required but not done so, because it needs to be dynamically loaded
   */
  @Input() public data: TableViewWidgetData;

  /**
   * Should create/edit links open in the view stack or a new tab/window?
   * TODO: RV Implement
   */
  @Input() public openLinksInStack = true;

  @Output() public aiButton = new EventEmitter<string>();

  /**
   * Reversed User FixedGroups Filter
   * Filter that's overwrites the current filterData's UserReversedFixedGroups.
   * (For Reversed User Collection Control Grid's)
   */
  @Input() public userReversedFixedGroupsFilter: SearchFilterGroupDto[] = [];
  //TODO: export permission
  public canExport = true;
  public isDownloading = false;

  /**
   * Optional custom commmand column template that can be used to set the columns for the grid.
   * use let-dataItem let-rowIndex="rowIndex" let-column="column" to access grid data in the template.
   */

  @Input() public commandColumnTemplate:
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TemplateRef<{ $implicit: any; rowIndex: number; column: GridColumnBase; }> | null = null;

  @Output() public selectionChange = new EventEmitter<any[]>();
  public reportsAvailableForUser = false;
  public searchTerm: string | undefined;
  /**
   * When the list search filter updates and is not undefined,
   * we fetch the data from the server,
   * update the gridData
   * and update the totalItems and wheter we have an activeFilter
   * See: initlistSearch()
   */
  public gridResultData$ = of({ totalItems: 0, activeFilter: false });
  protected listOptions: ListDesignGridOptionsDto;
  protected gridOptions: GridOptions;
  protected gridData: BehaviorSubject<CollectionListDataInstance[] | any[]> = new BehaviorSubject<CollectionListDataInstance[] | any[]>([]);
  protected isTrashView = false;
  protected collectionType: CollectionType;
  protected loading = true;
  protected totalItems = 0;
  protected currentPage = 1;
  protected activeFilter = false;
  protected showMobileActionsPopup = false;
  protected filterItemType = FilterItemType.Widget;
  protected toggleDownloadPopUp = false;
  protected anchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
  protected popupAlign: Align = { horizontal: 'right', vertical: 'top' };
  protected listSearchFilter$: BehaviorSubject<Partial<GridSearchFilterSearchDto> | undefined> =
    new BehaviorSubject<Partial<GridSearchFilterSearchDto> | undefined>(undefined);
  protected readonly FilterItemType = FilterItemType;

  public constructor(protected store$: Store,
    protected collectionListService: CollectionListApiService,
    protected downloadService: DownloadService,
    private dialog: Dialog,
    private editOnlineService: EditOnlineService,
    private gridService: GridService
  ) {
    super();
  }

  public get userType(): UserType {
    return this.store$.selectSignal(userSettingsFeature.selectUserType)();
  }

  public get userId(): UserType {
    return this.store$.selectSignal(userSettingsFeature.selectUserID)();
  }

  public ngOnInit(): void {
    this.initListSearch();
    if (this.data) {

      this.gridOptions = new GridOptions(this.data.ListOptions.GridOptions);
      if (this.data.ListOptions.Records > 0)
        this.gridOptions.pageSize = this.data.ListOptions.Records;
      this.listOptions = this.data.ListOptions;
      if (!this.listOptions.ShowGrouping) {
        this.gridOptions.groupable.enabled = false;
      }
      this.filterData = this.filterData ?? {} as GridFilterDto;
      this.filterData.UseDefaultFilter = true;
      this.gridOptions.autoSize = this.listOptions.GridColumnsAutoWidth;
      this.collectionType = this.data.CollectionsType;
    }
    //Disable this for now as the filter component should trigger the search (causing double search calls)
    //if data isn't loaded in a widget, check if this needs to reenabled for certain cases
    // this.fetchSearchData();

    if (this.data.CollectionsID !== undefined)
      this.startListeningForRefreshInvoke(this.data.CollectionsID, (props) => {
        if (props.collectionId == this.data.CollectionsID || props.widgetId == this.data.ID || props.all)
          this.fetchSearchData();
      });
  }

  /**
   * Adds new record/form to the view stack.
   * Note (for debugging): This function is overwritten by FormCollectionListComponent
   */
  public newRecord(): void {
    this.store$.dispatch(formsActions.getFormByWidgetId({
      collectionId: this.data.CollectionsID,
      widgetId: this.data.ID
    }));
  }

  // changes caret state caretVisible
  public changeDownloadPopUpState(): void {
    this.toggleDownloadPopUp = !this.toggleDownloadPopUp;
  }

  public exportXlsx(): void {
    this.isDownloading = true;
    this.changeDownloadPopUpState();

    const search = this.listSearchFilter$.value;
    if (search != null) {
      search.Records = 0;
      this.collectionListService.exportXlsx(this.data.CollectionsID, search)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          map(file => {
            this.downloadService.startDownload(file, this.data.Caption + '.xlsx');
            this.isDownloading = false;
          }
          )).subscribe({
            error: () => {
              this.isDownloading = false;
            }
          });
    }
  }

  public exportPdf(): void {
    // TODO: Replace
    this.collectionListService.reports(this.data.CollectionsID)
      .pipe(
        take(1),
        map((reportList: ReportTileListDto) => {
          this.dialog.open(ReportModalComponent, {
            data: {
              reportList: reportList,
              listId: this.data.ListOptions.ListID,
              reportSettings: {
                HasFormReports: true,
                HasWordTemplateReports: false,
                ShowDownloadReportButton: true
              }
            }
          });
        })).subscribe();
  }

  public exportCsv(): void {
    this.isDownloading = true;
    this.changeDownloadPopUpState();

    const search = this.listSearchFilter$.value;
    if (search != null) {
      search.Records = 0;
      let csvData: HttpResponse<ArrayBuffer>;
      this.collectionListService.exportCsv(this.data.CollectionsID, search)
        .pipe(
          takeUntilDestroyed(this.destroyRef)
        ).subscribe({
          next: (res): void => {
            csvData = res;
          },
          error: (): void => {
            this.isDownloading = false;
          },
          complete: (): void => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.downloadService.startDownload(csvData.body!, this.data.Caption + '.csv');
            this.isDownloading = false;
          }
        });
    }
  }

  public refreshGrid(): void {
    this.fetchSearchData();
  }

  public onCellAction(event: { action: CellActionType, data: CellActionData, mouseEvent?: MouseEvent }): void {
    switch (event.action) {
      case CellActionType.DOWNLOAD_FILE: {
        this.gridService.downloadFromInstance(event.data.data);
      }
        break;
      case CellActionType.AI_BUTTON: {
        //this.aiButton.emit(event.data.data);
      }
        break;
      case CellActionType.VIEW_ONLINE: {
        this.editOnlineService.openViewOnline(event.data.data);
      }
        break;
      case CellActionType.CONSULT_RECORD: {
        const newTab = event.mouseEvent && (event.mouseEvent.ctrlKey || event.mouseEvent.metaKey);
        this.gridService.readInstance(event.data.data.CollectionsID, event.data.data.ID, event.data.data.VersionsID, event.data.data.GuidChecksum, event.data.data.DraftsID, event.data.data.OriginalFoldersID, undefined, undefined, newTab);
      }
        break;
      default:
        throw new Error('Not implemented yet');
    }
  }

  /**
   * Adds new searchdata to behaviorsubject which triggers a new search request.
   * Note (for debugging): This function is overwritten by FormCollectionListComponent
   */

  //TODO: bv check for duplicate code
  public refreshFilterGrid(filter: SearchFilterOutputDto): void {
    this.searchTerm = filter.Search;
    this.filterData = filter.SearchFilters;
    this.fetchSearchData();
  }

  public fetchSearchData(): void {
    const searchDto: Partial<GridSearchFilterSearchDto> = {
      SearchFilters: this.filterData,
      ListID: this.data.ListOptions.ListID,
      WidgetID: this.data.ID,
      CanViewHiddenDocuments: true,
      CanViewDraftDocuments: true,
      State: this.isTrashView ? StateType.Inactive : StateType.Active,
      Page: this.currentPage,
      Records: this.gridOptions.pageSize,
      Group: this.gridOptions.dataSource.group as GridGroupSortDto[],
      Sort: this.gridOptions.dataSource.group
        .concat(this.gridOptions.dataSource.sort) as GridGroupSortDto[]
    };
    if (this.lookupData) {
      searchDto.ParentFormType = this.lookupData.parentFormType;
      searchDto.ParentFormsID = this.lookupData.parentFormsId;
      searchDto.CrossLinkCollectionsID = this.lookupData.crossLinkCollectionsID;
      searchDto.ViewDataSourcesID = this.lookupData.dataDesignViewDataSourcesId;
      // Entity
      searchDto.RegisteredByID = this.lookupData.registeredBy;
      searchDto.RegisteredOnBehalfOfID = this.lookupData.registredOnBehalfOf;
      searchDto.VersionsID = this.lookupData.versionsId ?? -1;
    }

    if (this.searchTerm) {
      searchDto.Search = this.searchTerm;
    }

    this.listSearchFilter$.next(searchDto);
  }

  public toggleTrashView(): void {
    this.isTrashView = !this.isTrashView;
    this.currentPage = 1;
    this.gridData.next([]);
    this.fetchSearchData();
  }

  public onPageChange(event: PageChangeEvent): void {
    this.currentPage = (event.skip / event.take) + 1; // Update the current page number
    this.gridOptions.pageSize = event.take;
    this.fetchSearchData();
  }

  public onGroupChange(groups: Array<GroupDescriptor>): void {
    //ColumnOrder
    this.gridOptions.dataSource.group = groups;
    this.fetchSearchData();
  }

  public onSortChange(sorting: Array<SortDescriptor>): void {
    this.gridOptions.dataSource.sort = sorting;
    this.fetchSearchData();
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (this.anchor?.nativeElement) {
      if (!this.contains(event.target!)) {
        this.toggle(false);
      }
    }
  }

  public toggle(show: boolean): void {
    this.toggleMobileActionsPopup(undefined, show);
    this.toggleDownloadPopUp = show;
  }

  public toggleMobileActionsPopup(event?: MouseEvent, value?: boolean): void {
    this.showMobileActionsPopup = value != undefined ? value : !this.showMobileActionsPopup;
    if (event) {
      //prevent documentClick being called.
      event.stopImmediatePropagation();
    }

  }

  /**
   * Sets gridResultData$ to the observable that fetches the data from the server
   * When the list search filter updates and is not undefined,
   * we fetch the data from the server,
   * update the gridData
   * and update the totalItems and wheter we have an activeFilter
   */
  protected initListSearch(): void {
    this.gridResultData$ = this.listSearchFilter$.pipe(
      filter((search) => search != undefined),
      tap(() => this.loading = true),
      switchMap((search) => this.collectionListService.listSearch(
        search?.ListID!, search!)),
      tap((value) => {
        if (value.Data.length == 0 && this.currentPage > 1 && value.TotalRecords > 0) {
          this.currentPage = 1;
          this.fetchSearchData();
        } else {
          this.loading = false;
          this.activeFilter = value.ActiveSearchfilter;
          this.reportsAvailableForUser = value.ReportsAvailableForUser;
          this.gridData.next(value.Data);
        }
      }),
      switchMap((value) => {
        return of({
          totalItems: value.TotalRecords,
          activeFilter: value.ActiveSearchfilter
        });
      }),
      shareReplay(1));
  }

  private contains(target: EventTarget): boolean {
    return (this.anchor.nativeElement.contains(target) || (this.toggleDownloadPopUp ? this.anchor.nativeElement.contains(target) : false) || (this.showMobileActionsPopup ? this.mobileAnchor.nativeElement.contains(target) : false));
  }
}
