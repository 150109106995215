export function convertHtmlToPlaintext(html: string, preserveLineBreaks = false): string {
  const parser = new DOMParser();

  if (!preserveLineBreaks) {
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.innerText;
  } else { //See: https://stackoverflow.com/a/50822488
    //remove code brakes and tabs
    html = html.replace(/\n/g, '');
    html = html.replace(/\t/g, '');

    //keep html brakes and tabs
    html = html.replace(/<\/td>/g, '\t');
    html = html.replace(/<\/table>/g, '\n');
    html = html.replace(/<\/tr>/g, '\n');
    html = html.replace(/<\/p>/g, '\n');
    html = html.replace(/<\/div>/g, '\n');
    html = html.replace(/<\/h>/g, '\n');
    html = html.replace(/<br>/g, '\n');
    html = html.replace(/<br( )*\/>/g, '\n');
    html = html.replace(/<\/li>/g, '\n');

    //remove duplicate linebreaks
    html = html.replace(/<\\n\\n>/g, '\n');

    //parse html into text
    const dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');

    const value = dom.body.textContent ?? '';

    //trim the last linebreak (if there is one)
    const lastOccurrenceIndex = value.lastIndexOf('\n');
    if (lastOccurrenceIndex !== -1)
      return value.substring(0, lastOccurrenceIndex);

    return value;
  }
}