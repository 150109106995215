import { Component, Inject, OnDestroy } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MimeTypeModalData } from './mime-type-modal-data';
import { IconComponent } from '../../ui/icon/icon.component';
import { TranslatePipe } from '../../../pipes/translate/translate.pipe';
@Component({
  selector: 'mime-type-modal',
  templateUrl: './mime-type-modal.component.html',
  styleUrls: ['./mime-type-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, IconComponent, TranslatePipe]
})
export class MimeTypeModalComponent implements OnDestroy {
  private destroy = new Subject<boolean>();
  public constructor(@Inject(DIALOG_DATA) public data: MimeTypeModalData, public dialogRef: DialogRef<boolean>) {
    this.dialogRef.disableClose = true;
  }
  public ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
