// This file contains definitions for all icons used in the diagram.
export const FlowDiagramIcons: { [dataType: string]: string } = {
  'house': 'M32 18.451l-16-12.42-16 12.42v-5.064l16-12.42 16 12.42zM28 18v12h-8v-8h-8v8h-8v-12l12-9z',

  'user': 'M8 10c0-4.418 3.582-8 8-8s8 3.582 8 8c0 4.418-3.582 8-8 8s-8-3.582-8-8zM24 20h-16c-4.418 0-8 3.582-8 8v2h32v-2c0-4.418-3.582-8-8-8z',

  'external-user': 'M 25.65,22.39 C 25.72,22.38 25.80,22.37 25.88,22.37   27.32,22.37 28.50,23.53 28.50,25.00   28.50,26.45 27.32,27.62 25.88,27.62   24.40,27.62 23.25,26.45 23.25,25.00   23.25,24.92 23.25,24.85 23.26,24.77   23.52,24.89 23.81,25.00 24.12,25.00   25.09,25.00 25.88,24.22 25.88,23.25   25.88,22.94 25.77,22.64 25.65,22.39 Z M 31.14,21.08 C 32.42,22.27 33.28,23.66 33.68,24.66   33.77,24.88 33.77,25.12 33.68,25.34   33.28,26.31 32.42,27.71 31.14,28.92   29.85,30.12 28.08,31.12 25.88,31.12   23.67,31.12 21.90,30.12 20.61,28.92   19.33,27.71 18.47,26.31 18.07,25.34   17.98,25.12 17.98,24.88 18.07,24.66   18.47,23.66 19.33,22.27 20.61,21.08   21.90,19.88 23.67,18.87 25.88,18.87   28.08,18.87 29.85,19.88 31.14,21.08 Z M 25.88,21.06 C 23.70,21.06 21.94,22.83 21.94,25.00   21.94,27.17 23.70,28.94 25.88,28.94   28.05,28.94 29.81,27.17 29.81,25.00   29.81,22.83 28.05,21.06 25.88,21.06 Z M 7.81,15.63 C 7.81,15.63 17.14,15.63 17.14,15.63   19.52,15.63 21.64,16.67 23.07,18.31   21.92,18.63 20.75,19.42 19.81,20.27   18.56,21.39 17.67,22.82 17.21,23.96   17.05,24.39 17.00,24.67 17.00,25.00   17.00,25.00 1.52,25.00 1.52,25.00   0.70,25.00 0.00,24.30 0.00,23.44   0.00,19.12 3.50,15.63 7.81,15.63 Z M 19.53,7.03 C 19.53,10.92 16.38,14.06 12.50,14.06   8.62,14.06 5.47,10.92 5.47,7.03   5.47,3.15 8.62,0.00 12.50,0.00   16.38,0.00 19.53,3.15 19.53,7.03 Z',

  'up-right-from-square': 'M704 0c-25.8 0-49.2 15.6-59.2 39.6s-4.4 51.4 13.8 69.8l82.8 82.6-338.6 338.8c-25 25-25 65.6 0 90.6s65.6 25 90.6 0l338.6-338.8 82.8 82.8c18.4 18.4 45.8 23.8 69.8 13.8s39.6-33.2 39.6-59.2v-256c0-35.4-28.6-64-64-64h-256.2zM160 64c-88.4 0-160 71.6-160 160v640c0 88.4 71.6 160 160 160h640c88.4 0 160-71.6 160-160v-224c0-35.4-28.6-64-64-64s-64 28.6-64 64v224c0 17.6-14.4 32-32 32h-640c-17.6 0-32-14.4-32-32v-640c0-17.6 14.4-32 32-32h224c35.4 0 64-28.6 64-64s-28.6-64-64-64h-224z',
  'share3': 'M896 254.2c0 107.8-86.003 192.001-192 192.001-51.802 0-98.8-18.6-133.2-52l-188.2 94c1 7.8-0.4 13.999-0.4 23.8 0 8 1.4 14.2 0.4 23.8l188.2 94c34.4-33.4 81.398-53.8 133.2-53.8 105.997 0 192 84.198 192 192 0 105.997-86.003 192-192 192-107.8 0-192-86.003-192-192 0-9.798 0.4-16 1.4-23.802l-188.2-93.997c-34.4 33.402-81.4 53.798-133.2 53.798-106.040 0-192-86-192-192 0-107.8 85.96-192 192-192 51.8 0 98.8 20.4 133.2 53.8l188.2-94c-1-9.6-1.4-15.8-1.4-23.8 0-106.040 84.2-192 192-192 105.997 0 192 85.96 192 192v-1.8zM190.2 574.2c37.2 0 64-26.8 64-64 0-33.6-26.8-64-64-64-33.54 0-64 30.4-64 64 0 37.2 30.46 64 64 64zM704 190.2c-35.398 0-64 30.4-64 64 0 37.2 28.602 64.001 64 64.001s64-26.801 64-64.001c0-33.6-28.602-64-64-64zM704 832c35.398 0 64-28.602 64-64s-28.602-64-64-64c-35.398 0-64 28.602-64 64s28.602 64 64 64z',
  'file-check': 'M0 170.666c0-62.844 50.933-113.778 113.778-113.778h284.444v227.555c0 31.467 25.422 56.889 56.889 56.889h227.555v68.622c-131.378 37.156-227.555 157.867-227.555 301.156 0 105.068 51.733 197.865 131.022 254.755-5.511 0.709-11.377 1.243-17.244 1.243h-455.111c-62.845 0-113.778-51.024-113.778-113.774v-682.669zM455.111 284.444v-227.555l227.555 227.555h-227.555zM1024 711.111c0 141.332-114.666 255.999-256 255.999s-256-114.666-256-255.999c0-141.334 114.667-256 256-256s256 114.666 256 256zM847.47 634.133l-107.915 107.908-51.022-51.020c-11.022-11.2-29.155-11.2-40.177 0-11.2 11.023-11.2 29.155 0 40.178l71.111 71.112c11.022 11.198 29.158 11.198 40.18 0l128-128.001c11.198-11.022 11.198-29.155 0-40.177-11.023-11.2-29.155-11.2-40.177 0z',
  'envelop2': 'M928 128c52.998 0 96 42.98 96 96 0 30.2-14.202 58.6-38.4 76.8l-435.2 326.4c-22.8 16.998-54 16.998-76.8 0l-435.2-326.4c-24.174-18.2-38.4-46.6-38.4-76.8 0-53.020 42.98-96 96-96h832zM435.2 678.4c45.6 34.202 108 34.202 153.6 0l435.2-326.4v416c0 70.598-57.402 128-128 128h-768c-70.7 0-128-57.402-128-128v-416l435.2 326.4z',
  'pen': 'M736.8 36.6l-111.4 111.6 250.4 250.4 111.4-111.4c43.8-43.8 43.8-114.6 0-158.4l-92-92.2c-43.8-43.8-114.6-43.8-158.4 0zM576 189.2l-18.4 5.6-288.2 86.4c-39.8 12-71.4 42.4-84.6 82l-177.2 528.4c-7.6 22.6-2 47.8 14.6 64.8l307.2-307c-6-12.6-9.4-26.6-9.4-41.4 0-53 43-96 96-96s96 43 96 96-43 96-96 96c-14.8 0-28.8-3.4-41.4-9.4l-307.2 307.2c17.2 16.6 42.2 22.4 64.8 14.6l528.6-177.2c39.4-13.2 70-44.8 82-84.6l86.4-288.2 5.6-18.4-258.8-258.8z',

  'pen-plus': 'M738.459 33.106c44.157-44.142 115.53-44.142 159.681 0l92.743 92.919c44.157 44.142 44.157 115.494 0 159.636l-112.304 112.27-252.422-252.354 112.301-112.471zM576.36 186.897l260.891 260.818-5.647 18.545-19.801 66.042c-11.389-1.607-23.034-2.441-34.873-2.441-136.454 0-247.070 110.616-247.070 247.070 0 36.337 7.85 70.844 21.936 101.918l-422.825 141.702c-22.785 7.859-47.982 2.015-65.326-14.714l309.686-309.596c12.705 6.046 26.815 9.472 41.734 9.472 53.43 0 96.777-43.335 96.777-96.747 0-53.415-43.347-96.75-96.777-96.75-53.427 0-96.774 43.335-96.774 96.75 0 14.913 3.423 29.023 9.475 41.722l-309.685 309.394c-16.733-17.131-22.378-42.529-14.721-65.304l178.634-532.522c13.308-39.909 45.163-70.547 85.285-82.641l290.53-87.074 18.551-5.644zM915.852 915.852c-36.843 36.843-86.817 57.543-138.922 57.543s-102.079-20.7-138.922-57.543c-36.843-36.843-57.543-86.817-57.543-138.922s20.7-102.079 57.543-138.922c36.843-36.843 86.817-57.543 138.922-57.543s102.079 20.7 138.922 57.543c36.843 36.843 57.543 86.817 57.543 138.922s-20.7 102.079-57.543 138.922zM758.51 795.35v49.116c0 10.204 8.213 18.417 18.42 18.417s18.417-8.213 18.417-18.417v-49.116h49.116c10.207 0 18.42-8.213 18.42-18.42s-8.213-18.42-18.42-18.42h-49.116v-49.116c0-10.204-8.21-18.417-18.417-18.417s-18.42 8.213-18.42 18.417v49.116h-49.116c-10.204 0-18.417 8.213-18.417 18.42s8.213 18.42 18.417 18.42h49.116z',

  'round-diamond': 'F M79.469 442.224l362.039-362.039c38.452-38.452 100.794-38.452 139.246 0l362.039 362.039c38.452 38.452 38.452 100.794 0 139.246l-362.039 362.039c-38.452 38.452-100.794 38.452-139.246 0l-362.039-362.039c-38.452-38.452-38.452-100.794 0-139.246z',
};