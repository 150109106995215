<button
    class="w-full cursor-pointer rounded border-2 border-form-control bg-section p-2 text-button hover:border-blue-500 hover:bg-button-hover hover:shadow-sm hover:shadow-blue-600/30"
    type="button"
    (click)="onClick($event)"
>
    @if(theme) { 
        @if(showFullPreview) {
            <bizz-theme-preview-icon-full [theme]="theme" [isReadOnly]="true"> </bizz-theme-preview-icon-full>
        } @else {
            <bizz-theme-preview-icon-simple [theme]="theme" [isReadOnly]="true" layoutType="horizontal"> </bizz-theme-preview-icon-simple>
        } 
    } @else {
        <p>{{ "ChooseATheme" | translate }}</p>
    }
</button>
