import { Component, OnInit } from '@angular/core';
import { ViewStackLoadedComponent } from 'src/app/shared/interfaces/view-stack-loaded-component';
import { ViewStackService } from 'src/app/shared/services/view-stack/view-stack.service';
import { TrackChangesComponent } from '../track-changes/track-changes.component';
import { TrackChangesParams } from '../../interfaces/track-changes-params.interface';

@Component({
  selector: 'bizz-view-stack-track-changes',
  standalone: true,
  imports: [TrackChangesComponent],
  templateUrl: './view-stack-track-changes.component.html',
  styleUrl: './view-stack-track-changes.component.scss'
})
export class ViewStackTrackChangesComponent  implements ViewStackLoadedComponent, OnInit  {
  public id: string;
  public data: TrackChangesParams;
  
  public constructor(private viewStackService: ViewStackService,){
  }

  public ngOnInit(): void {
  }

  public removeFromViewStack(): void {
    // TODO: dispatch appropriate store action;
    this.viewStackService.removeLastItemFromStack();
  }

  public hasUnsavedChanges(): boolean {
    return false;
  }

}
