import { Component, DestroyRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Dialog } from '@angular/cdk/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import { Country, getAllCountries } from 'countries-and-timezones';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate, Location, NgIf } from '@angular/common';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { WorkspaceIndexDto } from '../../../../models/ts/workspace-index-dto.model';
import { selectUserSettingsFeature } from '../../../store/root-state-selectors';
import { UserSettingsApiService } from '../../../api/bizzmine/user-settings/user-settings-api.service';
import { ThemeApiService } from '../../../api/bizzmine/theme/theme-api.service';
import { ThemeService } from '../../../core/services/theme/theme.service';
import { ThousandSeparatorType } from '../../../../models/ts/thousand-separator-type.model';
import { UserSettingsIndexDto } from '../../../../models/ts/user-settings-index-dto.model';
import { UserSettingsDto } from '../../../../models/ts/user-settings-dto.model';
import { WorkspaceApiService } from '../../../api/bizzmine/workspace/workspace-api.service';
import { DecimalSeparatorType } from '../../../../models/ts/decimal-separator-type.model';
import { DelimiterType } from '../../../../models/ts/delimiter-type.model';
import { IconLibrary } from '../../../../models/ts/icon-library.model';
import { ViewStackService } from '../../../shared/services/view-stack/view-stack.service';
import { mapTimezonesDtoToMapped } from '../../../shared/functions/helpers/map-timezones';
import { mapToNewDelimiterTypes } from '../../../shared/functions/helpers/map-delimiters';
import { TimezonesDto } from '../../../../models/ts/timezones-dto.model';
import { TimezoneDto } from '../../../../models/ts/timezone-dto.model';
import { UserSettingsPasswordResetModalComponent } from '../../../shared/components/modals/user-settings-modal/Password/user-settings-password-reset-modal.component';
import { UserSettings2faResetModalComponent } from '../../../shared/components/modals/user-settings-modal/2FA/user-settings-2fa-reset-modal.component';
import { UserSettings2faSetupModalComponent } from '../../../shared/components/modals/user-settings-modal/2FA/Setup/user-settings-2fa-setup-modal.component';
import { UserSettings2faSetupModalData } from '../../../shared/components/modals/user-settings-modal/2FA/Setup/user-settings-2fa-setup-modal-data';
import { FormSubmitType } from '../../../features/bizzmine/form/enums/form-submit-type';
import { DropDownListModule, SharedModule } from '@progress/kendo-angular-dropdowns';
import { TranslatePipe } from '../../../shared/pipes/translate/translate.pipe';
import { WorkspaceSelectorComponent } from '../../../features/bizzmine/workspace-selector/components/workspace-selector/workspace-selector.component';
import { TwoFactorPipe } from '../../../shared/pipes/twofa/two-factor.pipe';
import { IconComponent } from '../../../shared/components/ui/icon/icon.component';
import { ThemeDto } from '../../../../models/ts/theme-dto.model';
import { ThemeSelectorModalComponent } from 'src/app/shared/components/modals/user-settings-modal/theme-selector-modal/theme-selector-modal.component';
import { ThemeSelectorButtonComponent } from '../../../shared/components/theme-selector-button/theme-selector-button.component';
import { map, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { userSettingsActions } from '../../../store/features/user-settings/user-settings-actions';

export type ScrollbarVisibility = 'hover' | 'always' | 'native';

@Component({
  selector: 'bizz-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  standalone: true,
  imports: [
    DropDownListModule,
    TranslatePipe,
    NgIf,
    SharedModule,
    WorkspaceSelectorComponent,
    TwoFactorPipe,
    IconComponent,
    ThemeSelectorButtonComponent
  ]
})
export class UserSettingsComponent implements OnInit {
  //TODO: Bv: 'noticed es lint errors' Check full component for code issues (or unused code).
  public index: UserSettingsIndexDto;
  public userSettings: UserSettingsDto;
  public userForm: FormGroup;
  public workspaces: WorkspaceIndexDto;
  public today: string;
  public timezones = UserSettingsComponent.getTimezones();
  public passwordReset = false;
  public ThousandSeparatorType = ThousandSeparatorType;
  public DecimalSeparatorType = DecimalSeparatorType;
  public DelimiterType = DelimiterType;
  public userSettingsSignal = this.store$.selectSignal(selectUserSettingsFeature);
  public hiddenElement: string;
  public error: string[] = [];
  public readonly IconLibrary = IconLibrary;
  public scrollBarVisible: ScrollbarVisibility = 'hover';
  public groupedData: GroupResult[];
  public virtual: any = { itemHeight: 28 };

  public currentTheme: ThemeDto | null;
  protected readonly formSubmitType = FormSubmitType;

  public constructor(
    private destroyRef: DestroyRef,
    private workspaceApiService: WorkspaceApiService,
    private fb: FormBuilder,
    private dialog: Dialog,
    private userSettingsApiService: UserSettingsApiService,
    private themeApiService: ThemeApiService,
    private themeService: ThemeService,
    private store$: Store,
    private router: Router,
    private viewStackService: ViewStackService,
    private location: Location,
    private route: ActivatedRoute) {
  }

  public get currentDateTime(): string {
    const format = this.userSettings.DateTimeFormatTypeString.replace('DD', 'dd');
    this.today = formatDate(new Date(), format, 'en', this.userSettings.TimezoneOffset).toString();
    return this.today;
  }

  /**
   * Builds a timezonesdto object from countries using moment-timezone.
   */
  public static getTimezones(): TimezonesDto[] {
    const countries = getAllCountries() as Record<string, Country>;
    const timezones: TimezonesDto[] = [];

    for (const key in countries) {
      const timezonesDto = new TimezonesDto();

      timezonesDto.CountryName = countries[key].name;
      timezonesDto.ID = countries[key].id;

      countries[key].timezones.forEach(function (country) {
        timezonesDto.Timezone.push({
            DisplayName: country,
            BaseUtcOffset: (moment.tz(moment.utc(), country)).format('Z')
          } as TimezoneDto
        );
      });
      timezones.push(timezonesDto);
    }
    return timezones;
  }

  public ngOnInit(): void {
    this.getRouteData();
    if (this.index) {
      this.userForm = this.buildForm(this.index);
    }

    const newItems = mapTimezonesDtoToMapped(this.timezones);
    this.groupedData = groupBy(newItems, [{ field: 'CountryName' }]) as GroupResult[];

    this.index.DelimiterTypes = mapToNewDelimiterTypes(this.index.DelimiterTypes);
    this.index.DecimalSeparatorTypes = mapToNewDelimiterTypes(this.index.DecimalSeparatorTypes);
    this.index.ThousandSeparatorTypes = mapToNewDelimiterTypes(this.index.ThousandSeparatorTypes);


    this.themeService.currentTheme$.pipe(
      takeUntilDestroyed(this.destroyRef),
      map((theme) => this.currentTheme = theme))
      .subscribe();

  }

  /** Gets the current URL and
   * removes the '/user-settings' part from it.
   * And navigates to the modified URL.
   */
  public goBack(): void {
    this.router.navigateByUrl(this.location.path().replace('/user-settings', '')).then(
      () => {
        window.location.reload();//needed for theme refresh.
      }
    );
  }

  public setOffset(event: any): void {
    const countries = ((event.target as HTMLInputElement).value).toString();
    const ct = countries.split(':').pop();
    this.userForm.get('TimezoneOffset')?.setValue((moment.tz(moment.utc(), ct!.trim())).format('Z'));
  }

  public openResetPasswordModal(): void {
    const dialogRef = this.dialog.open<boolean>(UserSettingsPasswordResetModalComponent);

    dialogRef.closed.subscribe({
      next: (value) => {
        if (value)
          this.passwordReset = value;
      }
    });
  }

  /*  TODO: BV check if works (think ok)*/
  public checkActiveSperator(type: string): void {
    let secondType = 'Thousand';

    if (type == 'Thousand') {
      secondType = 'Decimal';
    }

    const seperatorId = this.userForm.get(`${type}SeparatorType`)?.value;
    const secondSeperatorId = this.userForm.get(`${secondType}SeparatorType`)?.value;
    const secondElement = document.getElementById(`${secondType}${seperatorId}`);

    if (this.hiddenElement != 'none' && document.getElementById(this.hiddenElement)?.classList.contains('hidden')) {
      document.getElementById(this.hiddenElement)?.classList.remove('hidden');
    }

    if (seperatorId == 3) {
      this.hiddenElement = (`none`);
    } else {
      this.hiddenElement = (`${secondType}${seperatorId}`);
      secondElement?.classList.add('hidden');

      if (seperatorId == secondSeperatorId) {
        this.userForm.get(`${secondType}SeparatorType`)?.setValue(0);
      }
    }
  }

  public openReset2faModal(): void {
    const dialogRef = this.dialog.open<boolean>(UserSettings2faResetModalComponent);

    dialogRef.closed.subscribe({
      next: (value) => {
        if (value)
          window.location.reload();
        //this.twofaReset = value
      }
    });
  }

  public openSetup2faModal(): void {

    let name = this.userSettings.UserFirstName + '.' + this.userSettings.UserLastName;

    if (this.userSettings.UserEmail) {
      name = this.userSettings.UserEmail;
    }

    const dialogRef = this.dialog.open<boolean>(UserSettings2faSetupModalComponent, { data: new UserSettings2faSetupModalData(this.index, name) });

    dialogRef.closed.subscribe({
      next: (value) => {
        if (value)
          window.location.reload();
        //this.twofaReset = value
      }
    });
  }

  public getId(): number {
    return this.userForm.get('DefaultWorkspacesID')?.value;
  }

  public handleOutputFromSelector(result: number): void {
    this.userForm.get('DefaultWorkspacesID')?.patchValue(result);
  }

  public openThemeSelectorModal(): void {
    const dialogRef = this.dialog.open<ThemeDto>(ThemeSelectorModalComponent,
      {
        data: {
          currentTheme: this.themeService.currentTheme$.value,
          themes: this.index.Themes,
          showFullPreviewIcons: true,
          applyStyle: true
        }
      });

    dialogRef.closed.subscribe({
      next: (chosenTheme: ThemeDto | undefined) => {
        if (chosenTheme) {
          this.themeService.currentTheme$.next(chosenTheme);
          this.currentTheme = chosenTheme;
        }
      }
    });
  }

  public submitForm(): void {
    let reloadTheme = false;
    this.index.DefaultWorkspacesID = this.userForm.get('DefaultWorkspacesID')?.value;
    this.index.UserLanguagesID = this.userForm.get('UserLanguagesID')?.value;
    this.index.TimezoneName = this.userForm.get('TimezoneName')?.value;
    this.index.TimezoneOffset = this.userForm.get('TimezoneOffset')?.value;
    this.index.DateFormatType = this.userForm.get('DateFormatType')?.value;
    this.index.TimeFormatType = this.userForm.get('TimeFormatType')?.value;
    this.index.DelimiterType = this.userForm.get('DelimiterType')?.value;
    this.index.DecimalSeparatorType = this.userForm.get('DecimalSeparatorType')?.value;
    this.index.ThousandSeparatorType = this.userForm.get('ThousandSeparatorType')?.value;
    this.index.PageSizeType = this.userForm.get('PageSizeType')?.value;
    this.index.UnitType = this.userForm.get('UnitType')?.value;
    this.index.UserTwoFactorAuthenticationMethod = this.userForm.get('UserTwoFactorAuthenticationMethod')?.value;
    if (this.currentTheme) {
      reloadTheme = this.index.ThemesID !== this.currentTheme.ID;
      this.index.ThemesID = this.currentTheme.ID;
    }


    this.userSettingsApiService.validateUserSettings(this.index).pipe(take(1)).subscribe({
      error: (error) => {
        error.error.Errors.forEach((item: any) => {
          this.error.push(item.Message);
        });
      },
      complete: () => {
        // TODO: BV make action
        this.userSettingsApiService.postUserSettingsIndex(this.index).pipe(take(1)).subscribe({
          next: () => {
            if (reloadTheme)
              this.themeService.getTheme(this.index.ThemesID!).pipe(take(1)).subscribe({
                next: (theme) => {
                  this.themeService.applyCssThemeAndBranding(theme);
                }
              });
          },
          complete: () => {
            //Unfortunately this is needed because kendo's 'chart.reloadTheme() doesn't work properly.
            //So we need to reload the page.
            if (reloadTheme)
              window.location.reload();
            this.store$.dispatch(userSettingsActions.fetchUserSettings());
          }
        });
      }
    });
  }

  private getRouteData(): void {
    const data = this.route.snapshot.data;
    if (data) {
      this.userSettings = data['settings'];
      this.index = data['index'];
      this.workspaces = data['workspaces'];
    }
  }

  private buildForm(index: UserSettingsIndexDto): FormGroup {
    return this.fb.group({
      DefaultWorkspacesID: [index.DefaultWorkspacesID],
      ThemesID: [index.ThemesID],
      UserLanguagesID: [index.UserLanguagesID],
      TimezoneName: [index.TimezoneName],
      TimezoneOffset: [index.TimezoneOffset],
      DateFormatType: [index.DateFormatType],
      TimeFormatType: [index.TimeFormatType],
      DelimiterType: [index.DelimiterType],
      DecimalSeparatorType: [index.DecimalSeparatorType],
      ThousandSeparatorType: [index.ThousandSeparatorType],
      PageSizeType: [index.PageSizeType],
      UnitType: [index.UnitType],
      UserTwoFactorAuthenticationMethod: [index.UserTwoFactorAuthenticationMethod]
    });
  }
}