@if(isInGrid() || field == null || field.ViewDataSourcesID == null || field.ViewDataSourcesID <= 0){
@if(isInGrid() && instance()){
    @if(gridFileName(); as fileName) {
    @if (fileName.split('.').pop(); as ext) {
        <div class="flex cursor-pointer items-center justify-center gap-4 text-blue-500 hover:text-blue-700" (click)="downloadFromGrid()">
        <bizz-extension-icon [isUploadIcon]="true"
                             [extension]="ext"
                             [size]="'extraSmall'"
                             class="flex items-center">
        </bizz-extension-icon>
        <span class="text-small max-w-[calc(100%_-_40px)] text-wrap break-words font-semibold">{{ fileName }}</span>
       
        </div>
    }
    }
}

<bizz-file-select-multi [id]="'fileUpload_'+fileUid" (filesSelected)="filesDropped($event)"></bizz-file-select-multi>
@if (((field?.IsReadOnly || isLocked()) && !isInGrid())) {
    @if (!uploading && !validatingFile && currentFile(); as currentFile) {
        <div class="flex h-auto max-w-full flex-row items-center justify-between gap-4 text-wrap break-words rounded-t bg-button p-3 text-blue-500"
             [class.justify-center]="filePreview == null || !previewEnabled"
             [class.select-none]="!canDownload()"
             [class.cursor-pointer]="canDownload()"
             [class.hover:text-blue-700]="canDownload()"
             >
             <div class="flex flex-grow items-center gap-4" (click)="download()">

            @if (currentFile.split('.').pop(); as ext) {
                <bizz-extension-icon [isUploadIcon]="true"
                                     [extension]="ext"
                                     [size]="'extraSmall'"
                                     class="flex items-center">
                </bizz-extension-icon>
                <span class="text-small max-w-[calc(100%_-_40px)] text-wrap break-words font-semibold">{{ currentFile }}</span>
            }
        </div>
            @if (!!documentProperties()?.DocumentComparison?.InstanceCanBeCompared && filePreview != null && previewEnabled && filePreview!.DisplayType == FilePreviewDisplayType.PDF) {
        <div class="flex items-center">
            <bizz-icon class="h-5 w-5 text-blue-500" iconName="file-lines"></bizz-icon>
            @if(compareFile == undefined) {
                <bizz-loader-button buttonClass="button form text-xs" [isLoading]="loadingCompare" (click)="compare()" [disabled]="loadingCompare">{{ "ShowComparison" | translate }}</bizz-loader-button>
            } @else {
                <bizz-loader-button buttonClass="button form text-xs" [isLoading]="loadingCompare" (click)="compare()" [disabled]="loadingCompare">{{ "ShowCurrentVersion" | translate }}</bizz-loader-button>
            }

        </div>
            }

        </div>

        @if (filePreview != null && previewEnabled) {
            <!-- Preview -->
            @switch (filePreview!.DisplayType) {
                @case (FilePreviewDisplayType.Image){
                <!-- Image -->
                    <div class="mb-5 flex h-full w-full items-center justify-center rounded-b border-x-2 border-b-2 border-t-0 border-form-control bg-button p-1">
                        <img
                                [id]="'file_imagepreview_'+fileUid"
                                [src]="'data:' + filePreview.ContentType + ';base64,' + filePreview.File" alt=""/></div>
                }
                @case (FilePreviewDisplayType.PDF){
                <!-- PDF -->
                    @if (tools(); as tools) {
                        @if(showPdfViewer){
                            <kendo-pdfviewer #pdfViewer [zoom]="zoomLevel" [style]="'height: '+viewerHeight+'px; --scale-factor:1;'"
                            [data]="compareFile ?? filePreview!.File"
                            class="rounded-b border-x-2 border-b-2 border-t-0 border-form-control"
                            [class.blur-sm]="loadingCompare"
                            (download)="downloadKendoPreviewFile($event)"
                            [tools]="tools"
                            >
                        </kendo-pdfviewer>
                    }
                    @if(compareFile != undefined) {
                        @if(documentComparison(); as comparison) {
                            <div class="flex items-center justify-between">
                                <div class="flex w-full flex-wrap items-center justify-center gap-3.5 border-form-control bg-section px-4 pb-4 pt-2.5">
                                    <p>{{ 'ComparedOn' | translate }}: {{ comparison.CompareDate | date:'short' }}</p>
                                    <bizz-loader-button [isLoading]="loadingCompare" [disabled]="loadingCompare" buttonClass="button form text-xs"
                                            (click)="generatePdfCompare()">
                                        {{ "Regenerate" | translate }}
                                    </bizz-loader-button>
                                </div>
                            </div>
                        }
                    }
                    }
                }
                @case (FilePreviewDisplayType.Converting){
                    <!-- Converting -->
                    <div class="flex flex-col items-center justify-center gap-2 rounded-b border-2 border-form-control bg-section pb-5 font-sans font-semibold text-default">
                        <div class="relative flex size-14 h-full min-h-16 w-full items-center justify-center">
                            <bizz-loader [showLoader]="true" backgroundClasses="bg-transparent"></bizz-loader>
                        </div>
                        <p>{{ "PreviewBusy" | translate }}</p>
                        <button class="default" (click)="refreshFilePreview()"
                                [disabled]="refreshingPreview">{{ "Retry" | translate }}
                        </button>
                    </div>
                }
                @case (FilePreviewDisplayType.SizeWarning){
                    <!-- Size Warning -->
                    <div class="flex flex-col items-center justify-center gap-2 rounded-b border-2 border-form-control bg-section py-4 font-sans font-semibold text-default">
                        <bizz-icon
                                class="h-14 w-14 text-yellow-300"
                                iconName="file-circle-exclamation"
                                [hover]="true">
                        </bizz-icon>
                        <p class="text-sm text-widget-button">{{ "LargePreview" | translate }}
                            ({{ fileSizeLargePreview() | bytes }})</p>
                        <button class="default" (click)="refreshFilePreview(true)"
                                [disabled]="refreshingPreview">{{ "ShowPreview" | translate }}
                        </button>
                    </div>
                }
                @case (FilePreviewDisplayType.Unsupported){
                    <!-- Unsupported -->
                    <div class="flex flex-col items-center justify-center gap-2 rounded-b border-2 border-form-control bg-section py-4 font-sans font-semibold text-default">
                        <bizz-icon
                                class="h-14 w-14 text-lighter"
                                iconName="file-circle-exclamation"
                                [hover]="true">
                        </bizz-icon>
                        <p class="text-sm text-widget-button">{{ "FileNotPreviewable" | translate }}</p>
                    </div>
                }
            }
        }
    } @else {
        <div class="flex flex-col items-center justify-center gap-5 border-2 bg-section pb-5">
            <bizz-icon
                    class="m-auto block w-14 p-2 text-widget-button md:ml-auto"
                    iconName="file-circle-exclamation"
                    [hover]="true">
            </bizz-icon>
            <p class="text-sm text-widget-button">{{ "NoFile" | translate }}</p>
        </div>
    }
    @if (currentFile() && showViewOnline()) {
        <div class="flex w-full flex-wrap justify-center gap-3.5 border-form-control bg-section px-4 pb-4 pt-2.5">
            <button class="button form text-xs"
                    (click)="openOnline(FileUploadEditOnlineModalComponentMode.View)">
                <bizz-icon
                        class="ml-auto block size-3.5 text-blue-500 md:ml-0"
                        iconName="eye"
                        [hover]="true">
                </bizz-icon>
                {{ "DirectEdit_View" | translate }}
            </button>
        </div>
    }
} @else {
    <!--<ng-container *ngIf="percentage == 0 && !uploaded && !uploadFailed && !uploadOverSize && !readonly">-->
    @if (!isInGrid() && (field == null || (!field.IsReadOnly && !isLocked())) || (isInGrid() && !instance())) {
        <div bizz-drag-drop
             [disabled]="isLocked()"
             (files)="filesDropped($event)"
             (isHoveringDragDrop)="isHoveringDragDrop = $event"
             class="flex h-auto min-h-52 flex-col rounded border-2 border-dashed pt-4"
             [class.border-form-control]="!isHoveringDragDrop"
             [class.bg-section]="!isHoveringDragDrop && !currentFile() && errors == null"
             [ngClass]="{'bg-red-500/20 border-red-500' : errors, 'bg-blue-500/20 border-blue-500' : isHoveringDragDrop}">
            @if (validatingFile) {
                <div class="m-4 flex h-56 flex-col items-center justify-center gap-2 rounded sm:flex-row sm:gap-14">
                    <!-- Circular progressbar     -->
                    <kendo-circularprogressbar class="animate-spin" [value]="75"
                                               style="height: 8rem; width: 8rem">
                    </kendo-circularprogressbar>
                    <!-- Progress text     -->
                    <div class="flex flex-col items-center font-bold">
                        <p> {{ "ValidatingFile" | translate }} </p>
                    </div>
                </div>
            }
            @for (fileUpload of fileUploads; track $index) {
                @if (fileUpload.fileChunkUpload; as file) {
                    <div class="m-4 flex h-56 flex-col items-center justify-center gap-2 rounded sm:flex-row sm:gap-14">
                        <!-- Circular progressbar     -->
                        <kendo-circularprogressbar
                                [value]="file.progress" style="height: 8rem; width: 8rem">
                            <ng-template kendoCircularProgressbarCenterTemplate let-color="color" let-value="value">
                                <span [style.color]="color" class="text-lg font-bold">{{ value | number : '1.0-0' }}
                                    <span [style.color]="color" class="text-xs">%</span></span>
                            </ng-template>
                        </kendo-circularprogressbar>
                        <!-- Progress text     -->
                        <div class="flex flex-col items-center">
                            <p class="text-title text-sm">{{ file.fileName }}</p>
                            <!--TODO: How to translate this? -->
                            <p class="text-widget-on-button mb-5 text-sm">
                                {{ file.progress  | number : '1.0-0' }}%
                                {{ "of" | translate }} {{ file.size | bytes }}
                                {{ "uploaded" | translate }}</p>
                            <button class="button cancel"
                                    (click)="fileUpload.cancel()">{{ "Cancel" | translate }}
                            </button>
                        </div>
                    </div>
                }
            } @empty {
                @if (currentFile(); as currentFile) {
                    <div class="flex h-auto flex-row items-center justify-center gap-4 px-4 text-blue-500"
                         [class.cursor-pointer]="canDownload()"
                         [class.hover:text-blue-700]="canDownload()"
                         (click)="download()">
                        <div class="m-auto flex w-full max-w-full flex-wrap items-center justify-center gap-4 text-wrap break-words text-center">
                            @if (currentFile.split('.').pop(); as ext) {
                                <bizz-extension-icon [isUploadIcon]="true"
                                                     class="float-right"
                                                     [extension]="ext"
                                                     [size]="'large'">
                                </bizz-extension-icon>
                                <span class="max-w-full text-wrap break-words font-semibold">{{ latestUploadFileName ?? currentFile }}</span>
                            }
                        </div>

                    </div>
                }
                <div class="flex flex-1 flex-col items-center justify-center"
                     [ngClass]="currentFile() == null ? 'min-h-56' : ''"
                     [class.sm:flex-row]="!errors">
                    <!-- Dropzone-->
                    @if (!forceStartFromTemplate()) {
                        <div class="flex flex-1 items-center justify-center gap-4 p-4"
                             [class.flex-col]="currentFile() == null && !errors"
                             [class.cursor-pointer]="!isLocked()"
                             (click)="triggerButtonClick()">
                            <div class="flex items-center justify-center">
                                <bizz-icon
                                        class="ml-0 block text-blue-500 sm:ml-auto md:ml-0"
                                        [ngClass]="currentFile() ? 'w-7 h-7' : 'w-14 h-14'"

                                        iconName="cloud-arrow-up"
                                        [hover]="true">
                                </bizz-icon>
                            </div>
                            <div class="flex items-center justify-center">
                                <p class="text-xs sm:text-sm">{{ "DropOrUploadFile" | translate }}</p>
                            </div>
                        </div>
                    }
                    @if (currentFile() == null && showStartFromTemplate() && !errors) {
                        @if (!forceStartFromTemplate()) {
                            <div class="flex sm:items-center sm:justify-center">
                                <div class="m-2.5 h-0.5 h-full w-12 w-full rounded bg-divider-handle sm:h-12 sm:w-1">
                                </div>
                            </div>
                        }
                        <div class="flex flex-1 items-center justify-center gap-4"
                             [class.flex-col]="currentFile() == null && !errors"
                             [class.cursor-pointer]="!isLocked()"
                             (click)="startFromTemplate()">
                            <div class="flex items-center justify-center">
                                <bizz-icon
                                        class="ml-0 block text-blue-500 sm:ml-auto md:ml-0"
                                        [ngClass]="currentFile() ? 'w-7 h-7' : 'w-14 h-14'"
                                        iconName="memo"
                                        [hover]="true">
                                </bizz-icon>
                            </div>
                            <div class="flex items-center justify-center">
                                <p class="text-xs sm:text-sm">{{ "StartFromTemplate" | translate }}
                                    <br></p>
                            </div>
                        </div>

                    }
                    @if (errors) {
                        <div class="m-4 flex flex-1 items-center justify-center rounded text-red-500">
                            <div class="flex flex-col items-center justify-center rounded text-red-500">
                                @if (errors.genericError) {
                                    <p>{{ "SomethingWentWrong" | translate }}</p>
                                }
                                @if (errors.oversize) {
                                    <p>{{ "Info_FileSizeExceeded" | translate }} {{
                                            fileUploadService.formatFileSize(
                                                    errors.oversize.fileSize - errors.oversize.maxFileSize)
                                        }}
                                        , {{ "Info_MaximumFileSize" | translate }} {{
                                            fileUploadService.formatFileSize(maxFileSize)
                                        }}</p>
                                }
                                @if (errors.emptyFile) {
                                    <p>{{ "Info_EmptyFile" | translate }}</p>
                                }
                                @if (errors.emptyExtension) {
                                    <p>{{ "Info_EmptyExtension" | translate }}</p>
                                }
                                @if (errors.fileExtension) {
                                    <p>{{ "Info_RequiredToUploadCsvFile" | translate }}</p>
                                }
                                @if (errors.fileNotAllowed) {
                                    <p>{{ "ThisExtensionIsNotAllowed" | translate }}</p>
                                }
                                @if (errors.uploadError) {
                                    <p>{{ "Info_UploadError" | translate }}</p>
                                }

                            </div>
                        </div>

                        @if (!currentFile()) {
                            @if (showStartFromTemplate()) {
                                <div class="flex flex-1 items-center justify-center rounded text-red-500">
                                    <button class="button form text-xs" (click)="startFromTemplate()"
                                            [disabled]="isLocked()">
                                        <bizz-icon
                                                class="ml-auto block size-3.5 text-blue-500 md:ml-0"
                                                iconName="memo"
                                                [hover]="true">
                                        </bizz-icon>
                                        {{ "StartFromTemplate" | translate }}
                                    </button>
                                </div>
                            }
                        }
                    }
                </div>

                @if (currentFile()) {
                    <div class="flex w-full flex-wrap justify-center gap-3.5 px-4 pb-4 pt-2.5"
                         [class.bg-section]="!errors">
                        @if (showCheckOut()) {
                            <button class="button form text-xs" (click)="checkOut()" [disabled]="isLocked()">
                                <bizz-icon
                                        class="ml-auto block size-3.5 text-blue-500 md:ml-0"
                                        iconName="file-pen"
                                        [hover]="true">
                                </bizz-icon>
                                {{ "CheckOut" | translate }}
                            </button>
                        }
                        @if (showCheckIn()) {
                            <button class="button form text-xs" (click)="triggerButtonClick()"
                                    [disabled]="isLocked()">
                                <bizz-icon
                                        class="ml-auto block size-3.5 text-blue-500 md:ml-0"
                                        iconName="file-pen"
                                        [hover]="true">
                                </bizz-icon>
                                {{ "CheckIn" | translate }}
                            </button>
                        }
                        @if (showStartFromTemplate()) {
                            <button class="button form text-xs" (click)="startFromTemplate()"
                                    [disabled]="isLocked()">
                                <bizz-icon
                                        class="ml-auto block size-3.5 text-blue-500 md:ml-0"
                                        iconName="memo"
                                        [hover]="true">
                                </bizz-icon>
                                {{ "StartFromTemplate" | translate }}
                            </button>
                        }
                        @if (showEditOnline()) {
                            <button class="button form text-xs"
                                    (click)="openOnline(FileUploadEditOnlineModalComponentMode.Edit)">
                                <bizz-icon
                                        class="ml-auto block size-3.5 text-blue-500 md:ml-0"
                                        iconName="pencil"
                                        [hover]="true">
                                </bizz-icon>
                                {{ "DirectEdit_Edit" | translate }}
                            </button>
                        }
                        @if (showViewOnline()) {
                            <button class="button form text-xs"
                                    (click)="openOnline(FileUploadEditOnlineModalComponentMode.View)">
                                <bizz-icon
                                        class="ml-auto block size-3.5 text-blue-500 md:ml-0"
                                        iconName="eye"
                                        [hover]="true">
                                </bizz-icon>
                                {{ "DirectEdit_View" | translate }}
                            </button>
                        }
                        <button class="button delete rounded-md text-xs" (click)="deleteFile()"
                                [disabled]="isLocked()">
                            <bizz-icon
                                    class="ml-auto block size-3.5 text-white md:ml-0"
                                    iconName="trash"
                                    [hover]="true">
                            </bizz-icon>
                            {{ "Delete" | translate }}
                        </button>
                    </div>
                }
            }
        </div>
    }
}


} @else {
    @if (field && field.Text && field.Text.split('.').pop(); as ext) {
        <div class="flex min-h-9 cursor-pointer items-center justify-center gap-4 text-blue-500 hover:text-blue-700" (click)="downloadFromDataSource()">
            <bizz-extension-icon [isUploadIcon]="true"
                                 [extension]="ext"
                                 [size]="'extraSmall'"
                                 class="flex items-center">
            </bizz-extension-icon>
            <span class="text-small max-w-[calc(100%_-_40px)] text-wrap break-words font-semibold">{{ field.Text }}</span>
        </div>
    }
}