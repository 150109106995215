import { isPast } from 'date-fns';

// Wrapper for date fns isPast function, adding null checking
export function isPastDate(date: string | number | Date | undefined | null): boolean {
  if (date instanceof Date) {
    // Date
    return isPast(date);
  } else if (date !== null && date !== undefined) {
    // String or number, try parsing
    try {
      return isPast(new Date(date));
    } catch (e) {
      console.error(`Attempted to parse ${date} as a date.`, e);
      return false;
    }
  } else {
    // undefined or null
    return false;
  }
}