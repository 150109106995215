<div class="button-group" tabIndex="-1">
    <!-- Search -->
    @if (formField.IsLookupField && externalUserCheck(externalAccess?.AllowLookup)) {
        <button title="Search" [tabIndex]="readOnly || formField.IsReadOnly ? -1  : 0" type="button"
                class="default border-form-control" [disabled]="readOnly"
                (click)="emitAction(formControlLinkedButtonActionType.Search)">
            <bizz-icon class="size-4" iconName="magnifying-glass">
            </bizz-icon>
        </button>
    }

    <!-- Edit -->
    @if (viewDataSource != undefined && viewDataSource.ParentDataSourcesID == 0 && (viewDataSource.LinkedCollectionStorageType !=
            LinkedCollectionStorageType.Historical) && formField.Value && formField.CanEditOnStack && userType !=
    UserType.AnonymousUser && userType != UserType.ExternalUser) {
        <button title="Edit" [tabIndex]="readOnly || formField.IsReadOnly ? -1  : 0" type="button"
                class="default border-form-control" [disabled]="readOnly"
                (click)="emitAction(formControlLinkedButtonActionType.Edit)">
            <bizz-icon class="size-4" iconName="arrow-up-right-from-square"></bizz-icon>
        </button>
    }

    <!-- Consult -->
    @if (viewDataSource != undefined && viewDataSource.ParentDataSourcesID == 0 && formField.Value && formField.CanReadOnStack &&
    readOnly && userType != UserType.AnonymousUser && userType != UserType.ExternalUser) {
        <button title="Consult" [tabIndex]="readOnly || formField.IsReadOnly ? -1  : 0" type="button"
                class="default border-form-control" (click)="emitAction(formControlLinkedButtonActionType.View)">
            <bizz-icon class="size-4" iconName="eye"></bizz-icon>
        </button>
    }

    <!-- New -->

    @if (viewDataSource != undefined && viewDataSource.ParentDataSourcesID == 0 && formField.CanCreate &&
    externalUserCheck(externalAccess?.AllowCreateRecord)) {
        <button title="New" class="default border-form-control" type="button"
                [tabIndex]="readOnly || formField.IsReadOnly ? -1  : 0" [disabled]="readOnly"
                (click)="emitAction(formControlLinkedButtonActionType.Create)">
            <bizz-icon class="size-4" iconName="plus"></bizz-icon>
        </button>
    }
</div>