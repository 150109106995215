<div class="mt-5 flex gap-2 rounded bg-section p-5">
    <input class="mr-1 h-6 w-auto lg:h-8" type="radio" id="everyDay" name="daily" value="everyDay" [disabled]="readOnly"
           [checked]="state.subTab =='everyDays'" kendoRadioButton (click)="state.subTab = 'everyDays'"/>
    <div class="grid w-full grid-cols-2 gap-2 lg:inline-flex lg:items-center">
        <p>{{ 'Every' | translate }}</p>
        <kendo-combobox class="w-full lg:max-w-32" [clearButton]="false"
                        [readonly]="readOnly"
                        [data]="cronGenService.dropDownOptions.monthDays" [value]="state.everyDays.days"
                        [disabled]="(state.subTab !=='everyDays')" [valuePrimitive]="true"
                        (valueChange)="daysChange($event)"></kendo-combobox>

        <p>{{ 'day_sAt' | translate }}</p>
        <bizz-cron-gen-time-select class="lg:max-w-32" [state]="state.everyDays"
                                   [disabled]="(state.subTab !=='everyDays')"
                                   [readOnly]="readOnly"
                                   (stateChange)="cronGenTimeChange($event, 'everyDays')"></bizz-cron-gen-time-select>
    </div>
</div>
<div class="mt-5 flex gap-2 rounded bg-section p-5">
    <input class="mr-1 h-6 w-auto lg:h-8" type="radio" id="EveryWeekDayMondayThroughFridayAt" name="daily"
           value="EveryWeekDayMondayThroughFridayAt" [checked]="state.subTab =='everyWeekDay'" kendoRadioButton
           [disabled]="readOnly"
           (click)="state.subTab = 'everyWeekDay'"/>
    <div class="flex flex-wrap items-center gap-2">

        <p>{{ 'EveryWeekDayMondayThroughFridayAt' | translate }}</p>
        <bizz-cron-gen-time-select class="lg:max-w-32" [state]="state.everyWeekDay"
                                   [readOnly]="readOnly"
                                   [disabled]="(state.subTab !=='everyWeekDay')"
                                   (stateChange)="cronGenTimeChange($event, 'everyWeekDay')"></bizz-cron-gen-time-select>
    </div>
</div>