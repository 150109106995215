import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, fromEvent, map, merge, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  public isOnline: boolean = false;
  public checkIsOnline$: Subscription = Subscription.EMPTY;
  public networkStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }

  public checkNetworkStatus() {
    this.isOnline = navigator.onLine;
    this.checkIsOnline$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.isOnline = status;
        this.networkStatus$.next(this.isOnline);
        return this.isOnline;

      });
  }

  get isOnline$(){
    return this.networkStatus$;
  }
}
