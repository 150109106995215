import { Component } from '@angular/core';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { BytesPipe } from '../../../../pipes/bytes/bytes.pipe';

@Component({
  selector: 'bizz-grid-cell-file-size',
  standalone: true,
  templateUrl: './grid-cell-file-size.component.html',
  styleUrl: './grid-cell-file-size.component.scss',
  //changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [BytesPipe]
})
export class GridCellFileSizeComponent extends GridCellBaseComponent<number> {
}
