import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { FormReportComponent } from './form-report/form-report.component';
import { LoaderButtonComponent } from '../../loader-button/loader-button/loader-button.component';
import { ReportDto } from 'src/models/ts/report-dto.model';
import { IconLibrary } from 'src/models/ts/icon-library.model';
import { StepperStep } from '../../ui/stepper/interfaces/stepper-step';
import { NgTemplateOutlet } from '@angular/common';
import { StepperComponent } from '../../ui/stepper/stepper.component';
import { FromWordTemplateComponent } from './from-word-template/from-word-template.component';
import { ReportSettings } from 'src/models/ts/report-settings.model';
import { BehaviorSubject } from 'rxjs';
import { MediaType } from 'src/models/ts/media-type.model';

export enum ReportModalTabs {
  FormReport = 0,
  FromWordTemplate = 1,
}

@Component({
  selector: 'bizz-report-modal',
  standalone: true,
  imports: [
    ModalComponent,
    TranslatePipe,
    LoaderButtonComponent,
    FormReportComponent,
    NgTemplateOutlet,
    StepperComponent,
    FromWordTemplateComponent
  ],
  templateUrl: './report-modal.component.html',
  styleUrl: './report-modal.component.scss'
})
export class ReportModalComponent implements OnInit {
  public activeReportDetails: ReportDto;
  public isGenerating: boolean = false;
  public steps: StepperStep[] = [{
    label: 'FormReports',
    disabled: false,
    showLabelOnMobile: true,
    icon: { name: 'memo-pad', library: IconLibrary.FontAwesome }
  },{
    label: 'WordReports',
    disabled: false,
    showLabelOnMobile: true,
    icon: { name: 'file-word', library: IconLibrary.FontAwesome }
  }, 
  ];
  public activeTab: ReportModalTabs;
  public readonly ReportModalTabs = ReportModalTabs;
  @ViewChild('fromWordTabTemplateRef', { static: true }) private fromWordTabTemplateRef: TemplateRef<any>;
  @ViewChild('formReportTabTemplateRef', { static: true }) private formReportTabTemplateRef: TemplateRef<any>;
  public selectedFormat: MediaType | null;
  public activeTabTemplateRef: TemplateRef<any>;
  public submit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public constructor(public dialogRef: DialogRef,
                     @Inject(DIALOG_DATA) public data: { reportSettings: ReportSettings }) {

  }

  public ngOnInit(): void {
    if (!this.data.reportSettings.HasFormReports || !this.data.reportSettings.HasWordTemplateReports) {
      this.steps = [];
      if (this.data.reportSettings?.HasFormReports) {
        this.setTemplateRef(ReportModalTabs.FormReport);
      } else if (this.data.reportSettings?.HasWordTemplateReports) {
        this.setTemplateRef(ReportModalTabs.FromWordTemplate);
      }
    }
  }

  public setTemplateRef(step: number): void {
    this.activeTab = ReportModalTabs[ReportModalTabs[step] as keyof typeof ReportModalTabs];
    switch (this.activeTab) {
      case ReportModalTabs.FromWordTemplate:
        this.activeTabTemplateRef = this.fromWordTabTemplateRef;
        break;
      case ReportModalTabs.FormReport:
        this.activeTabTemplateRef = this.formReportTabTemplateRef;
        break;
    }
  }

  public onError(): void {
    this.isGenerating = false;
  }

  public onSelectedFormat(mediaType: MediaType | null): void {
    this.selectedFormat = mediaType;
  }


  public submit(): void {
    this.isGenerating = true;
    this.submit$.next(true);
  }

}
