import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class BizzTitleStrategy extends TitleStrategy {
  public constructor(private readonly title: Title) {
    super();
  }

  public override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    if (title) {
      this.title.setTitle(title);
    } else {
      this.title.setTitle(`Bizzmine - ${environment.appVersion}`);
    }
  }
}


// export const appConfig: ApplicationConfig = {
//   providers: [
//     provideRouter(routes),
//     {provide: TitleStrategy, useClass: TemplatePageTitleStrategy},
//   ]
// };