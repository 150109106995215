import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

/**
 * Displays a tooltip on an 'i' icon with the given text.
 * Utilizes the Kendo Tooltip component.
 * Not displayed if no text is given.
 * @example
 * <bizz-tooltip text="This is a tooltip"></bizz-tooltip>
 */
@Component({
  selector: 'bizz-tooltip',
  standalone: true,
  imports: [CommonModule, IconComponent, TooltipModule],
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() text: string;
}
