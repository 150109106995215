import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  private http = inject(HttpClient);

  public getAppSettingsFromJson(): Observable<AppSettings> {
    return this.http.get<AppSettings>('assets/appsettings.json');
  }

  public loadAppSettings(settings: AppSettings): void {
    if (settings && settings.deploymentType == 'onPremise') {
      environment.port = settings.port.toString();
      environment.deploymentType = settings.deploymentType;
      environment.idpUri = settings.identityProviderURI;
      environment.apiUri = settings.api;
      environment.apiAiUri = settings.apiAiUri;
      environment.tenant = settings.tenant;
      environment.sentryDsn = settings.sentryDsn;
      environment.sentryTraceSampleRate = settings.sentryTraceSampleRate;
      environment.sentryReplaySampleRate = settings.sentryReplaySampleRate;
      environment.sentryReplayErrorsSampleRate = settings.sentryReplayErrorsSampleRate;
      environment.sentryTracePropagationTargets = settings.sentryTracePropagationTargets;
      environment.sentryEnabled = settings.sentryEnabled ? 'true' : 'false';
    }
  }
}

export type AppSettings = {
  port: number,
  deploymentType: 'cloud' | 'onPremise',
  identityProviderURI: string,
  api: string,
  apiAiUri: string,
  tenant: string,
  sentryDsn: string,
  sentryTraceSampleRate: string,
  sentryReplaySampleRate: string,
  sentryReplayErrorsSampleRate: string,
  sentryTracePropagationTargets: string,
  sentryEnabled: boolean,
}
