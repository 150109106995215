import { ElementRef } from '@angular/core';

/**
 * Checks if the given target is contained within the given anchor or popup.
 * Used for Kendo Popup components.
 * @param target
 * @param anchor
 * @param popup
 * @constructor
 */
export function KendoPopupContains(target: EventTarget, anchor: ElementRef, popup: ElementRef): boolean {
  return (
    (anchor ? anchor.nativeElement.contains(target) : false) ||
    (popup ? popup.nativeElement.contains(target) : false)
  );
}
