import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ReverseEnumPipe } from '../../../../../shared/pipes/reverse-enum/reverse-enum.pipe';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { AssessmentDto } from '../../../../../../models/ts/assessment-dto.model';
import { AssessmentType } from 'src/models/ts/assessment-type.model';
import { GridModule } from '@progress/kendo-angular-grid';
import { UtcDateTimePipe } from "../../../../../shared/pipes/dates/utc-date-time.pipe";
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AssessmentGridComponent } from '../../../flow-status/components/modals/assessment-grid/assessment-grid/assessment-grid.component';

@Component({
    selector: 'bizz-form-assessments-modal',
    standalone: true,
    templateUrl: './form-assessments-modal.component.html',
    styleUrls: ['./form-assessments-modal.component.scss'],
    imports: [CommonModule, NgScrollbarModule, IconComponent, TranslatePipe, ReverseEnumPipe, GridModule, UtcDateTimePipe, AssessmentGridComponent]
})
export class FormAssessmentsModalComponent {
  protected readonly AssessmentType = AssessmentType;

  public readonly MAX_MOBILE_WIDTH = 1024;

  public constructor(
    public dialogRef: DialogRef<Array<AssessmentDto>>,
    @Inject(DIALOG_DATA) public assessmentList: Array<AssessmentDto>) {
      this.dialogRef.disableClose = true;
  }
}
