@defer (when validation) {
    @if (validation) {
        <form class="rounded-b bg-form shadow-md">
            <div class="h-2 w-full rounded-t bg-red-300"></div>
            <div class="mx-4 my-2 flex items-center gap-2 rounded-t text-red-500">
                <bizz-icon class="h-4 w-4"
                           iconName="circle-exclamation"></bizz-icon>
                <span class="text-lg font-bold">{{ "SomeFieldsRequireYourAttention" | translate }}</span>
            </div>
            <div class="mx-4 flex flex-col">
                <hr class="hidden sm:flex">
                <div class="my-2 flex flex-col gap-2">
                    <span class="mb-2 text-default">{{ "PleaseReviewFields" | translate }}</span>
                    @for (error of validation.Errors; track $index) {
                        <div class="flex items-center gap-2">
                            <span class="text-default text-sm">{{ error.Message }}</span>
                            @if(error.CollectionFormFieldID){
                                <bizz-icon (click)="validationLinkClick.emit(error.CollectionFormFieldID)"
                                class="h-4 w-4 cursor-pointer text-default"
                                iconName="link-simple"></bizz-icon>
                            }
                        </div>
                    }
                </div>
            </div>
        </form>
    }
}

