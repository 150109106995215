import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';

@Component({
  selector: 'bizz-edit-online-error-modal',
  standalone: true,
    imports: [
        IconComponent,
        TranslatePipe
    ],
  templateUrl: './edit-online-error-modal.component.html',
  styleUrl: './edit-online-error-modal.component.scss'
})
export class EditOnlineErrorModalComponent {
  public constructor(public dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: {
    title: string,
    message: string,
    subText: string
  }){

  }



}
