import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { CollectionForm } from '../../../../../../models/ts/collection-form.model';
import { TrainingAssessmentDto } from '../../../../../../models/ts/training-assessment-dto.model';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { CheckBoxModule, RadioButtonModule, TextAreaModule } from '@progress/kendo-angular-inputs';
import { EditorModule } from '@progress/kendo-angular-editor';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { GridColumnType } from '../../../../../../models/ts/grid-column-type.model';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { TrnUserSkillGradeDto } from '../../../../../../models/ts/trn-user-skill-grade-dto.model';
import { TrnSkillGradeDto } from '../../../../../../models/ts/trn-skill-grade-dto.model';
import { TrnSkillGradeExpiracyStatus } from '../../../../../../models/ts/trn-skill-grade-expiracy-status.model';
import { FormTrainingappAssessmentSliderComponent } from './form-trainingapp-assessment-slider/form-trainingapp-assessment-slider.component';
import { TrnUserDto } from '../../../../../../models/ts/trn-user-dto.model';
import { asyncScheduler, BehaviorSubject, debounceTime } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridComponent } from '@progress/kendo-angular-grid/grid.component';

@Component({
  selector: 'bizz-form-trainingapp-assessment',
  standalone: true,
  imports: [
    TranslatePipe,
    CheckBoxModule,
    EditorModule,
    IconComponent,
    RadioButtonModule,
    TextAreaModule,
    GridModule,
    GaugesModule,
    FormTrainingappAssessmentSliderComponent
  ],
  templateUrl: './form-trainingapp-assessment.component.html',
  styleUrl: './form-trainingapp-assessment.component.scss'
})
export class FormTrainingappAssessmentComponent implements AfterViewInit {

  public data: TrainingAssessmentDto;
  @Input({ required: true }) public set formData(data: CollectionForm) {
    this.data = structuredClone(data.TrainingAssessment);
  }
  @Input() public readonly: boolean;
  @Output() public trainingAssessmentDtoPropertiesChange: EventEmitter<TrainingAssessmentDto> = new EventEmitter<TrainingAssessmentDto>();
  public ExpiracyStatus = TrnSkillGradeExpiracyStatus;
  public colors = [
    {
      from: 0,
      color: '#3fad10'
    }
  ];
  protected readonly gridColumnType = GridColumnType;

  private emitUpdate$ = new BehaviorSubject<void>(undefined);
  @ViewChild('trainingAssessmentKendoGrid', { read: ElementRef })
  private kendoGridElement: ElementRef;
  @ViewChild('trainingAssessmentKendoGrid')
  private kendoGridComponent: GridComponent;
  private totalGridWidth: number;
  private combinedWidthOfOtherColumns: number;
  private initialLastColumnWidth: number;

  public constructor(private destroyRef: DestroyRef) {
    this.emitUpdate$.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500)).subscribe(() => {
      this.trainingAssessmentDtoPropertiesChange.emit(this.data);
    });
  }

  public ngAfterViewInit(): void {
    this.kendoGridComponent.columnResize.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.resizeLastGridColumn();
    });
    asyncScheduler.schedule(() => {
      this.resizeLastGridColumn();
    });
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.resizeLastGridColumn();
  }

  public emitUpdate(): void {
    this.emitUpdate$.next();
  }

  public onSkillChanged(skill: TrnUserSkillGradeDto, participants: TrnUserDto): void {
    participants.NewUserSkillGrade = skill;
    this.emitUpdate();
  }

  public getScore(skillGrade: TrnUserSkillGradeDto, defaultValue: boolean): TrnSkillGradeDto | null {
    if (!this.data || !this.data.SkillGrades || this.data.SkillGrades.length == 0) {
      return null;
    }
    const found = this.data.SkillGrades.find(x => x.ID == skillGrade.SkillGradeID);
    if (found) {
      return found;
    }
    if (defaultValue) {
      return this.data.SkillGrades[0];
    }
    return null;
  }

  private resizeLastGridColumn(): void {
    if (this.kendoGridElement == undefined) {
      throw new Error('kendoGridElement is undefined');
    }
    this.totalGridWidth = this.kendoGridElement.nativeElement.offsetWidth;
    this.combinedWidthOfOtherColumns = this.kendoGridComponent.columns.toArray().slice(0, -1).reduce(
      (accumulator, currentObject) => {
        return accumulator + currentObject.width;
      }, 0);
    if (this.kendoGridComponent.columns.last != undefined) {
      if (this.initialLastColumnWidth == undefined)
        this.initialLastColumnWidth = this.kendoGridComponent.columns.last.width + 10;

      if (this.totalGridWidth > this.combinedWidthOfOtherColumns)
        this.kendoGridComponent.columns.last.width =
          Math.max(this.totalGridWidth - this.combinedWidthOfOtherColumns, this.initialLastColumnWidth);
    }
  }
}
