<div class="modal-container justify-between sm:h-full md:justify-normal" [class]="'modal-'+modalSize">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0" (click)="dialogRef.close()" iconName="xmark"
                   [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body overflow-auto">
        <ng-container [ngTemplateOutlet]="headerTemplate || defaultHeader"></ng-container>
        <ng-template #defaultHeader>
            <div class="modal-header pb-0">
                <h1 class="text-center font-title text-xl font-bold text-default">
                    {{ title | translate }}
                </h1>
                @if (message) {
                <p class="mt-5 text-center text-sm font-normal">
                    {{ message | translate }}
                </p>
                }
            </div>
            <hr class="my-5"/>
        </ng-template>
        <ng-container [ngTemplateOutlet]="contentTemplate || defaultContent"></ng-container>
        <ng-template #defaultContent>
            <div>
                <label class="mb-2 inline-block text-sm text-default">
                    content
                </label>
            </div>
        </ng-template>
    </div>
    @if (footerTemplate) {
        <div class="modal-buttons">
            <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
        </div>
    }
</div>