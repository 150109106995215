import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FlowStatusTaskType } from '../../../interfaces/flow-status-task-type';
import { FlowStatusTasksGridMobileColumnComponent } from './columns/flow-status-tasks-grid-mobile-column/flow-status-tasks-grid-mobile-column.component';
import { FlowStatusTasksGridCompletedByColumnComponent } from './columns/flow-status-tasks-grid-completed-by-column/flow-status-tasks-grid-completed-by-column.component';
import { SkeletonModule } from '@progress/kendo-angular-indicators';
import { StatusIconComponent } from '../../../../../../shared/components/ui/status-icon/status-icon.component';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { FlowStatusTaskDto } from '../../../../../../../models/ts/flow-status-task-dto.model';
import { OrganizationChartItemType } from 'src/models/ts/organization-chart-item-type.model';
import { OrgChartSelectComponent } from 'src/app/features/bizzmine/org-chart/org-chart-select/org-chart-select.component';
import { GridComponent as KendoGridComponent } from '@progress/kendo-angular-grid/grid.component';
import { BehaviorSubject, debounceTime, map, take } from 'rxjs';
import { resizeLastGridColumn } from '../../../../../../shared/components/grid/helpers/resize-last-grid-column';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'bizz-flow-status-tasks-grid',
  standalone: true,
  imports: [CommonModule, GridModule, StatusIconComponent, TranslatePipe, IconComponent, NgScrollbarModule, FlowStatusTasksGridMobileColumnComponent, FlowStatusTasksGridCompletedByColumnComponent, SkeletonModule, OrgChartSelectComponent],
  templateUrl: './flow-status-tasks-grid.component.html',
  styleUrls: ['./flow-status-tasks-grid.component.scss']
})
@Sentry.TraceClass({ name: 'FlowStatusTasksGridComponent' })
export class FlowStatusTasksGridComponent implements AfterViewInit {

  @Input({ required: true })
  public set tasks(value: FlowStatusTaskDto[]) {
    this.tasksInternal = value;
    if (this.kendoGridComponent) {
      this.fitColumnsToContent();
    }
  }

  @Input({ required: true }) public taskType: FlowStatusTaskType;

  @ViewChild('flowStatusGrid') public kendoGridComponent: KendoGridComponent;
  @ViewChild('flowStatusGrid', { read: ElementRef }) private kendoGridElement: ElementRef;

  @Output() public showAssessmentDetails = new EventEmitter<FlowStatusTaskDto>();
  @Output() public taskDetails = new EventEmitter<FlowStatusTaskDto>();


  public readonly MAX_MOBILE_WIDTH = 1024;
  public readonly TAILWIND_LG_BR = 1280;
  private readonly MIN_COL_WIDTH = 150;

  public tasksInternal: FlowStatusTaskDto[] = [];

  private intersectionObserver: IntersectionObserver;
  private resizeObserver: ResizeObserver;
  private lastObservedElementWidth: number = 0;
  private isGridInView = false;
  private gridWidth$ = new BehaviorSubject<number>(0);
  private initialLastColumnWidth: number | undefined;

  public constructor(private ngZone: NgZone) {
  }

  @Sentry.TraceMethod({ name: 'ngOnDestroy' })
  public ngOnDestroy(): void {
    this.kendoGridComponent.columnResize.unsubscribe();
    this.gridWidth$.unsubscribe();
    this.intersectionObserver.disconnect();
    this.resizeObserver.disconnect();
  }

  private fitColumnsToContent(): void {
    this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      if (this.isGridInView && this.kendoGridComponent && this.tasksInternal.length > 0) {
        this.kendoGridComponent.resizable = true;
        this.kendoGridComponent.columns.forEach((column, index) => {
          this.kendoGridComponent.autoFitColumn(column);
          if (column.width < this.MIN_COL_WIDTH) {
            column.width = this.MIN_COL_WIDTH;
          }
        });
        if (this.initialLastColumnWidth == undefined)
          this.initialLastColumnWidth = this.kendoGridComponent.columns.last.width + 10;

        resizeLastGridColumn(
          this.kendoGridComponent.columns.toArray(),
          this.kendoGridElement.nativeElement.clientWidth, 0, this.initialLastColumnWidth);
        this.kendoGridComponent.resizable = false;
      }
    });
  }

  public ngAfterViewInit(): void {
    this.kendoGridComponent.columnResize.subscribe(() => {
      this.fitColumnsToContent();
    });
    //Intersection observer to check if the grid is in view
    //This is needed to reliably autofit columns, because autofitting columns when the grid is not in view
    //will cause the columns to be resized to 0.
    this.intersectionObserver = new IntersectionObserver(([entry]) => {
      this.isGridInView = entry.isIntersecting;
      if (entry.isIntersecting &&
        this.lastObservedElementWidth != entry.boundingClientRect.width) {
        this.lastObservedElementWidth = entry.boundingClientRect.width;
        this.fitColumnsToContent();
      }
    });
    this.intersectionObserver.observe(this.kendoGridElement.nativeElement);
    this.resizeObserver = new ResizeObserver(([entry]) => {
      this.ngZone.run(() => {
        if (this.lastObservedElementWidth != entry.contentRect.width) {
          this.lastObservedElementWidth = entry.contentRect.width;
          this.gridWidth$.next(entry.contentRect.width);
        }
      });
    });

    this.resizeObserver.observe(this.kendoGridElement.nativeElement);
    this.gridWidth$.pipe(
      debounceTime(150),
      map(() => this.fitColumnsToContent()))
      .subscribe();
  }

  protected readonly OrganizationChartItemType = OrganizationChartItemType;
  protected readonly FlowStatusTaskType = FlowStatusTaskType;

}
