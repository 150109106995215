import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserSettingsEffects } from './user-settings-effects';
import { userSettingsFeature } from './user-settings-feature';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(userSettingsFeature),
    EffectsModule.forFeature(UserSettingsEffects)
  ]
})
export class UserSettingsFeatureStoreModule {
}
