import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { flowStatusFeature } from './flow-status-feature';
import { FlowStatusEffects } from './flow-status-effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(flowStatusFeature),
    EffectsModule.forFeature(FlowStatusEffects),
  ],
})
export class FlowStatusFeatureStoreModule {
}
