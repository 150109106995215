// Collections that may not be directly edited by anyone
// TRN Collections are also protected, but may be edited/consulted
import { ProtectedCollectionType } from '../../../models/ts/protected-collection-type.model';

export const StrictlyProtectedCollectionTypes: Array<ProtectedCollectionType> = [
  ProtectedCollectionType.Users,
  ProtectedCollectionType.Functions,
  ProtectedCollectionType.Departments,
  ProtectedCollectionType.Roles,
  ProtectedCollectionType.DepartmentDepartments,
  ProtectedCollectionType.DepartmentFunctions,
  ProtectedCollectionType.DepartmentUsers,
  ProtectedCollectionType.FunctionDepartments,
  ProtectedCollectionType.FunctionFunctions,
  ProtectedCollectionType.FunctionUsers,
  ProtectedCollectionType.RoleDepartments,
  ProtectedCollectionType.RoleFunctions,
  ProtectedCollectionType.RoleUsers
];