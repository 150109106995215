<div class="group flex h-10 cursor-pointer items-center gap-4 px-2.5 py-1 sm:h-9"
 [ngClass]="{ 'bg-button-hover !border-blue-500': isSelected,
 'flex-col py-2.5 h-auto sm:h-auto text-left' : layoutType === 'vertical',
'hover:bg-button-hover': !isReadOnly && !isInput,
'bg-button rounded border-3 border-button': !isInput,
'pointer-events-none !cursor-not-allowed bg-button-disabled': isReadOnly
 }"
(click)="onClick()" >
    <p class="max-w-28 overflow-hidden text-ellipsis whitespace-nowrap text-left font-sans text-base font-semibold text-default"
    [ngClass]="{ 'group-hover:text-blue-400': !isReadOnly, 'text-blue-400': !isReadOnly && isSelected }">{{ internalTheme.Name }}</p>
    <svg #iconComponent width="90" height="21" viewBox="0 0 113 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 6C1 3.23858 3.23858 1 6 1H20C22.7614 1 25 3.23858 25 6V20C25 22.7614 22.7614 25 20 25H6C3.23858 25 1 22.7614 1 20V6Z" 
        fill="rgb(var(--workspace-selector-bg))"/>
        <path d="M1 6C1 3.23858 3.23858 1 6 1H20C22.7614 1 25 3.23858 25 6V20C25 22.7614 22.7614 25 20 25H6C3.23858 25 1 22.7614 1 20V6Z" 
        stroke="rgb(var(--form-control-border))" stroke-width="2"/>
        <path d="M30 6C30 3.23858 32.2386 1 35 1H49C51.7614 1 54 3.23858 54 6V20C54 22.7614 51.7614 25 49 25H35C32.2386 25 30 22.7614 30 20V6Z" 
        fill="rgb(var(--header-bg))"/>
        <path d="M30 6C30 3.23858 32.2386 1 35 1H49C51.7614 1 54 3.23858 54 6V20C54 22.7614 51.7614 25 49 25H35C32.2386 25 30 22.7614 30 20V6Z" stroke="rgb(var(--form-control-border))" stroke-width="2"/>
        <path d="M59 6C59 3.23858 61.2386 1 64 1H78C80.7614 1 83 3.23858 83 6V20C83 22.7614 80.7614 25 78 25H64C61.2386 25 59 22.7614 59 20V6Z" fill="rgb(var(--sidebar-bg))"/>
        <path d="M59 6C59 3.23858 61.2386 1 64 1H78C80.7614 1 83 3.23858 83 6V20C83 22.7614 80.7614 25 78 25H64C61.2386 25 59 22.7614 59 20V6Z" stroke="rgb(var(--form-control-border))" stroke-width="2"/>
        <path d="M88 6C88 3.23858 90.2386 1 93 1H107C109.761 1 112 3.23858 112 6V20C112 22.7614 109.761 25 107 25H93C90.2386 25 88 22.7614 88 20V6Z" fill="rgb(var(--body-bg))"/>
        <path d="M88 6C88 3.23858 90.2386 1 93 1H107C109.761 1 112 3.23858 112 6V20C112 22.7614 109.761 25 107 25H93C90.2386 25 88 22.7614 88 20V6Z" stroke="rgb(var(--form-control-border))" stroke-width="2"/>
    </svg>
</div>