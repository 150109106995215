import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { FormsModule } from '@angular/forms';
import { PopperComponent } from '../../popper/popper.component';
import { PermissionDto } from '../../../../../../models/ts/permission-dto.model';
import { OrganizationChartItemType } from '../../../../../../models/ts/organization-chart-item-type.model';
import { AccessType } from '../../../../../../models/ts/access-type.model';
import { UpdateDeleteState } from '../../../../../../models/ts/update-delete-state.model';
import { DropDownListModule, SharedDirectivesModule } from '@progress/kendo-angular-dropdowns';
import { OrganizationChartControlComponent } from '../../../../../features/bizzmine/form/components/controls/organization-chart-control/organization-chart-control.component';
import { OrgChartSelectComponent } from 'src/app/features/bizzmine/org-chart/org-chart-select/org-chart-select.component';
import { BehaviorSubject } from 'rxjs';
import { OrgChartItem } from 'src/app/features/bizzmine/org-chart/interfaces/org-chart-item';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'bizz-access-permission',
  standalone: true,
  imports: [
    CommonModule,
    TranslatePipe,
    OrganizationChartControlComponent,
    FormsModule,
    PopperComponent,
    DropDownListModule,
    SharedDirectivesModule,
    OrgChartSelectComponent
  ],
  templateUrl: './access-permission.component.html',
  styleUrls: ['./access-permission.component.scss']
})
export class AccessPermissionComponent implements OnInit {
  @Input() public disabled = false;
  @Input() public floatLeft = false;
  @Input() public popperText = '';
  @Input() public item: PermissionDto;
  @Output() public itemChange = new EventEmitter<PermissionDto>();
  @Input() public parentId: number;
  @Input() public currentUser = false;
  @Input() public currentUserItem?: OrgChartItem[] | undefined;

  protected autoFillCurrentUser: boolean;
  protected types: OrganizationChartItemType[];
  protected keepRemovedTags: boolean;
  protected accessList: (
    | { id: AccessType.Nobody; text: string }
    | { id: AccessType.Everybody; text: string }
    )[];
  private oldAccessType = AccessType.Nobody;

  //TODO: RV implement access permisson box with orgchart input
  public selectedOrgChartItems$ = new BehaviorSubject<Array<OrgChartItem>>([]);

  public constructor(private destroyRef: DestroyRef) {
    this.autoFillCurrentUser = false;
    this.types = [
      OrganizationChartItemType.User,
      OrganizationChartItemType.Function,
      OrganizationChartItemType.Department
    ];

    this.keepRemovedTags = false;

    this.selectedOrgChartItems$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((items) => {
      if (this.item && items) {
        this.item.SelectedOrganizationChartItemsDisplay = items;
      }
    });

  }

  public selectedOrgChartItemsWithAllStateChange(items: OrgChartItem[]): void {
      this.item.SelectedOrganizationChartItems = items;
  }


  public ngOnInit(): void {
    this.accessList = [
      { id: AccessType.Nobody, text: 'Nobody' },
      { id: AccessType.Everybody, text: 'Everybody' },
    ];

      if(!this.item.SelectedOrganizationChartItemsDisplay) {
        this.selectedOrgChartItems$.next(this.item.SelectedOrganizationChartItems);
      }else{
        this.selectedOrgChartItems$.next(this.item.SelectedOrganizationChartItemsDisplay);
      }
   // if(!this.item.SelectedOrganizationChartItemsDisplay) this.selectedOrgChartItems$.next(this.item.SelectedOrganizationChartItems);
    if (this.item.IsEnabled === undefined) {
      console.warn('Permission does not has the IsEnabled property');
      this.item.IsEnabled = true;
    }

    if (this.item != undefined)
      this.oldAccessType = this.item.AccessType;

    //TODO: RV check this with new orgchart component
    if (this.parentId !== undefined) {
      this.autoFillCurrentUser = !!(this.currentUser && this.parentId === 0);
    }
  }

  protected setState(): void {
    // when nobody has been changed to everybody or otherwise then we have to update the state of the permission
    if (this.oldAccessType !== this.item.AccessType)
      this.item.State = UpdateDeleteState.Update;
    else
      this.item.State = UpdateDeleteState.Default;
  }
}
