import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private sidebarExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public set expanded(expanded: boolean) {
    this.sidebarExpanded.next(expanded);
  }

  public get expanded(): boolean {
    return this.sidebarExpanded.getValue();
  }

  public toggleSidebar(): void {
    this.expanded = !this.expanded;
  }

  public collapseSidebar(collapsed: boolean): void{
    this.expanded = !collapsed;
  }

  public getObservable(): Observable<boolean> {
    return this.sidebarExpanded.asObservable();
  }
}
