export enum AuditLogItemType {
	Instance = 1,
	Collection = 2,
	Subcollection = 3,
	Form = 4,
	List = 5,
	Search = 6,
	Template = 7,
	DataStructure = 8,
	CollectionField = 9,
	LinkedCollection = 10,
	CollectionFormField = 11,
	DataDesign = 12,
	Permission = 13,
	CollectionProperties = 14,
	Theme = 15,
	Flow = 20,
	FlowVersion = 21,
	Steps = 22,
	Task = 23,
	AssessmentStep = 24,
	AssessmentTask = 25,
	PublicationStep = 26,
	PublicationTask = 27,
	DistributionStep = 28,
	DistributionTask = 29,
	Notification = 30,
	Reminder = 31,
	TrainingAppAssessmentStep = 32,
	TrainingAppSubscribeToExamStep = 33,
	TrainingAppExaminationStep = 34,
	TrainingAppPublishExamStep = 35,
	Workspace = 40,
	WorkspaceItem = 41,
	Widget = 42,
	AccountSettings = 60,
	Solution = 70,
	OrgChart = 100,
	Role = 104,
	Import = 105,
	ImportConfig = 106,
	ImportConfigSettings = 107,
	Report = 110,
	File = 111,
	ListReport = 112,
	History = 113,
	TrackChanges = 114,
	Property = 121,
	PdfConversion = 130,
	Formula = 131,
	Evaluation = 132,
	Watermark = 133,
	ElectronicSignature = 134,
	TrainingApp = 135,
	TrainingQueue = 136
}