<div class="modal-container">
    <div class="modal-close-container">
        <bizz-icon iconName="xmark" (click)="dialogRef.close()"></bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1>
                {{ (this.data.checkIn ? "CheckInFile" : "PutFileWithoutCheckIn") | translate }} {{ data.data.Title }}
            </h1>
            <hr class="m-auto mb-5 mt-5 h-0 w-full border-t-2 border-divider">
        </div>

        @if (errors.length > 0) {
            <div class="rounded bg-yellow-200/50 text-default">
                <div class="m-3 flex items-center gap-2 rounded">
                    <bizz-icon class="size-6 text-yellow-500"
                               iconName="triangle-exclamation"></bizz-icon>
                    <div class="flex flex-col gap-2">
                        @if (errors.length > 0) {
                            @for (errorMessage of errors; track errorMessage) {
                                <p>{{ errorMessage | translate }}</p>
                            }
                        }
                    </div>
                </div>

            </div>
        }

        <div class="flex flex-col gap-8 overflow-y-scroll pr-4">
            <div class="flex">
                <div class="flex flex-col basis-1/2 mr-6">
                    <label>{{ "Title" | translate }}</label>
                    <input disabled [value]="data.data.Title">
                </div>

                <div class="flex flex-col basis-1/4 mr-2">
                    <label>{{ "Extension" | translate }}</label>
                    <input disabled [value]="fileExtension">
                </div>

                <div class="flex flex-col basis-1/4">
                    <label>{{ "Size" | translate }}</label>
                    <input disabled [value]="fileSize">
                </div>
            </div>
            <bizz-file-upload
                    [collectionsId]="data.data.CollectionsID"
                    (fileChanged)="fileChanged($event)"
                    [versionsId]="versionId"
                    [instancesId]="data.data.ID"
                    [documentTemplate]="documentTemplate">
            </bizz-file-upload>

            <div class="flex flex-col gap-4">
                <label>{{ "ReasonModification" | translate }} <span class="text-red-500">*</span></label>
                    <kendo-editor
                            class="w-full group kendo-toolbar_hidden min-w-full max-h-[200px] min-h-[200px] max-w-full"
                            [iframe]="false"
                            [resizable]="false"
                            [(ngModel)]="modificationReason">
                    </kendo-editor>
            </div>
        </div>


        <div class="modal-buttons pt-4">
            <button class="default" (click)="dialogRef.close()">
                {{ 'Cancel' | translate }}
            </button>
            <button class="save" (click)="save()">
                {{ 'Save' | translate }}
            </button>
        </div>
    </div>
</div>

