import { Component, inject, OnInit } from '@angular/core';
import { DialogModule } from '@angular/cdk/dialog';
import { IconComponent } from '../../../icon/icon.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { AsyncPipe, DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '../../../../../pipes/translate/translate.pipe';
import { NotificationBlockComponent } from '../notification-block/notification-block.component';
import { NotificationPopUpDto } from '../../../../../../../models/ts/notification-pop-up-dto.model';
import { EditorModule } from '@progress/kendo-angular-editor';
import { HeaderNotificationsService } from '../../../../../services/header/header-notifcations/header-notifications.service';
import { headerNotificationsFeature } from '../../../../../../store/features/header-notifications/header-notifications-feature';
import { headerNotificationsActions } from '../../../../../../store/features/header-notifications/header-notifications-actions';
import { HeaderPopupListBase } from '../../classes/header-popup-list-base';
import { LoaderComponent } from '../../../loader/loader.component';
import { ListViewModule } from '@progress/kendo-angular-listview';


/**
 * Notification component that shows modal in header.
 */

@Component({
    selector: 'bizz-header-notifications',
    templateUrl: './header-notifications.component.html',
    styleUrls: ['./header-notifications.component.scss'],
    standalone: true,
  imports: [
    IconComponent,
    NotificationBlockComponent,
    PopupModule,
    DatePipe,
    RouterLink,
    NgIf,
    NgForOf,
    DialogModule,
    TranslatePipe,
    AsyncPipe,
    EditorModule,
    ListViewModule,
    LoaderComponent,
    NgClass
  ]
})
export class HeaderNotificationsComponent extends HeaderPopupListBase<NotificationPopUpDto> implements OnInit {

  public data = this.store$.selectSignal(headerNotificationsFeature.selectNotifications);
  public count = this.store$.selectSignal(headerNotificationsFeature.selectCount);
  public loading = this.store$.selectSignal(headerNotificationsFeature.selectLoading);
  private headerNotificationsService = inject(HeaderNotificationsService);

  public open(id: number): void {
    this.headerNotificationsService.openNotification(id);
    this.togglePopup();
  }

  public readNotifications(): void {
    this.store$.dispatch(headerNotificationsActions.readNotifications({ ids: this.data().map(n => n.ID) }));
  }

  public refresh(): void {
    this.store$.dispatch(headerNotificationsActions.fetchNotifications());
  }

  public readNotification(id: number): void {
    this.store$.dispatch(headerNotificationsActions.readNotification({ id }));
  }

  public unreadNotification(id: number): void {
    this.store$.dispatch(headerNotificationsActions.unreadNotification({ id }));
  }

  protected search(searchTerm: string): void {
    this.store$.dispatch(headerNotificationsActions.updateSearch({ search: searchTerm }));
  }
}
