import { Injectable } from '@angular/core';
import { MessageService } from '@progress/kendo-angular-l10n';
import { BizzMineLocalStorageService } from '../../../shared/services/localStorage/bizzmine-local-storage.service';

interface Message {
  [key: string]: string;
}

interface Messages {
  messages: Message;
}

interface Translation {
  Key: string;
  Value: string;
}

@Injectable({
  providedIn: 'root'
})

export class MessageTranslationService extends MessageService {
  public data: { [lang: string]: Messages } | undefined = undefined;

  public constructor(private localStorage: BizzMineLocalStorageService) {
    super();
  }

  public set language(value: number) {
    const messages: Messages = {
      messages: {}
    };

    this.data = {
      en: {
        messages: {
        }
      },
    };

    // Retrieve translations from localStorage by set usersettings-language
    const translations = this.localStorage.getItem(`BizzMineTranslations${value}`);

    if (translations == null){
     return;
    }

    // Parses translations to key:value
    const parsedTranslations = translations ? JSON.parse(translations) : JSON.parse('{"key":"value"}');

    // Filters parsed translations to only return 'kendo.'
    const kendoTranslationsOnly = parsedTranslations.filter((t: Translation) => t.Key.includes('kendo'));

    if (kendoTranslationsOnly){
        // Populate the messages object
        kendoTranslationsOnly.forEach((pair: { Key: string | number; Value: string; }) => {
        messages.messages[pair.Key] = pair.Value;
      });

      // All messages go under 'en' tho en could also hold nl messages
      this.data['en'].messages = messages.messages;

      // Notifys kendo-service to update components
      this.notify();
    }
  }

  private get messages(): any {
    // Gets kendo translation messages

    if (this.data){
      const lang = this.data['en'];

      if (lang) {
        return lang.messages;
      }
    }else{
      return '';
    }
  }

  public override get(key: string): string {
      return this.messages[key];
  }
}