import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ChatRequest } from 'src/models/ts/chat-request';
import { ChatResult } from 'src/models/ts/chat-result';
import { AiButtonModel } from '../../../../models/ts/ai-button-model';

@Injectable({
  providedIn: 'root'
})
export class AiApiService {

  public constructor(private http: HttpClient) { 

  }

  public chat(request: ChatRequest): Observable<ChatResult> {
    return this.http.post<ChatResult>(`api/ai/chat`, request);
  }

  public chatStream(request: ChatRequest): Observable<string> {
    return this.http.post<string>(`api/ai/chat`, request);
  }

  public autoComplete(aiButton: AiButtonModel): Observable<string> {
    return this.http.post<string>(`api/ai/autocomplete/form/${aiButton.FormId}/collection/${aiButton.CollectionsID}/instance/${aiButton.InstancesID}/version/${aiButton.VersionsID}/aiButton/${aiButton.AiButtonID}`, aiButton.Fields);
  }
}
