@if( schedulerData && schedulerFormGroup) {

<form [formGroup]="schedulerFormGroup" class="bg-form shadow-dark-grey-md sm:rounded-b">

    <div class="rounded shadow-dark-grey-md">

        <bizz-form-accordion-header [canCollapse]="false" accentClass="bg-blue-500">
            <bizz-icon class="h-4 w-4 text-blue-500" iconName="watch"></bizz-icon>
            <span class="font-title text-lg font-bold text-blue-500 sm:text-base">
                {{'Recurrence' | translate}}
            </span>
        </bizz-form-accordion-header>


        <div class="p-4 text-form sm:rounded-b sm:px-5 sm:pb-3 sm:pt-1.5">
            <div class="mb-2 flex flex-col gap-2">
                <label class="flex text-sm font-semibold">
                    {{'Name' | translate}}
                    <span class="relative -top-1 float-right mr-3 h-5 text-xl leading-tight text-red-500">*</span>
                </label>
                <div class="flex">
                    <bizz-shared-alpha-numeric-control [options]="{placeholder: 'Name', readonly: formData.IsLocked}"
                        [control]="('Name' | SharedFieldFormControl: schedulerFormGroup)"></bizz-shared-alpha-numeric-control>

                </div>
            </div>

            <div class="mb-2 flex flex-col gap-2">
                <label class="flex text-sm font-semibold">
                    {{'StartDate' | translate}}
                </label>
                <div class="flex">
                    <bizz-shared-date-picker-control [options]="{placeholder: 'StartDate', readonly: formData.IsLocked}"
                        [control]="('StartDate' | SharedFieldFormControl: schedulerFormGroup)"></bizz-shared-date-picker-control>

                </div>
            </div>


            <div class="mb-2 flex flex-col gap-2">
                <label class="flex text-sm font-semibold">
                    {{'EndSchedulerBasedOn' | translate}}
                </label>
                <div class="flex">
                    <!-- TODO: Fix hardcoded array-->
                    <bizz-shared-combobox-control [options]="{placeholder: this.endTypes[0], values: this.endTypes, readonly: formData.IsLocked}"
                        [control]="('EndType' | SharedFieldFormControl: schedulerFormGroup)" ></bizz-shared-combobox-control>
                </div>
            </div>

            @if(schedulerFormGroup.value.EndType == EnumSchedulerEndType.EndDate){
            <div class="mb-2 flex flex-col gap-2">
                <label class="flex text-sm font-semibold">
                    {{'EndDate' | translate}}
                </label>
                <div class="flex">
                    <bizz-shared-date-picker-control [options]="{placeholder: 'EndDate', readonly: formData.IsLocked}"
                        [control]="('EndDate' | SharedFieldFormControl: schedulerFormGroup)"></bizz-shared-date-picker-control>
                </div>
            </div>
            } @else if(schedulerFormGroup.value.EndType == EnumSchedulerEndType.EndAfter){
            <div class="mb-2 flex flex-col gap-2">
                <label class="flex text-sm font-semibold">
                    {{'Total' | translate}}
                </label>
                <div class="flex">
                    <bizz-shared-numeric-control [options]="{placeholder: '1', readonly: formData.IsLocked}"
                        [control]="('EndAmount' | SharedFieldFormControl: schedulerFormGroup)"></bizz-shared-numeric-control>
                </div>
            </div>
            }
            
            <bizz-cron-gen [cronExpression]="schedulerData.CronExpression" (updateCron)="setCronExpression($event)"> </bizz-cron-gen>
        </div>
    </div>
</form>
}

