import { FlowDiagramTools } from './flow-diagram-tools';

//Defines the link between the tool and the category of block used in the diagram.
export const FLOW_DIAGRAM_TOOL_CATEGORIES: { [dataType: number]: string } = {
  [FlowDiagramTools.AddBlock]: 'normal',
  [FlowDiagramTools.addPublicationBlock]: 'publication',
  [FlowDiagramTools.addAssessmentBlock]: 'assessment',
  [FlowDiagramTools.addTrainingAppAssessmentBlock]: 'trainingappassessment',
  [FlowDiagramTools.addTrainingAppSubscribeToExamBlock]: 'trainingappsubscribetoexam',
  [FlowDiagramTools.addTrainingAppExaminationBlock]: 'trainingappexamination',
  [FlowDiagramTools.addTrainingAppPublishExamBlock]: 'trainingapppublishexam',
  [FlowDiagramTools.addDistributionBlock]: 'distribution',
  [FlowDiagramTools.AddGateway]: 'gateway',
  [FlowDiagramTools.AddStart]: 'start',
  [FlowDiagramTools.AddStop]: 'end',
};
