<div class="flex h-full w-full flex-col gap-1 bg-body-alternate">
    <div class="flex flex-wrap justify-between gap-1 border-b-2 border-sidebar bg-page-header px-4 py-2 sm:pr-2">
        <button class="default" (click)="onBackClicked()">
            <bizz-icon class="h-4 w-4" iconName="caret-left"></bizz-icon>
            <span>{{ 'Back' | translate}}</span>
        </button>

        <button class="save min-w-16" type="submit" (click)="save()">
            <bizz-icon class="size-4" iconName="circle-check"></bizz-icon>
            {{ 'Save' | translate }}
        </button>
    </div>
    <div class="m-5 h-[calc(100%_-_4rem)] overflow-y-auto bg-form">

        <div class="mx-auto mb-4 w-full max-w-5xl gap-2.5 rounded p-4">
            
            <h1 class="my-3 font-title text-default">{{'InstancePermissions' | translate}}</h1>
            
            
            <bizz-permissions-data [data]="data"></bizz-permissions-data>
        </div>
    </div>
    
</div>