import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { TaskInfoDto } from '../../../../../../../models/ts/task-info-dto.model';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { SafePipe } from 'safe-pipe';
import { NotificationFilterPipe } from '../../../../../../shared/pipes/filter/notification-filter.pipe';

@Component({
  selector: 'bizz-task-info-dialog',
  standalone: true,
  imports: [
    IconComponent,
    TranslatePipe,
    SafePipe,
    NotificationFilterPipe
  ],
  templateUrl: './task-info-dialog.component.html',
  styleUrl: './task-info-dialog.component.scss'
})
export class TaskInfoDialogComponent {
  public constructor(@Inject(DIALOG_DATA) public data: TaskInfoDto, public dialogRef: DialogRef) {
  }

}
