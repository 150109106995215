export enum TableFieldDataType {
	None = 0,
	// Yes
	AlphaNumeric = 1,
	// Yes
	Numeric = 3,
	// Yes
	Email = 4,
	// Yes
	DatePicker = 5,
	// Yes
	TimePicker = 6,
	// Yes
	DateTimePicker = 7,
	Checkbox = 8,
	RadioGroup = 9,
	// Yes
	Memo = 10,
	// Yes
	Combobox = 11,
	// Yes
	OrganizationChartUnitSelector = 13,
	ControlledDocument = 14,
	// Yes
	HyperLink = 15,
	MacroButton = 16,
	PdfFile = 17,
	Canvas = 18,
	// Yes
	OrganizationChartUnitSelectorForTrnApp = 19,
	ExamCreator = 20,
	ExamScoreEvaluations = 21,
  MailingList = 22,
  AiButton = 23,

  // Yes
	EnumList = 50,
	TextValue = 500,
	SimpleList = 501
}