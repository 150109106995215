import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotificationDetailDto } from '../../../../models/ts/notification-detail-dto.model';
import { NotificationPopUpListDto } from '../../../../models/ts/notification-pop-up-list-dto.model';
import { NotificationsDto } from '../../../../models/ts/notifications-dto.model';
import { NotificationSearchDto } from '../../../../models/ts/notification-search-dto.model';
import { GridDataDto } from '../../../../models/ts/grid-data-dto.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationApiService {

  constructor(private http: HttpClient) {
  }

  public getNotification(ID: number): Observable<NotificationDetailDto> {
    return this.http.get<NotificationDetailDto>(`api/notification/${ID}`);
  }

  public getNotifications(): Observable<NotificationsDto> {
    return this.http.get<NotificationsDto>(`api/notifications`);
  }

  public getPopupNotifications(search: string): Observable<NotificationPopUpListDto> {
    return this.http.get<NotificationPopUpListDto>(`api/notification/popup`, { params: { search } });
  }

  public readNotification(ID: number): Observable<void> {
    return this.http.post<void>(`api/notification/${ID}/read`, { notificationsID: ID });
  }

  public unreadNotification(ID: number): Observable<void> {
    return this.http.post<void>(`api/notification/${ID}/unread`, { notificationsID: ID });
  }

  public readNotifications(IDs: number[]): Observable<void> {
    return this.http.post<void>(`api/notifications/read`, IDs);
  }

  public searchNotifications(search: NotificationSearchDto): Observable<GridDataDto> {
    return this.http.post<GridDataDto>('api/notifications/search', search);
  }
}
