export enum StepType {
	StartStep = 1,
	NormalStep = 2,
	EndStep = 3,
	Gateway = 4,
	AssessmentStep = 5,
	PublicationStep = 6,
	DistributionStep = 7,
	AssessmentGateway = 8,
	TrainingAppAssessmentStep = 9,
	TrainingAppSubscribeToExamStep = 10,
	TrainingAppExaminationStep = 11,
	TrainingAppPublishExamStep = 12
}