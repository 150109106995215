export enum ExamCreatorFormControlActionType {
  GenerateAi = 1,
}

export interface ExamCreatorFormControlAction {
  type: ExamCreatorFormControlActionType;
  generateAi?: ExamCreatorFormControlGenerateAiAction;
}


export interface ExamCreatorFormControlGenerateAiAction {
  questions: number;
  answersPerQuestion: number;
}

