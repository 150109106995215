import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CollectionsEffects } from './collections-effects';
import { collectionsFeature } from './collections-feature';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(collectionsFeature),
    EffectsModule.forFeature(CollectionsEffects),
  ],
})
export class CollectionFeatureModule {}
