<!--TODO: Check if alt is needed for qr code image + check modals when ready for dev-->
<div class="modal-container sm:!min-w-0 sm:w-[30rem]">
    <div class="modal-close-container">
        <bizz-icon [iconLibrary]="'FontAwesome'" [iconName]="'fa-xmark'" (click)="dialogRef.close(false)"></bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header !mb-0">
            <h1>{{"TwoFactorSetup" | translate}}</h1>
            <p>{{"TwoFactorSetup_Info" | translate}}</p>
        </div>
        <hr>

        <div class="w-full flex justify-center mt-5 mb-5">
            <img class="w-full" *ngIf="index.TwoFactorSecret" [src]="imageToShow" alt=""/>
        </div>

        <div class="flex flex-col items-center">
            <div class="modal-header !mb-0">
                <h1>{{"TwoFactorCode" | translate}}:</h1>
            </div>
            <input type="text" class="w-1/2" [(ngModel)]="index.TwoFactorCode" placeholder="123456" />
        </div>

        <div class="w-full mt-5">
            <button class="button save !w-full !justify-center" (click)="saveTwoFactor()">{{"Confirm" | translate}}</button>
        </div>
    </div>
</div>