import { ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ViewStackLoadedComponent } from '../../../../../shared/interfaces/view-stack-loaded-component';
import { FlowStatusComponent } from '../../../../../views/workspace/flow-status/flow-status.component';
import { FlowStatusDto } from '../../../../../../models/ts/flow-status-dto.model';
import { StoreFlowStatus } from '../../../../../store/features/flow-status/flow-status-state';
import { ViewStackService } from '../../../../../shared/services/view-stack/view-stack.service';
import { selectFlowStatus } from '../../../../../store/features/flow-status/flow-status-selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'bizz-view-stack-flow-status',
  standalone: true,
  imports: [CommonModule, FlowStatusComponent],
  templateUrl: './view-stack-flow-status.component.html',
  styleUrls: ['./view-stack-flow-status.component.scss']
})
export class ViewStackFlowStatusComponent implements ViewStackLoadedComponent, OnInit {
  public id: string;
  public data: unknown;
  public flowStatus: FlowStatusDto | undefined;
  private storeFlowStatus: Observable<Readonly<StoreFlowStatus> | undefined>;

  public constructor(private viewStackService: ViewStackService,
                     private store$: Store,
                     private destroyRef: DestroyRef,
                     private cdr: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.selectStoreFlowStatus();
  }

  public hasUnsavedChanges(): boolean {
    return false;
  }

  public removeFromViewStack(): void {
    // TODO: dispatch appropriate store action;
  }

  public close(): void {
    // TODO: RV this leaves an entity in the store without a linked component, have an effect handle the removal from the view stack, (see removeFromViewStack() above)
    this.viewStackService.removeLastItemFromStack();
  }

  private selectStoreFlowStatus(): void {
    this.storeFlowStatus = this.store$.select(selectFlowStatus(this.id));
    this.storeFlowStatus.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (data: Readonly<StoreFlowStatus> | undefined) =>
        (this.flowStatus = data?.data)
    });
  }

}