import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Injector,
  Input,
  NgZone,
  OnInit,
  Output,
  TemplateRef,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CellClickEvent,
  CheckboxColumnComponent,
  ColumnBase,
  ColumnReorderEvent,
  CommandColumnComponent,
  EditEvent,
  GridModule,
  PageChangeEvent,
  SelectionEvent,
  SharedModule
} from '@progress/kendo-angular-grid';
import { SafePipe } from 'safe-pipe';
import { GridCellComponent } from './cells/grid-cell/grid-cell.component';
import { SkeletonModule } from '@progress/kendo-angular-indicators';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { GridColumnType } from '../../../../models/ts/grid-column-type.model';
import { GridComponent as KendoGridComponent } from '@progress/kendo-angular-grid/grid.component';
import { CellActionData } from '../../../features/bizzmine/widgets/collection-list-widget/interfaces/cell-action-data';
import { CellActionType } from '../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';
import { PagerModule } from '@progress/kendo-angular-pager';
import { GroupDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { GridCellActionsComponent } from './cells/grid-cell-actions/grid-cell-actions.component';
import { LoaderComponent } from '../ui/loader/loader.component';
import { TranslatePipe } from '../../pipes/translate/translate.pipe';
import { IconComponent } from '../ui/icon/icon.component';
import { GridOptions } from '../../classes/list/grid-options';
import { GridContextPermissionsMetadata } from '../../../features/bizzmine/widgets/collection-list-widget/interfaces/grid-context-permissions-metadata';
import * as Sentry from '@sentry/angular';
import { GridMobileCellComponent } from './cells/grid-mobile-cell/grid-mobile-cell.component';
import { GridColumnBase } from '../../classes/list/grid-column-base';
import { FormGroup } from '@angular/forms';
import { RowArgs } from '@progress/kendo-angular-grid/rendering/common/row-args';
import { BehaviorSubject, debounceTime, map, take } from 'rxjs';
import { AlignmentType } from 'src/models/ts/alignment-type.model';
import { resizeLastGridColumn } from './helpers/resize-last-grid-column';
import { TableFieldDataType } from 'src/models/ts/table-field-data-type.model';

@Component({
  selector: 'bizz-grid',
  standalone: true,
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  imports: [
    CommonModule,
    GridModule,
    PagerModule,
    SafePipe,
    SharedModule,
    SkeletonModule,
    TranslatePipe,
    NgScrollbarModule,
    IconComponent,
    PagerModule,
    LoaderComponent,
    GridMobileCellComponent,
    GridCellActionsComponent,
    GridCellComponent
  ]
})
@Sentry.TraceClass({ name: 'GridComponent' })
export class GridComponent implements OnInit, AfterViewInit {

  /**
   * Emits when the page changes.
   */
  @Output() public pageChange = new EventEmitter<PageChangeEvent>();

  /**
   * Emits when a cell action is triggered.
   * Currently unimplemented.
   */
  @Output() public cellAction = new EventEmitter<{ action: CellActionType, data: CellActionData }>();

  /**
   * Emits when the group or group order changes.
   */
  @Output() public groupChange = new EventEmitter<GroupDescriptor[]>();

  /**
   * Emits when the sort order changes.
   */
  @Output() public sortChange = new EventEmitter<SortDescriptor[]>();

  /**
   * Emits when selection is enabled in GridOptions and a row selection is made.
   */
  @Output() public selectionChange = new EventEmitter<any[]>();
  /**
   * Optional custom column template that can be used for the grid
   * This is to overwrite the columns used by gridOptions.columns
   * use let-data: { data:any, col: GridColumnBase } to access column and data in the template.
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public columnTemplate: TemplateRef<{ $implicit: any; column: GridColumnBase; columnIndex: number; }> | null;
  /**
   * Optional custom commmand column template that can be used to set the columns for the grid.
   * use let-dataItem let-rowIndex="rowIndex" let-column="column" to access grid data in the template.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public commandColumnTemplate: TemplateRef<{
    $implicit: any;
    rowIndex: number;
    column: GridColumnBase;
  }> | null = null;
  /**
   * Optional edit column template that can be used to set the edit (input) mode of columns.
   * use let-dataItem let-formGroup="formGroup" let-column="column" to access grid data in the template.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public editColumnTemplate: TemplateRef<{
    $implicit: any;
    formGroup: FormGroup;
    column: GridColumnBase;
    columnIndex: number;
  }> | null;
  /**
   * The loading state of the grid.
   * Default is true.
   */
  @Input({ required: true }) public loading = true;
  /**
   * The grid options.
   * Can be created from GridOptionsDto
   * Holds information about the column options, pagesize, skip, etc.
   * @required
   **/
  @Input({ required: true }) public gridOptions: GridOptions;
  /**
   * The total number of items in the grid.
   * @required
   */
  @Input({ required: true }) public totalItems: number;
  /**
   * The permissions needed for the action cell.
   * If your grid has an action cell, you need to provide this.
   */
  @Input({ alias: 'gridCellMetadata' }) public actionCellData: GridContextPermissionsMetadata;
  /**
   * Override to set the grid in readonly mode (when used in a form)
   * @type {boolean}
   */
  @Input() public readOnly = false;
  /**
   * Page sizes to show in the pager.
   * Defaults to 1, 2, 5, 10, 20, 50 and 200
   */
  @Input() public pageSizes = [1, 2, 5, 10, 20, 50, 200];
  /**
   * Show the pager of the grid
   * Defaults to true
   */
  @Input() public showPager: boolean = true;
  /**
   * Show the columns in mobile card view or not.
   * Defaults to false (should be true for forms)
   */
  @Input() public showMobileColumnView: boolean = false;
  /**
   * Fit columns to content. This is a configurable option for list widgets.
   * The last column will always auto size to the remaining space.
   */
  @Input() public autoFitColumns: boolean = false;
  @Input() public selection: any[] = [];
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public gridDataInternal: any[] = [];
  public currentPageInternal: number = 1;
  public readonly gridColumnType = GridColumnType;
  //The minimum width of a column when resizing in px.
  public minResizableWidth = 50;
  //Based on watch of window size
  public isMobileView = false;
  public totalGridWidth: number;
  public initialLastColumnWidth?: number = undefined;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public readonly MAX_MOBILE_WIDTH = 1024;
  public injector = inject(Injector);
  @ViewChild('kendoGrid')
  private kendoGridComponent: KendoGridComponent;
  @ViewChild('kendoGrid', { read: ElementRef })
  private kendoGridElement: ElementRef;
  private ngZone: NgZone = inject(NgZone);
  public AlignmentType = AlignmentType;

  private intersectionObserver: IntersectionObserver;
  private resizeObserver: ResizeObserver;
  private lastObservedElementWidth: number = 0;
  private isGridInView = false;
  private gridWidth$ = new BehaviorSubject<number>(0);
  public TableFieldDataType = TableFieldDataType;
  public constructor(private destroyRef: DestroyRef) {
  }

  /**
   * The data to show in the grid.
   */
  @Input()
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public set gridData(value: any[]) { //Kendo takes any[]...
    this.gridDataInternal = value;
    this.loading = false;
    this.updateColumnWidth();
  }

  /**
   * The current page of the grid.
   * Default is 1.
   * @required
   */
  @Input({ required: true })
  public set currentPage(value: number) {
    if (value > 0)
      this.currentPageInternal = value;
    else throw new Error('Current page must be greater than 0');
  }

  private get kendoGridColumns(): ColumnBase[] {
    return this.kendoGridComponent.columns.filter(f => !(f instanceof CommandColumnComponent)
      && !(f instanceof CheckboxColumnComponent) && !f.hidden);
  }

  @Input() public trackBy: TrackByFunction<any> = (index: number, row: any) => {
    return index;
  };

  public ngOnInit(): void {
    this.isMobileView = window.innerWidth <= 640;
  }

  @Sentry.TraceMethod({ name: 'ngOnDestroy' })
  public ngOnDestroy(): void {
    this.kendoGridComponent.columnResize.unsubscribe();
    this.gridWidth$.unsubscribe();
    this.intersectionObserver.disconnect();
    this.resizeObserver.disconnect();
  }

  public ngAfterViewInit(): void {
    this.kendoGridComponent.columnResize.subscribe(() => {
      this.resizeLastGridColumn();
    });

    //Intersection observer to check if the grid is in view
    //This is needed to reliably autofit columns, because autofitting columns when the grid is not in view
    //will cause the columns to be resized to 0.
    this.intersectionObserver = new IntersectionObserver(([entry]) => {
      this.isGridInView = entry.isIntersecting;
      if (entry.isIntersecting &&
        this.lastObservedElementWidth != entry.boundingClientRect.width) {
        this.lastObservedElementWidth = entry.boundingClientRect.width;
        this.updateColumnWidth();
      }
    });
    //Resize observer to update the width of the columns when the window or grid is resized.
    this.resizeObserver = new ResizeObserver(([entry]) => {
      this.ngZone.run(() => {
        if (this.lastObservedElementWidth != entry.contentRect.width) {
          this.lastObservedElementWidth = entry.contentRect.width;
          this.gridWidth$.next(entry.contentRect.width);
        }
        //640px is the tailwindcss sm breakpoint (maybe make a class for this)
        this.isMobileView = window.innerWidth <= 640;
      });
    });
    this.intersectionObserver.observe(this.kendoGridElement.nativeElement);
    this.resizeObserver.observe(this.kendoGridElement.nativeElement);
    this.gridWidth$.pipe(
      debounceTime(150),
      map(() => this.updateColumnWidth()))
      .subscribe();
  }

  public onGroupChange(event: GroupDescriptor[]): void {
    // Default dir is undefined, which will causes API error (expects 'asc' or 'desc')
    event.forEach(group => {
      if (group.dir == undefined) {
        group.dir = 'asc';
      }
    });
    this.gridOptions.dataSource.group = event;
    //set columns that are grouped hidden
    this.gridOptions.columns.forEach(c => {
      c.hidden = event.some(g => g.field == c.field);
    })
    this.groupChange.emit(event);
  }

  public onSortChange(sort: SortDescriptor[]): void {
    //ignore sort without direction
    const newSort = sort.filter(s => s.dir != undefined);
    this.gridOptions.dataSource.sort = newSort;
    this.sortChange.emit(newSort);
  }

  public onPageChange(event: PageChangeEvent): void {
    this.kendoGridComponent.scrollTo({ row: 0 });
    this.pageChange.emit(event);
  }

  public onEditRow(event: EditEvent): void {
  }

  /**
   * Overwrites kendo's selection logic to check if a row should be selected.
   * @param {RowArgs} e
   */
  public isRowSelected(e: RowArgs): boolean {
    return this.selection.some((s) => s === e.dataItem);
  }

  public onSelectionChange(e: SelectionEvent): void {
    if (!this.gridOptions.selectable)
      return;
    e.selectedRows?.forEach(
      (r) => this.handleSelection(r.dataItem)
    );

    e.deselectedRows?.forEach(
      (r) => this.selection = this.selection.filter((s) => s !== r.dataItem)
    );
    this.selectionChange.emit(this.selection);
  }

  public onCellClick(e: CellClickEvent): void {
    e.originalEvent.preventDefault();
    if (!this.gridOptions.selectable)
      return;
    this.handleSelection(e.dataItem);
    this.selectionChange.emit(this.selection);
  }

  public onColumnReorder(e: ColumnReorderEvent): void {
    //Don't allow reordering of other columns to the place of a non reorderable column like the actions/checkbox/status column.
    const oldColumn = this.gridOptions.columns[e.newIndex];
    if(oldColumn && oldColumn.reorderable == false || e.newIndex == undefined || e.newIndex < 0)
      e.preventDefault();
  }

  /**
   * Update the column width based on is it a mobile view,
   * and resize last column to take up the remaining space
   * @private
   */
  private updateColumnWidth(): void {
    if (this.gridDataInternal.length == 0 || !this.isGridInView)
      return;

    //See example kendo:
    //Currently the best way to reliably resize the columns without visible layout shift.
    //https://www.telerik.com/kendo-angular-ui/components/grid/columns/resizing/#toc-auto-fitting-the-content/
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        if (this.autoFitColumns) {
          this.fitColumnsToContent();
          return;
        }
        this.kendoGridColumns.forEach((column, index) => {
          if (this.gridOptions.columns[index] == undefined) {
            console.warn('Column not found in gridOptions.columns', column, index, this.gridOptions.columns);
            return;
          }
          if (
            column instanceof CommandColumnComponent ||
            this.gridOptions.columns[index].GridColumnType == GridColumnType.Actions ||
            this.gridOptions.columns[index].GridColumnType == GridColumnType.Status) {
            column.resizable = true; //Make sure the column is resizable for autofit.
            this.kendoGridComponent.autoFitColumn(column);
            column.resizable = false;
          } else {
            column.minResizableWidth = this.minResizableWidth;
            column.width = this.gridOptions.columns[index].width;
          }
        });
        this.resizeLastGridColumn();
      });
  }

  /**
   * Resize the last grid column to take the remaining width of the grid and leave no
   * whitespace.
   * @private
   */
  private resizeLastGridColumn(): void {
    if (this.gridDataInternal.length == 0)
      return;

    if (this.kendoGridElement == undefined) {
      throw new Error('kendoGridElement is undefined');
    }
    //Remove 10px of the totalwidth because this is the outside of the grid.
    this.totalGridWidth = this.kendoGridElement.nativeElement.offsetWidth - 10;
    resizeLastGridColumn(this.kendoGridColumns,
      this.totalGridWidth,
      this.gridOptions.dataSource.group.length,
      this.initialLastColumnWidth);
  }

  /**
   * Execute kendos autoFitColumns function and resize the status column to the minimum  mobile width.
   * Resize the last column to take up the remaining space.
   */
  private fitColumnsToContent(): void {
    if (!this.autoFitColumns || this.gridDataInternal.length == 0) {
      console.warn('fitColumnsToContent but not autofit or no internal data');
      return;
    }

    const statusColumnIndex = this.gridOptions.columns.findIndex(c => c.GridColumnType == GridColumnType.Status);
    const actionsColumnIndex = this.gridOptions.columns.findIndex(c => c.GridColumnType == GridColumnType.Actions);

    //non resizable columns (like actions, status) need to be resizable for autofit.
    const nonResizableColumns = this.kendoGridColumns.filter((c) => !c.resizable);
    nonResizableColumns.forEach(c => { c.resizable = true; }); 

    this.kendoGridColumns.forEach((column, index) => {
      this.kendoGridComponent.autoFitColumn(column);
      //If the column is the status or actions column, don't resize it.
      if (column.width < this.minResizableWidth && (index != statusColumnIndex && index != actionsColumnIndex))
        column.width = this.minResizableWidth;
    });
    nonResizableColumns.forEach(c => { c.resizable = false; });
    //Updated initialLastColumnWidth again because of the autofit.
    this.initialLastColumnWidth = this.kendoGridComponent.columns.last.width + 10;
    this.resizeLastGridColumn();
  }

  private handleSelection(dataItem: any): void {
    if (this.gridOptions.selectable.mode === 'single') {
      this.selection = [dataItem];
      return;
    }
    const isSelected = this.selection.some((s) => s === dataItem);
    if (isSelected) {
      this.selection = this.selection.filter((s) => s !== dataItem);
    } else {
      this.selection.push(dataItem);
    }
  }
}