import { DestroyRef, HostListener, inject, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from '../../../environments/environment';
import { filter, map, Observable, of, shareReplay, Subject, switchMap, take, tap } from 'rxjs';
import {
  CompleteStepMessageCallBackSignalrMessage,
  CompleteStepMessageJobResult
} from '../../../models/ts/complete-step-message';
import { BizzMineSessionStorageService } from '../../shared/services/localStorage/bizzmine-session-storage.service';
import { AlertService } from '../../features/bizzmine/alerts/alert.service';
import { DIFFERENT_BROWSER_LOGIN_ALERT } from '../../features/bizzmine/alerts/constants/preset-alerts';
import { UserLoginSignalRMessage } from '../../features/bizzmine/widgets/copilot-widget/interfaces/copilot-widget-message';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserSettingsApiService } from '../../api/bizzmine/user-settings/user-settings-api.service';
import { UserType } from '../../../models/ts/user-type.model';
import { userSettingsActions } from '../../store/features/user-settings/user-settings-actions';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  private static methodName = 'BizzMineMessage';
  public signalrMessage$ = new Subject<BizzMineSignalRMessage>();

  public connectionId: string | null | undefined = null;
  public aiConnectionId: string | null | undefined = null;
  public idpConnectionId: string | null | undefined = null;
  private store$ = inject(Store);
  public user$ = this.userSettingsApiService.getUserSettings().pipe(tap(settings => {
    this.store$.dispatch(userSettingsActions.userSettingsFetched({ userSettings: settings }));
  }),shareReplay(1));
  private _connection: HubConnection | null = null;
  private _hasBeenSuccessfullyInit = false;
  private _aiConnection: HubConnection | null = null;
  private _aiHasBeenSuccessfullyInit = false;
  private _idpConnection: HubConnection | null = null;
  private _idpHasBeenSuccessfullyInit = false;

  public constructor(private sessionStorage: BizzMineSessionStorageService,
                     private alertService: AlertService,
                     private destroyRef: DestroyRef,
                     private userSettingsApiService: UserSettingsApiService) {
    this.filterByType(UserLoginSignalRMessage, 'UserLoginSignalRMessage').pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.alertService.setAlert(DIFFERENT_BROWSER_LOGIN_ALERT);
    });
  }

  public callBackMessage(): Observable<CompleteStepMessageCallBackSignalrMessage> {
    return this.filterByType(CompleteStepMessageCallBackSignalrMessage, 'CompleteStepMessageCallBackSignalrMessage`1');
  }

  public callBackMessageWithRequest<TRequestClass,TItemClass>(requestClassName: string): Observable<CompleteStepMessageJobResult<TRequestClass,TItemClass>> {
    return this.callBackMessage()
      .pipe(
        filter(message => message.type == requestClassName),
        map(message => {
          return {
            request: JSON.parse(message.request) as TRequestClass,
            item: message.item == undefined ? undefined : message.item as TItemClass,
            state: message.state
          }
        })
      );
  }

  public filterByType<TClass>(type: new () => TClass, className: string): Observable<TClass> {
    return this.signalrMessage$.pipe(
      filter(message => message?.type == className),
      switchMap(data => of(data?.value as TClass))
    );
  }

//headers: request.headers.set('Authorization', 'Bearer ' + this.authService.rawAccessToken)
  public initApiHub(): void {
    this.getAccessToken().then((accessToken) => {
      this.user$.pipe(take(1)).subscribe({
        next: user => {
          if (user != undefined && user.UserType != UserType.AnonymousUser && user.UserType != UserType.ExternalUser) {
            if (this._hasBeenSuccessfullyInit) {
              return; // has been initialized;
            }
            this._connection = new HubConnectionBuilder()
              .withAutomaticReconnect()
              .withUrl(`${environment.apiUri}/BizzMineHub`, {
                accessTokenFactory: () => accessToken,
                withCredentials: false
              })
              .build();

            this._connection.on(SignalRService.methodName, (message) => {
              // can be logged in v1 - we need this for testing will remove log in future
              console.info('signalRmessage - API', message);
              this.signalrMessage$.next(message);
            });

            this._connection.onclose((error) => {
              console.error('signalR(API) connection has been closed', error);
            });

            this._connection.onreconnecting((error) => {
              console.error('signalR(API) connection is reconnecting', error);
            });

            this._connection.onreconnected((connectionId) => {
              console.info('signalR(API) connection has been reconnected', connectionId);
              this.connectionId = this._connection?.connectionId;
            });

            this._connection.start()
              .then(() => {
                this.connectionId = this._connection?.connectionId;
                console.info('signalR(API) connection has been made', this._connection?.connectionId, this.connectionId);
                this._hasBeenSuccessfullyInit = true;
              })
              .catch(err => {
                console.error(err);
              });
          } else {
            console.info('no signalR connection was made to API');
          }
        }
      });
    });
  }

  public initIdpHub(): void {
    this.getAccessToken().then(() => {
      this.user$.pipe(take(1)).subscribe({
        next: user => {
          if (user != undefined && user.UserType != UserType.AnonymousUser && user.UserType != UserType.ExternalUser) {
            if (environment.idpUri == null || environment.idpUri.trim() == '') {
              console.info('no signalR connection was made to IDP for there was no IDP detected');
              return;
            }
            if (this._idpHasBeenSuccessfullyInit) {
              return; // has been initialized;
            }

            this._idpConnection = new HubConnectionBuilder()
              .withAutomaticReconnect()
              .withUrl(`${environment.idpUri}/BizzMineHub`, {
                withCredentials: false
              })
              .build();

            this._idpConnection.on(SignalRService.methodName, (message) => {
              // can be logged in v1 - we need this for testing will remove log in future
              console.info('signalRmessage - IDP', message);
              this.signalrMessage$.next(message);
            });

            this._idpConnection.onclose((error) => {
              console.error('signalR(idp) connection has been closed', error);
            });

            this._idpConnection.onreconnecting((error) => {
              console.error('signalR(idp) connection is reconnecting', error);
            });

            this._idpConnection.onreconnected((connectionId) => {
              console.info('signalR(idp) connection has been reconnected', connectionId);
              this.idpConnectionId = this._idpConnection?.connectionId;
            });

            this._idpConnection.start()
              .then(() => {
                this.idpConnectionId = this._idpConnection?.connectionId;
                console.info('signalR(idp) connection has been made', this._idpConnection?.connectionId, this.idpConnectionId);
                this._idpHasBeenSuccessfullyInit = true;
              })
              .catch(err => {
                console.error(err);
              });
          } else {
            console.info('no signalR connection was made to Idp');
          }
        }
      });
    });
  }

  public initAiHub(): void {
    if (!environment.apiAiUri && !environment.production)
      return; //ignore signal-r on development if url is not set

    this.getAccessToken().then((accessToken) => {
      this.user$.pipe(take(1)).subscribe({
        next: user => {
          if (user != undefined && user.UserType != UserType.AnonymousUser && user.UserType != UserType.ExternalUser) {
            if (environment.apiAiUri == null || environment.apiAiUri.trim() == '') {
              console.info('no signalR connection was made to AI for there was no AI detected');
              return;
            }
            if (this._aiHasBeenSuccessfullyInit) {
              return; // has been initialized;
            }

            this._aiConnection = new HubConnectionBuilder()
              .withAutomaticReconnect()
              .withUrl(`${environment.apiAiUri}/BizzMineHub`, {
                accessTokenFactory: () => accessToken,
                withCredentials: false
              })
              .build();

            this._aiConnection.on(SignalRService.methodName, (message) => {
              // can be logged in v1 - we need this for testing will remove log in future
              console.info('signalRmessage - AI', message);
              this.signalrMessage$.next(message);
            });

            this._aiConnection.onclose((error) => {
              console.error('signalR(AI) connection has been closed', error);
            });

            this._aiConnection.onreconnecting((error) => {
              console.error('signalR(AI) connection is reconnecting', error);
            });

            this._aiConnection.onreconnected((connectionId) => {
              console.info('signalR(AI) connection has been reconnected', connectionId);
              this.aiConnectionId = this._aiConnection?.connectionId;
            });

            this._aiConnection.start()
              .then(() => {
                this.aiConnectionId = this._aiConnection?.connectionId;
                console.info('signalR(AI) connection has been made', this._aiConnection?.connectionId, this.aiConnectionId);
                this._aiHasBeenSuccessfullyInit = true;
              })
              .catch(err => {
                console.error(err);
              });
          } else {
            console.info('no signalR connection was made to AI');
          }
        }
      });
    });
  }

  public stop(): void {
    this._connection?.stop();
    this._aiConnection?.stop();
  }

  private getAccessToken(): Promise<string> {
    return new Promise((resolve) => {
      let value = this.sessionStorage.getItem('access_token') as string;
      if (value == null || value.trim() == '') {
        const interval = setInterval(() => {
          value = this.sessionStorage.getItem('access_token') as string;
          if (value != null && value.trim() != '') {
            clearInterval(interval);
            resolve(value);
          }
        }, 1000);
      } else {
        resolve(value);
      }
    });
  }

  @HostListener('window:beforeunload')
  private beforeUnloadHandler(): void {
    this.stop();
  }

}

export class BizzMineSignalRMessage {
  public type: string;
  public value: unknown;
}
