import { Component } from '@angular/core';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { TableFieldDataType } from 'src/models/ts/table-field-data-type.model';
import { getDisplayStyleByFieldId } from 'src/app/shared/functions/helpers/display-style-helpers';

@Component({
  selector: 'bizz-grid-cell-text',
  templateUrl: './grid-cell-text.component.html',
  styleUrls: ['./grid-cell-text.component.scss'],
  standalone: true
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridCellTextComponent extends GridCellBaseComponent<string> {

  public backgroundColor: string;
  public foregroundColor: string;
  public hasDisplayStyle = false;
  protected override onDataChanged(): void {
    const idProperty = this.column.field + '_ID';
    if(this.column.DisplayStyleValues && 
      idProperty in this.data &&
      this.data[idProperty]) {
          const id = Number(this.data[idProperty]);
          const displayValue = getDisplayStyleByFieldId(this.column.DisplayStyleValues, id);
          if(displayValue) {
            this.backgroundColor = displayValue.BackgroundColor;
            this.foregroundColor = displayValue.ForegroundColor;
            this.hasDisplayStyle = true;
          }
      }
  }

}
