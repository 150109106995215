<div class="h-full w-full sm:relative"  [ngClass]="{'overflow-hidden' : !dropDown}">
    @defer (when workspaces) {
    @if (activeWorkspace) {
        <button type="button"
                (click)="toggleDropDown($event)"
                class="hover:bg-work≈space-selector-hover h-full w-full min-w-0 flex-1 items-center bg-workspace-selector text-workspace-selector hover:text-workspace-selector-hover"
                [ngClass]="{ 'bg-workspace-selector-hover' :  dropDown }">
            <bizz-icon class="size-6" [iconLibrary]="activeWorkspace.Icon.Library" [iconName]="activeWorkspace.Icon.Name">
            </bizz-icon>
            @if (showFullButton) {
                <label class="ml-2 min-w-0 flex-1 cursor-pointer truncate text-left leading-tight text-white">
                    {{ activeWorkspace.Name }}
                </label>
            }
            @if((expanded | async)){
                <bizz-toggle-arrow class="pointer-events-none size-4" [direction]="dropDown ? 'up' : 'down'"></bizz-toggle-arrow>
            }
        </button>
    }

    @if (dropDown) {
        <div class="pointer-events-none fixed left-0 top-0 z-[1] h-svh w-svw bg-[rgba(0,0,0,0.32)]"></div>
        <div [ngClass]="{'!rounded-none' : isMobile}"
             class="maw-w-[100vw] shadow-black absolute left-0 top-[-49px] z-10 flex h-svh max-h-[100vh] flex-col bg-body-popup shadow-lg drop-shadow-xl sm:!top-12 sm:mt-1 sm:h-fit sm:min-w-[400px] sm:rounded-lg maxw-sm:w-full">
                <div class="mx-4 mt-4 flex items-center justify-between sm:hidden">
                    <h2 class="font-title text-xl font-bold leading-none text-default sm:leading-normal">{{ 'SelectAWorkspace' | translate }}</h2>
                    <button type="button" class="p-1 sm:p-2" (click)="this.dropDown = false">
                        <bizz-icon class="size-6 text-button" iconName="xmark"></bizz-icon>
                    </button>
                </div>

                    <div class="relative my-4 box-border flex h-10 w-full flex-row items-center justify-end px-4">
                        <input #inputFocus (keyup.Enter)="onSearchEnterPressed($event)" id="filter"
                            class="size-10 w-full rounded-md border-form-control text-default placeholder:text-lighter focus:border-3 focus:border-form-control-focus focus:shadow-inner sm:h-8"
                            [ngModel]="search | async"
                            (ngModelChange)="search.next($event)"
                            placeholder="{{'Search' | translate}}">
                        <bizz-icon class="absolute mr-2.5 size-4 text-button" iconName="magnifying-glass"></bizz-icon>
                    </div>

                    <p *ngIf="workspaces.Workspaces.length == 0" class="mx-4 pl-3 text-default">{{ 'NoResultsFound' | translate }}</p>

                <kendo-listview #workspaceList
                        [data]="workspaces.Workspaces"
                        [navigable]="true"
                        class="mx-4 h-[calc(100svh_-_138px)] w-[calc(100%_-_32px)] border-none bg-transparent pb-0 sm:mx-0 sm:box-border sm:h-[min(32rem,-200px_+_100vh)] sm:w-full sm:px-4 sm:pb-4"
                        containerClass="gap-5 container sm:gap-2.5 flex flex-col"
                        (scrollBottom)="getNextPage()">

                    <ng-template kendoListViewItemTemplate let-dataItem="dataItem">
                        <button (click)="workspaceSelected(dataItem.ID)"
                                class="flex w-full flex-row items-center gap-2.5 rounded p-2.5 hover:bg-button-hover"
                                [ngClass]="{'bg-button': dataItem.ID == this.selectedWorkspace}">
                            <bizz-icon
                                    class="size-5 min-h-5 min-w-5 text-sidebar-icon"
                                    [ngClass]="{'!text-sidebar-header-hover': dataItem.ID == this.selectedWorkspace}"
                                    [iconLibrary]="dataItem.Icon.Library"
                                    [iconName]="dataItem.Icon.Name"
                                    [hover]="true">
                            </bizz-icon>
                            <div class="flex flex-col justify-items-start overflow-hidden text-left">
                                <p class="specialtext text-sm text-button no-underline hover:text-button-hover"
                                   [ngClass]="{'!text-sidebar-header-hover': dataItem.ID == this.selectedWorkspace}">
                                    {{ dataItem.Name }}
                                </p>
                                <p class="specialtext text-xs font-light text-button no-underline hover:text-button-hover"
                                   [ngClass]="{'!text-sidebar-header-hover': dataItem.ID == this.selectedWorkspace}">
                                    {{ dataItem.Description }}
                                </p>
                            </div>
                        </button>
                    </ng-template>
                </kendo-listview>
        </div>
    }
}
</div>