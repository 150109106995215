/**
 * Note only fontawesome or bizzmine icons are supported for now.
 * See: extension-icon.component.ts updateIcon function.
 */
export const EXTENSION_ICON_NAMES: { [dataType: string]: string } = {
  '.doc': 'fa-file-word',
  '.docx': 'fa-file-word',
  '.rtf': 'fa-file-lines',
  '.txt': 'fa-file-lines',
  '.otf': 'fa-file-lines',
  '.ttf': 'fa-file-lines',
  '.pdf': 'fa-file-pdf',
  '.xls': 'fa-file-excel',
  '.xlsx': 'fa-file-excel',
  '.csv': 'fa-file-csv',
  '.ppt': 'fa-file-powerpoint',
  '.pptx': 'fa-file-powerpoint',
  '.zip': 'fa-file-zipper',
  '.rar': 'fa-file-zipper',
  '.7z': 'fa-file-zipper',
  '.tar': 'fa-file-zipper',
  '.ace': 'fa-file-zipper',
  '.jpg': 'fa-file-image',
  '.jpeg': 'fa-file-image',
  '.png': 'fa-file-image',
  '.bmp': 'fa-file-image',
  '.gif': 'fa-file-image',
  '.flv': 'fa-file-video',
  '.mp4': 'fa-file-video',
  '.avi': 'fa-file-video',
  '.mov': 'fa-file-video',
  '.wmv': 'fa-file-video',
  '.mpg': 'fa-file-video',
  '.mpeg': 'fa-file-video',
  '.3gp': 'fa-file-video',
  '.m4v': 'fa-file-video',
  '.mp3': 'fa-file-audio',
  '.wav': 'fa-file-audio',
  '.m4a': 'fa-file-audio',
  '.flac': 'fa-file-audio',
  '.ses': 'fa-file-audio',
  '.sesx': 'fa-file-audio',
  '.vsdx': 'file-visio', //not a fontawesome icon
  '.js': 'fa-file-code',
  '.sql': 'fa-file-code',
  '.xsd': 'fa-file-code',
  '.php': 'fa-file-code',
  '.asp': 'fa-file-code',
  '.aspx': 'fa-file-code',
  '.html': 'fa-file-code',
  '.xml': 'fa-file-xml',
  '.scss': 'file-css', //not a fontawesome icon
  '.css': 'file-css',
  '.dll': 'file-dll', //not a fontawesome icon
  '.fla': 'file-flash', //not a fontawesome icon
  '.swf': 'file-flash',
  '.f4v': 'file-flash',
  '.apk': 'fa-file',
  '.psd': 'file-ps', //not a fontawesome icon
  '.ai': 'file-indesign', //not a fontawesome icon
  '.indd': 'file-indesign',
  '.indl': 'file-indesign',
  '.indb': 'file-indesign',
  '.indt': 'file-indesign',
  '.mdb': 'file-db', //not a fontawesome icon
  '.eml': 'file-enveloppe', //not a fontawesome icon
  '.ics': 'file-enveloppe',
  '.msg': 'file-enveloppe',
  '.pst': 'file-enveloppe',
  '.ost': 'file-enveloppe',
};