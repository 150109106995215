import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconComponent } from 'src/app/shared/components/ui/icon/icon.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { PermissionsDataComponent } from '../permissions-data/permissions-data.component';
import { PermissionsParams } from '../../interfaces/permissions-params.interface';
import { take } from 'rxjs';
import { CollectionListApiService } from 'src/app/api/bizzmine/collection-list/collection-list-api.service';

@Component({
  selector: 'bizz-permissions',
  standalone: true,
  imports: [TranslatePipe, IconComponent, PermissionsDataComponent],
  templateUrl: './permissions.component.html',
  styleUrl: './permissions.component.scss'
})
export class PermissionsComponent {
  @Output() public onClose = new EventEmitter<void>();
  @Input() public data: PermissionsParams;

  public constructor(private collectionListService: CollectionListApiService){}

  public onBackClicked(): void {
    this.onClose.emit();
  }

  public save(): void {
    this.collectionListService.savePermissions(this.data.collectionId, this.data.instanceId, this.data.versionId, this.data.folderId, this.data.permissions)
    .pipe(take(1)).subscribe(() => {
      this.onClose.emit();
    })
  }
}
