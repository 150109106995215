import { Component } from '@angular/core';
import { FormLandingPageComponent } from '../form-landing-page/form-landing-page.component';
import { formsActions } from '../../../../store/features/forms/forms-actions';

@Component({
  selector: 'bizz-form-readonly-page',
  standalone: true,
  imports: [],
  templateUrl: './form-readonly-page.component.html',
  styleUrl: './form-readonly-page.component.scss'
})
export class FormReadonlyPageComponent extends FormLandingPageComponent{
  public override routeApplied() : void {
    this.store$.dispatch(formsActions.getFormReadOnly({
      collectionId: this.collectionId!,
      instanceId: this.instanceId!,
      versionId: this.versionId!,
      guidChecksum: this.checkSum,
      state: this.state
    }));
  }
}
