/* eslint-disable @typescript-eslint/naming-convention */
export enum FormFieldType {
	List = 12,
	Panel = 100,
  MailingList = 206,
	TitleBar = 202,
	Text = 205,
	Button = 203,
	ImageComponent = 204,
	EmptyField = 300,
	Container = 400,
	LinkedCollection = 500,
	ReversedUserCollection = 600
}