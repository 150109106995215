import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContentChunkCheck, MediaCommitDto } from '../../../../models/ts/media-commit-dto.model';
import { FilePreviewDto } from '../../../../models/ts/file-preview-dto.model';
import { BlobChunkDto } from '../../../../models/ts/blob-chunk-dto.model';
import { ValidationResponse } from '../../../../models/ts/validation-response';
import { CollectionCheckinData } from '../../../../models/ts/collection-checkin-data.model';
import { MediaDto } from '../../../../models/ts/media-dto.model';

@Injectable({
  providedIn: 'root'
})
export class CollectionFormFileUploadApiService {

  public constructor(private http: HttpClient) {
  }

  public commitFile(file: MediaCommitDto): Observable<number> {
    return this.http.post<number>(`api/collectionupload/commit`, file);
  }

  public downloadFile(file: MediaCommitDto): Observable<Blob> {
    return this.http.get(`api/CollectionUpload/${file.CollectionsID}/instance/0/version/0/media/${file.MediasID}/${file.FileName}/download`, { responseType: 'blob' });
  }

  public validateChunkBlob(data: ContentChunkCheck): Observable<ValidationResponse> {
    return this.http.post<ValidationResponse>(`api/collectionupload/validate`, data, {headers: {'Content-Type': 'application/json'}});
  }

  public copyFile(intent: {
    mediaId: number,
    sourceCollectionId: number,
    instanceId: number,
    versionId: number,
    targetCollection: number
  }) : Observable<MediaDto>{
    return this.http.post<MediaDto>(`api/media/${intent.mediaId}/source/${intent.sourceCollectionId}/instance/${intent.instanceId}/version/${intent.versionId}/target/${intent.targetCollection}/copy`, null);
  }

  public previewFile(request: {
    collectionsId: number,
    instancesId: number,
    versionsId: number,
    ignoreSize: boolean,
    hidePreviewWatermarks: boolean,
    isPrint: boolean
  }): Observable<FilePreviewDto> {
    return this.http.get<FilePreviewDto>(`api/collection/${request.collectionsId}/instance/${request.instancesId}/version/${request.versionsId}/preview?ignoreSize=${request.ignoreSize}&hidePreviewWatermarks=${request.hidePreviewWatermarks}&isPrint${request.isPrint}`);
  }

  public uploadFileFromFormData(data: FormData): Observable<HttpEvent<BlobChunkDto>> {
    return this.http.post<BlobChunkDto>('api/collectionupload', data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public checkOut(request: {
    collectionId: number,
    instanceId: number,
    versionId: number
  }) : Observable<HttpResponse<ArrayBuffer>> {
    return this.http.get(`api/collection/${request.collectionId}/instance/${request.instanceId}/version/${request.versionId}/checkout`, {responseType: 'arraybuffer',observe:'response'});
  }

  public checkIn(intent: {
    collectionId: number,
    instanceId: number,
    versionId: number,
    checkIn: boolean
    checkInData: Partial<CollectionCheckinData>
  }) : Observable<void> {
    const postFix = intent.checkIn ? 'checkin' : 'updateandkeepcheckedout'
    return this.http.post<void>(`api/collection/id/${intent.collectionId}/instancesid/${intent.instanceId}/versionsID/${intent.versionId}/${postFix}`, intent.checkInData);
  }

  public updateDocumentOnly(intent: {
    collectionId: number,
    instanceId: number,
    versionId: number,
    checkInData: Partial<CollectionCheckinData>
  }) : Observable<void> {
    return this.http.post<void>(`api/collection/id/${intent.collectionId}/instancesid/${intent.instanceId}/versionsID/${intent.versionId}/updatedocumentonly`, intent.checkInData);
  }
}
