<bizz-modal modalSize="xs" title="PermissionHistoryDetails" [contentTemplate]="contentTemplateRef"></bizz-modal>

<ng-template #contentTemplateRef>
    <div class="modal-content">
        @if(data.permissionHistory.AccessType !== data.permissionHistory.PreviousAccessType){
        <div>
            <p class="inline-help"> {{"AccessTypeChangedFrom" | translate}} {{data.permissionHistory.PreviousAccessTypeString}} <span class="text-lowercase">{{"To" | translate}}</span>
                {{data.permissionHistory.AccessTypeString}} <span class="text-lowercase">{{"Except" | translate }}</span></p>
        </div>
        }

        <kendo-grid [data]="data.historyDetails">
            <kendo-grid-column field="ObjectName" [title]="'ObjectName' | translate"></kendo-grid-column>
            <kendo-grid-column [title]="'Action' | translate">
                <ng-template kendoGridCellTemplate let-dataItem>
                    @if(dataItem.EventType == AuditLogEventType.Create){
                    <span>{{"Added" | translate}}</span>
                    }
                    @if(dataItem.EventType == AuditLogEventType.Delete){
                    <span>{{"Removed" | translate}}</span>
                    }
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</ng-template>