import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconLibNamePipe } from '../../../../../pipes/icon-lib-name/icon-lib-name.pipe';
import { NotificationPopUpDto } from '../../../../../../../models/ts/notification-pop-up-dto.model';
import { ReadType } from 'src/models/ts/read-type.model';
import { calculateDateStrings } from '../../../../../functions/helpers/date-difference';
import { NotificationFilterPipe } from '../../../../../pipes/filter/notification-filter.pipe';
import { selectUserSettingsFeature } from '../../../../../../store/root-state-selectors';
import { Store } from '@ngrx/store';
import { IconComponent } from '../../../icon/icon.component';

/**
 * Notification block is part of header-notifications.
 * It is a reusable component for each block inside notifications list.
 */

@Component({
  selector: 'bizz-notification-block',
  templateUrl: './notification-block.component.html',
  styleUrls: ['./notification-block.component.scss'],
  standalone: true,
  imports: [CommonModule, IconLibNamePipe, NotificationFilterPipe, IconComponent],
})
export class NotificationBlockComponent {
  @Input() notification: NotificationPopUpDto;
  @Input() isReminder: boolean = false;
  @Output() public readIcon = new EventEmitter<number>();
  @Output() public unreadIcon = new EventEmitter<number>();
  @Output() public openReminder = new EventEmitter<number>();

  public readonly ReadType = ReadType;
  public calculateDateStrings = calculateDateStrings;
  public language: string;

  public constructor(private store$: Store) {
  }

  public read(id: number): void{
    this.readIcon.emit(id);
  }

  public unread(id: number): void{
    this.unreadIcon.emit(id);
  }

  public open(id: number): void{
    this.openReminder.emit(id);
  }

  public userSettingsSignal = this.store$.selectSignal(selectUserSettingsFeature);

  public ngOnInit(): void {
    this.language = this.userSettingsSignal().UserLanguagesCode;
  }

}
