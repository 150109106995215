import { Component, Input } from '@angular/core';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { CellActionType } from '../../../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';

@Component({
  selector: 'bizz-grid-cell-file',
  standalone: true,
  imports: [
    TranslatePipe
  ],
  templateUrl: './grid-cell-file.component.html',
  styleUrl: './grid-cell-file.component.scss'
})
export class GridCellFileComponent extends GridCellBaseComponent<number> {

  @Input() public hideDownload = false; //TODO Implement

  public downloadFile(): void { //TODO Implement
    this.onCellAction.emit({ 
      action: CellActionType.DOWNLOAD_FILE, 
      data: { data: this.data } 
    });
    //see angjs: ngBizzColumnFile
    //See UploadService R41 -> is now -> DownloadService R42
    //Also requires the VersionCompareService and VersionCompareDownloadModal to be implemented

  }
}
