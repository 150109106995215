import { createActionGroup, props } from '@ngrx/store';
import { CollectionStore } from './collections-state';

export const collectionsActions = createActionGroup({
  source: 'Collection',
  events: {
    getCollectionById: props<{ collectionId: number }>(),
    addCollection: props<{ collection: CollectionStore }>()
  },
});
