<div class="flex h-svh w-svw flex-col items-center font-sans text-default">
    <bizz-header class="w-full" [mode]="2">
    </bizz-header>
    <bizz-loader class="w-full" [showLoader]="(init$ | async) ?? false" [fullscreen]="true"></bizz-loader>
    @if (exam$ | async; as examDetails) {
        <div class="flex h-16 w-full flex-col justify-center bg-page-header px-6">
            <div class="hidden gap-2.5 truncate px-6 font-title text-3xl font-semibold md:block">
                {{"Exam" | translate }}:&nbsp;{{ examDetails.Name }}
            </div>
            <div class="block gap-2.5 truncate px-6 font-title text-3xl font-semibold md:hidden">
                {{ examDetails.Name }}
            </div>
            <hr class="mt-2 sm:hidden"/>
        </div>
        <div class="flex h-[calc(100%_-_5.8rem)] w-full flex-col items-center justify-center bg-body-alternate sm:h-[calc(100%_-_3rem_-_4rem)] sm:py-4" [class.justify-start]="pageConfig$.value.showTimeline">
            @if (pageConfig$.value.showTimeline && examDetails.TotalSessionQuestions > 0) {
                <div class="my-2 flex w-full flex-col items-center sm:my-4" >
                    <span class="w-full text-center text-2xl font-bold"> 
                        {{ examDetails.Progress }} / {{ examDetails.TotalSessionQuestions }}
                    </span>
                        @if (questionsArray$ | async; as questionsArray) {
                        <kendo-stepper
                            class="exams-stepper !hidden w-full md:!grid"
                            [steps]="questionsArray"           
                            [currentStep]="examDetails.Progress - 1"
                            (activate)="onStepActivate($event)">
                            <ng-template kendoStepperStepTemplate let-step>
                                @if (step < examDetails.Progress) {
                                    <div class="custom-step flex h-5 w-5 items-center justify-center rounded-full bg-green-500"></div>
                                } @else if (step > examDetails.Progress) {
                                    <div class="custom-step flex h-5 w-5 items-center justify-center rounded-full bg-green-500">
                                        <div class="h-3 w-3 rounded-full bg-divider-handle"></div>
                                    </div>
                                } @else if (step === examDetails.Progress) {
                                    <div class="custom-step flex h-7 w-7 items-center justify-center rounded-full bg-white shadow-md">
                                        <div class="h-5 w-5 rounded-full bg-green-500"></div>
                                    </div>
                                }
                            </ng-template>
                        </kendo-stepper>
                    }                    
                </div>
            }

            <div class="flex h-full w-full flex-col items-center gap-10 overflow-y-hidden bg-form px-7 pb-7 pt-2 shadow-md sm:w-5/6 sm:rounded-lg sm:p-8">
                @switch (pageConfig$.value.pageContext) {
                    @case (TrainingExamPageComponentPageContext.COVER_PAGE) {
                        <bizz-training-exam-cover-content class="flex h-full w-full flex-1 flex-col overflow-y-hidden" (startExam)="startExam()"
                                                          [isLoading]="(isLoading$ | async) ?? false"
                                                          [exam]="examDetails"></bizz-training-exam-cover-content>

                    }
                    @case (TrainingExamPageComponentPageContext.QUESTION_PAGE) {
                        <bizz-training-exam-question-content class="flex w-full flex-1 flex-col overflow-y-hidden" [isLoading]="(isLoading$ | async) ?? false"
                                                             [exam]="examDetails" (nextQuestion)="nextQuestion($event)"></bizz-training-exam-question-content>
                    }
                    @case (TrainingExamPageComponentPageContext.RESULT_PAGE) {
                        <bizz-training-exam-result-content class="flex w-full flex-1 flex-col gap-2 overflow-y-hidden"
                                                             [exam]="examDetails" (viewReport)="viewReport()"></bizz-training-exam-result-content>
                    }
                    @case (TrainingExamPageComponentPageContext.REPORT_PAGE) {
                        <bizz-training-exam-report-content class="flex w-full flex-1 flex-col gap-2 overflow-y-hidden"
                                                           [exam]="examDetails" (completed)="viewResult()"></bizz-training-exam-report-content>
                    }
                }
            </div>
        </div>
    }

</div>
