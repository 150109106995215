<div class="modal-container justify-between md:justify-normal">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="dialogRef.close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
                {{ "UsedIn" | translate }} {{ data.examUsages.length }} {{ "OtherPlaces" | translate }}</h1>
        </div>
        <hr class="my-5"/>
        @if (usedInDocuments != null && usedInDocuments.length > 0) {
            <div class="flex flex-col justify-center gap-2 text-center">
                <div><b>{{ "ExamUsedOnDocuments" | translate }}</b></div>
                @for (doc of usedInDocuments; track $index) {
                    <div>{{doc.Name}}</div>
                }
            </div>
        }

        @if (usedInTrainings != null && usedInTrainings.length > 0) {
            <div class="flex flex-col justify-center gap-2 text-center">
                <div><b>{{ "ExamUsedOnTrainings" | translate }}</b></div>
                @for (training of usedInTrainings; track $index) {
                    <div>{{training.Name}}</div>
                }
            </div>
        }
    </div>
    <div class="modal-buttons">
        <button class="default" (click)="dialogRef.close()">
            {{ 'Close' | translate }}
        </button>
    </div>
</div>
