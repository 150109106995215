import { Component, Input } from '@angular/core';
import { IconComponent } from '../../ui/icon/icon.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'bizz-loader-button',
  standalone: true,
  imports: [
    IconComponent,
    CommonModule
  ],
  templateUrl: './loader-button.component.html',
  styleUrl: './loader-button.component.scss',
})
export class LoaderButtonComponent {
  @Input({required: true}) public isLoading: boolean | null;
  @Input() public disabled: boolean | null;
  @Input() public isEnabled: boolean = false; // override disabled while loading
  @Input() public buttonClass: string | null;
}
