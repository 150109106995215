/**
 * All icon names with custom colors
 * Default icon color is set in extension-icon.component.ts
 * (not specified here).
 */
export const EXTENSION_ICON_COLORS: { [dataType: string]: string } = {
  'fa-file-word': '#066fba',
  'fa-file-pdf': '#ed3f39',
  'fa-file-spreadsheet': '#2d8c04',
  'fa-file-excel': '#2d8c04',
  'fa-file-powerpoint': '#E85B15',
  'fa-file-image': '#94d6f7',
};