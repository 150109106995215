import { Component } from '@angular/core';
import {EditorModule} from "@progress/kendo-angular-editor";
import {IconComponent} from "../../../ui/icon/icon.component";
import {ToolBarModule} from "@progress/kendo-angular-toolbar";
import {TranslatePipe} from "../../../../pipes/translate/translate.pipe";
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'bizz-file-upload-delete-modal',
  standalone: true,
    imports: [
        EditorModule,
        IconComponent,
        ToolBarModule,
        TranslatePipe
    ],
  templateUrl: './file-upload-delete-modal.component.html',
  styleUrl: './file-upload-delete-modal.component.scss'
})
export class FileUploadDeleteModalComponent {
  public constructor(public dialogRef: DialogRef) {
  }

}
