<div class="gap-y flex h-full flex-col">
        <div class="flex h-[calc(100%_-_4rem)] flex-col gap-5 lg:flex-row">

            <div class="flex items-center justify-center">
                <img class="min-w-80 max-w-80 xl:max-w-xl" src="/assets/image/exam-splash.svg" alt="exam-splash" />
            </div>

                <div class="flex flex-col justify-center overflow-y-auto">
                    <div class="mb-5 font-title text-2xl font-semibold leading-7 text-widget-header">
                        {{ exam.Name }}
                    </div>
                    <div class="overflow-y-auto font-sans text-base sm:text-sm"
                        [innerHtml]="exam.Description | safe : 'html'">
                    </div>
                </div>
        </div>

    <div class="flex w-full grow items-end justify-end">
        <bizz-loader-button buttonClass="save" (click)="startExam.emit()" [isLoading]="isLoading">
            {{ "StartExam" | translate }}
            <bizz-icon class="h-4 w-4" iconName="caret-right"></bizz-icon>
        </bizz-loader-button>
    </div>
</div>