import { Component, DestroyRef, TemplateRef } from '@angular/core';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

import { ContextMenuComponent } from '../../../../../features/bizzmine/widgets/context-menu/context-menu.component';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { Dialog } from '@angular/cdk/dialog';
import { AuditlogInfoModalComponent } from 'src/app/features/bizzmine/history/components/auditlog-info-modal/auditlog-info-modal.component';
import { CollectionHistoryApiService } from 'src/app/api/bizzmine/collection-history/collection-history-api.service';
import { AuditLogInfoDto } from 'src/models/ts/audit-log-info-dto.model';
import { AuditLogItemType } from 'src/models/ts/audit-log-item-type.model';
import { AuditLogItem } from 'src/app/features/bizzmine/history/interfaces/auditlog.interface';
import { AuditLogPropertyHistoryComponent } from 'src/app/features/bizzmine/history/components/auditlog-property-history/auditlog-property-history.component';
import { CollectionFormApiService } from 'src/app/api/bizzmine/collection-form/collection-form-api.service';
import { MailForMailTemplateDto } from 'src/models/ts/mail-for-mail-template-dto';
import { SendMailFromTemplateComponent } from 'src/app/features/bizzmine/form/components/controls/form-mailing-list/send-mail-from-template/send-mail-from-template.component';
import { AuditlogForwardReasonComponent } from 'src/app/features/bizzmine/history/components/auditlog-forward-reason/auditlog-forward-reason.component';
import { AuditlogQueueEventsComponent } from 'src/app/features/bizzmine/history/components/auditlog-queue-events/auditlog-queue-events.component';
import { AuditlogPermissionHistoryComponent } from 'src/app/features/bizzmine/history/components/auditlog-permission-history/auditlog-permission-history.component';
import { TrnTrainingQueueHistoryDto } from 'src/models/ts/trn-training-queue-history-dto.model';
import { TrackChangesApiService } from 'src/app/api/bizzmine/track-changes/track-changes-api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuditlogTrackChangesComponent } from 'src/app/features/bizzmine/history/components/auditlog-track-changes/auditlog-track-changes.component';
import { GridOptionsDto } from 'src/models/ts/grid-options-dto.model';
import { take } from 'rxjs';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';


/**
 * Cell with actions (read, edit, execute, menu,...) for an instance.
 */
@Component({
  selector: 'bizz-grid-cell-auditlog-info-actions',
  standalone: true,
  imports: [
    IconComponent,
    ContextMenuComponent,
    TooltipsModule,
    TranslatePipe],
  templateUrl: './grid-cell-auditlog-info-actions.component.html',
  styleUrls: ['./grid-cell-auditlog-info-actions.component.scss']
})
export class GridCellAuditlogInfoActionsComponent extends GridCellBaseComponent<boolean> {

  public get auditLogItem(): AuditLogItem {
    return this._data as AuditLogItem;
  }

  protected readonly AuditLogItemType = AuditLogItemType;
  public activeContentTemplateRef: TemplateRef<any>;

  public constructor(public dialog: Dialog,
                     private collectionHistoryApiService: CollectionHistoryApiService,
                     private collectionFormApiService: CollectionFormApiService,
                     private trackChangesApiService: TrackChangesApiService,
                     private destroyRef: DestroyRef) {
    super();
  }

  public showInfo(): void {
    this.collectionHistoryApiService.getHistoryInfo(
      this.auditLogItem.AuditLogsID,
      this.auditLogItem.CollectionsID,
      this.auditLogItem.InstancesID,
      this.auditLogItem.VersionsID).pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (data: AuditLogInfoDto) => {
          this.dialog.open(AuditlogInfoModalComponent, { data });
        }
      );
  }

  public showTrackChanges(): void {
    this.trackChangesApiService.getGridOptions().pipe(take(1))
      .subscribe((gridOptions: GridOptionsDto) => {
        this.dialog.open(AuditlogTrackChangesComponent, {
          data: {
            collectionId: this.auditLogItem.CollectionsID,
            instanceId: this.auditLogItem.InstancesID,
            versionId: this.auditLogItem.VersionsID,
            auditlogId: this.auditLogItem.AuditLogsID,
            gridOptions: gridOptions
          }
        });
      });

  }

  public showQueueEvents(): void {
    this.trackChangesApiService.getTrainingQueueHistory(
      this.auditLogItem.CollectionsID,
      this.auditLogItem.InstancesID,
      this.auditLogItem.VersionsID,
      this.auditLogItem.AuditLogsID).pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (data: TrnTrainingQueueHistoryDto[]) => {
          this.dialog.open(AuditlogQueueEventsComponent, { data: data });
        });
  }

  public showPermissionHistory(auditLogItem: AuditLogItem): void {
    this.dialog.open(AuditlogPermissionHistoryComponent, { data: { auditLogItem } });
  }

  public showPropertyHistory(auditLogItem: AuditLogItem): void {
    this.dialog.open(AuditLogPropertyHistoryComponent, { data: auditLogItem });

  }

  public showForwardedReason(auditLogItem: AuditLogItem): void {
    this.collectionHistoryApiService.getHistoryInfo(
      this.auditLogItem.AuditLogsID,
      this.auditLogItem.CollectionsID,
      this.auditLogItem.InstancesID,
      this.auditLogItem.VersionsID).pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (auditLogInfo: AuditLogInfoDto) => {
          this.dialog.open(AuditlogForwardReasonComponent, {
            data: {
              auditLogItem,
              auditLogInfo
            }
          });
        });
  }

  public showSendMailModal(mailId: number): void {
    this.collectionFormApiService.getMailTemplateById({ mailId }).pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (mail: MailForMailTemplateDto) => {
          this.dialog.open(SendMailFromTemplateComponent, {
            data: {
              template: mail,
              instanceId: mail.InstancesID,
              collectionId: mail.CollectionsID,
              versionId: mail.VersionsID,
              mailIds: mail.mailId,
              viewOnly: true
            }
          });
        });
  }


}
