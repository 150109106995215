import { animate, state, style, transition, trigger } from '@angular/animations';

/**
 * Slide in out is an animation used for sliding the mobile sidebar eas-in & out.
 */

export const slideInOut = trigger('slideInOut', [
  state('in', style({
    marginLeft: '-100vw', // Set the desired width for 'in' state
  })),
  state('out', style({
    marginLeft: '0', // Set the desired width for 'out' state
  })),
  transition('in => out', animate('300ms ease-in-out')),
  transition('out => in', animate('300ms ease-in-out'))
]);
