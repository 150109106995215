export enum ProtectedFieldType {
	Unknown = -1,
	ID = 1,
	RegistrationTime = 2,
	RegisteredOnBehalfOf = 3,
	Extension = 4,
	Size = 5,
	CreationDate = 6,
	VersionDate = 7,
	Title = 8,
	CompletedOn = 9,
	File = 10,
	Status = 11,
	EarliestDueDate = 12,
	RegistrationYear = 100,
	RegistrationMonth = 101,
	RegistrationQuarter = 102,
	RegisteredBy = 103,
	RegistrationYearWeek = 104,
	RegistrationWeek = 105,
	RegistrationYearMonth = 106,
	RegistrationYearQuarter = 107,
	PendingSteps = 150,
	DocumentStatus = 300,
	DocumentCheckinStatus = 301,
	DocumentFlow = 302,
	ChangeReason = 303,
	MinorVersion = 350,
	MajorVersion = 351,
	Version = 352,
	PdfFile = 360,
	SkillName = 404,
	GradeName = 406,
	GradeScore = 407,
	TrainingCategoryName = 408,
	SkillCategoryName = 409,
	TrainingName = 410,
	TrainingType = 411,
	TrainingDescription = 412,
	TrainingPrerequisites = 413,
	TrainingMaxParticipants = 414,
	TrainingResponsibes = 415,
	TrainingTargetFunctions = 416,
	TrainingSessionFrom = 417,
	TrainingSessionType = 418,
	TrainingSessionSubscribedUsers = 419,
	DocColDistributeOrgChart = 420,
	CostsName = 421,
	CostsDescription = 422,
	CostsPrice = 423,
	TrainingLocation = 424,
	TrainingSubscriptionType = 425,
	TrainingEvaluationType = 426,
	TrainerName = 427,
	TrainerType = 428,
	TrainerOrgChart = 429,
	TrainingSessionLessonFrom = 430,
	TrainingSessionLessonTo = 431,
	TrainingSessionLessonRemarks = 432,
	TrainingSessionTo = 433,
	SkillExpiracyType = 434,
	SkillExpiracyDays = 435,
	ExamCategoryName = 436,
	ExamName = 405,
	ExamDescription = 437,
	ExamTargetAudience = 438,
	ExamReadyForPublicationFlag = 439,
	ExamIsPublishedFlag = 440,
	ExamAmountOfQuestions = 441,
	ExamAmountOfAnswersPerQuestion = 442,
	ExamManager = 443,
	ExamCreator = 444,
	ExamSessionExaminee = 445,
	ExamSessionScore = 446,
	ExamSessionOrigin = 447,
	ExamSessionProgress = 448,
	ExamQuestionsAnswersQuestionID = 449,
	ExamQuestionsAnswersAnswerID = 450,
	ExamQuestionAnswerQuestionCaption = 451,
	ExamQuestionAnswerAnswerCaption = 452,
	ExamQuestionAnswerScore = 453,
	ExamScoreEvaluations = 454,
	ExamQuestionAnswerMaxPossibleScore = 455,
	ExamShowResultToExaminee = 456,
	TrainingSessionEvaluationType = 457,
	AnswerScorePercentage = 458,
	UserFirstName = 1000,
	UserLastName = 1001,
	UserEmail = 1002,
	FunctionName = 2000,
	DepartmentName = 3000,
	RoleName = 4000
}