import { CollectionFormField } from '../../../../models/ts/collection-form-field.model';
import { FormFieldType } from '../../../../models/ts/form-field-type.model';
import { LinkedCollectionType } from '../../../../models/ts/linked-collection-type.model';

// TODO: Verify and possibly expand this function
/**
 * Returns whether the given field is a grid based on it's FormFieldType
 * @param field
 */
export function isGrid(field: CollectionFormField | undefined): boolean {
  return field?.FormFieldType == FormFieldType.LinkedCollection || field?.FormFieldType == FormFieldType.List;
}

export function getLinkedCollectionType(field: CollectionFormField | undefined): LinkedCollectionType {
  return isGrid(field) ? LinkedCollectionType.GridRecord : LinkedCollectionType.SingleRecord;
}