<div class="flex">
    <!-- TODO: read only state   -->
    <bizz-macro-button [style]="column.ButtonStyle"
                       [icon]="column.ButtonIcon"
                       [disabled]="false"
                       [caption]="column.Caption"
                       [displayType]="column.ButtonDisplayType"
                       [macroButtonType]="MacroButtonType.WidgetList"
                       [macroButtonProperties]="{data, column}"
                       [url]="value!"></bizz-macro-button>
</div>