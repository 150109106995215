import { Component, Inject } from '@angular/core';
import { AuditLogItem } from '../../interfaces/auditlog.interface';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { ModalComponent } from 'src/app/shared/components/modals/modal/modal.component';
import { AuditLogInfoDto } from 'src/models/ts/audit-log-info-dto.model';

@Component({
  selector: 'bizz-auditlog-forward-reason',
  standalone: true,
  imports: [TranslatePipe, ModalComponent],
  templateUrl: './auditlog-forward-reason.component.html',
  styleUrl: './auditlog-forward-reason.component.scss'
})
export class AuditlogForwardReasonComponent {
  public constructor(@Inject(DIALOG_DATA) public data:{ auditLogItem: AuditLogItem, auditLogInfo: AuditLogInfoDto}) {
  }
}
