<ng-template #toolbarTemplate>
    <kendo-dropdownlist kendoTooltip 
            #dropdownlist
            [tabindex]="tabindex"
            [(value)]="value"
            [data]="items"
            [defaultItem]="defaultItemText"
            [attr.title]="value ?? defaultItemText"
            (selectionChange)="selectionChange($event)"
    >
    </kendo-dropdownlist>
</ng-template>