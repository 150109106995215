import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconLibrary } from '../../../../../models/ts/icon-library.model';
import { CommonModule } from '@angular/common';
import { IconLibNamePipe } from '../../../pipes/icon-lib-name/icon-lib-name.pipe';

/**
 * Displays an icon from supported icon libraries
 * */
@Component({
  selector: 'bizz-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  imports: [CommonModule, IconLibNamePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
  public readonly IconLibrary = IconLibrary;

  @Input() public hover = false;
  /**
   * Which library to display an icon from
   *
   * @type {IconLibrary}
   */
  @Input() public iconLibrary: IconLibrary | string = IconLibrary.FontAwesome;
  /**
   * Class name of the icon
   *
   * FontAwesome Example: user, bell, etc.
   * @type {string}
   */
  @Input({ required: true }) public iconName: string;

  @Input() public iconStyle: 'solid' | 'duotone' | 'regular' | 'brands' = 'solid';

  /**
   * Only works on FontAwesome icons
   * Options include: rotate-[angle],flip-[direction], [direction]-[Units are 1/16em],grow-[size],shrink-[size]
   * Example: rotate-90,flip-v,up-8,grow-2,shrink-3
   * See: https://fontawesome.com/docs/web/style/power-transform
   */
  //@Input() transform: string | null | undefined = null;
}
