import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeService } from 'src/app/core/services/theme/theme.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

/**
 * Displays the BizzMine Logo in different variants.
 * Use the `type` input to select the variant.
 * @example
 * <bizz-brand-logo type="branded"></bizz-brand-logo>
 * <bizz-brand-logo type="full"></bizz-brand-logo>
 */
@Component({
  selector: 'bizz-brand-logo',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './brand-logo.component.html',
  styleUrls: ['./brand-logo.component.scss']
})
export class BrandLogoComponent implements OnInit {
  //overrride type 
  @Input() public logoType: BrandLogoType | undefined = undefined;

  public type: BrandLogoType = BrandLogoType.FULL;

  //eslint-disable-next-line @typescript-eslint/naming-convention
  public readonly BrandLogoType = BrandLogoType;


  public brandingLogo: string | undefined = undefined;

  public constructor(private readonly themeService: ThemeService,
                     private readonly destroyRef: DestroyRef) {
  }

  public ngOnInit(): void {
    this.themeService.currentTheme$.pipe(
      takeUntilDestroyed(this.destroyRef),
      map(theme => {
        this.brandingLogo = theme?.Logo;
        if(this.logoType == undefined){
          this.type = this.brandingLogo ? BrandLogoType.BRANDED : BrandLogoType.FULL;
        }else{
          this.type = this.logoType;
        }
      })
    ).subscribe();
  }
}

/**
 * The different variants of the BizzMine Logo.
 */
export enum BrandLogoType {
  BRANDED = 'branded',
  FULL = 'full',
  ICON = 'icon'
}
