import { NotificationPopUpDto } from '../../../../models/ts/notification-pop-up-dto.model';

export type HeaderNotificationsState = {
  notifications: NotificationPopUpDto[],
  count: number,
  loading: boolean,
  search: string
};

export const initialHeaderNotificationsStoreState: HeaderNotificationsState = {
  notifications: [],
  count: 0,
  loading: false,
  search: ''
};

