<ng-container *ngIf="flowStatus$ | async as _flowStatus">
  <bizz-flow-status-header
    class="shrink-0"
    [title]="_flowStatus.Name" [flowVersion]="_flowStatus.Flow.Version"
    (backClick)="onBackClicked()">
  </bizz-flow-status-header>
  <div class="flex w-full grow flex-col gap-2.5 overflow-y-auto p-4">
    <bizz-stepper class="sm:hidden" [steps]="steps" [currentStep]="currentStep.value" 
    (currentStepChange)="currentStep.next($event)" [wizard]="false"></bizz-stepper>
    <bizz-flow-status-diagram #bizzFlowStatusDiagram
        class="hidden h-full min-h-[calc((100svh_/_3)_*_2)] w-full overflow-hidden rounded-xl border-4 border-widget bg-body sm:block"
        [class]="{'!block': currentStep.value == flowStatusStepType.FLOW }"
        [flow]="_flowStatus.Flow"
        [tasks]="_flowStatus.Tasks">
    </bizz-flow-status-diagram>
    @if(_flowStatus.Tasks && _flowStatus.Tasks.length > 0) {
        <bizz-flow-status-tasks 
        class="hidden w-full grow sm:block"
        [class]="{ '!block': currentStep.value == flowStatusStepType.TASKS }"
        [tasks]="_flowStatus.Tasks">
      </bizz-flow-status-tasks>
    }
  </div>
</ng-container>

