import { AfterViewInit, ChangeDetectorRef, Component, HostListener, inject, Input } from '@angular/core';
import { UIMonitorTask } from '../monitor-step/monitor-step.component';
import { extractLinesFromTextNode } from '../../../../functions/helpers/extract-lines-from-node';

@Component({
  standalone: true,
  imports: [],
  template: '',
})
export abstract class MonitorStepReasonComponent implements AfterViewInit {
  @Input({required: true}) abstract task: UIMonitorTask;
  public isCollapsible = false;
  private cdr = inject(ChangeDetectorRef)

  public ngAfterViewInit() {
    this.setIsCollapsible();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.setIsCollapsible();
  }

  protected setIsCollapsible(): void {
    const elem = document.getElementById(this.getTextElementName());
    if (elem) {
      //Always show icon if text was truncated.
      if (elem.classList.contains('truncate')) {
        this.isCollapsible = true;
      }
      else if (elem.firstChild !== null) {
        this.isCollapsible = extractLinesFromTextNode(elem.firstChild).length > 1;
      }
    }
  }

  protected abstract getTextElementName(): string;
}