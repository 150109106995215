import { Directive, inject, ViewContainerRef } from '@angular/core';

/**
 * Exposes viewContainerRef to enable dynamically loading components.
 * 
 * Note: make sure to add it to your component imports for it to work.
 * @see https://angular.io/guide/dynamic-component-loader
 */
@Directive({
  selector: '[viewContainerRef]',
  standalone: true
})
export class ViewContainerRefDirective {
   public viewContainerRef = inject(ViewContainerRef);
}
