<div class="modal-container">
    <div class="modal-close-container">
        <bizz-icon iconName="xmark" (click)="dialogRef.close()"></bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1>
                {{ "SelectTemplate" | translate }}</h1>
            <hr class="m-auto mb-5 mt-5 h-0 w-full border-t-2 border-divider">
        </div>


        <div>
            <div class="flex">
                <div class="w-full h-full">
                    @if(widgetData && lookupData){
                        <bizz-instance-selection-list class="flex h-svh min-h-80 flex-col overflow-hidden rounded sm:h-[60svh] sm:max-w-[1024px]"
                                                      [data]="widgetData"
                                                      [isGridField]="false"
                                                      [openLinksInStack]="false"
                                                      [lookupData]="lookupData"
                                                      (selectionChange)="onSelectionChange($event)">

                        </bizz-instance-selection-list>
                    }

                </div>
            </div>

        </div>

        <div class="modal-buttons pt-4">
            <button class="save" (click)="close()">
                <bizz-icon class="mr-2.5 size-5 sm:size-4" iconName="eye"></bizz-icon>
                {{ 'Ok' | translate }}
            </button>

        </div>
    </div>
</div>

