import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { AsyncPipe, NgClass } from '@angular/common';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { LoaderButtonComponent } from '../../../../../../shared/components/loader-button/loader-button/loader-button.component';
import { BehaviorSubject } from 'rxjs';
import { TrnExamDto } from '../../../../../../../models/ts/trn-exam-dto.model';
import { SafePipe } from 'safe-pipe';

@Component({
  selector: 'bizz-training-exam-report-content',
  standalone: true,
  imports: [
    TranslatePipe,
    AsyncPipe,
    IconComponent,
    LoaderButtonComponent,
    NgClass,
    SafePipe
  ],
  templateUrl: './training-exam-report-content.component.html',
  styleUrl: './training-exam-report-content.component.scss'
})
export class TrainingExamReportContentComponent  implements OnInit {

  @Input({required: true}) public exam: TrnExamDto;
  @Output() public completed = new EventEmitter<void>();

  public reportConfig$ = new BehaviorSubject<{
    currentIndex: number
  } >({
    currentIndex : 0
  });
  public totalQuestion = 0;

  public ngOnInit(): void {
     this.totalQuestion = this.exam?.TotalSessionQuestions;
  }
  public nextReportQuestion(): void {
    if ((this.reportConfig$.value.currentIndex + 1) >= this.totalQuestion) {
      this.completed.emit();
    }
    this.reportConfig$.next({
      currentIndex: this.reportConfig$.value.currentIndex + 1
    });
  }
}
