import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepType } from '../../../../../../models/ts/step-type.model';
import { TaskCompletedType } from '../../../../../../models/ts/task-completed-type.model';
import { AssessmentType } from '../../../../../../models/ts/assessment-type.model';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TimePipe } from '../../../../pipes/dates/time.pipe';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { UtcDatePipe } from '../../../../pipes/dates/utc-date.pipe';
import { MonitorStepDto } from '../../../../../../models/ts/monitor-step-dto.model';
import { MonitorTaskDto } from '../../../../../../models/ts/monitor-task-dto.model';
import { MonitorStepReadAndUnderstoodComponent } from '../monitor-step-read-and-understood/monitor-step-read-and-understood.component';
import { MonitorStepForwardComponent } from '../monitor-step-forward/monitor-step-forward.component';

@Component({
  selector: 'bizz-monitor-step',
  standalone: true,
  imports: [CommonModule, IconComponent, TimePipe, TranslatePipe, UtcDatePipe, MonitorStepReadAndUnderstoodComponent, MonitorStepForwardComponent],
  templateUrl: './monitor-step.component.html',
  styleUrls: ['./monitor-step.component.scss'],
})
export class MonitorStepComponent {
  protected readonly StepType = StepType;
  protected readonly TaskCompletedType = TaskCompletedType;
  protected readonly AssessmentType = AssessmentType;

  @Input({ required: true }) public step: UIMonitorStep;
  @Input({ required: true }) public isLastStep: boolean;

  protected isTextCollapsable(elementId: string): boolean {
    const elem = document.getElementById(elementId);
    if (elem) {
      //Always show icon if text was truncated.
      if (elem.classList.contains('truncate')) {
        return true;
      }
      if (elem.firstChild !== null) {
        return this.extractLinesFromTextNode(elem.firstChild!).length > 1;
      }
      return false;
    }
    return true;
  }

  // Source: https://www.bennadel.com/blog/4310-detecting-rendered-line-breaks-in-a-text-node-in-javascript
  private extractLinesFromTextNode( textNode: Node ) {

    if ( textNode.nodeType !== 3 ) {

      throw( new Error( "Lines can only be extracted from text nodes." ) );

    }

    // BECAUSE SAFARI: None of the "modern" browsers seem to care about the actual
    // layout of the underlying markup. However, Safari seems to create range
    // rectangles based on the physical structure of the markup (even when it
    // makes no difference in the rendering of the text). As such, let's rewrite
    // the text content of the node to REMOVE SUPERFLUOS WHITE-SPACE. This will
    // allow Safari's .getClientRects() to work like the other modern browsers.
    textNode.textContent = this.collapseWhiteSpace( textNode.textContent ?? '' );

    // A Range represents a fragment of the document which contains nodes and
    // parts of text nodes. One thing that's really cool about a Range is that we
    // can access the bounding boxes that contain the contents of the Range. By
    // incrementally adding characters - from our text node - into the range, and
    // then looking at the Range's client rectangles, we can determine which
    // characters belong in which rendered line.
    let textContent = textNode.textContent ?? '';
    let range = document.createRange();
    let lines = [];
    let lineCharacters = [];

    // Iterate over every character in the text node.
    for ( let i = 0 ; i < textContent?.length ; i++ ) {

      // Set the range to span from the beginning of the text node up to and
      // including the current character (offset).
      range.setStart( textNode, 0 );
      range.setEnd( textNode, ( i + 1 ) );

      // At this point, the Range's client rectangles will include a rectangle
      // for each visually-rendered line of text. Which means, the last
      // character in our Range (the current character in our for-loop) will be
      // the last character in the last line of text (in our Range). As such, we
      // can use the current rectangle count to determine the line of text.
      let lineIndex = ( range.getClientRects().length - 1 );

      // If this is the first character in this line, create a new buffer for
      // this line.
      if ( ! lines[ lineIndex ] ) {

        lines.push( lineCharacters = [] );

      }

      // Add this character to the currently pending line of text.
      lineCharacters.push( textContent.charAt( i ) );

    }

    // At this point, we have an array (lines) of arrays (characters). Let's
    // collapse the character buffers down into a single text value.
    lines = lines.map(characters => {
      return this.collapseWhiteSpace( characters.join( "" ) )
    });
    return lines;
  }

  // Source: https://www.bennadel.com/blog/4310-detecting-rendered-line-breaks-in-a-text-node-in-javascript
  /**
   * I normalize the white-space in the given value such that the amount of white-
   * space matches the rendered white-space (browsers collapse strings of white-space
   * down to single space character, visually, and this is just updating the text to
   * match that behavior).
   */
  private collapseWhiteSpace( value: string ): string {

    return( value.trim().replace( /\s+/g, " " ) );

  }
}

export interface UIMonitorStep extends MonitorStepDto {
  collapsed: boolean;
  Tasks: UIMonitorTask[];
}

export interface UIMonitorTask extends MonitorTaskDto {
  collapsed: boolean;
  reasonCollapsed: boolean;
}
