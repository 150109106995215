import { Component } from '@angular/core';
import { IconComponent } from '../../../icon/icon.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { HeaderPopupBase } from '../../classes/header-popup-base';

@Component({
  selector: 'bizz-header-help',
  standalone: true,
  imports: [IconComponent,
    PopupModule,
    TranslatePipe,
  ],
  templateUrl: './header-help.component.html',
  styleUrl: './header-help.component.scss'
})
export class HeaderHelpComponent  extends HeaderPopupBase {

  public openHelpCenter(): void {
    window.open('https://portal.bizzmine.com/', '_blank');
  }

  public openSupport(): void {
    window.open("https://www.bizzmine.com/en/supportticket", "_blank");
  }
}
