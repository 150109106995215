import { GridOptions } from 'src/app/shared/classes/list/grid-options';
import { LookupData } from './../../../../../../../shared/services/lookup/lookup.service';
import { AsyncPipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { GridModule, PagerModule, SharedFilterModule } from '@progress/kendo-angular-grid';
import { SkeletonModule } from '@progress/kendo-angular-indicators';
import { filter, of, shareReplay, switchMap, take, tap } from 'rxjs';
import { SafePipe } from 'safe-pipe';
import { CollectionListWidgetComponent } from 'src/app/features/bizzmine/widgets/collection-list-widget/collection-list-widget.component';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { ContextMenuComponent } from 'src/app/features/bizzmine/widgets/context-menu/context-menu.component';
import { GridCellComponent } from 'src/app/shared/components/grid/cells/grid-cell/grid-cell.component';
import { FilterComponent } from 'src/app/shared/components/ui/filter-component/filter.component';
import { IconComponent } from 'src/app/shared/components/ui/icon/icon.component';
import { ExtensionIconComponent } from 'src/app/shared/components/ui/icon/extension-icon/extension-icon.component';
import { ToggleArrowComponent } from 'src/app/shared/components/ui/toggle-arrow/toggle-arrow.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { formsActions } from 'src/app/store/features/forms/forms-actions';
import { CollectionFormLookupApiService } from 'src/app/api/bizzmine/collection-form-lookup/collection-form-lookup-api.service';
import { FormType } from 'src/models/ts/form-type.model';
import { FilterItemType } from 'src/models/ts/filter-item-type.model';
import { KENDO_POPUP } from '@progress/kendo-angular-popup';

@Component({
  selector: 'bizz-instance-selection-list',
  templateUrl: '../../../../../widgets/collection-list-widget/collection-list-widget.component.html',
  styleUrls: ['../../../../../widgets/collection-list-widget/collection-list-widget.component.scss'],
  imports: [
    IconComponent,
    GridModule,
    NgForOf,
    GridCellComponent,
    SafePipe,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    SkeletonModule,
    NgIf,
    TranslatePipe,
    PagerModule,
    FilterComponent,
    GridComponent,
    AsyncPipe,
    SharedFilterModule,
    ToggleArrowComponent,
    ContextMenuComponent,
    ExtensionIconComponent,
    KENDO_POPUP
  ],
  standalone: true
})
export class InstanceSelectionListComponent extends CollectionListWidgetComponent {

  @Input() public formFieldId: number;
  @Input() public formId: number;
  @Input() public isGridField: boolean;
  @Input() public sourceCollectionId: number;
  @Input() public override lookupData: LookupData;
  //See ngBizzFormLookupField.js showSearchModalDeeperLevel()
  @Input() public formType: FormType;

  //TODO: RV for External Forms different listId needs to be set.
  @Output() public onNewRecord = new EventEmitter<void>();
  //@Input() public ExternalAccessList: ;
  protected override filterItemType = FilterItemType.InstanceSelector;
  private lookupApiService = inject(CollectionFormLookupApiService);

  public override ngOnInit(): void {
    super.ngOnInit();
  }

  public override newRecord(): void {
    if (this.lookupData.linkedLevel === 1) {
      //Clear selection when creating a new record
      this.selectionChange.emit([]);
      if (this.formFieldId !== undefined) {
        //TODO: RV (ask Jonas) should only happen once
        this.actions$.pipe(
          ofType(formsActions.formFetched, formsActions.formFetchedWithFiles, formsActions.formFetchedWithTemplate),
          take(1)
        ).subscribe(() => this.onNewRecord.emit());
        if (this.isGridField) {
          this.store$.dispatch(formsActions.getFormByCollectionGridField({
            formFieldId: this.formFieldId!,
            addToViewStack: true
          }));
        } else {
          this.store$.dispatch(formsActions.getFormByCollectionField({
            formFieldId: this.formFieldId!,
            addToViewStack: true
          }));
        }
      }
    } else {
      throw new Error('Deeper Level Create action not implemented');
    }

  }

  /**
   * Sets gridResultData$ to the observable that fetches the data from the server
   * When the list search filter updates and is not undefined,
   * we fetch the data from the server,
   * update the gridData
   * and update the totalItems and wheter we have an activeFilter
   */
  protected override initListSearch(): void {
    if (this.lookupData.linkedLevel === 1) {
      super.initListSearch();
    } else {
      this.gridResultData$ = this.listSearchFilter$.pipe(
        filter((search) => search != undefined),
        tap(() => this.loading = true),
        switchMap((search) => this.lookupApiService.getDeeperLevelLookupList(
          this.lookupData.parentFormsId,
          this.lookupData.childOriginalCollectionsId, // SourceParentCollectionsId (in VDS)
          this.sourceCollectionId, // collectionsId of the grid (deeper level make sure it's NOT a crosslink)
          this.lookupData.crossLinkCollectionsID, // crossLinkedCollections
          this.lookupData.dataDesignViewDataSourcesId,
          this.lookupData.listId ?? 0,
          this.lookupData.instancesId)
        ),
        tap((value) => {
          value.GridOptions.selectable = this.gridOptions.selectable;
          this.gridOptions = new GridOptions(value.GridOptions);
          this.gridData.next(value.Records);
        }),
        switchMap((value) => {
          return of({
            totalItems: value.Records.length, //TODO: Check if this is correct
            activeFilter: false
          });
        }),
        shareReplay(1));
    }
  }

}
