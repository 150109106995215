import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { headerNotificationsActions } from './header-notifications-actions';
import { exhaustMap, forkJoin, map, timer } from 'rxjs';
import { NotificationApiService } from '../../../api/bizzmine/notification/notification-api.service';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { headerNotificationsFeature } from './header-notifications-feature';
import { headerTasksActions } from '../header-tasks/header-tasks-actions';

@Injectable()
export class HeaderNotificationsEffects {
  private store$ = inject(Store);
  public fetchNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerNotificationsActions.fetchNotifications),
      concatLatestFrom(() => this.store$.select(headerNotificationsFeature.selectSearch)),
      exhaustMap(([action, search]) => forkJoin(
        { minimumTime: timer(300), request: this.notificationsApiService.getPopupNotifications(search) }
      )),
      map(({ request }) => ({ type: headerNotificationsActions.notificationsFetched.type, notifications: request }))
    )
  );

  public fetchAfterSearchUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerNotificationsActions.updateSearch),
      map(({ search }) => ({
          type: headerNotificationsActions.fetchNotifications.type
        })
      )
    )
  );

  public readNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerNotificationsActions.readNotifications),
      exhaustMap(({ ids }) =>
        this.notificationsApiService.readNotifications(ids).pipe(
          map(() => ({
              type: headerNotificationsActions.fetchNotifications.type
            })
          )
        )
      )
    )
  );

  public readNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerNotificationsActions.readNotification),
      exhaustMap(({ id }) =>
        this.notificationsApiService.readNotification(id).pipe(
          map(() => ({
              type: headerNotificationsActions.fetchNotifications.type
            })
          )
        )
      )
    )
  );

  public unreadNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerNotificationsActions.unreadNotification),
      exhaustMap(({ id }) =>
        this.notificationsApiService.unreadNotification(id).pipe(
          map(() => ({
              type: headerNotificationsActions.fetchNotifications.type
            })
          )
        )
      )
    )
  );

  public constructor(
    private actions$: Actions,
    private notificationsApiService: NotificationApiService
  ) {
  }
}
