<div #band class="grid auto-cols-fr grid-flow-col gap-4 sm:grid-flow-row sm:grid-cols-8 xl:grid-cols-12">
    @defer (on viewport(band)) {
    @for (item of dashboardBand.Items; track item.ID) {
    <bizz-widget class="calc-col-span-{{item.ColumnWidth}}" [style.width]="dashboardBand.FullSize ? '100%' : 'auto'"
        [style.height]="dashboardBand.FullSize ? ('calc(100svh - 3rem - 32px)') : (dashboardBand.Height + 'px')"
        [style.max-height]="dashboardBand.FullSize ? ('calc(100svh - 3rem - 32px)') : (dashboardBand.Height + 'px')"
        [workspaceItemId]="item.ParentID" [workspaceItemsWidgetId]="item.WorkspaceItemsWidgetID"
        [widgetId]="item.WidgetID">
    </bizz-widget>
    }
    }
</div>