import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { formsActions } from '../forms-actions';
import { combineLatestWith, exhaustMap, filter, map, of, switchMap, take } from 'rxjs';
import { ProtectedCollectionType } from '../../../../../models/ts/protected-collection-type.model';
import { CollectionFormService } from '../../../../features/bizzmine/form/services/collection-form.service';
import { ProtectedFieldType } from '../../../../../models/ts/protected-field-type.model';
import { selectForm } from '../forms-selectors';
import { Store } from '@ngrx/store';
import { SkillIntegrityCheck } from '../../../../../models/ts/skill-integrity-check.model';
import { CollectionFormLookupApiService } from '../../../../api/bizzmine/collection-form-lookup/collection-form-lookup-api.service';

@Injectable()
export class FormsIntegrityEffects {
  private actions$ = inject(Actions);
  private store$ = inject(Store);
  private collectionFormLookupApiService = inject(CollectionFormLookupApiService);
  public mapIntegrity$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.mapIntegrity),
    switchMap(({ form, documentVersionId, skillVersionId, examVersionId, trainingVersionId }) => {
        return this.collectionFormLookupApiService.getIntegrity({
          collectionId: form.data.CollectionsID,
          examVersionId: examVersionId,
          skillVersionId: skillVersionId,
          documentVersionId: documentVersionId,
          trainingVersionsId: trainingVersionId
        })
          .pipe(combineLatestWith(of(form)));
      }
    ),
    map(([found, form]) => {
      const integrity = new Map<number, SkillIntegrityCheck>();
      if (found?.LookupMismatches != null && found.LookupMismatches.length > 0) {
        const field = CollectionFormService.getField(form.data, (f) => {
          return f.ProtectedFieldType == ProtectedFieldType.SkillName;
        });
        if (field != null) {
          integrity.set(field.Id, found);
        }
      }

      if (found?.ExamUsages != null && found.ExamUsages.length > 0) {
        if (form.data.ProtectedCollectionType == ProtectedCollectionType.Exams) {
          const field = CollectionFormService.getField(form.data, (f) => {
            return f.ProtectedFieldType == ProtectedFieldType.SkillName;
          });
          if (field != null) {
            integrity.set(field.Id, found);
          }
        } else {
          const field = CollectionFormService.getField(form.data, (f) => {
            return f.ProtectedFieldType == ProtectedFieldType.ExamName;
          });
          if (field != null) {
            integrity.set(field.Id, found);
          }
        }
      }

      return ({
        type: formsActions.updateIntegrity.type,
        formId: form.id,
        integrity
      });
    })
  ));
  public getIntegrityOnFormFetched$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formFetched),
    filter(({ form }) => (form?.data != null && (form.data.ProtectedCollectionType == ProtectedCollectionType.TrnDocumentCollection || form.data.ProtectedCollectionType == ProtectedCollectionType.Trainings || form.data.ProtectedCollectionType == ProtectedCollectionType.Exams))),
    map(({ form }) => ({
        type: formsActions.getIntegrity.type,
        formId: form.id
      })
    )
  ));
  public getIntegrity$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getIntegrity),
    exhaustMap(({
                  formId
                }) => this.store$.select(selectForm(formId)).pipe(take(1))
    ),
    map((form) => ({
        type: formsActions.fetchIntegrity.type,
        form: form
      })
    )
  ));
  public fetchIntegrityForExams$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.fetchIntegrity),
    filter(({ form }) => (form?.data != null && form.data.ProtectedCollectionType == ProtectedCollectionType.Exams)),
    map(({ form }) => {
      let examversionsid = form.data.VersionsID;
      const docSkillVDS = form.data.ViewDataSources?.find(_ => _.ProtectedCollectionType == ProtectedCollectionType.LinkExamsSkills);

      const skillversionsid = docSkillVDS?.Instances[0]?.ChildVersionsID ?? 0;
      //const evaluationTypeField = form.Fields.find(_ => _.ProtectedFieldType == ProtectedFieldType.TrainingEvaluationType); //_.findWhere($scope.ngModel.fields, { ProtectedFieldType: Enums.ProtectedFieldType.TrainingEvaluationType });
      const evaluationTypeField = CollectionFormService.getField(form.data, (f) => {
        return f.ProtectedFieldType == ProtectedFieldType.TrainingEvaluationType;
      });

      if (evaluationTypeField && evaluationTypeField.Value == 1) {
        examversionsid = 0;
      }
      const documentversionsid = 0;
      const trainingversionsid = 0;
      return ({
        type: formsActions.mapIntegrity.type,
        form: form, documentversionsid,
        skillVersionId: skillversionsid,
        examVersionId: examversionsid,
        trainingVersionId: trainingversionsid,
        documentVersionId: documentversionsid
      });
    })
  ));

  public fetchIntegrityForTrainings$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.fetchIntegrity),
    filter(({ form }) => (form?.data != null && form.data.ProtectedCollectionType == ProtectedCollectionType.Trainings)),
    map(({ form }) => {
      let trainingversionsid = form.data.VersionsID;
      const docSkillVDS = form.data.ViewDataSources?.find(_ => _.ProtectedCollectionType == ProtectedCollectionType.LinkTrainingSkill);
      const docExamVDS = form.data.ViewDataSources?.find(_ => _.ProtectedCollectionType == ProtectedCollectionType.LinkTrainingExam);

      const skillversionsid = docSkillVDS?.Instances[0]?.ChildVersionsID ?? 0;
      let examversionsid = docExamVDS?.Instances[0]?.ChildVersionsID ?? 0;

      //const evaluationTypeField = form.Fields.find(_ => _.ProtectedFieldType == ProtectedFieldType.TrainingEvaluationType); //_.findWhere($scope.ngModel.fields, { ProtectedFieldType: Enums.ProtectedFieldType.TrainingEvaluationType });
      const evaluationTypeField = CollectionFormService.getField(form.data, (f) => {
        return f.ProtectedFieldType == ProtectedFieldType.TrainingEvaluationType;
      });

      if (evaluationTypeField && evaluationTypeField.Value == 1) {
        examversionsid = 0;
      }
      const documentversionsid = 0;
      return ({
        type: formsActions.mapIntegrity.type,
        form: form, documentversionsid,
        skillVersionId: skillversionsid,
        examVersionId: examversionsid,
        trainingVersionId: trainingversionsid,
        documentVersionId: documentversionsid
      });
    })
  ));

  public fetchIntegrityForTrnDocumentCollection$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.fetchIntegrity),
    filter(({ form }) => (form?.data != null && form.data.ProtectedCollectionType == ProtectedCollectionType.TrnDocumentCollection)),
    map(({ form }) => {
      const documentversionsid = form.data.VersionsID;
      const docSkillVDS = form.data.ViewDataSources?.find(_ => _.ProtectedCollectionType == ProtectedCollectionType.LinkDocumentSkill);
      const docExamVDS = form.data.ViewDataSources?.find(_ => _.ProtectedCollectionType == ProtectedCollectionType.LinkDocumentExam);
      const docTrainingVDS = form.data.ViewDataSources?.find(_ => _.ProtectedCollectionType == ProtectedCollectionType.LinkDocumentTraining);

      const skillversionsid = docSkillVDS?.Instances[0]?.ChildVersionsID ?? 0;
      let examversionsid = docExamVDS?.Instances[0]?.ChildVersionsID ?? 0;

      const trainingversionsid = docTrainingVDS?.Instances[0]?.ChildVersionsID ?? 0;

      //const evaluationTypeField = form.Fields.find(_ => _.ProtectedFieldType == ProtectedFieldType.TrainingEvaluationType); //_.findWhere($scope.ngModel.fields, { ProtectedFieldType: Enums.ProtectedFieldType.TrainingEvaluationType });
      const evaluationTypeField = CollectionFormService.getField(form.data, (f) => {
        return f.ProtectedFieldType == ProtectedFieldType.TrainingEvaluationType;
      });

      if (evaluationTypeField && evaluationTypeField.Value == 1) {
        examversionsid = 0;
      }

      return ({
        type: formsActions.mapIntegrity.type,
        form: form, documentversionsid,
        skillVersionId: skillversionsid,
        examVersionId: examversionsid,
        trainingVersionId: trainingversionsid,
        documentVersionId: documentversionsid
      });
    })
  ));

  public resetIntegrity$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getIntegrity),
    map(({ formId }) => formsActions.updateIntegrity({
        formId: formId,
        integrity: undefined
      })
    )
  ));
}