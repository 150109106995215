import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { CollectionListApiService } from 'src/app/api/bizzmine/collection-list/collection-list-api.service';
import { OrganizationChartApiService } from 'src/app/api/bizzmine/organization-chart/organization-chart-api.service';
import { AccessPermissionComponent } from 'src/app/shared/components/ui/role/access-permission/access-permission.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { InheritType } from 'src/models/ts/inherit-type.model';
import { InstancePermissionsDto } from 'src/models/ts/instance-permissions-dto.model';
import { OrgChartItem } from '../../../org-chart/interfaces/org-chart-item';
import { AccessType } from 'src/models/ts/access-type.model';
import { PermissionDto } from 'src/models/ts/permission-dto.model';
import { PermissionsParams } from '../../interfaces/permissions-params.interface';
import { LoaderComponent } from 'src/app/shared/components/ui/loader/loader.component';

@Component({
  selector: 'bizz-permissions-data',
  standalone: true,
  imports: [
    TranslatePipe,
    DropDownsModule,
    AccessPermissionComponent,
    FormsModule,
    LoaderComponent

  ],
  templateUrl: './permissions-data.component.html',
  styleUrl: './permissions-data.component.scss'
})
export class PermissionsDataComponent implements OnInit {
  @Input() public data: PermissionsParams;
  public disabled: boolean = false;
  public currentUserItem: OrgChartItem[] | undefined;
  public isLoading: boolean = false;
  protected readonly InheritType = InheritType;


  public constructor(private collectionListService: CollectionListApiService, private orgChartApiService: OrganizationChartApiService) { }

  public ngOnInit(): void {
    if (this.data.permissions.InheritType == InheritType.InheritFromCollection || this.data.permissions.InheritType == InheritType.InheritFromFolder) {
      this.setProperties();
    }
  }

  public setProperties(togglePermissions: boolean = false): void {
    this.isLoading = true;
    this.disabled = false;
    switch (this.data.permissions.InheritType) {
      case InheritType.CopyFromCollection:
        this.loadCollectionPermissions();
        break;
      case InheritType.InheritFromCollection:
        this.loadCollectionPermissions();
        this.disabled = true;
        break;
      case InheritType.CopyFromFolder:
        this.loadFolderPermissions();
        break;
      case InheritType.InheritFromFolder:
        this.loadFolderPermissions();
        this.disabled = true;

        break;
      case InheritType.Custom:
        if (!togglePermissions) {
          this.orgChartApiService.getOrgChartCurrentuser().pipe(take(1))
            .subscribe({
              next: (data: OrgChartItem): void => {
                this.currentUserItem = [data];
                this.setCustomUsers();
                this.setCustomAccessTypes();
              },
              error: (): void => {
                this.isLoading = false;
              },
              complete: (): void => {
                this.isLoading = false;
              }
            });
        } else {
          this.isLoading = false;
        }
        break;
      default:
        break;
    }

  }

  private loadCollectionPermissions(): void {
    this.collectionListService.collectionPermissions(this.data.collectionId).pipe(take(1)).subscribe({
      next: (data: InstancePermissionsDto): void => {
        this.data.permissions.ForeignID = undefined;
        this.reset(data);
      },
      error: (): void => {
        this.isLoading = false;
      },
      complete: (): void => {
        this.isLoading = false;
      }
    });
  }
  private loadFolderPermissions(): void {
    this.collectionListService.folderPermissions(this.data.folderId).pipe(take(1)).subscribe({
      next: (data: InstancePermissionsDto): void => {
        this.data.permissions.ForeignID = undefined;
        this.reset(data);
      },
      error: (): void => {
        this.isLoading = false;
      },
      complete: (): void => {
        this.isLoading = false;
      }
    });
  }

  private reset(source: InstancePermissionsDto): void {
    this.resetModel(this.data.permissions.CanAccessArchives, source.CanAccessArchives);
    this.resetModel(this.data.permissions.CanAccessHistory, source.CanAccessHistory);
    this.resetModel(this.data.permissions.CanAccessTrackChanges, source.CanAccessTrackChanges);
    this.resetModel(this.data.permissions.CanDeleteToTrashCan, source.CanDeleteToTrashCan);
    this.resetModel(this.data.permissions.CanEditProperties, source.CanEditProperties);
    this.resetModel(this.data.permissions.CanGenerateReport, source.CanGenerateReport);
    this.resetModel(this.data.permissions.CanReadDocPDF, source.CanReadDocPDF);
    this.resetModel(this.data.permissions.CanReadProperties, source.CanReadProperties);
    this.resetModel(this.data.permissions.CanRevisionWithMajorVersionChange, source.CanRevisionWithMajorVersionChange);
    this.resetModel(this.data.permissions.CanRevisionWithMinorVersionChange, source.CanRevisionWithMinorVersionChange);
    this.resetModel(this.data.permissions.CanSetPermissions, source.CanSetPermissions);
    this.resetModel(this.data.permissions.CanViewFlowStatus, source.CanViewFlowStatus);
    this.resetModel(this.data.permissions.CanExecuteOnBehalfOf, source.CanExecuteOnBehalfOf);
    this.resetModel(this.data.permissions.CanForwardTasks, source.CanForwardTasks);
  }

  private resetModel(dest: PermissionDto, source: PermissionDto): void {
    if (dest) {
      dest.AccessType = source.AccessType;
      dest.SelectedOrganizationChartItems = source.SelectedOrganizationChartItems;
      dest.SelectedOrganizationChartItemsDisplay = source.SelectedOrganizationChartItems;
    }
  }

  public setCustomAccessTypes(): void {
    this.data.permissions.ForeignID = undefined;
    this.data.permissions.CanReadProperties.AccessType = AccessType.Nobody;
    this.data.permissions.CanReadDocPDF.AccessType = AccessType.Nobody;
    this.data.permissions.CanEditProperties.AccessType = AccessType.Nobody;
    this.data.permissions.CanRevisionWithMinorVersionChange.AccessType = AccessType.Nobody;
    this.data.permissions.CanRevisionWithMajorVersionChange.AccessType = AccessType.Nobody;
    this.data.permissions.CanAccessHistory.AccessType = AccessType.Nobody;
    this.data.permissions.CanAccessTrackChanges.AccessType = AccessType.Nobody;
    this.data.permissions.CanAccessArchives.AccessType = AccessType.Nobody;
    this.data.permissions.CanDeleteToTrashCan.AccessType = AccessType.Nobody;
    this.data.permissions.CanSetPermissions.AccessType = AccessType.Nobody;
    this.data.permissions.CanExecuteOnBehalfOf.AccessType = AccessType.Nobody;
    this.data.permissions.CanForwardTasks.AccessType = AccessType.Nobody;

  }

  public setCustomUsers(): void {
    if (this.currentUserItem) {
      this.data.permissions.CanReadDocPDF.SelectedOrganizationChartItems = this.currentUserItem;
      this.data.permissions.CanReadProperties.SelectedOrganizationChartItems = this.currentUserItem;
      this.data.permissions.CanEditProperties.SelectedOrganizationChartItems = this.currentUserItem;
      this.data.permissions.CanRevisionWithMinorVersionChange.SelectedOrganizationChartItems = this.currentUserItem;
      this.data.permissions.CanRevisionWithMajorVersionChange.SelectedOrganizationChartItems = this.currentUserItem;
      this.data.permissions.CanAccessHistory.SelectedOrganizationChartItems = this.currentUserItem;
      this.data.permissions.CanAccessTrackChanges.SelectedOrganizationChartItems = this.currentUserItem;
      this.data.permissions.CanAccessArchives.SelectedOrganizationChartItems = this.currentUserItem;
      this.data.permissions.CanDeleteToTrashCan.SelectedOrganizationChartItems = this.currentUserItem;
      this.data.permissions.CanSetPermissions.SelectedOrganizationChartItems = this.currentUserItem;
      this.data.permissions.CanExecuteOnBehalfOf.SelectedOrganizationChartItems = this.currentUserItem;
      this.data.permissions.CanForwardTasks.SelectedOrganizationChartItems = this.currentUserItem;

      this.data.permissions.CanReadDocPDF.SelectedOrganizationChartItemsDisplay = this.currentUserItem;
      this.data.permissions.CanReadProperties.SelectedOrganizationChartItemsDisplay = this.currentUserItem;
      this.data.permissions.CanEditProperties.SelectedOrganizationChartItemsDisplay = this.currentUserItem;
      this.data.permissions.CanRevisionWithMinorVersionChange.SelectedOrganizationChartItemsDisplay = this.currentUserItem;
      this.data.permissions.CanRevisionWithMajorVersionChange.SelectedOrganizationChartItemsDisplay = this.currentUserItem;
      this.data.permissions.CanAccessHistory.SelectedOrganizationChartItemsDisplay = this.currentUserItem;
      this.data.permissions.CanAccessTrackChanges.SelectedOrganizationChartItemsDisplay = this.currentUserItem;
      this.data.permissions.CanAccessArchives.SelectedOrganizationChartItemsDisplay = this.currentUserItem;
      this.data.permissions.CanDeleteToTrashCan.SelectedOrganizationChartItemsDisplay = this.currentUserItem;
      this.data.permissions.CanSetPermissions.SelectedOrganizationChartItemsDisplay = this.currentUserItem;
      this.data.permissions.CanExecuteOnBehalfOf.SelectedOrganizationChartItemsDisplay = this.currentUserItem;
      this.data.permissions.CanForwardTasks.SelectedOrganizationChartItemsDisplay = this.currentUserItem;
    }
  }

}
