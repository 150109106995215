<bizz-modal [headerTemplate]="headerTemplateRef" [contentTemplate]="contentTemplateRef"></bizz-modal>


<ng-template #headerTemplateRef>
    <div class="modal-header">
        <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
            {{ ('TrainingQueue' | translate) + ' ' + ('TrackChanges' | translate) }}</h1>
    </div>
    <hr class="my-5" />
</ng-template>

<ng-template #contentTemplateRef>
    <div class="modal-content">
        <kendo-grid [data]="data">
            <kendo-grid-column field="User" [title]="'User' | translate"> </kendo-grid-column>
            <kendo-grid-column field="HistoryItemType" [title]="'HistoryType' | translate">
                <ng-template kendoGridCellTemplate let-dataItem>
                    @switch(dataItem.HistoryItemType){
                        @case (TrainingAppQueueHistoryType.SubscribedToQueue) {
                            <span>{{"AddedToQueue" | translate}}</span>
                        }
                        @case (TrainingAppQueueHistoryType.UnsubscribedFromQueueByUser) {
                            <span>{{"RemovedFromQueue" | translate}}</span>
                        }
                        @case (TrainingAppQueueHistoryType.UnsubscribedFromQueueByNewDocVersionPublication) {
                            <span>{{"AddedToQueue" | translate}}</span>
                        }
                        @case (TrainingAppQueueHistoryType.RestoredToQueueByUser) {
                            <span>{{"RestoredToQueue" | translate}}</span>
                        }
                        @case (TrainingAppQueueHistoryType.AssignedToTrainingSession) {
                            <span>{{"AssignedToTrainingSession" | translate}}</span>
                        }
                        @case (TrainingAppQueueHistoryType.RemovedFromTrainingSession) {
                            <span>{{"RemovedFromTrainingSession" | translate}}</span>
                        }
                    }
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="SourceType" [title]="'SourceType' | translate">
                <ng-template kendoGridCellTemplate let-dataItem>
                    @if(dataItem.HistoryItemType == TrainingAppQueueHistoryType.SubscribedToQueue){

                        @switch(dataItem.SourceType){
                            @case (TrainingAppSubscriptionSourceType.Manual) {
                                <span><b>{{"Manually" | translate}}
                                    @if(dataItem.IsSigned){
                                    <span>{{"WithElectronicSignature" | translate}}</span>
                                    }
                                </b> {{"ByUser" | translate}} {{dataItem.UpdatedByUserName}} </span>
                            }
                            @case (TrainingAppSubscriptionSourceType.ByRandUYes) {
                                <span><b>{{"ReadAndUnderstood" | translate}}</b> <span class="lowercase">{{"By" |
                                    translate}}</span>{{dataItem.UpdatedByDocumentVersionsName}}</span>
                            }
                            @case (TrainingAppSubscriptionSourceType.ByRandUNo) {
                                <span><b>{{"ReadAndNotUnderstood" | translate}}</b> <span class="lowercase">{{"By" |
                                    translate}}</span>{{dataItem.UpdatedByDocumentVersionsName}}</span>                            }
                        }
                    }
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="RegistrationTime" [title]="'ModifiedOn' | translate">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.RegistrationTime | datetime}}
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
   
</ng-template>