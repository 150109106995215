import { Align } from '@progress/kendo-angular-popup';
import { Component, DestroyRef, ElementRef, HostListener, inject, Input, Signal, ViewChild } from '@angular/core';
import { KendoPopupContains } from '../../../../functions/kendo/kendo-popup-contains';
import { calculateDateStrings } from '../../../../functions/helpers/date-difference';

// Decorator required to use angular decorators in class (@ViewChild, @HostListener)
@Component({
  template: '',
  standalone: true,
  imports: []
})
export abstract class HeaderPopupBase {

  public calculateDateStrings = calculateDateStrings;
  public anchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
  public popupAlign: Align = { horizontal: 'right', vertical: 'top' };
  public showPopup = false;

  @ViewChild('anchor') public anchor: ElementRef;
  @ViewChild('popup', { read: ElementRef }) public popup: ElementRef;

  /**
   * Closes the popup if the user clicks outside the popup or anchor.
   * @param event
   */
  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (this.showPopup && event.target && 
      !this.popup.nativeElement.contains(event.target as Node) && 
      !this.anchor.nativeElement.contains(event.target as Node)) {
      this.togglePopup(false);
    }
  }

  /**
   * Toggles the popup by flipping the value of showPopup.
   * If value is given, sets showPopup to that value.
   * @param value - Optional value to set showPopup to.
   */
  public togglePopup(value?: boolean): void {
    this.showPopup = value != undefined ? value : !this.showPopup;
  }
}
