<bizz-modal [headerTemplate]="headerTemplateRef" [contentTemplate]="contentTemplateRef"
    [footerTemplate]="footerTemplateRef"></bizz-modal>

<ng-template #headerTemplateRef>
    <div class="modal-header">
        <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
            {{'DocumentSettings' | translate}}
        </h1>

        <bizz-stepper [wizard]="false" [scrollable]="true" class="sm: mt-6 block" [currentStep]="activeTab"
            [steps]="steps" (currentStepChange)="setTemplateRef($event)"></bizz-stepper>

    </div>
    <hr class="my-5" />
</ng-template>


<form [formGroup]="propertiesForm" (sumbit)="saveProperties()">

    <ng-template #contentTemplateRef>
        <div class="overflow-y-auto px-4">
            <ng-container [ngTemplateOutlet]="activeTabTemplateRef">
            </ng-container>
        </div>
    </ng-template>



    <ng-template #footerTemplateRef>
        <div class="modal-buttons">
            <button class="default" type="button" (click)="dialogRef.close()">
                {{ 'Cancel' | translate }}
            </button>
            <button class="save min-w-16" type="submit" (click)="saveProperties()">
                <bizz-icon class="size-4" iconName="circle-check"></bizz-icon>
                {{ 'Save' | translate }}
            </button>
        </div>
    </ng-template>

    <ng-template #bookmarksTabTemplateRef>

        <div class="mt-4 flex items-center gap-1.5">
            <bizz-icon class="size-5" iconName="file-word"></bizz-icon>
            <h2 class="font-bold">{{'Bookmarks' | translate}}</h2>
        </div>
        <div class="my-7 rounded bg-widget px-5 py-3 text-sm">
            {{'Info_WordExcelInteraction' | translate}}
        </div>

        <div class="flex gap-3">
            <input type="checkbox" id="enableBookmarks" formControlName="UseWordExcelInteraction" kendoCheckBox>
            <label for="enableBookmarks">{{'EnableWordExcelInteraction' | translate}}</label>
        </div>

        @if(data.properties.HasAiLicense){
        <div class="mt-2 flex gap-3">
            <input type="checkbox" id="enableSearchIndexation" formControlName="EnableSearchIndexation" kendoCheckBox />
            <label for="enableSearchIndexation">{{"EnableSearchIndexation" | translate}}</label>
        </div>
        }

    </ng-template>

    <ng-template #directLinkTabTemplateRef>
        <div class="mt-4 flex items-center gap-1.5">
            <bizz-icon class="size-5" iconName="link-simple"></bizz-icon>
            <h2 class="font-bold">{{'DirectLink' | translate}}</h2>
        </div>
     
        <div class="my-7 rounded bg-widget px-5 py-3 text-sm">
            {{'Info_DirectLink' | translate}}
        </div>
        <div class="mt-2 grid grid-cols-4 items-center gap-3">
            <label>{{'DefaultDirectLinkSetting' | translate}}</label>
            <kendo-dropdownlist class="col-span-3" formControlName="RetrDocAccessType"
                [data]="data.properties.RetrDocAccessTypes" textField="Text" valueField="Value" [valuePrimitive]="true"
                (valueChange)="getDirectLink($event)">
                <ng-template kendoDropDownListItemTemplate let-type>
                    <span>{{ type.Text }}</span>
                </ng-template>
            </kendo-dropdownlist>
        </div>

        @if(propertiesForm.value.RetrDocAccessType !== 0){
        <div class="mt-3 grid grid-cols-4 items-center gap-3">
            <label for="directLink" class="shrink-0">{{'DirectLink' | translate}}</label>
            <div class="input-button-group col-span-3 flex">
                <input type="text" disabled id="directLink" [value]="propertiesForm.value.DirectLink" />
                <div class="button-group">
                    <button type="button" class="default h-10" (click)="copyToClipboard()">
                        <bizz-icon class="h-4 w-4" iconName="clipboard"></bizz-icon>
                    </button>
                </div>
            </div>
        </div>
        }

    </ng-template>


    <ng-template #pdfConverterTabTemplateRef>
        <div class="mt-4 flex items-center gap-1.5">
            <bizz-icon class="size-5" iconName="file-pdf"></bizz-icon>
            <h2 class="font-bold">{{'PDFConverter' | translate}}</h2>
        </div>

        <div class="my-7 rounded bg-widget px-5 py-3 text-sm">
            {{'Info_PDFConverter' | translate}}
        </div>
        <div class="flex gap-3">
            <input type="checkbox" id="enablePdfConverter" formControlName="UsePDFConverter" kendoCheckBox>
            <label for="enablePdfConverter">{{'EnablePDFConverter' | translate}}</label>
        </div>

        @if(propertiesForm.value.UsePDFConverter){
        <div class="mt-5">
            <h3 class="font-bold" for="enablePdfConverter">{{'SecurityOptions' | translate}}</h3>
            @for(item of data.properties.PdfPermissions; track item.Permission){
            <div class="mt-2 flex gap-3">
                <input id="permission_{{item.Permission}}" kendoCheckBox type="checkbox" [checked]="item.Allow"
                    (change)="togglePermission(item)" [disabled]="(item.Permission == PdfPermission.AllowDocumentAssembly && disableAssembly)" />
                <label for="permission_{{item.Permission}}">{{item.PermissionCaption}}</label>
            </div>
            }
        </div>

        <div class="mt-5">
            <h3 class="font-bold">{{'ConversionSettings' | translate}}</h3>
            <div class="mt-2 flex gap-3">
                <input id="checkbox_setpdfacompliant" type="checkbox" formControlName="SetPdfACompliant" kendoCheckBox />
                <label for="checkbox_setpdfacompliant">
                    {{"SetPdfACompliancy" | translate}}
                </label>
            </div>
            <div class="mt-2 flex gap-3">
                <input id="checkbox_useheaderconversion" type="checkbox"
                    formControlName="EnableWordHeaderConversionToPdfBookmarks" kendoCheckBox />
                <label for="checkbox_useheaderconversion">
                    {{"WordHeaderToPdfBookmarks" | translate}}
                </label>
            </div>
        </div>

      

        }

    </ng-template>


    <ng-template #assessmentAndDistributionTabTemplateRef>
        <div class="mt-4 flex items-center gap-1.5">
            <bizz-icon class="size-5" iconName="clipboard-list"></bizz-icon>
            <h2 class="font-bold">{{'AssessmentAndDistribution' | translate}}</h2>
        </div>
      
        <div class="my-7 rounded bg-widget px-5 py-3 text-sm">
            {{'Info_DisplayFieldsInstanceInheritType' | translate}}
        </div>
        

        <div class="grid grid-cols-4 items-center gap-1.5">
            <label>{{'DisplayFieldsInheritType' | translate}}</label>
            <kendo-dropdownlist class="col-span-3" formControlName="DisplayFieldsInheritType" [readonly]="!data.properties.CanEditInheritType || !data.properties.CanSetPermissions"
                [data]="data.properties.DisplayFieldsInheritTypes" textField="Text" valueField="Value"
                [valuePrimitive]="true">
                <ng-template kendoDropDownListItemTemplate let-type>
                    <span>{{ type.Text }}</span>
                </ng-template>
            </kendo-dropdownlist>
        </div>
    </ng-template>
</form>