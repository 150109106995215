export enum FileUploadEditOnlineModalComponentMode {
  View = 1,
  Edit = 2
}

export interface FileUploadEditOnlineModalComponentData {
  mode: FileUploadEditOnlineModalComponentMode;
  docheckoutcheckin: boolean;
  fromGrid?: {
    collectionId: number;
    instanceId: number;
    versionId: number;
  } | undefined;
  mediaId: number;
  filename: string;
  modificationreason: string;
  closeModalMethod: () => void;
  finishMethod: (data: {
    modificationReasonFromOnlineEdit: string,
    openedOneDriveWindow: any | undefined,
    mediasID: number | undefined,
    oneDriveKey: string,
    lastModified: string
  }) => void;
}