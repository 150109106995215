import { AfterContentInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, forwardRef } from '@angular/core';
import { FormAccordionHeaderComponent } from '../form-accordion-header/form-accordion-header.component';
import { IconComponent } from 'src/app/shared/components/ui/icon/icon.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { FormControlComponent } from '../controls/form-control/form-control.component';
import { CollectionForm } from 'src/models/ts/collection-form.model';
import { DatePickerControlComponent } from '../controls/date-picker-control/date-picker-control.component';
import { SchedulerDto } from 'src/models/ts/scheduler-dto.model';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SchedulerEndType } from 'src/models/ts/scheduler-end-type.model';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { SharedDatePickerControlComponent } from 'src/app/shared/components/forms/controls/date-picker-control/date-picker-control.component';
import { SharedFieldFormControlPipe } from 'src/app/shared/components/forms/field-form-control.pipe';
import { SharedAlphaNumericControlComponent } from 'src/app/shared/components/forms/controls/alpha-numeric-control/alpha-numeric-control.component';
import { SharedNumericControlComponent } from 'src/app/shared/components/forms/controls/numeric-control/numeric-control.component';
import { SharedComboboxControlComponent } from 'src/app/shared/components/forms/controls/combobox-control/combobox-control.component';
import { CronGenComponent } from './cron-gen/cron-gen.component';
import { selectByCollectionId } from 'src/app/store/features/collections/collections-selectors';
import { Store } from '@ngrx/store';
import { CollectionStoreState } from 'src/app/store/features/collections/collections-state';

@Component({
  selector: 'bizz-scheduler-panel',
  standalone: true,
  imports: [FormAccordionHeaderComponent,
    IconComponent,
    TranslatePipe,
    FormControlComponent,
    DatePickerControlComponent,
    DatePickerModule,
    ReactiveFormsModule,
    DropDownsModule,
    SharedAlphaNumericControlComponent,
    SharedDatePickerControlComponent,
    SharedNumericControlComponent,
    SharedComboboxControlComponent,
    SharedFieldFormControlPipe,
    CronGenComponent
  ],
  templateUrl: './scheduler-panel.component.html',
  styleUrls: ['./scheduler-panel.component.scss'],
})

export class SchedulerPanelComponent implements OnInit {
  @Input({ required: true }) public formData: CollectionForm;
  @Input({ required: true }) public schedulerData: SchedulerDto;

  @Output() public schedulerDataChanged: EventEmitter<SchedulerDto> = new EventEmitter<SchedulerDto>();
  public schedulerFormGroup: FormGroup;
  public endType: SchedulerEndType = SchedulerEndType.NoEndDate;
  
  protected readonly EnumSchedulerEndType = SchedulerEndType;

  //TODO: Fix translation of this
  public endTypes: any[];

  public constructor(private store$: Store) {
  }

  public ngOnInit(): void {
    this.store$.select(selectByCollectionId(this.schedulerData.WidgetsID)).subscribe((res: any) => {
      if(res){
        this.endTypes = res.data.EndTypeOptions as any;
      }
    })
    this.schedulerFormGroup = new FormGroup({
      Name: new FormControl(this.schedulerData.Name),
      StartDate: new FormControl(this.getDateValue(this.schedulerData.StartDate)),
      EndType: new FormControl(this.schedulerData.EndType),
      EndDate: new FormControl(this.getDateValue(this.schedulerData.EndDate)),
      LastRun: new FormControl(this.schedulerData.LastRun),
      EndAmount: new FormControl(this.schedulerData.EndAmount),
    });

    this.schedulerFormGroup.valueChanges.subscribe(() => {
      this.emitChanges(this.schedulerData.CronExpression);
    })
  }
  public getDateValue(dateString: string): Date {
    return new Date(dateString);
  }

  public setCronExpression(cronExpression: string): void {
    this.emitChanges(cronExpression);
  }

  public emitChanges(cronExpression: string): void {
    const schedulerData = { ...this.schedulerData, ...this.schedulerFormGroup.value };
    schedulerData.CronExpression = cronExpression;
    this.schedulerDataChanged.emit(schedulerData);
  }


}
