<div class="relative">
  <!-- <bizz-loader [showLoader]="isLoading" [fullscreen]="false" backgroundClasses="bg-transparent"></bizz-loader> -->

  <bizz-modal modalSize="md" [headerTemplate]="headerTemplateRef"
    [contentTemplate]="activeContentTemplateRef"></bizz-modal>
  <ng-template #headerTemplateRef>
    <div class="modal-header">
      <h1>
        {{ 'Archive' | translate }}
      </h1>
      <p>{{ 'Info_VersionHistory' | translate }}</p>
    </div>
    <hr class="my-4" />
  </ng-template>

  <ng-template #activeContentTemplateRef>
    <div class="flex max-h-[80%] w-full flex-col overflow-y-auto">
      @if(isLoading){
      <div class="flex flex-col gap-8">
        @for(i of [].constructor(3); track i) {
        <div class="flex w-full">
          <div class="w-20"></div>
          <div class="w-32">
            <kendo-skeleton shape="rectangle" animation="pulse" width="48px" height="48px"
              class="m-auto rounded pr-4"></kendo-skeleton>
          </div>
          <div class="flex grow items-center justify-between px-3.5">
            <div class="flex gap-4">
              <kendo-skeleton shape="rectangle" animation="pulse" width="48px" height="48px"
                class="rounded"></kendo-skeleton>
              <div class="w-32">
                <kendo-skeleton shape="text" animation="pulse" width="100%"></kendo-skeleton>
                <kendo-skeleton shape="text" animation="pulse" width="100%"></kendo-skeleton>
              </div>
            </div>
            <kendo-skeleton shape="rectangle" animation="pulse" width="10px" height="10px"
              class="rounded"></kendo-skeleton>
          </div>
        </div>
        }
      </div>
      } @else if (model.Data.length > 0) {
      @for (item of model.Data; track item.File; let last = $last) {
      <bizz-archive-item class="flex min-w-full" [item]="item" [isLastItem]="last" [permissions]="permissions"
        [dataItem]="dataItem" (archiveContextMenuAnchorClick)="onArchiveItemAnchorClicked($event)">
      </bizz-archive-item>
      }
      }
    </div>
  </ng-template>
</div>

<bizz-archive-context-menu #contextMenu></bizz-archive-context-menu>