import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, createPlatform } from '@angular/core';
import { CronGenService, SelectOptions } from '../../../services/cron-gen.service';
import { CronMinutesComponent } from 'src/app/shared/components/cron/cron-minutes/cron-minutes.component';
import { CronHourlyComponent } from 'src/app/shared/components/cron/cron-hourly/cron-hourly.component';
import { CronDailyComponent } from 'src/app/shared/components/cron/cron-daily/cron-daily.component';
import { CronWeeklyComponent } from 'src/app/shared/components/cron/cron-weekly/cron-weekly.component';
import { CronMonthlyComponent } from 'src/app/shared/components/cron/cron-monthly/cron-monthly.component';
import { CronYearlyComponent } from 'src/app/shared/components/cron/cron-yearly/cron-yearly.component';
import { CronAdvancedComponent } from 'src/app/shared/components/cron/cron-advanced/cron-advanced.component';
import { NgTemplateOutlet } from '@angular/common';
import { StepperComponent } from 'src/app/shared/components/ui/stepper/stepper.component';
import { StepperStep } from 'src/app/shared/components/ui/stepper/interfaces/stepper-step';
import { Store } from '@ngrx/store';
import { userSettingsFeature } from 'src/app/store/features/user-settings/user-settings-feature';
import moment from 'moment';
import { CronGenState } from '../../../interfaces/crongen/cron-gen-state';
import { CronGenClass } from '../../../classes/cron-gen-state';
import { CronGenWeekly } from '../../../interfaces/crongen/cron-gen-weekly';

export enum States {
  INIT = 1,
  DIRTY = 2,
  CLEAN = 3
};

export enum CronTabs {
  MINUTES = 0,
  HOURLY = 1,
  DAILY = 2,
  WEEKLY = 3,
  MONTHLY = 4,
  YEARLY = 5,
  ADVANCED = 6
};

@Component({
  selector: 'bizz-cron-gen',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    CronMinutesComponent,
    CronHourlyComponent,
    CronDailyComponent,
    CronWeeklyComponent,
    CronMonthlyComponent,
    CronYearlyComponent,
    CronAdvancedComponent,
    StepperComponent
  ],
  templateUrl: './cron-gen.component.html',
  styleUrls: ['./cron-gen.component.scss'],
})

export class CronGenComponent implements OnInit {
  @Input() public cronExpression: string;

  @Output() public updateCron = new EventEmitter<string>();
  @ViewChild('minutesTabTemplateRef', { static: true }) private minutesTabTemplateRef: TemplateRef<any>;
  @ViewChild('hourlyTabTemplateRef', { static: true }) private hourlyTabTemplateRef: TemplateRef<any>;
  @ViewChild('dailyTabTemplateRef', { static: true }) private dailyTabTemplateRef: TemplateRef<any>;
  @ViewChild('weeklyTabTemplateRef', { static: true }) private weeklyTabTemplateRef: TemplateRef<any>;
  @ViewChild('monthlyTabTemplateRef', { static: true }) private monthlyTabTemplateRef: TemplateRef<any>;
  @ViewChild('yearlyTabTemplateRef', { static: true }) private yearlyTabTemplateRef: TemplateRef<any>;
  @ViewChild('advancedTabTemplateRef', { static: true }) private advancedTabTemplateRef: TemplateRef<any>;
  public activeTabTemplateRef: TemplateRef<any>;

  public steps: StepperStep[] = [{
    label: 'Minutes', disabled: false, showLabelOnMobile: true,
  }, {
    label: 'Hourly', disabled: false, showLabelOnMobile: true,
  }, {
    label: 'Daily', disabled: false, showLabelOnMobile: true,
  }, {
    label: 'Weekly', disabled: false, showLabelOnMobile: true,
  }, {
    label: 'Monthly', disabled: false, showLabelOnMobile: true,
  }, {
    label: 'Yearly', disabled: false, showLabelOnMobile: true,
  }, {
    label: 'Advanced', disabled: false, showLabelOnMobile: true,
  }
  ];

  public parsedOptions = {
    hideMinutesTab: false,
    hideHourlyTab: false,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: false,
    hideAdvancedTab: true,
    use24HourTime: false,
    hideSeconds: false
  }
  public currentState: States = States.INIT;

  public state: CronGenState;
  public selectOptions: SelectOptions = this.cronGenService.selectOptions();
  public activeTab: CronTabs;

  public constructor(private cronGenService: CronGenService, private store$: Store) {
    this.parsedOptions.use24HourTime = this.store$.selectSignal(
      userSettingsFeature.selectTimeFormatTypeEnum
    )() == 2;
  }

  public ngOnInit(): void {
    
    this.state = new CronGenClass(this.parsedOptions.use24HourTime);

    this.handleModelChange(this.cronExpression);
    this.regenerateCron();

  }

  public toggleTemplateRef(step: number): void {
    this.activeTab = CronTabs[CronTabs[step] as keyof typeof CronTabs];
    this.regenerateCron();
  }


  public regenerateCron() {
    this.currentState = States.DIRTY;
    switch (this.activeTab) {
      case CronTabs.MINUTES:
        this.activeTabTemplateRef = this.minutesTabTemplateRef;
        this.cronExpression = this.state.minutes.seconds + ' 0/' + this.state.minutes.minutes + ' * 1/1 * ? *';
        break;
      case CronTabs.HOURLY:
        this.activeTabTemplateRef = this.hourlyTabTemplateRef;
        this.cronExpression = this.state.hourly.seconds + ' ' + this.state.hourly.minutes + ' 0/' + this.state.hourly.hours + ' 1/1 * ? *';
        break;
      case CronTabs.DAILY:
        this.activeTabTemplateRef = this.dailyTabTemplateRef;
        switch (this.state.daily.subTab) {
          case 'everyDays':
            this.cronExpression = this.state.daily.everyDays.seconds + ' ' + this.state.daily.everyDays.minutes + ' ' + this.hourToCron(this.state.daily.everyDays.hours, this.state.daily.everyDays.hourType) + ' 1/' + this.state.daily.everyDays.days + ' * ? *';
            break;
          case 'everyWeekDay':
            this.cronExpression = this.state.daily.everyWeekDay.seconds + ' ' + this.state.daily.everyWeekDay.minutes + ' ' + this.hourToCron(this.state.daily.everyWeekDay.hours, this.state.daily.everyWeekDay.hourType) + ' ? * MON-FRI *';
            break;
          default:
            throw 'Invalid cron daily subtab selection';
        }
        break;
      case CronTabs.WEEKLY:
        this.activeTabTemplateRef = this.weeklyTabTemplateRef;
        let days = this.selectOptions.days.reduce((acc: any, day: any) => {

          return this.state.weekly[day as keyof CronGenWeekly] ? acc.concat([day]) : acc;
        }, []).join(',');
        this.cronExpression = this.state.weekly.seconds + ' ' + this.state.weekly.minutes + ' ' + this.hourToCron(this.state.weekly.hours, this.state.weekly.hourType) + ' ? * ' + days + ' *';
        break;
      case CronTabs.MONTHLY:
        this.activeTabTemplateRef = this.monthlyTabTemplateRef;
        switch (this.state.monthly.subTab) {
          case 'specificDay':
            this.cronExpression = this.state.monthly.specificDay.seconds + ' ' + this.state.monthly.specificDay.minutes + ' ' + this.hourToCron(this.state.monthly.specificDay.hours, this.state.monthly.specificDay.hourType) + ' ' + this.state.monthly.specificDay.day + ' 1/' + this.state.monthly.specificDay.months + ' ? *';
            break;
          case 'specificWeekDay':
            this.cronExpression = this.state.monthly.specificWeekDay.seconds + ' ' + this.state.monthly.specificWeekDay.minutes + ' ' + this.hourToCron(this.state.monthly.specificWeekDay.hours, this.state.monthly.specificWeekDay.hourType) + ' ? 1/' + this.state.monthly.specificWeekDay.months + ' ' + this.state.monthly.specificWeekDay.day + this.state.monthly.specificWeekDay.monthWeek + ' *';
            break;
          default:
            throw 'Invalid cron monthly subtab selection';
        }
        break;
      case CronTabs.YEARLY:
        this.activeTabTemplateRef = this.yearlyTabTemplateRef;
        switch (this.state.yearly.subTab) {
          case 'specificMonthDay':
            this.cronExpression = this.state.yearly.specificMonthDay.seconds + ' ' + this.state.yearly.specificMonthDay.minutes + ' ' + this.hourToCron(this.state.yearly.specificMonthDay.hours, this.state.yearly.specificMonthDay.hourType) + ' ' + this.state.yearly.specificMonthDay.day + ' ' + this.state.yearly.specificMonthDay.month + ' ? *';
            break;
          case 'specificMonthWeek':
            this.cronExpression = this.state.yearly.specificMonthWeek.seconds + ' ' + this.state.yearly.specificMonthWeek.minutes + ' ' + this.hourToCron(this.state.yearly.specificMonthWeek.hours, this.state.yearly.specificMonthWeek.hourType) + ' ? ' + this.state.yearly.specificMonthWeek.month + ' ' + this.state.yearly.specificMonthWeek.day + this.state.yearly.specificMonthWeek.monthWeek + ' *';
            break;
          default:
            throw 'Invalid cron yearly subtab selection';
        }
        break;
      case CronTabs.ADVANCED:
        this.activeTabTemplateRef = this.advancedTabTemplateRef;
        this.cronExpression = this.state.advanced.expression;
        break;
      default:
        throw 'Invalid cron active tab selection';
    }
    if (this.cronExpression) {
      this.updateCron.emit(this.cronExpression);
    }
  }



  private handleModelChange(cron: string) : void{

    if (this.currentState === States.DIRTY) {
      this.currentState = States.CLEAN;
      return;
    } else {
      this.currentState = States.CLEAN;
    }

    const segments = cron.split(' ');
    if (segments.length === 6 || segments.length === 7) {
      let _segments: any = segments.slice(0, 6);
      let seconds = _segments[0];
      let minutes = _segments[1];
      let hours = _segments[2];
      let dayOfMonth = _segments[3];
      let month = _segments[4];
      let dayOfWeek = _segments[5];

      if (cron.match(/\d+ 0\/\d+ \* 1\/1 \* \? \*/)) {
        this.activeTab = CronTabs.MINUTES;
        this.state.minutes.minutes = parseInt(minutes.substring(2));
        this.state.minutes.seconds = parseInt(seconds);
      } else if (cron.match(/\d+ \d+ 0\/\d+ 1\/1 \* \? \*/)) {
        this.activeTab= CronTabs.HOURLY;
        this.state.hourly.hours = parseInt(hours.substring(2));
        this.state.hourly.minutes = parseInt(minutes);
        this.state.hourly.seconds = parseInt(seconds);
      } else if (cron.match(/\d+ \d+ \d+ 1\/\d+ \* \? \*/)) {
        this.activeTab= CronTabs.DAILY;
        this.state.daily.subTab = 'everyDays';
        this.state.daily.everyDays.days = parseInt(dayOfMonth.substring(2));
        let parsedHours = parseInt(hours);
        this.state.daily.everyDays.hours = this.processHour(parsedHours);
        this.state.daily.everyDays.hourType = this.getHourType(parsedHours);
        this.state.daily.everyDays.minutes = parseInt(minutes);
        this.state.daily.everyDays.seconds = parseInt(seconds);
        this.state.daily.everyDays.datetime = moment().hours(parsedHours).minutes(parseInt(minutes)).seconds(parseInt(seconds)).toDate();
      } else if (cron.match(/\d+ \d+ \d+ \? \* MON-FRI \*/)) {
        this.activeTab= CronTabs.DAILY;
        this.state.daily.subTab = 'everyWeekDay';
        let _parsedHours = parseInt(hours);
        this.state.daily.everyWeekDay.hours = this.processHour(_parsedHours);
        this.state.daily.everyWeekDay.hourType = this.getHourType(_parsedHours);
        this.state.daily.everyWeekDay.minutes = parseInt(minutes);
        this.state.daily.everyWeekDay.seconds = parseInt(seconds);
        this.state.daily.everyWeekDay.datetime = moment().hours(_parsedHours).minutes(parseInt(minutes)).seconds(parseInt(seconds)).toDate();

      } else if (cron.match(/\d+ \d+ \d+ \? \* (MON|TUE|WED|THU|FRI|SAT|SUN)(,(MON|TUE|WED|THU|FRI|SAT|SUN))* \*/)) {
        this.activeTab= CronTabs.WEEKLY;
        this.selectOptions.days.forEach((weekDay: string)=>  {
          this.state.weekly[weekDay as keyof CronGenWeekly] = false as never;
        });
        dayOfWeek.split(',').forEach((weekDay: string)=> {
          this.state.weekly[weekDay as keyof CronGenWeekly] = true as never;
        });
        let _parsedHours2 = parseInt(hours);
        this.state.weekly.hours = this.processHour(_parsedHours2);
        this.state.weekly.hourType = this.getHourType(_parsedHours2);
        this.state.weekly.minutes = parseInt(minutes);
        this.state.weekly.seconds = parseInt(seconds);
        this.state.weekly.datetime = moment().hours(_parsedHours2).minutes(parseInt(minutes)).seconds(parseInt(seconds)).toDate();

      } else if (cron.match(/\d+ \d+ \d+ (\d+|L|LW|1W) 1\/\d+ \? \*/)) {
        this.activeTab= CronTabs.MONTHLY;
        this.state.monthly.subTab = 'specificDay';
        this.state.monthly.specificDay.day = dayOfMonth;
        this.state.monthly.specificDay.months = parseInt(month.substring(2));
        let _parsedHours3 = parseInt(hours);
        this.state.monthly.specificDay.hours = this.processHour(_parsedHours3);
        this.state.monthly.specificDay.hourType = this.getHourType(_parsedHours3);
        this.state.monthly.specificDay.minutes = parseInt(minutes);
        this.state.monthly.specificDay.seconds = parseInt(seconds);
        this.state.monthly.specificDay.datetime = moment().hours(_parsedHours3).minutes(parseInt(minutes)).seconds(parseInt(seconds)).toDate();
      } else if (cron.match(/\d+ \d+ \d+ \? 1\/\d+ (MON|TUE|WED|THU|FRI|SAT|SUN)((#[1-5])|L) \*/)) {
        let day = dayOfWeek.substr(0, 3);
        let monthWeek = dayOfWeek.substr(3);
        this.activeTab= CronTabs.MONTHLY;
        this.state.monthly.subTab = 'specificWeekDay';
        this.state.monthly.specificWeekDay.monthWeek = monthWeek;
        this.state.monthly.specificWeekDay.day = day;
        this.state.monthly.specificWeekDay.months = parseInt(month.substring(2));
        let _parsedHours4 = parseInt(hours);
        this.state.monthly.specificWeekDay.hours = this.processHour(_parsedHours4);
        this.state.monthly.specificWeekDay.hourType = this.getHourType(_parsedHours4);
        this.state.monthly.specificWeekDay.minutes = parseInt(minutes);
        this.state.monthly.specificWeekDay.seconds = parseInt(seconds);
        this.state.monthly.specificWeekDay.datetime = moment().hours(_parsedHours4).minutes(parseInt(minutes)).seconds(parseInt(seconds)).toDate();
      } else if (cron.match(/\d+ \d+ \d+ (\d+|L|LW|1W) \d+ \? \*/)) {
        this.activeTab= CronTabs.YEARLY;
        this.state.yearly.subTab = 'specificMonthDay';
        this.state.yearly.specificMonthDay.month = parseInt(month);
        this.state.yearly.specificMonthDay.day = dayOfMonth;
        let _parsedHours5 = parseInt(hours);
        this.state.yearly.specificMonthDay.hours = this.processHour(_parsedHours5);
        this.state.yearly.specificMonthDay.hourType = this.getHourType(_parsedHours5);
        this.state.yearly.specificMonthDay.minutes = parseInt(minutes);
        this.state.yearly.specificMonthDay.seconds = parseInt(seconds);
        this.state.yearly.specificMonthDay.datetime = moment().hours(_parsedHours5).minutes(parseInt(minutes)).seconds(parseInt(seconds)).toDate();
      } else if (cron.match(/\d+ \d+ \d+ \? \d+ (MON|TUE|WED|THU|FRI|SAT|SUN)((#[1-5])|L) \*/)) {
        let _day = dayOfWeek.substr(0, 3);
        let _monthWeek = dayOfWeek.substr(3);
        this.activeTab= CronTabs.YEARLY;
        this.state.yearly.subTab = 'specificMonthWeek';
        this.state.yearly.specificMonthWeek.monthWeek = _monthWeek;
        this.state.yearly.specificMonthWeek.day = _day;
        this.state.yearly.specificMonthWeek.month = parseInt(month);
        let _parsedHours6 = parseInt(hours);
        this.state.yearly.specificMonthWeek.hours = this.processHour(_parsedHours6);
        this.state.yearly.specificMonthWeek.hourType = this.getHourType(_parsedHours6);
        this.state.yearly.specificMonthWeek.minutes = parseInt(minutes);
        this.state.yearly.specificMonthWeek.seconds = parseInt(seconds);
        this.state.yearly.specificMonthWeek.datetime = moment().hours(_parsedHours6).minutes(parseInt(minutes)).seconds(parseInt(seconds)).toDate();

      } else {
        this.activeTab= CronTabs.ADVANCED;
        this.state.advanced.expression = cron;
      }
    } else {
      throw 'Unsupported cron expression. Expression must be 6 or 7 segments';
    }
  }

  //-------------------------------------------------------- HELPERS
  private hourToCron(hour: number, hourType: 'AM' | 'PM' | null): number {
    if (this.parsedOptions.use24HourTime) {
      return hour;
    } else {
      return hourType === 'AM' ? hour === 12 ? 0 : hour : hour === 12 ? 12 : hour + 12;
    }
    
  }
  private processHour(hours: number): number {
    if (this.parsedOptions.use24HourTime) {
      return hours;
    } else {
      return (hours + 11) % 12 + 1;
    }
  }

  private getHourType(hours: number): 'AM' | 'PM' | null{
    return this.parsedOptions.use24HourTime ? null : hours >= 12 ? 'PM' : 'AM';
  }

}