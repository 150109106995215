import { AfterViewInit, Component, EventEmitter, Input, Output, Type, ViewChild } from '@angular/core';
import { GridColumnType } from '../../../../../../models/ts/grid-column-type.model';
import { CellActionType } from '../../../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';
import { CellActionData } from '../../../../../features/bizzmine/widgets/collection-list-widget/interfaces/cell-action-data';
import { GridColumnBase } from '../../../../classes/list/grid-column-base';
import { CollectionListDataInstanceProperties } from '../../../../interfaces/collection-list-data-instance';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { GRID_CELL_COMPONENT_TYPES } from '../../constants/grid-cell-component-types';
import { ViewContainerRefDirective } from '../../../../directives/view-container-ref.directive';
import { createViewContainerComponent } from '../../../../functions/helpers/view-container-helpers';
import { GridContextPermissionsMetadata } from '../../../../../features/bizzmine/widgets/collection-list-widget/interfaces/grid-context-permissions-metadata';
import { TaskStateType } from '../../../../../../models/ts/task-state-type.model';

/**
 * Container cell class containing the specific cell class
 * depending on the data being displayed.
 */
@Component({
  selector: 'bizz-grid-cell',
  templateUrl: './grid-cell.component.html',
  styleUrls: ['./grid-cell.component.scss'],
  //TODO: investigate this: https://blog.angular-university.io/onpush-change-detection-how-it-works/
  //Might have issues when inline editing
  //changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ViewContainerRefDirective
  ]
})
export class GridCellComponent implements AfterViewInit {

  @Input({ required: true }) public gridColumn: GridColumnBase;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input({ required: true })
  public set data(items: CollectionListDataInstanceProperties & { [key: string]: any; } | any) {
    this._data = items;
    if (this.currentComponentType) {
      this.setInstanceData(this.currentComponentType);
      this.currentComponentType.changeDetectorRef.detectChanges();
    }
  }

  public get data(): CollectionListDataInstanceProperties & { [key: string]: any; } | any {
    return this._data;
  }

  private _data: CollectionListDataInstanceProperties & { [key: string]: any; } | any;
  @Input() public metaData: GridContextPermissionsMetadata;
  @Output() public onCellAction = new EventEmitter<{ action: CellActionType, data: CellActionData }>();

  @ViewChild(ViewContainerRefDirective, { static: true })
  public componentHost: ViewContainerRefDirective;


  public ngAfterViewInit(): void {
    if (this.componentHost && this.gridColumn && this.data) {
      this.loadComponent();
    } else console.error('Could not load cell component');
  }

  public currentComponentType: GridCellBaseComponent<unknown>;

  public loadComponent(): void {
    const componentType = this.resolveGridCellComponent(this.gridColumn.GridColumnType);
    if (!componentType)
      throw new Error(`No component found for column type ${this.gridColumn.GridColumnType}`);

    const componentRef = createViewContainerComponent<GridCellBaseComponent<unknown>>
    (this.componentHost.viewContainerRef, componentType);
    this.setInstanceData(componentRef.instance);
    this.currentComponentType = componentRef.instance;
  }

  protected setInstanceData(component: GridCellBaseComponent<unknown>): void {
    component.value = this.getFieldValue(this.gridColumn.GridColumnType);
    component.column = this.gridColumn;
    component.data = this.data;
    component.metaData = this.metaData;
    component.onCellAction = this.onCellAction;
  }

  protected resolveGridCellComponent(columnType: GridColumnType): Type<GridCellBaseComponent<unknown>> {
    return GRID_CELL_COMPONENT_TYPES[columnType];
  }

  public getFieldValue(columnType: GridColumnType): unknown {
    switch (columnType) {
      case(GridColumnType.Status):
        return this.data.InstanceStatusType;
      case(GridColumnType.TaskStatus):
        return this.data[this.gridColumn.field] as TaskStateType;
      default:
        return this.data[this.gridColumn.field];
    }
  }
}

export interface DynamicCellComponent<T> extends Component {
  column: GridColumnBase;
  data: CollectionListDataInstanceProperties & { [key: string]: T; };
  value: T | undefined;
}
