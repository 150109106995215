import { Component } from '@angular/core';
import { IconComponent } from '../../ui/icon/icon.component';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { TranslatePipe } from '../../../pipes/translate/translate.pipe';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'bizz-unsaved-changes-modal',
  standalone: true,
  imports: [
    IconComponent,
    TextAreaModule,
    TranslatePipe
  ],
  templateUrl: './unsaved-changes-modal.component.html',
  styleUrl: './unsaved-changes-modal.component.scss'
})
export class UnsavedChangesModalComponent {

  public constructor(public dialogRef: DialogRef<boolean>) {}
}
