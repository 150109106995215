import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { formsActions } from '../../../store/features/forms/forms-actions';
import { TaskApiService } from '../../../api/bizzmine/task/task-api.service';
import { Dialog } from '@angular/cdk/dialog';
import { Store } from '@ngrx/store';
import { TaskForwardDialogComponent } from '../../../features/bizzmine/widgets/task-list-widget/task-forward-dialog/task-forward-dialog.component';
import { TaskType } from '../../../../models/ts/task-type.model';
import { Router } from '@angular/router';
import { TaskRevisionDialogComponent } from '../../../features/bizzmine/widgets/task-list-widget/task-revision-dialog/task-revision-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  public constructor(private taskApiService: TaskApiService, private dialog: Dialog, private store$: Store,
                     private route: Router) {
  }

  public executeTask(data: {
    collectionId: number;
    instanceId: number;
    versionId: number;
    taskId: number;
    draftId: number | undefined;
    prioTaskId? : boolean;
  }): void {
    if (!data) return;
    if(data.taskId){
      this.taskApiService.getTaskInfo(data.taskId)
        .pipe(take(1))
        .subscribe(found => {
          if (found.TaskType == TaskType.TrainingAppExaminationTask) {
            this.route.navigate([`./trn/${found.TrainingAppID}/exam/${found.VersionsID}`])
              .then();
          } else if (found.TaskType == TaskType.RevisionTaskRestart || found.TaskType == TaskType.RevisionFlow) {

            this.taskApiService.getRevisionTask(data.taskId)
              .pipe(take(1))
              .subscribe({
                next: (found) => {
                  this.dialog.open(TaskRevisionDialogComponent, {
                    data: {
                      task: found.Tasks[0]
                    }
                  });
                }
              });
          } else {
            this.checkInstances(data);
          }
        });
    } else {
      this.checkInstances(data);
    }
  }

  public forwardTask(taskId: number, collectionId: number): void {
    this.dialog.open(TaskForwardDialogComponent, {
      data: {
        taskId: taskId,
        collectionId: collectionId
      }
    });
  }

  public checkInstances(data: {
    collectionId: number;
    instanceId: number;
    versionId: number;
    taskId: number;
    draftId: number | undefined;
    retryCount? : number | undefined;
    prioTaskId? : boolean;
  }): void {
    if (data) {
      // both could be filled taskId And Collection-Version-Instance Ids -> prioTaskId enables us to decide which one to use if both are filled
      if ((data.prioTaskId != true || data.taskId == null || data.taskId <= 0) && data.collectionId && data.instanceId && data.versionId) {
        this.store$.dispatch(formsActions.getPendingSteps(
          {
            collectionId: data.collectionId,
            instanceId: data.instanceId,
            versionId: data.versionId,
            draftId: data.draftId,
            retryCount: data.retryCount
          }
        ));
      } else if (data.taskId) {
        this.store$.dispatch(formsActions.getTaskForm(
          {
            taskId: data.taskId
          }));
      }
    }

  }
}
