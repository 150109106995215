<div class="modal-container">

    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>

    <div class="modal-body w-full">
        <div class="modal-header">
            <h1>{{ "PendingTasks" | translate }}</h1>
        </div>

        <div class="gutter-stable-both flex flex-col gap-4 overflow-auto scroll-smooth px-2">
        @if (myTasks.length > 0) {
            <div class="flex items-center justify-between">
                <div class="mb-2 text-base font-bold text-blue-500">{{ "TasksAssignedToYou" | translate }}</div>
                <bizz-icon [iconName]="showMyTasks ? 'chevron-down' : 'chevron-up'" (click)="showMyTasks = !showMyTasks"
                           class="mr-2 flex size-3.5 cursor-pointer flex-col items-center justify-center"></bizz-icon>
            </div>

            @if (showMyTasks) {
                <div class="flex flex-col">
                @for (taskGroup of myTasks; track $index) {
                    <div class="text-s grid grid-cols-1 gap-2 bg-section p-2 sm:grid-cols-3">
                        <div class="flex gap-2">
                            <div class="font-bold">
                                {{ "Step" | translate }}:
                            </div>
                            <div>{{ taskGroup.StepsName }}</div>
                        </div>
                        <div class="flex gap-2">
                            <div class="font-bold">
                                {{ "Subject" | translate }}:
                            </div>
                            <div>{{ taskGroup.Subject }}</div>
                        </div>
                        <div class="flex gap-2">
                            <div class="font-bold">
                                {{ "DueDate" | translate }}:
                            </div>
                            <div>{{ taskGroup.DueDate  ? (taskGroup.DueDate | datetime) : '-'}}</div>
                        </div>
                    </div>
                    <div class="flex flex-col gap-2">
                        <div class="flex flex-col text-sm">
                            @for (task of taskGroup.Tasks; track $index) {
                                <div class="flex items-center justify-between border-b border-list-row bg-list-row even:bg-list-row-alternate">
                                    <div class="mx-2">{{ task.AssignedTo }}</div>
                                    <button class="default m-2 mr-10"
                                            (click)="executeTask(task)"><span
                                            class="text-sm text-blue-500">{{ "Execute" | translate }}</span>
                                    </button>
                                </div>

                            }
                        </div>

                    </div>
                }

                </div>
            }
        }


            @if (foundTasks.length > 0) {
                <div class="flex items-center justify-between">
                    <div class="mb-2 text-base font-bold text-blue-500">{{ "TasksAssignedToOtherUsers" | translate }}</div>
                    <bizz-icon [iconName]="showFoundTasks ? 'chevron-down' : 'chevron-up'" (click)="showFoundTasks = !showFoundTasks"
                               class="mr-2 flex size-3.5 cursor-pointer flex-col items-center justify-center"></bizz-icon>
                </div>

                @if (showFoundTasks) {
                    <div class="flex flex-col">
                    @for (taskGroup of foundTasks; track $index) {
                        <div class="text-s grid grid-cols-1 gap-2 bg-section p-2 sm:grid-cols-3">
                            <div class="flex gap-2">
                                <div class="font-bold">
                                    {{ "Step" | translate }}:
                                </div>
                                <div>{{ taskGroup.StepsName }}</div>
                            </div>
                            <div class="flex gap-2">
                                <div class="font-bold">
                                    {{ "Subject" | translate }}:
                                </div>
                                <div>{{ taskGroup.Subject }}</div>
                            </div>
                            <div class="flex gap-2">
                                <div class="font-bold">
                                    {{ "DueDate" | translate }}:
                                </div>
                                <div>{{ taskGroup.DueDate  ? (taskGroup.DueDate | datetime) : '-'}}</div>
                            </div>
                        </div>
                        <div class="flex flex-col gap-2">
                            <div class="flex flex-col text-sm">
                                @for (task of taskGroup.Tasks; track $index) {
                                    <div class="flex items-center justify-between border-b border-list-row bg-list-row even:bg-list-row-alternate">
                                        <div class="mx-2">{{ task.AssignedTo }}</div>
                                        <button class="default m-2 mr-10"
                                                (click)="executeTask(task)"><span
                                                class="text-sm text-blue-500">{{ "Execute" | translate }}</span>
                                        </button>
                                    </div>

                                }
                            </div>

                        </div>
                    }
                    </div>
                }
            }
    </div>


    </div>


    <div class="modal-buttons">
        <div class="flex flex-row justify-end gap-3 text-center">
            <button class="default" (click)="close()">
                {{ "Cancel" | translate }}
            </button>
        </div>

    </div>
</div>
