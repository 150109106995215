import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { headerRemindersActions } from './header-reminders-actions';
import { exhaustMap, forkJoin, map, timer } from 'rxjs';
import { ReminderApiService } from '../../../api/bizzmine/reminder/reminder-api.service';
import { concatLatestFrom } from '@ngrx/operators';
import { headerRemindersFeature } from './header-reminders-feature';
import { Store } from '@ngrx/store';

@Injectable()
export class HeaderRemindersEffects {
  private store$ = inject(Store);
  public fetchReminders$ = createEffect(() => this.actions$.pipe(
    ofType(headerRemindersActions.fetchReminders),
    concatLatestFrom(() => this.store$.select(headerRemindersFeature.selectSearch)),
    exhaustMap(([action, search]) => forkJoin(
      { minimumTime: timer(300), request: this.reminderApiService.getPopupReminders(search) }
    )),
    map(({ request }) => ({ type: headerRemindersActions.remindersFetched.type, reminders: request }))
  ));

  public fetchAfterSearchUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerRemindersActions.updateSearch),
      map(({ search }) => ({
          type: headerRemindersActions.fetchReminders.type
        })
      )
    )
  );

  public readReminders$ = createEffect(() => this.actions$.pipe(
    ofType(headerRemindersActions.readReminders),
    exhaustMap(({ ids }) => this.reminderApiService.readReminders(ids).pipe(
      map(reminders => ({
        type: headerRemindersActions.fetchReminders.type
      }))
    ))
  ));

  public readReminder$ = createEffect(() => this.actions$.pipe(
    ofType(headerRemindersActions.readReminder),
    exhaustMap(({ id }) => this.reminderApiService.readReminder(id).pipe(
      map(reminders => ({
        type: headerRemindersActions.fetchReminders.type
      }))
    ))
  ));

  public unreadReminder$ = createEffect(() => this.actions$.pipe(
    ofType(headerRemindersActions.unreadReminder),
    exhaustMap(({ id }) => this.reminderApiService.unreadReminder(id).pipe(
      map(reminders => ({
        type: headerRemindersActions.fetchReminders.type
      }))
    ))
  ));

  public constructor(
    private actions$: Actions,
    private reminderApiService: ReminderApiService
  ) {
  }

}
