import { Component } from '@angular/core';
import { ExternalActionType, ExternalUserTokenComponent } from '../external-user-token/external-user-token.component';
import { ExternalCompletedComponent } from '../external-completed/external-completed.component';
import { HeaderComponent } from '../../../../shared/components/ui/header/header.component';
import { ViewStackComponent } from '../../../../shared/components/ui/view-stack/view-stack.component';
import { AccessToken } from '../../../../core/constants/access-token';
import { formsActions } from '../../../../store/features/forms/forms-actions';
import { ofType } from '@ngrx/effects';
import { filter, take } from 'rxjs';

@Component({
  selector: 'bizz-anonymous-user',
  standalone: true,
  imports: [
    ExternalCompletedComponent,
    HeaderComponent,
    ViewStackComponent
  ],
  templateUrl: './anonymous-user.component.html',
  styleUrl: './anonymous-user.component.scss'
})
export class AnonymousUserComponent extends ExternalUserTokenComponent {
  public override type = ExternalActionType.AnonymousRegistration;

  public override fetchForm(accessToken: AccessToken | undefined) : void {
    if(accessToken == null) return;
    const collectionId = +accessToken[this.anonymousCollectionId];
    this.actions$.pipe(ofType(formsActions.formFetched.type), filter(fetched => fetched.form.data.CollectionsID == collectionId), take(1))
      .subscribe(fetched => this.setFormId(fetched.form.id));
    this.store$.dispatch(formsActions.getAnonymousForm({ collectionId: collectionId }));
  }
}
