@if (formFieldSignal(); as formField) {
    @if(errorState?.Message; as errorMessage){
        <div class="absolute right-12 top-0 z-[1] flex h-full items-center justify-center">
            <bizz-tooltip class="cursor-pointer text-red-400" [text]="errorMessage"></bizz-tooltip>
        </div>
    }
    <kendo-datetimepicker #input
            [class.error]="errorState != null"
            [formControl]="formControl"
            [ngClass]="{'hidden' : formField.IsHidden}"
            [placeholder]="''"
            [readonly]="readOnlyOverride || formField.IsReadOnly"
            [readOnlyInput]="readOnlyOverride || formField.IsReadOnly"
            tabIndex="-1"
            [format]="dateFormat"
            [id]="'TabIDTimePicker'+ formField.Bookmark"
            (focus)="setCurrentDate()" (blur)="hidePopup()">
    </kendo-datetimepicker>
}

