@if(!isLoading){

<form [formGroup]="reportForm" >
    <div class="modal-content overflow-y-auto">
        @if(activeReportDetails){
        <label class="block font-bold">{{'Template' | translate}}</label>
        <p class="mb-3 text-xs font-normal text-default">{{'Info_ChooseATemplateFromTheTemplateCollection' | translate}}</p>
        <kendo-dropdownlist class="h-10" [readonly]="isGenerating" (selectionChange)="setActiveReport($event)" id="ActiveReport"
            formControlName="ActiveReport" [data]="reports" textField="FileName" valueField="FileName"
            [valuePrimitive]="true">
            <ng-template kendoDropDownListItemTemplate let-data>
                <div class="inline-flex items-center">
                <bizz-extension-icon [extension]="'.docx'" [size]="'extraSmall'" class="mr-2" />
                <label>{{ data.FileName }}</label>
                </div>
            </ng-template>
            <ng-template kendoDropDownListValueTemplate let-data>
                <div class="inline-flex h-full items-center">
                    <bizz-extension-icon [extension]="'.docx'" [size]="'small'" class="ml-2 mr-2.5 flex" />
                    <label>{{ data.FileName }}</label>
                </div>
            </ng-template>
        </kendo-dropdownlist>

        <label class="mt-6 block font-bold">{{'ExportFormat' | translate}}</label>
        <p class="mb-5 text-xs font-normal text-default">{{'Info_ChooseFormatReportGenerate' | translate}}</p>
        <div class="mb-4 flex flex-col justify-between gap-4 self-stretch md:flex-row">
            <button class="version-button flex w-full items-center" [disabled]="!activeReportDetails.AllowDocxReport || isGenerating" [ngClass]="selectedFormat == undefined ? '' : selectedFormat === MediaType.WordReportDocx ? 'selected' : 'not-selected'" (click)="setSelectedFormat(MediaType.WordReportDocx)">
                <div class="accent absolute left-0 top-0 z-10 h-full w-[7px] rounded-bl rounded-tl bg-blue-500"></div>
                <div class="flex items-center gap-4">
                    <bizz-extension-icon extension=".docx" size="medium" />
                    <h2>{{ 'WordDocument' | translate }}</h2>
                    <span class="absolute right-3 top-3 items-center justify-center gap-2.5 rounded-md bg-button px-2 py-1 text-center text-[14px] font-bold leading-none text-default">
                        .docx
                    </span>
                </div>
            </button>

                <button class="version-button w-full" [disabled]="!activeReportDetails.AllowPdfReport || isGenerating"  [ngClass]="selectedFormat == undefined ? '' : selectedFormat === MediaType.WordReportPdf ? 'selected' : 'not-selected'" (click)="setSelectedFormat(MediaType.WordReportPdf)">
                    <div class="accent absolute left-0 top-0 z-10 h-full w-[7px] rounded-bl rounded-tl bg-blue-500"></div>
                    <div class="flex items-center gap-4">
                        <bizz-extension-icon extension=".pdf" size="medium" />
                        <h2 class="text-default">{{ 'PdfDocument' | translate }}</h2>
                        <span class="absolute right-3 top-3 items-center justify-center gap-2.5 rounded-md bg-button px-2 py-1 text-center text-[14px] font-bold leading-none text-default">
                            .pdf
                        </span>
                    </div>
                </button>

        </div>
        @if(selectedFormat === MediaType.WordReportPdf){

            <label class="mt-3 block font-bold">{{'Security' | translate}}</label>
            <div class="inline-flex h-6 w-full items-center gap-3">
                <input type="checkbox" id="CanPrint" [class.disabled]="isGenerating" [readonly]="isGenerating" formControlName="CanPrint" kendoCombobox>
                <label for="CanPrint" class="font-semibold" [class.pointer-events-none]="isGenerating">{{"CanPrint" | translate}}</label>
            </div>
            <div class="inline-flex h-6 w-full items-center gap-3">
                <input type="checkbox" id="CanEdit" [class.disabled]="isGenerating" [readonly]="isGenerating" formControlName="CanEdit" kendoCombobox>
                <label for="CanEdit" class="font-semibold" [class.pointer-events-none]="isGenerating">{{"CanEdit" | translate}}</label>
            </div>
            <div class="inline-flex h-6 w-full items-center gap-3">
                <input type="checkbox" id="CanCopy" [class.disabled]="isGenerating" [readonly]="false" formControlName="CanCopy" kendoCombobox>
                <label for="CanCopy" class="font-semibold" [class.pointer-events-none]="isGenerating">{{"CanCopy" | translate}}</label>
            </div>        
        }

        }
    </div>

</form>

}@else{
    <bizz-loader [fullscreen]="false"  backgroundClasses="transparent"  [showLoader]="true"></bizz-loader>
}