import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardBandComponent } from './components/dashboard-band/dashboard-band.component';
import { DashboardBand } from '../../../../models/ts/dashboard-band.model';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'bizz-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [DashboardBandComponent]
})
@Sentry.TraceClass({ name: 'DashboardComponent' })
export class DashboardComponent implements OnInit, OnDestroy {

  public dashboard: DashboardBand[] = [];

  public constructor(private route: ActivatedRoute) {
  }

  @Sentry.TraceMethod({ name: 'ngOnInit' })
  public ngOnInit(): void {
    this.getRouteData();
  }

  @Sentry.TraceMethod({ name: 'ngOnDestroy' })
  public ngOnDestroy(): void {
  }

  private getRouteData(): void {
    this.route.data.subscribe({
      next: data => {
        this.dashboard = data['dashboard'];
      }
    });

  }
}
