import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { WorkspaceIndexDto } from '../../../../../../models/ts/workspace-index-dto.model';
import { WorkspaceInListDto } from '../../../../../../models/ts/workspace-in-list-dto.model';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { PopupModule} from '@progress/kendo-angular-popup';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { WorkspaceSelectorButton } from '../workspace-selector-button/workspace-selector-button';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { FilterMenuModule } from '@progress/kendo-angular-grid';
import {NgScrollbarModule} from "ngx-scrollbar";
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { WorkspaceApiService } from '../../../../../api/bizzmine/workspace/workspace-api.service';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { ScrollbarComponent } from '../../../../../shared/components/ui/scrollbar/scrollbar.component';
import { ToggleArrowComponent } from '../../../../../shared/components/ui/toggle-arrow/toggle-arrow.component';
import { SidebarService } from '../../../../../shared/services/sidebar/sidebar.service';
import { ListViewComponent, ListViewModule } from '@progress/kendo-angular-listview';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'bizz-workspace-selector',
  templateUrl: './workspace-selector.component.html',
  styleUrls: ['./workspace-selector.component.scss'],
  imports: [
    NgIf,
    NgClass,
    DropDownsModule,
    NgForOf,
    IconComponent,
    PopupModule,
    ButtonModule,
    WorkspaceSelectorButton,
    AsyncPipe,
    FilterMenuModule,
    TranslatePipe,
    NgScrollbarModule,
    ScrollbarComponent,
    ToggleArrowComponent,
    ListViewModule,
    FormsModule
  ],
  standalone: true
})

export class WorkspaceSelectorComponent implements OnInit, OnDestroy, OnChanges {
  // View Children
  @ViewChild('workspaceList') public workspaceList: ListViewComponent;
  @ViewChild('inputFocus', {read: ElementRef}) private inputFocus: any;

  // Input and Output
  @Input() public showFullButton: boolean | null = true;
  @Input({required: true}) public selectedWorkspace: number;
  @Input() public isSettings: boolean;
  @Input() public isMobile = false;
  @Output() public selectedWorkspaceChange = new EventEmitter<number>();

  // Properties
  public workspaces: WorkspaceIndexDto;
  public defaultWorkspaces: WorkspaceIndexDto;
  public activeWorkspace: WorkspaceInListDto | undefined;
  // TODO: implement loading state in template
  public isLoading = true;
  public search: Subject<string> = new Subject<string>();
  private destroy: Subject<void> = new Subject<void>();
  public popupString: string;
  public expanded = this.sidebarService.getObservable();
  public dropDown = false;

  public constructor(
    private workspaceApiService: WorkspaceApiService, 
    private sidebarService: SidebarService, 
    private elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {
  }
  /**
   * Listens to document click events and closes the list if the click is outside the list
   * @param event
   */
  @HostListener('document:click', ['$event.target'])
  public documentClick(targetElement: any): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.dropDown = false;
    }
  }

  @HostListener('document:keydown', ['$event'])
  public onkeydown(event: KeyboardEvent): void { 
    if(this.dropDown) {
      if(event.key === 'Escape') {
        this.dropDown = false;
      }
      else if(this.inputFocus?.nativeElement === document.activeElement) {
        if((event.key === 'Tab' || event.key === 'ArrowDown')) {
          this.workspaceList.focus();
        } else return;
      }
      else if(event.key === 'Tab') { //focus next element on tab
        let index = this.workspaceList.activeIndex + 1;
        if(event.shiftKey)
          index = index - 2;
        if(index >= 0) {
          this.workspaceList.focus(index);
        } else this.focusInput();
      }
  
      else if(event.key === 'Enter' && this.workspaceList.activeIndex != null) {
        const activeWorkspace = this.workspaces.Workspaces[this.workspaceList.activeIndex];
        this.workspaceSelected(activeWorkspace.ID);
      }
      event.preventDefault();
    }
  }

  public ngOnInit(): void {
    if (this.workspaces == null) {
      this.getWorkspaces();
    } else this.isLoading = false;

    this.subscribeToSearch();

    if (this.isMobile) {
      this.popupString = "border-none bg-transparent top-0"
    } else {
      this.popupString = "border-none bg-transparent"
    }
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedWorkspace']) {
      this.setActiveWorkspace(this.selectedWorkspace);
    }
  }

  public toggleDropDown(event?: MouseEvent | undefined): void {
    this.dropDown = !this.dropDown;
    this.workspaces = this.defaultWorkspaces;
    this.focusInput();
  }

  private focusInput(): void {
    this.changeDetector.detectChanges();
    if (this.inputFocus && this.dropDown) {
      this.inputFocus.nativeElement.focus();
    }
  }

  public onSearchEnterPressed(event: any): void {
    if(event.target['value'])
      this.workspaceSelected(this.workspaces.Workspaces[0].ID);
  }

  public workspaceSelected(id: number): void {
    this.selectedWorkspaceChange.emit(id);
    this.setActiveWorkspace(id);
    this.toggleDropDown();
  }

  public getWorkspaces(): void {
    this.isLoading = true;
    this.workspaceApiService.getWorkspaces().subscribe({
      next: (workspaces: WorkspaceIndexDto): void => {
        this.workspaces = workspaces;
        this.defaultWorkspaces = workspaces;
        this.setActiveWorkspace(this.selectedWorkspace);
      },
      error: (): void => {
        this.isLoading = false;
      },
      complete: (): void => {
        this.isLoading = false;
      },
    });
  }

  public searchWorkspaces(): void {
    this.isLoading = true;
    this.workspaceApiService.searchWorkspaces(this.workspaces.TileListSearch).subscribe({
      next: (workspaces: WorkspaceIndexDto): void => {
        this.workspaces = workspaces;
      },
      error: (): void => {
        this.isLoading = false;
      },
      complete: (): void => {
        this.isLoading = false;
      },
    });
  }

  /**
   * Gets the next page of workspaces and concatenates them to the existing workspaces.
   */
  public getNextPage(): void {
    if (this.workspaces.TileListSearch.Page < this.workspaces.Paging.NumberOfPages) {
      this.isLoading = true;
      this.workspaces.TileListSearch.Page++;
      this.workspaceApiService.searchWorkspaces(this.workspaces.TileListSearch).subscribe({
        next: (workspaces: WorkspaceIndexDto): void => {
          workspaces.Workspaces = this.workspaces.Workspaces.concat(workspaces.Workspaces);
          this.workspaces = workspaces;
          //Set the page again because backend returns wrong one.
          this.workspaces.TileListSearch.Page++;
        },
        error: (): void => {
          this.isLoading = false;
        },
        complete: (): void => {
          this.isLoading = false;
        },
      });
    }
  }

  private setActiveWorkspace(id: number): void {
    this.activeWorkspace = this.workspaces?.Workspaces.find((workspace: WorkspaceInListDto) => workspace.ID === id);
  }

  /**
   * Subscribes to the search subject and updates the search property of the workspaces, then calls the API to search for workspaces
   * @private
   */
  private subscribeToSearch(): void {
    this.search.pipe(debounceTime(500), takeUntil(this.destroy)).subscribe({
      next: (search) => {
        this.workspaces.TileListSearch.Search = search;
        this.searchWorkspaces();
      },
    });
  }
}


