import { Injectable } from '@angular/core';
import { DecimalService } from './decimal-service';
import { DateFilterService } from './date-filter-service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public constructor(private decimalService: DecimalService, private dateFilterService: DateFilterService) {}
  // TODO: re-declare as global constants.
  public dtpRegex = /#%%dtp\*\|(\w*-\w*-\w*T\w*:\w*\+\w\w*:\w*)\|\*dtp%%#/g;
  public dpRegex = /#%%dp\*\|(\w*-\w*-\w*T\w*:\w*\+\w\w*:\w*)\|\*dp%%#/g;
  public tpRegex = /#%%tp\*\|(\w*-\w*-\w*T\w*:\w*\+\w\w*:\w*)\|\*tp%%#/g;
  public numRegex = /#%%num\*\|([&-?a-zA-Z0-9.,]*)\|\*num%%#/g;

  public convertNotificationText (input: string): string {

    return this.convertText(input);

    /*if (notification.BodyHTML) {
      notification.BodyHTML = this.convertText(notification.BodyHTML);
    } /* TODO: BR check by RV (and check sanitizer) */
  }

  public convertText (text: string): string {
    if (text == null)
      return "";

    text = this.convertDates(text);
    text = this.convertTimes(text);
    text = this.convertDateTimes(text);
    text = this.convertNumericFields(text);

    return text;
  }
  public convertDates(text: string): string {
    return text.replace(this.dpRegex, (a, b) =>{
      const filteredValue = this.dateFilterService.convertDate(b);
      return "" + filteredValue;
    });
  }
  public convertTimes(text: string): string {
    return text.replace(this.tpRegex,  (a, b) =>{
      const filteredValue = this.dateFilterService.convertTime(b);
      return "" + filteredValue;
    })
  }
  public convertDateTimes(text: string): string {
    return text.replace(this.dtpRegex,  (a, b) =>{
      const filteredValue = this.dateFilterService.convertDateTime(b);
      return "" + filteredValue;
    })
  }
  public convertNumericFields(text: string): string {
    return text.replace(this.numRegex, (a, b) => {
      const filteredValue = this.decimalService.getFormattedValue(b);
      return "" + filteredValue;
    });
  }

  public convertToDateTimeOffsetObject(text: string): void {
      text.split('/')
  }
}
