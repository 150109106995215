@if (formFieldSignal(); as formField){
<!--    <label class="inline-flex items-center cursor-pointer">
        <input [(ngModel)]="aiLicense" type="checkbox" value="" class="sr-only peer">
        <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Ai License</span>
    </label>-->

    <div class="group mx-auto flex w-full max-w-lg items-center justify-center">
        <div class="relative z-10 flex w-full cursor-pointer items-center overflow-hidden rounded-[0.6rem] p-[1.5px]">
            <div [ngClass]="{'invisible' : !aiLoading}" class="animation-rotate absolute inset-0 h-full w-full rounded-full bg-[conic-gradient(#139cd8_20deg,transparent_120deg)]"></div>
            <div class="relative z-20 flex w-full rounded-[0.60rem] bg-white p-[0.1px]"><!--[disabled]="btnLoading || formField.IsReadOnly || readOnlyOverride"-->
                <button class="button form relative" [disabled]="aiLoading" (click)="validateAiLicense()">
                    <p>{{ formField.Caption }}</p> <!---->
                    <i class="text-xl fa-solid fa-microchip-ai"></i>
                    @if (!aiLicense){
                        <div class="absolute h-full w-full left-0 z-40 flex justify-center items-center bg-[black]/40">
                            <div kendoTooltip class="flex p-0.5 bg-white rounded-3xl" tooltipClass="!z-[9000]">
                                <bizz-icon
                                        class="size-4 text-blue-500"
                                        title="'You have no license to use AI, please contact a admin.'"
                                        [hover]="false"
                                        iconName="circle-info">
                                </bizz-icon>
                            </div>
                        </div>
                    }
                </button>
            </div>
        </div>
    </div>
}