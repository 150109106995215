import { Injectable } from '@angular/core';
import { CollectionForm } from '../../../../../models/ts/collection-form.model';
import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';
import { CollectionFormService } from './collection-form.service';
import { ViewDataSource } from '../../../../../models/ts/view-data-source.model';
import { ProtectedFieldType } from '../../../../../models/ts/protected-field-type.model';

/**
 * Service with static functions useful for entity related functionality.
 */
@Injectable({
  providedIn: 'root'
})
export class EntityService {

  public static getEntityField(form: CollectionForm, dependencyFieldId: number): CollectionFormField | undefined {
    const entityVds = EntityService.getEntityViewDataSourceForField(form, dependencyFieldId);
    if (entityVds) {
      return CollectionFormService.getField(form,field => field.ViewDataSourcesID == entityVds.ViewDataSourcesID && field.ProtectedFieldType == ProtectedFieldType.DepartmentName);
    }
    return undefined;
  }

  public static getEntityViewDataSource(form: CollectionForm): ViewDataSource | undefined {
    return form.ViewDataSources.find(vds => vds.EntityRelatedFieldID != null);
  }

  public static getEntityViewDataSourceForField(form: CollectionForm, fieldId: number): ViewDataSource | undefined {
    return form.ViewDataSources.find(vds => vds.EntityRelatedFieldID == fieldId);
  }

  public static hasEntity(form: CollectionForm): boolean {
    return EntityService.getEntityViewDataSource(form) !== undefined;
  }
}
