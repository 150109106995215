import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { formsActions } from '../forms-actions';
import { filter, map } from 'rxjs';
import {
  CollectionFormValidatorResult,
  isCollectionFormValidatorResult
} from '../../../../features/bizzmine/form/classes/collection-form-validator-result';

/**
 * Effects for validation of forms
 */
@Injectable()
export class FormsValidationEffects {
  private actions$ = inject(Actions);
  public formValidationFailed$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveFormInstanceFailed, formsActions.createFormInstanceFailed, formsActions.createLinkedFormInstanceFailed, formsActions.saveLinkedFormInstanceFailed, formsActions.executeTaskFailed, formsActions.saveTaskFailed, formsActions.preValidationFailed),
    filter(({ response }) => response.status === 400 && isCollectionFormValidatorResult(response.error)),
    map(({ formId, response }) => formsActions.formValidationFailed({
        formId: formId,
        validationErrors: response.error as CollectionFormValidatorResult
      })
    )
  ));
}