import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { Subject } from 'rxjs';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { FormsModule } from '@angular/forms';
import { IconComponent } from '../../../ui/icon/icon.component';
import { CommonModule } from '@angular/common';
import { ResetPasswordDto } from '../../../../../../models/ts/reset-password-dto.model';
import { UserSettingsApiService } from '../../../../../api/bizzmine/user-settings/user-settings-api.service';

@Component({
  selector: 'user-settings-2fa-reset-modal',
  templateUrl: './user-settings-2fa-reset-modal.component.html',
  styleUrls: ['./user-settings-2fa-reset-modal.component.scss'],
  standalone: true,
  imports: [TranslatePipe, FormsModule, IconComponent, CommonModule],
})
export class UserSettings2faResetModalComponent implements OnInit, OnDestroy {

  // TODO: BV use formgroup
  public search: string;
  public resetPasswordDto: ResetPasswordDto;
  public error: string[] = [];
  private destroy = new Subject<boolean>();

  public constructor(public dialogRef: DialogRef<boolean>,
              private userSettingsApiService: UserSettingsApiService) {
  }

  public ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
    this.destroy.unsubscribe();
  }

  public ngOnInit(): void {
    this.resetPasswordDto = new ResetPasswordDto();
  }

  public resetTwoFa(): void {
    this.userSettingsApiService.resetTwoFactor().subscribe({
      complete: () => {
        this.dialogRef.close(true);
      },
    });
  }

}
