import { EntityState } from '@ngrx/entity';

export const initialAppState: ApplicationState = {
  translationsLoaded: false,
  themeLoaded: false,
  userSettingsLoaded: false
}

export class ApplicationState {
  translationsLoaded: boolean;
  themeLoaded: boolean;
  userSettingsLoaded: boolean;
}