import { Component, inject, OnInit } from '@angular/core';
import { TaskPopUpDto } from '../../../../../../../models/ts/task-pop-up-dto.model';
import { PopupModule } from '@progress/kendo-angular-popup';
import { IconComponent } from '../../../icon/icon.component';
import { AsyncPipe, DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '../../../../../pipes/translate/translate.pipe';
import { selectUserSettingsFeature } from '../../../../../../store/root-state-selectors';
import { NotificationFilterPipe } from '../../../../../pipes/filter/notification-filter.pipe';
import { NotificationBlockComponent } from '../notification-block/notification-block.component';
import { FilterMenuModule } from '@progress/kendo-angular-grid';
import { isPastDate } from '../../../../../functions/helpers/is-past-date';
import { TaskService } from '../../../../../services/tasks/task.service';
import { SidebarService } from '../../../../../services/sidebar/sidebar.service';
import { LoaderComponent } from '../../../loader/loader.component';
import { headerTasksFeature } from '../../../../../../store/features/header-tasks/header-tasks-feature';
import { HeaderPopupListBase } from '../../classes/header-popup-list-base';
import { headerTasksActions } from '../../../../../../store/features/header-tasks/header-tasks-actions';
import { IsPastPipe } from '../../../../../pipes/is-past/is-past.pipe';
import { FormsModule } from '@angular/forms';

/**
 * Tasks component that shows modal in header.
 */
@Component({
  selector: 'bizz-header-tasks',
  templateUrl: './header-tasks.component.html',
  styleUrls: ['./header-tasks.component.scss'],
  standalone: true,
  imports: [
    PopupModule,
    IconComponent,
    NgIf,
    DatePipe,
    RouterLink,
    NgForOf,
    TranslatePipe,
    NotificationFilterPipe,
    NotificationBlockComponent,
    AsyncPipe,
    FilterMenuModule,
    LoaderComponent,
    IsPastPipe,
    FormsModule,
    NgClass
  ]
})
export class HeaderTasksComponent extends HeaderPopupListBase<TaskPopUpDto> implements OnInit {

  public language: string;
  public readonly isPastDate = isPastDate;
  public data = this.store$.selectSignal(headerTasksFeature.selectTasks);
  public count = this.store$.selectSignal(headerTasksFeature.selectCount);
  public loading = this.store$.selectSignal(headerTasksFeature.selectLoading);
  public userSettingsSignal = this.store$.selectSignal(selectUserSettingsFeature);

  private taskService = inject(TaskService);
  private sidebarService = inject(SidebarService);

  public override ngOnInit(): void {
    super.ngOnInit();
    this.language = this.userSettingsSignal().UserLanguagesCode;
  }

  public refresh(): void {
    this.store$.dispatch(headerTasksActions.fetchTasks());
  }

  // Implementation for abstract parent, template uses openTask directly to avoid array operation.
  public open(id: number): void {
    const task = this.data().find(t => t.ID === id);
    if (task) {
      this.openTask(task);
    } else {
      throw new Error(`No task with ID ${id} found in data array.`);
    }
  }

  public openTask(task: TaskPopUpDto): void {
    this.togglePopup();
    this.sidebarService.collapseSidebar(true);
    this.taskService.executeTask({
      taskId: task.ID,
      collectionId: task.CollectionsID,
      instanceId: task.InstancesID,
      versionId: task.VersionsID,
      draftId: task.RevisionsID,
      prioTaskId: true
    });
  }

  protected search(searchTerm: string): void {
    this.store$.dispatch(headerTasksActions.updateSearch({ search: searchTerm }));
  }
}
