import { ProtectedFieldType } from "src/models/ts/protected-field-type.model";

/**
 * @description a list of form properties where 
 * the backend requires lowercase keys instead of camelcase...
 */
export const FORM_CONSTANTS: { [dataType: string]: string } = {
  "RowDataDesignCrossID": "rowDataDesignCrossID",
  "VersionsID": "versionsID",
  "PreviousChildVersionsID": "previousChildVersionsID",
  "CrossLinkedInstancesID": "crossLinkedInstancesID",
  "OriginalChildInstancesID": "originalChildInstancesID",
  "SnapshotCrosslinkInstancesID": "snapshotCrosslinkInstancesID",
  "DeleteIfExists": "deleteIfExists",
  //"DocumentProperties": "DocumentProperties",
  //"InheritType": "InheritType",
  //"DisplayFieldsInheritType": "DisplayFieldsInheritType",
  //"TrainingAssessment": "TrainingAssessment",
  //"ExamSubscriptions": "ExamSubscriptions",
}


/**
 * @description A list of field types where formatting should be ignored
 */

export const FORMAT_EXCEPTION_FIELD_TYPES = [
  ProtectedFieldType.RegistrationYear,
  ProtectedFieldType.RegistrationMonth,
  ProtectedFieldType.RegistrationWeek,
  ProtectedFieldType.RegistrationQuarter
];
