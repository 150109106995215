import { Component, DestroyRef, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../icon/icon.component';
import { SidebarListItem } from '../sidebar.component';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { WorkspaceSidebarItemDto } from '../../../../../../models/ts/workspace-sidebar-item-dto.model';
import { SidebarService } from '../../../../services/sidebar/sidebar.service';
import { ViewStackService } from '../../../../services/view-stack/view-stack.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WorkspaceItemType } from '../../../../../../models/ts/workspace-item-type.model';

@Component({
  selector: 'bizz-sidebar-block',
  standalone: true,
  imports: [CommonModule, IconComponent, RouterLink, RouterLinkActive],
  templateUrl: './sidebar-block.component.html',
  styleUrls: ['./sidebar-block.component.scss'],
})
export class SidebarBlockComponent {
  @Input() public sidebarListItem: SidebarListItem ;
  @Input() public children: WorkspaceSidebarItemDto[];
  @Input() public isMobile: boolean;
  @Output() public mobileRoutingClicked = new EventEmitter<void>();

  public sidebarState: 'in' | 'out' = 'out';
  public expanded = this.sidebarService.getObservable();

  public constructor(
    private sidebarService: SidebarService,
    private viewStackService: ViewStackService,
    private destroyRef: DestroyRef,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.expanded.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (expanded) => {
        this.sidebarState = expanded ? 'out' : 'in';
        this.toggleContent(!expanded);
      },
    });
  }

  public openLink(sidebarListItem: SidebarListItem | WorkspaceSidebarItemDto) : void {
    if(sidebarListItem.ItemType == WorkspaceItemType.ExternalLink) {
      window.open(sidebarListItem.URL, '_blank');
      return;
    }
    let defaultUrl = ['dashboard/' + sidebarListItem.WorkspaceItemsID];
    if(sidebarListItem.ItemType == WorkspaceItemType.NewTableViewRecord) {
      defaultUrl = ['form/collectionsid/' + sidebarListItem.CollectionsID + '/workspaceitemsid/' + sidebarListItem.WorkspaceItemsID];
    }
    this.router.navigate(defaultUrl,
      {
        relativeTo: this.activatedRoute
      }).then();
  }


  public slideSidebar(): void {
    if (this.isMobile) {
      this.mobileRoutingClicked.emit();
    }
  }

  private toggleContent(collapsed: boolean): void {
    if (this.sidebarListItem)
      this.sidebarListItem.collapsed = collapsed;
  }


  protected readonly WorkspaceItemType = WorkspaceItemType;
}
