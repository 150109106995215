import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ThemeDto } from '../../../../models/ts/theme-dto.model';
import { ThemeListDto } from '../../../../models/ts/theme-list-dto.model';
import { ThemeUsageDto } from '../../../../models/ts/theme-usage-dto.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeApiService {

  public constructor(private http: HttpClient) {
  }

  public getTheme(themeID: number): Observable<ThemeDto> {
    return this.http.get<ThemeDto>(`api/themes/${themeID}`);
  }

  public getThemes(): Observable<ThemeListDto> {
    return this.http.get<ThemeListDto>('api/themes');
  }

  public getUserTheme(): Observable<ThemeDto> {
    return this.http.get<ThemeDto>('api/themes/user');
  }

  public getThemeUsage(themeID: number): Observable<ThemeUsageDto> {
    return this.http.get<ThemeUsageDto>(`api/themes/${themeID}/usage`);
  }

  public saveTheme(theme: ThemeDto): Observable<ThemeDto> {
    return this.http.post<ThemeDto>('api/themes', theme);
  }

  public replaceTheme(themeID: number, replacementID: number): Observable<void> {
    return this.http.delete<void>(`api/themes/replace/${themeID}/${replacementID}`);
  }

  public deleteTheme(themeID: number): Observable<void> {
    return this.http.delete<void>(`api/themes/${themeID}`);
  }
}
