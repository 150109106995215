<div class="h-full w-full">


    @if (items$ | async; as items) {
        <div class="h-[calc(100%_-_3.5rem)] w-full overflow-auto">
            <kendo-grid
                    [kendoGridBinding]="items.OrganizationChartItems"
                    [selectable]="selectableSettings"
                    [kendoGridSelectBy]="buildUniqueId"
                    [selectedKeys]="selectedKeys"
                    [sortable]="true"
                    [rowSelected]="isRowSelected.bind(this)"
                    (cellClick)="onCellClick($event, items.OrganizationChartItems)"
            >

                <kendo-grid-column title=""  [width]="50">
                    <ng-template
                    kendoGridHeaderTemplate
                    let-column
                    let-columnIndex="columnIndex"
                  > <div class="h-5 px-4"></div>
                </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="h-5 px-4" [class]="{'cursor-not-allowed': !isEnabledForSelection(dataItem), 'opacity-50': !isEnabledForSelection(dataItem)}">
                            @if (isEnabledForSelection(dataItem)) {
                                @if (isSelected(dataItem)) {
                                    <bizz-icon iconName="circle-check"
                                               class="size-5 cursor-pointer text-green-500"></bizz-icon>
                                } @else {
                                    <div class="icon relative h-5 w-5 cursor-pointer rounded-full bg-button">
                                        <bizz-icon iconName="plus" class="absolute left-1/2 top-1/2 size-3 translate-x-[-51%] translate-y-[-49%] text-center text-blue-500"></bizz-icon>
                                    </div>
                                }
                            }
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="Name" title="{{'Name' | translate}}">
                    <ng-template
                    kendoGridHeaderTemplate
                    let-column
                    let-columnIndex="columnIndex"
                  > <p class="ml-6">
                    {{ column.title }}
                  </p>
                </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <p [class.text-blue-500]="isSelected(dataItem)" [class]="{'cursor-not-allowed': !isEnabledForSelection(dataItem), 'opacity-50': !isEnabledForSelection(dataItem)}">
                            <bizz-icon class="mr-1 h-4 w-4 text-blue-500"
                                       [iconName]="ORG_CHART_ITEM_TYPE_ICONS[dataItem.ItemType]"
                            ></bizz-icon>
                            {{ dataItem.Name }}
                        </p>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="Type" title="{{'Type' | translate}}" [media]="typMedia">
                    <ng-template
                    kendoGridHeaderTemplate
                    let-column
                    let-columnIndex="columnIndex"
                  > <p>
                    {{ column.title }}
                  </p>
                </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <p [class.text-blue-500]="isSelected(dataItem)" [class]="{'cursor-not-allowed': !isEnabledForSelection(dataItem), 'opacity-50': !isEnabledForSelection(dataItem)}">{{ dataItem.ItemTypeName }}</p>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="UserType" title="{{'UserType' | translate}}" [media]="userTypMedia">
                    <ng-template
                    kendoGridHeaderTemplate
                    let-column
                    let-columnIndex="columnIndex"
                  > <p>
                    {{ column.title }}
                  </p>
                </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <p [class.text-blue-500]="isSelected(dataItem)" [class]="{'cursor-not-allowed': !isEnabledForSelection(dataItem), 'opacity-50': !isEnabledForSelection(dataItem)}">{{ dataItem.UserTypeName }}</p>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>

        <kendo-datapager [style.width.%]="100" [pageSize]="pageSize"
                         [skip]="(currentPage - 1) * pageSize" [total]="totalItems"
                         (pageChange)="onPageChange($event)"
                         [size]="'large'">
            <ng-template kendoDataPagerTemplate>
                <div class="flex w-full items-center justify-between">
                    <kendo-datapager-info class="hidden md:block"></kendo-datapager-info>
                    <div class="flex w-full items-center justify-between gap-0.5 md:w-auto md:justify-start">
                        <kendo-datapager-page-sizes class="pageSizes gap-2" [pageSizes]="pageSizes">
                        </kendo-datapager-page-sizes>
                        @if (totalItems > pageSize) {
                            <div class="k-pager-numbers-wrap">
                                <kendo-datapager-prev-buttons></kendo-datapager-prev-buttons>
                                <kendo-datapager-numeric-buttons [buttonCount]="3">

                                </kendo-datapager-numeric-buttons>
                                <kendo-datapager-next-buttons></kendo-datapager-next-buttons>
                            </div>
                        }
                    </div>
                </div>
            </ng-template>
        </kendo-datapager>
    }
</div>
