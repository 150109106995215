import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[bizz-drag-drop]'
})
export class DragFileUploadDirective {
  @Output() public files: EventEmitter<Array<File>> = new EventEmitter();
  @Output() public isHoveringDragDrop: EventEmitter<boolean> = new EventEmitter();
  @Input() public disabled: boolean = false;


  public constructor() {
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    if(this.disabled) return;
    this.isHoveringDragDrop.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    if(this.disabled) return;
    this.isHoveringDragDrop.emit(false);
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt: DragEvent): void {
    if(this.disabled) return;
    if(evt == null || evt.dataTransfer == null || evt.dataTransfer.files == null || evt.dataTransfer.files.length == 0){
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    this.isHoveringDragDrop.emit(false);

    const files: Array<File> = [];
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      const file = evt.dataTransfer.files[i];
      files.push(file);
    }
    if (files.length > 0) {
      this.files.emit(files);
    }
  }
}