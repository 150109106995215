import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { CronGenMinutes } from 'src/app/features/bizzmine/form/interfaces/crongen/cron-gen-minutes';
import { CronGenService } from 'src/app/features/bizzmine/form/services/cron-gen.service';
import { CronTimeHelpers, timeHelpers } from 'src/app/shared/constants/time-helpers';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';

@Component({
  selector: 'bizz-cron-minutes',
  standalone: true,
  imports: [
    TranslatePipe, 
    DropDownsModule,
  ],
  templateUrl: './cron-minutes.component.html',
  styleUrl: './cron-minutes.component.scss',
})
export class CronMinutesComponent {
  public helpers: CronTimeHelpers = timeHelpers;

  @Input() public readOnly = false;
  @Input() public state: CronGenMinutes;
  @Output() public stateChange: EventEmitter<CronGenMinutes> = new EventEmitter<CronGenMinutes>();

  public constructor(public cronGenService: CronGenService) {
  }

  public changeMinutes(minutes: number): void {
    this.state.minutes = minutes;
    this.stateChange.emit(this.state);
  }

  public changeSeconds(seconds: number): void {
    this.state.seconds = seconds;
    this.stateChange.emit(this.state);
  }
  
}
