export enum FilterItemType {
	Tasks = 1,
	History = 2,
	GeneralLogs = 3,
	SecurityLogs = 4,
	PermissionHistory = 5,
	Collection = 6,
	TaskListWidget = 7,
	Widget = 8,
	Tree = 9,
	UserReversedIndex = 10,
  Matrix = 11,
  InstanceSelector = 12
}