import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

/**
 * Pipe to get a fields corresponding formcontrol from given formgroup<br>
 */
@Pipe({
  name: 'SharedFieldFormControl',
  standalone: true,
  pure: true
})
export class SharedFieldFormControlPipe implements PipeTransform {

  /**
   * Pipe to get a fields corresponding formcontrol from given formgroup<br>
   * Intended for use in FormComponent.
   * @param field
   * @param form
   */
  transform(field: string, form: FormGroup): FormControl {
    return form.controls[field] as FormControl;
  }

}
