import { inject, Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { DownloadService } from '../download/download.service';
import { Dialog } from '@angular/cdk/dialog';
import { FileUploadCheckinModalComponent } from '../../../shared/components/file-upload/file-upload/file-upload-checkin-modal/file-upload-checkin-modal.component';
import { formsActions } from '../../../store/features/forms/forms-actions';
import { DocumentCheckinType } from '../../../../models/ts/document-checkin-type.model';
import { userSettingsFeature } from '../../../store/features/user-settings/user-settings-feature';

@Injectable({
  providedIn: 'root'
})
export class FileUploadCheckinService {
  private downloadService = inject(DownloadService);
  private dialog = inject(Dialog);

  public openCheckInDialog(dataItem: unknown, checkIn: boolean): Observable<unknown> {
    return this.dialog.open(FileUploadCheckinModalComponent, {
      data: {
        data: dataItem,
        checkIn: checkIn
      }
    }).closed.pipe(take(1));
  }

  public checkOutDocument(intent: {
    checkOut$: Observable<HttpResponse<ArrayBuffer>>,
    storeData: {
      store: Store,
      formId: string,
      documentCheckInStatusFieldId: number
    } | undefined
  }) : void {
    intent.checkOut$.pipe(take(1))
      .subscribe((response) => {
        if(intent.storeData){
          intent.storeData.store.dispatch(formsActions.updateFormFieldEnumValue({
            formId: intent.storeData.formId,
            fieldId: intent.storeData.documentCheckInStatusFieldId,
            value: DocumentCheckinType.CheckedOut
          }));
          intent.storeData.store.dispatch(formsActions.updateFormCheckedOutBy({
            formId: intent.storeData.formId,
            checkOutByUserId: intent.storeData.store.selectSignal(userSettingsFeature.selectUserID)()
          }))
        }

        if(response.body){
          this.downloadService.startDownload(response.body, response.headers.get('x-filename') ?? '');
        }
      });
  }
}
