import { Component } from '@angular/core';
import { IconComponent } from '../../../ui/icon/icon.component';
import { CellActionType } from '../../../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';

@Component({
  selector: 'bizz-grid-cell-mail-actions',
  standalone: true,
  imports: [
    IconComponent
  ],
  templateUrl: './grid-cell-mail-actions.component.html',
  styleUrl: './grid-cell-mail-actions.component.scss'
})
export class GridCellMailActionsComponent extends GridCellBaseComponent<string>{
  public status: boolean;
  public isLockedOrReadonly: boolean;
  public canViewChanges: boolean;
  public onView(): void{
    this.onCellAction.emit({ action: CellActionType.MAIL_VIEW, data: { data: this.data } });
  }
  public onEdit(): void{
    this.onCellAction.emit({ action: CellActionType.MAIL_EDIT, data: { data: this.data } });
  }
  public onTrackChanges(): void{
    this.onCellAction.emit({ action: CellActionType.MAIL_TRACK_CHANGES, data: { data: this.data } });
  }
  public onDelete(): void{
    this.onCellAction.emit({ action: CellActionType.MAIL_DELETE_DRAFT, data: { data: this.data } });
  }

  protected override onDataChanged(): void {
    this.status = <unknown>this.data['Status'] as boolean;
    this.canViewChanges = <unknown>this.data['CanViewChanges'] as boolean;
    this.isLockedOrReadonly = <unknown>this.data['IsLockedOrReadonly'] as boolean;
  }
}
