import { DialogRef } from '@angular/cdk/dialog';
import { NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { IconComponent } from '../../ui/icon/icon.component';

@Component({
  selector: 'bizz-modal',
  standalone: true,
  imports: [TextAreaModule, NgTemplateOutlet, TranslatePipe, IconComponent],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent implements OnInit {
  @Input() public headerTemplate: TemplateRef<unknown> | undefined;
  @Input() public contentTemplate: TemplateRef<unknown> | undefined;
  @Input() public footerTemplate: TemplateRef<unknown> | undefined;
  @Input() public modalSize = 'xl';
  @Input() public title: string;
  @Input() public message: string;

  public constructor(public dialogRef: DialogRef<ModalComponent>) {
    this.dialogRef.disableClose = true;
  }

  public ngOnInit(): void {
    if (!this.headerTemplate && !this.title)
      console.error('set title and or message when headerTemplate is not set');
  }

}
