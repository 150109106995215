import { Component, DestroyRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router, RouterOutlet } from '@angular/router';
import { WorkspaceSidebarDto } from '../../../../../models/ts/workspace-sidebar-dto.model';
import { HeaderComponent } from '../header/header.component';
import { ViewStackComponent } from '../view-stack/view-stack.component';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { DialogModule } from '@angular/cdk/dialog';
import { Store } from '@ngrx/store';
import { userSettingsActions } from '../../../../store/features/user-settings/user-settings-actions';
import { ScrollbarComponent } from '../scrollbar/scrollbar.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { workspaceSidebarActions } from '../../../../store/features/workspace-sidebar/workspace-sidebar-actions';
import { workspaceSidebarFeature } from '../../../../store/features/workspace-sidebar/workspace-sidebar-feature';
import { asyncScheduler, filter, Observable, pairwise, Subject, takeUntil } from 'rxjs';
import { SidebarService } from '../../../services/sidebar/sidebar.service';
import { ContextMenuComponent } from '../../../../features/bizzmine/widgets/context-menu/context-menu.component';
import { AlertPopupComponent } from '../../../../features/bizzmine/alerts/components/alert-popup/alert-popup.component';
import { selectRenderState } from '../../../../store/features/app/app-selectors';
import { LoaderComponent } from '../loader/loader.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StackedAlertsComponent } from '../stacked-alerts/stacked-alerts.component';
import { viewStackActions } from 'src/app/store/features/forms/view-stack-actions';

export type ScrollbarVisibility = 'hover' | 'always' | 'native';

@Component({
  selector: 'bizz-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: true,
  imports: [
    HeaderComponent,
    ViewStackComponent,
    RouterOutlet,
    NgClass,
    SidebarComponent,
    NgIf,
    DialogModule,
    ScrollbarComponent,
    NgScrollbarModule,
    AsyncPipe,
    ContextMenuComponent,
    AlertPopupComponent,
    LoaderComponent,
    StackedAlertsComponent
  ]
})
export class LayoutComponent implements OnInit, OnDestroy {
  public isSettings = false;
  public showViewStack = false;
  public loadingViewStack = false;
  public scrollbarVisibility: ScrollbarVisibility = 'hover';
  public showSidebar$ = this.sidebarService.getObservable();
  public sidebarEvent: boolean;

  /**
   * Observable selected from the store that contains the sidebar.
   */
  public sidebar: Observable<WorkspaceSidebarDto> = this.store$.select(
    workspaceSidebarFeature.selectWorkspaceSidebarState
  );

  public readyToRender: Observable<boolean> = this.store$.select(selectRenderState);

  /**
   * Subject that emits when the component is destroyed.
   */
  private destroy = new Subject<void>();

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store,
    private sidebarService: SidebarService,
    public destroyRef: DestroyRef,
  ) {
    this.store$.dispatch(userSettingsActions.fetchUserSettings());
  }

  public ngOnInit(): void {
    this.processRouteData(this.route);
    this.subscribeToSidebar();

    //Listens to route events to a workspace and redirect to the correct dashboard if needed.
    this.router.events.pipe(
      takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next: (event) => {
        if(event instanceof NavigationEnd) {
          const navEndEvent = event as NavigationEnd;
          const primary = this.router.parseUrl(navEndEvent.url).root?.children[PRIMARY_OUTLET];
          if(primary && !primary.segments.some(segment => segment.path === 'dashboard')) {
            this.processRouteData(this.route);
          }
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  /**
   * Sets the showViewStack property based on the length of the view stack. <br>
   * If the length is greater than 0, the view stack is shown.
   * @param length
   */
  public viewStackChanged(length: number): void {
    this.showViewStack = length > 0;
  }
  public loadingChanged(loading: boolean): void {
    this.loadingViewStack = loading;
    asyncScheduler.schedule(() => {
      this.store$.dispatch(viewStackActions.loadingStateChanged({loading: this.loadingViewStack}))
    })
  }

  public triggerHoverSidebar(): void {
    this.sidebarEvent = !this.sidebarEvent;
  }

  public updateWorkspaceStore(id: number): void {
    this.store$.dispatch(
      workspaceSidebarActions.getWorkspaceSidebar({ workspaceId: id })
    );
  }

  /**
   * Processes route data, setting isSettings and dispatching the getWorkspace action with route parameter.
   */
  private processRouteData(route: ActivatedRoute): void {
    this.isSettings = route.snapshot.data['settings'];
    this.store$.dispatch(
      workspaceSidebarActions.getWorkspaceSidebar({
        workspaceId: route.snapshot.params['workspaceId']
      })
    );
  }

  /**
   * Subscribes to the sidebar observable and navigates to the first dashboard in the workspace if conditions are met.
   * Conditions for navigation are:
   * - The previous sidebar ID is not the same as the next sidebar ID. (indicating a new workspace was selected)
   * - The previous sidebar ID is not 0. (prevent navigation on initial load, the store default ID is 0, which is not a valid workspace ID)
   * - The route snapshot children length is 0. (indicating no dashboard is currently loaded, preventing navigating away from a dashboard specified in the route)
   */
  private subscribeToSidebar(): void {
    this.sidebar.pipe(
      pairwise(),
      filter(([previous, next]) => (previous.ID !== next.ID && previous.ID !== 0) || this.route.snapshot.children.length === 0),
      takeUntil(this.destroy)
    ).subscribe({
      next: ([previous, next]) => {
        this.navigateToWorkspaceDashboard(next);
      }
    });
  }

  /**
   * Navigates to the first/default dashboard in the workspace.XL
   * @param sidebar
   */
  private navigateToWorkspaceDashboard(sidebar: WorkspaceSidebarDto): void {
    this.router
      .navigate([
        `./workspace/${sidebar.ID}/dashboard/${sidebar.DefaultWorkSpaceItemsID}`
      ])
      .then();
  }
}
