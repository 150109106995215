import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusIconComponent } from '../../../ui/status-icon/status-icon.component';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { InstanceStatusType } from '../../../../../../models/ts/instance-status-type.model';
import { TaskStateType } from '../../../../../../models/ts/task-state-type.model';

/**
 * Cell containing the status icon of the instance
 */
@Component({
  selector: 'bizz-grid-cell-status',
  standalone: true,
  imports: [CommonModule, StatusIconComponent],
  templateUrl: './grid-cell-status.component.html',
  styleUrls: ['./grid-cell-status.component.scss']
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellStatusComponent extends GridCellBaseComponent<InstanceStatusType | TaskStateType> {

}
