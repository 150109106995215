import { Component } from '@angular/core';
import { FormLandingPageComponent } from '../form-landing-page/form-landing-page.component';
import { take } from 'rxjs';
import { TaskRevisionDialogComponent } from '../../../../features/bizzmine/widgets/task-list-widget/task-revision-dialog/task-revision-dialog.component';

@Component({
  selector: 'bizz-revision-landing-page',
  standalone: true,
  imports: [],
  templateUrl: './revision-landing-page.component.html',
  styleUrl: './revision-landing-page.component.scss'
})
export class RevisionLandingPageComponent extends FormLandingPageComponent{
  public override routeApplied() : void {
    this.taskApiService.getRevisionTask(this.taskId!)
      .pipe(take(1))
      .subscribe({
        next: (found) => {
          this.dialog.open(TaskRevisionDialogComponent, {
            data: {
              task: found.Tasks[0]
            }
          });
        }
      });

    this.router.navigate(['./workspace']);
  }
}
