import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { TaskPopUpDto } from '../../../../../../models/ts/task-pop-up-dto.model';
import { EditorModule } from '@progress/kendo-angular-editor';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { CollectionApiService } from '../../../../../api/bizzmine/collection/collection-api.service';
import { catchError, exhaustMap, take } from 'rxjs';
import { TaskInfoDto } from '../../../../../../models/ts/task-info-dto.model';
import { SharedDatePickerControlComponent } from '../../../../../shared/components/forms/controls/date-picker-control/date-picker-control.component';
import { DateTimePickerModule } from '@progress/kendo-angular-dateinputs';
import { Router } from '@angular/router';
import { BizzMineLocalStorageService } from '../../../../../shared/services/localStorage/bizzmine-local-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  BizzMineHttpValidationErrorResponse,
  TaskPostponeDto
} from '../../../../../../models/ts/task-postpone-dto.model';
import { PeriodicRevisionDto } from '../../../../../../models/ts/periodic-revision-dto.model';
import { TaskType } from '../../../../../../models/ts/task-type.model';
import { SafePipe } from 'safe-pipe';
import { NotificationFilterPipe } from '../../../../../shared/pipes/filter/notification-filter.pipe';

@Component({
  selector: 'bizz-task-revision-dialog',
  standalone: true,
  imports: [
    EditorModule,
    IconComponent,
    ToolBarModule,
    TranslatePipe,
    SharedDatePickerControlComponent,
    DateTimePickerModule,
    SafePipe,
    NotificationFilterPipe
  ],
  templateUrl: './task-revision-dialog.component.html',
  styleUrl: './task-revision-dialog.component.scss'
})
export class TaskRevisionDialogComponent {
  public task: TaskPopUpDto;
  public taskInfo: PeriodicRevisionDto;
  public postPoneDateTime: Date;
  public loading: boolean;
  public disableRestart: boolean;
  public errors: BizzMineHttpValidationErrorResponse | null = null;
  public taskType = TaskType;

  public constructor(@Inject(DIALOG_DATA) public data: { task: TaskPopUpDto },
                     private dialogRef: DialogRef<TaskRevisionDialogComponent>,
                     private collectionApiService: CollectionApiService,
                     private router: Router,
                     private bizzMineLocalStorageService: BizzMineLocalStorageService) {

    this.task = data.task;
    this.collectionApiService.getRevisionTaskInfo({
      taskId: this.task.ID,
      revisionId: this.task.RevisionsID,
      collectionId: this.task.CollectionsID,
      instanceId: this.task.InstancesID
    }).pipe(take(1)).subscribe(taskInfo => {
      this.taskInfo = taskInfo;
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public details(): void {
    if (this.taskInfo) {
      this.loading = true;
      const path = this.preRouteWorkspace() + '/form/collectionsid/' + this.task.CollectionsID + '/instancesid/' + this.task.InstancesID + '/versionsid/' + this.task.VersionsID + '/readonly';
      this.router.navigate([path])
        .then(() => this.close());
    }
  }

  public major(): void {
    if (this.taskInfo) {
      this.loading = true;
      const path = this.preRouteWorkspace() + '/form/collectionsid/' + this.task.CollectionsID + '/instancesid/' + this.task.InstancesID + '/versionsid/' + this.task.VersionsID + '/major/create';
      this.router.navigate([path])
        .then(() => this.close());
    }
  }

  public minor(): void {
    if (this.taskInfo) {
      this.loading = true;
      const path = this.preRouteWorkspace() + '/form/collectionsid/' + this.task.CollectionsID + '/instancesid/' + this.task.InstancesID + '/versionsid/' + this.task.VersionsID + '/minor/create';
      this.router.navigate([path])
        .then(() => this.close());
    }
  }

  public restart(): void {
    if (this.taskInfo) {
      this.loading = true;
      this.disableRestart = true;
      const path = this.preRouteWorkspace() + '/form/collectionsid/' + this.task.CollectionsID + '/instancesid/' + this.task.InstancesID + '/versionsid/' + this.task.VersionsID + '/restart';
      this.router.navigate([path])
        .then(() => this.close());
    }
  }

  public postPone(): void {
    this.loading = true;
    this.errors = null;
    const postpone = { TaskID: this.task.ID, PostponeDate: this.postPoneDateTime.toISOString() } as TaskPostponeDto;
    this.collectionApiService.validatePostponeTask(postpone).pipe(
      take(1),
      catchError((error: HttpErrorResponse) => {
        this.loading = false;
        this.errors = error.error;
        throw error;
      }),
      exhaustMap(() => {
        return this.collectionApiService.postponeTask({
          postpone: postpone,
          revisionId: this.task.RevisionsID,
          instanceId: this.task.InstancesID,
          collectionId: this.task.CollectionsID,
          versionId: this.task.VersionsID
        });
      })
    ).subscribe(
      {
        complete: () => {
          this.close();
        }
      }
    );
  }

  private preRouteWorkspace(): string {
    return `./workspace/${this.bizzMineLocalStorageService.getItem('workspaceID')}`;
  }

}
