import { Component } from '@angular/core';
import { OrganizationChartControlComponent } from '../organization-chart-control/organization-chart-control.component';
import { selectForm } from '../../../../../../store/features/forms/forms-selectors';
import { EntityService } from '../../../services/entity.service';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { formsActions } from '../../../../../../store/features/forms/forms-actions';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import {
  AutoCompleteModule,
  ComboBoxModule,
  DropDownTreesModule,
  MultiSelectModule
} from '@progress/kendo-angular-dropdowns';
import { LinkedFormControlButtonsComponent } from '../linked-form-control-buttons/linked-form-control-buttons.component';
import { OrgChartSelectComponent } from '../../../../org-chart/org-chart-select/org-chart-select.component';

@Component({
  selector: 'bizz-registered-on-behalf-of-control',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconComponent,
    DropDownTreesModule,
    MultiSelectModule,
    AutoCompleteModule,
    LinkedFormControlButtonsComponent,
    ComboBoxModule,
    OrgChartSelectComponent
  ],
  templateUrl: '../organization-chart-control/organization-chart-control.component.html',
  styleUrl: '../organization-chart-control/organization-chart-control.component.scss'
})
export class RegisteredOnBehalfOfControlComponent extends OrganizationChartControlComponent {
  public isEntityDependency = false;
  // Might cause issues if any property which changes is used in NgRx reducers/effects.
  public entityField: CollectionFormField | undefined;

  public get hasEntity(): boolean {
    return this.isEntityDependency && this.entityField !== undefined;
  }

  public override ngOnInit() {
    super.ngOnInit();
    this.checkForEntityDependency();
  }

  public checkForEntityDependency(): void {
    const field = this.formFieldSignal();
    const form = this.store$.selectSignal(selectForm(this.formId))();
    if (field !== undefined && form !== undefined) {
      const vds = EntityService.getEntityViewDataSourceForField(form.data, field.CollectionFieldsID);
      if (vds !== undefined) {
          this.isEntityDependency = true;
          this.entityField = EntityService.getEntityField(form.data, field.CollectionFieldsID);
      }
    }
  }

  protected override valueChangeDispatcher(value: any): void {
    const formFieldId = this.formFieldId();
    if (formFieldId !== undefined) {
      if (this.hasEntity)
        this.store$.dispatch(formsActions.updateRegisteredOnBehalfOfFieldValueWithEntity({
          formId: this.formId,
          fieldId: formFieldId,
          entityField: this.entityField!,
          value
        }));
      else
        this.store$.dispatch(formsActions.updateOrgChartFieldValue({
          formId: this.formId,
          fieldId: formFieldId,
          value
        }));
    }
  }
}
