import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridContextPermissions } from '../../../features/bizzmine/widgets/collection-list-widget/classes/grid-context-permissions';
import { CollectionListDataInstance } from '../../interfaces/collection-list-data-instance';

@Injectable({
  providedIn: 'root',
})
export class ContextMenuService {

  private contextMenuExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _anchorElement: ElementRef | undefined;
  private _permissions: GridContextPermissions | undefined;
  private _dataItem: CollectionListDataInstance | any | undefined;
  private _useSeparators: boolean;
  private _widgetId: number | undefined;

  public get dataItem(): any {
    return this._dataItem;
  }

  public get permissions(): GridContextPermissions | undefined {
    return this._permissions;
  }

  public get anchorElement(): ElementRef | undefined {
    return this._anchorElement;
  }

  public get useSeparators(): boolean {
    return this._useSeparators;
  }
  public get widgetId(): number | undefined {
    return this._widgetId;
  }

  /**
   * Opens the context menu.
   * @param permissions The permissions of the dataItem.
   * @param dataItem The dataItem.
   * @param anchorElement The anchor htmlelement of the context menu.
   */
  public open(permissions: GridContextPermissions, dataItem: CollectionListDataInstance | any,
              anchorElement: ElementRef,useSeparators: boolean,  widgetId: number | undefined): void {
    if (!anchorElement || !permissions || !dataItem) {
      throw new Error('Missing data for context menu');
    }

    this._anchorElement = anchorElement;
    this._permissions = permissions;
    this._dataItem = dataItem;
    this._useSeparators = useSeparators;
    this._widgetId = widgetId;
    this.contextMenuExpanded.next(true);

  }

  public close(): void {
    this.contextMenuExpanded.next(false);
  }

  public get expanded(): boolean {
    return this.contextMenuExpanded.getValue();
  }

  public getObservable(): Observable<boolean> {
    return this.contextMenuExpanded.asObservable();
  }
}
