<div class="max-h-[40svh] sm:min-w-[1280px] sm:max-w-[1440px] modal-container justify-between md:justify-normal">
    <div class="flex flex-col h-full sm:h-auto flex-grow pb-4 sm:px-6 md:flex-grow-0 overflow-auto">
        <div class="modal-header">
            <h2 class="mb-5 text-center font-title text-xl font-bold text-default">
                {{ 'YourSessionIsAboutToExpire' | translate }}
            </h2>
        </div>
        <hr class="my-5"/>
        <div class="flex flex-col h-full sm:h-auto flex-grow pb-4 sm:px-6 md:flex-grow-0">
            <div class="flex flex-col justify-center items-center gap-2">
                <h2 class="mb-5 text-center font-title text-xl font-bold text-default">{{ "LoggedOutByInactivity" | translate }} {{ remaining }} {{ "second(s)" | translate }}</h2>
                <kendo-progressbar [max]="totalTime" [value]="totalTime-remaining" [label]="false"
                                   [progressCssStyle]="progressStyles"></kendo-progressbar>

            </div>
        </div>
    </div>
</div>