import { DialogModalButtons } from '../../../enums/dialog-modal-buttons';

export class DialogModalModel {

  /**
   * Title to display,
   * can be a translation key
   */
  public title: string;

  /**
   * Message to display,
   * can be a translation key
   */
  public message: string;

  /**
   * Should the modal show a confirmation.
   */
  public mode: DialogModalModelMode = DialogModalModelMode.simple;

  /**
   * The confirmation text to enter.
   */
  public confirmationtext: string;

  /**
   * The buttons to display
   */
  public buttons: DialogModalButtons;

  /**
   * ShowCancelButton
   */
  public showCancelButton: boolean = true;
}

export enum DialogModalModelMode {
  confirmation = 0,
  simple = 1
}
