import { Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ToolBarToolComponent } from '@progress/kendo-angular-toolbar';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { IconComponent } from '../../../../../../../../shared/components/ui/icon/icon.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { SliderModule } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'bizz-custom-canvas-file-upload',
  standalone: true,
  imports: [
    ButtonModule,
    IconComponent,
    PopupModule,
    SliderModule
  ],
  templateUrl: './custom-canvas-file-upload.component.html',
  styleUrl: './custom-canvas-file-upload.component.scss',
  providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => CustomCanvasFileUploadComponent) }]
})
export class CustomCanvasFileUploadComponent extends ToolBarToolComponent implements OnInit {
  @ViewChild('toolbarTemplate', { static: true }) public override toolbarTemplate: TemplateRef<any>;
  @ViewChild('popupTemplate', { static: true }) public override popupTemplate: TemplateRef<any>;
  @Output() public fileSelected = new EventEmitter<File>();
  @Output() public error = new EventEmitter<string>();
  public extensionToValidate: string | null = null;
  @Input({ required: true }) public extensions: Array<string>;
  public fileId = 'fileUploadId' + crypto.randomUUID();


  public constructor() {
    super();
  }

  public ngOnInit(): void {
    if (this.extensions) {
      this.extensionToValidate = this.extensions.join(',');
    }
  }

  public triggerFileSelect(): void {
    const fileInput = document.getElementById(this.fileId) as HTMLInputElement;
    fileInput.addEventListener('click', (event) => {
      event.stopPropagation();
    });
    fileInput.showPicker();
  }


  public onFileChange(event: any): void {
    if (event == null || event.target == null || event.target.files == null || event.target.files.length === 0) return;
    const file = event.target.files[0];
    let validExtension = this.extensions == null || this.extensions.length === 0;
    this.extensions.forEach((extension) => {
      validExtension = validExtension || file.type.toLowerCase().startsWith(extension.toLowerCase().replace('*','').replace('.',''));
    });

    if (!validExtension) {
      this.error.emit('Invalid file type. Only ' + this.extensionToValidate + ' are allowed.');
      console.error('Invalid file type. Only '+ this.extensionToValidate +' are allowed.');
      return;
    }
    const exceededFileSize = (file.size / 1048 / 1048) > 15;

    if (exceededFileSize) {
      this.error.emit('File size exceeds maximum limit of 15MB.');
      return;
    }

    this.fileSelected.emit(file);
  }
}
