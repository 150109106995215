import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { userSettingsActions } from './user-settings-actions';
import { exhaustMap, map, tap } from 'rxjs';
import { UserSettingsApiService } from '../../../api/bizzmine/user-settings/user-settings-api.service';
import { TranslationService } from '../../../core/services/translation/translation.service';
import { appActions } from '../app/app-actions';
import { MessageTranslationService } from '../../../core/services/kendo-translations/message-translation.service';
import { MessageService } from '@progress/kendo-angular-l10n';
import { userSettingsFeature } from './user-settings-feature';
import { Store } from '@ngrx/store';

@Injectable()
export class UserSettingsEffects {

  fetchUserSettings$ = createEffect(() => this.actions$.pipe(
    ofType(userSettingsActions.fetchUserSettings),
    exhaustMap(() => this.userSettingsApiService.getUserSettings()
      .pipe(
        map(userSettings => {
          return ({ type: userSettingsActions.userSettingsFetched.type, userSettings: userSettings });}),
        ))
  ));

  fetchUserSettingsWithOverRules$ = createEffect(() => this.actions$.pipe(
    ofType(userSettingsActions.fetchUserSettingsWithOverRules),
    exhaustMap(({themeId, language}) => this.userSettingsApiService.getUserSettings()
      .pipe(
        map(userSettings => {
          userSettings.ThemesID = themeId ?? userSettings.ThemesID;
          userSettings.CompanyLanguagesID = language ?? userSettings.UserLanguagesID;
          userSettings.UserLanguagesID = language ?? userSettings.UserLanguagesID;
          return ({ type: userSettingsActions.userSettingsFetched.type, userSettings: userSettings });}),
      ))
  ));

  userSettingsLoading$ = createEffect(() => this.actions$.pipe(
    ofType(userSettingsActions.fetchUserSettings, userSettingsActions.fetchUserSettingsWithOverRules),
    map(() => ({ type: appActions.userSettingsLoading.type}))
  ));

  updateTranslations$ = createEffect(() => this.actions$.pipe(
    ofType(userSettingsActions.userSettingsFetched),
    tap(settings => {
      this.translationService.storeTranslations(new Set<number>([settings.userSettings.UserLanguagesID, settings.userSettings.CompanyLanguagesID]));
    }),
    map(() => {return ({ type: appActions.userSettingsLoaded.type})})
  ));

  setKendoTranslations$ = createEffect(() =>  this.actions$.pipe(
    ofType(appActions.translationsLoaded),
    tap(() => {
      const languageId= this.store$.selectSignal(userSettingsFeature.selectUserLanguagesID)() ?? this.store$.selectSignal(userSettingsFeature.selectCompanyLanguagesID)();
      const svc = <MessageTranslationService>this.messages;
      svc.language = languageId;
    }),
    map(() => {return ({ type: appActions.userSettingsLoaded.type})})
  ));

  public constructor(
    private actions$: Actions,
    private userSettingsApiService: UserSettingsApiService,
    private translationService: TranslationService,
    private messages: MessageService,
    private store$: Store
  ) {
  }

}
