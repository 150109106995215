import { Component, DestroyRef, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgClass } from '@angular/common';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Represents a control that allows the user to enter a hyperlink.
 * The hyperlink can be opened in a new tab with a button.
 */
@Component({
  selector: 'bizz-hyperlink-control',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, IconComponent, AsyncPipe],
  templateUrl: './hyperlink-control.component.html',
  styleUrls: ['./hyperlink-control.component.scss'],
})
export class HyperlinkControlComponent extends BaseFormControlComponent implements OnInit {
  public disableLinkButton = true;
  public formattedLink = '';
  @ViewChild('input') inputElement: ElementRef;

  public override ngOnInit(): void {
    super.ngOnInit();
    if (this.formControl) {
      this.disableLinkButton = (this.formControl.value === '');
      this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: string) => {
        // TODO: refine validation of hyperlink
        this.disableLinkButton = (value === '');
      });
    }
  }

  public navigateToLink(): void {
    const url = this.formControl.value;
    // Check if the URL is valid
    if (url && typeof url === 'string') {
      const trimmedLink = url.trim();

      if (!trimmedLink) {
        this.formattedLink = '';
        return;
      }

      const protocols = [
        'http://', 'https://', 'ftp://', 'mailto:', 'file://', 'ssh://',
        'tel:', 'sms:', 'news:', 'data:', 'irc:', 'geo:'
      ];

      const hasProtocol = protocols.some(protocol => trimmedLink.startsWith(protocol));

      if (hasProtocol) {
        this.formattedLink = trimmedLink;
        window.open(this.formattedLink, '_blank')
      } else {
        this.formattedLink = 'http://' + trimmedLink;
        window.open(this.formattedLink, '_blank')
      }
    }
  }
  protected override focus(): void {
      this.inputElement.nativeElement.focus();
  }
}