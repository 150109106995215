import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';
import { Type } from '@angular/core';
import { DynamicFormControlComponent } from '../../../../shared/interfaces/dynamic-form-control-component';
import { CollectionFormService } from '../services/collection-form.service';
import { FormListControlComponent } from '../components/controls/form-list-control/form-list-control.component';
import { TABLE_FIELD_DATA_TYPE_COMPONENTS } from '../constants/table-field-data-type-components';
import { LinkedFormControlComponent } from '../components/controls/linked-form-control/linked-form-control.component';
import { FormFieldType } from '../../../../../models/ts/form-field-type.model';
import { FormLinkedCollectionControlComponent } from '../components/controls/form-linked-collection-control/form-linked-collection-control.component';
import { ProtectedFieldType } from '../../../../../models/ts/protected-field-type.model';
import { EntityLinkedFormControlComponent } from '../components/controls/linked-form-control/entity-linked-form-control/entity-linked-form-control.component';
import { RegisteredOnBehalfOfControlComponent } from '../components/controls/registered-on-behalf-of-control/registered-on-behalf-of-control.component';
import { AlphaNumericControlComponent } from '../components/controls/alpha-numeric-control/alpha-numeric-control.component';
import { StrictlyProtectedCollectionTypes } from '../../../../shared/constants/strictly-protected-collections';

export function resolveControlComponent(field: CollectionFormField): Type<DynamicFormControlComponent> {
  // Linked Field/Grid
  if (field.FormFieldType == FormFieldType.LinkedCollection)
    return FormLinkedCollectionControlComponent;

  if (field.FormFieldType == FormFieldType.List)
    return FormListControlComponent;


  if (field.ProtectedFieldType == ProtectedFieldType.RegisteredOnBehalfOf)
    return RegisteredOnBehalfOfControlComponent;

  //Note ReversedUserCollection has a field.ComponentType of OrganizationChartUnitSelector
  //So we always need to check if the field is a grid first.
  if (CollectionFormService.fieldIsGrid(field))
    return FormListControlComponent;

  // Check for lookup fields (Lookups from protected collections default to alphanumeric (no lookups allowed)
  if (field.IsLookupField) {
    // TODO: replace with specific property on field indicating a entity lookup.
    if (field.ProtectedFieldType == ProtectedFieldType.DepartmentName) return EntityLinkedFormControlComponent;
    else return StrictlyProtectedCollectionTypes.includes(field.SourceProtectedCollectionType) ? AlphaNumericControlComponent : LinkedFormControlComponent;
  } else
    return TABLE_FIELD_DATA_TYPE_COMPONENTS[field.ComponentType];
}
