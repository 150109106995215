@if (formFieldSignal(); as formField) {
    <bizz-file-upload
            [ngClass]="{'hidden' : formField.IsHidden}"
            [collectionsId]="formField.SourceCollectionsID"
            [previewEnabled]="formField.PreviewEnabled"
            [externalAccess]="externalAccess"
            [formId]="formId"
            [field]="formField"
            [isInGrid]="isInGrid"
            [recordId]="recordId"
            [gridFieldId]="gridFieldId"
            [uploadFile$]="uploadFile$"
            (fileChanged)="fileUploaded($event)"
            [externalAccess]="externalAccess">
    </bizz-file-upload>
}
<!-- Preview Component, File Upload Component, Alles styling en buttons van controlled document -->