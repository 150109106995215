import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ToolBarToolComponent } from '@progress/kendo-angular-toolbar';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ColorPickerModule, SliderModule } from '@progress/kendo-angular-inputs';
import { ColorPickerComponent } from '@progress/kendo-angular-inputs/colorpicker/colorpicker.component';
import { IconComponent } from '../../../../../../../../shared/components/ui/icon/icon.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'bizz-custom-canvas-toolbar-colorpicker',
  standalone: true,
  imports: [
    ButtonModule,
    PopupModule,
    SliderModule,
    ColorPickerModule,
    IconComponent,
    TooltipModule
  ],
  templateUrl: './custom-canvas-toolbar-colorpicker.component.html',
  styleUrl: './custom-canvas-toolbar-colorpicker.component.scss',
  providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => CustomCanvasToolbarColorpickerComponent) }]
})
export class CustomCanvasToolbarColorpickerComponent extends ToolBarToolComponent {
  @ViewChild('toolbarTemplate', { static: true }) public override toolbarTemplate: TemplateRef<any>;
  @ViewChild('popupTemplate', { static: true }) public override popupTemplate: TemplateRef<any>;
  @Input({ required: true }) public value: string;
  @Output() public valueChange = new EventEmitter<string>();
  @ViewChild('toolbar') public toolbar: ColorPickerComponent;
  public show = false;

  public constructor() {
    super();
  }

  public onValueChange($event: string): void {
    this.value = $event;
    this.valueChange.emit(this.value);
  }

  public close() : void{
    if(this.toolbar){
      this.toolbar.toggle(false);
    }
  }

}
