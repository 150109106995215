import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy,
  DetachedRouteHandle,
  RouteReuseStrategy
} from '@angular/router';

// TODO: investigate if we can cache any routes (shouldAttach, shouldDetach).
export class BizzRouteReuseStrategy extends BaseRouteReuseStrategy {
  override shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return super.shouldDetach(route);
  }

  override store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    if (route.data['store'] && route.routeConfig?.path !== undefined) {
      if (handle !== null)
        sessionStorage.setItem(route.routeConfig.path, JSON.stringify(handle));
      else
        sessionStorage.removeItem(route.routeConfig.path);
    }
  }

  override shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  override retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const stored = sessionStorage.getItem(route.routeConfig?.path ?? '');
    if (stored) {
      return JSON.parse(stored);
    }
    return null;
  }

  // If this returns false, a route will be reloaded.
  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return (future.routeConfig === curr.routeConfig)
  }

}