@if (formFieldSignal(); as formField) {
    @if (errorState?.Message; as errorMessage) {
        <div class="absolute right-16 top-0 z-[2] flex h-full items-center justify-center">
            <bizz-tooltip class="cursor-pointer text-red-400" [text]="errorMessage"></bizz-tooltip>
        </div>
    }
    <div class="input-button-group flex" [ngClass]="{'hidden' : formField.IsHidden}" #vcr>
        <kendo-multiselect [id]="inputId" #multiSelectInput
                           class="bizz-combobox-multiselect"
                           [class.display-values]="hasCustomColors"
                           [class.focused]="isFocused"
                           [data]="filteredData"
                           textField="Caption"
                           valueField="CollectionFieldValuesID"
                           [value]="formControl.value"
                           (valueChange)="onValueChange($event)"
                           [valuePrimitive]="false"
                           [filterable]="true"
                           [clearButton]="false"
                           [listHeight]="isInGrid? 200 : 400"
                           [readonly]="readOnlyOverride || formField.IsReadOnly"
                           [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0"
                           (filterChange)="onFilterChange($event)"
                           [popupSettings]="{ appendTo: isInGrid? 'root' : multiSelectGroupRef, 
                           width: isInGrid? 'auto' : '100%' }">
            <ng-template kendoMultiSelectTagTemplate let-dataItem>
                @if (dataItem.ForegroundColor != null) {
                    <span class="text-xs" [ngStyle]="{ 'color': dataItem.ForegroundColor }">
                                    {{ dataItem.Caption }}
                                </span>
                } @else {
                    <span class="text-xs">{{ dataItem.Caption }}</span>
                }
            </ng-template>
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
                @if (dataItem.BackgroundColor != null && dataItem.ForegroundColor != null) {
                    <span class="text-nowrap rounded-full px-3 py-0.5 text-xs"
                          [ngStyle]="{
                                'background-color': dataItem.BackgroundColor,
                                'color': dataItem.ForegroundColor }">
                                    {{ dataItem.Caption }}
                                </span>
                } @else {
                    <span class="text-nowrap py-0.5 text-xs text-default"
                          [ngClass]="{ 'px-3' : hasCustomColors }">
                                    {{ dataItem.Caption }}
                                </span>
                }

            </ng-template>
        </kendo-multiselect>
        <div class="button-group">
            <button type="button"
                    [disabled]="readOnlyOverride || formField.IsReadOnly"
                    [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0"
                    class="default !px-2 !py-1"
                    (click)="multiSelectInput.toggle()">
                <bizz-toggle-arrow class="size-4" [direction]="multiSelectInput.isOpen ? 'up' : 'down'">
                </bizz-toggle-arrow>
            </button>
        </div>
    </div>
}
