import { Pipe, PipeTransform } from '@angular/core';

const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];

/**
 * Pipe to transform a file size in bytes into a string representation with appropriate suffix (kB, MB, ...).
 */
@Pipe({
  name: 'bytes',
  standalone: true,
})
export class BytesPipe implements PipeTransform {

  /**
   * Transforms a file size in bytes into a string representation with appropriate suffix (kB, MB, ...).
   * @param value
   */
  public transform(value: number): string {
    if (value > 0 && isFinite(value)) {
      const number = Math.floor(Math.log(value) / Math.log(1024));
      return (value / Math.pow(1024, Math.floor(number))).toFixed(2) + ' ' + units[number];
    } else {
      return '-';
    }
  }

}
