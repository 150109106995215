<div class="modal-container modal-xs justify-between md:justify-normal">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="dialogRef.close(false)"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body">
        <div class="model-header">
            <h1 class="text-title mb-5 text-center font-title text-xl font-bold">
                {{'WarningUnsavedChanges' | translate}}
            </h1>
        </div>
        <hr class="my-5"/>
        <div class="flex items-center gap-4 rounded bg-blue-500/20 p-4">
            <bizz-icon class="h-8 w-8 flex-shrink-0 text-blue-500"
                       iconName="circle-info">
            </bizz-icon>
            <p class="text-blue-500">
                {{ 'WarningTextUnsavedChanges' | translate }}
            </p>
        </div>
    </div>
    <div class="modal-buttons">
        <button class="cancel" (click)="dialogRef.close(false)">
            {{ 'Cancel' | translate }}
        </button>
        <button class="delete" (click)="dialogRef.close(true)">
            {{ 'DiscardChanges' | translate }}
        </button>
    </div>
</div>
