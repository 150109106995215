import { TaskPopUpDto } from '../../../../models/ts/task-pop-up-dto.model';
import { GridSearchDto } from '../../../../models/ts/grid-search-dto.model';

export type HeaderTasksState = {
  tasks: TaskPopUpDto[],
  count: number,
  loading: boolean;
  search: string;
}

export const initialHeaderTasksStoreState: HeaderTasksState = {
  tasks: [],
  count: 0,
  loading: false,
  search: ''
};

