import * as go from 'gojs';
import { Diagram, Group, Iterable, Part } from 'gojs';
import { Observable } from 'rxjs';
import { FlowDiagram } from './flow-diagram';

/**
 * The PoolLayout class is taken from AngularJS code.
 * I suspect it's used for the swimlanes in the flow status diagram.
 * Found similar code in the samples of goJS:
 * https://github.com/NorthwoodsSoftware/GoJS/blob/master/samples/swimLanes.html
 * TODO: RV Further investigate this code.
 */
export class PoolLayout extends go.Layout {
  //TODO:RV implement poollayout

  private readonly minSwimlaneHeight: number = 0;
  private maxSwimlaneWidth: number = 0;

  public constructor(minSwimlaneHeight: number, maxSwimlaneWidthObservable: Observable<number>) {
    super();
    this.minSwimlaneHeight = minSwimlaneHeight;
    maxSwimlaneWidthObservable.subscribe((maxSwimlaneWidth: number) => {
      this.maxSwimlaneWidth = maxSwimlaneWidth;
    });
  }

  public override doLayout(coll: Diagram | Group | Iterable<Part>): void {
    if (this.diagram === null)
      return;

    this.diagram.startTransaction('PoolLayout');

    // make sure all of the Group Shapes are big enough
    const minSwimLaneWidth = FlowDiagram.computeMinSwimLaneWidth(this.diagram);
  
    this.diagram.findTopLevelGroups().each(group => {
      const shape = group.findObject('SHAPE');
      if (shape !== null) {
        const laneSize = FlowDiagram.computeLaneSize(group, this.maxSwimlaneWidth, this.minSwimlaneHeight);
        shape.width = (isNaN(shape.width) || shape.width == 0 ? minSwimLaneWidth : Math.max(shape.width, minSwimLaneWidth));
        shape.height = (isNaN(shape.height) || shape.height == 0 ? laneSize.height : Math.max(shape.height, laneSize.height));
      }
    });

    // now position all of the Groups
    let z = 0;
    this.diagram.findTopLevelGroups().each((group) => {
      const groupHeight = group.actualBounds.height;
      const groupBounds = group.actualBounds;
      if (groupBounds.isReal()) {
        const offset = new go.Point(0, z - group.actualBounds.y - group.locationSpot.offsetY);
        this.diagram?.moveParts(group.memberParts, offset, false);
      }
      group.location = new go.Point(0, z);
      z += groupHeight;
    });


    this.diagram.commitTransaction('PoolLayout');
    //super.doLayout(coll);
  }
}
