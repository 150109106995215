import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AutofocusService {
  public autoFocusedElements$: BehaviorSubject< {formId: string, formFieldId: number} | null> = new BehaviorSubject<{formId: string, formFieldId: number} | null>(null);

  setElementFocus(formId: string, formFieldId: number): void {
    this.autoFocusedElements$.next({formId, formFieldId})
  }

}
