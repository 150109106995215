export enum Claims {
  // core oidc claims
  UserID = 'sub',
  UserName = 'username',
  UserFirstName = 'givenname',
  UserLastName = 'surname',
  IdentityProvider = 'idp',

  // more standard claims
  UpdatedAt = 'updated_at',
  Nonce = 'nonce',

  // more claims
  ClientId = 'client_id',
  Tenant = 'tenant',
  TenantWebUrl = 'tweburl',
  Product = 'product',
  Scope = 'scope',
  Id = 'id',
  Secret = 'secret',
  Role = 'role',
  IsExternalOidcUser = 'extoidc',

  OrganizationChartType = 'orgchart_type',

  // TODO: BV get from settings
  ThemesID = 'bizz_utid',
  // UserLanguagesID = 'bizz_ulid',
  // CompanyLanguagesID = 'bizz_clid',
  UserType = 'bizz_utype',
  DefaultWorkspacesID = 'bizz_dwid',

  // claims for authentication controller partial logins
  IsExternalUser = 'isextuser',
  ExternalTasksID = 'etid',
  ExternalIdToken = 'eidtoken',
}
