import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  filter,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs';
import { AsyncPipe, CommonModule, JsonPipe } from '@angular/common';
import { TrnExamDto } from '../../../../models/ts/trn-exam-dto.model';
import { TranslatePipe } from '../../../shared/pipes/translate/translate.pipe';
import { HeaderComponent } from '../../../shared/components/ui/header/header.component';
import { IconComponent } from '../../../shared/components/ui/icon/icon.component';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { TrnExamQuestion } from '../../../../models/ts/trn-exam-question.model';
import { LoaderComponent } from '../../../shared/components/ui/loader/loader.component';
import { BizzMineLocalStorageService } from '../../../shared/services/localStorage/bizzmine-local-storage.service';
import { Store } from '@ngrx/store';
import { userSettingsActions } from '../../../store/features/user-settings/user-settings-actions';
import { HttpErrorResponse } from '@angular/common/http';
import { TrainingApiService } from '../../../api/bizzmine/training/training-api.service';
import { LoaderButtonComponent } from '../../../shared/components/loader-button/loader-button/loader-button.component';
import { TrainingExamCoverContentComponent } from './content-types/cover-page/training-exam-cover-content/training-exam-cover-content.component';
import { TrainingExamQuestionContentComponent } from './content-types/question-page/training-exam-question-content/training-exam-question-content.component';
import { TrainingExamResultContentComponent } from './content-types/result-page/training-exam-result-content/training-exam-result-content.component';
import { TrainingExamReportContentComponent } from './content-types/report-page/training-exam-report-content/training-exam-report-content.component';
import { StepperActivateEvent, StepperComponent, StepperModule } from '@progress/kendo-angular-layout';

enum TrainingExamPageComponentPageContext {
  COVER_PAGE = 0,
  QUESTION_PAGE = 1,
  RESULT_PAGE = 2,
  REPORT_PAGE = 3,
}

@Component({
  selector: 'bizz-training-exam-page',
  standalone: true,
  imports: [
    AsyncPipe,
    JsonPipe,
    TranslatePipe,
    HeaderComponent,
    IconComponent,
    StepperModule,
    CommonModule,
    LoaderComponent,
    LoaderButtonComponent,
    TrainingExamCoverContentComponent,
    TrainingExamQuestionContentComponent,
    TrainingExamResultContentComponent,
    TrainingExamReportContentComponent
  ],
  templateUrl: './training-exam-page.component.html',
  styleUrl: './training-exam-page.component.scss'
})

export class TrainingExamPageComponent {
  public unAuthorised$ = new BehaviorSubject(false);
  public pageConfig$ = new BehaviorSubject<{
    pageContext: TrainingExamPageComponentPageContext,
    showTimeline: boolean
  }>({
    pageContext: TrainingExamPageComponentPageContext.COVER_PAGE,
    showTimeline: false
  });


  public TrainingExamPageComponentPageContext = TrainingExamPageComponentPageContext;

  public isLoading$ = this.trainingApiService.isLoading$;
  public init$ = new BehaviorSubject(true);
  private ExamSessionVersionsID_routingParam = 'examSessionVersionsID';
  private TrainingAppID_routingParam = 'trainingAppID';

  private request$ = new BehaviorSubject<{
    trainingAppID: number,
    versionsID: number,
    createIfNonExist: boolean
  } | undefined>(undefined);

  public exam$ = this.request$.pipe(
    filter(request => request != null),
    tap(() => this.unAuthorised$.next(false)),
    switchMap(request => this.trainingApiService.getExam(request!)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.init$.next(false);
        if (error.status == 403) {
          this.unAuthorised$.next(true);
        }
        throw error;
      }))), tap(found => {
      this.applyPageContext(found);
    }), shareReplay(1));

  public questionsArray$ = this.exam$.pipe(
    map(exam => exam ? Array.from({length: (exam.TotalSessionQuestions == 1 ? 2 :exam.TotalSessionQuestions) }, (_, i) => i + 1) : []), 
    startWith([])
  );

  // will return null if unAuthUser -> will return query param when valid user
  private params$ = this.activatedRoute.params.pipe(
    take(1),
    switchMap(params => {
      if (params) {
        return of({
          trainingAppID: params[this.TrainingAppID_routingParam],
          versionsID: params[this.ExamSessionVersionsID_routingParam]
        });
      }
      return of(undefined);
    }));

  public constructor(private trainingApiService: TrainingApiService,
                     private activatedRoute: ActivatedRoute,
                     private localStorage: BizzMineLocalStorageService,
                     private store$: Store) {
    this.store$.dispatch(userSettingsActions.fetchUserSettings());

    // singular call to find out if we are authenticed -> if so propegate query params -> if not end stream
    this.params$.pipe(take(1))
      .subscribe(params => {
        // will trigger exam Fetch
        this.request$.next({
          createIfNonExist: false,
          trainingAppID: params?.trainingAppID,
          versionsID: params?.versionsID
        });
        this.localStorage.set('request$', JSON.stringify(this.request$.value));
      });
  }

  public startExam(): void {
    const foundRequest = this.request$.value;
    this.request$.next({
      trainingAppID: foundRequest!.trainingAppID,
      versionsID: foundRequest!.versionsID,
      createIfNonExist: true
    });
    this.pageConfig$.next({
      pageContext: TrainingExamPageComponentPageContext.QUESTION_PAGE,
      showTimeline: true
    });
  }

  public nextQuestion(intent: TrnExamQuestion): void {
    this.trainingApiService.commitQuestionWithAnswer({
      question: intent,
      trainingAppID: this.request$.value?.trainingAppID ?? 0,
      versionsID: this.request$.value?.versionsID ?? 0
    }).pipe(take(1))
      .subscribe(() => this.request$.next(this.request$.value));
  }

  public viewReport(): void {
    this.pageConfig$.next({
      pageContext: TrainingExamPageComponentPageContext.REPORT_PAGE,
      showTimeline: false
    });
  }

  public viewResult(): void {
    this.pageConfig$.next({
      pageContext: TrainingExamPageComponentPageContext.RESULT_PAGE,
      showTimeline: false
    });
  }

  // Prevent user from skipping questions
  public onStepActivate(e: StepperActivateEvent): void {
    e.preventDefault();
  }

  private applyPageContext(data: TrnExamDto): void {
    if (data.TotalSessionQuestions > 0) {
      if (data.Progress <= data.TotalSessionQuestions) {
        this.pageConfig$.next({
          pageContext: TrainingExamPageComponentPageContext.QUESTION_PAGE,
          showTimeline: true
        });
      } else {
        this.pageConfig$.next({
          pageContext: TrainingExamPageComponentPageContext.RESULT_PAGE,
          showTimeline: false
        });
      }
    }
    this.init$.next(false);
  }
}