<div class="flex flex-col justify-center gap-4" [class.hidden]="!hasTwoFactorSecret">
    <label class="self-center">{{ "TwoFactorCode" | translate }} <span
            class="text-danger">*</span></label>
    <div class="flex justify-center gap-4 sm:[&>input]:size-8 [&>input]:size-10 [&>input]:content-center [&>input]:text-center">
        <input class="w-7" #input1 type="text" maxlength="1" inputmode="numeric" pattern="0-9" [(ngModel)]="tokenSegments[0]"
               (keyup)="updateToken(0, $event)"
               (paste)="onPaste($event)" placeholder="1">
        <input class="w-7" #input2 type="text" maxlength="1" inputmode="numeric" pattern="0-9" [(ngModel)]="tokenSegments[1]"
               placeholder="2"
               (keyup)="updateToken(1, $event)">
        <input class="w-7" #input3 type="text" maxlength="1" inputmode="numeric" pattern="0-9" [(ngModel)]="tokenSegments[2]"
               (paste)="onPaste($event)" placeholder="3"
               (keyup)="updateToken(2, $event)">
        <div class="w-1"></div>
        <input class="w-7" #input4 type="text" maxlength="1" inputmode="numeric" pattern="0-9" [(ngModel)]="tokenSegments[3]"
               (paste)="onPaste($event)" placeholder="4"
               (keyup)="updateToken(3, $event)">
        <input class="w-7" #input5 type="text" maxlength="1" inputmode="numeric" pattern="0-9" [(ngModel)]="tokenSegments[4]"
               (paste)="onPaste($event)" placeholder="5"
               (keyup)="updateToken(4, $event)">
        <input class="w-7" #input6 type="text" maxlength="1" inputmode="numeric" pattern="0-9" [(ngModel)]="tokenSegments[5]"
               (paste)="onPaste($event)" placeholder="6"
               (keyup)="updateToken(5, $event)">
    </div>
</div>