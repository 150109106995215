import { Component } from '@angular/core';
import { ExtensionDisplayType } from '../../../../../../models/ts/extension-display-type.model';
import { NgIf } from '@angular/common';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { InstanceStatusType } from '../../../../../../models/ts/instance-status-type.model';
import { ExtensionIconComponent } from '../../../ui/icon/extension-icon/extension-icon.component';

/**
 * Cell containing a file extension icon and text
 * Can contain both or either.
 */
@Component({
  selector: 'bizz-grid-cell-extension',
  templateUrl: './grid-cell-extension.component.html',
  styleUrls: ['./grid-cell-extension.component.scss'],
  imports: [
    ExtensionIconComponent,
    NgIf
  ],
  standalone: true
})
export class GridCellExtensionComponent extends GridCellBaseComponent<InstanceStatusType> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly ExtensionDisplayType = ExtensionDisplayType;
}
