import { Component, Signal, WritableSignal, computed, effect, signal } from '@angular/core';
import { AlertService } from 'src/app/features/bizzmine/alerts/alert.service';
import { AlertPopupComponent } from 'src/app/features/bizzmine/alerts/components/alert-popup/alert-popup.component';
import { IconComponent } from '../icon/icon.component';
import { AlertOptions } from 'src/app/features/bizzmine/alerts/classes/alert-options';
import { asyncScheduler, of } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';

@Component({
  selector: 'bizz-stacked-alerts',
  standalone: true,
  imports: [AlertPopupComponent, IconComponent, NgScrollbar, CommonModule, TranslatePipe
  ],
  templateUrl: './stacked-alerts.component.html',
  styleUrl: './stacked-alerts.component.scss'
})
export class StackedAlertsComponent {
  public alerts = this.alertService.alerts();
  public groupedAlerts: any;
  public alertTypesExpanded: { [key: string]: boolean } = {
    success: false,
    error: false,
    warning: false,
    info: false,
  }
  constructor(public alertService: AlertService) {

    this.groupedAlerts = computed(() => {
      return this.alertService.alerts().reduce((acc, alert) => {
          if(!alert.type) alert.type = 'info'; 
          const alertGroup = acc.find((group) => group.type === alert.type);
          if (!alertGroup) {
            acc.push({type: alert.type, alerts:[alert]});
          }else{
            alertGroup?.alerts.unshift(alert);
            const index = acc.indexOf(alertGroup!);
            acc.splice(index, 1);
            acc.push(alertGroup!);
          }
          if (alert.timed) {
            asyncScheduler.schedule(() => {
              this.dismissAlert(alert.id!);
            }, alert.timer);
          }
          return acc;
      }, [] as unknown as [{type: string, alerts: AlertOptions[]}]);
    })
  }


  public toggleDetailsExpanded(alertType: 'success' | 'error' | 'warning' | 'info'):void{
    this.alertTypesExpanded[alertType] = !this.alertTypesExpanded[alertType];
  }

  public dismissAlert(alertId: string): void {
    this.alertService.dismissAlert(alertId);
  }
  public dismissAlerts(alertType: 'success' | 'error' | 'warning' | 'info'): void {
    this.alertService.dismissAlertType(alertType);
    this.alertTypesExpanded[alertType] = false;
  }
}
