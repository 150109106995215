<div class="bg-form rounded border border-form-control sm:rounded-b w-full">
    <div class="h-1 w-full sm:h-2.5 sm:rounded-t {{config.formHeaderBgClass}} animate-pulse"></div>
    <div class="mx-4 my-3 flex flex-col sm:mx-5 sm:my-1.5 sm:rounded-t animate-pulse">
        <div class="flex cursor-pointer items-center gap-4 sm:gap-2 rounded grid grid-cols-1 lg:grid-cols-4 min-h-6 animate-pulse">
            <div class="col-span-1 flex items-center gap-2 animate-pulse">
                <bizz-icon class="h-5 w-5 text-lighter/75 sm:h-4 sm:w-4 animate-pulse"
                           iconName="{{(config.elements.length > 0 ? 'caret-down' : 'caret-right')}}"></bizz-icon>

                <div class="h-2 w-full {{config.bgElementClass}} animate-pulse rounded"></div>
            </div>

        </div>
        @if(config.elements.length > 0){
            <hr class="mb-2 mt-1 hidden sm:mb-0 sm:inline-block">
        }



        <div class="p-4 sm:pt-1.5 sm:pb-3 sm:px-5 gap-y-2 gap-x-4 grid grid-cols-1 lg:grid-cols-4 animate-pulse">
            @for (element of config.elements; track $index) {
                <bizz-view-stack-skeleton-element [bgClass]="config.bgElementClass"
                                                  [borderClass]="config.borderClass"
                                                  [customHeightClass]="element.customHeightClass ?? 'h-9'"
                                                  class="col-span-1 lg:col-span-{{element.colSpan}}"></bizz-view-stack-skeleton-element>
            }

        </div>
    </div>
</div>