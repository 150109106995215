import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bizz-file-select-multi',
  standalone: true,
  imports: [],
  templateUrl: './file-select-multi.component.html',
  styleUrl: './file-select-multi.component.scss'
})
export class FileSelectMultiComponent implements OnInit{

  public customId = 'fileUpload_'+crypto.randomUUID().toString();
  @Output() public filesSelected = new EventEmitter<Array<File>>();
  @Input() public extensions: Array<string>;
  public extensionToValidate: string | null = null;
  @Output() public error = new EventEmitter<string>();

  public ngOnInit(): void {
    if (this.extensions) {
      this.extensionToValidate = this.extensions.join(',');
    }
  }

  public openFileSelect(): void {
    const fileInput = document.getElementById(this.customId) as HTMLInputElement;
    fileInput.addEventListener('click', (event) => {
      event.stopPropagation();
    });
    fileInput.showPicker();
  }

  public onFileSelected(event: any): void {
    if (event == null || event.target == null || event.target.files == null || event.target.files.length === 0) return;
    const fileList = event.target.files as FileList;
    const files: Array<File> = [];
    for (let i = fileList.length - 1; i >= 0; i--) {
      if (!this.validateExtension(fileList[i])) {
        console.error('Invalid file type. Only '+ this.extensionToValidate +' are allowed.');
        this.error.emit('Invalid file type. Only ' + this.extensionToValidate + ' are allowed.');
        return;
      }
      files.push(fileList[i]);
    }
    this.filesSelected.emit(files);
    const fileInput = document.getElementById(this.customId) as HTMLInputElement;
    fileInput.value = '';
  }

  public validateExtension(file: File): boolean {
    let validExtension = true;
    if(this.extensions == null || this.extensions.length <= 0) return validExtension;
    this.extensions.forEach((extension) => {
      validExtension = validExtension && file.type.toLowerCase().startsWith(extension.toLowerCase().replace('*','').replace('.',''));
    });
    return validExtension;
  }
}
