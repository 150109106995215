import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { GridCellComponent } from '../grid-cell/grid-cell.component';
import { SafePipe } from 'safe-pipe';
import { GridColumnType } from '../../../../../../models/ts/grid-column-type.model';
import { ListDesignGridOptionsDto } from '../../../../../../models/ts/list-design-grid-options-dto.model';
import { CollectionType } from '../../../../../../models/ts/collection-type.model';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { StatusIconComponent } from '../../../ui/status-icon/status-icon.component';
import { GridColumnBase } from 'src/app/shared/classes/list/grid-column-base';

@Component({
  selector: 'bizz-grid-mobile-cell',
  standalone: true,
  imports: [CommonModule, StatusIconComponent, TranslatePipe, GridCellComponent, SafePipe],
  templateUrl: './grid-mobile-cell.component.html',
  styleUrls: ['./grid-mobile-cell.component.scss']
})
export class GridMobileCellComponent extends GridCellBaseComponent<string> {

  /**
   * Required for permissions.
   */
  @Input() public listOptions: ListDesignGridOptionsDto;
  @Input() public trashFilterActive: boolean;
  @Input() public collectionType: CollectionType;
  @Input() public isDocumentTreeGrid = false;

  @Input({ required: true }) public gridColumns: GridColumnBase[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GridColumnType = GridColumnType;
}
