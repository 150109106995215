import { inject, Injectable } from '@angular/core';

import {
  ScriptingConditionsValidator,
  ScriptingConditionsValidatorConfig
} from '../../classes/scripting/scripting-conditions-validator';
import { FormScriptingActionsService } from './form-scripting-actions.service';
import { CollectionFormService } from '../collection-form.service';
import { AuthService } from '../../../../../core/services/auth/auth.service';
import { CollectionForm } from '../../../../../../models/ts/collection-form.model';
import { FormScriptingField } from '../../classes/form-scripting-field';
import { FormScriptingFieldType } from '../../../../../shared/enums/form-scripting-field-type.enum';
import { CollectionFormRow } from '../../../../../../models/ts/collection-form-row.model';
import { CollectionFormAccordion } from '../../../../../../models/ts/collection-form-accordion.model';
import { CollectionFormField } from '../../../../../../models/ts/collection-form-field.model';
import { FormFieldType } from '../../../../../../models/ts/form-field-type.model';
import { EvaluationDto } from '../../../../../../models/ts/evaluation-dto.model';
import { Claims } from '../../../../../core/constants/claims';
import { Store } from '@ngrx/store';
import { EvaluationsOnLoadOfForm } from '../../../../../../models/ts/evaluations-on-load-of-form.model';

/**
 * Service providing functions to perform scripting on a CollectionFormField.
 * @see ScriptingConditionsValidator
 */
@Injectable({
  providedIn: 'root'
})
export class FormScriptingService {

  private store$ = inject(Store);
  private authService = inject(AuthService);

  public static hasScripting(field: CollectionFormField): boolean {
    return field.Evaluations.length > 0;
  }

  public static hasOnOpenScripting(field: CollectionFormField): boolean {
    return field.Evaluations.findIndex(evaluation => evaluation.OnLoadOfForm == EvaluationsOnLoadOfForm.Yes) != -1;
  }

  public static getOnOpenScripts(form: CollectionForm): EvaluationDto[] {
    const fields = CollectionFormService.getFields(form, field => FormScriptingService.hasOnOpenScripting(field));
    let scripts: EvaluationDto[] = [];
    fields.forEach(field => scripts = scripts.concat(field.Evaluations));
    return scripts;
  }

  // TODO: GL fix, can't find fields
  /**
   * Gets a field from a CollectionForm as a FormScriptingField.
   * @param data
   * @param fieldID
   * @param viewDataSourcesId
   */
  public static getFormScriptingField(data: CollectionForm, fieldId: number, viewDataSourcesId: number): FormScriptingField | undefined {
    for (const accordion of data.Accordions) {
      for (const row of accordion.Rows) {
        for (const container of row.Containers) {
          const field = container.Fields.find(field =>
            FormScriptingService.isScriptingField(field, fieldId, viewDataSourcesId) ||
            FormScriptingService.isScriptingGrid(field, viewDataSourcesId)
          );
          if (field) {
            return {
              field: field,
              accordion: accordion,
              row: row,
              type: field.IsGridField ? FormScriptingFieldType.Grid : FormScriptingFieldType.Field
            };
          }
        }
      }
    }
    return undefined;
  }

  private static isScriptingGrid(field: CollectionFormField, vdsId: number): boolean {
    return (field.DataDesignViewDataSourcesID == vdsId && (field.FormFieldType == FormFieldType.LinkedCollection || field.FormFieldType == FormFieldType.List));
  }

  private static isScriptingField(field: CollectionFormField, fieldId: number, vdsId: number): boolean {
    return (field.CollectionFieldsID == fieldId || (field.SourceCollectionFieldsID > 0 && field.SourceCollectionFieldsID == fieldId)) && (vdsId == -1 || field.DataDesignViewDataSourcesID == vdsId);
  }

  /**
   * Hides a row if all fields in the row are hidden.
   * @param row
   */
  private static hideCompleteRow(row: CollectionFormRow): void {
    const fields = CollectionFormService.extractFieldsFromRow(row);
    // row is hidden if no visible field is found
    row.IsHidden = fields.findIndex(f => !this.isHiddenField(f)) == -1;
  }

  /**
   * Hides an accordion if all fields in the accordion are hidden.
   * @param accordion
   */
  private static hideCompleteAccordion(accordion: CollectionFormAccordion): void {
    const fields = CollectionFormService.extractFieldsFromAccordion(accordion);
    accordion.IsHidden = fields.findIndex(f => !this.isHiddenField(f)) == -1;
  }

  /**
   * Checks if a field is hidden.
   * Returns true for FormFieldTypes that can't be hidden.
   * @param field
   */
  private static isHiddenField(field: CollectionFormField): boolean {
    return field.IsHidden
      || field.FormFieldType == FormFieldType.TitleBar
      || field.FormFieldType == FormFieldType.EmptyField
      || field.FormFieldType == FormFieldType.Text
      || field.FormFieldType == FormFieldType.Button
      || field.FormFieldType == FormFieldType.ImageComponent;
  }

  public performOnOpenScripting(form: CollectionForm): CollectionForm {
    return this.performScripting(form, FormScriptingService.getOnOpenScripts(form));
  }

  /**
   * Performs the scripting(Evaluations) on a form and returns the updated form.
   * Loops through all the scripting and performs the actions according to the conditions being OK or not.
   * @param form
   * @param scripting
   */
  public performScripting(form: CollectionForm, scripting: EvaluationDto[]): CollectionForm {
    if (scripting.length > 0) {
      const conditionsValidator = new ScriptingConditionsValidator();
      const userId = this.authService.getAccessTokenClaim(Claims.UserID) as number;

      scripting.forEach(evaluation => {
        conditionsValidator.configuration = new ScriptingConditionsValidatorConfig(form, evaluation.Conditions, userId);
        const isValidCondition = conditionsValidator.validateConditions();

        const actions = isValidCondition ? evaluation.Actions.ActionsWhenOk : evaluation.Actions.ActionsWhenNotOk;
        actions.forEach(action => {
          const scriptingField = FormScriptingService.getFormScriptingField(form, action.FieldsID, action.ViewDataSourcesID);
          if (scriptingField) {
            FormScriptingActionsService.executeAction(form, scriptingField, action.Values);
            FormScriptingService.hideCompleteRow(scriptingField.row);
            FormScriptingService.hideCompleteAccordion(scriptingField.accordion);
          } else {
            console.log(action);
            console.error('Field \'' + action.Caption + '\' not present on the form so could not perform the action');
          }
        });
      });
    }
    return form;
  }

}
