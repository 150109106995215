<div class="modal-container justify-between md:justify-normal">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="dialogRef.close()"
                   [iconName]="'fa-xmark'" [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1>
                {{"ResetTwoFactorAuthenticationDevice" | translate }}</h1>
            <p class="text-center text-sm font-normal">
                {{"TwoFactorSetup_Warning4" | translate }}
            </p>
        </div>
        <hr class="my-5" />
        <div class="modal-buttons">
            <button class="default" (click)="dialogRef.close(false)">{{'Cancel' | translate }}</button>
            <button class="save min-w-16" (click)="resetTwoFa()">{{'Ok' | translate}}></button>
        </div>
    </div>
</div>