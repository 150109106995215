import { DestroyRef, inject } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, exhaustMap, filter, shareReplay, Subscription, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Actions, ofType } from '@ngrx/effects';
import { RefreshProperties, refreshActions } from 'src/app/store/features/refresh/refresh-actions';

export class WidgetRefreshListener {
  protected destroyRef = inject(DestroyRef);

  private filterRefreshById$ = new BehaviorSubject<number | undefined>(undefined);
  private refreshFunction: (props: RefreshProperties) => void;
  protected actions$ = inject(Actions);
  private refreshObservable$ = this.filterRefreshById$
    .pipe(takeUntilDestroyed(this.destroyRef),
      filter(value => value != null && value > 0),
      distinctUntilChanged(),
      exhaustMap(value => this.actions$.pipe(ofType(refreshActions.refreshData))),
      shareReplay(1));
  private refreshSubscription: Subscription | undefined;

  public startListeningForRefreshInvoke(value:number, refreshFunc: (props: RefreshProperties) => void): void {
    if(value == null || value <= 0 || refreshFunc == null) {
      throw new Error('CollectionId and refreshFunc must be provided');
    }
    if(this.refreshSubscription != null){
      this.refreshSubscription.unsubscribe();
    }
    this.refreshFunction = refreshFunc;
    this.refreshSubscription = this.refreshObservable$.subscribe((props) => this.refreshFunction(props));
    this.filterRefreshById$.next(value);
  }
}
