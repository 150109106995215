import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserSettingsApiService } from '../user-settings-api.service';
import { UserSettingsIndexDto } from '../../../../../models/ts/user-settings-index-dto.model';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsIndexResolver  {

  constructor(private userSettingsApiService: UserSettingsApiService) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserSettingsIndexDto> {
    return this.userSettingsApiService.getUserSettingsIndex();
  }
}
