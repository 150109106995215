<!-- <bizz-alert [options]="alert" (dismissed)="alertService.dismissAlert($index)">
</bizz-alert> -->

<div class="flex flex-col gap-5">

    @for(item of groupedAlerts(); track $index){
    <div class="w-full bg-body-popup sm:rounded">
        <dialog
            class="bg-body-popup flex sm:rounded relative bizz-alert {{item.type}} w-full sm:w-[600px] max-h-[97vh] sm:shadow">
            <div class="alert-border hidden w-3 flex-shrink-0 rounded-l sm:block">
            </div>

            @if(item.alerts[0].dismissable){
            <div class="absolute right-3.5 top-3.5">
                <bizz-icon class="block h-4 w-4 text-button" (click)="dismissAlerts(item.type)" iconName="xmark"
                    [hover]="true">
                </bizz-icon>
            </div>
            }
            <div class="mt-6 flex w-full flex-col justify-center gap-4 p-4">
                <div class="grid grid-cols-[50px_auto]">
                    @if (item.alerts[0].icon) {
                    <bizz-icon class="alert-icon mt-2 size-8" [iconName]="item.alerts[0].icon"></bizz-icon>
                    }
                    <div>
                        @if (item.alerts[0].title) {
                            <span class="mb-3 font-bold">
                                {{ item.alerts[0].title }}
                            </span>
                        }
                        <ng-scrollbar>
                            <div class="overflow-y-auto" [ngClass]="item.alerts.length>1? 'max-h-24':'max-h-80'">
                                @for (section of item.alerts[0].content; track $index) {
                                <p class="text-sm" [style.overflow-wrap]="'anywhere'">
                                    {{ section }}
                                </p>
                                }
                            </div>
                        </ng-scrollbar>
                    </div>
                </div>
               

                <div class="flex flex-col gap-2 overflow-hidden">

                    @if (item.alerts.length > 1) {
                    <div class="flex items-center justify-between">
                        <div class="mt-auto flex cursor-pointer items-center gap-4 font-medium"
                            (click)="toggleDetailsExpanded(item.type)">
                            <bizz-icon class="size-4"
                                [iconName]="alertTypesExpanded[item.type] ? 'chevron-up' : 'chevron-down'"></bizz-icon>
                            <span class="text-xs">{{ (alertTypesExpanded[item.type] ? "HideMore" : "ShowMore") | translate}}</span>
                        </div>
                        <span
                            class="alert-counter flex h-6 w-6 items-center justify-center rounded-full text-xs text-white">
                            {{item.alerts.length - 1 }}
                        </span>
                    </div>
                    }

                    @if (alertTypesExpanded[item.type]) {
                    <ng-scrollbar>
                        <div class="flex flex-col gap-2.5 overflow-y-auto">
                            @for (alert of item.alerts; track $index) {
                            @if($index !== 0){
                            <div class="flex gap-4 sm:rounded p-4 bizz-alert {{item.type}}">
                                <bizz-icon class="alert-icon size-6 shrink-0" [iconName]="alert.icon"></bizz-icon>
                                <div>
                                    <p class="mb-1 font-bold">
                                        {{ alert.title }}
                                    </p>
                              
                                            @for (section of alert.content; track $index) {
                                            <p class="text-xs" [style.overflow-wrap]="'anywhere'">
                                                {{ section }}
                                            </p>
                                            }
                                </div>
                              
                            </div>
                            }
                            }
                        </div>

                    </ng-scrollbar>
                    }

                </div>
            </div>
        </dialog>
    </div>

    }
</div>

<!-- @for (alert of alertService.alerts(); track $index) {
<bizz-alert [options]="alert" (dismissed)="alertService.clearAlerts()">
</bizz-alert>
} -->