import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ProtectedFieldType } from '../../../../../../../../models/ts/protected-field-type.model';
import { TrnSkillDto } from '../../../../../../../../models/ts/trn-skill-dto.model';
import { IconComponent } from '../../../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../../../shared/pipes/translate/translate.pipe';

@Component({
  selector: 'bizz-skill-mismatch-dialog',
  standalone: true,
  imports: [
    IconComponent,
    TranslatePipe
  ],
  templateUrl: './skill-mismatch-dialog.component.html',
  styleUrl: './skill-mismatch-dialog.component.scss'
})
export class SkillMismatchDialogComponent {
  public exams: Array<TrnSkillDto> = [];
  public trainings: Array<TrnSkillDto> = [];
  public constructor(@Inject(DIALOG_DATA) public data: {lookupMismatches: Array<{Key: ProtectedFieldType, Value: TrnSkillDto}> },
                     public dialogRef: DialogRef) {
    this.exams  = data.lookupMismatches.filter(lm => lm.Key == ProtectedFieldType.ExamName)?.flatMap(lm => lm.Value);
    this.trainings  = data.lookupMismatches.filter(lm => lm.Key == ProtectedFieldType.TrainingName)?.flatMap(lm => lm.Value);
  }
}
