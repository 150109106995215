<!-- TODO: BV check if legacy has validation messages -->

<!-- User Settings Header -->
<section class="flex shrink-0 items-center justify-between border-b-2 border-sidebar bg-page-header !py-3 pl-5 pr-5">
    <button (click)="goBack()" class="default form-header-button">
        <bizz-icon iconName="caret-left" class="size-4"></bizz-icon>
        {{ 'Back' | translate }}
    </button>

    <h3 class="font-bold text-default">{{ 'ProfileSettings' | translate }}</h3>

    <button class="save form-header-button" type="submit" form="settings">
        <bizz-icon iconName="circle-check" class="size-4"></bizz-icon>
        {{ 'Save' | translate }}
    </button>
</section>

<!-- User Settings Form -->
<div class="grow overflow-y-auto sm:rounded-lg sm:p-5">
    <form class="flex h-auto flex-col bg-form p-5 sm:rounded-lg sm:px-12 sm:py-8 xl:px-28"
          [formGroup]="userForm" (ngSubmit)="submitForm()" id="settings" *ngIf="userSettingsSignal()">
        <fieldset class="grid w-full grid-cols-1 items-center gap-y-2 lg:grid-cols-4 lg:gap-2.5">
            <!-- UserName block -->
            @if (userSettings.UserFirstName) {
                <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2"
                       for="User">{{ 'User' | translate }}</label>
                <input class="col-span-full cursor-default lg:col-span-2"
                       [value]="userSettings.UserFirstName + ' ' + userSettings.UserLastName" id="User" type="text"
                       readonly>
            }
            @if (userSettings.UserEmail) {
                <!-- UserEmail block -->
                <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2" for="Email">
                    {{ 'Email' | translate }}
                </label>
                <input class="col-span-full cursor-default lg:col-span-2" [value]="userSettings.UserEmail" id="Email"
                       type="text" readonly>
            }
            <!-- Password block -->
            @if (!index.ADUsername) {
                <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2" for="password">
                    {{ 'Password' | translate }}
                </label>
                <ng-container *ngIf="!passwordReset else passwordResetSuccess">
                    <button class="button default col-span-full w-fit p-2 text-sm lg:col-span-2" id="password"
                            type="button"
                            (click)="openResetPasswordModal()">{{ "ResetPassword" | translate }}
                    </button>
                </ng-container>
                <!-- Password reset valid message -->
                <ng-template #passwordResetSuccess>
                    <p class="col-span-full text-blue-500 lg:col-span-2">{{ "PasswordHasBeenResetSuccessfully" | translate }}</p>
                </ng-template>
            } @else {
                <!-- AD block -->
                <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2" for="adpassword">
                    {{ "Password" | translate }}
                </label>
                <div class="col-span-full lg:col-span-2">
                    <button class="button default w-fit" id="adpassword" disabled type="button">
                        {{ "ManagedByActiveDirectory" | translate }}
                    </button>
                </div>
            }

            @if (index.TwoFactorActive || (index | twoFactor)) {
                <!-- TwoFactorAuthentication block -->
                <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2" for="UserTwoFactorAuthenticationMethod">
                    {{ 'TwoFactorAuthentication' | translate }}
                </label>
                <kendo-dropdownlist class="col-span-full lg:col-span-2" *ngIf="index.TwoFactorActive"
                                    formControlName="UserTwoFactorAuthenticationMethod"
                                    id="UserTwoFactorAuthenticationMethod"
                                    [valuePrimitive]="true"
                                    [data]="index.TwoFactorAuthenticationMethodTypes"
                                    textField="Text"
                                    valueField="Value">
                </kendo-dropdownlist>

                <a class="hidden sm:invisible sm:flex sm:h-0 lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2">
                    {{ 'TwoFactorAuthentication' | translate }}
                </a>

                <!-- TwoFa reset button-->
                @if (!index.TwoFactorNeedsSetup && (index | twoFactor) && userForm.get('UserTwoFactorAuthenticationMethod')?.value == 1){
                    <button class="col-span-full p-0 !text-link sm:-mt-1 lg:col-span-2" type="button" (click)="openReset2faModal()">
                        {{ 'ResetTwoFactorAuthenticationDevice' | translate }}
                    </button>
                } @else if (index.TwoFactorNeedsSetup && (index | twoFactor)){
                    <p class="col-span-full cursor-pointer text-sm text-link lg:col-span-2" (click)="openSetup2faModal()">
                        {{ 'ClickToConfigure2FA' | translate }}
                    </p>
                }
            }
            <hr class="col-span-4 my-3 w-full sm:my-4">
        </fieldset>
        <fieldset class="grid w-full grid-cols-1 items-center gap-y-2 lg:grid-cols-4 lg:gap-2.5">
            <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2" for="DefaultWorkspacesName">
                {{ 'DefaultWorkspace' | translate }}
            </label>
            <bizz-workspace-selector
                    id="DefaultWorkspacesName"
                    class="col-span-full block sm:hidden lg:col-span-2"
                    [isSettings]="true"
                    [isMobile]="true"
                    [selectedWorkspace]="index.DefaultWorkspacesID"
                    (selectedWorkspaceChange)="handleOutputFromSelector($event)"
                    [showFullButton]="true">
            </bizz-workspace-selector>
            <bizz-workspace-selector
                    id="DefaultWorkspacesName"
                    class="col-span-full hidden sm:block lg:col-span-2"
                    [isSettings]="true"
                    [isMobile]="false"
                    [selectedWorkspace]="index.DefaultWorkspacesID"
                    (selectedWorkspaceChange)="handleOutputFromSelector($event)"
                    [showFullButton]="true">
            </bizz-workspace-selector>
            <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2">{{ 'Theme' | translate }}</label>
            <bizz-theme-selector-button class="col-span-full lg:col-span-2" [theme]="currentTheme" [showFullPreview]="true"
                                        (click)="openThemeSelectorModal()"></bizz-theme-selector-button>
            <hr class="col-span-4 my-3 w-full sm:my-4">
        </fieldset>

        <fieldset class="grid w-full grid-cols-1 items-center gap-y-2 lg:grid-cols-4 lg:gap-2.5">
            <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2"
                   for="UserLanguagesID">{{ 'Language' | translate }}</label>

            @if(index.DateFormatType){
            <kendo-dropdownlist class="col-span-full lg:col-span-2"
                                formControlName="UserLanguagesID"
                                id="UserLanguagesID"
                                [valuePrimitive]="true"
                                [data]="index.Languages"
                                textField="Text"
                                valueField="Value">
            </kendo-dropdownlist>
            }


            <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2"
                   for="TimezoneName">{{ 'Timezone' | translate }}</label>
            @if(index.DateFormatType){
            <kendo-dropdownlist class="col-span-full lg:col-span-2"
                                formControlName="TimezoneName"
                                id="TimezoneName"
                                [valuePrimitive]="true"
                                [showStickyHeader]="false"
                                [data]="groupedData"
                                textField="Value"
                                [virtual]="virtual"
                                valueField="Displayname">
            </kendo-dropdownlist>
            }

            <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2"
                   for="DateFormatType">{{ 'DateFormat' | translate }}</label>
            @if(index.DateFormatType){
            <kendo-dropdownlist class="col-span-full lg:col-span-2"
                                formControlName="DateFormatType"
                                id="DateFormatType"
                                [valuePrimitive]="true"
                                [data]="index.DateFormatTypes"
                                textField="Text"
                                valueField="Value">
            </kendo-dropdownlist>
        }

            <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2"
                   for="TimeFormatType">{{ 'TimeFormat' | translate }}</label>
            
            @if(index.TimeFormatType){
                <kendo-dropdownlist class="col-span-full lg:col-span-2"
                formControlName="TimeFormatType"
                id="TimeFormatType"
                [valuePrimitive]="true"
                [data]="index.TimeFormatTypes"
                textField="Text"
                valueField="Value">
            </kendo-dropdownlist>
            }

            <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2"
                   for="date">{{ 'YourCurrentDateTime' | translate }}</label>
            <input disabled id="date" class="col-span-full lg:col-span-2" [value]="currentDateTime">
            <hr class="col-span-4 my-3 w-full sm:my-4">
        </fieldset>

        <fieldset class="grid w-full grid-cols-1 items-center gap-y-2 lg:grid-cols-4 lg:gap-2.5">
            <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2"
                   for="DelimiterType">{{ 'DelimiterSeparator' | translate }}</label>

            @if(index.PageSizeType){
            <kendo-dropdownlist class="col-span-full lg:col-span-2"
                                formControlName="DelimiterType"
                                (change)="checkActiveSperator('Decimal')"
                                id="DelimiterType"
                                [valuePrimitive]="true"
                                [data]="index.DelimiterTypes"
                                textField="DisplayText"
                                valueField="Value">
            </kendo-dropdownlist>
            }

            <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2"
                   for="DecimalSeparatorType">{{ 'DecimalSeparator' | translate }}</label>
            @if(index.PageSizeType){
            <kendo-dropdownlist class="col-span-full lg:col-span-2"
                                formControlName="DecimalSeparatorType"
                                (change)="checkActiveSperator('Decimal')"
                                id="DecimalSeparatorType"
                                [valuePrimitive]="true"
                                [data]="index.DecimalSeparatorTypes"
                                textField="DisplayText"
                                valueField="Value">
            </kendo-dropdownlist>
            }
            <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2"
                   for="ThousandSeparatorType">{{ 'ThousandSeparator' | translate }}</label>
            @if(index.PageSizeType){
            <kendo-dropdownlist class="col-span-full lg:col-span-2"
                                formControlName="ThousandSeparatorType"
                                (change)="checkActiveSperator('Thousand')"
                                id="ThousandSeparatorType"
                                [valuePrimitive]="true"
                                [data]="index.ThousandSeparatorTypes"
                                textField="DisplayText"
                                valueField="Value">
            </kendo-dropdownlist>
            }
            <hr class="col-span-4 my-3 w-full sm:my-4">
        </fieldset>

        <fieldset class="grid w-full grid-cols-1 items-center gap-y-2 lg:grid-cols-4 lg:gap-2.5">
            <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2"
                   for="PageSizeType">{{ 'PageSize' | translate }}</label>
            @if(index.PageSizeType){
            <kendo-dropdownlist class="col-span-full lg:col-span-2"
                                formControlName="PageSizeType"
                                id="PageSizeType"
                                [valuePrimitive]="true"
                                [data]="index.PageSizeTypes"
                                textField="Text"
                                valueField="Value">
            </kendo-dropdownlist>
            }
            <label class="lg:col-start-1 maxw-lg:col-span-full maxw-lg:pt-2"
                   for="UnitType">{{ 'Unit' | translate }}</label>
            @if(index.UnitType){
            <kendo-dropdownlist class="col-span-full lg:col-span-2" 
                                formControlName="UnitType"
                                id="UnitType"
                                [valuePrimitive]="true"
                                [data]="index.UnitTypes"
                                textField="Text"
                                valueField="Value">
            </kendo-dropdownlist>
            }
        </fieldset>
    </form>
</div>



