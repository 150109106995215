import { createReducer, on } from '@ngrx/store';
import { collectionsActions } from './collections-actions';
import { CollectionStore } from './collections-state';
import { createEntityAdapter } from '@ngrx/entity';

export const collectionsAdapter =
  createEntityAdapter<CollectionStore>();

export const collectionsReducer = createReducer(
  collectionsAdapter.getInitialState(),
  on(
    collectionsActions.addCollection,
    (state, { collection }) => {
      return collectionsAdapter.upsertOne(collection, state);
    },
  ),
);

