export enum PageSizeType {
	A0 = 1,
	A1 = 2,
	A2 = 3,
	A3 = 4,
	A4 = 5,
	A5 = 6,
	A6 = 7,
	A7 = 8,
	A8 = 9,
	A9 = 10,
	A10 = 11,
	ArchA = 12,
	ArchB = 13,
	ArchC = 14,
	ArchD = 15,
	ArchE = 16,
	B0 = 17,
	B1 = 18,
	B2 = 19,
	B3 = 20,
	B4 = 21,
	B5 = 22,
	Flsa = 23,
	HalfLetter = 24,
	Ledger = 25,
	Legal = 26,
	Letter = 27,
	Letter11x17 = 28,
	Note = 29
}