<div class="modal-container justify-between md:justify-normal">
    <div class="modal-close-container">
      <bizz-icon
        class="block w-5 h-5 text-button ml-auto md:ml-0"
        (click)="dialogRef.close()"
        iconName="xmark"
        [hover]="true">
      </bizz-icon>
    </div>
    <div class="modal-body">
      <div class="modal-header">
        <h1>
          {{ 'FileChangesFor' | translate }}&nbsp;"{{dataItem.Title}}"
        </h1>
        <p>{{ 'Info_HistoryOfaFile' | translate }}</p>
      </div>
      <hr class="my-4" />
      @if (model.length > 0) {
          <div class="flex flex-col w-full max-h-[80%] overflow-y-auto">
              @for (item of model; track item.ID;) {
                <div class="flex flex-row items-center text-xs mb-4">
                    <!-- Extension icon -->
                    <bizz-extension-icon [extension]="item.Extension" [size]="'large'" class="mr-3"/>
                    <div class="flex flex-row items-center flex-1">
                        <div class="flex flex-col">
                            <div class="flex flex-col md:flex-row">
                                <div>{{'UploadedBy' | translate}}&nbsp;<span class="font-bold">{{item.UploadedBy}}</span></div>
                                <span class="hidden md:flex">&nbsp;</span>
                                <div>{{'at' | translate}}&nbsp;<span class="font-bold">{{ item.Date | utcDateTime }}</span></div>
                            </div>
                            <div class="flex flex-row text-lighter md:text-widget">
                                <!-- Extension-->
                                <div class="hidden md:flex md:mr-4">{{item.Extension}}</div>
                                <!-- Size -->
                                <div>{{item.Size | bytes}}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <bizz-icon 
                            class="inline-block h-6 w-6 text-widget-button hover:cursor-pointer hover:text-blue-600 focus:text-blue-600" 
                            iconName="download"
                            (click)="download()">
                        ></bizz-icon>
                    </div>                  
                </div>
              }      
          </div>
      }
    </div>  
</div>