<ng-template #toolbarTemplate>
    <kendo-button class="flex flex-row justify-center gap-1 bg-transparent" (click)="click.emit()">
        <bizz-icon [iconName]="iconName" class="h-5 max-w-5 align-middle" kendoTooltip [title]="tooltip"></bizz-icon>
    </kendo-button>

</ng-template>
<ng-template #popupTemplate>
    <kendo-button class="flex flex-row justify-center gap-1 bg-transparent" (click)="click.emit()">
        <bizz-icon [iconName]="iconName" class="size-4 align-middle" kendoTooltip [title]="tooltip"></bizz-icon>
    </kendo-button>
</ng-template>