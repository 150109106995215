import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true
})
export class TruncatePipe implements PipeTransform {

  public transform(value: string, maxLength: number): string {
    return (value.length > maxLength && maxLength > 0) ?
      value.slice(0, maxLength - 1) + '…' : value;
  }
}
