import { DestroyRef, inject, Injectable } from '@angular/core';
import { BizzMineSessionStorageService } from '../../../shared/services/localStorage/bizzmine-session-storage.service';
import { take } from 'rxjs';
import { UserSettingsApiService } from '../../../api/bizzmine/user-settings/user-settings-api.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { UserSessionIdleModalComponent } from '../../../shared/components/modals/user-session-idle-modal/user-session-idle-modal.component';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { UserType } from '../../../../models/ts/user-type.model';
import { Store } from '@ngrx/store';
import { userSettingsActions } from '../../../store/features/user-settings/user-settings-actions';
import { BlackListApiService } from '../../../api/bizzmine/black-list/black-list-api.service';

@Injectable({
  providedIn: 'root'
})
export class UserSessionsService {

  private hasInit = false;
  private idleDialog: DialogRef<void, UserSessionIdleModalComponent> | undefined;
  private initTitle: string | undefined;
  private timeOutTimer = 60;
  private blackListApiService = inject(BlackListApiService);

  public constructor(private sessionStorage: BizzMineSessionStorageService,
                     private userSettingsApiService: UserSettingsApiService,
                     private idle: Idle,
                     private destroyRef: DestroyRef,
                     private dialog: Dialog,
                     private titleService: Title,
                     private authService: AuthService,
                     private httpClient: HttpClient,
                     private store$: Store){
  }

  public init(): void {
    // pre-init method, check if has UseSessions enabled etc
    this.getAccessToken().then((token) => {
      this.userSettingsApiService.getUserSettings().pipe(take(1))
        .subscribe((settings) => {
          this.store$.dispatch(userSettingsActions.userSettingsFetched({ userSettings: settings }));
          if (settings?.UseSession == true && settings.UserType != UserType.AnonymousUser && settings.UserType != UserType.ExternalUser) {
            this._init(settings.SessionTimeout);
          }
        });
    });
  }

  private getAccessToken(): Promise<string> {
    return new Promise((resolve) => {
      let value = this.sessionStorage.getItem('access_token') as string;
      if (value == null || value.trim() == '') {
        const interval = setInterval(() => {
          value = this.sessionStorage.getItem('access_token') as string;
          if (value != null && value.trim() != '') {
            clearInterval(interval);
            resolve(value);
          }
        }, 1000);
      } else {
        resolve(value);
      }
    });
  }

  private _init(timeOutInMinutes: number): void {
    // spam safety -> somewhat of an overkill as it shouldn't be possible to call this method twice
    if (this.hasInit) {
      return;
    }
    this.hasInit = true;
    if (timeOutInMinutes <= 0) {
      timeOutInMinutes = 60;
    }
    const timeOut = timeOutInMinutes * 60;
    this.idle.setIdle(timeOut);
    this.idle.setTimeout(this.timeOutTimer);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleStart.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.idleDialog = this.dialog.open(UserSessionIdleModalComponent);
    });
    this.idle.onIdleEnd.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.idleDialog?.close();
      if (this.initTitle) {
        this.titleService.setTitle(this.initTitle);
        this.initTitle = undefined;
      }
    });
    this.idle.onTimeoutWarning.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((remain) => {
      this.initTitle = this.initTitle ?? this.titleService.getTitle();

      // gracing by 1 second delaying the logout and thus giving a man more chances in life!
      if (this.idleDialog?.componentInstance?.remaining) {
        this.idleDialog.componentInstance.remaining = remain;
        this.idleDialog.componentInstance.totalTime = this.timeOutTimer;
      }

      const minutes = Math.floor(remain / 60);
      let seconds = (remain % 60).toString();
      if (seconds?.length == 1) seconds = '0' + seconds;
      this.titleService.setTitle(`${minutes}:${seconds} until your session times out!`);
    });
    this.idle.onTimeout.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.titleService.setTitle('Your session has expired.');
      this.authService.logoutViaIdentityServer();
    });
    this.idle.watch();
  }
}
