import { Injectable } from '@angular/core';
import {
  combineLatestWith,
  concatMap,
  filter,
  from,
  map,
  mergeMap,
  Observable,
  of,
  Subscription,
  switchMap
} from 'rxjs';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { sum } from 'lodash';
import { BlobChunkDto } from '../../../../models/ts/blob-chunk-dto.model';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {



  public uploadFiles(intent: UploadFilesIntent): Array<FileUpload> {
    const result = new Array<FileUpload>();
    const mapped = intent.files.map(file => {
      const fileId = crypto.randomUUID().toString();
      const fileUploadObservable = this.uploadFile$({
          upload$: (formData) => intent.upload$(formData),
          file: file,
          chunkSize: intent.chunkSize,
          fileId: fileId,
          maxConcurrentUploads: null,
          preValidation$: (data) => intent.preValidation$(data)
        }
      );
      return { fileId: fileId, fileUpload$: fileUploadObservable };
    });
    mapped.forEach(file => {
      // @ts-ignore
      const fileUpload: FileUpload = {
        fileId: file.fileId,
        fileUpload$: file.fileUpload$
          .subscribe(
            {
              next: (value) => {
                fileUpload.fileChunkUpload = value;
              },
              error: (error: any) => {
                fileUpload.onError(error);
              },
              complete: () => {
                fileUpload.onCompleted();
              }
            }
          ),
        cancel: (): void => {
          if (fileUpload.fileUpload$ != null) {
            fileUpload.fileUpload$.unsubscribe();
          }
          fileUpload.onError();
        }
      };
      result.push(fileUpload);
    });
    return result;
  }

  public getFileExtension(fileName: string): string {
    const values = fileName.split('.');
    if (values.length == 1) {
      return '';
    }
    return fileName.split('.').pop() ?? '';
  }

  public formatFileSize(fileSizeInBytes: number): string {
    const units = ['b', 'kb', 'mb', 'gb', 'tb'];
    let unitIndex = 0;

    while (fileSizeInBytes >= 1024 && unitIndex < units.length - 1) {
      fileSizeInBytes /= 1024;
      unitIndex++;
    }

    // Ensure at least one decimal place and a maximum of two
    const formattedFileSize = fileSizeInBytes.toFixed(2).replace(/\.0$/, '');

    return `${formattedFileSize} ${units[unitIndex]}`;
  }

  //docs found at https://github.com/bizzmine/BizzMine-Frontend/wiki/File-upload-(flow%E2%80%90js-mock)
  private uploadFile$(intent: UploadFileIntent): Observable<FileChunkUpload> {

    const progresses: Array<number> = [];
    return from(this.getFormData(intent.file, intent.chunkSize, intent.fileId))
      .pipe(
        switchMap((formDatasWithMeta) => {
          for (let i = 0; i < formDatasWithMeta.formData.length; i++) {
            progresses.push(0);
          }
          if (intent.preValidation$ != null) {
            return intent.preValidation$(
              {
                fileId: intent.fileId,
                meta: formDatasWithMeta.meta,
                formData: formDatasWithMeta.formData
              }
            );
          }
          return of(formDatasWithMeta);
        }),
        concatMap(data => {
          return data.formData.map(
            (formData: FormData) => {
              const meta = data.meta; // Handle potential missing `meta`
              return {
                formData: formData,
                meta
              };
            }
          );
        }),
        mergeMap((data, index) => {

          return intent.upload$(data.formData)
            .pipe(
              combineLatestWith(of(data.meta), of(index)));
        }, (intent.maxConcurrentUploads != null && intent.maxConcurrentUploads > 0) ? intent.maxConcurrentUploads : 4),
        filter(([event]) => {
          return event.type == HttpEventType.UploadProgress || event.type == HttpEventType.Response;
        }),
        map(([event, meta, index]): FileChunkUpload =>
          this.mapFileProgress({
            httpEvent: event,
            meta: meta,
            index: index,
            progresses: progresses
          }))
      );
  }

  private mapFileProgress(intent: {
    httpEvent: HttpEvent<BlobChunkDto>,
    meta: FileChunkUploadMeta,
    index: number,
    progresses: Array<number>,
  }): FileChunkUpload {
    if (intent.httpEvent.type == HttpEventType.UploadProgress) {
      if (intent.httpEvent.total != null && intent.httpEvent.total > 0) { // dodging division by zero
        intent.progresses[intent.index] = Math.round((100 / intent.httpEvent.total) * intent.httpEvent.loaded);
        return {
          fileName: intent.meta.fileName,
          progress: sum(intent.progresses) / intent.progresses.length,
          blobChunk: null,
          chunkIds: intent.meta.chunkIds,
          type: intent.meta.type,
          lastModifiedDate: intent.meta.lastModifiedDate,
          size: intent.meta.size
        };
      }
    } else if (intent.httpEvent.type == HttpEventType.Response) {
      intent.progresses[intent.index] = 100;
      const progress = sum(intent.progresses) / intent.progresses.length;
      console.log('COMPLETED', intent.index);
      return {
        fileName: intent.meta.fileName,
        progress: progress,
        blobChunk: null,
        chunkIds: intent.meta.chunkIds,
        type: intent.meta.type,
        lastModifiedDate: intent.meta.lastModifiedDate,
        size: intent.meta.size
      };
    }
    intent.progresses[intent.index] = 0;
    return {
      fileName: intent.meta.fileName,
      progress: 0,
      blobChunk: null,
      chunkIds: intent.meta.chunkIds,
      type: intent.meta.type,
      lastModifiedDate: intent.meta.lastModifiedDate,
      size: intent.meta.size
    };
  }

  private getFormData(file: File, chunkSize: number, fileId: string): Promise<{
    formData: Array<FormData>, meta: FileChunkUploadMeta
  }> {
    return new Promise<{
      formData: Array<FormData>, meta: FileChunkUploadMeta
    }>(resolve => {
        const chunkCount = Math.ceil(file.size / chunkSize);
        const dataForms: Array<FormData> = []; // Array to store chunks
        this.readFileInChunks(file, chunkSize)
          .then(chunks => {
            const chunkIds: Array<number> = [];
            chunks.forEach((chunkInfo, readerIndex) => {
              const formData = new FormData();
              formData.append('flowFilename', file.name);
              formData.append('flowRelativePath', file.name);

              formData.append('flowChunkSize', chunkSize.toString());
              formData.append('flowTotalChunks', chunkCount.toString());

              formData.append('flowIdentifier', fileId);
              formData.append('flowChunkNumber', (readerIndex + 1).toString());
              formData.append('flowCurrentChunkSize', chunkInfo.chunkSize.toString());
              formData.append('file', new Blob([chunkInfo.chunk]));
              dataForms.push(formData);
              chunkIds.push(readerIndex + 1);
            });

            resolve({
              formData: dataForms,
              meta: {
                fileName: file.name,
                chunkIds: chunkIds,
                lastModifiedDate: new Date(file.lastModified),
                size: file.size,
                type: file.type
              }
            });

          });
      }
    );
  }

  private async readFileInChunks(file: File, chunkSize: number): Promise<{ chunk: ArrayBuffer; chunkSize: number }[]> {
    let offset = 0;
    const reader = new FileReader();

    const chunks: { chunk: ArrayBuffer; chunkSize: number }[] = [];

    const readChunk = async (): Promise<{ chunk: ArrayBuffer; chunkSize: number }[]> => {
      const nextChunkSize = Math.min(chunkSize, file.size - offset);
      const chunk = await new Promise<ArrayBuffer>((resolve, reject) => {
        reader.onload = (event: any) => resolve(event.target.result as ArrayBuffer);
        reader.onerror = reject;
        reader.readAsArrayBuffer(file.slice(offset, offset + nextChunkSize));
      });
      chunks.push({ chunk, chunkSize: nextChunkSize });
      offset += nextChunkSize;

      if (offset < file.size) {
        return await readChunk(); // Ensure recursive call returns an array
      } else {
        return chunks; // Base case: return the complete chunks array
      }
    };

    return await readChunk(); // Start the reading process and return the promise
  }
}

export interface FileChunkUpload {
  fileName: string;
  progress: number;
  blobChunk: BlobChunkDto | null;
  chunkIds: Array<number>;
  lastModifiedDate: Date;
  size: number;
  type: string;
}

export interface FileChunkUploadMeta {
  fileName: string,
  chunkIds: Array<number>,
  lastModifiedDate: Date,
  size: number,
  type: string
}

export interface FormDataWithMeta {
  formData: Array<FormData>,
  meta: FileChunkUploadMeta,
  fileId: string
}

export interface FileUpload {
  fileId: string,
  fileUpload$: Subscription
  cancel: () => void,
  onCompleted: () => void,
  onError: (error?: any) => void,
  fileChunkUpload: FileChunkUpload | null
}

export interface UploadFilesIntent {
  files: Array<File>
  chunkSize: number,
  maxConcurrentUploads: number | null,
  upload$: (data: FormData) => Observable<HttpEvent<BlobChunkDto>>,

  preValidation$(formData: FormDataWithMeta): Observable<FormDataWithMeta>
}

export interface UploadFileIntent {
  file: File,
  chunkSize: number,
  fileId: string,
  maxConcurrentUploads: number | null,
  upload$: (data: FormData) => Observable<HttpEvent<BlobChunkDto>>,

  preValidation$(formData: FormDataWithMeta): Observable<FormDataWithMeta>
}