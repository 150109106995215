import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

import { ContextMenuComponent } from '../../../../../features/bizzmine/widgets/context-menu/context-menu.component';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { GridService } from '../../../../../features/bizzmine/widgets/collection-list-widget/services/grid/grid.service';
import { ContextMenuService } from '../../../../services/context-menu/context-menu.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CollectionSchedulerApiService } from 'src/app/api/bizzmine/collection-scheduler/collection-scheduler-api.service';
import { DialogModalButtons } from 'src/app/shared/enums/dialog-modal-buttons';
import { DialogModalComponent } from 'src/app/shared/components/modals/dialog-modal/dialog-modal.component';
import { Dialog } from '@angular/cdk/dialog';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { SchedulerDto } from 'src/models/ts/scheduler-dto.model';
import { refreshActions } from 'src/app/store/features/refresh/refresh-actions';


/**
 * Cell with actions (read, edit, execute, menu,...) for an instance.
 */
@Component({
  selector: 'bizz-grid-cell-scheduler-actions',
  standalone: true,
  imports: [CommonModule,
    IconComponent,
    ContextMenuComponent,
    TooltipsModule,
    TranslatePipe],
  templateUrl: './grid-cell-scheduler-actions.component.html',
  styleUrls: ['./grid-cell-scheduler-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridCellSchedulerActionsComponent extends GridCellBaseComponent<string> implements OnInit {
  @ViewChild('menuIcon') menuIcon!: ElementRef;

  private schedulerData: SchedulerDto;

  public constructor(private gridService: GridService, private dialog: Dialog, private contextMenuService: ContextMenuService,
                     private store$: Store, private router: Router, private collectionSchedulerApiServiceService: CollectionSchedulerApiService) {
    super();
  }

  public ngOnInit(): void {
    this.schedulerData = this.data as unknown as SchedulerDto;
    if (!this.schedulerData)
      throw new Error('Scheduler data is not valid');
  }

  protected onEditClicked(event: MouseEvent): void {
  const newTab = event && (event.ctrlKey || event.metaKey);

    this.gridService.editInstance(
      this.schedulerData.CollectionsID,
      this.schedulerData.InstancesID,
      this.schedulerData.VersionsID,
      this.data.GuidChecksum,
      this.data.DraftsID,
      this.data['OriginalFoldersID'],
      true,
      this.schedulerData, newTab);
  }

  protected onDeleteClicked(event: MouseEvent): void {
    const dialogModalRef = this.dialog.open<DialogModalButtons>(DialogModalComponent,
      {
        data: {
          title: 'DeleteScheduler',
          message: 'AreYouSureYouWantToDeleteThisScheduler',
          buttons: DialogModalButtons.Delete,
          showCancelButton: true
        }
      });

    dialogModalRef.closed.subscribe((result: DialogModalButtons | undefined) => {
      if (result == DialogModalButtons.Delete) {
        this.collectionSchedulerApiServiceService.deleteScheduler(this.data.ID).subscribe({
          next: () => {
            this.store$.dispatch(refreshActions.refreshData({ widgetId: this.schedulerData.WidgetsID }));
          }
        });
      }
    });

  }

  protected onRecoverClicked(event: MouseEvent): void {
    const dialogModalRef = this.dialog.open<DialogModalButtons>(DialogModalComponent,
      {
        data: {
          title: 'RestoreScheduler',
          message: 'AreYouSureYouWantToRestoreThisScheduler',
          buttons: DialogModalButtons.Confirm,
          showCancelButton: true
        }
      });

    dialogModalRef.closed.subscribe((result: DialogModalButtons | undefined) => {
      if (result == DialogModalButtons.Confirm) {
        this.collectionSchedulerApiServiceService.restoreScheduler(this.data.ID).subscribe({
          next: () => {
            this.store$.dispatch(refreshActions.refreshData({ widgetId: this.schedulerData.WidgetsID }));
          }
        });
      }
    });
  }


}
