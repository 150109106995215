<button class="relative" [ngClass]="buttonClass" [disabled]="(isLoading || disabled) && !isEnabled">

    @if (isLoading) {
        <bizz-icon iconName="spinner-third" iconStyle="duotone"
                   class="absolute left-[calc(50%_-_0.5rem)] flex size-5 animate-spin items-center justify-center text-white">

        </bizz-icon>
    }

    <div [class]="{ 'invisible' :  isLoading }" class="flex flex-row items-center justify-center align-middle">
        <ng-content></ng-content>
    </div>
</button>