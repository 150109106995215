import { CollectionFieldDisplayStyleValueDto } from 'src/models/ts/collection-field-display-style-value-dto.model';
import { RangeType } from 'src/models/ts/range-type.model';

export function getDisplayStyleForNumber(displayStyles: CollectionFieldDisplayStyleValueDto[] | null, numericValue: number):
  CollectionFieldDisplayStyleValueDto | null {
  if (displayStyles == null) {
    return null;
  }
  for (let i = 0; i < displayStyles.length; i++) {
    let displayValue = displayStyles[i];
    switch (displayValue.RangeType) {
      case RangeType.FromToValue:
        if (numericValue >= displayValue.FromValue && numericValue < displayValue.ToValue) {
          return displayValue;
        }
        break;
      case RangeType.FromNegativeInfinite:
        if (numericValue < displayValue.ToValue) {
          return displayValue;
        }
        break;
      case RangeType.ToPositiveInfinite:
        if (numericValue >= displayValue.FromValue) {
          return displayValue;
        }
        break;
    }
  }
  return null;
}

export function getDisplayStyleByFieldId(displayStyles: CollectionFieldDisplayStyleValueDto[] | null, fieldId: number):
  CollectionFieldDisplayStyleValueDto | null {

  let displayStyle = null;
  if (displayStyles != null) {
    displayStyles.forEach(dStyle => {
      if (dStyle.CollectionFieldValuesID == fieldId) {
        displayStyle = dStyle;
      }
    });
  }

  return displayStyle;
}