<div class="flex h-full w-full flex-col gap-1 p-2 sm:gap-2">
    <div class="flex flex-wrap justify-between gap-1 border-b-2 border-sidebar px-4 py-2 sm:pr-2">
        <button class="default" (click)="onBackClicked()">
            <bizz-icon class="h-4 w-4" iconName="caret-left"></bizz-icon>
            <span>{{ 'Back' | translate}}</span>
        </button>
    </div>

    <div class="mx-4 flex grow flex-col gap-2.5 overflow-hidden overflow-y-auto rounded bg-widget p-4">

        <div class="mb-3 flex flex-wrap justify-between gap-1 sm:pr-2">
            <span  class="widget-header">{{'TrackChanges' | translate}}</span>
            <div class="flex flex-grow items-center gap-2 sm:flex-grow-0">
                <button type="button" class="hidden px-1 py-1 sm:flex sm:px-2" (click)="export()">
                    <bizz-icon class="icon-button size-5 sm:size-4" iconName="download" transform="grow-2"></bizz-icon>
                </button>

                <!-- TODO: replace with generic search -->
                <div class="relative box-border flex h-8 w-full flex-row items-center justify-end sm:mr-2 sm:h-8">
                    <input id="filter" [(ngModel)]="searchTerm"  (ngModelChange)="onSearchTermChanged()"
                        class="size-8 rounded-md border-form-control text-transparent placeholder:text-transparent focus:w-full focus:border-3 focus:border-form-control-focus focus:text-default focus:shadow-inner focus:transition-all placeholder:focus:text-lighter sm:h-8 sm:w-full sm:text-default sm:placeholder:text-lighter"
                        placeholder="{{'Search' | translate}}">
                    <bizz-icon
                        class="pointer-events-none absolute mr-2 size-4 cursor-pointer text-button sm:pointer-events-auto"
                        iconName="magnifying-glass"> </bizz-icon>
                </div>
            </div>
        </div>

        <bizz-track-changes-grid class="flex grow overflow-y-auto" [data]="data" [searchTerm$]="searchTerm$" (listSearchFilterChange)="onListSearchFilterChange($event)"></bizz-track-changes-grid>
    </div>
</div>