import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Subject} from 'rxjs';
import { ViewStackLoadedComponent } from '../../../../../shared/interfaces/view-stack-loaded-component';
import { ViewStackService } from '../../../../../shared/services/view-stack/view-stack.service';
import { HistoryComponent } from 'src/app/views/workspace/history/history.component';
import { HistoryParams } from '../../interfaces/history-params.interface';

@Component({
  selector: 'bizz-view-stack-history',
  standalone: true,
  imports: [CommonModule, HistoryComponent],
  templateUrl: './view-stack-history.component.html',
  styleUrls: ['./view-stack-history.component.scss'],
})
export class ViewStackHistoryComponent implements ViewStackLoadedComponent<HistoryParams> , OnInit, OnDestroy {
  public id: string;
  public data: HistoryParams;
  private destroy = new Subject<void>();

  public constructor(private viewStackService: ViewStackService) {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public removeFromViewStack(): void {
    // TODO: dispatch appropriate store action;
    this.viewStackService.removeLastItemFromStack();
  }

  public hasUnsavedChanges(): boolean {
    return false;
  }
}