import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FlowStatusTasksGridCompletedByColumnComponent,
} from '../flow-status-tasks-grid-completed-by-column/flow-status-tasks-grid-completed-by-column.component';
import { IconComponent } from '../../../../../../../../shared/components/ui/icon/icon.component';
import { StatusIconComponent } from '../../../../../../../../shared/components/ui/status-icon/status-icon.component';
import { TranslatePipe } from '../../../../../../../../shared/pipes/translate/translate.pipe';
import { FlowStatusTaskDto } from '../../../../../../../../../models/ts/flow-status-task-dto.model';
import { FlowStatusTaskType } from 'src/app/features/bizzmine/flow-status/interfaces/flow-status-task-type';
import { OrganizationChartItemType } from 'src/models/ts/organization-chart-item-type.model';
import { OrgChartSelectComponent } from 'src/app/features/bizzmine/org-chart/org-chart-select/org-chart-select.component';

@Component({
  selector: 'bizz-flow-status-tasks-grid-mobile-column',
  standalone: true,
  imports: [CommonModule, IconComponent, StatusIconComponent, TranslatePipe, FlowStatusTasksGridCompletedByColumnComponent, OrgChartSelectComponent],
  templateUrl: './flow-status-tasks-grid-mobile-column.component.html',
  styleUrls: ['./flow-status-tasks-grid-mobile-column.component.scss'],
})
export class FlowStatusTasksGridMobileColumnComponent {


  @Input({ required: true }) public dataItem: FlowStatusTaskDto;
  @Input({ required: true }) public taskType: FlowStatusTaskType;
  @Output() public showAssessmentDetails: EventEmitter<void> = new EventEmitter<void>();
  @Output() public showTaskDetails: EventEmitter<void> = new EventEmitter<void>();

  protected readonly OrganizationChartItemType = OrganizationChartItemType;
  protected readonly FlowStatusTaskType = FlowStatusTaskType;

}
