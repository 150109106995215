<ng-template #toolbarTemplate>

    <div class="border-0">
        <kendo-colorpicker
                #toolbar
                [views]="['gradient','palette']"
                [value]="value"
                [clearButton]="true"
                (valueChange)="onValueChange($event);"
        ></kendo-colorpicker>
    </div>

</ng-template>

<ng-template #popupTemplate>
    <div class="border-0">
        <kendo-colorpicker
                #toolbar
                [views]="['gradient','palette']"
                [value]="value"
                [clearButton]="true"
                (valueChange)="onValueChange($event);"
        ></kendo-colorpicker>
    </div>
</ng-template>
