import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { CronTimeHelpers, timeHelpers } from 'src/app/shared/constants/time-helpers';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { CronGenTimeSelectComponent, CronGenTimeType } from '../cron-gen-time-select/cron-gen-time-select.component';
import { CronGenService } from 'src/app/features/bizzmine/form/services/cron-gen.service';
import { CronGenDaily } from 'src/app/features/bizzmine/form/interfaces/crongen/cron-gen-daily';

@Component({
  selector: 'bizz-cron-daily',
  standalone: true,
  imports: [
    TranslatePipe,
    DropDownsModule,
    CronGenTimeSelectComponent
  ],
  templateUrl: './cron-daily.component.html',
  styleUrl: './cron-daily.component.scss',
})
export class CronDailyComponent {
  public helpers: CronTimeHelpers = timeHelpers;
  @Input() public readOnly = false;

  @Input() public state: CronGenDaily;
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<any>();

  public constructor(public cronGenService: CronGenService) {
  }

  public daysChange(days: any){
    this.state.everyDays.days = days;
    this.stateChange.emit(this.state);
    
  }

  public cronGenTimeChange(state: CronGenTimeType, subTab: 'everyDays'|'everyWeekDay') {
    Object.assign(this.state[subTab], state);
    this.stateChange.emit(this.state);
  }
}
