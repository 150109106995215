<div class="modal-container justify-between md:justify-normal">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="dialogRef.close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1>
                {{ 'Delete' | translate }} {{ dataItem.Title }} ?</h1>
            @if (folders.length > 0) {
                <div>
                    <p class="text-sm font-normal">
                        {{ 'Info_DeleteInstanceInsideAFolder' | translate }}:
                    </p>
                    @for (tree of folders; track $index) {
                        <div class="mt-4">
                            <h4><b>{{ tree.Tree }}</b></h4>
                            <ul class="ml-5 list-disc">
                                @for (folder of tree.Folders; track $index) {
                                    <li>
                                        {{ folder.FolderCaption }}
                                        @if (folder.TargetType == FolderInstanceTargetType.Link) {
                                            <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                                                       iconName="rotate-right">
                                            </bizz-icon>
                                        }
                                    </li>
                                }
                            </ul>
                        </div>
                    }
                </div>
            } @else {
                <div>
                    <p>{{ 'Info_DeleteInstance' | translate }}</p>
                </div>
            }
        </div>
        <hr class="my-5"/>
        <div class="h-full">
            <label class="mb-2 inline-block text-sm text-default">{{ 'ReasonDelete' | translate }}
                <span class="ml-0.5 text-red-400">*</span></label>

            <kendo-textarea
                    [rows]="5"
                    size="large"
                    resizable="vertical"
                    [(value)]="deleteReason"
                    fillMode="solid"
                    rounded="large"
            ></kendo-textarea>
        </div>
    </div>
    <div class="modal-buttons">
        <button class="default" (click)="dialogRef.close()">{{ 'Cancel' | translate }}</button>
        <button class="delete" (click)="delete()" [disabled]="deleteReason == ''">
            {{ 'Delete' | translate }}
        </button>
    </div>
</div>
