import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTimePickerModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { CronGenHourly } from 'src/app/features/bizzmine/form/interfaces/crongen/cron-gen-hourly';
import { CronGenService } from 'src/app/features/bizzmine/form/services/cron-gen.service';
import { CronTimeHelpers, timeHelpers } from 'src/app/shared/constants/time-helpers';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';

@Component({
  selector: 'bizz-cron-hourly',
  standalone: true,
  imports: [
    TranslatePipe,
    DropDownsModule,
    DateTimePickerModule
  ],
  templateUrl: './cron-hourly.component.html',
  styleUrl: './cron-hourly.component.scss',
})
export class CronHourlyComponent {
  public helpers: CronTimeHelpers = timeHelpers;
  @Input() public readOnly = false;

  @Input() public state: CronGenHourly;
  
    @Output() public stateChange: EventEmitter<CronGenHourly> = new EventEmitter<CronGenHourly>();

    public constructor(public cronGenService: CronGenService) {
    }

    public changeHours(hours: number): void {
      this.state.hours = hours;
      this.stateChange.emit(this.state);
    }
    public changeMinutes(minutes: number): void {
      this.state.minutes = minutes;
      this.stateChange.emit(this.state);
    }
  
    public changeSeconds(seconds: number): void {
      this.state.seconds = seconds;
      this.stateChange.emit(this.state);
    }

}
