import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { Component, inject } from '@angular/core';
import { ViewStackService } from '../../../../shared/services/view-stack/view-stack.service';
import { UnsavedChangesService } from '../../../../shared/services/unsaved-changes/unsaved-changes.service';
import { map } from 'rxjs';

export const unsavedChangesGuard: CanDeactivateFn<Component> = (component: Component, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) => {
  const viewStackService = inject(ViewStackService);
  const unsavedChangesService = inject(UnsavedChangesService);
  if (viewStackService.viewStackReference) {
    if (viewStackService.viewStackReference.checkViewStackForUnsavedChanges()) {
      return unsavedChangesService.openUnsavedChangesDialog().pipe(map(result => {
        if (result) viewStackService.clearViewStack();
        return !!result;
      }));
    }
  }
  viewStackService.clearViewStack();
  return true;
};
