import { ComponentRef, Type } from '@angular/core';
import { ViewStackLoadedComponent } from '../interfaces/view-stack-loaded-component';

export class ViewStackItem<T = unknown> {
  public id: string;
  public componentRef: ComponentRef<ViewStackLoadedComponent>;
  public componentData: T;

  public constructor(public componentType: Type<ViewStackLoadedComponent>, id?: string) {
    this.id = id ?? crypto.randomUUID();
  }
}

