import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatePickerModule, DateTimePickerModule, TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { CronGenService } from 'src/app/features/bizzmine/form/services/cron-gen.service';
import { CronTimeHelpers, timeHelpers } from 'src/app/shared/constants/time-helpers';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';

export interface CronGenTimeType {
  hours: number;
  minutes: number;
  seconds: number;
  datetime: Date;
  hourType: 'AM' | 'PM' | null;

}
@Component({
  selector: 'bizz-cron-gen-time-select',
  standalone: true,
  imports: [
    TranslatePipe,
    DropDownsModule,
    DatePickerModule,
    DateTimePickerModule,
    TimePickerModule,

  ],
  templateUrl: './cron-gen-time-select.component.html',
  styleUrl: './cron-gen-time-select.component.scss',
})
export class CronGenTimeSelectComponent {

  public helpers: CronTimeHelpers = timeHelpers;
  
  @Input() public state: CronGenTimeType;
  @Input() public readOnly = false;
  @Input() public disabled: boolean;
  @Output() public stateChange: EventEmitter<CronGenTimeType> = new EventEmitter<CronGenTimeType>();


  public constructor(public cronGenService: CronGenService) {
  }

  public changeHours(hours: number): void {
    this.state.hours = hours;
    this.stateChange.emit(this.state);
  }
  public changeMinutes(minutes: number): void {
    this.state.minutes = minutes;
    this.stateChange.emit(this.state);
  }

  public changeSeconds(seconds: number): void {
    this.state.seconds = seconds;
    this.stateChange.emit(this.state);
  }
  public changeHourType(hourType: 'AM' | 'PM'): void {
    this.state.hourType = hourType;
    this.stateChange.emit(this.state);
  }

  public changeTimePicker(date: Date): void {
    this.state.hours = this.processHour(date.getHours());
    this.state.minutes = date.getMinutes();
    this.state.seconds = date.getSeconds();
    if (this.state.hourType) {
      if (date.getHours() > 12) this.state.hourType = 'PM'
      else this.state.hourType = 'AM';
    }
    this.stateChange.emit(this.state);
  }

  private processHour(hours: number): number {
    if (this.state.hourType) {
      return (hours + 11) % 12 + 1;
    } else {
      return hours;
    }
  }

}
