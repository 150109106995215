<div class="modal-container modal-xs">
    <div class="modal-close-container">
        <bizz-icon iconName="xmark" (click)="dialogRef.close()"></bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1>
                {{ data.title | translate }}
            </h1>

            <hr class="m-auto mb-5 mt-5 h-0 w-full border-t-2 border-divider">
        </div>


        <div class="flex flex-col gap-8">
            @if(data.subText != null){
                <i>{{ data.subText | translate }}</i>
            }
            <p>{{data.message | translate}}</p>
        </div>



    </div>
    <div class="modal-buttons pt-4">
        <button class="save" (click)="dialogRef.close()">
            {{ 'Ok' | translate }}
        </button>

    </div>
</div>