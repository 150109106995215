<div class="flex h-full w-full">
    @switch (type) {
        @case (BrandLogoType.BRANDED) {
            <!-- Branded -->
            <div class="my-1.5 flex w-max items-center gap-2">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="my-1 min-w-8 fill-current">
                    <g clip-path="url(#clip0_9809_7270)">
                        <path d="M25.3766 23.0338C25.1888 27.7888 21.4124 31.6739 16.6673 31.9803C11.6849 32.2966 7.46372 28.6884 6.80137 23.9531L6.79149 23.9432V23.8839C6.74206 23.4885 6.7124 23.0832 6.7124 22.6779C6.7124 22.2726 6.74206 21.8672 6.79149 21.4619C6.82114 21.2247 6.86069 20.9775 6.91012 20.7502C7.27589 19.0202 8.11617 17.4681 9.29257 16.2423V23.2414C9.58914 26.8694 12.7624 29.6967 16.5289 29.4397C19.9296 29.2024 22.6481 26.4245 22.8162 23.014C23.004 19.1289 19.8999 15.9062 16.0544 15.9062C14.9867 15.9062 13.9685 16.1533 13.0689 16.5982C12.2187 17.0134 11.2302 16.4005 11.2302 15.4514V15.4415C11.2302 14.9571 11.507 14.5024 11.9518 14.2948C13.1875 13.6819 14.5814 13.3458 16.0544 13.3458C21.3235 13.326 25.5842 17.7152 25.3766 23.0338Z"
                              />
                        <path d="M9.24312 1.25548V15.7973C8.00741 17.0331 7.12758 18.5851 6.74204 20.3052C6.69261 20.5425 6.64318 20.7797 6.61353 21.017V1.2456C6.61353 0.563485 7.19678 0 7.92832 0C8.64998 0 9.24312 0.563485 9.24312 1.25548Z"
                              />
                    </g>
                    <defs>
                        <clipPath id="clip0_9809_7270">
                            <rect width="18.7729" height="32" fill="white" transform="translate(6.61353)"/>
                        </clipPath>
                    </defs>
                </svg>
                <img class="h-9 w-auto max-w-full" [src]="brandingLogo"/>
            </div>
        }
        @case (BrandLogoType.FULL) {
            <!-- Full -->
            <svg
                    class="w-[132px] fill-current"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 841.9 277.4"
                    xml:space="preserve"
            >
        <g id="BizzMine">
            <g id="BizzMine_00000156563694639911290610000007470433591309565830_">
                <g>
                    <path
                            class="st0"
                            d="M154.6,135.3c-0.9,23.6-19.7,42.8-43.2,44.4c-24.7,1.6-45.7-16.3-48.9-39.8l0,0v-0.3c-0.3-2-0.4-4-0.4-6
				s0.1-4,0.4-6c0.2-1.2,0.4-2.4,0.6-3.5c1.8-8.6,6-16.3,11.8-22.4v34.7c1.5,18,17.2,32,35.9,30.7c16.9-1.2,30.4-15,31.2-31.8
				c0.9-19.3-14.5-35.3-33.6-35.3c-5.3,0-10.3,1.2-14.8,3.4c-4.2,2.1-9.1-1-9.1-5.7l0,0c0-2.4,1.4-4.6,3.6-5.7
				c6.1-3,13.1-4.7,20.4-4.7C134.5,87.2,155.6,109,154.6,135.3z"
                    />
                </g>
                <circle class="st0" cx="570.9" cy="76.2" r="6.3"/>
                <circle class="st0" cx="170.3" cy="76.2" r="6.3"/>
                <path
                        class="st0"
                        d="M170.3,179.8c-3.5,0-6.3-2.8-6.3-6.3V93.7c0-3.5,2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3v79.8
			C176.6,177,173.7,179.8,170.3,179.8z"
                />
                <path
                        class="st0"
                        d="M193,179.8c-2.3,0-4.8-1.5-5.9-3.8l-0.1-0.1v-0.1c-0.7-2.5-0.2-4.8,1.4-6.7l0.1-0.1l65.1-69.5L193,99.2
			c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3l75.2,0.3c2.5,0,4.6,1.4,5.6,3.8c1.1,2.2,0.6,5-1.1,6.7l-65.4,69.8h61.4
			c3.5,0,6.3,2.8,6.3,6.3s-2.8,6.3-6.3,6.3H193z"
                />
                <path
                        class="st0"
                        d="M290.9,179.8c-2.3,0-4.8-1.5-5.9-3.8l-0.1-0.1v-0.1c-0.7-2.5-0.2-4.8,1.4-6.7l0.1-0.1l65.1-69.5l-60.6-0.3
			c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3l75.2,0.3c2.5,0,4.6,1.4,5.6,3.8c1.1,2.2,0.6,5-1.1,6.7l-65.4,69.8h61.4
			c3.5,0,6.3,2.8,6.3,6.3s-2.8,6.3-6.3,6.3L290.9,179.8L290.9,179.8z"
                />
                <path
                        class="st0"
                        d="M388.9,180.3c-3.5,0-6.3-2.8-6.3-6.3v-40.9c0-25.5,20.7-46.2,46.2-46.2c16.7,0,31.8,8.7,40.1,23
			c3.7-6.5,9-12,15.4-16c7.3-4.6,15.8-7,24.5-7c25.5,0,46.2,20.7,46.2,46.2v40.6c0,3.5-2.8,6.3-6.3,6.3s-6.3-2.8-6.3-6.3v-40.6
			c0-18.5-15.1-33.6-33.6-33.6c-18.5,0-33.6,15.1-33.6,33.6v40.4c0,3.5-2.8,6.3-6.3,6.3s-6.3-2.8-6.3-6.3v-40.4
			c0-18.5-15.2-33.6-33.9-33.6c-18.5,0-33.6,15.1-33.6,33.6V174C395.2,177.4,392.4,180.3,388.9,180.3z"
                />
                <path
                        class="st0"
                        d="M570.9,179.8c-3.5,0-6.3-2.8-6.3-6.3V93.7c0-3.5,2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3v79.8
			C577.2,177,574.4,179.8,570.9,179.8z"
                />
                <path
                        class="st0"
                        d="M593.1,180c-3.5,0-6.3-2.8-6.3-6.3v-40.6c0-25.5,20.7-46.2,46.2-46.2c25.6,0,46.4,20.7,46.4,46.2v40.4
			c0,3.5-2.8,6.3-6.3,6.3s-6.3-2.8-6.3-6.3v-40.4c0-18.5-15.2-33.6-33.9-33.6c-18.5,0-33.6,15.1-33.6,33.6v40.6
			C599.4,177.2,596.6,180,593.1,180z"
                />
                <path
                        class="st0"
                        d="M735,179.8c-25.5,0-46.2-20.7-46.2-46.2s20.7-46.2,46.2-46.2c12.4,0,23.6,4.6,32.6,13.3
			c8.6,8.6,13.6,20.4,13.6,32.4v0.5c0,3.5-2.8,6.3-6.3,6.3H702c3,15.7,16.9,27.3,33.1,27.3c10.5,0,20.2-4.8,26.7-13.1
			c1.2-1.6,3.2-2.6,5.2-2.6c1.4,0,2.7,0.4,3.7,1.2c1.4,1,2.2,2.5,2.4,4.3c0.2,1.7-0.3,3.4-1.3,4.7
			C762.8,173.2,749.4,179.8,735,179.8z M768,127.3c-3-15.7-16.9-27.3-33.1-27.3c-16.1,0-30,11.6-33.1,27.3H768z"
                />
                <path
                        class="st0"
                        d="M74.6,27.3v72.1c-6.1,6.1-10.5,13.8-12.4,22.4c-0.3,1.2-0.5,2.3-0.6,3.5v-98c0-3.4,2.9-6.2,6.5-6.2l0,0
			C71.7,21.1,74.6,23.9,74.6,27.3z"
                />
            </g>
        </g>
                <g id="QHSE">
            <g id="QHSE_00000181784784430627018270000017805248406109785003_">
                <path
                        class="st0"
                        d="M593.8,199c-16,0-27.2,11.8-27.2,28.6c0,17,11.3,28.9,27.5,28.9c5,0,8.7-0.9,13.4-3.2l1.2-0.6l5.6,4.8
			c2.5,2.2,3.3,2.4,4.2,2.4c2.3,0,4.3-1.9,4.3-4.2c0-1.3-0.1-2-3.1-4.4l-4.9-4.1l0.9-1.5c3.7-6,5.2-11,5.2-17.8
			C620.9,210.8,609.8,199,593.8,199z M608.3,238.1l-1.1,2.5l-4-3.4c-1.3-1.2-2.6-1.8-3.5-1.8c-1.9,0-3.8,2.1-3.8,4.1
			c0,0.9,0.5,2,1.1,2.7l4.6,4.3l-3,1.2c-1.8,0.7-2.7,0.9-4.6,0.9c-10.6,0-17.1-8-17.1-21c0-12.5,6.6-20.5,16.9-20.5
			c10,0,16.7,8.1,16.7,20.2C610.5,231.6,610,234.4,608.3,238.1z"
                />
                <path
                        class="st0"
                        d="M673.7,253.7c0.6,1.2,2.1,2.2,3.5,2.2c1.3,0,2.8-0.9,3.4-2.2c0.5-1.2,0.6-1.7,0.6-4.8v-42.4
			c0-3.2-0.1-3.6-0.6-4.8c-0.6-1.2-2.1-2.2-3.4-2.2c-1.4,0-2.9,0.9-3.5,2.2c-0.5,1.2-0.6,1.7-0.6,4.8V223h-26.6v-16.5
			c0-3.2-0.1-3.6-0.6-4.8c-0.6-1.2-2.1-2.2-3.4-2.2c-1.4,0-2.9,0.9-3.5,2.2c-0.5,1.2-0.6,1.7-0.6,4.8v42.4c0,3.2,0.1,3.6,0.6,4.8
			c0.6,1.2,2.1,2.2,3.5,2.2c1.3,0,2.8-0.9,3.4-2.2c0.5-1.2,0.6-1.7,0.6-4.8V231H673v17.9C673,252.1,673.1,252.5,673.7,253.7z"
                />
                <path
                        class="st0"
                        d="M718.6,222.2c-5.1-1.5-5.5-1.6-7.2-2.9c-1.5-1.1-2.5-3-2.5-4.9c0-4,3.4-6.8,8.1-6.8c1.9,0,4.1,0.5,6.2,1.3
			c2.7,1.1,2.7,1.1,3.5,1.1c1.9,0,3.4-1.9,3.4-4.2c0-3.9-5.3-6.7-12.6-6.7c-10.4,0-17.6,6.9-17.6,16.7c0,4,1,7.3,2.9,9.2
			c2,2.2,4.2,3.4,10.1,5.3c5.9,1.9,7.2,2.5,9,3.8c1.9,1.3,2.9,3.3,2.9,5.8c0,4.8-3.6,8.1-8.7,8.1c-2.1,0-4.7-0.6-6.8-1.5
			c-5.4-2.3-5.4-2.3-6.5-2.3c-1.7,0-3.3,2.1-3.3,4.4c0,4.5,6.9,7.9,16.1,7.9c6.2,0,11.2-2,14.2-5.5c2.6-3.1,4.1-7.5,4.1-12.1
			C733.9,230.3,729.5,225.5,718.6,222.2z"
                />
                <path
                        class="st0"
                        d="M756.3,255L756.3,255H774c2.6,0,2.7-0.1,3.6-0.5c0.8-0.5,1.7-1.9,1.7-3.5c0-1.4-0.8-2.9-1.7-3.4
			c-0.8-0.5-1.1-0.5-3.6-0.5h-16.3v-15.3h15.1c2.5,0,2.8-0.1,3.6-0.5c0.8-0.5,1.7-1.9,1.7-3.5c0-1.4-0.8-2.9-1.7-3.4
			c-0.8-0.5-1.1-0.5-3.6-0.5h-15.1v-15.1H774c2.6,0,2.7-0.1,3.6-0.5c0.8-0.5,1.7-1.9,1.7-3.5c0-1.4-0.8-2.9-1.7-3.4
			c-0.8-0.5-1.1-0.5-3.6-0.5h-19.1c-2.2,0-3.4,0.6-4.4,2.1c-0.7,1.1-0.9,1.7-0.9,5.6v39c-0.1,3.2,0.4,5.3,1.5,6.4
			C752.1,254.7,753.8,255.1,756.3,255z"
                />
            </g>
        </g>
    </svg>

        }
        @case (BrandLogoType.ICON) {
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                 class="w-8 fill-current">
                <g clip-path="url(#clip0_9809_7270)">
                    <path d="M25.3766 23.0338C25.1888 27.7888 21.4124 31.6739 16.6673 31.9803C11.6849 32.2966 7.46372 28.6884 6.80137 23.9531L6.79149 23.9432V23.8839C6.74206 23.4885 6.7124 23.0832 6.7124 22.6779C6.7124 22.2726 6.74206 21.8672 6.79149 21.4619C6.82114 21.2247 6.86069 20.9775 6.91012 20.7502C7.27589 19.0202 8.11617 17.4681 9.29257 16.2423V23.2414C9.58914 26.8694 12.7624 29.6967 16.5289 29.4397C19.9296 29.2024 22.6481 26.4245 22.8162 23.014C23.004 19.1289 19.8999 15.9062 16.0544 15.9062C14.9867 15.9062 13.9685 16.1533 13.0689 16.5982C12.2187 17.0134 11.2302 16.4005 11.2302 15.4514V15.4415C11.2302 14.9571 11.507 14.5024 11.9518 14.2948C13.1875 13.6819 14.5814 13.3458 16.0544 13.3458C21.3235 13.326 25.5842 17.7152 25.3766 23.0338Z"
                          fill="white"/>
                    <path d="M9.24312 1.25548V15.7973C8.00741 17.0331 7.12758 18.5851 6.74204 20.3052C6.69261 20.5425 6.64318 20.7797 6.61353 21.017V1.2456C6.61353 0.563485 7.19678 0 7.92832 0C8.64998 0 9.24312 0.563485 9.24312 1.25548Z"
                          fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_9809_7270">
                        <rect width="18.7729" height="32" fill="white" transform="translate(6.61353)"/>
                    </clipPath>
                </defs>
            </svg>

        }
    }
</div>
