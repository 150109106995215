
import { Subscription, tap, timer } from 'rxjs';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { Store, Action, ActionCreator } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export abstract class HeaderPopupBaseService {
  protected destroyRef = inject(DestroyRef);
  protected pollingSubscription: Subscription | undefined;
  protected abstract fetchAction: Action;
  protected store$ = inject(Store);
  protected actions$ = inject(Actions);

  public startPolling(): void {
    if (this.pollingSubscription !== undefined){
      this.stopPolling(); // Stop any ongoing polling
    }

    this.pollingSubscription = timer(0, 60000).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.store$.dispatch(this.fetchAction);
    });
  }

  public stopPolling(): void {
    this.pollingSubscription?.unsubscribe();
    this.pollingSubscription = undefined;
  }
}