import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { FormsModule } from '@angular/forms';
import { IconComponent } from '../../../ui/icon/icon.component';
import { CommonModule } from '@angular/common';
import { Error } from '../../../ui/error/error';
import { HeaderNotificationDetailModalData } from './header-notification-detail-modal-data';
import { NotificationFilterPipe } from '../../../../pipes/filter/notification-filter.pipe';
import { Router } from '@angular/router';
import { BizzMineLocalStorageService } from '../../../../services/localStorage/bizzmine-local-storage.service';
import { SafePipe } from 'safe-pipe';

@Component({
  selector: 'header-notification-detail-modal',
  templateUrl: './header-notification-detail-modal.component.html',
  styleUrls: ['./header-notification-detail-modal.component.scss'],
  standalone: true,
  imports: [TranslatePipe, FormsModule, IconComponent, CommonModule, Error, NotificationFilterPipe, SafePipe]
})
export class HeaderNotificationDetailModalComponent implements OnInit {

  // TODO: BV Cleanup usersettings stuff
  public search: string;
  public error: string[] = [];

  public constructor(public dialogRef: DialogRef<boolean>,
                     @Inject(DIALOG_DATA) public data: HeaderNotificationDetailModalData,
                     private router: Router,
                     private bizzMineLocalStorageService: BizzMineLocalStorageService) {
    this.dialogRef.disableClose = true;
  }


  public ngOnInit(): void {
  }

  public viewDetails(): void{
    const url = `./workspace/${this.bizzMineLocalStorageService.getItem('workspaceID')}/form/collectionsid/${this.data.notificationDetail.CollectionsID}/instancesid/${this.data.notificationDetail.InstancesID}/versionsid/${this.data.notificationDetail.VersionsID}/readonly`;
    this.router.navigate([url])
      .then(() => this.closeDialog());
  }

  public flowStatus() : void {
    const url = `./workspace/${this.bizzMineLocalStorageService.getItem('workspaceID')}/flow-status/collectionId/${this.data.notificationDetail.CollectionsID}/instanceId/${this.data.notificationDetail.InstancesID}/versionId/${this.data.notificationDetail.VersionsID}/methodType/${this.data.notificationDetail.MethodType}`;
    this.router.navigate([url])
      .then(() => this.closeDialog());
}

  public closeDialog(): void {
    this.dialogRef.close();
  }


}
