import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { formsActions } from '../forms-actions';
import { exhaustMap, map, tap } from 'rxjs';
import { CreatedFormInstance } from '../../../../features/bizzmine/form/classes/created-form-instance';
import { CollectionSchedulerApiService } from '../../../../api/bizzmine/collection-scheduler/collection-scheduler-api.service';
import { CollectionFormEditApiService } from '../../../../api/bizzmine/collection-form-edit/collection-form-edit-api.service';
import { ViewStackService } from '../../../../shared/services/view-stack/view-stack.service';
import { SchedulerDto } from '../../../../../models/ts/scheduler-dto.model';
import { SchedulerEndType } from '../../../../../models/ts/scheduler-end-type.model';
import { StoreCollectionForm } from '../forms-state';
import { CollectionModalComponent } from '../../../../shared/components/modals/collection-modal/collection-modal.component';
import { TableViewItemInListDto } from '../../../../../models/ts/table-view-item-in-list-dto.model';
import { CollectionFormApiService } from '../../../../api/bizzmine/collection-form/collection-form-api.service';
import { Dialog } from '@angular/cdk/dialog';

/**
 * Effects for forms with a scheduler
 */
@Injectable()
export class FormsSchedulerEffects {
  private actions$ = inject(Actions);
  private collectionFormEditApiService = inject(CollectionFormEditApiService);
  private viewStackService = inject(ViewStackService);
  private collectionFormApiService = inject(CollectionFormApiService);
  private dialog = inject(Dialog);
  private collectionSchedulerApiService = inject(CollectionSchedulerApiService);
  public validateSchedulerForm$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.validateSchedulerForm),
    exhaustMap(({ formId, form, schedule }) => this.collectionSchedulerApiService.validateScheduler(schedule).pipe(
      exhaustMap(() => this.collectionFormEditApiService.saveSchedulerData(form).pipe(
          map((res: CreatedFormInstance) => {
            let scheduler = { ...schedule };
            scheduler.InstancesID = res.InstancesID;
            scheduler.VersionsID = res.VersionsID;
            return scheduler;
          }),
          exhaustMap((scheduler) => this.collectionSchedulerApiService.postScheduler(scheduler).pipe(
            map(() => ({
              type: formsActions.updateScheduler.type,
              update: { formId: formId, scheduler: schedule, form }
            })),
            tap(() => this.viewStackService.removeLastItemFromStack())
          ))
        )
      ))
    )
  ));
  public getSchedulerByWidget$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getSchedulerByWidgetId),
    exhaustMap(({ widgetId }) => this.collectionSchedulerApiService.getList(widgetId)
      .pipe(
        exhaustMap((data) => {

          let schedulerData: SchedulerDto = {
            QuartzID: '',
            WidgetsID: widgetId,
            ID: 0,
            CollectionsID: 0,
            CollectionName: '',
            InstancesID: 0,
            VersionsID: 0,
            RegisteredByID: 1,
            RegisteredByName: '',
            Name: '',
            CronExpression: '0 0 10 1/1 * ? *',
            StartDate: new Date().toISOString(),
            EndType: SchedulerEndType.NoEndDate,
            EndAmount: 1,
            EndDate: new Date().toISOString(),
            NextRun: '',
            LastRun: '',
            State: 1,
            GuidChecksum: undefined
          };

          if (data.length == 1) {
            schedulerData.CollectionsID = data[0].TableID;
            schedulerData.CollectionName = data[0].ViewName;

            return this.collectionFormApiService.getFormByCollection(data[0].TableID).pipe(
              map(data => ({
                type: formsActions.formFetched.type,
                form: new StoreCollectionForm(data, undefined, schedulerData),
                addToViewStack: true
              }))
            );
          } else {
            return this.dialog.open(CollectionModalComponent, { data: data }).closed.pipe(
              exhaustMap((value: TableViewItemInListDto | unknown) => {
                const form = value as TableViewItemInListDto;
                schedulerData.CollectionsID = form.TableID;
                schedulerData.CollectionName = form.ViewName;
                return this.collectionFormApiService.getFormByCollection(form.TableID).pipe(
                  map(data => ({
                    type: formsActions.formFetched.type,
                    form: new StoreCollectionForm(data, undefined, schedulerData),
                    addToViewStack: true
                  }))
                );
              })
            );
          }
        })
      )
    )
  ));

}