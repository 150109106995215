import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatetimePipe } from '../../../../pipes/dates/datetime.pipe';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { VersionType } from '../../../../enums/version-type';
import { CollectionListDataInstance } from '../../../../interfaces/collection-list-data-instance';
import { FolderInstanceTargetType } from '../../../../../../models/ts/folder-instance-target-type.model';
import { DeleteInstanceModel } from './delete-instance-model';
import { InstanceFolderTreeDto } from '../../../../../../models/ts/instance-folder-tree-dto.model';
import { CollectionListApiService } from '../../../../../api/bizzmine/collection-list/collection-list-api.service';

/**
 * Modal that asks the user for a reason and then deletes an instance.
 */
@Component({
  selector: 'bizz-delete-instance-modal',
  standalone: true,
  imports: [CommonModule, DatetimePipe, IconComponent, TextAreaModule, TranslatePipe],
  templateUrl: './delete-instance-modal.component.html',
  styleUrls: ['./delete-instance-modal.component.scss'],
})
export class DeleteInstanceModalComponent {
  protected deleteReason = '';
  protected dataItem: CollectionListDataInstance;
  protected folders: InstanceFolderTreeDto[];

  constructor(public dialogRef: DialogRef,
              @Inject(DIALOG_DATA) public modalData: DeleteInstanceModel,
              private collectionListService: CollectionListApiService) {
    this.dataItem = modalData.dataItem;
    this.folders = modalData.folders;
    this.dialogRef.disableClose = true;
  }

  protected delete(): void {
    this.collectionListService.deleteInstance(
      VersionType.Public, this.dataItem.CollectionsID!, this.dataItem.ID,
      this.dataItem.VersionsID, 0, this.deleteReason).subscribe({
      next: () => {
        //TODO: RV Refresh Grid? //this.gridService.refreshLists();
        this.dialogRef.close();
      }, error: () => {
        this.dialogRef.close();
      },
    });
  }

  protected readonly FolderInstanceTargetType = FolderInstanceTargetType;
}
