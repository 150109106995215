import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserSettingsIndexDto } from '../../../../models/ts/user-settings-index-dto.model';
import { UserSettingsDto } from '../../../../models/ts/user-settings-dto.model';
import { ResetPasswordDto } from '../../../../models/ts/reset-password-dto.model';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsApiService {
  public constructor(private http: HttpClient) { }

  public getUserSettingsIndex(): Observable<UserSettingsIndexDto> {
    return this.http.get<UserSettingsIndexDto>(`api/usersettingsindex`);
  }

  public postUserSettingsIndex(value: UserSettingsIndexDto): Observable<UserSettingsIndexDto> {
    return this.http.post<UserSettingsIndexDto>(`api/usersettingsindex`, value);
  }

  public getUserSettings(): Observable<UserSettingsDto> {
    return this.http.get<UserSettingsDto>(`api/usersettings`);
  }

  public getCheckTwoFactor(): Observable<boolean> {
    return this.http.get<boolean>(`settings/api/user/checktwofactor`);
  }

  public resetPassword(model: ResetPasswordDto): Observable<ResetPasswordDto> {
    return this.http.post<ResetPasswordDto>(`api/settings/user/resetpassword`, model);
  }
  public validateUserSettings(value: UserSettingsIndexDto): Observable<UserSettingsIndexDto> {
    return this.http.post<UserSettingsIndexDto>(`api/settings/validation/usersettings`, value);
  }
  public resetTwoFactor(): Observable<object>{
    return this.http.delete(`settings/api/user/resettwofactor`);
  }
  public saveTwoFactor(value: UserSettingsIndexDto): Observable<UserSettingsIndexDto>{
      return this.http.post<UserSettingsIndexDto>(`settings/api/user/savetwofactor`, value);
  }
  /*TODO: BV (later) Finish qr code call when Geert fixes api*/
  public getQrcode(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'blob' });
  }
}
