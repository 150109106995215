export enum WidgetsType {
	All = 0,
	ListOfCollectionItems = 2,
	CollectionInfoWidget = 8,
	HTMLWidget = 11,
	RSSFeed = 12,
	Chart = 14,
	DocumentTree = 15,
	Scheduler = 16,
	TrainingQueue = 17,
	TrainingSkillMatrixUser = 20,
	TrainingSkillMatrixFunction = 21,
	TrainingGapAnalysisMatrix = 22,
	TaskListWidget = 23,
	CopilotWidget = 24,
  NoPermision = 25,
}