import { inject, Injectable } from '@angular/core';
import { MacroButtonApiService } from '../../../../api/bizzmine/macro-button/macro-button-api.service';
import { isEmpty } from 'lodash';
import { MacroButtonType } from './macro-button-type.enum';
import { Observable } from 'rxjs/internal/Observable';
import { exhaustMap, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/operators';
import { selectForm } from '../../../../store/features/forms/forms-selectors';
import { CollectionFormService } from '../../../../features/bizzmine/form/services/collection-form.service';
import { CollectionFormField, Record } from '../../../../../models/ts/collection-form-field.model';
import { CollectionForm } from '../../../../../models/ts/collection-form.model';
import { TableFieldDataType } from '../../../../../models/ts/table-field-data-type.model';
import {
  FormControlMacroButtonProperties,
  ListMacroButtonProperties,
  MacroButtonProperties
} from './macro-button-properties';
import { LinkedCollectionStorageType } from '../../../../../models/ts/linked-collection-storage-type.model';
import { UpdateDeleteState } from '../../../../../models/ts/update-delete-state.model';

/**
 * Service for handling the complicated macro button url logic
 */
@Injectable({
  providedIn: 'root'
})
export class MacroButtonService {

  private macroButtonApiService = inject(MacroButtonApiService);
  private store$ = inject(Store);

  /**
   * Returns the URL for a macro button based on its type.
   * @param {string} url
   * @param {MacroButtonType} macroButtonType
   * @param {MacroButtonProperties} macroButtonProperties
   * @return {Observable<string>}
   */
  public getMacroButtonUrl(url: string, macroButtonType: MacroButtonType, macroButtonProperties: MacroButtonProperties): Observable<string> {
    switch (macroButtonType) {
      case MacroButtonType.WidgetList:
        return this.getMacroButtonUrlForWidgetList(url, macroButtonProperties as ListMacroButtonProperties);
      case MacroButtonType.FormControl:
        // No Bookmarks => Return URL as is
        if (url !== null && !this.hasBookmarks(url))
          return of(url);
        return this.getMacroButtonUrlForFormControl(url, macroButtonProperties as FormControlMacroButtonProperties);
      default:
        throw new Error('Invalid macro button type');
    }
  }

  /**
   * Returns the URL for a macro button used as a cell in a widget list. Also used on reversed lists on forms. This function is intended for widget list macro buttons only.
   * @param {string} url
   * @param {ListMacroButtonProperties} macroButtonProperties
   * @return {Observable<string>}
   * @private
   */
  private getMacroButtonUrlForWidgetList(url: string, macroButtonProperties: ListMacroButtonProperties): Observable<string> {
    return this.macroButtonApiService.getMacroButtonUrl(
      macroButtonProperties.data.CollectionsID ?? 0,
      macroButtonProperties.data.ID ?? 0,
      macroButtonProperties.data.VersionsID ?? 0,
      macroButtonProperties.column.CollectionFieldsID,
      url
    );
  }


  /**
   * Base Form Control: Replace bookmarks in the URL with field values from the form, get missing bookmarks from the API
   *  - Instance: Form Instance
   *  - Version: Form Version
   *
   * 1x1 Linked FormControl: Get the URL from the API
   *  - VDS: Field VDS
   *  - Instance: VDS Instance
   *  - Version: VDS Instance Version
   *
   * 1x1 Linked FormControl (Historical): Replace bookmarks in the URL with field values from the form, get missing bookmarks from the API
   *  - VDS: Field VDS
   *  - Instance: VDS Instance
   *  - Version: VDS Instance Version
   *
   *  1xN Linked FormControl: Get the URL from the API
   *  - VDS: Field VDS
   *  - Instance: VDS Instance
   *  - Version: VDS Instance Version
   *  - RowDataDesignCrossId: parameter => Record of the macro button
   *
   *  1xN Linked FormControl (Historical): Replace bookmarks in the URL with field values from the grid record, get missing bookmarks from the API
   * @param {string} url
   * @param {FormControlMacroButtonProperties} macroButtonProperties
   * @return {Observable<string>}
   * @private
   */
  private getMacroButtonUrlForFormControl(url: string, macroButtonProperties: FormControlMacroButtonProperties): Observable<string> {
    return of(url).pipe(
      concatLatestFrom(() =>
        this.store$.select(selectForm(macroButtonProperties.formId))
      ),
      exhaustMap(([url, form]) => {
        if (form !== undefined) {
          if (macroButtonProperties.viewDataSourceId == 0) {
            return this.getUrlForBaseFormControl(url, form.data, macroButtonProperties);
          } else {
            if (macroButtonProperties.gridFieldId !== undefined) {
              return this.getUrlForGridFormControl(url, form.data, macroButtonProperties);
            } else {
              return this.getUrlForLinkedFormControl(url, form.data, macroButtonProperties);
            }
          }
        } else throw new Error(`Form ${macroButtonProperties.formId} not found`);
      })
    );
  }

  /**
   * Replace bookmarks, if any are left, get the URL from the API.
   * @param {string} url
   * @param {CollectionForm} form
   * @param {FormControlMacroButtonProperties} macroButtonProperties
   * @return {Observable<string>}
   * @private
   */
  private getUrlForBaseFormControl(url: string, form: CollectionForm, macroButtonProperties: FormControlMacroButtonProperties): Observable<string> {
    url = this.replaceBookmarksInUrl(url, this.getBookmarkFields(url, form));
    if (this.hasBookmarks(url)) {
      return this.macroButtonApiService.getMacroButtonUrl(
        form.CollectionsID,
        form.InstancesID,
        form.VersionsID,
        macroButtonProperties.fieldId,
        url
      );
    } else return of(url);
  }

  /**
   * Replace bookmarks for linked (1x1) fields, if any are left, get the URL from the API.
   * @param {string} url
   * @param {CollectionForm} form
   * @param {FormControlMacroButtonProperties} macroButtonProperties
   * @return {Observable<string>}
   * @private
   */
  private getUrlForLinkedFormControl(url: string, form: CollectionForm, macroButtonProperties: FormControlMacroButtonProperties): Observable<string> {
    const vds = form.ViewDataSources.find(vds => vds.ViewDataSourcesID === macroButtonProperties.viewDataSourceId);
    if(vds !== undefined) {
      const instance = vds.Instances.find(i => i.State !== UpdateDeleteState.Delete);
      if(vds.LinkedCollectionStorageType == LinkedCollectionStorageType.Historical) {
        url = this.replaceBookmarksInUrl(url, this.getBookmarkFields(url, form));
        if (this.hasBookmarks(url)) {
          return this.macroButtonApiService.getMacroButtonUrl(
            vds.ChildCollectionsID,
            instance?.ChildInstancesID ?? 0,
            instance?.ChildVersionsID ?? 0,
            macroButtonProperties.fieldId,
            url
          );
        } else return of(url);
      } else {
        return this.macroButtonApiService.getMacroButtonUrl(
          vds.ChildCollectionsID,
          instance?.ChildInstancesID ?? 0,
          instance?.ChildVersionsID ?? 0,
          macroButtonProperties.fieldId,
          url
        );
      }
    } else throw new Error('Macro button ViewDataSource not found');
  }

  /**
   * Replace bookmarks from grid record, if any are left, get the URL from the API.
   * @param {string} url
   * @param {CollectionForm} form
   * @param {FormControlMacroButtonProperties} macroButtonProperties
   * @return {Observable<string>}
   * @private
   */
  private getUrlForGridFormControl(url: string, form: CollectionForm, macroButtonProperties: FormControlMacroButtonProperties): Observable<string> {
    if (macroButtonProperties.gridFieldId !== undefined && macroButtonProperties.rowDataDesignCrossID !== 0) {
      const vds = form.ViewDataSources.find(vds => vds.ViewDataSourcesID === macroButtonProperties.viewDataSourceId);
      const gridField = CollectionFormService.getField(form, field => field.Id == macroButtonProperties.gridFieldId);
      if (vds !== undefined && gridField !== undefined) {
        const instance = vds.Instances.find(i => i.RowDataDesignCrossID == macroButtonProperties.rowDataDesignCrossID);
        if (instance !== undefined) {
          if (vds.LinkedCollectionStorageType == LinkedCollectionStorageType.Historical) {
            const record = gridField.Records?.find(r => r.RowDataDesignCrossID == macroButtonProperties.rowDataDesignCrossID);
            if (record !== undefined) {
              url = this.replaceBookmarksInUrl(url, this.getBookmarkGridFields(url, record));
              if (this.hasBookmarks(url)) {
                return this.macroButtonApiService.getGridMacroButtonUrl(
                  vds.ChildCollectionsID,
                  form.InstancesID,
                  form.VersionsID,
                  macroButtonProperties.fieldId,
                  macroButtonProperties.viewDataSourceId,
                  instance.CrossLinkedInstancesID,
                  instance.ChildInstancesID,
                  url
                );
              } else return of(url);
            } else throw new Error('Macro button record not found');
          } else return this.macroButtonApiService.getGridMacroButtonUrl(
            vds.ChildCollectionsID,
            form.InstancesID,
            form.VersionsID,
            macroButtonProperties.fieldId,
            macroButtonProperties.viewDataSourceId,
            instance.CrossLinkedInstancesID,
            instance.ChildInstancesID,
            url
          );
        } else throw new Error('Macro button instance not found');
      } else throw new Error('Macro button ViewDataSource / Grid Field not found');
    } else throw new Error(`Missing gridFieldId or rowDataDesignCrossID for grid macro button`);
  }

  /**
   * Replaces bookmarks in the URL with field values from the form
   * @param {string} url
   * @param {Map<string, CollectionFormField | undefined>} bookmarkFields
   * @return {string}
   * @private
   */
  private replaceBookmarksInUrl(url: string, bookmarkFields: Map<string, CollectionFormField | undefined>): string {
    bookmarkFields.forEach((field, bookmark) => {
      if (field !== undefined)
        url = url.replace(bookmark, this.getBookmarkReplacementValueForField(field) ?? bookmark);
    });
    return url;
  }

  /**
   * Checks if the URL has bookmarks by checking if it contains square brackets
   * @param {string} url
   * @return {boolean}
   * @private
   */
  private hasBookmarks(url: string): boolean {
    if (!isEmpty(url))
      return url.includes('[');
    else return false;
  }

  /**
   * Extracts bookmarks from the URL by using a regex testing for strings between square brackets
   * @param {string} url
   * @return {string[]}
   * @private
   */
  private getBookmarksFromUrl(url: string): string[] {
    let bookMarkRegex = /\[(.*?)\]/gm;
    let matches = url.matchAll(bookMarkRegex);
    return [...matches][0];
  }

  /**
   * Maps bookmarks to their corresponding form fields
   * @param {string} bookmarkedUrl
   * @param {CollectionForm} form
   * @return {Map<string, CollectionFormField | undefined>}
   * @private
   */
  private getBookmarkFields(bookmarkedUrl: string, form: CollectionForm): Map<string, CollectionFormField | undefined> {
    // Extract bookmarks from the URL
    let bookMarks = this.getBookmarksFromUrl(bookmarkedUrl);
    // Extract fields from the form
    let fields = CollectionFormService.extractFieldsFromForm(form);

    // Map bookmarks to their corresponding fields
    let bookMarkFields = new Map<string, CollectionFormField | undefined>();
    bookMarks.forEach(bookmark => {
      // Remove the square brackets from the bookmark with slice
      // If fields aren't found then the bookmark should not be replaced => undefined indicates this.
      bookMarkFields.set(bookmark, fields.find(f => f.Bookmark === bookmark.slice(1, -1)));
    });
    return bookMarkFields;
  }

  /**
   * Maps bookmarks to their corresponding grid record fields
   * @param {string} bookmarkedUrl
   * @param {Record} record
   * @return {Map<string, CollectionFormField | undefined>}
   * @private
   */
  private getBookmarkGridFields(bookmarkedUrl: string, record: Record): Map<string, CollectionFormField | undefined> {
    // Extract bookmarks from the URL
    let bookMarks = this.getBookmarksFromUrl(bookmarkedUrl);
    // Map bookmarks to their corresponding fields
    let bookMarkFields = new Map<string, CollectionFormField | undefined>();
    bookMarks.forEach(bookmark => {
      // Remove the square brackets from the bookmark with slice
      // If fields aren't found then the bookmark should not be replaced
      bookMarkFields.set(bookmark, record.Fields.find(f => f.Bookmark === bookmark.slice(1, -1)));
    });
    return bookMarkFields;
  }

  /**
   * Returns the string value of a field for bookmark replacement
   * @param {CollectionFormField} field
   * @return {string}
   * @private
   */
  private getBookmarkReplacementValueForField(field: CollectionFormField): string {
    switch (field.ComponentType) {
      case TableFieldDataType.AlphaNumeric:
      case TableFieldDataType.Numeric:
      case TableFieldDataType.Email:
      case TableFieldDataType.HyperLink:
      case TableFieldDataType.Memo:
      case TableFieldDataType.Canvas:
        // Value represented as is in string format
        return field.Value ? field.Value.toString() : '';
      case TableFieldDataType.Checkbox:
        // Boolean represented as 1 or 0
        return field.Value ? '1' : '0';
      case TableFieldDataType.RadioGroup:
      case TableFieldDataType.Combobox:
        // Caption of the selected field value
        return field.FieldValues.find(fv => fv.CollectionFieldValuesID == field.Value)?.Caption ?? '';
      default:
        // If the field type is not supported (not selectable when picking macro button bookmarks), throw an error
        throw new Error('Invalid field type for macro button bookmark replacement');
    }
  }
}
