import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FlowStatusTasksGridComponent,
} from '../../flow-status-tasks/flow-status-tasks-grid/flow-status-tasks-grid.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FlowStatusTaskType } from '../../../interfaces/flow-status-task-type';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { FlowStatusTaskAssessmentModel } from './flow-status-task-assessment-model';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { DialogModalButtons } from '../../../../../../shared/enums/dialog-modal-buttons';
import { UtcDateTimePipe } from '../../../../../../shared/pipes/dates/utc-date-time.pipe';
import { FlowStatusTaskDto } from '../../../../../../../models/ts/flow-status-task-dto.model';
import { AssessmentGridComponent } from "../assessment-grid/assessment-grid/assessment-grid.component";

@Component({
    selector: 'bizz-flow-status-task-assessment-modal',
    standalone: true,
    templateUrl: './flow-status-task-assessment-modal.component.html',
    styleUrls: ['./flow-status-task-assessment-modal.component.scss'],
    imports: [
      CommonModule, 
      FlowStatusTasksGridComponent, 
      IconComponent, 
      NgScrollbarModule, 
      TranslatePipe, 
      GridModule, 
      UtcDateTimePipe, 
      AssessmentGridComponent]
})
export class FlowStatusTaskAssessmentModalComponent {
  public taskInfo: FlowStatusTaskDto;
  public tasks: FlowStatusTaskDto[];

  public constructor(public dialogRef: DialogRef,
                     @Inject(DIALOG_DATA) public modalData: FlowStatusTaskAssessmentModel) {
    this.taskInfo = modalData.taskInfo;
    this.tasks = modalData.tasks;
    this.dialogRef.disableClose = true;
  }

  protected readonly FlowStatusTaskType = FlowStatusTaskType;
  protected readonly DialogModalButtons = DialogModalButtons;

  public readonly MAX_MOBILE_WIDTH = 1024;
  public readonly TAILWIND_LG_BR = 1280;
}
