import { CollectionFormValidatorError } from './collection-form-validator-error';

export class CollectionFormValidatorResult {
  public Errors: Array<CollectionFormValidatorError>;
  public IsValid: boolean;

  public constructor() {
    this.Errors = [];
  }
}

export function isCollectionFormValidatorResult(arg: any): arg is CollectionFormValidatorResult {
  return (arg as CollectionFormValidatorResult) !== undefined;
}


