import { Component, Input } from '@angular/core';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';

@Component({
  selector: 'bizz-grid-cell-checkbox',
  standalone: true,
  imports: [],
  templateUrl: './grid-cell-checkbox.component.html',
  styleUrl: './grid-cell-checkbox.component.scss'
})
export class GridCellCheckboxComponent extends GridCellBaseComponent<boolean>  {
}
