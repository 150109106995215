import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { ButtonDisplayType } from 'src/models/ts/button-display-type.model';
import { IconDto } from 'src/models/ts/icon-dto.model';
import { IconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';
import { MacroButtonService } from './macro-button.service';
import { MacroButtonType } from './macro-button-type.enum';
import { MacroButtonProperties } from './macro-button-properties';

@Component({
  selector: 'bizz-macro-button',
  standalone: true,
  templateUrl: './macro-button.component.html',
  styleUrl: './macro-button.component.scss',
  imports: [IconComponent, NgClass]
})
export class MacroButtonComponent implements OnInit {

  // TODO: loader state?
  // TODO: disabled state in template
  @Input({ required: true }) public icon: IconDto;
  @Input({ required: true }) public displayType: ButtonDisplayType;
  @Input({ required: true }) public caption: string;
  @Input({ required: true }) public url: string;
  @Input({ required: true }) public disabled = false;
  @Input({ required: true }) public style: string;
  @Input({ required: true }) public macroButtonType: MacroButtonType;
  @Input({ required: true }) public macroButtonProperties: MacroButtonProperties;
  public showIcon = false;
  public showCaption = false;
  @ViewChild('macroButton') private macroButton: ElementRef;
  private macroButtonService = inject(MacroButtonService);

  public ngOnInit(): void {
    if (this.icon) {
      this.showIcon = this.displayType === ButtonDisplayType.Icon ||
        this.displayType === ButtonDisplayType.IconCaption;
      this.showCaption = this.displayType === ButtonDisplayType.Caption ||
        this.displayType === ButtonDisplayType.IconCaption;
    }
  }

  public navigateToUrl(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.macroButtonService.getMacroButtonUrl(this.url, this.macroButtonType, this.macroButtonProperties).subscribe({
      next: url => {
        window.open(url, '_blank');
      }
    });
  }

  protected focus(): void {
    if (this.macroButton) {
      this.macroButton.nativeElement.focus();
    }
  }


  //See ngBizzFormMacroButton.js
}
