/* eslint-disable @typescript-eslint/naming-convention */
import { WorkspaceItemType } from '../../../../models/ts/workspace-item-type.model';
import { WorkspaceSidebarDto } from '../../../../models/ts/workspace-sidebar-dto.model';

export type WorkspaceSidebarState = Readonly<WorkspaceSidebarDto>;

export const initialWorkspaceState: WorkspaceSidebarState = {
  ID: 0,
  WorkspaceName: '',
  Parents: [],
  DefaultWorkSpaceItemsID: 0,
  DefaultWorkSpaceItemCollectionsID: 0,
  DefaultWorkSpaceItemsType: WorkspaceItemType.All,
};
