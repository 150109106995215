import { UserSettingsDto } from '../../../../models/ts/user-settings-dto.model';
import { TimeFormatType } from '../../../../models/ts/time-format-type.model';
import { DecimalSeparatorType } from '../../../../models/ts/decimal-separator-type.model';
import { ThousandSeparatorType } from '../../../../models/ts/thousand-separator-type.model';
import { DelimiterType } from '../../../../models/ts/delimiter-type.model';
import { PageSizeType } from '../../../../models/ts/page-size-type.model';
import { UnitType } from '../../../../models/ts/unit-type.model';
import { UserType } from '../../../../models/ts/user-type.model';

export type UserSettingsState = Readonly<UserSettingsDto>;

export const initialUserSettingsStoreState: UserSettingsState = {
    UserID: 0,
    UserFirstName: "",
    UserLastName: "",
    UserEmail: "",
    UserLanguagesID: 0,
    CompanyLanguagesID: 0,
    UserLanguagesCode: "",
    TimeFormatType: "",
    TimeFormatTypeEnum: TimeFormatType.Twelve,
    DateTimeFormatTypeString: "",
    DateFormatTypeString: "",
    DecimalSeparatorString: "",
    DecimalSeparatorType: DecimalSeparatorType.Comma,
    ThousandSeparatorString: "",
    ThousandSeparatorType: ThousandSeparatorType.Comma,
    DelimiterTypeString: "",
    DelimiterType: DelimiterType.Comma,
    PageSizeType: PageSizeType.A0,
    UnitType: UnitType.Imperial,
    TimezoneName: "",
    TimezoneOffset: "",
    UploadFileSize: 0,
    IsAdministrator: false,
    UserType: UserType.User,
    ThemesID: 0,
    UseSession: false,
    SessionTimeout: 0,
    AnonymousPortalUserLanguage: undefined
};

