import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AutoCompleteModule,
  ComboBoxModule,
  DropDownTreesModule,
  MultiSelectModule
} from '@progress/kendo-angular-dropdowns';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { LinkedFormControlButtonsComponent } from '../linked-form-control-buttons/linked-form-control-buttons.component';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { OrgChartSelectComponent } from '../../../../org-chart/org-chart-select/org-chart-select.component';
import { formsActions } from '../../../../../../store/features/forms/forms-actions';
import { UserType } from '../../../../../../../models/ts/user-type.model';
import { OrganizationChartItemType } from 'src/models/ts/organization-chart-item-type.model';
import {DatePickerModule} from "@progress/kendo-angular-dateinputs";

/**
 * FormControl wrapper for the OrgChartComponent to use in a collection form.
 * @see OrgChartComponent
 */
@Component({
  selector: 'bizz-organization-chart-control',
  standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IconComponent,
        DropDownTreesModule,
        MultiSelectModule,
        AutoCompleteModule,
        LinkedFormControlButtonsComponent,
        ComboBoxModule,
        OrgChartSelectComponent,
        DatePickerModule
    ],
  templateUrl: './organization-chart-control.component.html',
  styleUrls: ['./organization-chart-control.component.scss']
})
export class OrganizationChartControlComponent extends BaseFormControlComponent {
  @ViewChild('input') public orgChart: OrgChartSelectComponent;
  public allowedTypes: Array<OrganizationChartItemType> = [];
  public selectionChanged(selection: any): void {
    this.formControl.markAsDirty();
    this.formControl.setValue(selection);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.allowedTypes = this.getAllowedTypes();
  }
  getAllowedTypes(): Array<OrganizationChartItemType> {
    const allowedTypes: Array<OrganizationChartItemType> = [];

    if (this.formFieldSignal()?.CanSelectUsers) {
      allowedTypes.push(OrganizationChartItemType.User);
    }
    if (this.formFieldSignal()?.CanSelectDepartments) {
      allowedTypes.push(OrganizationChartItemType.Department);
    }
    if (this.formFieldSignal()?.CanSelectFunctions) {
      allowedTypes.push(OrganizationChartItemType.Function);
    }

    return allowedTypes;
  }
  protected override fieldValueProperty: keyof CollectionFormField = 'OrgChartUnitSelector';

  protected override valueChangeDispatcher(value: any): void {
    const fieldId = this.formFieldSignal()?.Id;
    if (fieldId)
      this.store$.dispatch(formsActions.updateOrgChartFieldValue({
        formId: this.formId,
        fieldId: fieldId,
        value
      }));
  }

  protected override gridValueChangeDispatcher(value: any): void {
    const field = this.formFieldSignal();
    if (field)
      this.store$.dispatch(formsActions.updateGridOrgChartFieldValue({
        formId: this.formId,
        gridFieldId: this.gridFieldId,
        recordId: this.recordId,
        recordFieldId: field.CollectionFieldsID,
        value
      }));
  }

  protected override fieldValueChangeComparator(previous: CollectionFormField | undefined, current: CollectionFormField | undefined): boolean {
    return previous?.OrgChartUnitSelector === current?.OrgChartUnitSelector;
  }

  // Implement the abstract member from OrganizationChartControlComponent
  protected override focus(): void {
    this.orgChart.kendoElement.focus();
  }

  protected readonly UserType = UserType;
}
