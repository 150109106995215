<div class="modal-container modal-xs w-[900px]">
    <div class="modal-close-container">
        <bizz-icon iconName="xmark" (click)="dialogRef.close(false)"></bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1>
                {{ "Info_DeleteFile" | translate }}
            </h1>
            <hr class="m-auto mb-5 mt-5 h-0 w-full border-t-2 border-divider">
        </div>

        <div>

            <div class="flex">
                <div class="w-full">
                    <div class="form-group">
                        <p>{{ "Info_AreYouSureYouWantToDeleteThisFile" | translate }}</p>
                    </div>
                </div>
            </div>

        </div>


    </div>
    <div class="modal-buttons">
        <div class="modal-buttons pt-4">
            <button class="default" (click)="dialogRef.close(false)">
                {{ 'Cancel' | translate }}
            </button>
            <button class="delete" (click)="dialogRef.close(true)">
                {{ 'DeleteFile' | translate }}
            </button>

        </div>
    </div>
</div>

