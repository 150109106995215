import { createActionGroup, props } from '@ngrx/store';
import { StoreFlowStatus } from './flow-status-state';
import { CollectionMethodType } from '../../../../models/ts/collection-method-type.model';

export const flowStatusActions = createActionGroup({
  source: 'Flow Status',
  events: {
    getFlowStatus: props<{
      collectionsID: number, instancesID: number,
      versionsID: number, methodType: CollectionMethodType
    }>(),
    getFlowStatusForViewStack: props<{
      collectionsID: number, instancesID: number,
      versionsID: number, methodType: CollectionMethodType
    }>(),
    setFlowStatus: props<{ data: StoreFlowStatus }>(),
    addFlowStatusToViewStack: props<{ data: StoreFlowStatus }>(),
    flowStatusFetched: props<{ data: StoreFlowStatus }>(),
  },
});