import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { DashboardApiService } from '../dashboard-api.service';
import { DashboardBand } from '../../../../../models/ts/dashboard-band.model';

export const dashboardResolver: ResolveFn<DashboardBand[]> = (route, state) => {
  return inject(DashboardApiService).getDashboard(
    route.params['workspaceId'],
    route.params['workspaceItemId']
  );
};
