import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { WorkspaceApiService } from '../../workspace-api.service';
import { WorkspaceIndexDto } from '../../../../../../models/ts/workspace-index-dto.model';

@Injectable({
  providedIn: 'root'
})
export class WorkspacesResolver  {

  constructor(private workspaceApiService: WorkspaceApiService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WorkspaceIndexDto> {
    return this.workspaceApiService.getWorkspaces();
  }
}
