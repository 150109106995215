<div class="mt-5 grid w-full grid-cols-2 gap-2 rounded bg-section p-5 lg:inline-flex lg:items-center">

    <p>{{ 'Every' | translate }} </p>
    <kendo-combobox class="w-full lg:w-16" [clearButton]="false" [data]="cronGenService.dropDownOptions.minutes"
                    [readonly]="readOnly"
                    [textField]="'minutes'" [valueField]="'minutes'" [value]="state.minutes"
                    [valuePrimitive]="true" (valueChange)="changeMinutes($event)"></kendo-combobox>
    <p> {{ 'minute_s' | translate }} {{ 'onSecond' | translate }} </p>
    <kendo-combobox class="w-full lg:w-16" [clearButton]="false" [data]="cronGenService.dropDownOptions.seconds"
                    [readonly]="readOnly"
                    [textField]="'seconds'" [valueField]="'seconds'" [value]="state.seconds"
                    [valuePrimitive]="true" (valueChange)="changeSeconds($event)"></kendo-combobox>
</div>