<bizz-modal modalSize="md" title="ReportToGenerate" [contentTemplate]="contentTemplateRef"
    [footerTemplate]="footerTemplateRef"></bizz-modal>



<ng-template #contentTemplateRef>
    @if(steps.length > 0){
    <bizz-stepper [disabled]="isGenerating" [wizard]="false" [scrollable]="true"
        class="mx-auto mb-5 block h-11 w-full sm:w-9/12" [currentStep]="activeTab" [steps]="steps"
        (currentStepChange)="setTemplateRef($event)"></bizz-stepper>
    }

    <div class="relative w-full overflow-y-auto px-4 md:h-[556px] md:w-[670px]">
        <ng-container [ngTemplateOutlet]="activeTabTemplateRef">
        </ng-container>
    </div>
</ng-template>

<ng-template #fromWordTabTemplateRef>
    <bizz-from-word-template (onSelectedFormat)="onSelectedFormat($event)" (onError)="onError()" [submit]="submit$"
        [isGenerating]="isGenerating"></bizz-from-word-template>
</ng-template>

<ng-template #formReportTabTemplateRef>
    <bizz-form-report (onError)="onError()" [submit]="submit$" [isGenerating]="isGenerating"></bizz-form-report>
</ng-template>

<ng-template #footerTemplateRef>
    <div class="modal-buttons">
        <bizz-loader-button [class.invisible]="!activeTabTemplateRef" (click)="submit()" [isLoading]="isGenerating"
            [class.pointer-events-none]="isGenerating || (activeTab == ReportModalTabs.FromWordTemplate && selectedFormat == null)"
            [isEnabled]="activeTab == ReportModalTabs.FromWordTemplate && selectedFormat != null"
            [disabled]="activeTab == ReportModalTabs.FromWordTemplate && selectedFormat == null" buttonClass="save">
            {{ 'Generate' | translate }}
        </bizz-loader-button>
    </div>
</ng-template>