import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HeaderTasksEffects } from './header-tasks-effects';
import { headerTasksFeature } from './header-tasks-feature';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(headerTasksFeature),
    EffectsModule.forFeature(HeaderTasksEffects)
  ]
})
export class HeaderTasksFeatureStoreModule {
}
