<div class="modal-container">
  <div class="modal-close-container">
    <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
               (click)="dialogRef.close()"
               iconName="xmark" [hover]="true">
    </bizz-icon>
  </div>

  <div class="modal-body">
    <div class="modal-header">
      {{"ViewAssessments" | translate}}
    </div>
    <hr class="my-5" />
    <div>
        <ng-scrollbar [visibility]="'hover'" style="height: 100%">
            <bizz-assessment-grid class="max-w-[1000px] text-start font-medium text-widget" 
            [assessmentList]="assessmentList"></bizz-assessment-grid>
          </ng-scrollbar>
    </div>
  </div>

  <div class="modal-buttons">
    <button class="save min-w-16" type="button" (click)="dialogRef.close()">
      {{'Ok' | translate}}
    </button>
  </div>
</div>