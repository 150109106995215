import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../../../core/services/auth/auth.service';
import { Claims } from '../../../core/constants/claims';

export const dispatchReadOnlyGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  try {
    return inject(Router).createUrlTree([`workspace/${inject(AuthService).getAccessTokenClaim(Claims.DefaultWorkspacesID)}/form/collectionsid/${route.params["collectionId"]}/instancesid/${route.params["instanceId"]}/versionsid/${route.params["versionId"]}/readonly`], {queryParams: route.queryParams});
  } catch (e) {
    return false;
  }
};


export const dispatchTaskGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  try {
    return inject(Router).createUrlTree([`workspace/${inject(AuthService).getAccessTokenClaim(Claims.DefaultWorkspacesID)}/form/task/${route.params["taskId"]}`], {queryParams: route.queryParams});
  } catch (e) {
    return false;
  }
};

export const dispatchRevisionGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  try {
    return inject(Router).createUrlTree([`workspace/${inject(AuthService).getAccessTokenClaim(Claims.DefaultWorkspacesID)}/form/revisiontask/${route.params["taskId"]}`], {queryParams: route.queryParams});
  } catch (e) {
    return false;
  }
};

