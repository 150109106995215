<div class="inline-flex justify-center flex-col items-center relative ml-1">
  <span kendoPopoverAnchor [popover]="bizzPopover" [showOn]="showOn">
    <bizz-icon iconName="circle-info"
               class="w-4 h-4 mt-2 cursor-pointer"></bizz-icon>
  </span>
  <kendo-popover
      #bizzPopover
      [title]="title"
      [body]="content"
      [width]="width"
      [animation]="true"
      [position]="'top'"
  >
  </kendo-popover>
</div>