<div class="modal-container w-[900px]">
    <div class="modal-close-container">
        <bizz-icon iconName="xmark" (click)="dialogRef.close(false)"></bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1>
                {{ data.notificationDetail.Subject | notificationFilterPipe }}</h1>
            <hr class="m-auto mb-5 mt-5 h-0 w-full border-t-2 border-divider">
        </div>
        <div class="html-notification w-full grow overflow-auto" style="padding: 0 34px 0 34px">
            <p class="mb-3">{{ 'CreationDate' | translate }}
                : {{ data.notificationDetail.Date | date:'M/d/yy, h:mm a' }}</p>
            <p class="mb-6">{{ 'CreatedBy' | translate }}: {{ data.notificationDetail.CreatedBy }}</p>
            <div [innerHtml]="data.notificationDetail.Body | notificationFilterPipe | safe: 'html'"></div>


            <hr class="m-auto h-0 w-full border-t-2 border-divider" style="margin-top: 18px">
        </div>
        <div class="modal-buttons pt-4">
            <button class="default" (click)="viewDetails()">
                <bizz-icon class="mr-2.5 size-5 sm:size-4" iconName="eye"></bizz-icon>
                {{ 'ViewDetails' | translate }}
            </button>
            <button class="default" (click)="flowStatus()">
                <bizz-icon class="mr-2.5 size-5 sm:size-4" iconName="chart-line"></bizz-icon>
                {{ 'FlowStatus' | translate }}
            </button>
        </div>
    </div>
</div>

