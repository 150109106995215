import { Injectable } from '@angular/core';
import { FlowStatusTaskDto } from '../../../../../models/ts/flow-status-task-dto.model';
import { TaskStateType } from '../../../../../models/ts/task-state-type.model';
import { TaskType } from '../../../../../models/ts/task-type.model';

@Injectable({
  providedIn: 'root',
})
export class FlowStatusTaskService {

  /**
   * Returns all the pending tasks from a tasks list
   * @param tasks
   */
  public static filterPendingTasks(tasks: FlowStatusTaskDto[]): FlowStatusTaskDto[] {
    return tasks.filter((task) => {
      return (task.TaskStateType === TaskStateType.Open ||
          task.TaskStateType === TaskStateType.Timed ||
          task.TaskStateType === TaskStateType.Expired) &&
        (task.TaskType === TaskType.Task ||
          task.TaskType === TaskType.TimerStepTask ||
          task.TaskType === TaskType.TrainingAppExaminationTask);
    });
  }

  /**
   * Returns all the completed tasks from a tasks list
   * @param tasks
   */
  public static filterCompletedTasks(tasks: FlowStatusTaskDto[]): FlowStatusTaskDto[] {
    return tasks.filter((task) => {
      return (task.TaskStateType == TaskStateType.Completed ||
          task.TaskStateType == TaskStateType.Cancelled) &&
        (task.TaskType === TaskType.Task ||
          task.TaskType === TaskType.TimerStepTask ||
          task.TaskType === TaskType.TrainingAppExaminationTask);
    });
  }


}
