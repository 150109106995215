import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Align, Collision, PopupModule } from '@progress/kendo-angular-popup';
import { FormButtonStates } from '../../classes/form-button.states';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { ToggleArrowComponent } from '../../../../../shared/components/ui/toggle-arrow/toggle-arrow.component';
import { KendoPopupContains } from '../../../../../shared/functions/kendo/kendo-popup-contains';
import { GridService } from '../../../widgets/collection-list-widget/services/grid/grid.service';
import { CollectionForm } from 'src/models/ts/collection-form.model';
import { LinkingService } from 'src/app/core/services/linking/linking.service';
import { GridContextPermissions } from '../../../widgets/collection-list-widget/classes/grid-context-permissions';
import { GridContextPermissionsMetadata } from '../../../widgets/collection-list-widget/interfaces/grid-context-permissions-metadata';
import { userSettingsFeature } from 'src/app/store/features/user-settings/user-settings-feature';
import { UserType } from 'src/models/ts/user-type.model';
import { Store } from '@ngrx/store';
import { ListDesignGridOptionsDto } from 'src/models/ts/list-design-grid-options-dto.model';
import { CollectionListApiService } from 'src/app/api/bizzmine/collection-list/collection-list-api.service';
import { take } from 'rxjs';
import { selectByCollectionId } from 'src/app/store/features/collections/collections-selectors';
import { CollectionType } from 'src/models/ts/collection-type.model';
import { TaskService } from 'src/app/shared/services/tasks/task.service';
import { workspaceSidebarFeature } from 'src/app/store/features/workspace-sidebar/workspace-sidebar-feature';
import { StoreCollectionForm } from '../../../../../store/features/forms/forms-state';
import { FormGroup } from '@angular/forms';

/**
 * Displays the header/top navigation of a form.
 * Emits events when the user clicks on the save or cancel button.
 */
@Component({
  selector: 'bizz-form-header',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslatePipe, PopupModule, ToggleArrowComponent],
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss']
})
export class FormHeaderComponent implements OnInit {
  @Input() public readMode: boolean;
  @Input({ required: true }) public buttonStates: FormButtonStates;
  @Input() public dataItem: StoreCollectionForm;
  @Input() public formGroup: FormGroup;
  @Output() public canceled = new EventEmitter<void>();
  @Output() public submitted = new EventEmitter<{ closeStep: boolean, closeForm: boolean }>();
  @Output() public editClicked = new EventEmitter<void>();
  public showPropertiesPopup = false;
  public showSavePopup = false;
  public popupCollision: Collision = { horizontal: 'flip', vertical: 'fit' };
  public popupAnchorAlignProperties: Align = { horizontal: 'left', vertical: 'bottom' };
  public popupAnchorAlignSave: Align = { horizontal: 'right', vertical: 'bottom' };
  public popupAlign: Align = { horizontal: 'right', vertical: 'top' };
  public readonly UserType = UserType;

  @ViewChild('saveAnchor', { static: true }) public saveAnchor: ElementRef;
  @ViewChild('savePopup', { static: true }) public savePopup: ElementRef;

  @ViewChild('propertiesAnchor', { static: true }) public propertiesAnchor: ElementRef;
  @ViewChild('propertiesPopup', { static: true }) public propertiesPopup: ElementRef;
  private workspaceIdSignal = this.store$.selectSignal(workspaceSidebarFeature.selectID);
  private permissions: GridContextPermissions;
  private metaData: GridContextPermissionsMetadata;

  public constructor(private gridService: GridService,
                     private linkingService: LinkingService,
                     private collectionListApiService: CollectionListApiService,
                     private store$: Store, private taskService: TaskService) {
  }

  public get userType(): UserType {
    return this.store$.selectSignal(userSettingsFeature.selectUserType)();
  }

  public get userId(): UserType {
    return this.store$.selectSignal(userSettingsFeature.selectUserID)();
  }
  
  public get form(): CollectionForm {
    return this.dataItem.data;
  }

  public ngOnInit(): void {
    this.store$.select(selectByCollectionId(this.form.CollectionsID)).subscribe((res) => {
     
        this.metaData = {
          userType: this.userType,
          userId: this.userId,
          trashFilterActive: false,
          isDocumentTreeGrid: false,
          listOptions: res?.data.ListOptions as ListDesignGridOptionsDto,
          collectionType: res?.data.CollectionsType as CollectionType,
          selectedTreeNode: null,
          managerTrainingQueue: false
        };
    });
  }

  /**
   * Closes the popup if the user clicks outside the popup or anchor.
   * @param event
   */
  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (this.showSavePopup && event.target && !KendoPopupContains(event.target, this.saveAnchor, this.savePopup)) {
      this.toggleSavePopup(undefined, false);
    }
    if (this.showPropertiesPopup && event.target && !KendoPopupContains(event.target, this.propertiesAnchor, this.propertiesPopup)) {
      this.togglePropertiesPopup(undefined, false);
    }
  }

  public copyLinkToRecord(): void {
    this.linkingService.copyLinkToRecord(this.form.CollectionsID, this.form.InstancesID, this.form.VersionsID, this.form.GuidCheckSum);
  }

  public openPermissions(): void {
    this.gridService.openPermissionsPage(this.form.CollectionsID, this.form.InstancesID, this.form.VersionsID, this.form.FoldersID, true);

  }

  public openDocumentSettings(): void {
    this.gridService.openDocumentSettings(this.form.CollectionsID, this.form.InstancesID, this.form.VersionsID);
  }

  public openFlowStatus(): void {
    this.gridService.openFlowStatusPage(this.form.CollectionsID, this.form.InstancesID, this.form.VersionsID, this.form.MethodType, this.workspaceIdSignal(), true);
  }

  public openHistory(): void {
    this.gridService.openHistoryPage(this.form.CollectionsID, this.form.InstancesID, this.form.VersionsID);
  }

  public openTrackChanges(): void {
    this.gridService.openTrackchangesPage(this.form.CollectionsID, this.form.InstancesID, this.form.VersionsID);
  }

  public openMonitor(): void {
    this.gridService.openMonitorModal(this.form.CollectionsID, this.form.InstancesID, this.form.VersionsID);
  }

  public openArchive(): void {
    this.collectionListApiService.getCollectionInstancefromList(this.form.CollectionsID, this.form.InstancesID)
      .pipe(take(1))
      .subscribe((data) => {
        this.permissions = new GridContextPermissions();
        this.permissions.setMetaData(this.metaData);
        this.permissions.loadMenuPermissions(data);

        this.gridService.openArchiveModal(structuredClone(data), this.permissions);
      });
  }

  public openReport(): void {
    this.gridService.openReportModal(this.form.CollectionsID, this.form.InstancesID, this.form.VersionsID, this.form.ReportSettings);
  }

  public forwardTask(): void {
    this.taskService.forwardTask(this.form.TasksID, this.form.CollectionsID);
  }

  public submit(closeStep: boolean, closeForm: boolean): void {
    this.submitted.emit({ closeStep, closeForm });
  }

  public cancel(): void {
    this.canceled.emit();
  }

  public togglePropertiesPopup(event?: MouseEvent, value?: boolean): void {
    this.showPropertiesPopup = value != undefined ? value : !this.showPropertiesPopup;
    if (event)
      event.stopImmediatePropagation();
  }

  public toggleSavePopup(event?: MouseEvent, value?: boolean): void {
    this.showSavePopup = value != undefined ? value : !this.showSavePopup;
    if (event)
      event.stopImmediatePropagation();
  }
}
