<ng-template #toolbarTemplate>

    <div class="border-0">
        <kendo-button #anchor (click)="onToggle()" >
            <span class="flex gap-1.5 py-0.5">
                <bizz-icon class="size-4 align-middle" iconName="circle-xmark"></bizz-icon>
                <bizz-icon class="size-4 align-middle transition-transform" iconName="caret-down"
                    [ngClass]="{'rotate-180': show }"></bizz-icon>
            </span>
        </kendo-button>
    </div>

    @if (show) {
        <kendo-popup
                #popup
                [anchor]="anchor.element"
                (anchorViewportLeave)="show = false"
                [margin]="{ horizontal: 0, vertical: 10 }"
        >
                <div class="flex h-full w-full cursor-pointer flex-col gap-1.5 p-2.5">
                    @if(containsAction(CustomCanvasActions.RevertImage)){
                    <div class="flex flex-row gap-2 rounded p-2 align-middle text-default hover:bg-button"
                    (click)="revertImage.next()">
                            <bizz-icon class="size-4 self-center" iconName="rotate-left"></bizz-icon>
                            
                        {{'RevertToDefaultImage' | translate }}
                    </div>
                    }

                    @if(containsAction(CustomCanvasActions.RemoveStrokes)){
                    <div class="flex flex-row gap-2 rounded p-2 align-middle text-default hover:bg-button"
                         (click)="removeStrokes.next()">
                        <bizz-icon class="size-4 self-center text-danger" iconName="pencil-slash"></bizz-icon>
                        {{'RemoveStrokes' | translate }}
                    </div>
                    }

                    @if(containsAction(CustomCanvasActions.RemoveImage)){
                    <div class="flex flex-row gap-2 rounded p-2 align-middle text-default hover:bg-button"
                         (click)="removeImage.next()">
                        <bizz-icon class="size-4 self-center text-danger" iconName="image-slash"></bizz-icon>
                        {{'RemoveImage' | translate }}
                    </div>
                    }

                    @if(containsAction(CustomCanvasActions.RemoveAll)){
                    <div class="flex flex-row gap-2 rounded p-2 align-middle text-default hover:bg-button" (click)="removeAll.next()">
                        <bizz-icon class="size-4 self-center text-danger" iconName="circle-xmark"></bizz-icon>
                        {{'RemoveAll' | translate }}
                    </div>
                    }

                </div>
        </kendo-popup>
    }

</ng-template>

<ng-template #popupTemplate>
    <div class="border-0">
        <kendo-button #anchor (click)="onToggle()">
            <span class="flex gap-1.5 py-0.5">
                <bizz-icon class="size-4 align-middle text-danger" iconName="circle-xmark"></bizz-icon>
                <bizz-icon class="size-4 align-middle transition-transform" iconName="caret-down"
                [ngClass]="{'rotate-180': show }"></bizz-icon>
            </span>
        </kendo-button>
    </div>

    @if (show) {
        <kendo-popup
                #popup
                [anchor]="anchor.element"
                (anchorViewportLeave)="show = false"
                [margin]="{ horizontal: 0, vertical: 10 }"
        >
            <div class="flex h-full w-full cursor-pointer flex-col gap-1.5 p-2.5">
                @if(containsAction(CustomCanvasActions.RevertImage)){
                    <div class="flex flex-row gap-1 rounded p-2 align-middle hover:bg-button"
                         (click)="revertImage.next()">
                        <bizz-icon class="size-4 self-center" iconName="rotate-left"></bizz-icon>
                        {{'RevertToDefaultImage' | translate }}
                    </div>
                }

                @if(containsAction(CustomCanvasActions.RemoveStrokes)){
                    <div class="flex flex-row gap-1 rounded p-2 align-middle hover:bg-button"
                         (click)="removeStrokes.next()">
                        <bizz-icon class="size-4 self-center text-danger" iconName="pencil-slash"></bizz-icon>
                        {{'RemoveStrokes' | translate }}
                    </div>
                }

                @if(containsAction(CustomCanvasActions.RemoveImage)){
                    <div class="flex flex-row gap-1 rounded p-2 align-middle hover:bg-button"
                         (click)="removeImage.next()">
                        <bizz-icon class="size-4 self-center text-danger" iconName="image-slash"></bizz-icon>
                        {{'RemoveImage' | translate }}
                    </div>
                }

                @if(containsAction(CustomCanvasActions.RemoveAll)){
                    <div class="flex flex-row gap-1 rounded p-2 align-middle hover:bg-button" (click)="removeAll.next()">
                        <bizz-icon class="size-4 self-center text-danger" iconName="circle-xmark"></bizz-icon>
                        {{'RemoveAll' | translate }}
                    </div>
                }

            </div>
        </kendo-popup>
    }
</ng-template>
