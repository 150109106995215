<bizz-stepper [wizard]="false" [scrollable]="true" class="sm: mt-6 block" [currentStep]="activeTab" [steps]="steps"
(currentStepChange)="toggleTemplateRef($event)"></bizz-stepper>

<ng-container [ngTemplateOutlet]="activeTabTemplateRef">
</ng-container>


<ng-template #minutesTabTemplateRef>
    <bizz-cron-minutes [(state)]="state.minutes" (stateChange)="regenerateCron()"></bizz-cron-minutes>
</ng-template>
<ng-template #hourlyTabTemplateRef>
    <bizz-cron-hourly [(state)]="state.hourly" (stateChange)="regenerateCron()"></bizz-cron-hourly>
</ng-template>
<ng-template #dailyTabTemplateRef>
    <bizz-cron-daily [(state)]="state.daily" (stateChange)="regenerateCron()"></bizz-cron-daily>
</ng-template>
<ng-template #weeklyTabTemplateRef>
    <bizz-cron-weekly [(state)]="state.weekly" (stateChange)="regenerateCron()"></bizz-cron-weekly>
</ng-template>
<ng-template #monthlyTabTemplateRef>
    <bizz-cron-monthly [(state)]="state.monthly" (stateChange)="regenerateCron()"></bizz-cron-monthly>
</ng-template>
<ng-template #yearlyTabTemplateRef>
    <bizz-cron-yearly [(state)]="state.yearly" (stateChange)="regenerateCron()"></bizz-cron-yearly>
</ng-template>
<ng-template #advancedTabTemplateRef>
    <bizz-cron-advanced [(state)]="state.advanced.expression" (stateChange)="regenerateCron()"></bizz-cron-advanced>
</ng-template> 