<div class="h-1 w-full sm:h-2.5 sm:rounded-t" [class]="accentClass">
</div>
<div [tabIndex]="0" class="mx-4 my-3 flex flex-col sm:mx-5 sm:my-1.5 sm:rounded-t" (click)="toggleAccordion()" (keydown.enter)="toggleAccordion()">
  <div class="flex cursor-pointer items-center gap-4 sm:gap-2">
    <ng-container *ngIf="canCollapse">
      <ng-container *ngTemplateOutlet="!isOpen ? collapse : expand">
      </ng-container>
      <ng-template #collapse>
        <bizz-icon [title]="'Collapse' | translate" class="h-5 w-5 text-form-header sm:h-4 sm:w-4"
                   [hover]="true" iconName="caret-right"></bizz-icon>
      </ng-template>
      <ng-template #expand>
        <bizz-icon [title]="'Expand' | translate" class="h-5 w-5 text-form-header sm:h-4 sm:w-4"
                   [hover]="true" iconName="caret-down"></bizz-icon>
      </ng-template>
    </ng-container>
    <ng-content></ng-content>
  </div>
  <hr class="mb-2 mt-1 hidden sm:mb-0 sm:inline-block" [class]="{ '!hidden': !isOpen }" />
</div>

