export enum CollectionMethodType {
	NoMethod = 0,
	ReadDocPDF = 2,
	ReadProperties = 3,
	EditProperties = 4,
	AccessHistory = 5,
	AccessArchives = 6,
	Create = 7,
	ExportBulk = 8,
	RevisionWithMinorVersionChange = 13,
	RevisionWithMajorVersionChange = 14,
	DeleteToTrashCan = 16,
	RecoverFromTrashCan = 18,
	AccessTrashCan = 20,
	AccessTrackChanges = 21,
	RestartWorkFlow = 22,
	ForwardTasks = 23
}