import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridOptions } from 'src/app/shared/classes/list/grid-options';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap
} from 'rxjs';
import { CollectionListDataInstance } from 'src/app/shared/interfaces/collection-list-data-instance';
import { AuditLogGridSearchDto } from 'src/models/ts/audit-log-grid-search-dto.model';
import { GridGroupSortDto } from 'src/models/ts/grid-group-sort-dto.model';
import { GridFilterDto } from 'src/models/ts/grid-filter-dto.model';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { GroupDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { ListDesignGridOptionsDto } from 'src/models/ts/list-design-grid-options-dto.model';
import { AsyncPipe } from '@angular/common';
import { TrackChangesParams } from '../../interfaces/track-changes-params.interface';
import { TrackChangesApiService } from 'src/app/api/bizzmine/track-changes/track-changes-api.service';
import { GridDataDto } from 'src/models/ts/grid-data-dto.model';
import { GridComponent } from '../../../../../shared/components/grid/grid.component';

@Component({
  selector: 'bizz-track-changes-grid',
  templateUrl: './track-changes-grid.component.html',
  styleUrl: './track-changes-grid.component.scss',
  imports: [GridComponent, AsyncPipe],
  standalone: true


})
export class TrackChangesGridComponent implements OnInit {
  @Input() public data: TrackChangesParams;
  @Input() public searchTerm: string;
  @Input() public searchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public debouncedSearchTerm$: Observable<string>;

  @Output() public listSearchFilterChange: EventEmitter<Partial<AuditLogGridSearchDto>> = new EventEmitter<Partial<AuditLogGridSearchDto>>();
  public listOptions: ListDesignGridOptionsDto;
  public gridOptions: GridOptions;
  public loading: boolean = false;
  public currentPage = 1;
  /**
   * Filter data
   */
  public filterData: GridFilterDto | undefined;
  public listSearchFilter$: BehaviorSubject<Partial<AuditLogGridSearchDto> | undefined> =
    new BehaviorSubject<Partial<AuditLogGridSearchDto> | undefined>(undefined);
  public gridData: BehaviorSubject<CollectionListDataInstance[] | any[]> = new BehaviorSubject<CollectionListDataInstance[] | any[]>([]);
  public filterWithSearchTerm$: Observable<Partial<AuditLogGridSearchDto> | undefined>;
  public gridResultData$: Observable<{ totalItems: number; activeFilter: boolean; }>;

  public constructor(private trackChangesService: TrackChangesApiService, private destroyRef: DestroyRef) {

  }

  public ngOnInit(): void {
    this.loading = true;
    this.debouncedSearchTerm$ = this.searchTerm$.pipe(debounceTime(300));
    this.filterWithSearchTerm$ = combineLatest([this.debouncedSearchTerm$, this.listSearchFilter$])
      .pipe(map((found) => {
        const filter = found[1];
        if (filter) {
          filter.Search = found[0];
        }
        return filter;
      }));

    this.gridResultData$ = this.filterWithSearchTerm$.pipe(
      filter((search) => search != undefined),
      tap(() => {
        this.loading = true;
      }),
      switchMap((search) => {
        if(this.data.mailId != null && this.data.mailId > 0){
          return this.trackChangesService.getMailTrackChanges({
            instanceId: this.data.instanceId,
            mailId: this.data.mailId,
            collectionId: this.data.collectionId,
            versionId: this.data.versionId,
            gridOptions: this.data.gridOptions
          })
        }
        return this.trackChangesService.getTrackChanges(this.data.collectionId, this.data.instanceId, this.data.versionId, this.data.auditlogId, search!)
      }),
      tap((value: GridDataDto) => {
        this.gridData.next(value.Data);
        this.loading = false;
      }),
      switchMap((value: GridDataDto) => {
        return of({
          totalItems: value.TotalRecords,
          activeFilter: value.ActiveSearchfilter
        });
      }),
      shareReplay(1));

    this.gridOptions = new GridOptions(this.data.gridOptions);
    this.gridOptions.autoSize = true;
    this.fetchSearchData();
  }


  public fetchSearchData(): void {
    const searchFilter = {
      SearchFilters: this.filterData,
      Page: this.currentPage,
      Records: this.gridOptions?.pageSize,
      Group: this.gridOptions?.dataSource?.group as GridGroupSortDto[],
      Sort: this.gridOptions?.dataSource?.sort as GridGroupSortDto[]
    };
    this.listSearchFilter$.next(searchFilter);
    this.listSearchFilterChange.emit(searchFilter);

  }

  public onPageChange(event: PageChangeEvent): void {
    this.currentPage = (event.skip / event.take) + 1; // Update the current page number
    this.gridOptions.pageSize = event.take;
    this.fetchSearchData();
  }

  public onGroupChange(groups: Array<GroupDescriptor>): void {
    this.gridOptions.dataSource.group = groups;
    this.fetchSearchData();
  }

  public onSortChange(sorting: Array<SortDescriptor>): void {
    this.gridOptions.dataSource.sort = sorting;
    this.fetchSearchData();
  }
}
