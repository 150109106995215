<!--Screen-->

<div class="max-w-svw h-svh max-h-svh w-svw bg-body">
    <bizz-header [mode]="HeaderComponentMode">
    </bizz-header>
    <div class="relative h-[calc(100%_-_48px)] w-full sm:block">
        @if (state == ExternalUserTokenState.DEFAULT) {
            <bizz-view-stack
                    class="h-full w-full max-w-full">
            </bizz-view-stack>
        } @else {
           <bizz-external-completed [title]="title" [info]="info"></bizz-external-completed>
        }
    </div>
</div>
