import { Component, DestroyRef, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from "../icon/icon.component";
import { TranslatePipe } from "../../../pipes/translate/translate.pipe";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, BehaviorSubject } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'bizz-search',
  standalone: true,
  imports: [IconComponent, TranslatePipe, CommonModule, FormsModule],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent {
  @Input() public placeholder = '';
  @Input() public searchTerm: string | undefined = '';
  @Output() public searchTermChange = new EventEmitter<string>();
  public searchTerm$ = new BehaviorSubject<string | undefined>(this.searchTerm);

  public constructor(private destroyRef: DestroyRef) { 
    this.searchTerm$.pipe(
      takeUntilDestroyed(this.destroyRef), 
      debounceTime(250))
    .subscribe(() =>  this.searchTermChange.emit(this.searchTerm));
  }

  public searchTermChanged(): void {
    this.searchTerm$.next(this.searchTerm);
  }
  

}
