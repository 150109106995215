import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BizzmineHttpclientService {

  public httpClient = inject(HttpClient);
  public http = this.httpClient;

}



declare global {
  interface String {
    appendChecksum(checkSum: string | undefined): string;
  }
}

String.prototype.appendChecksum = function (checkSum: string | undefined): string {
  if(checkSum == undefined){
    return this.toString();
  }
  return this?.includes('?') ? `${this}&checksum=${checkSum}` : `${this}?checksum=${checkSum}`;
};