<div class="flex gap-2 border-b-2 border-sidebar bg-page-header p-4 sm:px-5 sm:py-2.5 lg:gap-4">
    <div class="button-group">
        <button type="button" class="form-header-button default animate-pulse">
            <span class="w-24"></span>
        </button>
    </div>

    <button class="form-header-button default ml-auto animate-pulse">
        <span class="w-24"></span>
    </button>

    <div class="button-group">
        <button class="form-header-button bg-green-500/25 border-green-500 animate-pulse" type="button">
            <span class="w-24"></span>
        </button>
        <button class="form-header-button bg-green-500/25 border-green-500 animate-pulse" type="button">
        </button>
    </div>

</div>
<div class="absolute left-0 top-0 z-40 h-full w-full">
    <div class="absolute left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 transform">
        <svg class="h-12 w-12 animate-spin fill-current text-default" xmlns="http://www.w3.org/2000/svg"
             height="1em" viewBox="0 0 512 512">
            <defs>
                <style>.fa-secondary {
                    opacity: 0.4;
                }</style>
            </defs>
            <path class="fa-primary" d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3
          128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7
          0-32-14.3-32-32z"/>
            <path class="fa-secondary" d="M256 64C150 64 64 150 64 256s86 192 192 192c70.1 0 131.3-37.5 164.9-93.6l.1
          .1c-6.9 14.9-1.5 32.8 13 41.2c15.3 8.9 34.9 3.6 43.7-11.7c.2-.3 .4-.6 .5-.9l0 0C434.1 460.1 351.1 512 256
          512C114.6 512 0 397.4 0 256S114.6 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z"/>
        </svg>
    </div>
</div>


<div class="flex h-[calc(100%_-_5rem)] w-full max-w-full flex-col overflow-y-auto sm:gap-5 sm:p-5">

    @for (elements of config; track $index) {
        <bizz-view-stack-skeleton-accordion [config]="getAccordionConfig(elements)"></bizz-view-stack-skeleton-accordion>
    }


</div>

