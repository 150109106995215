export enum UserType {
	Unknown = -1,
	User = 0,
	Administrator = 1,
	Owner = 2,
	LightUser = 3,
	ExternalUser = 4,
	API = 100,
	CurrentUser = 101,
	Scheduler = 102,
  AnonymousUser = 103,
}
