<div class="modal-container justify-between md:justify-normal">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0" (click)="dialogRef.close()" iconName="xmark"
            [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body">
        <div class="model-header">
            <h1 class="text-title font-title text-xl font-bold">
                {{ 'SelectCollection' | translate }}</h1>
        </div>

        <div class="h-9.5 relative mt-6 box-border flex w-full flex-row items-center justify-end">

            <input #inputFocus id="filter" class="border-widget-header h-8 rounded-md focus:shadow-md sm:h-10"
                [ngModel]="search | async" (ngModelChange)="search.next($event)" placeholder="{{'Search' | translate}}">

            <bizz-icon
                class="pointer-events-none absolute mr-2 size-4 cursor-pointer text-button sm:pointer-events-auto"
                iconName="magnifying-glass"> </bizz-icon>
        </div>

        <div class="row-1 mx-auto mb-2 mt-6 grid h-96 grid-cols-4 gap-5 overflow-hidden" >
            @for (dataItem of filteredDataItems; track $index){
            <bizz-collection-button class="block h-28 w-full" (click)="dialogRef.close(dataItem)"
                [icon]="dataItem.Icon" [caption]="dataItem.ViewName"></bizz-collection-button>
            }
        </div>

        <kendo-datapager [style.width.%]="100" [pageSize]="pageSize"
            [skip]="(currentPageInternal - 1) * pageSize" [total]="dataItems.length"
            (pageChange)="onPageChange($event)" [size]="'large'">
            <ng-template kendoDataPagerTemplate>
                <div class="flex w-full items-center justify-between">
                    <kendo-datapager-info class="hidden md:block"></kendo-datapager-info>
                    <div class="flex w-full items-center justify-between gap-0.5 md:w-auto md:justify-start">
                      
                        @if (totalItems > pageSize) {
                        <div class="k-pager-numbers-wrap">
                            <kendo-datapager-prev-buttons></kendo-datapager-prev-buttons>
                       
                            <kendo-datapager-next-buttons></kendo-datapager-next-buttons>
                        </div>
                        }
                    </div>
                </div>
            </ng-template>
        </kendo-datapager>

    </div>
</div>