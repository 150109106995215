import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { trainingActions } from './training-actions';
import { exhaustMap, map, switchMap, tap } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { ExamEnrollmentModalComponent } from '../../../features/training/exam/modals/exam-enrollment-modal/exam-enrollment-modal.component';
import { TrainingApiService } from '../../../api/bizzmine/training/training-api.service';

export interface TrainingEffectConfig {
  openExamDialog: boolean;
  redirectUrl?: string;
}

@Injectable()
export class TrainingEffects {
  private openExamDialog$ = createEffect(() => this.actions$.pipe(
    ofType(trainingActions.examFetched),
    tap(data => {
      if (data?.config?.openExamDialog == true) {
        this.dialog.open(ExamEnrollmentModalComponent, {
          data: {
            exam: data.exam,
            redirectUrl: data.config.redirectUrl
          }
        });
      }
    })
  ), { dispatch: false });

  private fetchExam$ = createEffect(() => this.actions$.pipe(
    ofType(trainingActions.fetchExam),
    switchMap(intent => this.trainingApiService.getExamDetails({
        collectionsID: intent.collectionsId,
        versionsID: intent.versionsId
      })
        .pipe(
          map(found => ({ type: trainingActions.examFetched.type, exam: found, config: intent.config }))
        )
    )
  ));

  public constructor(
    private actions$: Actions,
    private trainingApiService: TrainingApiService,
    private dialog: Dialog
  ) {
  }


}