export function getFileExtension(fileName: string): string {
  return '.' + fileName.slice((Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1);
}

export function getFileName(fileName: string): string {
  return fileName.slice(0, (Math.max(0, fileName.lastIndexOf('.')) || Infinity));
}

export function getFileSize(fileName: number): string {
  //TODO: KB needs to be added but can't because of input type & math.ceil
  //eturn (fileName / 1000 + "kB");
  return Math.ceil(fileName / 1000).toString();
}