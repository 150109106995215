<div class="flex">
    @for (skill of skills; track $index) {
        <div class="flex-1 size-4 flex justify-center items-center" (mouseover)="mouseOver($index)"
             (mouseout)="mouseOut()">
            @if ($index > 0) {
                <div
                        [class.bg-form-control-border]="selectedIndex < $index && (mouseOverIndex == undefined || mouseOverIndex < $index)"
                        [class.bg-green-500]="(selectedIndex >= $index && mouseOverIndex == undefined) || (mouseOverIndex != undefined && mouseOverIndex >= $index)"
                        class="w-full flex-1" style="height: 3px;"></div>
            } @else {
                <div class="w-full flex-1" style="height: 3px;"></div>
            }
            <div
                    [class.bg-card]="selectedIndex < $index && (mouseOverIndex == undefined || mouseOverIndex < $index)"
                    [class.border-form-control]="selectedIndex < $index && (mouseOverIndex == undefined || mouseOverIndex < $index)"
                    [class.bg-green-500]="(selectedIndex >= $index && mouseOverIndex == undefined) || (mouseOverIndex != undefined && mouseOverIndex >= $index)"
                    [class.border-green-500]="(selectedIndex >= $index && mouseOverIndex == undefined) || (mouseOverIndex != undefined && mouseOverIndex >= $index)"
                    (click)="onSkillSelect(skill)"
                    kendoTooltip
                    [title]="skill.Name"
                    class="size-4 flex justify-center items-center m-0 p-0 rounded-3xl border-2 cursor-pointer">

            </div>
        </div>
    }
</div>