import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { Subject } from 'rxjs';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { FormsModule } from '@angular/forms';
import { IconComponent } from '../../../ui/icon/icon.component';
import { CommonModule } from '@angular/common';
import { ResetPasswordDto } from '../../../../../../models/ts/reset-password-dto.model';
import { Error } from '../../../ui/error/error';
import { UserSettingsApiService } from '../../../../../api/bizzmine/user-settings/user-settings-api.service';
import { AlertComponent } from '../../../ui/alert/alert.component';

@Component({
  selector: 'user-settings-password-reset-modal',
  templateUrl: './user-settings-password-reset-modal.component.html',
  styleUrls: ['./user-settings-password-reset-modal.component.scss'],
  standalone: true,
  imports: [TranslatePipe, FormsModule, IconComponent, CommonModule, Error, AlertComponent]
})
export class UserSettingsPasswordResetModalComponent implements OnInit, OnDestroy {
  // TODO: BV use formgroup
  public search: string;
  public resetPasswordDto: ResetPasswordDto;
  public error: string[] = [];
  private destroy = new Subject<boolean>();
  public combinedMessage = "";
  public oldPasswordVisisble = false;
  public newPasswordVisible = false;
  public confirmPasswordVisisble = false;


  public constructor(public dialogRef: DialogRef<boolean>,
              private userSettingsApiService: UserSettingsApiService) {
                this.dialogRef.disableClose = true;
  }
  public ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  public ngOnInit(): void {
    this.resetPasswordDto = new ResetPasswordDto();
  }

  public setNewPassword(resetPasswordDto: ResetPasswordDto): void {
    this.error = [];
    this.userSettingsApiService.resetPassword(resetPasswordDto).subscribe({
      // After
      error: (error: { error: { Errors: { Key: string, Message: string }[], IsValid: boolean } }) => {
        if (error.error.Errors) {
          this.combinedMessage = error.error.Errors.map(item => item.Message).join('. ');
        } else {
          this.combinedMessage = 'An error occurred';
        }
      },
      complete: () => {
        this.combinedMessage = "";
        this.dialogRef.close(true);
      },
    });
  }

}
