import { inject, Injectable } from '@angular/core';
import * as math from 'mathjs';
import { CollectionForm } from 'src/models/ts/collection-form.model';
import { LinkedCollectionType } from '../../../../../../models/ts/linked-collection-type.model';
import { CollectionFormFieldWithSubCollection } from '../../../../../../models/ts/collection-form-field-with-sub-collection.model';
import { FormulaTagType } from '../../../../../../models/ts/formula-tag-type.model';
import { CollectionFormFormulaTagDto } from '../../../../../../models/ts/collection-form-formula-tag-dto.model';
import { CollectionFormField } from '../../../../../../models/ts/collection-form-field.model';
import { CollectionFormFormulaDto } from '../../../../../../models/ts/collection-form-formulas-tag-dto.model';
import { CollectionFieldDto } from '../../../../../../models/ts/collection-field-dto.model';
import { Store } from '@ngrx/store';
import { CollectionFormService } from '../collection-form.service';
import { TableFieldDataType } from '../../../../../../models/ts/table-field-data-type.model';
import { formsActions } from '../../../../../store/features/forms/forms-actions';
import { UpdateDeleteState } from '../../../../../../models/ts/update-delete-state.model';

/**
 * Service for calculating formulas on collection forms.
 */
@Injectable({
  providedIn: 'root'
})
export class FormulaService {

  private store$ = inject(Store);

  /**
   * Checks whether a field is part of the provided formula.
   * @param {CollectionFormFormulaDto} formula
   * @param {number} fieldId
   * @return {boolean}
   */
  public static isFormulaFieldByFormFieldId(formula: CollectionFormFormulaDto, fieldId: number): boolean {
    return formula.Fields.findIndex(field => field.CollectionFormFieldsID == fieldId) !== -1 ||
      formula.Fields1x1.findIndex(field => field.CollectionFormFieldsID == fieldId) !== -1 ||
      formula.Fields1xN.findIndex(field => field.CollectionFormFieldsID == fieldId) !== -1 ||
      FormulaService.isGroupFormulaFieldByFormFieldId(formula.GroupFormulas, fieldId);
  }

  /**
   * Checks whether a field is part of the provided formula.
   * @param {CollectionFormFormulaDto} formula
   * @param {number} fieldId
   * @return {boolean}
   */
  public static isFormulaFieldByCollectionFieldId(formula: CollectionFormFormulaDto, fieldId: number): boolean {
    return formula.Fields.findIndex(field => field.CollectionFieldsID == fieldId) !== -1 ||
      formula.Fields1x1.findIndex(field => field.CollectionFieldsID == fieldId) !== -1 ||
      formula.Fields1xN.findIndex(field => field.CollectionFieldsID == fieldId) !== -1 ||
      FormulaService.isGroupFormulaFieldByCollectionFieldId(formula.GroupFormulas, fieldId);
  }

  /**
   * Checks whether a field is part of the group formula recursively.
   * @param {CollectionFormFormulaDto[]} groupFormulas
   * @param {number} fieldId
   * @return {boolean}
   */
  public static isGroupFormulaFieldByFormFieldId(groupFormulas: CollectionFormFormulaDto[], fieldId: number): boolean {
    return groupFormulas.findIndex(formula => FormulaService.isFormulaFieldByFormFieldId(formula, fieldId)) !== -1;
  }

  /**
   * Checks whether a field is part of the group formula recursively.
   * @param {CollectionFormFormulaDto[]} groupFormulas
   * @param {number} fieldId
   * @return {boolean}
   */
  public static isGroupFormulaFieldByCollectionFieldId(groupFormulas: CollectionFormFormulaDto[], fieldId: number): boolean {
    return groupFormulas.findIndex(formula => FormulaService.isFormulaFieldByCollectionFieldId(formula, fieldId)) !== -1;
  }

  /**
   * Returns all formulas where the provided field is part of the calculation.
   * @param {CollectionFormFormulaDto[]} formulas
   * @param {number} fieldId
   * @return {CollectionFormFormulaDto[]}
   */
  public static fieldFormulas(formulas: CollectionFormFormulaDto[], fieldId: number): CollectionFormFormulaDto[] {
    return formulas.filter(formula => FormulaService.isFormulaFieldByFormFieldId(formula, fieldId));
  }

  /**
   * Returns all formulas where any of the grid column fields are part of the calculation.
   * @param {CollectionFormFormulaDto[]} formulas
   * @param {CollectionFormField} grid
   * @return {CollectionFormFormulaDto[]}
   */
  public static gridFormulas(formulas: CollectionFormFormulaDto[], grid: CollectionFormField): Set<CollectionFormFormulaDto> {
    // Check if field is a grid
    if (!grid.GridOptions) return new Set();
    else if (grid.Records?.length == 0) return new Set();
    else {
      let fieldIds = grid.GridOptions.columns.map(c => Number.parseFloat(c.field.replace('F_', '')));
      return new Set<CollectionFormFormulaDto>(formulas.filter(formula => fieldIds.findIndex(fieldId => FormulaService.isFormulaFieldByCollectionFieldId(formula, fieldId)) > -1));
    }
  }

  private static calculateTags(tags: CollectionFormFormulaTagDto[], fields: CollectionFormField[], ignoreEmptyValues: boolean): string {
    let tagHasEmptyValue = false;
    let calculation = '';

    for (let i = 0, j = tags.length; i < j; i++) {

      // Find value of the tag if it's a field
      if (tags[i].Type == FormulaTagType.Field) {
        const val = fields.find(f => f.CollectionFieldsID == tags[i].CollectionFieldsID)?.Value;
        if (!val) {
          if (ignoreEmptyValues) {
            calculation += '0';
          } else {
            tagHasEmptyValue = true;
            break;
          }
        } else {
          calculation += val;
        }
      } else {
        calculation += tags[i].Tag;
      }
    }

    return tagHasEmptyValue ? 'empty' : math.evaluate(calculation);
  }

  // These functions used to disable the output field on form, but this is now handled by the BE through ReadOnlyPriority
  // Obsolete, kept around for possible future reference/debugging
  private static AllFieldsPresentOnForm(formula: CollectionFormFormulaDto, fields: CollectionFormField[]): boolean {
    return fields != null
      && fields.length > 0
      && FormulaService.checkIfSingleRecordFieldsAreOnForm(formula.Fields1x1, fields)
      && FormulaService.checkIfGridRecordFieldsAreOnForm(formula.Fields1xN, fields)
      && (formula.OutputField.SingleOrMany != LinkedCollectionType.GridRecord ? FormulaService.checkIfSingleRecordFieldsAreOnForm([formula.OutputField], fields) : FormulaService.checkIfGridRecordFieldsAreOnForm([formula.OutputField], fields))
      && FormulaService.checkIfGroupTagsArePresentOnForm(formula.GroupFormulas, fields);
  }

  private static checkIfSingleRecordFieldsAreOnForm(fields1x1: CollectionFieldDto[], fields: CollectionFormField[]): boolean {
    let present1x1 = true;
    let i = 0;

    while (present1x1 && i < fields1x1.length) {
      present1x1 = fields.findIndex(f => f.CollectionFieldsID == fields1x1[i].CollectionFieldsID) >= 0;
      i++;
    }

    return present1x1;
  }

  private static checkIfGridRecordFieldsAreOnForm(fields1xN: CollectionFieldDto[], fields: CollectionFormField[]): boolean {
    let present1xN = true;

    if (fields1xN.length == 0)
      return present1xN;

    const grid = FormulaService.getGridFrom1xNField(fields1xN[0], fields);
    // Grid is not found
    if (!grid)
      return false;

    // Check if all the fields are present in the grid options
    let i = 0;
    while (present1xN && i < fields1xN.length) {
      present1xN = grid.GridOptions.columns.some(c => c.field == 'F_' + fields1xN[i].CollectionFieldsID);
      i++;
    }

    return present1xN;
  }

  private static getGridFrom1xNField(field: CollectionFieldDto, fields: CollectionFormField[]): CollectionFormFieldWithSubCollection | undefined {
    // Get grids
    const grids = fields.filter(f => CollectionFormService.fieldIsGrid(f));
    if (grids.length == 0)
      return undefined;

    // Get grid of 1-N field
    return grids.find(g => g.GridOptions?.columns.some(c => c.field == 'F_' + field.CollectionFieldsID)) as CollectionFormFieldWithSubCollection;
  }

  private static checkIfGroupTagsArePresentOnForm(groupFormulas: CollectionFormFormulaDto[], fields: CollectionFormField[]): boolean {
    let present1x1 = true;
    let present1xN = true;
    let i = 0;

    while (present1x1 && present1x1 && i < groupFormulas.length) {
      const fields1x1 = groupFormulas[i].Fields.filter(f => f.SingleOrMany != LinkedCollectionType.GridRecord);
      const fields1xN = groupFormulas[i].Fields.filter(f => f.SingleOrMany == LinkedCollectionType.GridRecord);

      // Check 1x1 fields
      present1x1 = FormulaService.checkIfSingleRecordFieldsAreOnForm(fields1x1, fields);

      // Check grid fields
      present1xN = FormulaService.checkIfGridRecordFieldsAreOnForm(fields1xN, fields);

      i++;
    }

    return present1x1 && present1xN;
  }

  /**
   * Filters out all formula's that don't use the provided field, then calls the calculate function
   * @param {string} formId
   * @param {CollectionForm} form
   * @param {number} fieldId
   * @see calculate
   */
  public calculateField(formId: string, form: CollectionForm, fieldId: number) {
    form.Formulas = FormulaService.fieldFormulas(form.Formulas, fieldId);
    this.calculate(formId, form);
  }

  /**
   * Calculates all formulas on the provided form. Dispatches actions to update output fields on calculation completion.
   * @param formId
   * @param form
   */
  public calculate(formId: string, form: CollectionForm): void {
    const formulas = form.Formulas;

    if (formulas.length == 0)
      return;

    const fields = CollectionFormService.extractFieldsFromForm(form);
    const numericFields = fields.filter(f => f.ComponentType == TableFieldDataType.Numeric);

    for (let i = 0; i < formulas.length; i++) {
      const formula = formulas[i];
      if (formula.OutputField.SingleOrMany == LinkedCollectionType.GridRecord) {
        // Output is a grid field
        let grid;
        if (formulas[i].Fields1xN.length > 0)
          grid = FormulaService.getGridFrom1xNField(formula.Fields1xN[0], fields);
        else
          grid = FormulaService.getGridFrom1xNField(formula.OutputField, fields);

        if (grid !== undefined)
          for (let j = 0; j < grid.Records.length; j++) {
            const record = grid.Records[j];
            if (record.State == UpdateDeleteState.Delete)
              continue;

            let recordAndFormFields = record.Fields.concat(numericFields);

            let output = FormulaService.calculateTags(formula.Tags, recordAndFormFields, formula.IgnoreEmptyValues);

            if (output == 'empty')
              continue;

            let outputField = record.Fields.find(f => f.CollectionFieldsID == formula.OutputField.CollectionFieldsID);
            if (outputField !== undefined)
              this.store$.dispatch(formsActions.updateFormulaOutputGridField({
                formId: formId,
                gridFieldId: grid.Id,
                recordId: record.CrossLinkedInstancesID,
                collectionFieldId: outputField.CollectionFieldsID,
                value: output
              }));
          }
      } else if (formula.GroupFormulas.length > 0) {
        // Group calculation
        let masterCalculation = '';
        let formulaHasEmptyValue = false;
        let gridRecords = [];
        for (let j = 0; j < formula.Tags.length; j++) {
          const tag = formula.Tags[j];
          if (formulaHasEmptyValue)
            break;
          switch (tag.Type) {
            case FormulaTagType.Field:
              let field = numericFields.find(f => f.CollectionFieldsID == tag.CollectionFieldsID);
              if (field !== undefined && (field.Value == undefined || field.Value == null)) {
                formulaHasEmptyValue = true;
              } else if (field !== undefined) {
                masterCalculation += field.Value;
              }
              break;
            case FormulaTagType.GroupOperator:
              let group = formula.GroupFormulas.find(f => f.Name == tag.Tag);
              let grid;
              let outputs = [];
              let rowHasEmptyValue = false;

              if (group !== undefined) {
                grid = FormulaService.getGridFrom1xNField(group.Fields1xN[0], fields);
                if (grid !== undefined) {
                  gridRecords.push(grid.Records.length);
                  for (let r = 0; r < grid.Records.length; r++) {
                    const record = grid.Records[r];
                    if (record.State == UpdateDeleteState.Delete)
                      continue;
                    let recordAndFormFields = record.Fields.concat(numericFields);
                    let output = FormulaService.calculateTags(group.Tags, recordAndFormFields, formula.IgnoreEmptyValues);
                    if (output == 'empty') {
                      rowHasEmptyValue = true;
                      break;
                    }
                    outputs.push(math.bignumber(output));
                  }
                  if (rowHasEmptyValue) {
                    formulaHasEmptyValue = true;
                    break;
                  }
                  let output = undefined;
                  if (grid.Records.length == 0)
                    output = 0;
                  if (outputs.length > 0) {
                    switch (group.Operation) {
                      case 'SUM':
                        output = math.sum(outputs);
                        break;
                      case 'AVERAGE':
                        output = math.mean(outputs);
                        break;
                      case 'COUNT':
                        output = outputs.length;
                        break;
                      case 'MIN':
                        output = math.min(outputs);
                        break;
                      case 'MAX':
                        output = math.max(outputs);
                        break;
                    }
                  }
                  if (output == null) {
                    formulaHasEmptyValue = true;
                  } else {
                    masterCalculation += output;
                  }
                  break;
                }
              }

              break;
            case FormulaTagType.Operator:
            case FormulaTagType.Custom:
              masterCalculation += tag.Tag;
              break;
            default:
              break;
          }
        }
        if (formulaHasEmptyValue)
          continue;

        // const uniqueRecords = new Set(gridRecords);
        // if (uniqueRecords.size == 1 || [...uniqueRecords][0] == 0)
        //   continue;

        let output = math.evaluate(masterCalculation);
        let outputField = fields.find(f => f.CollectionFieldsID == formula.OutputField.CollectionFieldsID);
        if (outputField !== undefined)
          this.store$.dispatch(formsActions.updateFormulaOutputField({
            formId: formId,
            collectionFieldId: outputField.CollectionFieldsID,
            value: output
          }));
      } else {
        let output = FormulaService.calculateTags(formula.Tags, fields, formula.IgnoreEmptyValues);
        if (output == 'empty')
          continue;
        let outputField = fields.find(f => f.CollectionFieldsID == formula.OutputField.CollectionFieldsID);
        if (outputField !== undefined)
          this.store$.dispatch(formsActions.updateFormulaOutputField({
            formId: formId,
            collectionFieldId: outputField.CollectionFieldsID,
            value: output
          }));
      }
    }
  }
}
