import { environment } from '../../../environments/environment';
import * as Sentry from '@sentry/angular';

export function configureSentry(): void {
  let sentryEnabled: boolean = false;
// Check Sentry Environment Variable Strings
  if (environment.sentryEnabled == 'true') {
    if (!environment.sentryDsn) {
      console.error('NG_APP_SENTRY_DSN is not set, Sentry will not be enabled');
    } else if (!Number.parseFloat(environment.sentryTraceSampleRate)) {
      console.error('NG_APP_SENTRY_TRACE_SAMPLE_RATE is not a valid number, Sentry will not be enabled');
    } else if (!Number.parseFloat(environment.sentryReplaySampleRate)) {
      console.error('NG_APP_SENTRY_REPLAY_SAMPLE_RATE is not a valid number, Sentry will not be enabled');
    } else if (!Number.parseFloat(environment.sentryReplayErrorsSampleRate)) {
      console.error('NG_APP_SENTRY_REPLAY_ERRORS_SAMPLE_RATE is not a valid number, Sentry will not be enabled');
    } else if (environment.production && environment.sentryTracePropagationTargets) {
      throw new Error('NG_APP_SENTRY_TRACE_PROPAGATION_TARGETS is not allowed in production mode');
    } else {
      sentryEnabled = true;
    }
  }

  if (sentryEnabled) {
    const tracePropagationTargets = environment.sentryTracePropagationTargets ? environment.sentryTracePropagationTargets.split(',') : [];

    const SENTRY_OPTIONS = {
      dsn: environment.sentryDsn,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // The amount of tracing from 0-1 (where 1 = 100%) that should be captured.
      tracesSampleRate: Number.parseFloat(environment.sentryTraceSampleRate),

      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled,
      // For performance monitoring. Should be for local development urls only.
      tracePropagationTargets: [...tracePropagationTargets, environment.apiUri, environment.idpUri],

      // Session Replay sample rate from 0-1 (where 1 = 100%) that should be captured.
      replaysSessionSampleRate: Number.parseFloat(environment.sentryReplaySampleRate),

      // Session Error Replay sample rate from 0-1 (where 1 = 100%) that should be captured.
      // Can only be higher than replaysSessionSampleRate.
      replaysOnErrorSampleRate: Number.parseFloat(environment.sentryReplayErrorsSampleRate),
      attachStacktrace: true,
      environment: environment.production ? 'production' : 'development',

      //TODO RV: add release version, maybe set SENTRY_RELEASE in pipeline.
      //release:
      release: environment.appVersion,
      debug: !environment.production
    };
    Sentry.init(SENTRY_OPTIONS);
  }
}