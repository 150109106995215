import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { WorkspaceSidebarDto } from '../../../../models/ts/workspace-sidebar-dto.model';
import { BizzMineLocalStorageService } from '../../../shared/services/localStorage/bizzmine-local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarApiService {

  public constructor(private http: HttpClient,
              private bizzMineLocalStorage: BizzMineLocalStorageService) { }

  public getWorkspaceSidebar(workspaceID: number): Observable<WorkspaceSidebarDto> {
    return this.http.get<WorkspaceSidebarDto>(`api/sidebar/${workspaceID}`)
      .pipe(tap(data => {
        const current = this.bizzMineLocalStorage.getItem('workspaceID');
        if(current == null || (data && +current != data.ID)) {
          this.bizzMineLocalStorage.setItem('workspaceID',data.ID.toString());
        }
      }));
  }

  public getSettingsSidebar(): Observable<WorkspaceSidebarDto> {
    return this.http.get<WorkspaceSidebarDto>('api/sidebar/settings');
  }
}
