import { Injectable } from '@angular/core';
import { headerTasksActions } from '../../../../store/features/header-tasks/header-tasks-actions';
import { HeaderPopupBaseService } from '../header-popup-base-service';

/**
 * Header Notification Service is used for handling the opening of notification detail component.
 */

@Injectable({
  providedIn: 'root',
})
export class HeaderTaskService extends HeaderPopupBaseService {
  protected fetchAction = headerTasksActions.fetchTasks();
}
