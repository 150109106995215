import { computed, DestroyRef, inject, Injectable, Input, OnInit, Signal, signal } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, Observable, tap } from 'rxjs';
import { DynamicFormControlComponent } from '../../../../shared/interfaces/dynamic-form-control-component';
import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TableFieldDataType } from '../../../../../models/ts/table-field-data-type.model';
import { Store } from '@ngrx/store';
import { formsActions } from '../../../../store/features/forms/forms-actions';
import { selectFormFieldValidationState, selectFormLockState } from '../../../../store/features/forms/forms-selectors';
import { CollectionFormValidatorError } from './collection-form-validator-error';
import { ExternalUserService } from '../../../../core/services/external-user/external-user-service';
import { userSettingsFeature } from '../../../../store/features/user-settings/user-settings-feature';
import { UserType } from '../../../../../models/ts/user-type.model';
import { AutofocusService } from '../services/autofocus.service';

/**
 * Base class for dynamically loaded form controls.
 * Houses shared logic between all form controls.
 */
@Injectable()
export abstract class BaseControlComponent<T extends AbstractControl> implements DynamicFormControlComponent, OnInit {

  // Data Binding
  @Input() public formId: string;
  @Input() public formField$: Observable<CollectionFormField | undefined>;
  @Input({ required: true }) public formControl: T;
  @Input() public readOnlyOverride = false;
  @Input() public isInGrid = false;
  @Input() public recordId: number;
  @Input() public gridFieldId: number;

  // Signals
  @Input() public formFieldSignal: Signal<CollectionFormField | undefined> = signal<CollectionFormField | undefined>(undefined);
  public formFieldId = computed(() => {
    return this.formFieldSignal()?.Id;
  });
  // Inheritance Fields
  /**
   * Key to use in valueSetter
   * @type {keyof CollectionFormField}
   * @protected
   */
  protected fieldValueProperty: keyof CollectionFormField = 'Value';
  protected destroyRef: DestroyRef = inject(DestroyRef);
  protected store$: Store = inject(Store);
  protected errorState: CollectionFormValidatorError | undefined;
  protected externalUserService = inject(ExternalUserService);
  protected userType: UserType = this.store$.selectSignal(userSettingsFeature.selectUserType)();

  protected autoFocusService: AutofocusService = inject(AutofocusService);
  protected abstract focus(): void;

  public ngOnInit(): void {
    const fieldId = this.formFieldSignal()?.Id;
    if (fieldId){
      this.autoFocusService.autoFocusedElements$.pipe()
      .subscribe({
        next: (value) => {
        if (value?.formId === this.formId && value?.formFieldId === fieldId!) {
          setTimeout(() => {
            this.focus();
          }, 400);
        }
      }
    });
  }

    this.store$.select(selectFormLockState(this.formId)).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: locked => {
        this.readOnlyOverride = locked;
        const field = this.formFieldSignal()
        if(field?.ComponentType == TableFieldDataType.Checkbox || 
          field?.ComponentType == TableFieldDataType.RadioGroup)
          this.setFieldReadOnly(this.formFieldSignal());
      }
    });
    this.store$.select(selectFormFieldValidationState(this.formId, this.formFieldSignal()!.Id))
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe({
      next: (error: CollectionFormValidatorError | undefined) => {
        this.errorState = error;
      }
    });

    this.formField$.pipe(
      takeUntilDestroyed(this.destroyRef),
      distinctUntilChanged(this.fieldValueChangeComparator),
      tap((field) => {
        if (field) {
          this.valueSetter(field);
        }
      })
    ).subscribe();

    this.formControl.valueChanges.pipe(
      debounceTime(300),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe({
      next: value => this.isInGrid ? this.gridValueChangeDispatcher(value) : this.valueChangeDispatcher(value)
    });

    this.formField$.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter((field) =>
        (field?.ComponentType == TableFieldDataType.Checkbox || 
          field?.ComponentType == TableFieldDataType.RadioGroup)),
      distinctUntilChanged((previous, current) =>
        previous?.IsReadOnly === current?.IsReadOnly),
      tap((field) => this.setFieldReadOnly(field))
    ).subscribe();
  }

  protected setFieldReadOnly(field: CollectionFormField | undefined): void {
    if (field) {
      if (this.readOnlyOverride || field.IsReadOnly) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
      }
    }
  }

  /**
   * Function that dispatches the action when value changes.
   * Automatically called when formControl.valueChanges emits. (subscribed in ngOnInit).
   * @param value
   * @protected
   */
  protected valueChangeDispatcher(value: any): void {
    const fieldId = this.formFieldSignal()?.Id;
    if (fieldId)
      this.store$.dispatch(formsActions.updateFormFieldValue({
        formId: this.formId,
        fieldId: fieldId,
        value
      }));
  }

  protected gridValueChangeDispatcher(value: any): void {
    const field = this.formFieldSignal();
    if (field)
      this.store$.dispatch(formsActions.updateGridFormFieldValue({
        formId: this.formId,
        gridFieldId: this.gridFieldId,
        recordId: this.recordId,
        recordFieldId: field.CollectionFieldsID,
        value
      }));
  }

  /**
   * Function that sets the formControl value.
   * Automatically called when formField$ changes value (based on fieldValueChangeComparator).
   * Property used is fieldValueProperty, a key of CollectionFormField.
   * @param {CollectionFormField} field
   * @protected
   */
  protected valueSetter(field: CollectionFormField): void {
    this.formControl.setValue(field[this.fieldValueProperty], { emitEvent: false });
  }

  /**
   * Compares the previous and current field values to determine if they are the same.
   * @param {CollectionFormField | undefined} previous
   * @param {CollectionFormField | undefined} current
   * @return {boolean}
   */
  protected fieldValueChangeComparator(previous: CollectionFormField | undefined, current: CollectionFormField | undefined): boolean {
    return previous?.Value === current?.Value;
  }
}

export abstract class BaseFormControlComponent extends BaseControlComponent<FormControl> {
}