import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { headerTasksActions } from './header-tasks-actions';
import { TaskApiService } from '../../../api/bizzmine/task/task-api.service';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { headerTasksFeature } from './header-tasks-feature';
import { exhaustMap, forkJoin, map, timer } from 'rxjs';

@Injectable()
export class HeaderTasksEffects {
  private actions$ = inject(Actions);
  public fetchAfterSearchUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerTasksActions.updateSearch),
      map(({ search }) => ({
          type: headerTasksActions.fetchTasks.type
        })
      )
    )
  );
  private taskApiService = inject(TaskApiService);
  private store$ = inject(Store);
  fetchTasks$ = createEffect(() => this.actions$.pipe(
    ofType(headerTasksActions.fetchTasks),
    concatLatestFrom(() => this.store$.select(headerTasksFeature.selectSearch)),
    exhaustMap(([action, search]) => forkJoin(
      { minimumTime: timer(300), request: this.taskApiService.getPopupTasks(search) }
    )),
    map(({ request }) => ({ type: headerTasksActions.tasksFetched.type, tasks: request }))
  ));

}
