import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { StoreCollectionForm } from './forms-state';
import { ViewStackLoaderType } from '../../../shared/enums/view-stack-event-type.enum';

export const viewStackActions = createActionGroup({
  source: 'ViewStack',
  events: {
    showLoadingState: props<{ loaderType?: ViewStackLoaderType }>(),
    removeLoadingState: emptyProps(),
    removeLoadingStateWithLatestForm: emptyProps(),
    addFormToViewStack: props<{ data: StoreCollectionForm }>(),
    removeFormFromViewStack: props<{ formId: string }>(),
    loadingStateChanged: props<{ loading: boolean }>(),
  }
});