import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AssessmentDto } from '../../../../models/ts/assessment-dto.model';

@Injectable({
  providedIn: 'root'
})
export class AssessmentApiService {

  constructor(private http: HttpClient) { }

  public getAssessmentsByTaskId(taskId: number): Observable<Array<AssessmentDto>> {
    return this.http.get<Array<AssessmentDto>>(`api/assessment/task/${taskId}`);
  }
}
