import { Component, DestroyRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { BodyModule, GridModule, SelectableSettings, SharedModule } from '@progress/kendo-angular-grid';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrgChartItem } from '../interfaces/org-chart-item';
import { OrganizationChartItemType } from '../../../../../models/ts/organization-chart-item-type.model';
import { OrganizationChartIndexDto } from '../../../../../models/ts/organization-chart-index-dto.model';
import { TrainingApiService } from '../../../../api/bizzmine/training/training-api.service';
import { AsyncPipe } from '@angular/common';
import { IconComponent } from '../../../../shared/components/ui/icon/icon.component';
import { PagerModule } from '@progress/kendo-angular-pager';
import { TranslatePipe } from '../../../../shared/pipes/translate/translate.pipe';
import { ORG_CHART_ITEM_TYPE_ICONS } from '../constants/org-chart-item-type-icons';
import { RowArgs } from '@progress/kendo-angular-grid/rendering/common/row-args';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'bizz-org-chart-trainee-list',
  standalone: true,
  imports: [
    AsyncPipe,
    BodyModule,
    GridModule,
    IconComponent,
    PagerModule,
    SharedModule,
    TranslatePipe
  ],
  templateUrl: './org-chart-trainee-list.component.html',
  styleUrl: './org-chart-trainee-list.component.scss'
})
export class OrgChartTraineeListComponent implements OnInit {
  @Input() public selectableSettings: SelectableSettings = {
    mode: 'multiple',
    cell: false,
    enabled: true
  };

  @Input() public selectedItems$ = new BehaviorSubject<OrgChartItem[]>([]);
  @Input() public selectedKeys: string[] = [];
  @Output() public selectedKeysChange = new EventEmitter<OrgChartItem[]>();
  @Input() public list: OrganizationChartIndexDto | null;
  @Input() public maxSelectedItems = 0;
  @Input() public minSelectedItems = 0;
  @Input() public allowedItemTypes: Array<OrganizationChartItemType> = [OrganizationChartItemType.User, OrganizationChartItemType.Department, OrganizationChartItemType.Function];
  public items$: Observable<Array<OrgChartItem>>;
  @Input() public set requestData(data: {trainingId: number, trainingAppId: number}) {
    this.items$ = this.trainingApiService.getTrainees({trainingId: data.trainingId, trainingAppId: data.trainingAppId});
  }

  public constructor(private trainingApiService :TrainingApiService, private destroyRef: DestroyRef) {
  }
  public isSelected(data: OrgChartItem): boolean {
    if(data == null) return false;
    return this.selectedKeys?.includes(data.UniqueID) ?? false;
  }
  public buildUniqueId(context: RowArgs): string {
    return context.dataItem['UniqueID'];
  }

  public onSelectedKeysChange(data: Array<string>, kendoItems: Array<OrgChartItem>): void {
    const items = structuredClone(kendoItems)
    if(this.selectedItems$.value != null && this.selectedItems$.value.length > 0 && items != null && items.length > 0){
      this.selectedItems$.value.forEach(orgChartItem => {
        if(items.find(innerOrgChartItem => orgChartItem.UniqueID === innerOrgChartItem.UniqueID) == null){
          items.push(orgChartItem);
        }
      })
    }
    // sadly this doesnt work alike tree -> this only passes new selection as data, not the full selection - thus more logic :(
    if(this.maxSelectedItems > 0 && this.selectedKeys.length >= this.maxSelectedItems) {
      return;
    }
    this.selectedKeys.forEach(key => {
      if(data.find(d => d === key) != null) {
        data = data.filter(k => k !== key);
      }
    });
    // parsing -> getting ItemType info etc
    let selectedItems = <Array<OrgChartItem>>data.map(uniqueId => items.find(node => node.UniqueID === uniqueId)).filter(item => item != null);
    if(this.allowedItemTypes != null && this.allowedItemTypes.length > 0) {
      selectedItems = selectedItems.filter(item => this.allowedItemTypes?.includes(item.ItemType));
    }
    this.selectedKeys = this.selectedKeys.concat(selectedItems.map(item => item.UniqueID));
    const fullMappedList = <Array<OrgChartItem>>this.selectedKeys.map(uniqueId => items.find(node => node.UniqueID === uniqueId)).filter(item => item != null);
    this.selectedKeysChange.emit(fullMappedList);
    this.selectedItems$.next(fullMappedList);
  }

  public ngOnInit(): void {
    if (this.selectedItems$.value && this.selectedItems$.value.length > 0) {
      this.selectedKeys = this.selectedItems$.value.map(item => item.UniqueID);
    }
    this.selectedItems$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((items) => {
      this.selectedKeys = items?.map(item => item.UniqueID) ?? [];
    });
  }

  protected readonly ORG_CHART_ITEM_TYPE_ICONS = ORG_CHART_ITEM_TYPE_ICONS;
}
