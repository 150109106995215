import { Component } from '@angular/core';
import { FormLandingPageComponent } from '../form-landing-page/form-landing-page.component';
import { formsActions } from '../../../../store/features/forms/forms-actions';

@Component({
  selector: 'bizz-form-workspace-page',
  standalone: true,
  imports: [],
  templateUrl: './form-workspace-page.component.html',
  styleUrl: './form-workspace-page.component.scss'
})
export class FormWorkspacePageComponent extends FormLandingPageComponent {
  public override routeApplied(): void {
    this.store$.dispatch(formsActions.getFormWorkspace({
      collectionId: this.collectionId!,
      workspaceItemId: this.workspaceItemsId!,
    }));
  }

}
