import { Component } from '@angular/core';
import { FormLandingPageComponent } from '../form-landing-page/form-landing-page.component';
import { formsActions } from '../../../../store/features/forms/forms-actions';

@Component({
  selector: 'bizz-form-create-page',
  standalone: true,
  imports: [],
  templateUrl: './form-create-page.component.html',
  styleUrl: './form-create-page.component.scss'
})
export class FormCreatePageComponent extends FormLandingPageComponent{
  public override routeApplied() : void {
    this.store$.dispatch(formsActions.getFormCreate({
      collectionId: this.collectionId!,
      childInstanceId: this.childInstanceId,
      childVersionId: this.childVersionId,
      widgetId: this.widgetId,
      crossLinkCollectionId: this.crossLinkCollectionId,
      originalChildInstanceId: this.originalChildInstanceId
    }));
  }
}
