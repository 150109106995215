import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../icon/icon.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { WorkspaceItemType } from '../../../../../../models/ts/workspace-item-type.model';
import { SkeletonModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'bizz-sidebar-block-skeleton',
  standalone: true,
  imports: [CommonModule, IconComponent, RouterLink, RouterLinkActive, SkeletonModule],
  templateUrl: './sidebar-block-skeleton.component.html',
  styleUrls: ['./sidebar-block-skeleton.component.scss'],
})
export class SidebarBlockSkeletonComponent {

  public skeletonBlocks:{ id: number, items: { id: number }[] }[] = [];

  //Init dummy list of blocks with id for html
  public constructor() {
    const skeletonBlockCount = this.getRndInteger(4,8);
    for(let i = 0; i < skeletonBlockCount; i++) {
      this.skeletonBlocks.push({ id: i, items: [] });
      let linkBlockCount = this.getRndInteger(0,3); 
      for(let j = 0; j < linkBlockCount; j++)  {
        this.skeletonBlocks[i].items.push({ id: j  });
      }
    }
  }

  private getRndInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) ) + min;
  }

  protected readonly WorkspaceItemType = WorkspaceItemType;
}
