import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { CronGenTimeSelectComponent, CronGenTimeType } from '../cron-gen-time-select/cron-gen-time-select.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { CronGenService } from 'src/app/features/bizzmine/form/services/cron-gen.service';
import { CronTimeHelpers, timeHelpers } from 'src/app/shared/constants/time-helpers';
import { CronGenYearly } from 'src/app/features/bizzmine/form/interfaces/crongen/cron-gen-yearly';

@Component({
  selector: 'bizz-cron-yearly',
  standalone: true,
  imports: [
    TranslatePipe,
    DropDownsModule,
    CronGenTimeSelectComponent
  ],
  templateUrl: './cron-yearly.component.html',
  styleUrl: './cron-yearly.component.scss',
})
export class CronYearlyComponent {
  public helpers: CronTimeHelpers = timeHelpers;

  @Input() public state: CronGenYearly;
  @Input() public readOnly = false;

  @Output() public stateChange: EventEmitter<CronGenYearly> = new EventEmitter<CronGenYearly>();

  public constructor(public cronGenService: CronGenService) { 

  }

  public daysChange(state: string, subTab: 'specificMonthDay'|'specificMonthWeek'):void  {
    if(subTab === 'specificMonthDay'){ 
      this.state[subTab].day =  this.cronGenService.monthDayReverseDisplay(state);
    }else{
      this.state[subTab].day =  this.cronGenService.getKeyByValue(this.cronGenService.dayLookups, state) as string;
    }
    this.stateChange.emit(this.state);
  }
  public monthsChange(state: string, subTab: 'specificMonthDay'|'specificMonthWeek'):void  {
    this.state[subTab].month = this.helpers.monthsArray.indexOf(state)+1;
    this.stateChange.emit(this.state);
  }
  
  public monthWeekChange(state: string):void {
    this.state.specificMonthWeek.monthWeek = this.cronGenService.getKeyByValue(this.cronGenService.monthWeekLookups, state) as string;
    this.stateChange.emit(this.state);
  }
  public cronGenTimeChange(state: CronGenTimeType, subTab: 'specificMonthDay'|'specificMonthWeek'):void  {
    Object.assign(this.state[subTab], state);
    this.stateChange.emit(this.state);
  }
}
