<div class="h-full w-full">


    @if (items$ | async; as items) {
        <div class="h-[calc(100%_-_3.5rem)] w-full overflow-auto">
            <kendo-grid
                    [data]="items"
                    [selectable]="selectableSettings"
                    [kendoGridSelectBy]="buildUniqueId"
                    [selectedKeys]="selectedKeys"
                    (selectedKeysChange)="onSelectedKeysChange($event,items)"
            >
                <kendo-grid-column title="" [width]="50">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="mx-4">
                                @if (isSelected(dataItem)) {
                                    <bizz-icon iconName="circle-check"
                                               class="size-4 text-green-500 cursor-pointer"></bizz-icon>
                                } @else {
                                    <div class="rounded-full bg-form-control size-4 flex justify-center items-center cursor-pointer">
                                        <bizz-icon iconName="plus" class="size-4 text-blue-500"></bizz-icon>
                                    </div>
                                }
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Name' | translate}}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <p [class.text-blue-500]="isSelected(dataItem)">
                            <bizz-icon class="h-4 w-4 text-blue-500"
                                       [iconName]="ORG_CHART_ITEM_TYPE_ICONS[dataItem.ItemType]"
                            ></bizz-icon>
                            {{ dataItem.Name }}
                        </p>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Type' | translate}}" [media]="'(min-width:1024px)'">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <p [class.text-blue-500]="isSelected(dataItem)">{{ dataItem.ItemTypeName }}</p>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'UserType' | translate}}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <p [class.text-blue-500]="isSelected(dataItem)">{{ dataItem.UserTypeName }}</p>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>
    }
</div>
