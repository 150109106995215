@if (readyToRender | async) {
    <div class="fixed left-0 top-0 z-[1001] flex w-screen flex-col gap-4 sm:left-1/2 sm:top-4 sm:w-auto sm:-translate-x-1/2 sm:transform">
      <bizz-stacked-alerts></bizz-stacked-alerts>
    </div>
    <div class="root-container max-w-svw relative grid h-svh max-h-svh w-svw bg-body"
         [class.mobile-sidebar-expanded]="(showSidebar$ | async)">
        <bizz-header
                class="header h-full">
        </bizz-header>
        <bizz-sidebar
                class="sidebar absolute z-[100] ml-0 h-full max-h-full w-full max-w-full sm:static sm:block"
                [workspaceSidebar]="sidebar"
                [sidebarHoverState]="sidebarEvent"
                [ngClass]="{'hidden': !(showSidebar$ | async)}"
                (workspaceChanged)="updateWorkspaceStore($event)">
        </bizz-sidebar>

        <div class="main relative h-full w-full sm:block"
             [class.hidden]="(showSidebar$ | async)">
            <bizz-view-stack
                    [class.hidden]="!showViewStack && !loadingViewStack"
                    class="h-full w-full max-w-full"
                    (viewStackChange)="viewStackChanged($event)"
                    (loadingChange)="loadingChanged($event)">
            </bizz-view-stack>
            <div [class.hidden]="showViewStack || loadingViewStack" class="h-full">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <bizz-context-menu>
    </bizz-context-menu>
} @else {
    <bizz-loader [fullscreen]="true" [showLoader]="true">
    </bizz-loader>
}

