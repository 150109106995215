export enum KeyCode {
  ESCAPE = 'Escape',
  TAB = 'Tab',
  ENTER = 'Enter',
  SHIFT = 'Shift',
  CONTROL = 'Control',
  ALT = 'Alt',
  CAPS_LOCK = 'CapsLock',
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight'
}