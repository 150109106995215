<span class="flex w-fit justify-center">
  <div class="text-list-action flex w-auto items-center gap-1">

    <!--  actions -->
      @if (auditLogItem.HasInfo) {
          <span class="actions-cell-button mx-1" kendoTooltip [title]="'MoreInfo' | translate">
      <bizz-icon class="actions-cell-icon h-4" (click)="showInfo()" iconName="circle-info"></bizz-icon>
    </span>
      }
      @if (auditLogItem.HasTrackChanges && auditLogItem.CanAccessTrackChanges) {
          <span class="actions-cell-button mx-1" kendoTooltip [title]="'TrackChanges' | translate">
      <bizz-icon class="actions-cell-icon h-4" (click)="showTrackChanges()" iconName="clock-rotate-left"></bizz-icon>
    </span>
      }
      @if (auditLogItem.ItemType == AuditLogItemType.TrainingQueue && auditLogItem.CanAccessTrackChanges) {
          <span class="actions-cell-button mx-1" kendoTooltip
                [title]="('TrainingQueue' | translate) + ' ' + ('TrackChanges' | translate)">
      <bizz-icon class="actions-cell-icon h-4" (click)="showQueueEvents()" iconName="clock-rotate-left"></bizz-icon>
    </span>
      }
      @if (auditLogItem.HasPermissionHistory) {
          <span class="actions-cell-button mx-1" kendoTooltip [title]="'PermissionHistory' | translate">
      <bizz-icon class="actions-cell-icon h-4" (click)="showPermissionHistory(auditLogItem)"
                 iconName="clock-rotate-left"></bizz-icon>
    </span>
      }
      @if (auditLogItem.HasPropertyHistory) {
          <span class="actions-cell-button mx-1" kendoTooltip [title]="'PropertyChanged' | translate">
      <bizz-icon class="actions-cell-icon h-4" (click)="showPropertyHistory(auditLogItem)"
                 iconName="clock-rotate-left"></bizz-icon>
    </span>
      }
      @if (auditLogItem.ForwardedReason) {
          <span class="actions-cell-button mx-1" kendoTooltip [title]="'ForwardReason' | translate">
      <bizz-icon class="actions-cell-icon h-4" (click)="showForwardedReason(auditLogItem)"
                 iconName="circle-info"></bizz-icon>
    </span>
      }
      @if (auditLogItem.MailID) {
          <span class="actions-cell-button mx-1" kendoTooltip [title]="'MoreInfo' | translate">
      <bizz-icon class="actions-cell-icon h-4" (click)="showSendMailModal(auditLogItem.MailID)"
                 iconName="circle-info"></bizz-icon>
    </span>
      }
  </div>
</span>