import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NotificationPopUpListDto } from '../../../../models/ts/notification-pop-up-list-dto.model';

export const headerNotificationsActions = createActionGroup({
  source: 'Header Notifications',
  events: {
    readNotification: props<{ id: number }>(),
    unreadNotification: props<{ id: number }>(),
    readNotifications: props<{ ids: number[] }>(),
    fetchNotifications: emptyProps(),
    notificationsFetched: props<{ notifications: NotificationPopUpListDto }>(),
    updateSearch: props<{ search: string }>(),
  }
});
