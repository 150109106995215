import {
  AfterViewInit,
  Component,
  DestroyRef,
  EventEmitter,
  HostListener,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ViewContainerRefDirective } from '../../../directives/view-container-ref.directive';
import { asyncScheduler } from 'rxjs';
import { ViewStackService } from '../../../services/view-stack/view-stack.service';
import { ViewStackEventType, ViewStackLoaderType } from '../../../enums/view-stack-event-type.enum';
import { ViewStackEvent } from '../../../classes/view-stack-event';
import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { LoaderComponent } from '../loader/loader.component';
import { ViewStackItem } from '../../../classes/view-stack-item';
import { ViewStackLoadedComponent } from '../../../interfaces/view-stack-loaded-component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import * as Sentry from '@sentry/angular';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ViewStackLoaderComponent } from '../../../../features/bizzmine/form/components/view-stack-loader/view-stack-loader.component';
import { ViewStackSkeletonLoaderComponent } from '../../../../features/bizzmine/form/components/view-stack-skeleton-loader/view-stack-skeleton-loader.component';

export type ScrollbarVisibility = 'hover' | 'always' | 'native';

/**
 * Component showing dynamically loaded components in a stack like manner.
 * The components are loaded ontop of eachother.
 */
@Component({
  selector: 'bizz-view-stack',
  templateUrl: './view-stack.component.html',
  styleUrls: ['./view-stack.component.scss'],
  imports: [
    NgIf,
    ViewContainerRefDirective,
    NgFor,
    LoaderComponent,
    NgScrollbarModule,
    NgStyle,
    NgClass,
    ViewStackLoaderComponent,
    ViewStackSkeletonLoaderComponent
  ],
  standalone: true
})
@Sentry.TraceClass({ name: 'ViewStackComponent' })
export class ViewStackComponent implements AfterViewInit {

  public showLoader = false;
  public loaderType: ViewStackLoaderType = ViewStackLoaderType.DEFAULT;
  public scrollbarVisibility: ScrollbarVisibility;
  @ViewChildren(ViewContainerRefDirective) public viewStackHosts: QueryList<ViewContainerRefDirective>;
  @Output() public viewStackChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() public loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected viewStack: ViewStackItem[] = [];
  protected readonly ViewStackLoaderType = ViewStackLoaderType;
  private componentWaitingToLoad = false;

  public constructor(private viewStackService: ViewStackService, private destroyRef: DestroyRef) {
    this.viewStackService.getViewStackEventsObservable().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: event => this.handleViewStackEvent(event)
    });
  }

  @Sentry.TraceMethod({ name: 'ngOnInit' })
  public ngOnInit(): void {
    this.scrollbarVisibility = 'hover';
  }

  public ngAfterViewInit(): void {
    this.viewStackService.viewStackReference = this;
    this.viewStackHosts.changes.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => this.handleViewStackHostsChange()
    });
  }

  /**
   * Clears the viewstack when browser history navigation is used.
   * @param {PopStateEvent} event
   */
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    if (this.viewStack.length > 0)
      this.clearViewStack();
  }

  public checkViewStackForUnsavedChanges(): boolean {
    let unsavedChanges = false;
    for (let i = 0; i < this.viewStack.length; i++) {
      if (this.viewStack[i].componentRef.instance.hasUnsavedChanges()) {
        unsavedChanges = true;
        break;
      }
    }
    return unsavedChanges;
  }

  private changeLoader(loader: boolean, type?: ViewStackLoaderType): void {
    this.showLoader = loader;
    this.loaderType = type ?? ViewStackLoaderType.DEFAULT;
    this.loadingChange.emit(this.showLoader);
  }

  /**
   * Handles events fired from the ViewStackService.
   * Supported events:
   * - ViewStackEventType.ADDED => adds the component to the ViewStack
   * - ViewStackEventType.REMOVED => removes the last component from the ViewStack
   * - ViewStackEventType.CLEARED => clears the ViewStack by calling component defined removeFromViewStack method on each component in the stack
   * @param event
   * @private
   */
  private handleViewStackEvent(event: ViewStackEvent): void {
    switch (event.type) {
      case ViewStackEventType.ADD_LOADER_SKELETON: {
        this.changeLoader(true,ViewStackLoaderType.SKELETON);
      }
        break;
      case ViewStackEventType.ADD_LOADER: {
        this.changeLoader(true);
      }
        break;
      case ViewStackEventType.REMOVE_LOADER: {
        this.changeLoader(false);
      }
        break;
      case ViewStackEventType.ADDED:
        if (event.component) {
          this.viewStack.push(event.component);
          this.viewStackChange.emit(this.viewStack.length);
          this.componentWaitingToLoad = true;
        }
        break;
      case ViewStackEventType.REMOVED:
        this.viewStack.pop();
        this.viewStackChange.emit(this.viewStack.length);
        if(this.viewStack.length == 0){
          this.changeLoader(false);
        }
        break;
      case ViewStackEventType.CLEAR:
        // Clearing will(should) fire the REMOVED event for each component in the stack based on logic implemented in the
        // removeFromViewStack method of each component. This is done to ensure that the components are properly removed.
        this.clearViewStack();
        this.changeLoader(false);
        break;
    }
  }

  /**
   * iterates over the ViewStack and calls the removeFromViewStack method on each component which properly handles the
   * removal of the component from the ViewStack.
   * @private
   */
  private clearViewStack(): void {
    this.viewStack.reverse().forEach((item) => {
      item.componentRef.instance.removeFromViewStack();
    });
  }

  /**
   * Handles changes to the view stack hosts.
   * If a component is waiting to be loaded, the last view stack item is loaded into the last view stack host.
   * @private
   */
  private handleViewStackHostsChange(): void {
    asyncScheduler.schedule(() => {
      if (this.componentWaitingToLoad) {
        const lastIndex = this.viewStack.length - 1;
        this.loadComponent(this.viewStack[lastIndex], lastIndex);
        this.componentWaitingToLoad = false;
      }
    });
  }

  /**
   * Returns the view stack host at the given index.
   * @param index
   * @private
   */
  private getViewStackHost(index: number): ViewContainerRefDirective {
    return this.viewStackHosts.get(index) as ViewContainerRefDirective;
  }

  /**
   * Loads the component into the view stack.
   * Clears the view stack host at the given index and loads the component into it.
   * Sets the id of the component to the id of the view stack item.
   * Saves a reference to the componentRef in the view stack item.
   * Returns the view stack item.
   * @param viewStackDynamicComponent
   * @param index
   * @private
   */
  private loadComponent(viewStackDynamicComponent: ViewStackItem, index: number): ViewStackItem {
    const viewContainerRef = this.getViewStackHost(index)?.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<ViewStackLoadedComponent>(viewStackDynamicComponent.componentType);
    componentRef.instance.id = viewStackDynamicComponent.id;
    componentRef.instance.data = viewStackDynamicComponent.componentData;
    viewStackDynamicComponent.componentRef = componentRef;
    return viewStackDynamicComponent;
  }
}