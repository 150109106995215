import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable({
  providedIn: 'root'
})
export class LinkingService {

  public constructor(private clipboard: Clipboard) { }

  public copyLinkToRecord(collectionId: number, instanceId: number, versionId: number, guidChecksum: string | undefined): void {
    this.copyToClipboard(`${window.location.origin}/collection/${collectionId}/instance/${instanceId}/version/${versionId}/readonly`.appendChecksum(guidChecksum));
  }

  public copyToClipboard(text: string): void {
    this.clipboard.copy(text);
  }

}
