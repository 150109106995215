import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeDto } from 'src/models/ts/theme-dto.model';
import { ThemePreviewIconSimpleComponent } from '../../../modals/user-settings-modal/theme-selector-modal/components/theme-preview-icon-simple/theme-preview-icon-simple.component';
import { IconComponent } from '../../../ui/icon/icon.component';

@Component({
  selector: 'bizz-theme-selector-control',
  standalone: true,
  imports: [ThemePreviewIconSimpleComponent, IconComponent,],
  templateUrl: './theme-selector-control.component.html',
  styleUrl: './theme-selector-control.component.scss'
})
export class ThemeSelectorControlComponent {
  @Input() public readonly: boolean;
  @Input() public theme: ThemeDto;
  @Output() public click: EventEmitter<MouseEvent> = new EventEmitter();

  public onClick(event: MouseEvent): void {
    event.stopPropagation();
    this.click.emit(event);
  }
}
