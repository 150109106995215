import { Component, inject, Input, OnInit, Type } from '@angular/core';
import { NgComponentOutlet } from '@angular/common';
import { GridCellBaseComponent } from 'src/app/shared/components/grid/cells/grid-cell-base/grid-cell-base.component';
import { GridCellComponent } from 'src/app/shared/components/grid/cells/grid-cell/grid-cell.component';
import { GRID_CELL_COMPONENT_TYPES } from 'src/app/shared/components/grid/constants/grid-cell-component-types';
import { GridColumnType } from 'src/models/ts/grid-column-type.model';
import { CollectionFormField, Record } from 'src/models/ts/collection-form-field.model';
import { GridColumnBase } from 'src/app/shared/classes/list/grid-column-base';
import { TableFieldDataType } from 'src/models/ts/table-field-data-type.model';
import { ViewContainerRefDirective } from 'src/app/shared/directives/view-container-ref.directive';
import { TranslationService } from '../../../../../../../../core/services/translation/translation.service';
import { CollectionFormService } from '../../../../../services/collection-form.service';

@Component({
  selector: 'bizz-form-field-grid-cell',
  standalone: true,
  imports: [NgComponentOutlet, ViewContainerRefDirective],
  templateUrl: '../../../../../../../../shared/components/grid/cells/grid-cell/grid-cell.component.html',
  styleUrl: '../../../../../../../../shared/components/grid/cells/grid-cell/grid-cell.component.scss'
})
export class FormFieldGridCellComponent extends GridCellComponent implements OnInit {

  @Input() public fieldId: number;

  public translationService = inject(TranslationService);

  public formField: CollectionFormField | undefined;

  public ngOnInit(): void {
    this.getRecordFieldData();
  }

  private getRecordFieldData (): void {
    const record = this.data as Record;
    this.formField = CollectionFormService.getRecordField(record, field => field.CollectionFieldsID == this.fieldId);
    if (this.formField == undefined) {
      throw new Error('DataItem is not a Record');
    }
  }
  public getCellValue(col: GridColumnBase): unknown {
    if (this.formField != undefined) {
      if (col.GridColumnType === GridColumnType.Checkbox) {
        return this.formField.Value == 1;
      }

      /*if(this.formField.IsLookUpField) {

      } else if (field.ParentType != undefined && field.ParentType == FormFieldParentType.MailingList) {
      else if {*/
      switch (this.formField.ComponentType) {
        case(TableFieldDataType.HyperLink):
        case(TableFieldDataType.Checkbox):
        case(TableFieldDataType.AlphaNumeric):
        case(TableFieldDataType.Email):
        case(TableFieldDataType.TextValue):
        case(TableFieldDataType.EnumList):
        case(TableFieldDataType.Canvas):
          return this.formField.Value;
        case(TableFieldDataType.Memo):
          return this.formField.Value;
          //TODO: RV handle kendo stuff here
          break;
        case(TableFieldDataType.RadioGroup):
        case(TableFieldDataType.Combobox):
        case(TableFieldDataType.SimpleList): {
          //not sure why reports are just showing the value here
          //See onlyValue in ngBizzFormGridReadOnlyField.js
          if (!this.formField.Value) {
            return '-- ' + this.translationService.translate('empty') + '--';
          }
          return this.formField.FieldValues
            .find((fv) => fv.CollectionFieldValuesID == this.formField?.Value)?.Caption;
        }
        case(TableFieldDataType.OrganizationChartUnitSelector):
        case(TableFieldDataType.OrganizationChartUnitSelectorForTrnApp): {
          let value = '';
          this.formField.OrgChartUnitSelector.forEach(orgItem => {
            value += ' ' + orgItem.Name;
          });
          return value.trimStart();
        }
        case(TableFieldDataType.MacroButton):
          return this.formField.Url;
        default:
          return this.formField.Value;
      }
      /*}*/
    } else return undefined;
  }

  protected override resolveGridCellComponent(columnType: GridColumnType): Type<GridCellBaseComponent<unknown>> {
    return GRID_CELL_COMPONENT_TYPES[columnType];
  }

  protected override setInstanceData(component: GridCellBaseComponent<unknown>): void {
    const record = this.data as Record;
    this.formField = CollectionFormService.getRecordField(record, field => field.CollectionFieldsID == this.fieldId);
    if (this.formField == undefined) {
      throw new Error('DataItem is not a Record');
    }
    component.value = this.getCellValue(this.gridColumn);
    component.column = this.gridColumn;
  
    this.setComponentData(component);
    component.metaData = this.metaData;
    component.onCellAction = this.onCellAction;
  }

  /**
   * Sets the value of the combobox or radio group field in an 
   * additional property of the data object. Fieldname + ID
   * This is necessary for the color styling of these fields.
   * @param component 
   */
  private setComponentData(component: GridCellBaseComponent<unknown>): void {
    if(this.formField && 
      (this.formField.ComponentType == TableFieldDataType.RadioGroup ||
      this.formField.ComponentType == TableFieldDataType.Combobox)) {
        let newData = structuredClone(this.data);
        newData[this.gridColumn.field + '_ID'] = this.formField.Value;
        component.data = newData;
    } else {
      component.data = this.data;
    }
  }

}
