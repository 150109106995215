import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../ui/icon/icon.component';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { TranslatePipe } from '../../../pipes/translate/translate.pipe';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DialogModalModel, DialogModalModelMode } from './dialog-modal-model';
import { SafePipe } from 'safe-pipe';
import { DialogModalButtons } from '../../../enums/dialog-modal-buttons';
import { AlertComponent } from '../../ui/alert/alert.component';

@Component({
  selector: 'bizz-dialog-modal',
  standalone: true,
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss'],
  imports: [CommonModule, IconComponent, TextAreaModule, TranslatePipe, SafePipe, AlertComponent]
})

/**
 * A Generic Dialog Modal which shows a title, description
 * and optionally a confirmation input.
 * @returns the button that was pressed.
 */
export class DialogModalComponent {
  protected model: DialogModalModel;
  protected enteredConfirmText: string;

  public constructor(public dialogRef: DialogRef<DialogModalButtons>,
                     @Inject(DIALOG_DATA) public dialogModel: DialogModalModel) {
    this.model = dialogModel;
    this.model.mode = dialogModel.mode ?? DialogModalModelMode.simple;
    this.dialogRef.disableClose = true;
  }

  protected readonly DialogModalButtons = DialogModalButtons;
  protected readonly DialogModalModelMode = DialogModalModelMode;
}
