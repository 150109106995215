<div class="modal-container justify-between md:justify-normal">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="dialogRef.close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1>
                {{ 'Delete' | translate }} {{ model.PublicVersion['Title']?.Value }} ?</h1>
            <p class="text-center text-sm font-normal">{{ 'Info_ThereAreMultipleVersionsOfThisDocument' | translate }}
                <br/>
                {{ 'WhichVersionWouldYouLikeToDelete' | translate }}
            </p>
        </div>
        <hr class="my-5"/>
        <div class="flex flex-col md:flex-row justify-between self-stretch gap-4 mb-4">
            <!-- Current version -->
            <button class="version-button flex items-center w-full"
                    (click)="setSelectedVersion(VersionType.Public)"
                    [ngClass]="selectedVersion == undefined ? '' :  selectedVersion === VersionType.Public ? 'selected' : 'not-selected'">
                <div class="accent absolute left-0 top-0 h-full w-[7px] rounded-bl rounded-tl bg-green-500"></div>
                <div class="flex items-center">
                    <bizz-icon class="ml-auto mr-4 block h-8 w-8 text-default md:ml-0"
                               iconName="file-circle-check">
                    </bizz-icon>
                    <div class="flex flex-col gap-1">
                        <h2 class="flex justify-start">
                            {{ 'PublicVersion' | translate }}
                            <span class="absolute right-3 top-3 items-center justify-center gap-2.5 rounded-md bg-button px-2 py-1 text-center text-[14px] font-bold leading-none text-default">
                                {{ model.PublicVersion['Version']?.Value }}
                            </span>
                        </h2>
                        <div class="flex flex-col flex-wrap gap-1.5 items-start">
                            <p class="text-xs">
                                <b>{{ 'VersionDate' | translate }}</b>
                                {{ model.PublicVersion['VersionDate']?.Value | datetime }}
                            </p>
                            @if (model.PublicVersion['ArchiveDate']) {
                                <p class="text-danger text-xs">
                                    <b>{{ 'WillBeArchivedOn' | translate }}</b>
                                    {{ model.PublicVersion['ArchiveDate'] | datetime }}
                                </p>
                            }
                        </div>
                    </div>
                </div>
            </button>
            <!-- Draft version -->
            <button class="version-button flex items-center w-full"
                    (click)="setSelectedVersion(VersionType.Draft)"
                    [ngClass]="selectedVersion == undefined ? '' : selectedVersion === VersionType.Draft ? 'selected' : 'not-selected'">
                <div class="accent absolute left-0 top-0 h-full w-[7px] rounded-bl rounded-tl bg-blue-500"></div>
                <div class="flex items-center">
                    <bizz-icon class="ml-auto mr-4 block h-8 w-8 text-default md:ml-0"
                               iconName="file-pen">
                    </bizz-icon>
                    <h2 class="flex flex-col gap-1">
                        {{ 'DraftVersion' | translate }}
                    </h2>
                    @if(model.DraftVersion['Version']?.Value) {
                        <span class="absolute right-3 top-3 items-center justify-center gap-2.5 rounded-md bg-button px-2 py-1 text-center text-[14px] font-bold leading-none text-default">
                            {{ model.DraftVersion['Version']?.Value }}
                        </span>
                    }
                </div>
            </button>
        </div>
        <div class="md:max-w-3xl">
            @if (selectedVersion === VersionType.Public) {
                <p class="text-sans w-full whitespace-normal text-xs font-normal">
                    {{ 'Info_DeletionOfThePublicVersion' | translate }}
                </p>
            }
            @if (selectedVersion === VersionType.Public && model.folders && model.folders.length > 0) {
                <div>
                    <br/>
                    <div class="mt-4">
                        @for (tree of model.folders; track $index) {
                            <div>
                                <h4><b>{{ tree.Tree }}</b></h4>
                                <ul class="ml-5 list-disc">
                                    @for (folder of tree.Folders; track $index) {
                                        <li>
                                            {{ folder.FolderCaption }}
                                            @if (folder.TargetType == FolderInstanceTargetType.Link) {
                                                <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                                                           iconName="rotate-right">
                                                </bizz-icon>
                                            }
                                        </li>
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
        <hr class="my-5"/>
        <div class="h-full">
            <label class="mb-2 inline-block text-sm text-default">{{ 'ReasonDelete' | translate }}
                <span class="ml-0.5 text-red-400">*</span>
            </label>
            <div class="max-h-80">
                <kendo-textarea
                        [rows]="5"
                        size="large"
                        [style.max-height.px]="320"
                        resizable="vertical"
                        [(value)]="deleteReason"
                        fillMode="solid"
                        rounded="large"
                ></kendo-textarea>
            </div>
        </div>
    </div>
    <div class="modal-buttons">
        <button class="default" (click)="dialogRef.close()"> <span>{{ 'Cancel' | translate }}</span></button>
        <button class="delete" (click)="delete()" [disabled]="selectedVersion == undefined || deleteReason == ''">
            {{
                selectedVersion === VersionType.Public ?
                    ('DeletePublicVersion' | translate) : ('DeleteDraftVersion' | translate)
            }}
        </button>
    </div>
</div>
