import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrnExamDto } from '../../../../../../../models/ts/trn-exam-dto.model';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { LoaderButtonComponent } from '../../../../../../shared/components/loader-button/loader-button/loader-button.component';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { AsyncPipe } from '@angular/common';
import { Router } from '@angular/router';
import { BizzMineLocalStorageService } from '../../../../../../shared/services/localStorage/bizzmine-local-storage.service';
import { DecimalPipe } from 'src/app/shared/pipes/decimal/decimal.pipe';

@Component({
  selector: 'bizz-training-exam-result-content',
  standalone: true,
  imports: [
    IconComponent,
    LoaderButtonComponent,
    TranslatePipe,
    ProgressBarModule,
    AsyncPipe,
    DecimalPipe
  ],
  templateUrl: './training-exam-result-content.component.html',
  styleUrl: './training-exam-result-content.component.scss'
})
export class TrainingExamResultContentComponent {
  @Input({required: true}) public exam: TrnExamDto;
  @Output() public viewReport = new EventEmitter<void>();

  public constructor(
    private router: Router,
    private localStorage: BizzMineLocalStorageService){

  }

  public close(): void {
    const route = this.localStorage.getItem('exam_redirect') ?? '';
    this.router.navigate([route]).then();
  }
}

