<ng-template #toolbarTemplate>

    <div class="border-0">
        <kendo-button #anchor (click)="onToggle()">
            <span class="flex flex-row gap-2 py-0.5">
                <bizz-icon class="size-4 align-middle" iconName="pencil"></bizz-icon>
                <bizz-icon class="size-4 align-middle transition-transform" iconName="caret-down" 
                [ngClass]="{'rotate-180' : show }"></bizz-icon>
            </span>
        </kendo-button
        >
    </div>

    @if (show) {
        <kendo-popup
                #popup
                [anchor]="anchor.element"
                (anchorViewportLeave)="show = false"
                [margin]="{ horizontal: 0, vertical: 10 }"
        >
            <div class="flex h-[66px] w-[263px] flex-col justify-center px-5 pb-6 pt-4 align-middle">
                <kendo-slider
                        class="h-full w-full"
                        [showButtons]="false"
                        [min]="0"
                        [max]="20"
                        [largeStep]="5"
                        [(ngModel)]="value"
                        (ngModelChange)="onValueChange()"
                >
                </kendo-slider>
            </div>
        </kendo-popup>
    }
</ng-template>
    <ng-template #popupTemplate>
        <div class="border-0">
            <kendo-button #anchor (click)="onToggle()">
                <span class="flex flex-row gap-2 py-0.5">
                    <bizz-icon class="size-4 align-middle" iconName="pencil"></bizz-icon>
                    <bizz-icon class="size-4 align-middle" iconName="caret-down" 
                    [ngClass]="{'rotate-180' : show }"></bizz-icon>
                </span>
            </kendo-button
            >
        </div>

        @if (show) {
            <kendo-popup
                    #popup
                    [anchor]="anchor.element"
                    (anchorViewportLeave)="show = false"
                    [margin]="{ horizontal: 0, vertical: 10 }"
            >
                <div class="flex h-[66px] w-[263px] flex-col justify-center px-5 pb-6 pt-4 align-middle">
                    <kendo-slider
                            class="h-full w-full"
                            [showButtons]="false"
                            [min]="0"
                            [max]="20"
                            [largeStep]="5"
                            [(ngModel)]="value"
                            (ngModelChange)="onValueChange()"
                    >
                    </kendo-slider>
                </div>
            </kendo-popup>
        }
    </ng-template>


