@if (exam$ | async; as exam) {
<bizz-modal modalSize="sm" [headerTemplate]="headerTemplateRef" [contentTemplate]="contentTemplateRef" [footerTemplate]="footerTemplateRef"></bizz-modal>

<ng-template #headerTemplateRef>
    <div class="modal-header">
        @if (exam.Name) {
        <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
            {{ "EnrollForTheExam" | translate }} {{ exam.Name }}</h1>
            <hr class="border-box m-auto my-5 h-0 w-full border-t-2 border-divider">
        }
    </div>
</ng-template>
<ng-template #contentTemplateRef>
    <div class="box-border flex w-full flex-col items-center gap-2 overflow-y-auto px-8" [class.justify-center]="!exam.Description" [class.grow]="!exam.Description">
        @if (exam.Description) {
            <div class="mb-3 rounded bg-button px-7 py-4 text-sm text-default"
            [innerHTML]="exam.Description | safe: 'html'"></div>                    
        }@else{
            <img class="min-w-80 max-w-80" src="/assets/image/exam-splash.svg" alt="exam-splash" />
        }
        @if (exam.IsUserSubscribed) {
            <div class="mb-3 flex w-full items-center gap-4 rounded bg-yellow-700/20 px-7 py-4 text-sm text-default">
                <bizz-icon class="size-6 fill-yellow-500"
                           iconName="circle-info">
                </bizz-icon>
                <div>
                    <p>{{ "Info_AlreadyEnrolled" | translate }}</p>
                    @if (exam.IsPublished) {
                        <p><a (click)="openExam()">{{ "ClickHereToOpenExam" | translate }}</a></p>
                    }
                </div>
            </div>
        }
    </div>
</ng-template>
<ng-template #footerTemplateRef>
    <div class="modal-buttons">
        <div class="flex flex-row justify-end gap-4 text-center">
            @if (!exam.IsUserSubscribed) {
                <bizz-loader-button buttonClass="save" [disabled]="isLoading$ | async" (click)="enrollForExam(false)" [isLoading]="((isLoading$ | async) && !(isPolling$ | async))">
                    {{'Enroll' | translate}}
                </bizz-loader-button>
                <bizz-loader-button buttonClass="save" [disabled]="isLoading$ | async"  (click)="enrollForExam(true)" [isLoading]="((isLoading$ | async) && (isPolling$ | async))">
                {{'EnrollAndStartExam' | translate}}
                </bizz-loader-button>
            } @else {
                <button class="save rounded-md"
                        (click)="closeDialog()">
                    {{ 'Close' | translate }}
                </button>
            }
        </div>

    </div>
</ng-template>
}
