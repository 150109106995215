import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { TrackChangesApiService } from '../../../../../../../api/bizzmine/track-changes/track-changes-api.service';
import { switchMap } from 'rxjs';
import { AlertComponent } from '../../../../../../../shared/components/ui/alert/alert.component';
import { IconComponent } from '../../../../../../../shared/components/ui/icon/icon.component';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { TranslatePipe } from '../../../../../../../shared/pipes/translate/translate.pipe';
import { TrackChangesGridComponent } from '../../../../../track-changes/components/track-changes-grid/track-changes-grid.component';
import { AsyncPipe } from '@angular/common';
import { GridComponent } from '../../../../../../../shared/components/grid/grid.component';
import { GridOptions } from '../../../../../../../shared/classes/list/grid-options';

@Component({
  selector: 'bizz-mailing-track-changes',
  standalone: true,
  imports: [
    AlertComponent,
    IconComponent,
    TextAreaModule,
    TranslatePipe,
    TrackChangesGridComponent,
    AsyncPipe,
    GridComponent
  ],
  templateUrl: './mailing-track-changes.component.html',
  styleUrl: './mailing-track-changes.component.scss'
})
export class MailingTrackChangesComponent {
  public gridOptions: GridOptions;
  public data$ = this.trackChangesApi.getGridOptions()
    .pipe(switchMap((gridOptions) => {
      this.gridOptions = new GridOptions(gridOptions)
      return this.trackChangesApi.getMailTrackChanges({
        mailId: this.data.mailId,
        versionId: this.data.versionId,
        instanceId: this.data.instanceId,
        collectionId: this.data.collectionId,
        gridOptions: gridOptions
      })
    }));
  public constructor(@Inject(DIALOG_DATA) public data: {
    collectionId: number,
    instanceId: number,
    versionId: number,
    mailId: number
  }, private trackChangesApi: TrackChangesApiService, public dialogRef: DialogRef) {
  }

}
