export enum MenuAction {
  DownloadFile = 0,
  ViewOnline = 1,
  EditOnline = 2,
  CopyLinkToRecord = 3,
  CheckInFile = 4,
  PutFileWithoutCheckIn = 5,
  UndoCheckoutFile = 6,
  DocumentSettings = 7,
  Permissions = 8,
  FlowStatus = 9,
  Monitor = 10,
  History = 11,
  TrackChanges = 12,
  Archive = 13,
  RestartWorkflow = 14,
  Delete = 15,
  DuplicateExam = 16,
  CopyDirectLink = 17,
  CreateMinorChanges = 18,
  CreateMajorChanges = 19,
  CheckoutFile = 20,
  Report = 21,
  UnlinkFromFolder = 22,
  FolderShortcut = 23,
  ForwardTask = 24,
  Read = 25,
  Edit = 26,
  Execute = 27,
  RecoverInstance = 28,
  SubscribeToTraining = 29,
  SubscribeToExam = 30,
  ConsultRecord = 31,
  FileHistory = 32,
  WordReport = 33,
}