import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ReminderPopupListDto } from '../../../../models/ts/reminder-popup-list-dto.model';
import { ReminderSearchDto } from '../../../../models/ts/reminder-search-dto.model';

export const headerRemindersActions = createActionGroup({
  source: 'Header Reminders',
  events: {
    readReminders: props<{ ids: number[] }>(),
    readReminder: props<{ id: number }>(),
    unreadReminder: props<{ id: number }>(),
    fetchReminders: emptyProps(),
    remindersFetched: props<{ reminders: ReminderPopupListDto }>(),
    updateSearch: props<{ search: string }>()
  }
});
