export enum Languages {
  English = 1,
  Dutch,
  French,
  Swedish,
  Lithuanian,
  German,
  Portuguese,
  Italian,
  Spanish,
  Polish,
  Russian,
  Turkish,
  Chinese,
  Czech,
  Slovak,
  Romanian,
  Hungarian,
  Bulgarian,
  Danish,
}