import { Component, Inject } from '@angular/core';
import { TrnFieldProperties } from '../../../../../../../../models/ts/trn-field-properties-dto.model';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DatePipe } from '@angular/common';
import { IconComponent } from '../../../../../../../shared/components/ui/icon/icon.component';
import { SafePipe } from 'safe-pipe';
import { TranslatePipe } from '../../../../../../../shared/pipes/translate/translate.pipe';

@Component({
  selector: 'bizz-training-subscribed-user-delete-dialog',
  standalone: true,
  imports: [
    DatePipe,
    IconComponent,
    SafePipe,
    TranslatePipe
  ],
  templateUrl: './training-subscribed-user-delete-dialog.component.html',
  styleUrl: './training-subscribed-user-delete-dialog.component.scss'
})
export class TrainingSubscribedUserDeleteDialogComponent {

  public data: TrnFieldProperties;

  public constructor(@Inject(DIALOG_DATA) public trainee: TrnFieldProperties,
                     private dialogRef: DialogRef<boolean | undefined, TrainingSubscribedUserDeleteDialogComponent>) {
    this.data = trainee;
  }

  public close(response: boolean | undefined) : void {
    this.dialogRef.close(response);
  }
}
