import { state } from '@angular/animations';
/* eslint-disable no-case-declarations */
import { AfterViewInit, Component, HostListener, Input, OnDestroy, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SafePipe } from "safe-pipe";
import { MenuAction } from "../collection-list-widget/classes/menu.action";
import { ContextMenuItem } from "../collection-list-widget/classes/context-menu.item";
import { ContextMenuItemService } from "../collection-list-widget/services/context-menu-item.service";
import { Store } from "@ngrx/store";
import { workspaceSidebarFeature } from "../../../../store/features/workspace-sidebar/workspace-sidebar-feature";
import {
  ContextMenuComponent as KendoContextMenuComponent,
  ContextMenuModule,
  ContextMenuSelectEvent,
} from "@progress/kendo-angular-menu";
import {
  ActionSheetComponent as KendoActionSheetComponent,
  ActionSheetItemClickEvent,
  ActionSheetModule,
} from "@progress/kendo-angular-navigation";
import { finalize, Subject, take, takeUntil, tap } from "rxjs";
import { IconComponent } from "../../../../shared/components/ui/icon/icon.component";
import { CollectionListDataInstance } from "../../../../shared/interfaces/collection-list-data-instance";
import { GridService } from "../collection-list-widget/services/grid/grid.service";
import { ContextMenuService } from "../../../../shared/services/context-menu/context-menu.service";
import { trainingActions } from "../../../../store/features/training/training-actions";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { LinkingService } from "src/app/core/services/linking/linking.service";
import { CollectionFormFileUploadApiService } from "../../../../api/bizzmine/collection-form-file-upload/collection-form-file-upload-api.service";
import { CollectionFormEditApiService } from "../../../../api/bizzmine/collection-form-edit/collection-form-edit-api.service";
import { UnlinkFromFolderParams } from "src/app/shared/components/modals/unlink-from-folder-modal/interfaces/unlink-from-folder-params";
import { Dialog } from "@angular/cdk/dialog";
import { EditOnlineApiServiceService } from "../../../../api/bizzmine/edit-online/edit-online-api-service.service";
import { FileUploadEditOnlineModalComponent } from "../../../../shared/components/file-upload/file-upload/file-upload-edit-online-modal/file-upload-edit-online-modal.component";
import {
  FileUploadEditOnlineModalComponentData,
  FileUploadEditOnlineModalComponentMode
} from '../../../../../models/ts/file-upload-edit-online-modal-component-mode';
import { FileUploadCheckinService } from '../../../../core/services/file-upload/file-upload-checkin.service';
import { refreshActions } from 'src/app/store/features/refresh/refresh-actions';
import { TrainingApiService } from 'src/app/api/bizzmine/training/training-api.service';
import { TrainingDto } from 'src/models/ts/training-dto.model';
import { TrainingSubscribeModalComponent, TrainingSubscribeModalComponentMode } from 'src/app/features/training/modal/training-subscribe-modal/training-subscribe-modal.component';
import { DownloadService } from '../../../../core/services/download/download.service';

/**
 * kendo contextmenu containing all actions for an instance.
 */
@Component({
  selector: "bizz-context-menu",
  standalone: true,
  imports: [CommonModule, ContextMenuModule, IconComponent, SafePipe, ActionSheetModule],
  templateUrl: "./context-menu.component.html",
  styleUrls: ["./context-menu.component.scss"],
})
@Sentry.TraceClass({ name: 'ContextMenuComponent' })
export class ContextMenuComponent implements AfterViewInit, OnDestroy {
  @ViewChild("menu", { static: false }) public menu!: KendoContextMenuComponent;

  @ViewChild("actionsheet", { static: true }) public actionsheet!: KendoActionSheetComponent;

  // @Input() public dataItem: CollectionListDataInstance | any;
  // @Input() public permissions: GridContextPermissions | undefined;
  // @Input() public anchor: any;
  @Input() public useSeparators: boolean;
  public dataItem: CollectionListDataInstance | any;

  public menuItems: Partial<ContextMenuItem>[] = [];
  public isMobileView = false;
  public expanded = this.contextMenuService.getObservable();
  private workspaceIdSignal = this.store$.selectSignal(workspaceSidebarFeature.selectID);
  /**
   * Subject used to unsubscribe from all subscriptions when the component is destroyed.
   */
  private destroy: Subject<void> = new Subject<void>();

  public constructor(
    private gridService: GridService,
    private contextMenuItemService: ContextMenuItemService,
    private contextMenuService: ContextMenuService,
    private store$: Store,
    private router: Router,
    private linkingService: LinkingService,
    private fileUploadCheckinService: FileUploadCheckinService,
    private collectionFormFileUploadApiService: CollectionFormFileUploadApiService,
    private collectionFormEditApiService: CollectionFormEditApiService,
    private dialog: Dialog,
    private editOnlineApiServiceService: EditOnlineApiServiceService,
    private trainingApiService: TrainingApiService,

  ) {}

  public ngAfterViewInit(): void {
    this.isMobileView = window.innerWidth <= 640;
    this.expanded.pipe(takeUntil(this.destroy)).subscribe({
      next: (expanded) => {
        if (expanded) this.open();
        else {
          if (this.isMobileView) {
            this.actionsheet.toggle(false);
          } else {
            this.menu.hide();
          }
        }
      },
    });
  }

  /**
   * Listen to window resizing to resize the last grid column.
   */
  @HostListener("window:resize")
  public onResize(): void {
    //640px is the tailwindcss sm breakpoint (maybe make a class for this)
    this.isMobileView = window.innerWidth <= 640;
  }

  @Sentry.TraceMethod({ name: 'open' })
  public open(): void {
    this.dataItem = this.contextMenuService.dataItem;
    const permissions = this.contextMenuService.permissions;
    const anchor = this.contextMenuService.anchorElement;
    const useSeparators = this.contextMenuService.useSeparators;

    if (!this.dataItem || !permissions || !anchor) {
      throw new Error("Missing data for context menu");
    }

    this.menuItems = this.contextMenuItemService.getMenuItems(permissions, this.isMobileView, useSeparators);

    if (this.isMobileView) {
      this.actionsheet.toggle(true);
    } else this.menu.show(anchor);
  }

  @Sentry.TraceMethod({ name: 'close' })
  public close(): void {
    this.contextMenuService.close();
  }

  public onItemSelected($event: ContextMenuSelectEvent | ActionSheetItemClickEvent): void {
    const item = $event.item as Partial<ContextMenuItem>;
    if (!item || typeof item.action === "undefined") return;
    this.handleMenuAction(item.action);
    if (this.isMobileView) {
      this.actionsheet.toggle(false);
    } else this.menu.hide();
  }

  @Sentry.TraceMethod({ name: 'ngOnDestroy' })
  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.unsubscribe();
  }

  private handleMenuAction(action: MenuAction): void {
    switch (action) {
      //TODO: RV, GL, BVB Add other menu actions
      case MenuAction.Read:
        this.gridService.readInstance(
          this.dataItem.CollectionsID,
          this.dataItem.ID,
          this.dataItem.VersionsID,
          this.dataItem.GuidChecksum,
          this.dataItem.DraftsID,
          this.dataItem.OriginalFoldersID,
          true,
          this.contextMenuService.permissions?.isTrashFilterActive,
        );
        break;
      case MenuAction.Edit:
        this.gridService.editInstance(
          this.dataItem.CollectionsID,
          this.dataItem.ID,
          this.dataItem.VersionsID,
          this.dataItem.GuidChecksum,
          this.dataItem.DraftsID,
          this.dataItem.OriginalFoldersID
        );
        break;
      case MenuAction.Execute:
        this.gridService.openNextStep(
          undefined,
          this.dataItem.CollectionsID,
          this.dataItem.ID,
          this.dataItem.VersionsID,
          this.dataItem.TasksID,
          this.dataItem.DraftsID
        );
        break;
      case MenuAction.RecoverInstance:
        this.gridService.restoreInstance(this.dataItem);
        break;
      case MenuAction.RestartWorkflow:
        this.gridService.restartWorkflow(this.dataItem);
        break;
      case MenuAction.Delete:
        this.gridService.deleteInstance(this.dataItem);
        break;
      case MenuAction.Monitor:
        this.gridService.openMonitorModal(this.dataItem.CollectionsID, this.dataItem.ID,  this.dataItem.DraftsID !== 0 ? this.dataItem.DraftsID : this.dataItem.VersionsID);
        break;
      case MenuAction.History:
        this.gridService.openHistoryPage(
          this.dataItem.CollectionsID,
          this.dataItem.ID,
          this.dataItem.VersionsID,
          this.workspaceIdSignal()
        );
        break;
      case MenuAction.Archive: {
        const permissions = this.contextMenuService.permissions;

        if (!permissions || !this.dataItem) {
          throw new Error("Missing data for context menu");
        }

        this.gridService.openArchiveModal(this.dataItem, permissions);
        break;
      }
      case MenuAction.TrackChanges:
        this.gridService.openTrackchangesPage(this.dataItem.CollectionsID, this.dataItem.ID, this.dataItem.VersionsID);
        break;
      case MenuAction.FlowStatus:
        this.gridService.openFlowStatusPage(
          this.dataItem.CollectionsID,
          this.dataItem.ID,
          this.dataItem.DraftsID != undefined && this.dataItem.DraftsID > 0 ? this.dataItem.DraftsID : this.dataItem.VersionsID,
          this.dataItem.MethodType ?? this.dataItem.DocumentFlow,
          this.workspaceIdSignal(),
          true
        );
        break;
      case MenuAction.SubscribeToExam:
        this.store$.dispatch(
          trainingActions.fetchExam({
            collectionsId: <number>this.dataItem.CollectionsID,
            versionsId: <number>this.dataItem.VersionsID,
            config: {
              openExamDialog: true,
              redirectUrl: this.router.url,
            },
          })
        );
        break;
      case MenuAction.ConsultRecord:
          this.gridService.readInstance(this.dataItem.CollectionsID, this.dataItem.ID, this.dataItem.VersionsID,this.dataItem.GuidChecksum, this.dataItem.DraftsID, this.dataItem.OriginalFoldersID);
        break;
      case MenuAction.DownloadFile:
        this.gridService.downloadFromInstance(this.dataItem);
        break;
      case MenuAction.CopyLinkToRecord:
        this.linkingService.copyLinkToRecord(this.dataItem.CollectionsID, this.dataItem.ID, this.dataItem.VersionsID, this.dataItem.GuidChecksum);
        break;
      case MenuAction.DocumentSettings:
        this.gridService.openDocumentSettings(this.dataItem.CollectionsID, this.dataItem.ID, this.dataItem.VersionsID);
        break;
      case MenuAction.Permissions:
        this.gridService.openPermissionsPage(
          this.dataItem.CollectionsID,
          this.dataItem.ID,
          this.dataItem.VersionsID,
          this.dataItem.OriginalFoldersID,
          false
        );
        break;
      case MenuAction.UnlinkFromFolder:
        const widgetId = this.contextMenuService.widgetId;
        if (!widgetId) throw new Error("WidgetId is missing");
        const unlinkFromFolderParams: UnlinkFromFolderParams = {
          collectionId: this.dataItem.CollectionsID,
          instanceId: this.dataItem.ID,
          folderId: this.dataItem.FoldersID,
          widgetId: widgetId,
          inheritType: this.dataItem.InheritType,
          targetType: this.dataItem.TargetType,
        };
        this.gridService.openUnlinkFromFolderModal(unlinkFromFolderParams);
        break;
        break;
      case MenuAction.CheckInFile:
        this.fileUploadCheckinService.openCheckInDialog(this.dataItem, true);
        break;
      case MenuAction.UndoCheckoutFile: {
        let versionId: number = this.dataItem.VersionsID;
        if (this.dataItem.DraftsID != null && this.dataItem.DraftsID > 0) {
          versionId = this.dataItem.DraftsID;
        }
        this.collectionFormEditApiService.undoCheckOut({
          versionId: versionId,
          instanceId: this.dataItem.ID,
          collectionId: this.dataItem.CollectionsID
        }).pipe(take(1),tap(() => this.store$.dispatch(refreshActions.refreshData({collectionId: this.dataItem.CollectionsID}))))
          .subscribe();
      }
        break;
      case MenuAction.CheckoutFile : {
        let versionId: number = this.dataItem.VersionsID;
        if (this.dataItem.DraftsID != null && this.dataItem.DraftsID > 0) {
          versionId = this.dataItem.DraftsID;
        }
        this.fileUploadCheckinService.checkOutDocument({
          checkOut$: this.collectionFormFileUploadApiService.checkOut({
            collectionId: this.dataItem.CollectionsID,
            instanceId: this.dataItem.ID,
            versionId: versionId
          }).pipe(take(1),tap(() => this.store$.dispatch(refreshActions.refreshData({collectionId: this.dataItem.CollectionsID})))),
          storeData: undefined
        });
      }
        break;
      case MenuAction.PutFileWithoutCheckIn:
        this.fileUploadCheckinService.openCheckInDialog(this.dataItem, false);
        break;
      case MenuAction.DuplicateExam:
        this.gridService.duplicateInstance(this.dataItem);
        break;
      case MenuAction.Report:
        this.gridService.openReportModal(this.dataItem.CollectionsID, this.dataItem.ID, this.dataItem.VersionsID, this.dataItem.ReportSettings);
        break;
      case MenuAction.ViewOnline:
        {
          this.openOnline(this.dataItem, FileUploadEditOnlineModalComponentMode.View);
        }
        break;
      case MenuAction.EditOnline:
        {
          this.openOnline(this.dataItem, FileUploadEditOnlineModalComponentMode.Edit);
        }
        break;
      case MenuAction.CreateMajorChanges:
        {
          this.gridService.createMajorRevision(this.dataItem.CollectionsID, this.dataItem.ID, this.dataItem.VersionsID);
        }
        break;
      case MenuAction.CreateMinorChanges:
        {
          this.gridService.createMinorRevision(this.dataItem.CollectionsID, this.dataItem.ID, this.dataItem.VersionsID);
        }
        break;
      case MenuAction.SubscribeToTraining:
        {
          this.onSubscribeToTraining();
        }
        break;
      default:
        throw new Error("Unhandled context menu action");
    }
  }

  private openOnline(dataItem: CollectionListDataInstance | any, mode: FileUploadEditOnlineModalComponentMode): void {
    let versionId = dataItem.VersionsID;
    if (dataItem.DraftsID != null && dataItem.DraftsID > 0) {
      versionId = dataItem.DraftsID;
    }
    const params: FileUploadEditOnlineModalComponentData = {
      mode: mode,
      docheckoutcheckin: true,
      fromGrid: { collectionId: dataItem.CollectionsID, instanceId: dataItem.ID, versionId: versionId },
      mediaId: 0,
      filename: "",
      modificationreason: "",
      closeModalMethod: (): void => {
        dlgRef.close();
      },
      finishMethod: (data: any): void => {
        if (data.openedOneDriveWindow != null) {
          data.openedOneDriveWindow.close();
        }
        this.editOnlineApiServiceService
          .retrieveOnlineMediaId({
            mediaId: data.mediasID!,
            oneDriveKey: data.oneDriveKey,
            lastModified: data.lastModified,
          })
          .pipe(
            take(1),
            tap((response) => {
              if (response.body != null && response.body.byteLength > 0) {
                const headers = response.headers;
                const fileName = DownloadService.cleanupFileName(headers.get("x-filename"));
                const contentType = headers.get("contentType") ?? "";
                const file = new File([response.body], fileName, { type: contentType });

                if (dlgRef.componentInstance != null) {
                  dlgRef.componentInstance
                    .uploadFile(file)
                    .then((mediaDto) => {
                      this.editOnlineApiServiceService
                        .removeCheckOutOnlineMedia({
                          mode: mode,
                          oneDriveKey: data.oneDriveKey,
                          undoCheckout: false,
                          mediasId: data.mediasID!,
                        })
                        .pipe(
                          take(1),
                          finalize(() => dlgRef.close())
                        )
                        .subscribe();
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              } else {
                this.editOnlineApiServiceService
                  .removeCheckOutOnlineMedia({
                    mode: mode,
                    oneDriveKey: data.oneDriveKey,
                    undoCheckout: true,
                    mediasId: data.mediasID!,
                  })
                  .pipe(
                    take(1),
                    finalize(() => dlgRef.close())
                  )
                  .subscribe();
              }
            })
          )
          .subscribe();
      },
    };
    const dlgRef = this.dialog.open(FileUploadEditOnlineModalComponent, {
      data: params,
      disableClose: true,
    });
    dlgRef.closed.pipe(take(1)).subscribe();
  }

  private onSubscribeToTraining(): void {
    this.trainingApiService.getTraining({
      collectionId: this.dataItem.CollectionsID!,
      versionId: this.dataItem.VersionsID!,
      instanceId: this.dataItem.ID!
    }).subscribe(found => {
      this.dialog.open<TrainingDto | undefined>(TrainingSubscribeModalComponent, {
        data: {
          data: found,
          enableNoPreference: true,
          enableCreateNewSession: false,
          mode: TrainingSubscribeModalComponentMode.Subscribe
        }
      })
        .closed
        .subscribe((result: TrainingDto | undefined) => {
          if (result !== undefined) {
            this.addToQueue(found, result?.ID);
          }
        });
    });
  }

  public addToQueue(source: TrainingDto, sessionId: number | null): void {
    this.trainingApiService.subscribeToTraining({
      trainingId: source.ID,
      sessionId: sessionId ?? 0,
      trainingAppId: source.TrainingAppID
    }).subscribe(() => {
    });
  }
}
