import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../../../../ui/icon/icon.component';
import { TranslatePipe } from '../../../../../../pipes/translate/translate.pipe';
import { DatetimePipe } from '../../../../../../pipes/dates/datetime.pipe';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { CompareBaseModalComponent } from '../../compare-base-modal.component';


/**
 * Modal that offers the user the choice to delete the draft or published
 * version of an instance.
 */
@Component({
  selector: 'bizz-delete-draft-or-published-modal',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslatePipe, DatetimePipe, TextAreaModule],
  templateUrl: './delete-draft-or-published-modal.component.html',
  styleUrls: ['../../compare-base-modal.component.scss'],
})

export class DeleteDraftOrPublishedModalComponent extends CompareBaseModalComponent {
  public delete(): void {
    if (this.selectedVersion != undefined) {
      this.collectionListService.deleteInstance(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.selectedVersion, this.data.CollectionsID!, this.data.ID!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.data.VersionsID!, this.data.DraftsID!, this.deleteReason).subscribe({
        next: () => {
          //this.gridService.refreshLists();
        },
      });
    }

    this.dialogRef.close();
  }
}
