import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HeaderNotificationsFeatureStoreModule } from './features/header-notifications/header-notifications-feature-store.module';
import { rootStateReducer } from './root-state.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers } from './debug.meta-reducer';
import { FormsFeatureStoreModule } from './features/forms/forms-feature-store.module';
import { HeaderTasksFeatureStoreModule } from './features/header-tasks/header-tasks-feature-store.module';
import { HeaderRemindersFeatureStoreModule } from './features/header-reminders/header-reminders-feature-store.module';
import { UserSettingsFeatureStoreModule } from './features/user-settings/user-settings-feature-store.module';
import { WorkspaceSidebarFeatureModule } from './features/workspace-sidebar/workspace-sidebar-feature.module';
import { FlowStatusFeatureStoreModule } from './features/flow-status/flow-status-feature-store.module';
import { TrainingFeatureStoreModule } from './features/training/training-feature-store.module';
import { CollectionFeatureModule } from './features/collections/collections-feature.module';
import { AppFeatureStoreModule } from './features/app/app-feature-store.module';
import { NgIdleModule } from '@ng-idle/core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(rootStateReducer, { metaReducers }),
    EffectsModule.forRoot([]),
    AppFeatureStoreModule,
    HeaderNotificationsFeatureStoreModule,
    FormsFeatureStoreModule,
    HeaderTasksFeatureStoreModule,
    HeaderRemindersFeatureStoreModule,
    UserSettingsFeatureStoreModule,
    WorkspaceSidebarFeatureModule,
    FlowStatusFeatureStoreModule,
    TrainingFeatureStoreModule,
    CollectionFeatureModule,
    StoreDevtoolsModule.instrument(),
    NgIdleModule.forRoot()
  ],
})
export class RootStoreModule {
}
