import { createReducer, on } from '@ngrx/store';
import { appActions } from './app-actions';
import { initialAppState } from './app-state';

export const appReducer = createReducer(
  initialAppState,
  on(appActions.userSettingsLoading, (state) => {
    const updatedState = structuredClone(state);
    updatedState.userSettingsLoaded = false;
    return updatedState;
  }),
  on(appActions.userSettingsLoaded, (state) => {
    const updatedState = structuredClone(state);
    updatedState.userSettingsLoaded = true;
    return updatedState;
  }),
  on(appActions.translationsLoading, (state) => {
    const updatedState = structuredClone(state);
    updatedState.translationsLoaded = false;
    return updatedState;
  }),
  on(appActions.translationsLoaded, (state) => {
    const updatedState = structuredClone(state);
    updatedState.translationsLoaded = true;
    return updatedState;
  }),
);