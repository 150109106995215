<div class="h-full rounded bg-widget p-5 overflow-y-auto">
    @if (pendingTasks.length > 0) {
        <legend class="mb-4 font-sans text-base font-bold text-blue-500">{{ 'PendingSteps' | translate }}</legend>
        <bizz-flow-status-tasks-grid [tasks]="pendingTasks" [taskType]="FlowStatusTaskType.Pending"
                                     (showAssessmentDetails)="showAssessmentDetails($event)"
                                     (taskDetails)="taskDetails($event)"></bizz-flow-status-tasks-grid>
    }
    @if (completedTasks.length > 0) {
        <legend class="my-4 font-sans text-base font-bold text-blue-500">{{ 'CompletedTasks' | translate }}</legend>
        <bizz-flow-status-tasks-grid [tasks]="completedTasks"
                                     [taskType]="FlowStatusTaskType.Completed"
                                     (showAssessmentDetails)="showAssessmentDetails($event)"
                                     (taskDetails)="taskDetails($event)"></bizz-flow-status-tasks-grid>
    }
</div>
