import { Component, DestroyRef, EventEmitter, Input, Output } from '@angular/core';
import { IconComponent } from 'src/app/shared/components/ui/icon/icon.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { TrackChangesGridComponent } from '../track-changes-grid/track-changes-grid.component';
import { FormsModule } from '@angular/forms';
import { GridOptions } from 'src/app/shared/classes/list/grid-options';
import { TrackChangesParams } from '../../interfaces/track-changes-params.interface';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectByCollectionId } from 'src/app/store/features/collections/collections-selectors';
import { TrackChangesApiService } from 'src/app/api/bizzmine/track-changes/track-changes-api.service';
import { AsyncPipe } from '@angular/common';
import { AuditLogGridSearchDto } from 'src/models/ts/audit-log-grid-search-dto.model';

@Component({
  selector: 'bizz-track-changes',
  standalone: true,
  imports: [TranslatePipe, IconComponent, TrackChangesGridComponent, FormsModule, AsyncPipe],
  templateUrl: './track-changes.component.html',
  styleUrl: './track-changes.component.scss'
})
export class TrackChangesComponent {
  @Output() public onClose = new EventEmitter<void>();
  @Input() public data: TrackChangesParams;
  private caption: string;

  public gridOptions: GridOptions;
  public searchTerm: string;
  public searchTerm$ = new BehaviorSubject<string>('');

  public searchFilter:Partial<AuditLogGridSearchDto>;
  
  public constructor(private downloadService: DownloadService, private trackChangesService: TrackChangesApiService, private destroyRef: DestroyRef, private store$: Store) {

  }

  public ngOnInit(): void {
    this.store$.select(selectByCollectionId(this.data.collectionId)).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
      this.caption = res?.data?.Caption as string;
    });
  }
  public onBackClicked(): void {
    this.onClose.emit();

  }
  public export(): void {
    if (this.searchFilter != null) {
      this.searchFilter.Records = 0;
      this.trackChangesService.export(this.data.collectionId, this.data.instanceId, this.data.versionId, this.searchFilter)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          map(file =>
            this.downloadService.startDownload(file, this.caption + '_'+this.data.instanceId+'_Track Changes'+ '.csv')
          )).subscribe();
    }
  }

  public onListSearchFilterChange(search: Partial<AuditLogGridSearchDto>): void {
    this.searchFilter = search;
  }

  public onSearchTermChanged(): void {
    this.searchTerm$.next(this.searchTerm);

  }

}
