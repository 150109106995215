import { ColumnBase } from '@progress/kendo-angular-grid';


const KENDO_GRID_GROUP_COLUMN_WIDTH = 32;

/**
 * Resize the last grid column to take the remaining width of the grid and leave no
 * whitespace.
 * Should only be called when the grid is visible, has data(rows) and the columns are resizable.
 * @param columns The columns of the grid.
 * @param totalGridWidth The total width of the grid.
 * @param nrOfGroups The number of groups in the grid.
 * @param initialLastColumnWidth The initial width of the last column.
 */
export function resizeLastGridColumn(columns: ColumnBase[], totalGridWidth: number, nrOfGroups: number,
                                     initialLastColumnWidth: number | undefined): void {

  const visibleColumns = columns.filter((column) => !column.hidden && column.width != undefined);

  const combinedWidthOfOtherColumns = visibleColumns.slice(0, -1).reduce(
    (acc, curr) => {
      return (acc + curr.width);
    }, 0);
  if (visibleColumns[visibleColumns.length - 1] != undefined) {
    if (initialLastColumnWidth == undefined)
      initialLastColumnWidth = visibleColumns[visibleColumns.length - 1].width;

    if (totalGridWidth > combinedWidthOfOtherColumns + ((nrOfGroups * KENDO_GRID_GROUP_COLUMN_WIDTH) + 1)) {
      visibleColumns[visibleColumns.length - 1].width =
        Math.max(totalGridWidth - combinedWidthOfOtherColumns - ((nrOfGroups * KENDO_GRID_GROUP_COLUMN_WIDTH) + 1),
          initialLastColumnWidth);
    }
  }
}