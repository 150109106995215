import { Pipe, PipeTransform } from '@angular/core';
import { NotificationService } from '../../services/filter/notification-service';

/**
 * Notification Filter Pipe is used for converting bizzmine regex to normal text.
 */

@Pipe({
  name: 'notificationFilterPipe',
  standalone: true
})
export class NotificationFilterPipe implements PipeTransform {
  constructor(private notificationService: NotificationService) {
  }
  transform(filterInput: string): string {
    return this.notificationService.convertNotificationText(filterInput)
  }

}
