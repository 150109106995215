import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { TrnSkillGradeDto } from '../../../../../../../models/ts/trn-skill-grade-dto.model';
import { TrnUserSkillGradeDto } from '../../../../../../../models/ts/trn-user-skill-grade-dto.model';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { NgStyle } from '@angular/common';
import { ExtensionIconComponent } from '../../../../../../shared/components/ui/icon/extension-icon/extension-icon.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { SafePipe } from 'safe-pipe';
import { KeyCode } from '../../../../../../shared/enums/key-code';

@Component({
  selector: 'bizz-form-trainingapp-assessment-slider',
  standalone: true,
  imports: [
    IconComponent,
    NgStyle,
    ExtensionIconComponent,
    TooltipModule,
    SafePipe
  ],
  templateUrl: './form-trainingapp-assessment-slider.component.html',
  styleUrl: './form-trainingapp-assessment-slider.component.scss'
})
export class FormTrainingappAssessmentSliderComponent  {
  @HostListener('document:keydown', ['$event'])
  private handleKeyboardEvent(event: KeyboardEvent) : void {
    if(this.mouseOverIndex == undefined) return;
    this.selectedIndex = this.mouseOverIndex;
    if(event.key === KeyCode.ARROW_LEFT){
      this.left();
    }
    if(event.key === KeyCode.ARROW_RIGHT){
      this.right();
    }
  }

  public data: TrnUserSkillGradeDto;
  @Input({required: true}) public set value(value: TrnUserSkillGradeDto) {
    this.data = value;
    if(this.skills){
      this.selectedIndex = this.getSelectedIndex();
    }
  }
  @Output() public valueChanged = new EventEmitter<TrnUserSkillGradeDto>();
  @Input({required: true}) public set skillGrades(value: Array<TrnSkillGradeDto>) {
    this.skills = value;
    if(this.data){
      this.selectedIndex = this.getSelectedIndex();
    }
  }
  public skills: Array<TrnSkillGradeDto>;

  public width: 50;

  public onSkillSelect(skillgrade: TrnSkillGradeDto) : void{
    this.data.SkillGradeID = skillgrade.ID;
    this.valueChanged.emit(this.data);
    this.selectedIndex = this.getSelectedIndex();
  }

  public selectedIndex: number;
  public mouseOverIndex: number | undefined;

  public getSelectedIndex() : number {
    return  this.skills.findIndex(skill => skill.ID === this.data.SkillGradeID);
  }

  public mouseOver($event: number) : void{
    this.mouseOverIndex = $event;
  }

  public mouseOut() : void{
    this.mouseOverIndex = undefined;
  }
  public left () : void {
    if (this.selectedIndex > 0){
      this.selectedIndex--;
      this.mouseOverIndex = this.selectedIndex;
      this.onSkillSelect(this.skills[this.selectedIndex]);
    }
  }
  public right () : void {
    if (this.selectedIndex <= this.skills.length){
      this.selectedIndex++;
      this.mouseOverIndex = this.selectedIndex;
      this.onSkillSelect(this.skills[this.selectedIndex]);
    }
  }
}
