import { Component, Input, OnInit } from '@angular/core';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';

@Component({
  selector: 'bizz-grid-cell-link',
  templateUrl: './grid-cell-link.component.html',
  styleUrls: ['./grid-cell-link.component.scss'],
  standalone: true,
})
export class GridCellLinkComponent extends GridCellBaseComponent<string> {
}
