import { inject, Injectable } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { Observable, of } from 'rxjs';
import { UnsavedChangesModalComponent } from '../../components/modals/unsaved-changes-modal/unsaved-changes-modal.component';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesService {
  private dialog = inject(Dialog);

  public openUnsavedChangesDialog(): Observable<boolean | undefined> {
    return this.dialog.open<boolean>(UnsavedChangesModalComponent).closed;
  }
}
