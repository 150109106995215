import { Pipe, PipeTransform } from '@angular/core';
import { IconLibrary } from '../../../../models/ts/icon-library.model';

@Pipe({
  name: 'iconLibName',
  standalone: true
})
export class IconLibNamePipe implements PipeTransform {

  /**
   * Returns a string representation of the given IconLibrary.
   * @param value
   */
  transform(value: IconLibrary | string): string {
    if (typeof value == 'string') {
      return value.toLowerCase();
    } else {
      switch (value) {
        case IconLibrary.BizzMine:
          return 'bizzmine';
        case IconLibrary.FlatIcons:
          return 'flaticons';
        case IconLibrary.FontAwesome:
          // fontawesome should not need this
          return 'fontawesome';
        case IconLibrary.IcoMoon:
          return 'icomoon';
      }
    }
  }

}
