import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CollectionFormApiService } from '../../../../../../../api/bizzmine/collection-form/collection-form-api.service';
import { BehaviorSubject, map, shareReplay, switchMap, tap } from 'rxjs';
import { ExtensionIconComponent } from '../../../../../../../shared/components/ui/icon/extension-icon/extension-icon.component';
import { IconComponent } from '../../../../../../../shared/components/ui/icon/icon.component';
import { PopoverModule, TooltipModule } from '@progress/kendo-angular-tooltip';
import { SafePipe } from 'safe-pipe';
import { ToggleArrowComponent } from '../../../../../../../shared/components/ui/toggle-arrow/toggle-arrow.component';
import { AsyncPipe, CommonModule, JsonPipe, NgForOf, NgTemplateOutlet } from '@angular/common';
import { LoaderButtonComponent } from '../../../../../../../shared/components/loader-button/loader-button/loader-button.component';
import { TranslatePipe } from '../../../../../../../shared/pipes/translate/translate.pipe';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'bizz-select-mail-template',
  standalone: true,
  imports: [
    CommonModule,
    ExtensionIconComponent,
    IconComponent,
    PopoverModule,
    SafePipe,
    ToggleArrowComponent,
    AsyncPipe,
    LoaderButtonComponent,
    TranslatePipe,
    NgTemplateOutlet,
    NgForOf,
    JsonPipe,
    TooltipModule,
    FormsModule
  ],
  templateUrl: './select-mail-template.component.html',
  styleUrl: './select-mail-template.component.scss'
})
export class SelectMailTemplateComponent {

  public selectedTemplate : number | null | undefined;
  private collectionId$ = new BehaviorSubject(this.dialogModel.collectionId);
  public templates$ = this.collectionId$
    .pipe(
      switchMap(id => this.collectionFormApiService.getMailTemplates({ collectionId: id })),
      shareReplay(1)
    );

  public searchTerm: string = '';
  public search$ = new BehaviorSubject(this.searchTerm);
  public filteredTemplates$ = this.search$
    .pipe(
      switchMap(term =>{
        return this.templates$
          .pipe(
            map(templates => templates.filter(template => template.Name.toLowerCase().includes(term.toLowerCase()))),
            tap((data) => {
              if(this.selectedTemplate !== undefined && data.find(template => template.ID === this.selectedTemplate) == null){
                this.selectedTemplate = undefined;
              }
            })
          )

      })
    )

  public constructor(private dialogRef: DialogRef,
                     private collectionFormApiService: CollectionFormApiService,
                     @Inject(DIALOG_DATA) public dialogModel: {
                       collectionId: number
                     }) {
    this.dialogRef.disableClose = true;
  }

  public close(emitValue: boolean = false): void {
    if (!emitValue) {
      this.dialogRef.close(null);
    } else {
      const value: { selectedTemplate: number | null } = { selectedTemplate: this.selectedTemplate ?? null };
      this.dialogRef.close(value);
    }
  }

}
