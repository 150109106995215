import { Component } from '@angular/core';
import { GridCellBaseComponent } from '../../grid-cell-base/grid-cell-base.component';
import { TranslatePipe } from '../../../../../pipes/translate/translate.pipe';

@Component({
  selector: 'bizz-grid-cell-mail-status',
  standalone: true,
  imports: [
    TranslatePipe
  ],
  templateUrl: './grid-cell-mail-status.component.html',
  styleUrl: './grid-cell-mail-status.component.scss'
})
export class GridCellMailStatusComponent extends GridCellBaseComponent<boolean> {

}
