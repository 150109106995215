export interface CronTimeHelpers{
    firstToFiveArray: string[];
    weekdaysArray: string[];
    monthsArray: string[];
    monthSpecificdaysArray: string[];
}
export const timeHelpers: CronTimeHelpers = {
    firstToFiveArray: ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Last'],
    weekdaysArray: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    monthsArray: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthSpecificdaysArray: ['First Weekday', '1st Day', '2nd Day', '3rd Day', '4th Day', '5th Day', '6th Day', '7th Day', '8th Day', '9th Day', '10th Day', '11th Day', '12th Day', '13th Day', '14th Day', '15th Day', '16th Day', '17th Day', '18th Day', '19th Day', '20th Day', '21st Day', '22nd Day', '23rd Day', '24th Day', '25th Day', '26th Day', '27th Day', '28th Day', '29th Day', '30th Day', '31st Day', 'Last Weekday', 'Last Day'],
};