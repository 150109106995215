import { Injectable } from '@angular/core';
import { FormScriptingField } from '../../classes/form-scripting-field';
import { CollectionFormService } from '../collection-form.service';
import { CollectionForm } from '../../../../../../models/ts/collection-form.model';
import { EvaluationActionFieldValueDto } from '../../../../../../models/ts/evaluation-action-field-value-dto.model';
import { EvaluationsActionsType } from '../../../../../../models/ts/evaluations-actions-type.model';
import { CollectionFormField } from '../../../../../../models/ts/collection-form-field.model';
import { ProtectedFieldType } from '../../../../../../models/ts/protected-field-type.model';
import { LinkedCollectionStorageType } from '../../../../../../models/ts/linked-collection-storage-type.model';

/**
 * Service handling execution of scripting actions
 */
@Injectable({
  providedIn: 'root'
})
export class FormScriptingActionsService {

  /**
   * Executes an action (an effect from scripting). <br>
   * Currently supported actions are: SetVisible, SetInvisible, SetRequired, SetNotRequired
   * @param form
   * @param field
   * @param actionValues
   */
  public static executeAction(form: CollectionForm, field: FormScriptingField, actionValues: EvaluationActionFieldValueDto[]): CollectionForm {
    actionValues.forEach(actionValue => {
      switch (actionValue.ActionsType) {
        case EvaluationsActionsType.SetVisible:
          FormScriptingActionsService.setFieldVisibility(field, form, true);
          break;
        case EvaluationsActionsType.SetInvisible:
          FormScriptingActionsService.setFieldVisibility(field, form, false);
          break;
        case EvaluationsActionsType.SetRequired:
          FormScriptingActionsService.setFieldRequired(field, true);
          break;
        case EvaluationsActionsType.SetNotRequired:
          FormScriptingActionsService.setFieldRequired(field, false);
          break;
        default:
          break;
      }
    });
    return form;
  }

  private static setAllCollectionFileFieldsVisibility(form: CollectionForm, sourceCollectionsID: number, ViewDataSourcesID: number, hide: boolean): void {
    const fileFields = FormScriptingActionsService.getCollectionFileFields(form, sourceCollectionsID, ViewDataSourcesID);

    fileFields.forEach(field => {
      field.IsHidden = hide;
    });
  }

  private static setAllCollectionFieldsVisibility(form: CollectionForm, sourceCollectionsID: number, ViewDataSourcesID: number, hide: boolean): void {
    const fields = FormScriptingActionsService.getSourceCollectionFields(form, sourceCollectionsID, ViewDataSourcesID);
    fields.forEach(field => {
      field.IsHidden = hide;
    });
  }

  private static getSourceCollectionFields(form: CollectionForm, sourceCollectionsID: number, viewDataSourcesID: number): CollectionFormField[] {
    const collectionFields: CollectionFormField[] = [];

    CollectionFormService.extractFieldsFromForm(form).forEach(field => {
      if (field.SourceCollectionsID == sourceCollectionsID && field.DataDesignViewDataSourcesID == viewDataSourcesID) {
        collectionFields.push(field);
      }
    });
    return collectionFields;
  }

  private static getCollectionFileFields(form: CollectionForm, sourceCollectionsID: number, viewDataSourcesID: number): CollectionFormField[] {
    const collectionFileFields: CollectionFormField[] = [];

    const fileProtectedFieldTypes = [ProtectedFieldType.File, ProtectedFieldType.Extension, ProtectedFieldType.Size, ProtectedFieldType.VersionDate, ProtectedFieldType.Version,
      ProtectedFieldType.ChangeReason, ProtectedFieldType.Title, ProtectedFieldType.DocumentStatus, ProtectedFieldType.DocumentCheckinStatus, ProtectedFieldType.DocumentFlow,
      ProtectedFieldType.MinorVersion, ProtectedFieldType.MajorVersion];

    CollectionFormService.extractFieldsFromForm(form).forEach(field => {
      if (fileProtectedFieldTypes.includes(field.ProtectedFieldType) && field.SourceCollectionsID == sourceCollectionsID && field.DataDesignViewDataSourcesID == viewDataSourcesID) {
        collectionFileFields.push(field);
      }
    });
    return collectionFileFields;
  }

  /**
   * Sets the IsHidden property. Will do nothing if IsRequired is true and isVisible is false.
   * (Required fields cannot be hidden)
   * @param {FormScriptingField} field
   * @param {CollectionForm} form
   * @param {boolean} isVisible
   * @private
   */
  private static setFieldVisibility(field: FormScriptingField, form: CollectionForm, isVisible: boolean): void {
    field.field.IsHidden = !isVisible;

    if (field.field.LinkedCollectionStorageType == LinkedCollectionStorageType.Relational && field.field.IsLookupField) {
      console.warn('Relational linked collection with action on lookup field: Set all linked collection fields invisible');
      FormScriptingActionsService.setAllCollectionFieldsVisibility(form, field.field.SourceCollectionsID, field.field.ViewDataSourcesID, !isVisible);
    }

    if (field.field.ProtectedFieldType == ProtectedFieldType.File) {
      console.warn('Action on file field: Set all linked collection fields invisible');
      FormScriptingActionsService.setAllCollectionFileFieldsVisibility(form, field.field.SourceCollectionsID, field.field.ViewDataSourcesID, !isVisible);
    }
  }

  /**
   * Sets the field IsRequired property. If true, also sets IsHidden to false.
   * @param {FormScriptingField} field
   * @param {boolean} isRequired
   * @private
   */
  private static setFieldRequired(field: FormScriptingField, isRequired: boolean): void {
    field.field.IsRequired = isRequired;
    if (isRequired)
      field.field.IsHidden = false;
  }
}
