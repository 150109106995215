<div class="flex items-center justify-between border-b-2 border-sidebar bg-page-header px-4 py-2">
  <button class="default" (click)="back()">
    <bizz-icon class="h-4 w-4" iconName="caret-left"></bizz-icon>
    <span>{{ 'Back' | translate}}</span>
  </button>

  <h1 class="mx-4 w-full text-center font-title text-xl font-semibold leading-6 text-default">
    {{'Flow' | translate }} {{ title }}
    <span class="ml-0.5 font-sans text-xs font-medium">
      ({{ 'FlowVersion' | translate }}<span class="pl-1">{{ flowVersion }})</span>
    </span>
  </h1>
  <div class="invisible hidden sm:block sm:w-9"></div>
</div>
