<bizz-modal [modalSize]="modalSize" [headerTemplate]="headerTemplateRef"
    [contentTemplate]="activeContentTemplateRef"></bizz-modal>
<ng-template #headerTemplateRef>
    <div class="modal-header">
        <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
            {{ 'Info' | translate }}: {{auditLogInfo.EventTypeString}}</h1>
    </div>
    <hr class="my-5" />
</ng-template>

<ng-template #activeContentTemplateRef>
    @if (this.auditLogInfo.Tasks && this.auditLogInfo.Tasks.length>0) {
        @for(task of auditLogInfo.Tasks; track task.ID){
            <div>
                <h4>{{'Task' | translate}}: {{task.Subject}}</h4>
                <p>{{'CreatedOn' | translate}} <strong>{{task.CreatedDate | datetime}}</strong> {{'By' | translate}}
                    <strong>{{task.CreatedBy}}</strong>
                </p>
                @if(task.Mails.length > 0){
                    <kendo-grid class="mt-2" [data]="task.Mails">
                        <kendo-grid-column field="To[0].UsersEmail" [title]="'To' | translate"> </kendo-grid-column>
                        <kendo-grid-column field="Subject" [title]="'Subject' | translate"> </kendo-grid-column>
                        <kendo-grid-column field="Body" [title]="'Body' | translate">
                            <ng-template kendoGridCellTemplate let-mail>
                            {{mail.Body}}
                            <bizz-popper [title]="mail.Body" ></bizz-popper>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                }@else{
                <div>
                    {{ 'NoMailsSent' | translate }}
                </div>
                }
            </div>
            }
    }
    @if (this.auditLogInfo.Notifications && this.auditLogInfo.Notifications.length>0) {
        @for(notification of auditLogInfo.Notifications; track notification.ID){
            <h4>{{'Notification' | translate}}: {{notification.Name}} ({{notification.EventTypeString}})</h4>
            <p>{{'CreatedOn' | translate}} <strong>{{notification.CreatedDate | datetime}}</strong> {{'By' | translate}}
                <strong>{{notification.CreatedBy}}</strong>
            </p>
            @if(notification.Mails.length > 0){
                <kendo-grid class="mt-2" [data]="notification.Mails">
                    <kendo-grid-column field="To[0].UsersEmail" [title]="'To' | translate"> </kendo-grid-column>
                    <kendo-grid-column field="Subject" [title]="'Subject' | translate"> </kendo-grid-column>
                    <kendo-grid-column field="Body" [title]="'Body' | translate">
                        <ng-template kendoGridCellTemplate let-mail>
                        {{mail.Body}}
                        <bizz-popper [title]="mail.Body" ></bizz-popper>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            }@else{
            <div>
                {{ 'NoMailsSent' | translate }}
            </div>
            }
            }
    }
    @if (this.auditLogInfo.Reminder) {
        <h4>{{auditLogInfo.Reminder.Subject}} ({{auditLogInfo.Reminder.EventTypeString}})</h4>
        <p>{{'CreatedOn' | translate}} <strong>{{auditLogInfo.Reminder.Date | datetime}}</strong> {{'By' | translate}}
            <strong>{{auditLogInfo.Reminder.CreatedBy}}</strong>
        </p>
        @if(auditLogInfo.Reminder.Mails.length > 0){
            <kendo-grid class="mt-2" [data]="auditLogInfo.Reminder.Mails">
                <kendo-grid-column field="To[0].UsersEmail" [title]="'To' | translate"> </kendo-grid-column>
                <kendo-grid-column field="Subject" [title]="'Subject' | translate"> </kendo-grid-column>
                <kendo-grid-column field="Body" [title]="'Body' | translate">
                    <ng-template kendoGridCellTemplate let-mail>
                    {{mail.Body}}
                    <bizz-popper [title]="mail.Body" ></bizz-popper>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        }@else{
        <div>
            {{ 'NoMailsSent' | translate }}
        </div>
        }
    }
    @if (this.auditLogInfo.RestartWorkflowAuditLogs && this.auditLogInfo.RestartWorkflowAuditLogs.length > 0 ) {
        <p>{{'RestartedOn' | translate}} <strong>{{auditLogInfo.ExecutedOn | datetime}}</strong> {{'By' | translate}}
            <strong>{{auditLogInfo.ExecutedBy}}</strong>
        </p>
        <kendo-grid class="mt-2" [data]="auditLogInfo.RestartWorkflowAuditLogs">
            <kendo-grid-column field="EventTypeString" [title]="'EventType' | translate"> </kendo-grid-column>
            <kendo-grid-column field="StepName" [title]="'StepName' | translate"> </kendo-grid-column>
        </kendo-grid>
    }
    @if (this.auditLogInfo.EventType === AuditLogEventType.Delete ||
    this.auditLogInfo.EventType === AuditLogEventType.RecoverFromTrashCan ||
    this.auditLogInfo.EventType === AuditLogEventType.RestartWorkflow) {
        <h4>{{'Reason' | translate}}</h4>
        @if(auditLogInfo.Reason){
        <small [innerHTML]="auditLogInfo.Reason"></small>
        }
    }
    @if (this.auditLogInfo.EventType === AuditLogEventType.PeriodicRevisionPostPoned) {
        postPonedToTemplateRef
        <h4>{{'PostPonedTo' | translate}}</h4>
        <span>{{auditLogInfo.PostPonedTo | datetime}}</span>
    }
</ng-template>
