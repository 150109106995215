export class AlertOptions {
  id?: string;
  content: Array<string>;
  title: string;
  icon?: string;
  type?: 'success' | 'error' | 'warning' | 'info' = 'info';
  dismissable? = true;
  timed? = false;
  timer? = 5000;
  details? = true;

  public constructor(init?: Partial<AlertOptions>) {
    this.id = crypto.randomUUID();
    Object.assign(this, init);
  }
}