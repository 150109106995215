<!-- timestamp -->
<div class="hidden h-10 w-20 flex-col items-end justify-center text-xs md:flex">
  <span class="font-bold">{{ item.VersionDate | utcDate }}</span>
  <span>{{ item.VersionDate | time }}</span>
</div>

<!-- Icon and Timeline line -->
<div class="flex-col flex w-32 my-0 mr-4">
    <div class="flex flex-row justify-center" [ngClass]="isMinorVersion ? 'pl-[3.95rem]' : ''">
        @if (isMinorVersion) {
            <div class="mx-auto my-1.5 h-full w-0 border border-divider">
            </div>
            <div class="my-auto mr-1.5 w-full h-0 border border-divider">
            </div>
        } 
        <bizz-archive-item-version [content]="item.Version" [ngClass]="{
          'text-red-500': isDeleted,
          'text-lighter': isDraft,
          'text-blue-500': !isDraft
        }" class="flex justify-center" />
    </div>
    @if(!isLastItem) {
        <div class="mx-auto my-1.5 h-full w-0 border border-divider">
        </div>
    }    
</div>

<!-- Step content & actions -->
<div class="flex h-full items-center text-xs pb-8 flex-1">
    <bizz-extension-icon [extension]="item.Extension" [size]="'large'" class="mr-2 hidden md:flex"/>
    <div class="flex flex-col items-start">        
        @if (showDownload()) {
            <div class="cursor-pointer hover:text-blue-500 text-lg font-bold" (click)="download()">
                {{ item.Title }} ({{ item.Extension }})
            </div>
        } @else {
            <div class="text-lg font-bold">
                {{'NoDocument'| translate }}
            </div>
        }
        <div [ngSwitch]="item.DocumentStatus">
            @switch (item.DocumentStatus) {
                @case (versionStateType.Active) {
                    <span class="font-bold">{{ 'PublicVersion' | translate }}</span>
                }
                @case (versionStateType.PrePublicationDeleted) {
                    <span class="font-bold">{{'DeletedPrepublication' | translate}}</span>
                }
                @case (versionStateType.Archive) {
                    <span>{{'ArchivedOn'| translate }}</span>&nbsp;<span class="font-bold">{{ item.ArchivedOn | utcDateTime }}</span>
                }
                @case (versionStateType.Draft) {
                    <span class="font-bold text-lighter">{{ 'DraftVersion' | translate }}</span>
                }
                @case (versionStateType.DraftDeleted) {
                    <span class="font-bold text-lighter">{{ 'DeletedDraft' | translate }}</span>
                }
            }
            @if (item.FutureArchiveDate) {
                <span class="text-red-500 inline-block ml-2">{{ 'WillBeArchivedOn' | translate }}</span>&nbsp;<span class="text-red-500 font-bold">{{ item.FutureArchiveDate | utcDateTime }}</span>
            }
        </div>
    </div>
    <!-- Actions -->
    <button type="button" class="mr-2 px-0 py-1 h-10 ml-auto hover:text-blue-600 focus:text-blue-600"
        id="actions-menu-icon-test"
        (click)="archiveContextMenuAnchorClick.emit({dataItem: fullItem, permissions: filePermissions, anchor: iconAnchor})"
        [hidden]="!permissions.showDropdownMenu"
        #menuIcon>
        <bizz-icon class="inline-block h-6 w-4 text-widget-button hover:cursor-pointer hover:text-blue-600 focus:text-blue-600" iconName="ellipsis-vertical"></bizz-icon>
    </button>
</div>