<div class="flex h-full flex-col gap-2">
    <div class="flex shrink-0 flex-col items-center justify-center gap-2 pt-7 sm:flex-row sm:justify-between">
        <!-- Tab Selector -->
        <div class="sm:py-auto flex w-full items-center first:rounded-r-none last:rounded-l-none sm:w-auto">
            @if (hasAdditionalMode(AdditionalModes.TRAINING_QUEUE)) {
                <button class="default h-9 w-full min-w-16 justify-center rounded-r-none sm:min-w-28"
                        [ngClass]="{'!bg-blue-500 !text-white' : activeView == OrgChartView.TRAINEES}" type="button"
                        (click)="selectView(OrgChartView.TRAINEES)">
                    {{ 'TrainingQueue' | translate }}
                </button>
            }
            <button class="default h-9 w-full min-w-16 justify-center rounded-r-none sm:min-w-28"
                    [class.rounded-none]="hasAnyAdditionalMode()"
                    [ngClass]="{'!bg-blue-500 !text-white' : activeView == OrgChartView.TREE}" type="button"
                    (click)="selectView(OrgChartView.TREE)">
                {{ 'OrgChart' | translate }}
            </button>
            <button class="default h-9 w-full min-w-16 justify-center rounded-none sm:min-w-28"
                    [ngClass]="{'!bg-blue-500 !text-white' : activeView == OrgChartView.USERS}" type="button"
                    (click)="selectView(OrgChartView.USERS)">
                {{ 'Users' | translate }}
            </button>
            <button class="default h-9 w-full min-w-16 justify-center rounded-l-none sm:min-w-28"
                    [ngClass]="{'!bg-blue-500 !text-white' : activeView == OrgChartView.ALL}" type="button"
                    (click)="selectView(OrgChartView.ALL)">
                {{ 'All' | translate }}
            </button>
        </div>
        <div class="flex w-full flex-col gap-2 sm:w-auto sm:flex-row">
            @if (activeView != OrgChartView.TREE) {
                @if (activeView == OrgChartView.ALL) {
                    <!-- Types  -->
                    <div class="button-group flex justify-evenly sm:justify-normal">
                        <button (click)="toggleFilterGroup(OrganizationChartItemType.User)">
                            <bizz-icon class="icon-button h-4 w-4"
                                       [title]="'User' | translate"
                                       [class.text-blue-500]="isFilterGroupSelected(OrganizationChartItemType.User)"
                                       [iconName]="ORG_CHART_ITEM_TYPE_ICONS[OrganizationChartItemType.User]"></bizz-icon>
                        </button>
                        <button (click)="toggleFilterGroup(OrganizationChartItemType.Function)">
                            <bizz-icon class="icon-button h-4 w-4"
                                       [title]="'Function' | translate"
                                       [class.text-blue-500]="isFilterGroupSelected(OrganizationChartItemType.Function)"
                                       [iconName]="ORG_CHART_ITEM_TYPE_ICONS[OrganizationChartItemType.Function]"
                            ></bizz-icon>
                        </button>
                        <button (click)="toggleFilterGroup(OrganizationChartItemType.Department)">
                            <bizz-icon class="icon-button h-4 w-4"
                                       [title]="'Department' | translate"
                                       [class.text-blue-500]="isFilterGroupSelected(OrganizationChartItemType.Department)"
                                       [iconName]="ORG_CHART_ITEM_TYPE_ICONS[OrganizationChartItemType.Department]"
                            ></bizz-icon>
                        </button>
                        <button (click)="toggleFilterGroup(OrganizationChartItemType.CurrentUser)">
                            <bizz-icon class="icon-button h-4 w-4"
                                       [title]="'CurrentUser' | translate"
                                       [class.text-blue-500]="isFilterGroupSelected(OrganizationChartItemType.CurrentUser)"
                                       [iconName]="ORG_CHART_ITEM_TYPE_ICONS[OrganizationChartItemType.CurrentUser]"
                            ></bizz-icon>

                        </button>
                    </div>
                }
            }
            <!-- Search -->
            <bizz-search [searchTerm]="search.Search" (searchTermChange)="searchTermChange($event)"></bizz-search>
        </div>
    </div>

    <div class="m-b-0">
        @if (minSelectedItems > 0 && maxSelectedItems > 0 && minSelectedItems == maxSelectedItems) {
            <p>{{ "PleaseSelect" | translate }} {{ minSelectedItems }} {{ "Item(s)" | translate }}</p>
        }
        @if (minSelectedItems == 0 || maxSelectedItems > 100000) {
            <p> {{ "PleaseSelectSomeItems" | translate }} </p>
        }
        @if (minSelectedItems > 0 && maxSelectedItems > 0 && minSelectedItems != maxSelectedItems && maxSelectedItems < 100000) {
            <p>{{ "PleaseSelectBetween" | translate }} {{ minSelectedItems }} {{ "And" | translate }} {{ maxSelectedItems }} {{ "Items" | translate }}</p>
        }
    </div>

    <hr>
    <bizz-org-chart-tree class="max-w-[912px] grow overflow-y-auto"
                         [selectedItems$]="selectedItems$"
                         [minSelectedItems]="minSelectedItems"
                         [maxSelectedItems]="maxSelectedItems"
                         [selectableSettings]="selectableSettings"
                         [allowedItemTypes]="allowedItemTypes"
                         [excludedUserIds]="excludedUserIds"
                         [class.hidden]="activeView !== OrgChartView.TREE"
                         [replaceDepartmentsAndFunctionsWithUsers]="hasAdditionalMode(AdditionalModes.TRAINING_QUEUE)"
    >

    </bizz-org-chart-tree>
    @switch (activeView) {
        @case (OrgChartView.TRAINEES) {
            <bizz-org-chart-trainee-list class="max-w-[912px] grow overflow-y-auto"
                                         [selectedItems$]="selectedItems$"
                                         [selectableSettings]="selectableSettings"
                                         [requestData]="getTrainingRequestData()"
                                         [minSelectedItems]="minSelectedItems"
                                         [maxSelectedItems]="maxSelectedItems"
                                         [allowedItemTypes]="allowedItemTypes"
            >
            </bizz-org-chart-trainee-list>
        }
        @case (OrgChartView.USERS) {
            <bizz-org-chart-list class="max-w-[912px] grow overflow-y-auto"
                                 [selectedItems$]="selectedItems$"
                                 [selectableSettings]="selectableSettings"
                                 [groups]="[OrganizationChartItemType.User]"
                                 [minSelectedItems]="minSelectedItems"
                                 [maxSelectedItems]="maxSelectedItems"
                                 [allowedItemTypes]="allowedItemTypes"
                                 [excludedUserIds]="excludedUserIds"
                                 [excludeExternalUsers]="excludeExternalUsers"
                                 [searchTerm]="search.Search!">
            </bizz-org-chart-list>
        }
        @case (OrgChartView.ALL) {
            <bizz-org-chart-list class="max-w-[912px] grow overflow-y-auto"
                                 [selectedItems$]="selectedItems$"
                                 [selectableSettings]="selectableSettings"
                                 [searchTerm]="search.Search!"
                                 [minSelectedItems]="minSelectedItems"
                                 [maxSelectedItems]="maxSelectedItems"
                                 [allowedItemTypes]="allowedItemTypes"
                                 [excludedUserIds]="excludedUserIds"
                                 [excludeExternalUsers]="excludeExternalUsers"
                                 [replaceDepartmentsAndFunctionsWithUsers]="hasAdditionalMode(AdditionalModes.TRAINING_QUEUE)"
                                 [groups]="filterGroups">
            </bizz-org-chart-list>
        }
    }

    <!-- Selected Items -->
    @if (selectedItems$ | async; as selectedItems) {
        <kendo-multiselect
                class="disable-searchbar !h-auto max-w-[912px] shrink-0 !rounded-r"
                textField="Name"
                valueField="Name"
                [value]="selectedItems"
                [valuePrimitive]="false"
                (valueChange)="valueChanged($event)"
                (open)="onOpen($event)" [filterable]="false">
            <ng-template kendoMultiSelectTagTemplate let-dataItem>
                    <span class="flex items-center gap-1">
                        @switch (dataItem.ObjectType) {
                            @case (OrganizationChartItemType.User) {
                                <bizz-icon class="h-3 w-3 text-blue-500" iconName="user"></bizz-icon>
                            }
                            @case (OrganizationChartItemType.Function) {
                                <bizz-icon class="h-3 w-3 text-blue-500" iconName="users"></bizz-icon>
                            }
                            @case (OrganizationChartItemType.Department) {
                                <bizz-icon class="h-3 w-3 text-blue-500" iconName="building"></bizz-icon>
                            }
                            @case (OrganizationChartItemType.CurrentUser) {
                                <bizz-icon class="h-3 w-3 text-blue-500" iconName="circle-user"></bizz-icon>
                            }
                        }
                        {{ dataItem.text }}
                    </span>
            </ng-template>
        </kendo-multiselect>

    }
</div>

