@if (assessmentForm) {
    <form class="bg-form shadow-md sm:rounded-b" [formGroup]="assessmentForm">
        <div class="h-1 w-full bg-blue-500 sm:h-2 sm:rounded-t">
        </div>
        <div class="mx-4 my-2 flex items-center gap-2 text-blue-500 sm:rounded-t">
            <bizz-icon class="h-4 w-4" iconName="file-check"></bizz-icon>
            <span class="text-lg font-bold">{{"Assessment" | translate}}</span>
        </div>
        <div class="mx-4 flex flex-col">
            <hr class="hidden sm:flex">
            <div class="my-4 flex flex-col gap-2 sm:flex-row sm:gap-0">
                <!-- Assessment Inputs  -->
                <div class="flex sm:w-[30%] sm:min-w-48">
                    <div class="flex flex-col gap-8 sm:mx-auto">
                        <label>{{"Assessment" | translate}}</label>
                        <div class="flex flex-wrap justify-between gap-1 sm:mx-4">
                            <div class="flex items-center justify-evenly gap-2">
                                <input id="approve" type="radio" kendoRadioButton [value]="AssessmentType.Approved"
                     [formControlName]="'AssessmentType'" />
                                <label class="cursor-pointer text-blue-500" for="approve">{{'Accept' | translate}}</label>
                            </div>
                            <div class="flex items-center justify-evenly gap-2">
                                <input id="reject" type="radio" kendoRadioButton [value]="AssessmentType.Rejected"
                     [formControlName]="'AssessmentType'"/>
                                <label class="cursor-pointer text-red-300" for="reject">{{'Reject' | translate}}</label>
                            </div>
                        </div>
                        @if (formData.Permissions['Manager']) {
                            <div class="flex items-center justify-center gap-2 sm:mx-4">
                                <input id="isveto" type="checkbox" kendoCheckBox [formControlName]="'IsVeto'" />
                                <label class="cursor-pointer text-sm font-semibold" for="isveto">{{"UseVetoToCloseStep" | translate}}</label>
                            </div>
                        }
                        <button class="mb-4 w-fit bg-blue-100 text-blue-600 hover:bg-blue-400 hover:text-white sm:mx-auto" type="button"
                  (click)="openAssessmentsModal()" [disabled]="formData.IsLocked">
                            <bizz-icon class="size-4" iconName="file-check"></bizz-icon>
                            {{'ViewAssessments' | translate}}
                        </button>
                    </div>
              </div>
                <!-- Remarks -->
                <div class="flex flex-col gap-2 sm:w-[70%]">
                    <label>
                        {{'Remarks' | translate}} @if(assessmentForm.get('AssessmentType')?.value == AssessmentType.Rejected) {<span class="ml-0.5 text-red-400">*</span>}
                    </label>
                    <kendo-textarea [rows]="4" [resizable]="'vertical'" [formControlName]="'Reason'">
                    </kendo-textarea>
    
                    @if(assessmentForm.get('CompareAiSummary')?.value) {
                    <div class="flex flex-col" (click)="toggleCompareAiSummary()">
                        <div class="flex cursor-pointer items-center gap-4 sm:gap-2">
                            <ng-container *ngTemplateOutlet="!showCompareAiSummary ? collapse : expand">
                            </ng-container>
                            <ng-template #collapse>
                                <bizz-icon [title]="'collapse' | translate" class="h-5 w-5 text-form-header sm:h-4 sm:w-4"
                                    [hover]="true" iconName="caret-right"></bizz-icon>
                            </ng-template>
                            <ng-template #expand>
                                <bizz-icon [title]="'expand' | translate" class="h-5 w-5 text-form-header sm:h-4 sm:w-4"
                                    [hover]="true" iconName="caret-down"></bizz-icon>
                            </ng-template>
                            <label class="hover:cursor-pointer">
                                {{'CompareAiSummary' | translate}}
                            </label>
                        </div>
                      
                    </div>
    
    
                   @if(showCompareAiSummary) {
                    <kendo-textarea [rows]="4" [disabled]="true" [readonly]="true" [resizable]="'none'"
                        [formControlName]="'CompareAiSummary'" >
                    </kendo-textarea>
    
                    <button class="mb-4 w-fit bg-blue-100 text-blue-600 hover:bg-blue-400 hover:text-white" type="button"
                    (click)="downloadComparisonFile()" [disabled]="formData.IsLocked">
                        <bizz-icon class="size-4" iconName="file-arrow-down"></bizz-icon>
                        {{'ComparisonFile' | translate}}
                    </button>
                   }
                }
    
                </div>
            </div>
        </div>
    </form>
    
    }