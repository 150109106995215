import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';

@Component({
  selector: 'bizz-form-accordion-header',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslatePipe],
  templateUrl: './form-accordion-header.component.html',
  styleUrls: ['./form-accordion-header.component.scss'],
})
export class FormAccordionHeaderComponent {
  @Input() isOpen = true;
  @Input() public canCollapse: boolean = true;
  @Input() public accentClass = 'bg-form-accent';
  @Output() public toggle: EventEmitter<boolean> = new EventEmitter<boolean>();


  public toggleAccordion(): void {
    this.isOpen = !this.isOpen;
    this.toggle.emit(this.isOpen);
  }
}
