import * as go from 'gojs';
import { FlowDiagram } from './flow-diagram';
import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

export class GroupResizingTool extends go.ResizingTool {


  private readonly minSwimlaneHeight: number = 0;
  private maxSwimlaneWidth: number = 0;

  public readonly onResize: EventEmitter<GroupResizeEvent> = new EventEmitter<GroupResizeEvent>();

  public constructor(minSwimlaneHeight: number, maxSwimlaneWidthObservable: Observable<number>) {
    super();
    this.minSwimlaneHeight = minSwimlaneHeight;
    maxSwimlaneWidthObservable.subscribe((maxSwimlaneWidth: number) => {
      this.maxSwimlaneWidth = maxSwimlaneWidth;
    });
  }

  private isLengthening(): boolean {
    return (this.handle != null && this.handle.alignment === go.Spot.Right);
  }

  public override computeMinSize(): go.Size {
    const msz = FlowDiagram.computeLaneSize(null, this.maxSwimlaneWidth, this.minSwimlaneHeight);  // g
    if (this.isLengthening()) {  // compute the minimum length of all lanes
      msz.width = FlowDiagram.getMaxWidthOfNodeInSwimlane(this.diagram.model.nodeDataArray);
    } else if (this.adornedObject != null && this.adornedObject.part != null) {  // find the minimum size of this single lane
      const laneSize = FlowDiagram.computeLaneSize(this.adornedObject.part, this.maxSwimlaneWidth, this.minSwimlaneHeight);
      msz.width = Math.max(msz.width, laneSize.width);
      msz.height = Math.max(msz.height, laneSize.height);
    }
    return msz;
  }

  public override resize(newRect: go.Rect): void {
    if (this.isLengthening()) {  // changing the length of all of the lanes
      for (const it = this.diagram.nodes; it.next();) {
        const group = it.value;
        if (!(group instanceof go.Group))
          continue;
        const shape = group.findObject('SHAPE');
        if (shape !== null) {  // set its desiredSize, but leave the other direction alone
          shape.desiredSize = new go.Size(newRect.width, shape.height);
        }
      }
    } else {  // changing the breadth of a single lane
      super.resize(newRect);
    }

    this.onResize.emit({ nodes: new go.List(this.diagram.nodes), newRect });
  }
}

export interface GroupResizeEvent {
  nodes: go.List<go.Node>,
  newRect: go.Rect
}
