import { Component, Input, OnInit } from '@angular/core';
import { IconDto } from 'src/models/ts/icon-dto.model';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'bizz-collection-button',
  standalone: true,
  templateUrl: './collection-button.component.html',
  styleUrl: './collection-button.component.scss',
  imports: [IconComponent]
})
export class CollectionButtonComponent implements OnInit {

  @Input({ required: true }) public icon: IconDto;
  @Input({ required: true }) public caption: string;


  //See ngBizzFormMacroButton.js
  ngOnInit(): void {  
  }
}
