import { Component, Inject, forwardRef } from '@angular/core';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { ModalComponent } from 'src/app/shared/components/modals/modal/modal.component';
import { TrackChangesParams } from '../../../track-changes/interfaces/track-changes-params.interface';
import { TrackChangesGridComponent } from '../../../track-changes/components/track-changes-grid/track-changes-grid.component';

@Component({
  selector: 'bizz-auditlog-track-changes',
  standalone: true,
  imports: [TranslatePipe, ModalComponent, forwardRef(() => TrackChangesGridComponent)],
  templateUrl: './auditlog-track-changes.component.html',
  styleUrl: './auditlog-track-changes.component.scss',

})
export class AuditlogTrackChangesComponent {
  public constructor(@Inject(DIALOG_DATA) public data: TrackChangesParams) {
  }
}
