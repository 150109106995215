import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FlowStatusDto } from '../../../../models/ts/flow-status-dto.model';
import { CollectionMethodType } from '../../../../models/ts/collection-method-type.model';
import { Observable } from 'rxjs';
import { FlowStatusTaskDto } from '../../../../models/ts/flow-status-task-dto.model';

@Injectable({
  providedIn: 'root',
})
export class CollectionFlowStatusApiService {

  public constructor(private http: HttpClient) {

  }

  /**
   * Get flow status for a collection instance.
   * @param collectionsId
   * @param instancesId
   * @param versionsId
   * @param methodType
   */
  public getFlowStatus(collectionsId: number, instancesId: number, versionsId: number, methodType: CollectionMethodType):
    Observable<FlowStatusDto> {
    return this.http.get<FlowStatusDto>
    (`api/flowstatus/collection/${collectionsId}/instance/${instancesId}/version/${versionsId}/methodtype/${methodType}`);
  }

  /**
   * Returns the flow status task details. which is also a list of flow status tasks.
   * @param taskID
   */
  public getFlowStatusTaskDetails(taskID: number): Observable<FlowStatusTaskDto[]> {
    return this.http.get<FlowStatusTaskDto[]>(`api/flowstatus/task/${taskID}/details`);
  }


  /**
   * Returns the flow status task assessment.
   * @param taskID
   */
  public getFlowStatusTaskAssessment(taskID: number): Observable<FlowStatusTaskDto[]> {
    return this.http.get<FlowStatusTaskDto[]>(`api/assessment/task/${taskID}`);
  }
}
