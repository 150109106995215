import { Component, Inject } from '@angular/core';
import {DatePipe} from "@angular/common";
import {IconComponent} from "../../../ui/icon/icon.component";
import {NotificationFilterPipe} from "../../../../pipes/filter/notification-filter.pipe";
import {SafePipe} from "safe-pipe";
import {TranslatePipe} from "../../../../pipes/translate/translate.pipe";
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { EditorModule } from '@progress/kendo-angular-editor';

@Component({
  selector: 'bizz-file-upload-modification-reason-modal',
  standalone: true,
  imports: [
    DatePipe,
    IconComponent,
    NotificationFilterPipe,
    SafePipe,
    TranslatePipe,
    EditorModule
  ],
  templateUrl: './file-upload-modification-reason-modal.component.html',
  styleUrl: './file-upload-modification-reason-modal.component.scss'
})

export class FileUploadModificationReasonModalComponent {
  public constructor(private dialogRef: DialogRef,@Inject(DIALOG_DATA) public data: {modificationReason: string}) {
  }
  public error: string | null = null

  public close() : void {
    this.error = null;
    if(this.data.modificationReason == null || this.data.modificationReason.trim() == "") {
      this.error = 'ModificationReasonIsRequired';
      return;
    }
    this.dialogRef.close(this.data.modificationReason);
  }
}
