@if(isReadOnly) {
    <div class="flex w-fit cursor-pointer flex-col rounded bg-card px-1.5 pb-0.5 pt-1.5">
        <svg #readOnlyIconComponent width="201" height="113" viewBox="0 0 201 113" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-auto w-44">
            <g id="Color=Light">
                <g clip-path="url(#clip0_2039_19300)">
                    <g id="NavBar">
                        <rect width="200" height="10.9001" transform="translate(0.842781 0.771484)"
                        fill="rgb(var(--header-bg))" />
                        <g id="NavbarText">
                            <rect x="6.27829" y="4.31836" width="16.3066" height="3.80484" rx="1.90242"
                            fill="rgb(var(--header-text))" />
                        </g>
                        <g id="Frame 44">
                            <ellipse id="NavbarItem" cx="159.47" cy="6.22244" rx="1.63066" ry="1.63064"
                                fill="rgb(var(--header-text))" />
                            <ellipse id="NavbarItem_2" cx="166.331" cy="6.22244" rx="1.63066" ry="1.63064"
                                fill="rgb(var(--header-text))" />
                            <ellipse id="NavbarItem_3" cx="173.193" cy="6.22244" rx="1.63066" ry="1.63064"
                                fill="rgb(var(--header-accent-text))" />
                            <ellipse id="NavbarItem_4" cx="180.054" cy="6.22244" rx="1.63066" ry="1.63064"
                                fill="rgb(var(--header-text))" />
                            <ellipse id="NavbarItem_5" cx="186.915" cy="6.22244" rx="1.63066" ry="1.63064"
                                fill="rgb(var(--header-text))" />
                            <ellipse id="NavbarItem_6" cx="193.777" cy="6.22244" rx="1.63066" ry="1.63064"
                                fill="rgb(var(--header-text))" />
                        </g>
                    </g>
                    <g id="Body" clip-path="url(#clip1_2039_19300)">
                        <g id="Sidebar">
                            <rect width="38.1197" height="101.1" transform="translate(0.842781 11.6719)"
                            fill="rgb(var(--sidebar-bg))" />
                            <g id="WorkspaceSelector">
                                <rect x="3.56053" y="17.1074" width="32.4" height="6.47994" rx="1.43999"
                                fill="rgb(var(--workspace-selector-bg))"  />
                                <g id="WorkspaceSelectorText">
                                    <ellipse id="WorkspaceSelectorIcon" cx="7.10658" cy="20.347"
                                        rx="1.38606" ry="1.38605" fill="rgb(var(--workspace-selector-text))" />
                                    <g id="WorkspaceSelectorText_2">
                                        <rect x="9.93263" y="19.2676" width="15.12" height="2.15998"
                                            rx="1.07999" fill="rgb(var(--workspace-selector-text))"  />
                                    </g>
                                </g>
                                <g id="WorkspaceSelectorTextGrid">
                                    <g clip-path="url(#clip2_2039_19300)">
                                        <path id="WorkspaceSelectorTextGridIcon"
                                            d="M31.7433 19.784C31.7433 19.9703 31.5705 20.1215 31.3576 20.1215H30.5861C30.3731 20.1215 30.2004 19.9703 30.2004 19.784V19.109C30.2004 18.9226 30.3731 18.7715 30.5861 18.7715H31.3576C31.5705 18.7715 31.7433 18.9226 31.7433 19.109V19.784ZM31.7433 21.584C31.7433 21.7703 31.5705 21.9215 31.3576 21.9215H30.5861C30.3731 21.9215 30.2004 21.7703 30.2004 21.584V20.909C30.2004 20.7226 30.3731 20.5715 30.5861 20.5715H31.3576C31.5705 20.5715 31.7433 20.7226 31.7433 20.909V21.584ZM32.2576 19.109C32.2576 18.9226 32.4303 18.7715 32.6433 18.7715H33.4147C33.6276 18.7715 33.8004 18.9226 33.8004 19.109V19.784C33.8004 19.9703 33.6276 20.1215 33.4147 20.1215H32.6433C32.4303 20.1215 32.2576 19.9703 32.2576 19.784V19.109ZM33.8004 21.584C33.8004 21.7703 33.6276 21.9215 33.4147 21.9215H32.6433C32.4303 21.9215 32.2576 21.7703 32.2576 21.584V20.909C32.2576 20.7226 32.4303 20.5715 32.6433 20.5715H33.4147C33.6276 20.5715 33.8004 20.7226 33.8004 20.909V21.584Z"
                                            fill="rgb(var(--workspace-selector-text))" />
                                    </g>
                                </g>
                            </g>
                            <g id="SidebarItem">
                                <ellipse id="SidebarIcon" cx="6.20672" cy="29.9505" rx="1.38606"
                                    ry="1.38605" fill="rgb(var(--sidebar-icon-text))" />
                                <g id="SidebarHeader">
                                    <rect x="9.48282" y="28.6914" width="22.933" height="2.52009"
                                        rx="1.26004" fill="rgb(var(--sidebar-header-text))"  />
                                </g>
                            </g>
                            <g id="SidebarItem_2">
                                <ellipse id="SidebarIcon_2" cx="6.20678" cy="38.3333" rx="1.38606"
                                    ry="1.38605" fill="rgb(var(--sidebar-icon-text))" />
                                <g id="SidebarHeader_2">
                                    <rect x="9.4828" y="37.0742" width="22.933" height="2.52009"
                                        rx="1.26004" fill="rgb(var(--sidebar-header-text))"  />
                                </g>
                            </g>
                            <g id="SidebarItem_3">
                                <ellipse id="SidebarIcon_3" cx="6.20676" cy="46.7122" rx="1.38606"
                                    ry="1.38605" fill="rgb(var(--sidebar-icon-text))" />
                                <g id="SidebarHeader_3">
                                    <rect x="9.48278" y="45.4531" width="18.6488" height="2.52009"
                                        rx="1.26004" fill="rgb(var(--sidebar-header-text))"  />
                                </g>
                            </g>
                            <g id="SidebarItem_4">
                                <ellipse id="SidebarIcon_4" cx="6.20673" cy="55.095" rx="1.38606"
                                    ry="1.38605" fill="rgb(var(--sidebar-icon-text))" />
                                <g id="SidebarHeader_4">
                                    <rect x="9.48284" y="53.834" width="22.933" height="2.52009"
                                        rx="1.26004" fill="rgb(var(--sidebar-header-text))"  />
                                </g>
                            </g>
                            <g id="SidebarItem_5">
                                <ellipse id="SidebarIcon_5" cx="6.20676" cy="63.472" rx="1.38606"
                                    ry="1.38605" fill="rgb(var(--sidebar-icon-text))" />
                                <g id="SidebarHeader_5">
                                    <rect x="9.4828" y="62.2109" width="18.6488" height="2.52009"
                                        rx="1.26004" fill="rgb(var(--sidebar-header-text))"  />
                                </g>
                            </g>
                        </g>
                        <g id="WorkspaceBackground">
                            <rect width="161.88" height="101.1" transform="translate(38.9625 11.6719)"
                            fill="rgb(var(--body-bg))" />
                            <g id="Dashboard">
                                <g id="Widget">
                                    <rect x="41.6803" y="14.3887" width="156.445" height="8.15322"
                                        rx="2.71775" fill="rgb(var(--widget-bg))"  />
                                    <g id="WidgetHeader">
                                        <rect x="44.6699" y="16.8362" width="21.1986" height="1.63064"
                                            rx="0.815321" fill="rgb(var(--widget-header-text))"  />
                                        <rect x="44.6699" y="16.8362" width="21.1986" height="1.63064"
                                            rx="0.815321" stroke="rgb(var(--widget-header-text))" stroke-width="0.54355" />
                                    </g>
                                </g>
                                <g id="WidgetContainer">
                                    <g id="Widget_2">
                                        <rect x="41.6803" y="27.9766" width="75.5046" height="38.3201"
                                            rx="2.71775" fill="rgb(var(--widget-bg))"  />
                                        <g id="WidgetHeader_2">
                                            <rect x="44.3982" y="30.1504" width="21.7421" height="2.17419"
                                                rx="1.0871" fill="rgb(var(--widget-header-text))" />
                                        </g>
                                        <g id="LineChart" filter="url(#filter0_d_2039_19300)">
                                            <path
                                                d="M108.814 35.3594C108.814 35.3594 101.047 51.4594 97.548 51.366C94.0491 51.2725 89.1495 44.3759 85.9738 44.3759C82.7981 44.3759 77.1986 59.1192 74.5191 59.1192C71.8395 59.1192 66.0317 38.1104 63.0107 38.1104C59.9896 38.1104 51.5324 51.9212 51.5324 51.9212"
                                                stroke="#139CD8" stroke-width="1.43999"
                                                stroke-linecap="round" />
                                        </g>
                                    </g>
                                    <g id="Widget_3">
                                        <rect x="122.62" y="27.9766" width="75.5046" height="38.3201"
                                            rx="2.71775" fill="rgb(var(--widget-bg))" />
                                        <g id="DonutChart" filter="url(#filter1_d_2039_19300)">
                                            <path id="Ellipse 7"
                                                d="M146.368 51.5319C145.76 48.3104 146.319 44.9775 147.946 42.1309C149.572 39.2843 152.159 37.1101 155.243 35.998L158.284 44.4317C157.205 44.8209 156.299 45.5819 155.73 46.5782C155.161 47.5745 154.965 48.741 155.178 49.8685L146.368 51.5319Z"
                                                fill="#F79760" />
                                            <path id="Ellipse 10"
                                                d="M152.146 60.3642C150.65 59.3431 149.37 58.0373 148.378 56.5215C147.387 55.0056 146.704 53.3093 146.368 51.5295L155.178 49.8673C155.295 50.4902 155.534 51.0839 155.881 51.6145C156.228 52.145 156.677 52.602 157.2 52.9594L152.146 60.3642Z"
                                                fill="#E3BD14" />
                                            <path id="Ellipse 9"
                                                d="M171.227 56.8735C169.131 59.8718 165.93 61.9148 162.328 62.5531C158.727 63.1915 155.019 62.3728 152.02 60.2773L157.156 52.929C158.206 53.6624 159.503 53.949 160.764 53.7255C162.025 53.5021 163.145 52.7871 163.878 51.7377L171.227 56.8735Z"
                                                fill="#14D9C5" />
                                            <path id="Ellipse 8"
                                                d="M155.198 36.0136C158.056 34.9722 161.177 34.9043 164.077 35.8205L161.376 44.3691C160.361 44.0485 159.269 44.0722 158.268 44.4367L155.198 36.0136Z"
                                                fill="#ED3F39" />
                                            <path id="Ellipse 6"
                                                d="M164.011 35.8008C166.859 36.6849 169.343 38.4679 171.092 40.8826C172.841 43.2973 173.76 46.2137 173.713 49.1949L164.748 49.0507C164.765 48.0073 164.443 46.9865 163.831 46.1414C163.219 45.2962 162.35 44.6722 161.353 44.3627L164.011 35.8008Z"
                                                fill="#139CD8" />
                                            <path id="Ellipse 12"
                                                d="M173.713 49.1607C173.675 51.9245 172.808 54.6132 171.224 56.8781L163.877 51.7393C164.432 50.9465 164.735 50.0055 164.749 49.0382L173.713 49.1607Z"
                                                fill="#43B7ED" />
                                        </g>
                                        <g id="WidgetHeader_3">
                                            <rect x="125.338" y="30.1504" width="21.7421" height="2.17419"
                                                rx="1.0871" fill="rgb(var(--widget-header-text))" />
                                        </g>
                                    </g>
                                </g>
                                <g id="WidgetContainer_2">
                                    <g id="Widget_4">
                                        <rect x="41.6803" y="71.7324" width="75.5046" height="38.3201"
                                            rx="2.71775" fill="rgb(var(--widget-bg))"  />
                                        <g id="Content">
                                            <g id="WidgetText">
                                                <g id="Frame 248">
                                                    <rect x="49.2351" y="82.5059" width="15.0455"
                                                        height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                                </g>
                                                <g id="Frame 249">
                                                    <rect x="49.2033" y="90.2461" width="13.0453"
                                                        height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                                </g>
                                                <g id="Frame 250">
                                                    <rect x="49.2351" y="97.9863" width="15.0455"
                                                        height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                                </g>
                                            </g>
                                            <g id="WidgetText_2">
                                                <g id="Frame 248_2">
                                                    <rect x="72.8589" y="82.5469" width="13.0453"
                                                        height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                                </g>
                                                <g id="Frame 249_2">
                                                    <rect x="72.8909" y="90.2871" width="10.0014"
                                                        height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                                </g>
                                                <g id="Frame 250_2">
                                                    <rect x="72.8589" y="98.0273" width="13.0453"
                                                        height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                                </g>
                                            </g>
                                            <g id="WidgetText_3">
                                                <g id="Frame 256">
                                                    <rect x="96.5457" y="82.5059" width="10.1753"
                                                        height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                                </g>
                                                <g id="Frame 257">
                                                    <rect x="96.5143" y="90.2461" width="13.0453"
                                                        height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                                </g>
                                                <g id="Frame 258">
                                                    <rect x="96.5457" y="97.9863" width="10.1753"
                                                        height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                                </g>
                                            </g>
                                        </g>
                                        <g id="WidgetHeader_4">
                                            <rect x="44.3982" y="73.9043" width="19.0244" height="2.17419"
                                                rx="1.0871" fill="rgb(var(--widget-header-text))" />
                                        </g>
                                    </g>
                                    <g id="Widget_5">
                                        <rect x="122.62" y="71.7324" width="75.5046" height="38.3201"
                                            rx="2.71775" fill="rgb(var(--widget-bg))"  />
                                        <g id="Bars">
                                            <g clip-path="url(#clip3_2039_19300)">
                                                <path id="Rectangle 53"
                                                    d="M174.222 106.332L174.222 85.9236C174.222 85.7875 174.333 85.6772 174.469 85.6772L177.237 85.6772C177.373 85.6772 177.484 85.7875 177.484 85.9236L177.484 106.332L174.222 106.332Z"
                                                    fill="rgb(var(--widget-text))" />
                                                <path id="Rectangle 42"
                                                    d="M138.891 109.051L138.891 88.6423C138.891 88.5063 139.002 88.396 139.138 88.396L141.363 88.396C141.499 88.396 141.609 88.5063 141.609 88.6423L141.609 109.051L138.891 109.051Z"
                                                    fill="rgb(var(--widget-text))" />
                                                <path id="Rectangle 57"
                                                    d="M179.114 111.225L179.114 90.8162C179.114 90.6801 179.225 90.5698 179.361 90.5698L181.586 90.5698C181.722 90.5698 181.832 90.6801 181.832 90.8162L181.832 111.225L179.114 111.225Z"
                                                    fill="rgb(var(--widget-text))" />
                                                <path id="Rectangle 51"
                                                    d="M162.808 116.66L162.808 96.2517C162.808 96.1156 162.918 96.0053 163.054 96.0053L165.279 96.0053C165.415 96.0053 165.525 96.1156 165.525 96.2517L165.525 116.66L162.808 116.66Z"
                                                    fill="rgb(var(--widget-text))" />
                                                <path id="Rectangle 44"
                                                    d="M150.306 106.877L150.306 93.5346C150.306 93.3986 150.416 93.2883 150.552 93.2883L153.321 93.2883C153.457 93.2883 153.567 93.3986 153.567 93.5346L153.567 106.877L150.306 106.877Z"
                                                    fill="rgb(var(--widget-text))" />
                                                <path id="Rectangle 47"
                                                    d="M168.243 107.42L168.243 97.8824C168.243 97.7464 168.353 97.6361 168.489 97.6361L170.714 97.6361C170.851 97.6361 170.961 97.7464 170.961 97.8824L170.961 107.42L168.243 107.42Z"
                                                    fill="rgb(var(--widget-text))" />
                                                <path id="Rectangle 60"
                                                    d="M144.87 114.486L144.87 95.165C144.87 95.0289 144.981 94.9186 145.117 94.9186L147.342 94.9186C147.478 94.9186 147.588 95.0289 147.588 95.165L147.588 114.486L144.87 114.486Z"
                                                    fill="rgb(var(--widget-text))" />
                                                <path id="Rectangle 62"
                                                    d="M157.372 115.574L157.372 95.1658C157.372 95.0297 157.482 94.9194 157.618 94.9194L159.843 94.9194C159.98 94.9194 160.09 95.0297 160.09 95.1658L160.09 115.574L157.372 115.574Z"
                                                    fill="rgb(var(--widget-text))" />
                                            </g>
                                            <rect x="136.174" y="80.2441" width="47.8327" height="26.0903"
                                                rx="1.63065" stroke="rgb(var(--widget-text))" />
                                        </g>
                                        <g id="WidgetHeader_5">
                                            <rect x="125.338" y="73.9043" width="19.0244" height="2.17419"
                                                rx="1.0871" fill="rgb(var(--widget-header-text))" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <defs>
                <filter id="filter0_d_2039_19300" x="47.9323" y="32.4787" width="64.4822" height="30.9611"
                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="0.719997" />
                    <feGaussianBlur stdDeviation="1.43999" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 0.847059 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2039_19300" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2039_19300"
                        result="shape" />
                </filter>
                <filter id="filter1_d_2039_19300" x="144.689" y="34.4597" width="30.4654" height="30.4659"
                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="0.719997" />
                    <feGaussianBlur stdDeviation="0.719997" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2039_19300" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2039_19300"
                        result="shape" />
                </filter>
                <clipPath id="clip0_2039_19300">
                    <rect x="0.842781" y="0.771484" width="200" height="112" rx="2.71775" fill="white" />
                </clipPath>
                <clipPath id="clip1_2039_19300">
                    <rect width="200" height="101.1" fill="white" transform="translate(0.842781 11.6719)" />
                </clipPath>
                <clipPath id="clip2_2039_19300">
                    <rect x="30.2005" y="18.5469" width="3.6" height="3.59997" fill="white" />
                </clipPath>
                <clipPath id="clip3_2039_19300">
                    <rect x="136.174" y="80.2441" width="47.8327" height="26.0903" rx="1.63065" fill="white" />
                </clipPath>
            </defs>
        </svg>
        <p class="my-1.5 ml-0.5 text-left font-sans text-xs font-semibold text-button">{{ internalTheme.Name }}</p>
    </div>
} @else {
    <div class="group relative flex w-fit cursor-pointer flex-col rounded bg-section px-1.5 pb-0.5 pt-1.5 hover:border-blue-400 hover:shadow-md hover:shadow-blue-600/50 data-[clicked=true]:border-blue-500 data-[clicked=true]:shadow-blue-500/50"
    (click)="onClick()" [attr.data-clicked]="isSelected">
    <svg #iconComponent width="201" height="113" viewBox="0 0 201 113" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-auto w-44">
        <g id="Color=Light">
            <g clip-path="url(#clip0_2039_19300)">
                <g id="NavBar">
                    <rect width="200" height="10.9001" transform="translate(0.842781 0.771484)"
                    fill="rgb(var(--header-bg))" />
                    <g id="NavbarText">
                        <rect x="6.27829" y="4.31836" width="16.3066" height="3.80484" rx="1.90242"
                        fill="rgb(var(--header-text))" />
                    </g>
                    <g id="Frame 44">
                        <ellipse id="NavbarItem" cx="159.47" cy="6.22244" rx="1.63066" ry="1.63064"
                            fill="rgb(var(--header-text))" />
                        <ellipse id="NavbarItem_2" cx="166.331" cy="6.22244" rx="1.63066" ry="1.63064"
                            fill="rgb(var(--header-text))" />
                        <ellipse id="NavbarItem_3" cx="173.193" cy="6.22244" rx="1.63066" ry="1.63064"
                            fill="rgb(var(--header-accent-text))" />
                        <ellipse id="NavbarItem_4" cx="180.054" cy="6.22244" rx="1.63066" ry="1.63064"
                            fill="rgb(var(--header-text))" />
                        <ellipse id="NavbarItem_5" cx="186.915" cy="6.22244" rx="1.63066" ry="1.63064"
                            fill="rgb(var(--header-text))" />
                        <ellipse id="NavbarItem_6" cx="193.777" cy="6.22244" rx="1.63066" ry="1.63064"
                            fill="rgb(var(--header-text))" />
                    </g>
                </g>
                <g id="Body" clip-path="url(#clip1_2039_19300)">
                    <g id="Sidebar">
                        <rect width="38.1197" height="101.1" transform="translate(0.842781 11.6719)"
                        fill="rgb(var(--sidebar-bg))" />
                        <g id="WorkspaceSelector">
                            <rect x="3.56053" y="17.1074" width="32.4" height="6.47994" rx="1.43999"
                            fill="rgb(var(--workspace-selector-bg))"  />
                            <g id="WorkspaceSelectorText">
                                <ellipse id="WorkspaceSelectorIcon" cx="7.10658" cy="20.347"
                                    rx="1.38606" ry="1.38605" fill="rgb(var(--workspace-selector-text))" />
                                <g id="WorkspaceSelectorText_2">
                                    <rect x="9.93263" y="19.2676" width="15.12" height="2.15998"
                                        rx="1.07999" fill="rgb(var(--workspace-selector-text))"  />
                                </g>
                            </g>
                            <g id="WorkspaceSelectorTextGrid">
                                <g clip-path="url(#clip2_2039_19300)">
                                    <path id="WorkspaceSelectorTextGridIcon"
                                        d="M31.7433 19.784C31.7433 19.9703 31.5705 20.1215 31.3576 20.1215H30.5861C30.3731 20.1215 30.2004 19.9703 30.2004 19.784V19.109C30.2004 18.9226 30.3731 18.7715 30.5861 18.7715H31.3576C31.5705 18.7715 31.7433 18.9226 31.7433 19.109V19.784ZM31.7433 21.584C31.7433 21.7703 31.5705 21.9215 31.3576 21.9215H30.5861C30.3731 21.9215 30.2004 21.7703 30.2004 21.584V20.909C30.2004 20.7226 30.3731 20.5715 30.5861 20.5715H31.3576C31.5705 20.5715 31.7433 20.7226 31.7433 20.909V21.584ZM32.2576 19.109C32.2576 18.9226 32.4303 18.7715 32.6433 18.7715H33.4147C33.6276 18.7715 33.8004 18.9226 33.8004 19.109V19.784C33.8004 19.9703 33.6276 20.1215 33.4147 20.1215H32.6433C32.4303 20.1215 32.2576 19.9703 32.2576 19.784V19.109ZM33.8004 21.584C33.8004 21.7703 33.6276 21.9215 33.4147 21.9215H32.6433C32.4303 21.9215 32.2576 21.7703 32.2576 21.584V20.909C32.2576 20.7226 32.4303 20.5715 32.6433 20.5715H33.4147C33.6276 20.5715 33.8004 20.7226 33.8004 20.909V21.584Z"
                                        fill="rgb(var(--workspace-selector-text))" />
                                </g>
                            </g>
                        </g>
                        <g id="SidebarItem">
                            <ellipse id="SidebarIcon" cx="6.20672" cy="29.9505" rx="1.38606"
                                ry="1.38605" fill="rgb(var(--sidebar-icon-text))" />
                            <g id="SidebarHeader">
                                <rect x="9.48282" y="28.6914" width="22.933" height="2.52009"
                                    rx="1.26004" fill="rgb(var(--sidebar-header-text))"  />
                            </g>
                        </g>
                        <g id="SidebarItem_2">
                            <ellipse id="SidebarIcon_2" cx="6.20678" cy="38.3333" rx="1.38606"
                                ry="1.38605" fill="rgb(var(--sidebar-icon-text))" />
                            <g id="SidebarHeader_2">
                                <rect x="9.4828" y="37.0742" width="22.933" height="2.52009"
                                    rx="1.26004" fill="rgb(var(--sidebar-header-text))"  />
                            </g>
                        </g>
                        <g id="SidebarItem_3">
                            <ellipse id="SidebarIcon_3" cx="6.20676" cy="46.7122" rx="1.38606"
                                ry="1.38605" fill="rgb(var(--sidebar-icon-text))" />
                            <g id="SidebarHeader_3">
                                <rect x="9.48278" y="45.4531" width="18.6488" height="2.52009"
                                    rx="1.26004" fill="rgb(var(--sidebar-header-text))"  />
                            </g>
                        </g>
                        <g id="SidebarItem_4">
                            <ellipse id="SidebarIcon_4" cx="6.20673" cy="55.095" rx="1.38606"
                                ry="1.38605" fill="rgb(var(--sidebar-icon-text))" />
                            <g id="SidebarHeader_4">
                                <rect x="9.48284" y="53.834" width="22.933" height="2.52009"
                                    rx="1.26004" fill="rgb(var(--sidebar-header-text))"  />
                            </g>
                        </g>
                        <g id="SidebarItem_5">
                            <ellipse id="SidebarIcon_5" cx="6.20676" cy="63.472" rx="1.38606"
                                ry="1.38605" fill="rgb(var(--sidebar-icon-text))" />
                            <g id="SidebarHeader_5">
                                <rect x="9.4828" y="62.2109" width="18.6488" height="2.52009"
                                    rx="1.26004" fill="rgb(var(--sidebar-header-text))"  />
                            </g>
                        </g>
                    </g>
                    <g id="WorkspaceBackground">
                        <rect width="161.88" height="101.1" transform="translate(38.9625 11.6719)"
                        fill="rgb(var(--body-bg))" />
                        <g id="Dashboard">
                            <g id="Widget">
                                <rect x="41.6803" y="14.3887" width="156.445" height="8.15322"
                                    rx="2.71775" fill="rgb(var(--widget-bg))"  />
                                <g id="WidgetHeader">
                                    <rect x="44.6699" y="16.8362" width="21.1986" height="1.63064"
                                        rx="0.815321" fill="rgb(var(--widget-header-text))"  />
                                    <rect x="44.6699" y="16.8362" width="21.1986" height="1.63064"
                                        rx="0.815321" stroke="rgb(var(--widget-header-text))" stroke-width="0.54355" />
                                </g>
                            </g>
                            <g id="WidgetContainer">
                                <g id="Widget_2">
                                    <rect x="41.6803" y="27.9766" width="75.5046" height="38.3201"
                                        rx="2.71775" fill="rgb(var(--widget-bg))"  />
                                    <g id="WidgetHeader_2">
                                        <rect x="44.3982" y="30.1504" width="21.7421" height="2.17419"
                                            rx="1.0871" fill="rgb(var(--widget-header-text))" />
                                    </g>
                                    <g id="LineChart" filter="url(#filter0_d_2039_19300)">
                                        <path
                                            d="M108.814 35.3594C108.814 35.3594 101.047 51.4594 97.548 51.366C94.0491 51.2725 89.1495 44.3759 85.9738 44.3759C82.7981 44.3759 77.1986 59.1192 74.5191 59.1192C71.8395 59.1192 66.0317 38.1104 63.0107 38.1104C59.9896 38.1104 51.5324 51.9212 51.5324 51.9212"
                                            stroke="#139CD8" stroke-width="1.43999"
                                            stroke-linecap="round" />
                                    </g>
                                </g>
                                <g id="Widget_3">
                                    <rect x="122.62" y="27.9766" width="75.5046" height="38.3201"
                                        rx="2.71775" fill="rgb(var(--widget-bg))" />
                                    <g id="DonutChart" filter="url(#filter1_d_2039_19300)">
                                        <path id="Ellipse 7"
                                            d="M146.368 51.5319C145.76 48.3104 146.319 44.9775 147.946 42.1309C149.572 39.2843 152.159 37.1101 155.243 35.998L158.284 44.4317C157.205 44.8209 156.299 45.5819 155.73 46.5782C155.161 47.5745 154.965 48.741 155.178 49.8685L146.368 51.5319Z"
                                            fill="#F79760" />
                                        <path id="Ellipse 10"
                                            d="M152.146 60.3642C150.65 59.3431 149.37 58.0373 148.378 56.5215C147.387 55.0056 146.704 53.3093 146.368 51.5295L155.178 49.8673C155.295 50.4902 155.534 51.0839 155.881 51.6145C156.228 52.145 156.677 52.602 157.2 52.9594L152.146 60.3642Z"
                                            fill="#E3BD14" />
                                        <path id="Ellipse 9"
                                            d="M171.227 56.8735C169.131 59.8718 165.93 61.9148 162.328 62.5531C158.727 63.1915 155.019 62.3728 152.02 60.2773L157.156 52.929C158.206 53.6624 159.503 53.949 160.764 53.7255C162.025 53.5021 163.145 52.7871 163.878 51.7377L171.227 56.8735Z"
                                            fill="#14D9C5" />
                                        <path id="Ellipse 8"
                                            d="M155.198 36.0136C158.056 34.9722 161.177 34.9043 164.077 35.8205L161.376 44.3691C160.361 44.0485 159.269 44.0722 158.268 44.4367L155.198 36.0136Z"
                                            fill="#ED3F39" />
                                        <path id="Ellipse 6"
                                            d="M164.011 35.8008C166.859 36.6849 169.343 38.4679 171.092 40.8826C172.841 43.2973 173.76 46.2137 173.713 49.1949L164.748 49.0507C164.765 48.0073 164.443 46.9865 163.831 46.1414C163.219 45.2962 162.35 44.6722 161.353 44.3627L164.011 35.8008Z"
                                            fill="#139CD8" />
                                        <path id="Ellipse 12"
                                            d="M173.713 49.1607C173.675 51.9245 172.808 54.6132 171.224 56.8781L163.877 51.7393C164.432 50.9465 164.735 50.0055 164.749 49.0382L173.713 49.1607Z"
                                            fill="#43B7ED" />
                                    </g>
                                    <g id="WidgetHeader_3">
                                        <rect x="125.338" y="30.1504" width="21.7421" height="2.17419"
                                            rx="1.0871" fill="rgb(var(--widget-header-text))" />
                                    </g>
                                </g>
                            </g>
                            <g id="WidgetContainer_2">
                                <g id="Widget_4">
                                    <rect x="41.6803" y="71.7324" width="75.5046" height="38.3201"
                                        rx="2.71775" fill="rgb(var(--widget-bg))"  />
                                    <g id="Content">
                                        <g id="WidgetText">
                                            <g id="Frame 248">
                                                <rect x="49.2351" y="82.5059" width="15.0455"
                                                    height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                            </g>
                                            <g id="Frame 249">
                                                <rect x="49.2033" y="90.2461" width="13.0453"
                                                    height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                            </g>
                                            <g id="Frame 250">
                                                <rect x="49.2351" y="97.9863" width="15.0455"
                                                    height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                            </g>
                                        </g>
                                        <g id="WidgetText_2">
                                            <g id="Frame 248_2">
                                                <rect x="72.8589" y="82.5469" width="13.0453"
                                                    height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                            </g>
                                            <g id="Frame 249_2">
                                                <rect x="72.8909" y="90.2871" width="10.0014"
                                                    height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                            </g>
                                            <g id="Frame 250_2">
                                                <rect x="72.8589" y="98.0273" width="13.0453"
                                                    height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                            </g>
                                        </g>
                                        <g id="WidgetText_3">
                                            <g id="Frame 256">
                                                <rect x="96.5457" y="82.5059" width="10.1753"
                                                    height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                            </g>
                                            <g id="Frame 257">
                                                <rect x="96.5143" y="90.2461" width="13.0453"
                                                    height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                            </g>
                                            <g id="Frame 258">
                                                <rect x="96.5457" y="97.9863" width="10.1753"
                                                    height="1.73935" rx="0.869677" fill="rgb(var(--widget-text))" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="WidgetHeader_4">
                                        <rect x="44.3982" y="73.9043" width="19.0244" height="2.17419"
                                            rx="1.0871" fill="rgb(var(--widget-header-text))" />
                                    </g>
                                </g>
                                <g id="Widget_5">
                                    <rect x="122.62" y="71.7324" width="75.5046" height="38.3201"
                                        rx="2.71775" fill="rgb(var(--widget-bg))"  />
                                    <g id="Bars">
                                        <g clip-path="url(#clip3_2039_19300)">
                                            <path id="Rectangle 53"
                                                d="M174.222 106.332L174.222 85.9236C174.222 85.7875 174.333 85.6772 174.469 85.6772L177.237 85.6772C177.373 85.6772 177.484 85.7875 177.484 85.9236L177.484 106.332L174.222 106.332Z"
                                                fill="rgb(var(--widget-text))" />
                                            <path id="Rectangle 42"
                                                d="M138.891 109.051L138.891 88.6423C138.891 88.5063 139.002 88.396 139.138 88.396L141.363 88.396C141.499 88.396 141.609 88.5063 141.609 88.6423L141.609 109.051L138.891 109.051Z"
                                                fill="rgb(var(--widget-text))" />
                                            <path id="Rectangle 57"
                                                d="M179.114 111.225L179.114 90.8162C179.114 90.6801 179.225 90.5698 179.361 90.5698L181.586 90.5698C181.722 90.5698 181.832 90.6801 181.832 90.8162L181.832 111.225L179.114 111.225Z"
                                                fill="rgb(var(--widget-text))" />
                                            <path id="Rectangle 51"
                                                d="M162.808 116.66L162.808 96.2517C162.808 96.1156 162.918 96.0053 163.054 96.0053L165.279 96.0053C165.415 96.0053 165.525 96.1156 165.525 96.2517L165.525 116.66L162.808 116.66Z"
                                                fill="rgb(var(--widget-text))" />
                                            <path id="Rectangle 44"
                                                d="M150.306 106.877L150.306 93.5346C150.306 93.3986 150.416 93.2883 150.552 93.2883L153.321 93.2883C153.457 93.2883 153.567 93.3986 153.567 93.5346L153.567 106.877L150.306 106.877Z"
                                                fill="rgb(var(--widget-text))" />
                                            <path id="Rectangle 47"
                                                d="M168.243 107.42L168.243 97.8824C168.243 97.7464 168.353 97.6361 168.489 97.6361L170.714 97.6361C170.851 97.6361 170.961 97.7464 170.961 97.8824L170.961 107.42L168.243 107.42Z"
                                                fill="rgb(var(--widget-text))" />
                                            <path id="Rectangle 60"
                                                d="M144.87 114.486L144.87 95.165C144.87 95.0289 144.981 94.9186 145.117 94.9186L147.342 94.9186C147.478 94.9186 147.588 95.0289 147.588 95.165L147.588 114.486L144.87 114.486Z"
                                                fill="rgb(var(--widget-text))" />
                                            <path id="Rectangle 62"
                                                d="M157.372 115.574L157.372 95.1658C157.372 95.0297 157.482 94.9194 157.618 94.9194L159.843 94.9194C159.98 94.9194 160.09 95.0297 160.09 95.1658L160.09 115.574L157.372 115.574Z"
                                                fill="rgb(var(--widget-text))" />
                                        </g>
                                        <rect x="136.174" y="80.2441" width="47.8327" height="26.0903"
                                            rx="1.63065" stroke="rgb(var(--widget-text))" />
                                    </g>
                                    <g id="WidgetHeader_5">
                                        <rect x="125.338" y="73.9043" width="19.0244" height="2.17419"
                                            rx="1.0871" fill="rgb(var(--widget-header-text))" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
        <defs>
            <filter id="filter0_d_2039_19300" x="47.9323" y="32.4787" width="64.4822" height="30.9611"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="0.719997" />
                <feGaussianBlur stdDeviation="1.43999" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 0.847059 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2039_19300" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2039_19300"
                    result="shape" />
            </filter>
            <filter id="filter1_d_2039_19300" x="144.689" y="34.4597" width="30.4654" height="30.4659"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="0.719997" />
                <feGaussianBlur stdDeviation="0.719997" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2039_19300" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2039_19300"
                    result="shape" />
            </filter>
            <clipPath id="clip0_2039_19300">
                <rect x="0.842781" y="0.771484" width="200" height="112" rx="2.71775" fill="white" />
            </clipPath>
            <clipPath id="clip1_2039_19300">
                <rect width="200" height="101.1" fill="white" transform="translate(0.842781 11.6719)" />
            </clipPath>
            <clipPath id="clip2_2039_19300">
                <rect x="30.2005" y="18.5469" width="3.6" height="3.59997" fill="white" />
            </clipPath>
            <clipPath id="clip3_2039_19300">
                <rect x="136.174" y="80.2441" width="47.8327" height="26.0903" rx="1.63065" fill="white" />
            </clipPath>
        </defs>
    </svg>
        <bizz-icon class="absolute bottom-1.5 right-1.5 size-4 text-blue-600 shadow-blue-600/20" iconName="circle-check"
        [ngClass]="{ '!hidden' : !isSelected }"></bizz-icon>
    <p class="my-1.5 ml-0.5 font-sans text-xs font-semibold text-button group-hover:text-blue-400" [ngClass]="{'text-blue-500': isSelected, 'text-button': !isSelected }">{{ internalTheme.Name }}</p>
</div>
}
