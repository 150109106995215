import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormControl, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { ControlOptions } from '../../interfaces/control-options.interface';
import { IconComponent } from '../../../ui/icon/icon.component';

/**
 * Represents a control that allows the user to enter alphanumeric characters.
 */
@Component({
  selector: 'bizz-shared-alpha-numeric-control',
  imports: [CommonModule, ReactiveFormsModule, IconComponent],
  templateUrl: './alpha-numeric-control.component.html',
  styleUrls: ['./alpha-numeric-control.component.scss'],
  standalone: true,
  viewProviders: [
    {
        provide: ControlContainer,
        useExisting: FormGroupDirective
    }
]
})
export class SharedAlphaNumericControlComponent {

  @Input() control: FormControl;
  @Input() options: ControlOptions;
}

export interface AlphanumericOptions extends ControlOptions {
  
}