<ng-container [ngSwitch]="element.FormFieldType">
  <!-- TODO: split up into components -->
  <ng-template [ngSwitchCase]="FormFieldType.TitleBar">
    <div class="border-form-accent mb-3.5 border-b-2 pb-2 pt-3 font-title text-xl font-semibold">
      {{element.Caption}}
    </div>
  </ng-template>
  <ng-template [ngSwitchCase]="FormFieldType.Text">
    <div class="font-sans text-xs" [innerHTML]="element.Caption | safe: 'html'">
    </div>
  </ng-template>

    <ng-template [ngSwitchCase]="FormFieldType.MailingList">
        <bizz-form-mailing-list [element]="element" [formId]="formId"></bizz-form-mailing-list>
    </ng-template>
</ng-container>
