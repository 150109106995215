import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { IconComponent } from '../../../ui/icon/icon.component';
import { UIMonitorTask } from '../monitor-step/monitor-step.component';
import { MonitorStepReasonComponent } from '../classes/monitor-step-reason.component';

@Component({
  selector: 'bizz-monitor-step-forward',
  standalone: true,
  imports: [
    NgClass,
    TranslatePipe,
    IconComponent
  ],
  templateUrl: './monitor-step-forward.component.html',
  styleUrl: './monitor-step-forward.component.scss'
})
export class MonitorStepForwardComponent extends MonitorStepReasonComponent {
  @Input({required: true}) task: UIMonitorTask;
  protected getTextElementName(): string {
    return (this.task.ForwardedReason == '' ? 'remark-' : 'forward-') + this.task.TasksID;
  }
}
