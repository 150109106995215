import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserSettingsApiService } from '../user-settings-api.service';
import { UserSettingsDto } from '../../../../../models/ts/user-settings-dto.model';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsResolver  {

  constructor(private userSettingsApiService: UserSettingsApiService) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserSettingsDto> {
    return this.userSettingsApiService.getUserSettings();
  }
}
