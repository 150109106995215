<div class="flex h-full flex-col gap-4 bg-body p-4">
    <div class="flex h-full flex-col rounded-lg bg-widget p-2">
        @if (searchedTaskDto != undefined) {
            <div class="flex shrink-0 flex-wrap items-center justify-between gap-1 pb-1">
                <div class="my-1 truncate font-title text-base font-bold text-widget-header sm:my-2">
                    {{ "Tasks" | translate }}
                </div>
                <div class="flex flex-grow items-center justify-end gap-2 py-2 sm:w-96">
                    <bizz-filter class="w-full"
                                 [filterType]="filterItemType.Tasks"
                                 [active]="activeFilter"
                                 [dropdownAccessible]="false"
                                 [searchFieldAccessible]="true"
                                 [taskStatusAccessible]="true"
                                 [searchedTaskDto]="searchedTaskDto.TaskStateFilterTypes"
                                 (retrievedFilterData)="fetchDataByFilter($event)"
                                 (retrievedTaskStatus)="onTaskFilterStateTypeChanged($event)"
                    >
                    </bizz-filter>
                </div>
            </div>
        }

        @if (gridOptions !== undefined && tasks !== undefined) {
            <bizz-grid
            class="max-h-[calc(100%_-_56px)]"
            [currentPage]="currentPage"
                       [totalItems]="totalRecords"
                       [gridOptions]="gridOptions"
                       [loading]="isLoading"
                       [gridData]="tasks.Data"
                       (cellAction)="onCellAction($event)"
                       (pageChange)="onPageChange($event)"
                       (groupChange)="onGroupChange($event)"
                       (sortChange)="onSortChange($event)">
            </bizz-grid>
        }
    </div>
</div>