<div class="h-6 w-6 sm:h-5" #anchor (click)="togglePopup()">
  <bizz-icon class="header-popup-icon h-6 w-5 sm:h-5" [hover]="true"
             iconName="user"></bizz-icon>
</div>
@if(showPopup){
<kendo-popup [anchor]="anchor"
             [animate]="true"
             [popupClass]="'flex w-screen sm:w-auto h-auto sm:justify-center sm:rounded'"
             class="z-[1004] mt-12 sm:mt-0 sm:rounded maxw-sm:!top-0"
             [positionMode]="'absolute'"
             [anchorAlign]="anchorAlign"
             [popupAlign]="popupAlign" #popup>
  <div class="bizz-popup-content">
    <div class="flex flex-col items-start font-semibold text-default">
        <span>{{'Hi' | translate}} {{userSettings.UserFirstName}}</span>      
        <hr class="my-0.5">
    </div>
    <button (click)="openHelpCenter()" class="sm:hidden">
      <bizz-icon [title]="'HelpCenter' | translate"
                 class="mr-2 size-6 sm:size-4" [hover]="true"
                 iconName="circle-question">
      </bizz-icon>
      {{'HelpCenter' | translate}}
    </button>
    <button (click)="openSupport()" class="sm:hidden">
      <bizz-icon [title]="'GetSupport' | translate"
                 class="mr-2 size-6 sm:size-4" [hover]="true"
                 iconName="comments-question">
      </bizz-icon>
      {{'GetSupport' | translate}}
    </button>
    <button [routerLink]="['./user-settings']" (click)="togglePopup(false)">
      <bizz-icon [title]="'UserSettings' | translate"
                 class="mr-2 size-6 sm:size-4" [hover]="true"
                 iconName="user-gear">
      </bizz-icon>
      {{'UserSettings' | translate }}
    </button>
    <button class="sm:hidden" [routerLink]="['/settings']" (click)="togglePopup(false)">
      <bizz-icon [title]="'Settings' | translate"
                 class="mr-2 size-6 sm:size-4" [hover]="true"
                 iconName="gear">
      </bizz-icon>
      {{'Settings' | translate}}
    </button>
    <button (click)="logout()">
      <bizz-icon [title]="'Logout' | translate"
                 class="mr-2 size-6 sm:size-4" [hover]="true"
                 iconName="right-from-bracket">
      </bizz-icon>
      {{'Logout' | translate }}
    </button>
    <div *ngIf="!environment.production" class="ml-2 flex items-center p-3 text-xs font-normal text-default sm:hidden">
      <bizz-icon [title]="'Version' | translate"
                 class="mr-4 h-4 w-4" [hover]="true"
                 iconName="circle-info" (click)="pwaService.checkForUpdate(); togglePopup(false)">
      </bizz-icon>
      {{ environment.appVersion}}
    </div>
  </div>

</kendo-popup>
}
