import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrganizationChartIndexDto } from '../../../../models/ts/organization-chart-index-dto.model';
import { GridOrganizationGroupSearchDto } from '../../../../models/ts/grid-organization-group-search-dto.model';
import { ExecutorDataDto } from '../../../../models/ts/executor-data-dto.model';
import { OrgChartItem } from '../../../features/bizzmine/org-chart/interfaces/org-chart-item';
import { OrganizationChartItemType } from '../../../../models/ts/organization-chart-item-type.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationChartApiService {

  public constructor(private http: HttpClient) {
  }

  public getOrgChartTreeRootNode(): Observable<OrgChartItem> {
    return this.http.get<OrgChartItem>('api/organization-chart/rootnode');
  }

  public getOrgChartTreeRootNodeById(versionId: number, itemType: OrganizationChartItemType): Observable<OrgChartItem> {
    return this.http.get<OrgChartItem>(`api/organization-chart/rootnode/versionId/${versionId}/type/${itemType}`);
  }

  public getOrgChartTreeNodeChildren(versionId: number, itemType: OrganizationChartItemType): Observable<Array<OrgChartItem>> {
    return this.http.get<Array<OrgChartItem>>(`api/organization-chart/node/versionId/${versionId}/type/${itemType}`);
  }

  public getOrgChartList(search: Partial<GridOrganizationGroupSearchDto>): Observable<OrganizationChartIndexDto> {
    return this.http.post<OrganizationChartIndexDto>(`api/organization-chart/list`, search);
  }

  public searchOrgChart(search: string, groups: Array<OrganizationChartItemType>, excludedUserIds: Array<number>): Observable<Array<OrgChartItem>> {
    return this.http.request<Array<OrgChartItem>>('get', `api/organization-chart`, { params: { search, groups, excludedUserIds } });
  }

  public getExecutorData(collectionId: number): Observable<ExecutorDataDto> {
    return this.http.get<ExecutorDataDto>(`settings/api/collection/${collectionId}/getexecutordata`);
  }

  public searchOrgChartByFilter(intent: GridOrganizationGroupSearchDto): Observable<OrganizationChartIndexDto> {
    return this.http.post<OrganizationChartIndexDto>(`api/OrganizationChart/PostOrgChartGridSearch`, intent);
  }

  public getOrgChartCurrentuser(): Observable<OrgChartItem> {
    return this.http.get<OrgChartItem>(`api/orgchart/currentuser`);
  }

  public getUsersForFunctionDepartment(ids: string[]): Observable<OrgChartItem[]> {
    return this.http.post<OrgChartItem[]>('api/OrganizationChart/GetUsersFromFunctionsAndDepartments', ids);
  }
}
