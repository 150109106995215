import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { CollectionFormService } from './collection-form.service';
import { CollectionForm } from '../../../../../models/ts/collection-form.model';
import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';
import { TableFieldDataType } from '../../../../../models/ts/table-field-data-type.model';
import { FormFieldType } from 'src/models/ts/form-field-type.model';
import { nanValidator } from '../../../../shared/validators/nan-validator';

/**
 * Provides utility functions for FormGroups based off of CollectionForm
 */
@Injectable({
  providedIn: 'root'
})
export class CollectionFormGroupService {

  public constructor(private fb: FormBuilder) {
  }

  public getFormGroupFromData(form: CollectionForm): FormGroup {
    return this.getFormGroupFromFields(CollectionFormService.extractFieldsFromForm(form));
  }

  /**
   * Initializes a FormGroup with a control for each field given
   * @param fields
   */
  public getFormGroupFromFields(fields: CollectionFormField[]): FormGroup<{}> {
    const formGroup = this.fb.group({});
    fields.forEach((field) => {
      if (field.FormFieldType == FormFieldType.List) {
        //formListFieldGroup is not a FormControl but a FormGroup
        formGroup.addControl(field.Id.toString(), this.getFormGroupFromCollectionListField(field));
      } else if (field.ComponentType == TableFieldDataType.OrganizationChartUnitSelector) {
        formGroup.addControl(field.Id.toString(), new FormControl(field.OrgChartUnitSelector));
      } else if (field.ComponentType == TableFieldDataType.Numeric) {
        formGroup.addControl(field.Id.toString(), new FormControl(field.Value, { validators: nanValidator }));
      } else
        formGroup.addControl(field.Id.toString(), new FormControl(field.Value));
    });
    return formGroup;
  }

  /**
   * Initializes a FormGroup with a control for each field given.
   * Allows for the property to be specified to use as the key for the form control in case you want to use a different property than the field's Id.
   * Example: Fields in a grid record are defined by their CollectionFieldId, not their Id.
   * @param fields
   */
  public getFormGroupFromFieldsByProperty(fields: CollectionFormField[], property: keyof CollectionFormField): FormGroup<{}> {
    const formGroup = this.fb.group({});
    fields.forEach((field) => {
      if (field.FormFieldType == FormFieldType.List) {
        //formListFieldGroup is not a FormControl but a FormGroup

        formGroup.addControl(field[property].toString(), this.getFormGroupFromCollectionListField(field));
      } else if (field.ComponentType == TableFieldDataType.OrganizationChartUnitSelector) {
        formGroup.addControl(field[property].toString(), new FormControl(field.OrgChartUnitSelector));
      } else
        formGroup.addControl(field[property].toString(), new FormControl(field.Value));
    });
    return formGroup;
  }

  public getFormGroupFromCollectionListField(field: CollectionFormField): FormGroup<{}> {
    if (!field.Records) {
      throw new Error('Field is not a list field');
    }

    const formGroup = this.fb.group({});
    field.Records.forEach((record) => {
      //Each record inside formListfield is a formgroup
      const formRecordGroup = this.getFormGroupFromFieldsByProperty(record.Fields, 'CollectionFieldsID');
      formGroup.addControl(record.CrossLinkedInstancesID.toString(), formRecordGroup);
    });
    return formGroup;
  }
}
