import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CollectionFormField } from '../../../../models/ts/collection-form-field.model';
import { CollectionFormFieldGridLookupData } from '../../../../models/ts/collection-form-field-grid-lookup-data.model';
import {
  CollectionFormFieldSingleLookupData,
} from '../../../../models/ts/collection-form-field-single-lookup-data.model';
import { LinkedCollectionType } from '../../../../models/ts/linked-collection-type.model';
import { LookupModalSecondLevelDto } from 'src/models/ts/lookup-modal-second-level-dto.model';
import { SkillIntegrityCheck } from '../../../../models/ts/skill-integrity-check.model';

@Injectable({
  providedIn: 'root',
})
export class CollectionFormLookupApiService {

  public constructor(private http: HttpClient) {

  }

  public getSearchByTypeAhead(collectionsId: number, collectionFormFieldId: number, viewsId: number, viewDataSourcesId: number, search: string): Observable<Array<CollectionListSummaryItem>> {
    return this.http.get<Array<CollectionListSummaryItem>>(`api/collection/${collectionsId}/lookupfield/${collectionFormFieldId}/view/${viewsId}/viewdatasources/${viewDataSourcesId}?query=${search}`);
  }

  public getEntitySearchByTypeAhead(collectionsId: number, collectionFormFieldId: number, viewsId: number, viewDataSourcesId: number, search: string, registeredByID: number, registeredOnBehalfOfID: number, versionsId: number): Observable<Array<CollectionListSummaryItem>> {
    return this.http.get<Array<CollectionListSummaryItem>>(`api/collection/${collectionsId}/lookupfield/${collectionFormFieldId}/view/${viewsId}/viewdatasources/${viewDataSourcesId}`, {params: {'query': search, registeredByID, registeredOnBehalfOfID, versionsId}});
  }

  public getSearchByTypeAheadDeeperLevel(collectionsId: number, collectionFormFieldId: number, viewDataSourcesId: number, foreignCollectionsId: number, childCollectionsId: number, instancesId: number, search: string): Observable<Array<CollectionListSummaryItem>> {
    return this.http.get<Array<CollectionListSummaryItem>>(`api/collection/${collectionsId}/lookupfield/${collectionFormFieldId}/viewdatasources/${viewDataSourcesId}/crosscollection/${foreignCollectionsId}/childcollection/${childCollectionsId}/instance/${instancesId}?query=${search}`);
  }

  public getEmptyRow(formId: number, dataSourceId: number): Observable<Array<CollectionFormField>> {
    return this.http.get<Array<CollectionFormField>>(`api/collection/lookup/form/${formId}/datasource/${dataSourceId}/emptyrow`);
  }

  public getFormFieldValuesByLookupField(formsId: number, datasourcesId: number, linkedCollectionType: LinkedCollectionType, foreignInstancesId: number, foreignVersionsId: number): Observable<CollectionFormFieldGridLookupData | CollectionFormFieldSingleLookupData> {
    return this.http.get<CollectionFormFieldGridLookupData | CollectionFormFieldSingleLookupData>(`api/collection/lookup/form/${formsId}/datasource/${datasourcesId}/type/${linkedCollectionType}/instance/${foreignInstancesId}/version/${foreignVersionsId}/formfields`);
  }

  public getFormFieldValuesByDeepLookupField(formsId: number, dataSourcesId: number, type: number, crossCollectionsId: number, crossLinkInstancesId: number, foreignInstancesId: number, foreignVersionsId: number): Observable<CollectionFormFieldGridLookupData | CollectionFormFieldSingleLookupData> {
    return this.http.get<CollectionFormFieldGridLookupData | CollectionFormFieldSingleLookupData>(`api/collection/lookup/form/${formsId}/datasource/${dataSourcesId}/type/${type}/crosscollection/${crossCollectionsId}/crosslinkinstancesID/${crossLinkInstancesId}/instance/${foreignInstancesId}/version/${foreignVersionsId}/formfields`);
  }

  public getDeeperLevelLookupList(formsId: number, collectionsId: number, listCollectionsId: number, crosslinkCollectionsId: number, dataDesignViewDataSourcesId: number, listId: number, instancesId: number): Observable<LookupModalSecondLevelDto> {
    return this.http.get<LookupModalSecondLevelDto>(`api/collection/lookup/form/${formsId}/list/collection/${collectionsId}/listcollection/${listCollectionsId}/crosslinkcollection/${crosslinkCollectionsId}/datadesignvds/${dataDesignViewDataSourcesId}/list/${listId}/instance/${instancesId}`);

  }

  public getIntegrity(request: {
                        collectionId: number,
                        documentVersionId: number,
                        skillVersionId: number,
                        trainingVersionsId: number,
                        examVersionId: number
                      }
  ): Observable<SkillIntegrityCheck> {
    return this.http.get<SkillIntegrityCheck>(`api/collection/${request.collectionId}/checkintegrity/document/${request.documentVersionId}/skill/${request.skillVersionId}/training/${request.trainingVersionsId}/exam/${request.examVersionId}`);
  }
}

export interface CollectionListSummaryItem {
  InstancesID: number;
  OriginalChildInstancesID: number;
  Text: string;
  VersionsID: number;
  CrossLinkInstancesID: number;
  // ???
  LinkedToParentVersions: Array<number>;
}

/**
 * Type guard for CollectionListSummaryItem
 * @param item
 * @return {item is CollectionListSummaryItem}
 */
export function isCollectionListSummaryItem(item: unknown): item is CollectionListSummaryItem {
  return (item as CollectionListSummaryItem) !== undefined;
}
