import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { FlowStatusTaskDetailsModel } from './flow-status-task-details-model';
import { NgScrollbarModule } from 'ngx-scrollbar';
import {
  FlowStatusTasksGridComponent,
} from '../../flow-status-tasks/flow-status-tasks-grid/flow-status-tasks-grid.component';
import { FlowStatusTaskType } from '../../../interfaces/flow-status-task-type';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { DialogModalButtons } from '../../../../../../shared/enums/dialog-modal-buttons';
import { FlowStatusTaskDto } from '../../../../../../../models/ts/flow-status-task-dto.model';

@Component({
  selector: 'bizz-flow-status-task-details-modal',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslatePipe, NgScrollbarModule, FlowStatusTasksGridComponent],
  templateUrl: './flow-status-task-details-modal.component.html',
  styleUrls: ['./flow-status-task-details-modal.component.scss'],
})
export class FlowStatusTaskDetailsModalComponent {

  public tasks: FlowStatusTaskDto[];

  public constructor(public dialogRef: DialogRef,
                     @Inject(DIALOG_DATA) public modalData: FlowStatusTaskDetailsModel) {
    this.tasks = modalData.tasks;
    this.dialogRef.disableClose = true;
  }

  protected readonly DialogModalButtons = DialogModalButtons;
  protected readonly FlowStatusTaskType = FlowStatusTaskType;
}
