import { Component, EventEmitter, Output } from '@angular/core';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { IconComponent } from '../../../ui/icon/icon.component';
import { MacroButtonComponent } from '../../../ui/macro-button/macro-button.component';
import { Store } from '@ngrx/store';
import { StoreCollectionForm } from '../../../../../store/features/forms/forms-state';
import { CollectionFormField } from '../../../../../../models/ts/collection-form-field.model';
import { AiButtonModel } from '../../../../../../models/ts/ai-button-model';
import { CellActionType } from '../../../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';
import { CellActionData } from '../../../../../features/bizzmine/widgets/collection-list-widget/interfaces/cell-action-data';
import { column } from 'mathjs';

@Component({
  selector: 'bizz-grid-cell-ai-button',
  standalone: true,
  templateUrl: './grid-cell-aibutton.component.html',
  styleUrl: './grid-cell-aibutton.component.scss',
  imports: [IconComponent, MacroButtonComponent]
})
export class GridCellAibuttonComponent extends GridCellBaseComponent<string> {
  public field: CollectionFormField | undefined;
  public fields: CollectionFormField[] | null;

  public constructor(
    public store: Store,
  ) {
    super();
  }

  public aiButtonCall(): void {
    this.onCellAction.emit({
      action: CellActionType.AI_BUTTON,
      data: { data: { column: this.column, data: this.data} }
    });
  }

}