<span>
    <span class="text-sm font-normal sm:text-xs"
          [innerText]="previewText | truncate: column.ColMaxChar">
    </span>
    @if (column.ShowReadMoreIcon && value) {
        <!--    showOn="hover"-->
        <span class="relative">
        <bizz-icon class="ml-1 inline-block h-5 w-5 text-list-row-action hover:cursor-pointer sm:h-3 sm:w-3" #anchor
                   (touchend)="showPopup()" (mouseenter)="onAnchorMouseEnter()" (mouseleave)="onAnchorMouseLeave()"
                   iconName="circle-info">
        </bizz-icon>
    </span>
    }
</span>
<ng-template #readmorePopupTemplateRef>
    <div class="bizz-popup-content readmore max-w-screen-sm text-xs md:max-w-[800px]" (mouseenter)="onPopupMouseEnter()"
         (mouseleave)="onPopupMouseLeave()">
        <div
                class="flex w-full items-center justify-between px-4 py-2.5 font-title text-base font-bold text-widget sm:block sm:text-sm">
            {{ column.title }}
            <bizz-icon class="block h-6 w-6 sm:!hidden" iconName="xmark" (click)="hidePopup()" (touchend)="hidePopup()"
                       [hover]="true"></bizz-icon>
        </div>
        <div class="border-t-[1px] border-button px-4 py-3 text-sm sm:text-xs">
            <div class="inline-block max-h-[calc(100svh_-_60px)] max-w-[calc(100vw_-_48px)] overflow-y-auto text-default *:!text-default sm:max-h-[550px] sm:w-fit sm:min-w-[352px] sm:max-w-[750px]"
                 [innerHTML]="value ?? '' | safe: 'html'">
            </div>
        </div>
    </div>
</ng-template>