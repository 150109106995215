import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { CollectionListDataInstance } from 'src/app/shared/interfaces/collection-list-data-instance';
import { ModalComponent } from '../modal/modal.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { TrnExamDto } from 'src/models/ts/trn-exam-dto.model';
import { CollectionListApiService } from 'src/app/api/bizzmine/collection-list/collection-list-api.service';
import { take } from 'rxjs';
import { LoaderButtonComponent } from '../../loader-button/loader-button/loader-button.component';

@Component({
  selector: 'bizz-duplicate-modal',
  standalone: true,
  imports: [ModalComponent, TranslatePipe, TextBoxModule, LoaderButtonComponent, AsyncPipe],
  templateUrl: './duplicate-modal.component.html',
  styleUrl: './duplicate-modal.component.scss'
})
export class DuplicateModalComponent {
  public dataItem: CollectionListDataInstance;
  public examName = '';
  public isLoading = false;

  public constructor(
    public dialogRef: DialogRef<CollectionListDataInstance>,
    @Inject(DIALOG_DATA) public data: CollectionListDataInstance,
    private collectionListService: CollectionListApiService
  ) {
    this.dataItem = data['dataItem'] as CollectionListDataInstance;
  }

  public onValueChange(value: string): void {
    this.examName = value;
  }

  public save(): void {
    if (!this.dataItem.CollectionsID) {
      throw new Error ('Invalid dataitem')
    }
    this.isLoading = true;
    this.collectionListService.duplicateInstance(
      this.dataItem.CollectionsID, 
      this.dataItem.ID, 
      this.dataItem.VersionsID, 
      { 'TRN_ExamName': {value: this.examName} 
    })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.dialogRef.close()
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }
}
