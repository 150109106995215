import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { AuthService } from '../../../core/services/auth/auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';
import { UrlTree } from '@angular/router';

@Component({
  selector: 'bizz-logout',
  standalone: true,
  imports: [],
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss'
})
export class LogoutComponent implements OnInit {
  private authService = inject(AuthService);
  private destroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    this.authService.isLoggedIn.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(loggedIn => !(loggedIn instanceof UrlTree))
    ).subscribe(
      () => this.authService.logoutViaIdentityServer()
    );
  }
}
