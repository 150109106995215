export enum AuditLogEventType {
	Create = 1,
	Read = 2,
	Update = 3,
	Delete = 4,
	UpdateSortOrder = 5,
	RecoverFromTrashCan = 6,
	Export = 7,
	ExecuteTask = 8,
	Initialized = 10,
	RestartWorkflow = 11,
	Cancel = 12,
	Completed = 14,
	Download = 15,
	Started = 17,
	DocumentCheckOut = 20,
	DocumentCheckin = 21,
	DocumentUndoCheckout = 23,
	BookmarkReplaced = 24,
	DirectLinkDownload = 25,
	DocumentPublished = 26,
	DocumentRemoved = 27,
	ChangedTo = 30,
	Succeeded = 41,
	Failed = 42,
	DocumentWrongMimeType = 43,
	TaskForwarded = 44,
	PeriodicRevisionPostPoned = 45
}