import { Injectable } from '@angular/core';
import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';
import { ReadOnlyPriority } from '../enums/read-only-priority.enum';

@Injectable({
  providedIn: 'root'
})
export class CollectionFormReadOnlyService {

  public static setReadOnly(field: CollectionFormField, readOnly: boolean, readOnlyPriority: ReadOnlyPriority): CollectionFormField {
    if (readOnlyPriority >= field.ReadOnlyPriority) {
      field.IsReadOnly = readOnly;
      field.ReadOnlyPriority = readOnlyPriority;
    }
    return field;
  }
}
