import { Component } from '@angular/core';
import { StatusIconComponent } from '../../../ui/status-icon/status-icon.component';
import { TaskStateType } from 'src/models/ts/task-state-type.model';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';

@Component({
  selector: 'bizz-grid-cell-notification-and-reminder-status',
  standalone: true,
  templateUrl: './grid-cell-notification-and-reminder-status.component.html',
  styleUrl: './grid-cell-notification-and-reminder-status.component.scss',
  imports: [StatusIconComponent]
})
export class GridCellNotificationAndReminderStatusComponent extends GridCellBaseComponent<number> {
  public TaskStateType = TaskStateType;
}
