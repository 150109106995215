import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {trainingFeature} from "./training-feature";
import {EffectsModule} from "@ngrx/effects";
import {TrainingEffects} from "./training-effects";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(trainingFeature),
        EffectsModule.forFeature(TrainingEffects)
    ]
})
export class TrainingFeatureStoreModule {
}