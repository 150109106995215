import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TrnTrainingQueueHistoryDto } from 'src/models/ts/trn-training-queue-history-dto.model';
import { Observable } from 'rxjs';
import { GridDataDto } from 'src/models/ts/grid-data-dto.model';
import { GridOptionsDto } from 'src/models/ts/grid-options-dto.model';
import { AuditLogGridSearchDto } from 'src/models/ts/audit-log-grid-search-dto.model';

@Injectable({
  providedIn: 'root',
})
export class TrackChangesApiService {

  public constructor(private http: HttpClient) {
  }

/**
 * Retrieves the training queue history for a specific collection, instance, version, and audit logs.
 * @param collectionId - The ID of the collection.
 * @param instanceId - The ID of the instance.
 * @param versionId - The ID of the version.
 * @param auditLogsId - The ID of the audit logs.
 * @returns An Observable that emits the training queue history as a TrnTrainingQueueHistoryDto.
 */
 public getTrainingQueueHistory(collectionId: number, instanceId: number, versionId: number, auditLogsId: number): Observable<TrnTrainingQueueHistoryDto[]> {
  return this.http.post<TrnTrainingQueueHistoryDto[]>(`api/trackchanges/trainingcollection/${collectionId}/instance/${instanceId}/version/${versionId}/audit/${auditLogsId}`, []);
}

public getTrackChanges(collectionId: number, instanceId: number, versionId: number, auditLogsId: number, search: Partial<AuditLogGridSearchDto>): Observable<GridDataDto> {
  return this.http.post<GridDataDto>(`api/trackchanges/collection/${collectionId}/instance/${instanceId}/version/${versionId}/audit/${auditLogsId}/search`, search);
}
  public getMailTrackChanges(request: {
    collectionId: number,
    instanceId: number,
    versionId: number,
    mailId: number,
    gridOptions: GridOptionsDto
                             }): Observable<GridDataDto> {
    return this.http.post<GridDataDto>(`api/trackchanges/collection/${request.collectionId}/instance/${request.instanceId}/version/${request.versionId}/mail/${request.mailId}`, request.gridOptions);
  }

/**
 * Retrieves the grid options from the server.
 * @returns An Observable that emits a GridOptionsDto object.
 */
public getGridOptions(): Observable<GridOptionsDto> {
  return this.http.get<GridOptionsDto>(`api/trackchanges/gridoptions`);
}

public export(collectionId: number, instanceId: number, versionId: number, search: Partial<AuditLogGridSearchDto>): Observable<ArrayBuffer> {
  return this.http.post(`api/trackchanges/collection/${collectionId}/instance/${instanceId}/version/${versionId}/export`, search, { responseType: 'arraybuffer' })
}
}
