<div class="modal-container justify-between md:justify-normal">
  <div class="modal-close-container">
    <bizz-icon
      class="ml-auto block h-5 w-5 text-button md:ml-0"
      (click)="dialogRef.close()"
      iconName="xmark"
      [hover]="true">
    </bizz-icon>
  </div>
  <div class="modal-body flex flex-col">
    <div class="modal-header">
      @if(taskInfo){
        <h1>
          {{ 'Assessment' | translate }}: {{taskInfo.Subject}} - {{ 'Step' | translate }}: {{taskInfo.StepsName}}
        </h1>
      }
    </div>
    <hr class="my-4" />
    <div class="flex h-full w-full flex-grow flex-col overflow-y-auto">
      <ng-scrollbar [visibility]="'hover'" style="height: 100%">
        <bizz-assessment-grid [assessmentList]="tasks"></bizz-assessment-grid>
      </ng-scrollbar>
    </div>
    <div class="modal-buttons">
      <button class="save min-w-16" (click)="dialogRef.close(DialogModalButtons.Ok)">
        {{'Ok' | translate}}
      </button>
    </div>
  </div>
</div>