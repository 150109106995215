import { createReducer, on } from '@ngrx/store';
import { initialHeaderRemindersStoreState } from './header-reminders-state';
import { headerRemindersActions } from './header-reminders-actions';

export const headerRemindersReducer = createReducer(
  initialHeaderRemindersStoreState,
  on(headerRemindersActions.fetchReminders,
    headerRemindersActions.readReminder,
    headerRemindersActions.unreadReminder,
    headerRemindersActions.readReminders,
    headerRemindersActions.updateSearch,
    (state) => {
      if (!state.loading) {
        const updatedState = structuredClone(state);
        updatedState.loading = true;
        return updatedState;
      }
      return state;
    }),
  on(headerRemindersActions.remindersFetched, (state, { reminders }) => {
    const updatedState = structuredClone(state);
    updatedState.reminders = reminders.Reminders;
    updatedState.count = reminders.NumberOfReminders;
    updatedState.loading = false;
    return updatedState;
  }),
  on(headerRemindersActions.updateSearch, (state, { search }) => {
    const updatedState = structuredClone(state);
    updatedState.search = search;
    return updatedState;
  })
);
