import { Component, Inject } from '@angular/core';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { StepType } from '../../../../../models/ts/step-type.model';
import { TaskCompletedType } from '../../../../../models/ts/task-completed-type.model';
import { AssessmentType } from '../../../../../models/ts/assessment-type.model';
import { CollectionListDataInstance } from '../../../interfaces/collection-list-data-instance';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../ui/icon/icon.component';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { TimePipe } from '../../../pipes/dates/time.pipe';
import { UtcDatePipe } from '../../../pipes/dates/utc-date.pipe';
import { MonitorDto } from '../../../../../models/ts/monitor-dto.model';
import { MonitorStepComponent, UIMonitorStep } from './monitor-step/monitor-step.component';
import { MonitorModel } from './monitor-model';

@Component({
  selector: 'bizz-monitor-modal',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    TextAreaModule,
    TranslatePipe,
    TimePipe,
    UtcDatePipe,
    MonitorStepComponent,
  ],
  templateUrl: './monitor-modal.component.html',
  styleUrls: ['./monitor-modal.component.scss'],
})
export class MonitorModalComponent {
  protected dataItem: CollectionListDataInstance;
  protected model: UIMonitor;

  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public modalData: MonitorModel,
  ) {
    //this.dataItem = modalData.dataItem;
    this.model = modalData.monitorDto as UIMonitor;
    this.dialogRef.disableClose = true;
  }

  protected readonly StepType = StepType;
  protected readonly TaskCompletedType = TaskCompletedType;
  protected readonly AssessmentType = AssessmentType;
}

export interface UIMonitor extends MonitorDto {
  Steps: UIMonitorStep[];
}
