import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WorkspaceIndexDto } from '../../../../models/ts/workspace-index-dto.model';
import { TileListSearchDto } from '../../../../models/ts/tile-list-search-dto.model';
import { WorkspaceDto } from '../../../../models/ts/workspace-dto.model';


@Injectable({
  providedIn: 'root'
})
export class WorkspaceApiService {

  public constructor(private http: HttpClient) { }

  public getWorkspaces(): Observable<WorkspaceIndexDto> {
    return this.http.get<WorkspaceIndexDto>('api/workspaces');
  }

  public searchWorkspacesSettings(search: TileListSearchDto): Observable<WorkspaceIndexDto> {
    return this.http.post<WorkspaceIndexDto>('settings/api/workspaces/search', search);
  }

  public searchWorkspaces(search: TileListSearchDto): Observable<WorkspaceIndexDto> {
    return this.http.post<WorkspaceIndexDto>('api/workspaces/search', search);
  }

  public getWorkspace(id: number): Observable<WorkspaceDto> {
    return this.http.get<WorkspaceDto>(`api/workspace/${id}`);
  }



}
