import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionForm } from '../../../../models/ts/collection-form.model';
import { map, Observable } from 'rxjs';
import { OrganizationChartItemType } from '../../../../models/ts/organization-chart-item-type.model';
import { ListDesignGridOptionsDto } from '../../../../models/ts/list-design-grid-options-dto.model';
import { GridDataDto } from '../../../../models/ts/grid-data-dto.model';
import { MailinglistSearchDto } from '../../../../models/ts/mailinglist-search-dto';
import { MailTemplateManageDto } from '../../../../models/ts/mail-template-manage-dto';
import { MailForMailTemplateDto } from '../../../../models/ts/mail-for-mail-template-dto';
import { CollectionFormField } from '../../../../models/ts/collection-form-field.model';
import { CollectionMethodType } from '../../../../models/ts/collection-method-type.model';
import { BizzmineHttpclientService } from '../bizzmine-httpclient.service';
import { FlowStatusTaskDto } from '../../../../models/ts/flow-status-task-dto.model';

@Injectable({
  providedIn: 'root'
})
export class CollectionFormApiService extends BizzmineHttpclientService {


  public startMinorRevision(collectionId: number, formId: number, closeStep: boolean, json: string): Observable<CollectionForm> {
    return this.http.post<CollectionForm>(`api/collection/id/${collectionId}/form/${formId}/saveandclose/${closeStep}/minor/create`, json, { headers: { 'Content-Type': 'application/json' } });
  }

  public startMajorRevision(collectionId: number, formId: number, closeStep: boolean, json: string): Observable<CollectionForm> {
    return this.http.post<CollectionForm>(`api/collection/id/${collectionId}/form/${formId}/saveandclose/${closeStep}/major/create`, json, { headers: { 'Content-Type': 'application/json' } });
  }

  public getFormByCollection(collectionId: number): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/collection/form/collection/${collectionId}`);
  }

  public getFormByWidget(collectionId: number, widgetId: number): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/collection/form/collection/${collectionId}/widget/${widgetId}`);
  }

  public getFormByFolder(collectionId: number, folderId: number): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/collection/form/collection/${collectionId}/folder/${folderId}`);
  }

  public getFormByInstance(collectionId: number, instanceId: number, versionId: number, guidChecksum: string | undefined): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/collection/${collectionId}/instance/${instanceId}/version/${versionId}`.appendChecksum(guidChecksum));
  }

  public getFormByInstanceRead(collectionId: number, instanceId: number, versionId: number, guidChecksum: string | undefined): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/collection/${collectionId}/instance/${instanceId}/version/${versionId}/read`.appendChecksum(guidChecksum));
  }

  public getFormByInstanceReadWithState(collectionId: number, instanceId: number, versionId: number, state: number, guidChecksum: string | undefined): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/collection/${collectionId}/instance/${instanceId}/version/${versionId}/state/${state}/read`.appendChecksum(guidChecksum));
  }

  public getFormByWorkspaceId(request: { collectionId: number, workspaceItemId: number }): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/collection/${request.collectionId}/form/workspaceitem/${request.workspaceItemId}`);
  }

  public getFormByTaskID(taskID: number): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/task/${taskID}/form`);
  }

  public getAnonymousForm(collectionId: number): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/collection/${collectionId}/anonreg`);
  }

  public getFormByTaskIDAdmin(taskID: number, collectionsID: number, isManager: boolean, assignedToID: number, assignedToType: OrganizationChartItemType): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/task/${taskID}/collection/${collectionsID}/manager/${isManager}/onbehalf/${assignedToID}/type/${assignedToType}/form`);
  }

  public getFormByTaskIdOnBehalfOf(taskID: number, collectionsID: number, assignedToID: number, assignedToType: OrganizationChartItemType): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/task/${taskID}/collection/${collectionsID}/onbehalf/${assignedToID}/type/${assignedToType}/form`);
  }

  public getExamDetails(request: {taskId: number}) : Observable<FlowStatusTaskDto>{
    return this.http.get<FlowStatusTaskDto>(`api/flowstatus/examtask/${request.taskId}/details`);
  }

  public getMailinglist(request: {
    formsId: number,
    fieldId: number,
    newFlowStarted: boolean
  }): Observable<ListDesignGridOptionsDto> {
    return this.http.get<ListDesignGridOptionsDto>(`api/collectionform/${request.formsId}/mailinglist/${request.fieldId}/newflowstarted/${request.newFlowStarted}`);
  }

  public getFormByCollectionMethodType(request: {
    collectionId: number,
    instanceId: number,
    versionId: number,
    collectionMethodType: CollectionMethodType
  }): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/collection/${request.collectionId}/instance/${request.instanceId}/version/${request.versionId}/flow/${request.collectionMethodType}/start`);
  }
  
  public getFormByCollectionFormField(formFieldId: number): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/collection/form/collectionformfield/${formFieldId}/view`);
  }

  public getFormByCollectionFormGridField(formFieldId: number): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/collection/form/datasource/collectionformfield/${formFieldId}`);
  }

  public getMailTemplateList(request: {
    collectionsId: number,
    instancesId: number,
    versionsId: number,
    searchData: MailinglistSearchDto
  }): Observable<GridDataDto> {
    return this.http.post<GridDataDto>(`api/mailtemplate/mailinglist/${request.collectionsId}/instance/${request.instancesId}/version/${request.versionsId}`, request.searchData);
  }

  public getMailTemplates(request: {
    collectionId: number
  }): Observable<Array<MailTemplateManageDto>> {
    return this.http.get<Array<MailTemplateManageDto>>(`api/mailtemplate/mailtemplates/${request.collectionId}`);
  }

  public getMailTemplateById(request: {
    mailId: number
  }): Observable<MailForMailTemplateDto> {
    return this.http.get<MailForMailTemplateDto>(`api/mailtemplate/mail/${request.mailId}`)
      .pipe(
        map(found => {
          found.mailId = request.mailId;
          return found;
        }));
  }
  
  public deleteMailDraft(mailId: number): Observable<void>{
    return this.http.delete<void>(`api/mailtemplate/mail/${mailId}/draft/delete`);
  }

  public downloadMailTemplateMedia(request: {
    mailId: number,
    mediaId: number,
    name: string
  }): Observable<ArrayBuffer> {
    return this.http.post(`api/mail/${request.mailId}/media/${request.mediaId}/download`, JSON.stringify(request.name), {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'arraybuffer'
    });
  }

  public getSelectedMailTemplate(request: {
    templateId: number,
    instanceId: number,
    versionId: number,
    fields: Array<CollectionFormField>
  }): Observable<MailForMailTemplateDto> {
    return this.http.post<MailForMailTemplateDto>(`api/mailtemplate/mailtemplate/${request.templateId}/instance/${request.instanceId}/version/${request.versionId}`, request.fields);
  }

  public sendMailFromTemplate(intent: MailForMailTemplateDto): Observable<Array<unknown>> // return dynamic object
  {
    return this.http.post<Array<unknown>>(`api/mailtemplate/mailmodal`, intent);
  }

  public draftMailFromTemplate(intent: MailForMailTemplateDto): Observable<Array<unknown>> // return dynamic object
  {
    return this.http.post<Array<unknown>>(`api/mailtemplate/draft/save`, intent);
  }
}