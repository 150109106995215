<div class="modal-container">

    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>

    <div class="modal-body w-full gap-4">
        <div class="modal-header">
            <h1>{{ "ForwardTask" | translate }}</h1>
        </div>
        <div class="text-center text-sm">{{ "Info_ForwardTask" | translate }}</div>
        <hr>

        <div class="gap-4">
            <h2>{{ "ForwardTaskTo" | translate }}<span class="ml-0.5 text-red-400">*</span>
            </h2>
            <!--  TODO: define item types allowed (if needed) and readonly (if needed)          -->
            <bizz-org-chart-select [selection]="selectedOrgChartItems$.value"
                                   (selectionChanged)="selectedOrgChartItems$.next($event)"
                                   [min]="1"
                                   [max]="1"
                                   [excludedUserIds]="excludedUserIds"
                                   [allowedItemTypes]="[OrganizationChartItemType.User]">
            </bizz-org-chart-select>
        </div>

        <h2>{{ "ForwardReason" | translate }}<span class="ml-0.5 text-red-400">*</span></h2>
        <kendo-textarea
                [rows]="15"
                size="large"
                resizable="vertical"
                [(ngModel)]="forwardingReason"
                fillMode="solid"
                rounded="large"
                (ngModelChange)="validate()"
        ></kendo-textarea>
    </div>


    <div class="modal-buttons">
        <div class="flex flex-row justify-end gap-3 text-center">
            <button class="default" (click)="close()">
                {{ "Cancel" | translate }}
            </button>
            <button class="save" (click)="forwardTask()" [disabled]="!isValid">
                {{ "ForwardTask" | translate }}
            </button>
        </div>

    </div>
</div>
