import { Component } from '@angular/core';
import { IconComponent } from '../../../ui/icon/icon.component';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';

@Component({
  selector: 'bizz-grid-cell-mail-attachment',
  standalone: true,
  imports: [
    IconComponent
  ],
  templateUrl: './grid-cell-mail-attachment.component.html',
  styleUrl: './grid-cell-mail-attachment.component.scss'
})
export class GridCellMailAttachmentComponent extends GridCellBaseComponent<string>{

}
