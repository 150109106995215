import { Injectable } from '@angular/core';
import { CronGenWeekly } from '../interfaces/crongen/cron-gen-weekly';

export interface SelectOptions {
  months: number[];
  monthWeeks: string[];
  monthWeeksFull: string[];
  days: Array<keyof CronGenWeekly>;
  minutes: number[];
  fullMinutes: number[];
  seconds: number[];
  hours: number[];
  fullHours: number[];
  monthDays: number[];
  monthDaysWithLasts: string[];
  monthDaysWithLastsFull: string[];

}
@Injectable({
  providedIn: 'root'
})

export class CronGenService {
  private QUARTZ_REGEX = /^\s*($|#|\w+\s*=|(\?|\*|(?:[0-5]?\d)(?:(?:-|\/|\,)(?:[0-5]?\d))?(?:,(?:[0-5]?\d)(?:(?:-|\/|\,)(?:[0-5]?\d))?)*)\s+(\?|\*|(?:[0-5]?\d)(?:(?:-|\/|\,)(?:[0-5]?\d))?(?:,(?:[0-5]?\d)(?:(?:-|\/|\,)(?:[0-5]?\d))?)*)\s+(\?|\*|(?:[01]?\d|2[0-3])(?:(?:-|\/|\,)(?:[01]?\d|2[0-3]))?(?:,(?:[01]?\d|2[0-3])(?:(?:-|\/|\,)(?:[01]?\d|2[0-3]))?)*)\s+(\?|\*|(?:0?[1-9]|[12]\d|3[01])(?:(?:-|\/|\,)(?:0?[1-9]|[12]\d|3[01]))?(?:,(?:0?[1-9]|[12]\d|3[01])(?:(?:-|\/|\,)(?:0?[1-9]|[12]\d|3[01]))?)*)\s+(\?|\*|(?:[1-9]|1[012])(?:(?:-|\/|\,)(?:[1-9]|1[012]))?(?:L|W)?(?:,(?:[1-9]|1[012])(?:(?:-|\/|\,)(?:[1-9]|1[012]))?(?:L|W)?)*|\?|\*|(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?(?:,(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?)*)\s+(\?|\*|(?:[1-7]|MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-|\/|\,|#)(?:[1-5]))?(?:L)?(?:,(?:[1-7]|MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-|\/|\,|#)(?:[1-5]))?(?:L)?)*|\?|\*|(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?(?:,(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?)*)(|\s)+(\?|\*|(?:|\d{4})(?:(?:-|\/|\,)(?:|\d{4}))?(?:,(?:|\d{4})(?:(?:-|\/|\,)(?:|\d{4}))?)*))$/;

  public dropDownOptions: SelectOptions = this.selectOptions();

  public monthWeekLookups: Record<string, string> = {
    '#1': 'First',
    '#2': 'Second',
    '#3': 'Third',
    '#4': 'Fourth',
    '#5': 'Fifth',
    'L': 'Last'
  };

  public dayLookups: Record<string, string> = {
    'SUN': 'Sunday',
    'MON': 'Monday',
    'TUE': 'Tuesday',
    'WED': 'Wednesday',
    'THU': 'Thursday',
    'FRI': 'Friday',
    'SAT': 'Saturday'
  };

 public constructor() {
    this.dropDownOptions.monthWeeksFull = this.dropDownOptions.monthDaysWithLasts.map((monthDay) => this.monthDayDisplay(monthDay));
    this.dropDownOptions.monthDaysWithLastsFull = this.dropDownOptions.monthWeeks.map((monthWeek) => this.monthWeekLookups[monthWeek]);
  }

 public monthDayDisplay(monthDay: string | number): string {
    if (monthDay === 'L') {
      return 'Last Day';
    } else if (monthDay === 'LW') {
      return 'Last Weekday';
    } else if (monthDay === '1W') {
      return 'First Weekday';
    } else {
      return '' + monthDay + this.appendInt(monthDay) + ' Day';
    }

  }


  //reverse of monthDayDisplay
 public  monthDayReverseDisplay(monthDay: string) : string{
    if (monthDay === 'Last Day') {
      return 'L';
    } else if (monthDay === 'Last Weekday') {
      return 'LW';
    } else if (monthDay === 'First Weekday') {
      return '1W';
    } else {
      return monthDay.replace(/[^0-9]/g, "")
    }

  }

  private isValid(cronFormat: string, expression: string): boolean {
    const formattedExpression = expression.toUpperCase();
    switch (cronFormat) {
      case 'quartz':
        return !!formattedExpression.match(this.QUARTZ_REGEX);
      default:
        throw 'Desired cron format (' + cronFormat + ') is not available';
    }
  }

  private appendInt(number: number | string): string {
    const value = '' + number;
    if (value.length > 1) {
      const secondToLastDigit = value.charAt(value.length - 2);
      if (secondToLastDigit === '1') {
        return "th";
      }
    }
    const lastDigit = value.charAt(value.length - 1);
    switch (lastDigit) {
      case '1':
        return "st";
      case '2':
        return "nd";
      case '3':
        return "rd";
      default:
        return "th";
    }
  }
 
 /**
   * Generates an array of numbers within a specified range.
   * If only one argument is provided, the range starts from 0 and ends at the specified number.
   * If two arguments are provided, the range starts from the first number and ends at the second number.
   * @param start - The starting number of the range.
   * @param end - The ending number of the range. (optional)
   * @returns An array of numbers within the specified range.
   * @throws Throws an error if the range values are negative.
   */
  public range(start: number, end?: number): number[]{
    if (typeof end === 'undefined') {
      end = start;
      start = 0;
    }

    if (start < 0 || end < 0) throw 'Range values must be positive values';

    if (end > start) {
      return [].concat(this.toConsumableArray(new Array(end - start))).map(function (val, idx) {
        return idx + start;
      });
    } else if (start < end) {
      return [].concat(this.toConsumableArray(new Array(start - end))).map(function (val, idx) {
        return end! - idx;
      });
    } else return [];
  }

  private toConsumableArray(arr: Array<any>): any {
    if (Array.isArray(arr)) {
      let arr2 = Array(arr.length);
      for (let i = 0; i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  } // Remove the semicolon at the end of the function definition
  public selectOptions(): SelectOptions {
    return {
      months: this.range(1, 13),
      monthWeeks: ['#1', '#2', '#3', '#4', '#5', 'L'],
      monthWeeksFull: [],
      days: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
      minutes: this.range(1, 60),
      fullMinutes: this.range(60),
      seconds: this.range(60),
      hours: this.range(1, 24),
      fullHours: this.range(24),
      monthDays: this.range(1, 32),
      monthDaysWithLasts: ['1W'].concat(this.toConsumableArray([].concat(this.toConsumableArray(new Array(31))).map((val, idx) => {
        return '' + (idx + 1);
      })), ['LW', 'L']),
      monthDaysWithLastsFull: [],

    };
  }
  //-------------------------------------------------------------------------------- HELPERS
  public getKeyByValue(obj: any, value: string): string | undefined {
    return Object.keys(obj).find(key => obj[key] === value);
  }

}
