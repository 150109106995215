@if (formFieldSignal(); as formField) {
    <div class="flex items-center gap-2">
        <input [ngClass]="{'hidden' : formField.IsHidden}" [class.error]="errorState != null"
               [formControl]="formControl" id="{{formField.Id}}" type="checkbox" kendoCheckBox>
        <label class="flex justify-center items-center font-semibold text-sm gap-1" [for]="formField.Id">
            <span class="flex items-center gap-2" [innerHTML]="formField.Caption">
            </span>
            @if (formField.IsRequired) {
                <span class="-top-0.5 h-5 text-xl leading-4 text-red-400">*</span>
            }
            @if (errorState?.Message; as errorMessage) {
                <div class="mx-2 flex justify-center items-center z-[1] h-full">
                    <bizz-tooltip class="text-red-400 cursor-pointer relative -top-1"
                                  [text]="errorMessage"></bizz-tooltip>
                </div>
            }
        </label>
    </div>
}
