import { Component, DestroyRef, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { TrnExamDto } from '../../../../../../models/ts/trn-exam-dto.model';
import { AsyncPipe, DatePipe } from '@angular/common';
import { NotificationFilterPipe } from '../../../../../shared/pipes/filter/notification-filter.pipe';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { BehaviorSubject, combineLatest, filter, lastValueFrom, of, shareReplay, switchMap, tap } from 'rxjs';
import { SafePipe } from 'safe-pipe';
import { Router } from '@angular/router';
import { BizzMineLocalStorageService } from '../../../../../shared/services/localStorage/bizzmine-local-storage.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoaderComponent } from '../../../../../shared/components/ui/loader/loader.component';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { TrainingApiService } from '../../../../../api/bizzmine/training/training-api.service';
import { SignalRService } from '../../../../../core/signalR/signal-r.service';
import { CompleteStepMessage, ManualExamEnrollmentRequest } from '../../../../../../models/ts/complete-step-message';
import { LoaderButtonComponent } from '../../../../../shared/components/loader-button/loader-button/loader-button.component';
import { ModalComponent } from 'src/app/shared/components/modals/modal/modal.component';

@Component({
  selector: 'bizz-exam-enrollment-modal',
  standalone: true,
  imports: [
    DatePipe,
    NotificationFilterPipe,
    TranslatePipe,
    AsyncPipe,
    SafePipe,
    LoaderComponent,
    IconComponent,
    LoaderButtonComponent,
    ModalComponent
  ],
  templateUrl: './exam-enrollment-modal.component.html',
  styleUrl: './exam-enrollment-modal.component.scss'
})
export class ExamEnrollmentModalComponent {

  public exam$ = new BehaviorSubject<TrnExamDto | null>(null);

  public isPolling$ = new BehaviorSubject<boolean>(false);
  public isLoading$ = combineLatest([this.trainingApiService.isLoading$, this.isPolling$]).pipe(
    takeUntilDestroyed(this.destroyRef),
    switchMap(([loader, poller]) => of(loader || poller)),
    shareReplay(1)
  );
  public currentListening = false;
  private enrollmentCallBackListener$ = this.signalrService
    .callBackMessageWithRequest<ManualExamEnrollmentRequest,CompleteStepMessage>( 'ManualExamEnrollmentRequest')
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(jobResult =>
        jobResult.request.ExamVersionsID == this.exam$.value!.VersionsID
        && jobResult.request.TrainingAppId == this.exam$.value!.TrainingAppID),
      tap((jobResult) => {
        this.isPolling$.next(false);
        if(jobResult?.item?.versionsID)
          this.openExam(jobResult.item.versionsID);
      })
    );

  public constructor(@Inject(DIALOG_DATA) public data: { exam: TrnExamDto, redirectUrl: string },
                     public dialogRef: DialogRef<boolean>,
                     private trainingApiService: TrainingApiService,
                     private route: Router,
                     private localStorage: BizzMineLocalStorageService,
                     private destroyRef: DestroyRef,
                     private signalrService: SignalRService) {
    this.exam$.next(this.data.exam);
    if (data?.redirectUrl) {
      this.localStorage.setItem('exam_redirect', data.redirectUrl);
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public enrollForExam(autoStart: boolean): void {
    if (autoStart && !this.currentListening) {
      // make sure we only sub 1x
      this.currentListening = true;
      this.enrollmentCallBackListener$.subscribe();
    }
    this.isPolling$.next(autoStart);
    lastValueFrom(this.trainingApiService.manualExamEnrollment({
      trainingAppID: this.exam$.value!.TrainingAppID,
      versionsID: this.exam$.value!.VersionsID
    }))
      .then(() => {
        if (!autoStart) {
          this.closeDialog();
        }
      });
  }

  private deferExamStartup(examSessionVersionsID: number): void {
    //TODO -> should be reworked, using SignalR when we start implementing it.
    setTimeout(() => {
      this.isExamReady(examSessionVersionsID).then(data => {
        if (data) {
          this.isPolling$.next(false);
          this.openExam(examSessionVersionsID);
        } else {
          this.deferExamStartup(examSessionVersionsID);
        }
      });
    }, 1000);
  }

  private isExamReady(examSessionVersionsID: number): Promise<boolean> {
    return lastValueFrom(this.trainingApiService.isExamReady({
      trainingAppID: this.exam$!.value!.TrainingAppID!,
      versionsID: examSessionVersionsID!
    }));
  }

  public openExam(examSessionVersionsID?: number): void {
    this.closeDialog();
    this.route.navigate([`./trn/${this.data.exam.TrainingAppID}/exam/${examSessionVersionsID ?? this.data.exam.SubscribedExamSessionVersionsID}`])
      .then();
  }

}
