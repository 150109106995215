import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Usage } from '../../../../../../../../models/ts/usage.model';
import { ProtectedCollectionType } from '../../../../../../../../models/ts/protected-collection-type.model';
import { AlertComponent } from '../../../../../../../shared/components/ui/alert/alert.component';
import { IconComponent } from '../../../../../../../shared/components/ui/icon/icon.component';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { TranslatePipe } from '../../../../../../../shared/pipes/translate/translate.pipe';

@Component({
  selector: 'bizz-exam-usages-dialog',
  standalone: true,
  imports: [
    AlertComponent,
    IconComponent,
    TextAreaModule,
    TranslatePipe
  ],
  templateUrl: './exam-usages-dialog.component.html',
  styleUrl: './exam-usages-dialog.component.scss'
})
export class ExamUsagesDialogComponent {

  public usedInDocuments: Array<Usage>;
  public usedInTrainings: Array<Usage>;

  public constructor(@Inject(DIALOG_DATA) public data: {examUsages: Array<Usage>},
                     public dialogRef: DialogRef) {
    if(!data || !data.examUsages) {
      console.error('No data provided for ExamUsagesDialogComponent');
    }
    this.usedInDocuments = data.examUsages.filter(usage => usage.UsageType == ProtectedCollectionType.TrnDocumentCollection);
    this.usedInTrainings = data.examUsages.filter(usage => usage.UsageType == ProtectedCollectionType.Trainings);
  }

}
