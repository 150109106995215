<span class="flex w-fit justify-between sm:w-full">
  <div class="text-list-action flex w-auto items-center gap-1">
    @if (metaData.trashFilterActive) {
        <span class="actions-cell-button" kendoTooltip [title]="'RecoverFromTrashCan' | translate">
      <bizz-icon class="actions-cell-icon h-4" (click)="onRecoverClicked($event)" iconName="recycle"></bizz-icon>
    </span>
    } @else {
        <span class="actions-cell-button" kendoTooltip [title]="'Edit' | translate">
      <bizz-icon class="actions-cell-icon h-4" (click)="onEditClicked($event)" iconName="pencil"></bizz-icon>
    </span>
        <span class="actions-cell-button " kendoTooltip [title]="'Delete' | translate">
      <bizz-icon class="actions-cell-icon h-4 hover:!text-red-500" (click)="onDeleteClicked($event)"
                 iconName="trash-can"></bizz-icon>
    </span>
    }
  </div>
</span>