<div class="modal-container modal-sm w-[900px]">
    <div class="modal-body">
        <div class="modal-header">
            <h1>
                {{ "CheckInFile" | translate }}</h1>
            <hr class="m-auto mb-5 mt-5 h-0 w-full border-t-2 border-divider">
        </div>

        @if (error) {
            <div class="rounded bg-yellow-200/50 text-default">
                <div class="m-3 flex items-center gap-2 rounded">
                    <bizz-icon class="size-6 text-yellow-500"
                               iconName="triangle-exclamation"></bizz-icon>
                    <div class="flex flex-col gap-2">
                                <p>{{ error | translate }}</p>
                    </div>
                </div>

            </div>
        }
        <div>

            <div class="flex">
                <div class="ga-4 w-full">
                    <div class="form-group">
                        <label>{{"ReasonModification" | translate }} <span class="text-red-500">*</span></label>
                        <kendo-editor
                                class="kendo-toolbar_hidden h-[calc(100svh_-_12.5rem)] min-w-full max-w-full sm:h-96"
                                [iframe]="false"
                                [resizable]="false"
                                [(ngModel)]="data.modificationReason">
                            <kendo-toolbar>
                            </kendo-toolbar>
                        </kendo-editor>
                    </div>
                </div>
            </div>

        </div>

        <div class="modal-buttons pt-4">
            <button class="save" (click)="close()">
                {{ 'Ok' | translate }}
            </button>

        </div>
    </div>
</div>

