import { Component, computed, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule, SignatureModule } from '@progress/kendo-angular-inputs';
import { FileSelectModule } from '@progress/kendo-angular-upload';
import { SignatureComponent } from '@progress/kendo-angular-inputs/signature/signature.component';
import { CollectionFormEditApiService } from '../../../../../../api/bizzmine/collection-form-edit/collection-form-edit-api.service';
import { asyncScheduler, lastValueFrom } from 'rxjs';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';

import { CanvasCustomToolbarModule } from './custom-canvas-toolbar-buttons/canvas-custom-toolbar.module';
import {
  CustomCanvasActions,
  CustomCanvasActionsComponent
} from './custom-canvas-toolbar-buttons/custom-canvas-actions/custom-canvas-actions.component';
import { CustomCanvasToolbarColorpickerComponent } from './custom-canvas-toolbar-buttons/custom-canvas-toolbar-colorpicker/custom-canvas-toolbar-colorpicker.component';
import { CustomCanvasToolbarDrawLineComponent } from './custom-canvas-toolbar-buttons/custom-canvas-toolbar-draw-line/custom-canvas-toolbar-draw-line.component';
import { CustomCanvasFileUploadComponent } from './custom-canvas-toolbar-buttons/custom-canvas-file-upload/custom-canvas-file-upload.component';
import SignaturePad from 'signature_pad';
import { CustomCanvasToolbarButtonComponent } from './custom-canvas-toolbar-buttons/custom-canvas-toolbar-button/custom-canvas-toolbar-button.component';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';

/**
 * Represents a control that allows the user to draw on a canvas.
 */
@Component({
  selector: 'bizz-canvas-control',
  standalone: true,
  imports: [CommonModule,
    EditorModule,
    InputsModule,
    FileSelectModule,
    IconsModule,
    SignatureModule,
    IconComponent,
    CanvasCustomToolbarModule,
    CustomCanvasToolbarDrawLineComponent,
    CustomCanvasToolbarColorpickerComponent,
    CustomCanvasActionsComponent,
    CustomCanvasFileUploadComponent, CustomCanvasToolbarButtonComponent
  ],
  templateUrl: './canvas-control.component.html',
  styleUrls: ['./canvas-control.component.scss']
})
export class CanvasControlComponent extends BaseFormControlComponent implements OnInit {
  public value: string | null = null;
  // Use theme colors
  public color = 'rgba(0, 0, 0, 1)';
  public strokeWidth = 1;
  public canvasHeight = 200;
  @ViewChild('signature') public signatureElement: SignatureComponent;
  @ViewChild('colorPickerToolbar') public colorPickerToolbar: CustomCanvasToolbarColorpickerComponent;

  public fallBackImage: string | null = null;
  public signaturePad: SignaturePad;
  public maxWidth = 0;
  public hasImg = false;
  public hiddenProcessCanvasId = 'hiddenProcessCanvas';
  public canDraw = false;
  public canEditImage = false;
  public actions: Array<CustomCanvasActions> = [];
  public fileSelectionError: string | null = null;
  @ViewChild('canvasElement', { static: true }) private canvasElement: ElementRef<HTMLCanvasElement>;
  @ViewChild('SignaturePadElement', { static: true }) private signaturePadElement: ElementRef<HTMLCanvasElement>;
  @ViewChild('SignaturePadImageElement', { static: true }) private signaturePadImageElement: ElementRef<HTMLImageElement>;
  private currentSize = {
    width: 0,
    height: 0
  };

  public constructor(private collectionApiService: CollectionFormEditApiService) {
    super();
  }

  public revertImage(): void {
    if (this.formFieldSignal()?.DefaultImage != null) {
      this.formControl.markAsDirty();
      this.setImageFromBase64(this.formFieldSignal()!.DefaultImage);
    }
  }

  public removeStrokes(): void {
    this.formControl.markAsDirty();
    this.signaturePad.clear();
    this.mergeCanvasWithImage();
  }

  public removeImage(): void {
    this.formControl.markAsDirty();
    this.setWithoutImage();
  }

  public removeAll(): void {
    this.formControl.markAsDirty();
    this.removeStrokes();
    this.removeImage();
    this.mergeCanvasWithImage();
  }

  public setNewColor(color: string): void {
    this.signaturePad.penColor = color;
    this.signaturePad.compositeOperation = 'source-over';

    if (!this.color) {
      this.signaturePad.compositeOperation = 'destination-out';
    }
  }

  public undo(): void {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    const cloned = structuredClone(this.formFieldSignal());

    if (this.isInGrid && cloned)
      cloned.CanvasHeight = 100;

    if (cloned) {
      this.value = cloned?.Value;
      this.hiddenProcessCanvasId = this.hiddenProcessCanvasId + cloned.Id;
      this.fallBackImage = this.value;


      if (cloned?.CanvasHeight > 0) {
        this.canvasHeight = cloned.CanvasHeight;
      }
      this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
      this.signaturePad.addEventListener('beginStroke', () => {
        this.formControl.markAsDirty();
          this.colorPickerToolbar.close();
        }
      );
      this.signaturePad.penColor = this.color;
      this.onStrokeWidthChange(this.strokeWidth);

      this.signaturePad.addEventListener('endStroke', () => {
        this.mergeCanvasWithImage();
      });

      if (this.value == null || this.value.trim() == '') {
        this.value = cloned.DefaultImage;
      }

      asyncScheduler.schedule(() => {
        if (this.value != null && this.value.trim() != '') {
          this.setImageFromBase64(this.value);
        } else {
          this.setWithoutImage();
        }
      });
    }
  }

  public redrewAfterToolBarChanged = false;

  public canUseToolbar = computed(() => {
    const formField = this.formFieldSignal();
    this.canEditImage = false;
    this.canDraw = false;
    this.actions = [];
    if(formField == null){
      return false;
    }

    if((formField.CanUseToolbar || formField.CanDraw  || formField.CanEditImage)&& this.formFieldSignal()?.IsReadOnly != true && !this.readOnlyOverride) {
      if (formField.CanDraw) {
        this.canDraw = true;
        this.actions.push(CustomCanvasActions.RemoveStrokes);

        if (formField.CanEditImage) {
          this.actions.push(CustomCanvasActions.RemoveAll);
        }
      }


      if (formField.CanEditImage) {
        this.canEditImage = true;
        this.actions.push(CustomCanvasActions.RevertImage);
        this.actions.push(CustomCanvasActions.RemoveImage);
      }
    }

    if(!this.redrewAfterToolBarChanged){
      this.redrewAfterToolBarChanged = true;
      asyncScheduler.schedule(() => {
        this.redraw();
      });
    }
    return this.canEditImage || this.canDraw;
  })

  protected override valueSetter(field: CollectionFormField): void {
    const diffentValue = this.value != field.Value;
    if(!diffentValue) return;
    this.value = field.Value;
    if (this.value != null && this.value.trim() != '') {
      this.setImageFromBase64(this.value);
    } else {
      this.setWithoutImage();
    }
  }

  public onStrokeWidthChange($event: number): void {
    this.signaturePad.dotSize = $event * 0.75;
    this.signaturePad.minWidth = $event * 0.75;
    this.signaturePad.maxWidth = this.strokeWidth;
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.redraw();
  }

  public drawImage(base64: string | null): void {
    if (!base64) return;
    const img = new Image();
    img.onload = (): void => {
      this.canvasElement.nativeElement.width = img.width;
      this.canvasElement.nativeElement.height = img.height;
      this.canvasElement.nativeElement.style.height = img.height + 'px';
      this.canvasElement.nativeElement.style.width = img.width + 'px';
      const ctx = this.canvasElement.nativeElement.getContext('2d');

      if (ctx) {
        ctx.fillStyle = '#FFFFFF';
        ctx.canvas.width = img.width;
        ctx.canvas.height = img.height;
        ctx.canvas.style.height = img.height + 'px';
        ctx.canvas.style.width = img.width + 'px';
        ctx.fillRect(0, 0, this.canvasElement.nativeElement.width, this.canvasElement.nativeElement.height);
        ctx.drawImage(img, 0, 0);

      }
    };
    img.src = base64;
  }

  public onImageUploadError(error: string): void {
    this.fileSelectionError = error;

    //display error for 15 seconds - then clear
    setTimeout(() => {
      this.fileSelectionError = null;
    }, 15000);
  }

  public onImageUpload(file: File): void {
    this.formControl.markAsDirty();
    this.readFile(file);
  }

  public readFile(file: File): void {
    const reader = new FileReader();

    const onLoad = (e: any) => {
      const arrayBuffer = e.target.result as ArrayBuffer; // Get array buffer
      const blob = new Blob([arrayBuffer], { type: 'image/jpeg' }); // Create JPEG blob
      const jpegUrl = URL.createObjectURL(blob); // Create temporary URL

      // some complex logic abusing canvas to get the JPEG data (convert)
      const img = new Image();
      img.onload = () => {
        const canvas = document.getElementById(this.hiddenProcessCanvasId) as HTMLCanvasElement;
        if (!canvas) return;
        canvas.style.visibility = 'hidden';
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0);
        }
        const jpegData = canvas.toDataURL('image/jpeg', 0.8); // Get base64 JPEG data

        this.readImage(jpegData).then().finally(() => canvas.style.visibility = 'visible'); // Pass base64 data to your function

        URL.revokeObjectURL(jpegUrl); // Clean up temporary URL
        img.src = ''; // Clear image source to release memory
      };
      img.src = jpegUrl;
    };

    reader.addEventListener('load', onLoad);
    reader.readAsArrayBuffer(file); // Read as array buffer for conversion
  }

  public readImage(imageBase64: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const image = new Image();

      const onLoad = () => {
        image.removeEventListener('load', onLoad);
        lastValueFrom(this.collectionApiService.resizeImage(image.src))
          .then((res) => {
            this.value = res;
            if (res != null) {
              this.setImageFromBase64(res);
            }
            this.fallBackImage = res;
            resolve();
          })

          .catch(reject); // Handle errors from resizeImage
      };

      image.addEventListener('load', onLoad);
      image.src = imageBase64;

      image.onerror = (error) => {
        reject(error); // Reject the Promise on image load error
      };
    });
  }

  private redraw(): void {
    const image = this.signaturePadImageElement.nativeElement;
    const canvas = this.signaturePadElement.nativeElement;
    const found = this.signaturePad.toData();
    const ratioX = image.width / canvas.width;
    const ratioY = image.height / canvas.height;
    for (let i = 0; i < found.length; i++) {
      for (let j = 0; j < found[i].points.length; j++) {
        found[i].points[j].x = found[i].points[j].x * ratioX;
        found[i].points[j].y = found[i].points[j].y * ratioY;
      }
    }
    canvas.style.width = image.width + 'px';
    canvas.style.height = image.height + 'px';
    canvas.width = image.width;
    canvas.height = image.height;
    this.signaturePad.fromData(found);
  }

  private setWithoutImage(): void {
    const h = this.formFieldSignal()?.CanvasHeight;
    if (h) {
      this.signaturePadImageElement.nativeElement.src = '';
      this.hasImg = false;
      const w = this.signaturePadImageElement.nativeElement.width;
      const image = this.signaturePadImageElement.nativeElement;
      const canvas = this.signaturePadElement.nativeElement;
      if (image && canvas) {
        image.height = h;
        image.style.height = h + 'px';
        image.width = w;

        canvas.height = h;
        canvas.width = w;
      }
      setTimeout(() => {
        // handle last on stack -> making sure html is rendered completely
        this.mergeCanvasWithImage();
      }, 0);
    }
  }

  private setImageFromBase64(base64: string): void {
    const img = new Image();
    img.onload = () => {
      this.maxWidth = img.width;
      this.signaturePadImageElement.nativeElement.style.height = 'auto';
      this.signaturePadImageElement.nativeElement.src = base64;
      this.hasImg = true;
      this.signaturePadImageElement.nativeElement.onload = () => {
        const w = this.signaturePadImageElement.nativeElement.width;
        const h = this.signaturePadImageElement.nativeElement.height;
        const c = this.signaturePadElement.nativeElement;
        if (c) {
          c.height = h;
          c.width = w;
        }
      };
      setTimeout(() => {
        // handle last on stack -> making sure html is rendered completely
        this.mergeCanvasWithImage();
      }, 0);

    };
    img.src = base64;
  }

  private mergeCanvasWithImage(): void {
    const canvasRef: HTMLCanvasElement = document.getElementById(this.hiddenProcessCanvasId) as HTMLCanvasElement;
    const context = canvasRef.getContext('2d');
    if (canvasRef && context) {

      if (this.value == null || !this.hasImg) {
        const img = new Image();
        img.onload = () => {
          canvasRef.width = img.width;
          canvasRef.height = img.height;
          canvasRef.style.height = img.height + 'px';
          canvasRef.style.width = img.width + 'px';
          context.fillStyle = '#FFFFFF';
          context.fillRect(0, 0, img.width, img.height);
          context.drawImage(img, 0, 0, img.width, img.height);
          this.value = context.canvas.toDataURL('image/jpeg');
          this.formControl.setValue(this.value);
          // sets the background as image -> making for a good resize experience
          this.setImageFromBase64(this.value);
          asyncScheduler.schedule(() => {
            this.redraw();
          });
        };
        img.src = this.signaturePad.toDataURL();
      } else {
        const backgroundImage = new Image();
        const drawings = new Image();

        drawings.onload = () => {
          context.drawImage(backgroundImage, 0, 0);
          context.drawImage(drawings, 0, 0);
          this.value = context.canvas.toDataURL('image/jpeg');
          this.formControl.setValue(this.value);
        };

        backgroundImage.onload = () => {
          canvasRef.width = backgroundImage.width;
          canvasRef.height = backgroundImage.height;
          canvasRef.style.height = backgroundImage.height + 'px';
          canvasRef.style.width = backgroundImage.width + 'px';
          context.drawImage(this.signaturePadElement.nativeElement, 0, 0, backgroundImage.width, backgroundImage.height);
          drawings.src = context.canvas.toDataURL('image/png');
        };

        backgroundImage.src = this.signaturePadImageElement.nativeElement.src;
        this.hasImg = true;
        asyncScheduler.schedule(() => {
          this.redraw();
        });


      }

    }


  }
  protected override focus(): void {
    // Add your implementation here
  }
}

