import { CollectionForm } from '../../../../../../models/ts/collection-form.model';
import { FormScriptingService } from '../../services/scripting/form-scripting.service';
import { TableFieldDataType } from 'src/models/ts/table-field-data-type.model';
import { ConditionFieldDto } from '../../../../../../models/ts/condition-field-dto.model';
import { FilterGroupType } from '../../../../../../models/ts/filter-group-type.model';
import { ConditionGroupDto } from '../../../../../../models/ts/condition-group-dto.model';
import { ConditionFieldValueDto } from '../../../../../../models/ts/condition-field-value-dto.model';
import { CollectionFormField } from '../../../../../../models/ts/collection-form-field.model';
import { Operators } from '../../../../../../models/ts/operators.model';
import { OrganizationChartItemType } from '../../../../../../models/ts/organization-chart-item-type.model';
import { ConditionDto } from '../../../../../../models/ts/condition-dto.model';
import { OrgChartItem } from '../../../org-chart/interfaces/org-chart-item';
import { isAfter, isBefore, isEqual } from 'date-fns';

/**
 * Validates scripting conditions.
 * @example
 * const validator = new ScriptingConditionsValidator();
 * validator.configuration = new ScriptingConditionsValidatorConfig(form, conditions, userId);
 * const isValid = validator.validateConditions();
 *
 * @example
 * const validator = new ScriptingConditionsValidator(new ScriptingConditionsValidatorConfig(form, conditions, userId));
 * const isValid = validator.validateConditions();
 * @see ScriptingConditionsValidatorConfig
 * @see FormScriptingService
 */
export class ScriptingConditionsValidator {
  private config: ScriptingConditionsValidatorConfig;

  public constructor(config?: ScriptingConditionsValidatorConfig) {
    if (config)
      this.configuration = config;
  }

  public set configuration(config: ScriptingConditionsValidatorConfig) {
    this.config = config;
  }

  /**
   * Validates the conditions based on the config property.
   * Throws an error if the config property is not set.
   */
  public validateConditions(): boolean {
    if (this.config) {
      let isValid = false;

      for (const group of this.config.conditions.Groups.filter(g => g.FilterType == FilterGroupType.Included)) {
        if (this.isValidGroup(group)) {
          isValid = true;
          break;
        }
      }

      for (const group of this.config.conditions.Groups.filter(g => g.FilterType == FilterGroupType.Excluded)) {
        if (this.isValidGroup(group)) {
          isValid = false;
          break;
        }
      }

      return isValid;
    } else {
      throw new Error('validateConditions called with no config set.');
    }
  }

  /**
   * Validates a group of conditions.
   * @param group
   * @private
   */
  private isValidGroup(group: ConditionGroupDto): boolean {
    if (group.Fields.length == 0) {
      return true;
    }

    return group.Fields.findIndex(field => !this.isValidField(field)) == -1;
  }

  /**
   * Validates the conditions on a field.
   * @param field
   * @private
   */
  private isValidField(field: ConditionFieldDto): boolean {
    const formField = FormScriptingService.getFormScriptingField(this.config.form, field.TableFieldID, -1);
    let fieldIsValid = true;

    if (formField) {
      field.Values.forEach(value => {
        const fieldValueIsValid = this.isValidFieldValue(value, formField.field, field);
        fieldIsValid = fieldIsValid && fieldValueIsValid;
      });
      return fieldIsValid;
    } else {
      return false;
    }
  }

  /**
   * Validates the value of a form field.
   * @param value
   * @param formField
   * @param field
   * @private
   */
  private isValidFieldValue(value: ConditionFieldValueDto, formField: CollectionFormField, field: ConditionFieldDto): boolean {
    switch (field.FieldType) {
      case TableFieldDataType.AlphaNumeric:
      case TableFieldDataType.HyperLink:
      case TableFieldDataType.Email:
      case TableFieldDataType.Memo:
      case TableFieldDataType.TextValue:
        // Treat null or undefined asif it's an empty string
        let fieldValue: string;
        try {
          fieldValue = formField.Value.toString();
        } catch (error) {
          fieldValue = '';
        }
        return this.isValidStringValue(value.StringValue, value.Operator, fieldValue, formField);
      case TableFieldDataType.Numeric:
      case TableFieldDataType.SimpleList:
      case TableFieldDataType.RadioGroup:
      case TableFieldDataType.Combobox:
        return this.isValidDoubleValue(value.DoubleValue, value.Operator, formField);
      case TableFieldDataType.DatePicker:
      case TableFieldDataType.TimePicker:
      case TableFieldDataType.DateTimePicker:
        return this.isValidDateValue(value.DateTimeValue, value.Operator, formField);
      case TableFieldDataType.Checkbox:
        return this.isValidBooleanValue(value.DoubleValue, formField);
      case TableFieldDataType.Canvas:
        return true;
      case TableFieldDataType.EnumList:
        return this.isValidEnumValue(value.DoubleValue, value.Operator, formField);
      case TableFieldDataType.OrganizationChartUnitSelector:
        return this.isValidSelectedOrganizationChartItemsValue(value.SelectedOrganizationChartItems, value.Operator, formField);
      default:
        return false;
    }
  }

  /**
   * Validates values as org chart items.
   * @param value
   * @param operator
   * @param formField
   * @private
   */
  private isValidSelectedOrganizationChartItemsValue(value: OrgChartItem[], operator: Operators, formField: CollectionFormField): boolean {
    const conditionItems = value.length;
    const formFieldValueItems = formField.OrgChartUnitSelector.length;
    const matchingItems = this.countMatchingOrgChartItems(value, formField.OrgChartUnitSelector);

    switch (operator) {
      // Check if the matching items are the same as the condition items
      case Operators.Contains:
        return matchingItems == conditionItems;
      case Operators.DoesntContain:
        return !(matchingItems == conditionItems);
      // Check if the matching items are exactly the same as the condition items
      case Operators.Equals:
        return ((matchingItems == conditionItems) && (conditionItems == formFieldValueItems));
      case Operators.NotEquals:
        return !((matchingItems == conditionItems) && (conditionItems == formFieldValueItems));
      case Operators.IsEmpty:
        return formFieldValueItems == 0;
      case Operators.HasValue:
        return formFieldValueItems > 0;
      default:
        this.throwInvalidOperatorError(formField, operator);
    }
  }

  /**
   * Validates enum values.
   * @param value
   * @param operator
   * @param formField
   * @private
   */
  private isValidEnumValue(value: number | null, operator: Operators, formField: CollectionFormField): boolean {
    let fieldValue: string;
    try {
      fieldValue = formField.EnumValue.toString();
    } catch (error) {
      fieldValue = '';
    }
    return value != null && this.isValidStringValue(value.toString(), operator, fieldValue, formField);
  }

  /**
   * Validates string values.
   * @param value
   * @param operator
   * @param fieldValue
   * @param formField
   * @private
   */
  private isValidStringValue(value: string, operator: Operators, fieldValue: string, formField: CollectionFormField): boolean {
    switch (operator) {
      case Operators.Contains:
        return fieldValue.toLowerCase().includes(value.toString().toLowerCase());
      case Operators.Equals:
        return fieldValue.toLowerCase() === value.toString().toLowerCase();
      case Operators.BeginsWith:
        return fieldValue.toLowerCase().startsWith(value.toString().toLowerCase());
      case Operators.EndsWith:
        return fieldValue.toLowerCase().endsWith(value.toString().toLowerCase());
      case Operators.DoesntContain:
        return !fieldValue.toLowerCase().includes(value.toString().toLowerCase());
      case Operators.NotEquals:
        return fieldValue.toLowerCase() !== value.toString().toLowerCase();
      case Operators.IsEmpty:
        return fieldValue.length == 0;
      case Operators.HasValue:
        return fieldValue.length > 0;
      default:
        this.throwInvalidOperatorError(formField, operator);
    }
  }

  /**
   * Validates number values.
   * @param value
   * @param operator
   * @param formField
   * @private
   */
  private isValidDoubleValue(value: number | null, operator: Operators, formField: CollectionFormField): boolean {
    if (value !== null) {
      switch (operator) {
        case Operators.Equals:
          return formField.Value === value;
        case Operators.NotEquals:
          return formField.Value !== value;
        case Operators.IsEmpty:
          return formField.Value === '';
        case Operators.HasValue:
          return formField.Value !== '';
        case Operators.SmallerThan:
          return formField.Value < value;
        case Operators.GreaterThan:
          return formField.Value > value;
        case Operators.SmallerOrEqualThan:
          return formField.Value <= value;
        case Operators.GreaterOrEqualThan:
          return formField.Value >= value;
        default:
          this.throwInvalidOperatorError(formField, operator);
      }
    }
    return false;
  }

  /**
   * Validates boolean values.
   * @param value
   * @param formField
   * @private
   */
  private isValidBooleanValue(value: number | null, formField: CollectionFormField): boolean {
    if (value != null && typeof (formField.Value) != 'undefined') {
      switch (value) {
        case Operators.Yes:
          return formField.Value == 1;
        case Operators.No:
        case Operators.IsEmpty:
          return formField.Value == 0 || formField.Value.length == 0;
        default:
          throw new Error(`Invalid Scripting Value for Field:${formField.Id}, Type:${formField.FieldType}, Value:${value}`);
      }
    }

    return false;
  }

  /**
   * Validates date values.
   * @param conditionValue
   * @param operator
   * @param formField
   * @private
   */
  private isValidDateValue(conditionValue: string | null, operator: Operators, formField: CollectionFormField): boolean {
    if (conditionValue != null && formField.Value != undefined) {
      // const convertedDateTimeFieldValue = this.convertDateTime(formField.Value, formField.ComponentType);
      // const convertedConditionValue = this.convertDateTime(conditionValue, formField.ComponentType);

      const fieldDate = new Date(formField.Value);
      const conditionDate = new Date(conditionValue);
      switch (operator) {
        case Operators.Equals:
          return isEqual(conditionDate, fieldDate);
        case Operators.NotEquals:
          return !isEqual(conditionDate, fieldDate);
        case Operators.GreaterThan:
          return isAfter(fieldDate, conditionDate);
        case Operators.GreaterOrEqualThan:
          return isEqual(conditionDate, fieldDate) || isAfter(fieldDate, conditionDate);
        case Operators.SmallerThan:
          return isBefore(fieldDate, conditionDate);
        case Operators.SmallerOrEqualThan:
          return isEqual(conditionDate, fieldDate) || isBefore(fieldDate, conditionDate);
        case Operators.IsEmpty:
          return formField.Value.length === 0;
        case Operators.HasValue:
          return formField.Value.length !== 0;
        default:
          this.throwInvalidOperatorError(formField, operator);
      }
    }

    return false;
  }

  /**
   * Counts the matching items in the formfield and the condition.
   * @param valueItems
   * @param fieldItems
   */
  private countMatchingOrgChartItems(valueItems: OrgChartItem[], fieldItems: OrgChartItem[]): number {
    let matchingItems = 0;

    valueItems.forEach(valueItems => {
      fieldItems.forEach(formFieldItem => {
        // ObjectId and ObjectType ==
        // ObjectType CurrentUser and CurrentUser is the objectId in formfield!
        if ((valueItems.VersionId == formFieldItem.VersionId && valueItems.UserType == formFieldItem.UserType)
          || (valueItems.ItemType == OrganizationChartItemType.CurrentUser && this.config.userId == formFieldItem.VersionId)) {
          matchingItems++;
        }
      });
    });

    return matchingItems;
  }

  /**
   * Throws an error for an invalid operator.
   * @param formField
   * @param operator
   * @private
   */
  private throwInvalidOperatorError(formField: CollectionFormField, operator: Operators): never {
    throw new Error(`Invalid Scripting Operator for Field:${formField.Id}, Type:${formField.FieldType}, Operator:${operator}`);
  }

}

/**
 * Configuration for the ScriptingConditionsValidator.
 */
export class ScriptingConditionsValidatorConfig {
  constructor(public form: CollectionForm, public conditions: ConditionDto, public userId: number) {
  }
}
