<div class="modal-container modal-sm justify-between md:justify-normal text-widget text-base">
    <div class="modal-close-container">
        <bizz-icon
                class="block w-5 h-5 text-button ml-auto md:ml-0"
                (click)="close()"
                iconName="xmark"
                [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body h-full w-full">
        <div class="modal-header">
            <h1 class="font-lg text-center text-widget">
                {{ "GenerateWithAI" | translate }}
            </h1>
        </div>
        <hr class="my-6"/>
        <div class="flex flex-col justify-center text-center gap-4 px-5">

            <div class="w-full">


                <kendo-label class="gap-4 [&>label]:block [&>label]:m-2.5"
                             [ngClass]="(intent.questions <= min) ? 'error' : ''"
                             text="Number of questions ({{intent.questions}})">
                    <kendo-slider
                            class=" w-full"
                            [showButtons]="false"
                            [min]="min"
                            [max]="max"
                            [(ngModel)]="intent.questions"
                            [largeStep]="5"
                            (ngModelChange)="validate()"
                    >
                    </kendo-slider>
                </kendo-label>
            </div>
            <small [ngClass]="(!isValidInput && intent.questions <= min) ? 'visible' : 'invisible'"
                   class="text-danger text-sm text-center">value must be between {{ min + 1 }}
                and {{ max }}</small>
            <div class="w-full">
                <kendo-label class="gap-4 [&>label]:block [&>label]:m-2.5"
                             [ngClass]="(intent.answersPerQuestion <= min) ? 'error' : ''"
                             text="Answers per question ({{intent.answersPerQuestion}})">
                    <kendo-slider
                            class="w-full"
                            [showButtons]="false"
                            [min]="min"
                            [max]="max"
                            [(ngModel)]="intent.answersPerQuestion"
                            [largeStep]="5"
                            (ngModelChange)="validate()"
                    >
                    </kendo-slider>
                </kendo-label>
            </div>
            <small [ngClass]="(!isValidInput && intent.answersPerQuestion <= min) ? 'visible' : 'invisible'"
                   class="text-danger text-sm text-center">value must be between {{ min + 1 }}
                and {{ max }}</small>
        </div>
    </div>


    <div class="modal-buttons">
        <button class="btn default" type="button" (click)="close()">{{ 'Cancel' | translate }}</button>
        <button class="btn save" type="button" [disabled]="!isValidInput" (click)="confirm()">
            <p> {{ 'Confirm' | translate }}</p></button>
    </div>
</div>
