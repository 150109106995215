<div class="modal-container max-w-5xl">
    <div class="modal-close-container">
        <bizz-icon iconName="xmark" (click)="dialogRef.close(false)"></bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1>
                {{ "FileCheckedOut_Title" | translate }}
            </h1>
            <p>{{ "FileCheckedOut_Info" | translate }}</p>
            <hr class="m-auto mb-5 mt-5 h-0 w-full border-t-2 border-divider">
        </div>
        <div class="flex flex-col grow gap-8">
            <div [ngClass]="{ 'bg-blue-600/25 !border-blue-500' : selectedType == 'FileCheckedOut_NoStayOnForm' }"
                 (click)="selectedType = 'FileCheckedOut_NoStayOnForm'"
                 class="group relative flex w-full cursor-pointer gap-3 rounded border-2 border-form-control
                 border-l-4 border-l-blue-500 p-4 shadow-md
                 hover:border-blue-400 hover:bg-blue-600/25 hover:border-3">
                <div class="flex flex-col justify-center items-center">
                    <bizz-icon iconName="file-pen" iconStyle="duotone"
                               class="text-blue-500 size-12 min-w-12"></bizz-icon>
                </div>
                <div class="m-r-1 m-l-4 flex flex-col gap-2.5">
                    <p class="font-bold group-hover:text-blue-500"
                       [ngClass]="{ 'text-blue-500' : selectedType == 'FileCheckedOut_NoStayOnForm' }">{{ "FileCheckedOut_NoStayOnForm" | translate }}</p>
                    <small class="text-light italic">{{ "FileCheckedOut_NoStayOnForm_Info" | translate }}</small>
                </div>
            </div>

            <div [ngClass]="{ 'bg-blue-600/25 !border-blue-500' : selectedType == 'FileCheckedOut_YesKeepCheckOut' }"
                 (click)="selectedType = 'FileCheckedOut_YesKeepCheckOut'"
                 class="group relative flex w-full cursor-pointer gap-3 rounded border-2 border-form-control
                 border-l-4 border-l-blue-500 p-4 shadow-md
                 hover:border-blue-400 hover:bg-blue-600/25 hover:border-3">
                <div class="flex flex-col justify-center items-center">
                    <bizz-icon iconName="file-arrow-down" iconStyle="duotone"
                               class="text-blue-500 size-12 min-w-12"></bizz-icon>
                </div>

                <div class="m-r-1 m-l-4 flex flex-col gap-2.5">
                    <p class="font-bold group-hover:text-blue-500"
                       [ngClass]="{ 'text-blue-500' : selectedType == 'FileCheckedOut_YesKeepCheckOut' }">
                        {{ "FileCheckedOut_YesKeepCheckOut" | translate }}</p>
                    <small class="text-light italic">{{ "FileCheckedOut_YesKeepCheckOut_Info" | translate }}</small>
                </div>
            </div>

            <div [ngClass]="{ 'bg-blue-600/25 !border-blue-500' : selectedType == 'FileCheckedOut_YesCancelCheckout' }"
                 (click)="selectedType = 'FileCheckedOut_YesCancelCheckout'"
                 class="group relative flex w-full cursor-pointer gap-3 rounded border-2 border-form-control
                 border-l-4 border-l-blue-500 p-4 shadow-md
                 hover:border-blue-400 hover:bg-blue-600/25 hover:border-3">
                <div class="flex flex-col justify-center items-center">
                    <bizz-icon iconName="file-minus" iconStyle="duotone"
                               class="text-blue-500 size-12 min-w-12"></bizz-icon>
                </div>
                <div class="m-r-1 m-l-4 flex flex-col gap-2.5">
                    <p class="font-bold group-hover:text-blue-500"
                       [ngClass]="{ 'text-blue-500' : selectedType == 'FileCheckedOut_YesCancelCheckout' }">
                        {{ "FileCheckedOut_YesCancelCheckout" | translate }}</p>
                    <small class="text-light italic">{{ "FileCheckedOut_YesCancelCheckout_Info" | translate }}</small>
                </div>
            </div>
        </div>

        <div class="modal-buttons pt-4">
            <button class="default" (click)="dialogRef.close(false)">
                {{ 'cancel' | translate }}
            </button>
            <button class="save" (click)="confirm()" [disabled]="selectedType == null">
                {{ 'confirm' | translate }}
            </button>
        </div>
    </div>
</div>

