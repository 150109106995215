<div class="mt-5 rounded bg-section p-5">

    <div class="grid h-32 w-full grid-cols-2 gap-y-3 rounded p-5 pt-3.5">
        @for(day of cronGenService.dropDownOptions.days; track day){
        <div class="flex gap-2">
            <input type="checkbox" [disabled]="readOnly" [id]="day" [checked]="state[day]" (change)="toggleDay(day)">
            <label class="pl-3" [for]="day">{{dayLookups[day] | translate}}</label>
        </div>
        }
    </div>
</div>

<div class="mt-5 rounded bg-section p-5">
    <div class="grid w-full grid-cols-2 gap-2 lg:inline-flex lg:items-center">
        <p>{{'StartTime' | translate}}</p>
        <bizz-cron-gen-time-select
            class="w-full lg:max-w-32"
            [readOnly]="readOnly"
            [state]="{  datetime: state.datetime, hours: state.hours, minutes: state.minutes, seconds: state.seconds, hourType: state.hourType}"
            (stateChange)="cronGenTimeChange($event)"></bizz-cron-gen-time-select>
    </div>

</div>