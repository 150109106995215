import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class BlackListApiService {

  private http = inject(HttpClient);

  public blackListToken(accessToken: string): Observable<void> {
    return this.http.post<void>('api/blacklist/add', JSON.stringify(accessToken), {
      headers: { 'Content-Type': 'application/json' }
    });
  }

}
