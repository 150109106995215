export const FLOW_DIAGRAM_CATEGORY_ICON_NAMES: { [dataType: string]: string } = {
  'normal': '',
  'gateway': '',
  'start': '',
  'end': '',
  'publication': 'envelop2',
  'assessment': 'file-check',
  'trainingappassessment': 'file-check',
  'trainingappsubscribetoexam': 'pen-plus',
  'trainingappexamination': 'pen',
  'trainingapppublishexam': 'envelop2',
  'distribution': 'share3',
};