<!-- Expand/Collapse Icons -->
<div *ngIf="(expanded | async)" class="flex w-full justify-start">
  <bizz-icon (click)="changeExpandState()"
             [ngClass]="{'rotate-0 opacity-50 text-sidebar-header' : (countButtonState == 1),
                        'text-blue-500' : (countButtonState != 1)}"
             class="mx-[15px] rotate-45 cursor-pointer hover:text-blue-400 hover:opacity-100" iconName="thumbtack"></bizz-icon>
</div>
<div *ngIf="!(expanded | async)" class="flex w-full justify-center">
  <bizz-icon (click)="changeExpandState()" class="mt-auto rotate-0 cursor-pointer text-sidebar-header opacity-50 hover:text-blue-400 hover:opacity-100"
             iconName="thumbtack"></bizz-icon>
  <bizz-icon *ngIf="(expanded | async)" (click)="changeExpandState()" class="mx-[15px] h-6 w-6 cursor-pointer text-sidebar-header hover:text-blue-400"
             iconName="thumbtack"></bizz-icon>
</div>
<!-- Expand/Collapse Icons -->