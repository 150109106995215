import { Component, ElementRef, Inject, ViewChild, inject } from '@angular/core';
import { IconComponent } from '../../ui/icon/icon.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { CollectionListDataInstance } from '../../../interfaces/collection-list-data-instance';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ArchiveModel } from './archive-model';
import { ArchiveItemComponent } from './archive-item/archive-item.component';
import { GridDataDto } from 'src/models/ts/grid-data-dto.model';
import { GridContextPermissions } from 'src/app/features/bizzmine/widgets/collection-list-widget/classes/grid-context-permissions';
import { ArchiveContextMenuComponent } from 'src/app/features/bizzmine/widgets/context-menu/archive/archive-context-menu.component';
import { LoaderComponent } from '../../ui/loader/loader.component';
import { ArchiveApiService } from 'src/app/api/bizzmine/archive/archive-api.service';
import { take } from 'rxjs';
import { ModalComponent } from '../modal/modal.component';
import { SkeletonModule } from '@progress/kendo-angular-indicators';

@Component({
    selector: 'bizz-archive-modal',
    standalone: true,
    imports: [ModalComponent, CommonModule, IconComponent, TranslatePipe, ArchiveItemComponent, ArchiveContextMenuComponent, LoaderComponent,     SkeletonModule,
    ],
    templateUrl: './archive-modal.component.html',
    styleUrl: './archive-modal.component.scss'
})

export class ArchiveModalComponent {
    protected dataItem: CollectionListDataInstance;
    protected model: GridDataDto;
    protected permissions: GridContextPermissions
    public isLoading: boolean;
    @ViewChild('contextMenu', { static: false }) public contextMenu: ArchiveContextMenuComponent;
    private archiveService = inject(ArchiveApiService);

    public constructor(
        @Inject(DIALOG_DATA) public archiveData: ArchiveModel,
        public dialogRef: DialogRef,
    ) {
        this.dataItem = archiveData.dataItem;
        this.permissions = archiveData.permissions;
        this.getArchiveData();
    }

    private getArchiveData(): void{
        this.isLoading = true;
        this.archiveService.getArchiveData(
            this.dataItem.CollectionsID!,
            this.dataItem.ID
        )
            .pipe(take(1))
            .subscribe({
                next: (value: GridDataDto) => {
                    this.model = value;
                },
                complete: () => {
                   this.isLoading = false;
                }
            });
    }

    public onArchiveItemAnchorClicked({ dataItem, permissions, anchor }: { dataItem: CollectionListDataInstance, permissions: GridContextPermissions, anchor: ElementRef }): void {
        this.contextMenu.dataItem = dataItem;
        this.contextMenu.permissions = permissions;
        this.contextMenu.anchor = anchor;

        this.contextMenu.open()
    }
}
