import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgClass } from '@angular/common';
import { NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { ControlOptions } from '../../interfaces/control-options.interface';

/**
 * Represents a control that allows the user to enter a numeric value.
 * Decimal and thousand separators are automatically added.
 * Amount of decimal places is restricted according to configuration.
 */
@Component({
  selector: 'bizz-shared-numeric-control',
  standalone: true,
  imports: [ReactiveFormsModule, NumericTextBoxModule, AsyncPipe],
  templateUrl: './numeric-control.component.html',
  styleUrls: ['./numeric-control.component.scss']
})
export class SharedNumericControlComponent {
  @Input() control: FormControl;
  @Input() options: ControlOptions;

}
