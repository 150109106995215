import { AfterViewInit, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { formsActions } from '../../../../../../store/features/forms/forms-actions';
import {TooltipComponent} from "../../../../../../shared/components/ui/tooltip/tooltip.component";
import { DateTimePickerModule } from '@progress/kendo-angular-dateinputs';

@Component({
  selector: 'bizz-enum-list-control',
  standalone: true,
  imports: [CommonModule, ComboBoxModule, ReactiveFormsModule, TooltipComponent, DateTimePickerModule],
  templateUrl: './enum-list-control.component.html',
  styleUrls: ['./enum-list-control.component.scss'],
})
export class EnumListControlComponent extends BaseFormControlComponent {

  protected override fieldValueProperty: keyof CollectionFormField = 'EnumValue';

  protected override valueChangeDispatcher(value: any): void {
    const fieldId = this.formFieldSignal()?.Id;
    if (fieldId)
      this.store$.dispatch(formsActions.updateFormFieldEnumValue({
        formId: this.formId,
        fieldId: fieldId,
        value: value
      }));
  }

  protected override focus(): void {
    // Add your implementation here
  }
}
