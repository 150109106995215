import { Component, EventEmitter, forwardRef, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ToolBarToolComponent } from '@progress/kendo-angular-toolbar';
import { IconComponent } from '../../../../../../../../shared/components/ui/icon/icon.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ButtonModule } from '@progress/kendo-angular-buttons';

@Component({
  selector: 'bizz-custom-canvas-toolbar-button',
  standalone: true,
  imports: [
    IconComponent,
    TooltipModule,
    ButtonModule
  ],
  templateUrl: './custom-canvas-toolbar-button.component.html',
  styleUrl: './custom-canvas-toolbar-button.component.scss',
  providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => CustomCanvasToolbarButtonComponent) }]
})
export class CustomCanvasToolbarButtonComponent extends ToolBarToolComponent {
  @ViewChild('toolbarTemplate', { static: true }) public override toolbarTemplate: TemplateRef<any>;
  @ViewChild('popupTemplate', { static: true }) public override popupTemplate: TemplateRef<any>;
  @Output() public click = new EventEmitter<void>();

  @Input({required: true}) public iconName: string;
  @Input() public tooltip: string;
  public constructor() {
    super();
  }
}
