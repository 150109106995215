import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { LoaderButtonComponent } from '../../../../../../shared/components/loader-button/loader-button/loader-button.component';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { AsyncPipe, NgClass } from '@angular/common';
import { TrnExamDto } from '../../../../../../../models/ts/trn-exam-dto.model';
import { TrnExamQuestion } from '../../../../../../../models/ts/trn-exam-question.model';
import { SafePipe } from 'safe-pipe';

@Component({
  selector: 'bizz-training-exam-question-content',
  standalone: true,
  imports: [
    SafePipe,
    IconComponent,
    LoaderButtonComponent,
    TranslatePipe,
    AsyncPipe,
    NgClass
  ],
  templateUrl: './training-exam-question-content.component.html',
  styleUrl: './training-exam-question-content.component.scss'
})
export class TrainingExamQuestionContentComponent {
  @Input({required: true}) public exam: TrnExamDto;
  @Input({required: true}) public isLoading: boolean;
  @Output() public nextQuestion = new EventEmitter<TrnExamQuestion>();
  public prefixLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
}
