import { Component, inject, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { FileUploadComponent } from '../file-upload.component';
import { MediaCommitDto } from '../../../../../../models/ts/media-commit-dto.model';
import { getFileExtension } from '../../../../functions/helpers/file-name-helpers';
import { EditorModule } from '@progress/kendo-angular-editor';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { CollectionFormFileUploadApiService } from '../../../../../api/bizzmine/collection-form-file-upload/collection-form-file-upload-api.service';
import { catchError, take, tap, zip } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CollectionListApiService } from '../../../../../api/bizzmine/collection-list/collection-list-api.service';
import { SimpleDocumentTemplateDto } from '../../../../../../models/ts/simple-document-template-dto.model';
import { collectionsActions } from '../../../../../store/features/collections/collections-actions';
import { Store } from '@ngrx/store';
import { refreshActions } from 'src/app/store/features/refresh/refresh-actions';

@Component({
  selector: 'bizz-file-upload-checkin-modal',
  standalone: true,
  imports: [
    IconComponent,
    TranslatePipe,
    FileUploadComponent,
    EditorModule,
    ToolBarModule
  ],
  templateUrl: './file-upload-checkin-modal.component.html',
  styleUrl: './file-upload-checkin-modal.component.scss'
})
export class FileUploadCheckinModalComponent {
  public versionId: number;
  public modal: {
    CollectionsID: number,
    VersionsID: number,
    DraftsID: number | undefined,
    InstancesID: number,
    Title: string,
  };
  public file: MediaCommitDto | undefined;
  public fileExtension: string = '';
  public fileSize: string = '';
  public modificationReason: string = '';
  public documentTemplate: SimpleDocumentTemplateDto;
  public disabled = true;
  public errors: Array<string> = [];
  public constructor(@Inject(DIALOG_DATA) public data: {
    data: any,
    checkIn: boolean
  }, public dialogRef: DialogRef,
                     private collectionFormFileUploadApiService: CollectionFormFileUploadApiService,
                     private collectionListApiService : CollectionListApiService){
    this.versionId = data.data.VersionsID;
    if (this.data.data.DraftsID != null && this.data.data.DraftsID > 0) {
      this.versionId = this.data.data.DraftsID;
    }
    zip(this.collectionListApiService.getModificationReason({
      versionId: this.versionId,
      collectionId: this.data.data.CollectionsID,
      instanceId: this.data.data.ID
    }), this.collectionListApiService.getDocumentTemplate({
      collectionId: this.data.data.CollectionsID,
    })).pipe(take(1)).subscribe(([reason, documentTemplateSettings]) => {
        this.modificationReason = reason;
        this.documentTemplate = documentTemplateSettings;
      }
    )
  }


  private store$ = inject(Store);

  public save(): void {
    this.errors = [];
    this.collectionFormFileUploadApiService.checkIn({
      versionId: this.versionId,
      collectionId: this.data.data.CollectionsID,
      instanceId: this.data.data.ID,
      checkIn: this.data.checkIn,
      checkInData: {
        Checkin: this.data.checkIn,
        File: this.file?.MediasID ?? 0,
        //CollectionsID: this.data.data.CollectionsID,
        //InstancesID: this.data.data.ID,
        //VersionsID: this.versionId,
        Extension: this.fileExtension,
        MimeType: '',
        MimetypeNotMatch: false,
        Name: this.file?.FileName ?? '',
        Size: this.file?.Size ?? 0,
        ReasonModification: this.modificationReason,
        //SaveVersionData: false,
        //Signed: false
      }
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errors = error.error.Errors.map((_: { Message: string; }) => _.Message);
        throw error;
      }),
      tap(() => {
          this.store$.dispatch(refreshActions.refreshData({ collectionId: this.data.data.CollectionsID }));

      })
    )
      .subscribe(() => this.dialogRef.close(true));

  }

  public fileChanged(event: any): void {
    this.file = event;
    this.fileExtension = this.file ? getFileExtension(this.file.FileName) : '';
    this.fileSize = this.file ? this.file.Size.toString() : '';
  }
}
