<div class="my-1 grid grid-cols-2 gap-y-4 px-4 pb-2 pt-4 text-sm font-medium text-list-row">
    <p> {{ 'Status' | translate }}</p>
    <div class="word-wrap flex w-full items-center gap-2.5 text-start">
      <bizz-status-icon [statusType]="dataItem.TaskStateType"></bizz-status-icon>
      {{ dataItem.TaskStateTypeText }}
    </div>
    @if(taskType !== FlowStatusTaskType.Detailed) {
      <div class="text-default"> {{ 'Step' | translate }}</div>
      <div class="w-full">
        {{ dataItem.StepsName }}
      </div>
      <div class="text-default"> {{ 'Subject' | translate }}</div>
      <div class="w-full"> {{ dataItem.Subject }} </div>
    }
    <div class="text-default"> {{ 'DueDate' | translate }}</div>
    <div class="w-full">
      <span *ngIf="dataItem.DueDate !== null">{{dataItem.DueDate | date:'short' }}</span>
      <span *ngIf="dataItem.DueDate === null">-</span>
    </div>
    <div class="text-default"> {{ 'Scheduled' | translate }}</div>
    <div class="w-full">
      <span *ngIf="dataItem.TimedStepDate !== null">{{dataItem.TimedStepDate | date:'short' }}</span>
      <span *ngIf="dataItem.TimedStepDate === null">-</span>
    </div>
    @if(taskType !== FlowStatusTaskType.Completed) {
      <div class="text-default"> {{ 'AssignedTo' | translate }}</div>
      <div class="w-full">
        @if(dataItem.TaskAssignedToValues.length > 0) {
          <bizz-org-chart-select [selection]="dataItem.TaskAssignedToValues" [readonly]="true"></bizz-org-chart-select> 
        }
        <span *ngIf="taskType == FlowStatusTaskType.Detailed"> {{ dataItem.AssignedTo }}</span>
      </div>
    }
    @if(taskType !== FlowStatusTaskType.Pending) {
      <div class="text-default"> {{ 'CompletedBy' | translate }}</div>
      <div class="w-full">
        <bizz-flow-status-tasks-grid-completed-by-column [task]="dataItem">
        </bizz-flow-status-tasks-grid-completed-by-column>
      </div>
      <div class="text-default">
        {{ 'CompletedOn' | translate }}
      </div>
      <div class="w-full">
        {{ dataItem.CompletedOn | date:'short' }}
        <span *ngIf="dataItem.CompletedOn == null">-</span>
      </div>
    }
</div>
@if(taskType !== FlowStatusTaskType.Detailed) {
<div class="flex w-full justify-end gap-2.5 px-4 pb-4 pt-2">
  <button class="default w-fit" *ngIf="dataItem.ViewAssessments" (click)="showAssessmentDetails.emit()">
    {{ 'ViewAssessments' | translate }}
  </button>
  <button class="default w-fit" (click)="showTaskDetails.emit()">
    {{ 'Details' | translate }}
    <bizz-icon iconName="eye" class="h-4 w-4"></bizz-icon>
  </button>
</div>
}