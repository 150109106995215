import { Component, Signal} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { MacroButtonComponent } from '../../../../../../shared/components/ui/macro-button/macro-button.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import {
  selectForm,
  selectFormFieldsByAiSourceFields,
} from '../../../../../../store/features/forms/forms-selectors';
import { Store } from '@ngrx/store';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { take } from 'rxjs';
import { StoreCollectionForm } from '../../../../../../store/features/forms/forms-state';
import { AiApiService } from '../../../../../../api/bizzmine/ai/ai-api.service';
import { AiButtonModel } from '../../../../../../../models/ts/ai-button-model';
import { formsActions } from '../../../../../../store/features/forms/forms-actions';
import { TableFieldDataType } from '../../../../../../../models/ts/table-field-data-type.model';
import { CollectionListDataInstance } from '../../../../../../shared/interfaces/collection-list-data-instance';
import { ReadOnlyPriority } from '../../../enums/read-only-priority.enum';
import { TooltipComponent } from '../../../../../../shared/components/ui/tooltip/tooltip.component';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
/**
 * Represents a button that the user can click to go to a predefined url.
 */
@Component({
  selector: 'bizz-ai-button-control',
  standalone: true,
  imports: [CommonModule, IconComponent, MacroButtonComponent, EditorModule, TooltipComponent, TooltipDirective],
  templateUrl: 'ai-button-control.component.html',
  styleUrls: ['ai-button-control-component.scss']
})
export class AiButtonControlComponent extends BaseFormControlComponent {
  public form: Signal<StoreCollectionForm | undefined>;

  public aiSourceFields: CollectionFormField[] | null;
  public aiTargetFields: CollectionFormField[] | null;

  public inputValues: Array<object> = [];
  public outputValues: Array<object> = [];

  public aiLicense = true;
  public aiLoading: boolean;
  public oldGrid: Array<CollectionListDataInstance> = [];

  public constructor(public store: Store, public aiService: AiApiService) {super();}

  protected override focus(): void {}

  public validateAiLicense (): void {
    /*if (this.aiLicense){*/
      this.aiButton();
    /*}else {
      throw new Error(`No valid ai license detected, please contact an administrator`);
    }*/
  }

  public aiButton(): void {
    // Field that is the button Duplicate
    const field = this.formFieldSignal();
    // Get the main form
    const form = structuredClone(this.store.selectSignal(selectForm(this.formId))());

    if (form !== undefined && field !== undefined) {
      this.aiLoading = true;
      // Get the input & output fields AI
      this.getAiFields(field, true, true)
      // Set the input & output fields AI + Readonly states
      this.setAiFields()

      if (this.aiSourceFields != undefined && this.aiTargetFields != undefined) {
        this.postAiButton(field, form);
      }
    }
  }

  public getAiFields (field: CollectionFormField, getSourceFields: boolean, getTargetFields: boolean): void {
    // Get the output fields for the AI
    if (getSourceFields) {
      this.aiSourceFields = structuredClone(this.store.selectSignal(selectFormFieldsByAiSourceFields(
        this.formId, field.AiSourceFields
      ))())
    }
    if (getTargetFields){
      this.aiTargetFields = structuredClone(this.store.selectSignal(selectFormFieldsByAiSourceFields(
        this.formId, field.AiTargetFields
      ))());
    }
  }

  public setAiFields (): void{
    // Get the input field(s) for the AI checked by the AiSourceFields
    if (this.aiSourceFields && this.aiSourceFields.length > 0) {
      this.aiSourceFields.forEach((field) => {
        this.inputValues.push({[field.Bookmark] : field.Value})
        this.store$.dispatch(formsActions.setFieldReadOnly({
          formId: this.formId,
          formFieldId: field?.Id ?? 0,
          isReadOnly: true,
          readOnlyPriority: ReadOnlyPriority.Protected
        }));
      })
    }else {
      //No input field(s) found that = AiSourceFields
    }

    if (this.aiTargetFields && this.aiTargetFields.length > 0) {
      this.aiTargetFields.forEach((field) => {
        this.outputValues.push({[field.Bookmark] : field.Value})
        this.store$.dispatch(formsActions.setFieldReadOnly({
          formId: this.formId,
          formFieldId: field?.Id ?? 0,
          isReadOnly: true,
          readOnlyPriority: ReadOnlyPriority.Protected
        }));
      })
    }else {
      //No input field(s) found that = AiSourceFields
    }
  }

  public postAiButton (field: CollectionFormField, form: StoreCollectionForm): void{
    const aiButtonModel: AiButtonModel = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      FormId: form.data.CollectionFormId,
      CollectionsID: form.data.CollectionsID,
      InstancesID: form.data.InstancesID,
      VersionsID: form.data.VersionsID,
      AiButtonID: field.CollectionFieldsID ?? 0,
      Fields: this.inputValues,
    }

    this.aiService.autoComplete(aiButtonModel)
      .pipe(take(1))
      .subscribe((data: any) => {
        data.forEach((item: any) => {
          const fieldToChange = this.aiTargetFields?.find(field => field.Id === item.Key);

          //If fieldToChange is not undefined
          if (fieldToChange != undefined) {
            //If fieldToChange has new value
            if (item.Value){
              this.store$.dispatch(formsActions.updateFormFieldCaption({
                formId: this.formId,
                fieldId: fieldToChange?.Id ?? 0,
                value: '<i class="text-sm text-blue-500 fa-solid fa-microchip-ai"></i> ' + fieldToChange.Caption
              }));
            }
            //Else if fieldToChange has no new value
            else{
              this.store$.dispatch(formsActions.updateFormFieldCaption({
                formId: this.formId,
                fieldId: fieldToChange?.Id ?? 0,
                value: '<i class="text-sm text-orange-400 fa-solid fa-circle-exclamation"></i> ' + fieldToChange.Caption
              }));
            }

            // Single Record Lookups
            if (item.Value.Text){
              this.store$.dispatch(formsActions.lookupChanged({
                formId: this.formId,
                lookupFieldId: fieldToChange?.Id ?? 0,
                lookupItem: item.Value
              }));
            }

            // Comboboxes
            if (fieldToChange.ComponentType == TableFieldDataType.Combobox) {
              const displayStyleValue = fieldToChange.FieldValues.find(field => field.CollectionFieldValuesID === item.Value);
              const arrayDisplay = [];
              arrayDisplay.push(displayStyleValue);
              this.store$.dispatch(formsActions.updateFormField({
                formId: this.formId,
                fieldId: fieldToChange?.Id ?? 0,
                value: item.Value
              }));
            }

            // Normal fields
            else {
              this.store$.dispatch(formsActions.updateFormField({
                formId: this.formId,
                fieldId: fieldToChange?.Id ?? 0,
                value: item.Value
              }));
            }
          }

          // Linked grids
          else if (Array.isArray(item.Value)) {
            if (this.oldGrid && this.oldGrid.length > 0) {
              this.oldGrid.forEach((item: any, index) => {
                this.store$.dispatch(formsActions.removeRecordFromGrid({
                  formId: this.formId,
                  gridFieldId: item.Key,
                  recordId: index
                }))
              })
            }else{
              this.oldGrid = item.value;
            }


            this.store$.dispatch(formsActions.addInstancesToGrid({
              formId: this.formId,
              gridFieldId: item.Key,
              instances: item.Value
            }));
          }
        })

        // Get the input field(s) for the AI checked by the AiSourceFields
        if (this.aiSourceFields && this.aiSourceFields.length > 0) {
          this.aiSourceFields.forEach((field) => {
            this.store$.dispatch(formsActions.setFieldReadOnly({
              formId: this.formId,
              formFieldId: field?.Id ?? 0,
              isReadOnly: false,
              readOnlyPriority: ReadOnlyPriority.Protected
            }));
          })
        }

        if (this.aiTargetFields && this.aiTargetFields.length > 0) {
          this.aiTargetFields.forEach((field) => {
            this.store$.dispatch(formsActions.setFieldReadOnly({
              formId: this.formId,
              formFieldId: field?.Id ?? 0,
              isReadOnly: false,
              readOnlyPriority: ReadOnlyPriority.Protected
            }));
          })
        }

        this.inputValues.splice(0, this.inputValues.length);
        this.outputValues.splice(0, this.outputValues.length);
        this.aiLoading = false;
      })
  }
}