import { createReducer, on } from '@ngrx/store';
import { workspaceSidebarActions } from './workspace-sidebar-actions';
import { initialWorkspaceState } from './workspace-sidebar-state';

export const workspaceSidebarReducer = createReducer(
  initialWorkspaceState,
  on(
    workspaceSidebarActions.setWorkspaceSidebar,
    (state, { workspaceSidebar }) => {
      return { ...workspaceSidebar };
    },
  ),
);
