@if(task) {
    <div class="mb-4 ml-8 mr-12 mt-0 grid max-w-[900px] grid-cols-[auto_auto] text-xs">
        @if (task.ForwardedReason != '') {
            <div [ngClass]="{ truncate: task.collapsed }"
                 id="forward-{{ task.TasksID }}"
                 class="grey-darkest">
                {{ 'Reason_Remark' | translate }}: {{ task.ForwardedReason }}
            </div>
        }
        @if (task.ForwardedReason == '' && task.Remarks != '') {
            <div [ngClass]="{ truncate: task.collapsed }"
                 id="remark-{{ task.TasksID }}" class="grey-darkest">
                {{ 'Reason_Remark' | translate }}: {{ task.Remarks }}
            </div>
        }
        <div class="ml-auto pl-2" (click)="task.collapsed = !task.collapsed">
            @if (task.collapsed) {
                <bizz-icon [hover]="true" class="ml-auto hidden text-button md:ml-0 md:block md:h-4 md:w-4"
                           iconName="chevron-down">
                </bizz-icon>
            }
            @if (!task.collapsed && isCollapsible) {
                <bizz-icon [hover]="true" class="ml-auto hidden text-button md:ml-0 md:block md:h-4 md:w-4"
                           iconName="chevron-up">
                </bizz-icon>
            }
        </div>
    </div>
}
