import {TimezonesDto} from "../../../../models/ts/timezones-dto.model";


interface MappedTimezone {
  ID: string;
  CountryName: string;
  Displayname: string;
  BaseUtcOffset: string;
  Value: string;
}

export function mapTimezonesDtoToMapped(timezones: TimezonesDto[]): MappedTimezone[] {
  const mappedTimezones: MappedTimezone[] = [];

  for (const timezonesDto of timezones) {
    for (const timezoneDto of timezonesDto.Timezone) {
      const valueString = `${timezoneDto.DisplayName} (UTC ${timezoneDto.BaseUtcOffset || ''})`;

      mappedTimezones.push({
        ID: timezonesDto.ID,
        CountryName: timezonesDto.CountryName,
        Displayname: timezoneDto.DisplayName,
        BaseUtcOffset: timezoneDto.BaseUtcOffset || '',
        Value: valueString,
      });
    }
  }

  return mappedTimezones;
}
