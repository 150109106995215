import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subscription, take } from 'rxjs';
import { GridSearchFilterSearchDto } from '../../../../models/ts/grid-search-filter-search-dto.model';
import { GridDataDto } from '../../../../models/ts/grid-data-dto.model';
import { CollectionInstanceCompareDto } from '../../../../models/ts/collection-instance-compare-dto.model';
import { InstanceFolderTreeDto } from '../../../../models/ts/instance-folder-tree-dto.model';
import { VersionType } from '../../../shared/enums/version-type';
import { UniqueCollectionFieldModel } from '../../../../models/ts/unique-collection-field-model.model';
import { TableViewWidgetData } from '../../../../models/ts/table-view-widget-data.model';
import { ItemDto } from '../../../../models/ts/item-dto.model';
import { ListDesignGridOptionsDto } from '../../../../models/ts/list-design-grid-options-dto.model';
import { CollectionInstancePropertiesDto } from 'src/models/ts/collection-instance-properties-dto.model';
import { ReportTileListDto } from 'src/models/ts/report-tile-list-dto.model';
import { InstancePermissionsDto } from 'src/models/ts/instance-permissions-dto.model';
import { SimpleDocumentTemplateDto } from '../../../../models/ts/simple-document-template-dto.model';
import { CollectionListDataInstance } from 'src/app/shared/interfaces/collection-list-data-instance';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { CollectionForm } from 'src/models/ts/collection-form.model';
import { RestartWorkflowParams } from 'src/app/shared/components/modals/data-collection/restart-workflow-modal/restart-workflow-params.interface';
import { DocumentDataModel } from 'src/models/ts/document-data-model.model';
import { MediaType } from 'src/models/ts/media-type.model';
import { WordReports } from 'src/app/shared/components/modals/report-modal/from-word-template/word-reports.interface';

@Injectable({
  providedIn: 'root',
})
export class CollectionListApiService {

  public constructor(private http: HttpClient, private downloadService: DownloadService) {
  }

  public getListOptionsByListId(listId: number): Observable<ListDesignGridOptionsDto> {
    return this.http.get<ListDesignGridOptionsDto>(`api/collection/list/${listId}/options`);
  }

  public getListOptions(collectionsID: number, viewsID: number, listID: number):
    Observable<TableViewWidgetData> {
    return this.http.get<TableViewWidgetData>
      (`api/collection/${collectionsID}/subcollection/${viewsID}/list/${listID}`);
  }

  public getListIdByViewsId(viewsId: number): Observable<number> {
    return this.http.get<number>(`api/view/${viewsId}/listid`);
  }

  public listSearch(listID: number, searchFilter: Partial<GridSearchFilterSearchDto>):
    Observable<GridDataDto> {
    return this.http.post<GridDataDto>(`api/collection/list/${listID}/search`, searchFilter);
  }

  public getCollectionInstancefromList(collectionsID: number, instanceId: number):
    Observable<CollectionListDataInstance> {
    return this.http.get<CollectionListDataInstance>(`api/collection/${collectionsID}/instance/${instanceId}`);
  }

  public exportXlsx(collectionID: number, searchFilter: Partial<GridSearchFilterSearchDto>):
    Observable<ArrayBuffer> {
    return this.http.post(`api/collection/${collectionID}/exportToXlsx`, searchFilter, { headers: { 'Content-Type': 'application/json' }, responseType: 'arraybuffer' });
  }

  public reports(collectionID: number):
    Observable<ReportTileListDto> {
    return this.http.get<ReportTileListDto>(`api/collection/${collectionID}/reports`);
  }

  public wordReports(templateCollectionsID: number): Observable<WordReports[]> {
    return this.http.get<WordReports[]>(`api/collection/${templateCollectionsID}/wordreports`);
  }

  public getWordReportGridOptions(collectionsID: number): Observable<ListDesignGridOptionsDto> {
    return this.http.get<ListDesignGridOptionsDto>(`api/collection/${collectionsID}/gridoptions`);
  }
 

  public getTemplate(collectionsID: number, type: number): Observable<DocumentDataModel[]> {
    return this.http.get<DocumentDataModel[]>(`api/collection/${collectionsID}/template/${type}/extensions`);
  }
  public generateWordReport(collectionsID: number, instanceID: number, versionID: number, templateInfo:  Partial<DocumentDataModel>): Observable<string> {
    return this.http.post<string>(`api/collection/${collectionsID}/instance/${instanceID}/version/${versionID}/generatewordreport`, templateInfo);
  }

  public downloadReport(collectionsID: number, instanceID: number, versionID: number, reportID: string, type: MediaType, templateInfo:  Partial<DocumentDataModel>): Observable<HttpResponse<ArrayBuffer>> {
    return this.http.post(`api/collection/${collectionsID}/instance/${instanceID}/version/${versionID}/report/${reportID}/type/${type}`,templateInfo, { headers: { 'Content-Type': 'application/json' }, observe: 'response', responseType: 'arraybuffer' });
  }

  public exportCsv(collectionID: number, searchFilter: Partial<GridSearchFilterSearchDto>):
    Observable<HttpResponse<ArrayBuffer>> {
    return this.http.post(`api/collection/${collectionID}/export`, searchFilter, { headers: { 'Content-Type': 'application/json' }, observe: 'response', responseType: 'arraybuffer' });
  }

  /** 
    @param collectionsId number
    @param instanceId number
    @param versionsId number
    @param body object
  */
  public duplicateInstance(collectionsId: number, instanceId: number, versionsId: number, body: object): Observable<void> {
    return this.http.post<void>(`api/collection/id/${collectionsId}/instancesid/${instanceId}/versionsID/${versionsId}/duplicate`, body);
  }


  /**
   * Retrieves comparison data for the draft and published version of an instance
   */
  public getInstanceComparisonData(collectionsID: number,
    originalFolderID: number,
    instanceID: number,
    versionID: number,
    draftID: number,
    isDeleteAction = true): Observable<CollectionInstanceCompareDto> {
    return this.http.get<CollectionInstanceCompareDto>(
      `api/collection/${collectionsID}/folder/${originalFolderID}` +
      `/instance/${instanceID}/version/${versionID}/compareVersion/${draftID}/isdeleteaction/${isDeleteAction}`);
  }

  /**
   * Retrieve the folders where the instance is referenced
   * @param collectionID
   * @param instanceID
   */
  public getFoldersFromInstance(collectionID: number, instanceID: number): Observable<InstanceFolderTreeDto[]> {
    return this.http.get<InstanceFolderTreeDto[]>(`api/collection/${collectionID}/instance/${instanceID}/folders`);
  }

  /**
   * Delete an instance
   * @param versionType (public of draft)
   * @param collectionId
   * @param instanceId
   * @param versionId
   * @param draftId
   * @param deleteReason
   */
  public deleteInstance(versionType: VersionType, collectionId: number,
    instanceId: number, versionId: number, draftId: number, deleteReason: string): Observable<void> {
    if (versionType === VersionType.Public) {
      return this.http.delete<void>(`api/collection/${collectionId}/instance/${instanceId}/version/${versionId}`, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        body: { 'ReasonDelete': deleteReason },
      });
    } else {
      return this.http.delete<void>(`api/collection/${collectionId}/instance/${instanceId}/draft/${draftId}`, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        body: { 'ReasonDelete': deleteReason },
      });
    }
  }

  /**
   * Restart the workflow of an instance.
   * @param collectionId
   * @param instanceId
   * @param versionId
   * @param restartReason
   */
  public restartWorkflow(restartFlowParams: RestartWorkflowParams): Observable<void> {
    return this.http.post<void>(`api/collectionflow/collection/${restartFlowParams.collectionId}/instance/${restartFlowParams.instanceId}/version/${restartFlowParams.versionId}/restart`,
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Reason': restartFlowParams.restartReason, 'RestartedByUserID': restartFlowParams.userId,
      });
  }

  /**
   * Restore (undelete) an instance.
   * @param collectionsId
   * @param instanceId
   * @param versionsId
   * @param restoreReason
   */
  public restoreInstance(collectionsId: number, instanceId: number,
    versionsId: number, restoreReason: string): Observable<Array<UniqueCollectionFieldModel>> {
    return this.http.post<Array<UniqueCollectionFieldModel>>(`api/collection/${collectionsId}/instance/${instanceId}/version/${versionsId}`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      { 'ReasonRestore': restoreReason });
  }

  /**
   * Retrieves the properties of a specific instance of a collection.
   * @param collectionId - The ID of the collection.
   * @param instanceId - The ID of the instance.
   * @param versionId - The ID of the version.
   * @returns An Observable that emits the properties of the collection instance.
   */
  public getPropertiesFromInstance(collectionId: number, instanceId: number, versionId: number):
    Observable<CollectionInstancePropertiesDto> {
    return this.http.get<CollectionInstancePropertiesDto>(`api/collection/${collectionId}/instance/${instanceId}/version/${versionId}/properties`);
  }

  /**
   * Retrieves the direct link for a specific collection, instance, version, and access type.
   * @param collectionId - The ID of the collection.
   * @param instanceId - The ID of the instance.
   * @param versionId - The ID of the version.
   * @param accessType - The access type.
   * @returns An Observable that emits the direct link as a string.
   */
  public getDirectLinkFromInstance(collectionId: number, instanceId: number, versionId: number, accessType: number): Observable<string> {
    return this.http.get(`api/collection/${collectionId}/instance/${instanceId}/version/${versionId}/accesstype/${accessType}/directlink`, { responseType: 'text' });
  }

  /**
   * Saves the properties of a collection instance.
   * 
   * @param collectionId - The ID of the collection.
   * @param instanceId - The ID of the instance.
   * @param versionId - The ID of the version.
   * @param properties - The properties to be saved.
   * @returns An Observable of type CollectionInstancePropertiesDto representing the saved properties.
   */
  public savePropertiesFromInstance(collectionId: number, instanceId: number, versionId: number, properties: CollectionInstancePropertiesDto): Observable<CollectionInstancePropertiesDto> {
    return this.http.post<CollectionInstancePropertiesDto>(`api/collection/${collectionId}/instance/${instanceId}/version/${versionId}/properties`, properties);
  }


  public permissionsFromInstance(collectionId: number, instanceId: number, versionId: number, folderId: number): Observable<InstancePermissionsDto> {
    return this.http.get<InstancePermissionsDto>(`api/collection/${collectionId}/instance/${instanceId}/version/${versionId}/folder/${folderId}/permissions`);
  }


  public collectionPermissions(collectionId: number): Observable<InstancePermissionsDto> {
    return this.http.get<InstancePermissionsDto>(`api/collection/${collectionId}/permissions`);
  }

  public folderPermissions(folderId: number): Observable<InstancePermissionsDto> {
    return this.http.get<InstancePermissionsDto>(`api/folder/${folderId}/permissions`);
  }

  public savePermissions(
    collectionId: number,
    instanceId: number,
    versionId: number,
    folderId: number,
    permissions: InstancePermissionsDto
  ): Observable<void> {
    return this.http.post<void>(`api/collection/${collectionId}/instance/${instanceId}/version/${versionId}/folder/${folderId}/permissions`, permissions);
  }

  /**
   * Get the lists for a collection view
   * @param viewsId The view ID
   */
  public getListsForCollectionView(viewsId: number): Observable<Array<ItemDto>> {
    return this.http.get<Array<ItemDto>>(`settings/api/collection/view/${viewsId}/lists`);
  }

  public getModificationReason(request: {
    collectionId: number,
    instanceId: number,
    versionId: number,
  }): Observable<string> {
    return this.http.get(`api/collection/${request.collectionId}/instance/${request.instanceId}/version/${request.versionId}/reasonmodification`, { responseType: 'text' });
  }

  public getDocumentTemplate(request: {
    collectionId: number
  }): Observable<SimpleDocumentTemplateDto> {
    return this.http.get<SimpleDocumentTemplateDto>(`api/collection/${request.collectionId}/documenttemplate`);
  }

  public downloadCompareFile(dataItem: CollectionForm): Subscription | undefined {
    const { CollectionsID: collectionsId, InstancesID: instanceId, VersionsID: versionsId } = dataItem;
    const file = dataItem.DocumentProperties.DocumentComparison.CompareFileMediaID;
    //Hardcoded file name ?
    const fileName = 'comparison.pdf';
    // see logic upload service.js
    return this.http.post(
      `api/collection/${collectionsId}/instance/${instanceId}/version/${versionsId}/media/${file}/download`,
      JSON.stringify(fileName),
      { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, responseType: 'arraybuffer' })
      .pipe(take(1))
      .subscribe((data: ArrayBuffer) => {
        this.downloadService.startDownload(data, fileName);
      });
  }

  public downloadCompareFileBase64(intent: {
    collectionsId: number,
    instanceId: number,
    versionsId: number,
    compareFileMediaId: number,
    fileName: string
  }): Observable<ArrayBuffer> {
    // see logic upload service.js
    return this.http.post(
      `api/collection/${intent.collectionsId}/instance/${intent.instanceId}/version/${intent.versionsId}/media/${intent.compareFileMediaId}/download`,
      JSON.stringify(intent.fileName),
      { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, responseType: 'arraybuffer' })
      .pipe(take(1))
  }
}
