import { Injectable } from '@angular/core';
import * as go from 'gojs';
import { maxBy } from 'lodash';
import { FlowStatusTaskDto } from '../../../../../models/ts/flow-status-task-dto.model';
import { TranslationService } from '../../../../core/services/translation/translation.service';
import { FlowDto } from '../../../../../models/ts/flow-dto.model';
import { FlowDiagramState } from '../interfaces/flow-diagram-state';
import { FlowStatusTaskService } from './flow-status-task.service';
import { StepDto } from '../../../../../models/ts/step-dto.model';
import { BizzFlowNodeData } from '../interfaces/bizz-flow-node-data';
import { StepType } from '../../../../../models/ts/step-type.model';
import { UpdateDeleteState } from '../../../../../models/ts/update-delete-state.model';
import { BizzFlowStepData } from '../interfaces/bizz-flow-step-data';
import { SwimlaneDto } from '../../../../../models/ts/swimlane-dto.model';
import { BizzFlowSwimlaneData } from '../interfaces/bizz-flow-swimlane-data';
import { TranslationInfoDto } from '../../../../../models/ts/translation-info-dto.model';
import { TaskStateType } from '../../../../../models/ts/task-state-type.model';
import { LinkDto } from '../../../../../models/ts/link-dto.model';
import { BizzFlowLinkData } from '../interfaces/bizz-flow-link-data';
import { PortType } from '../../../../../models/ts/port-type.model';
import { LinkType } from '../../../../../models/ts/link-type.model';
/**
 * Service that maps a FlowDto object to gojs data
 * Based on AngularJS FlowMapper.js
 */
@Injectable({
  providedIn: 'root',
})
export class FlowMapperService {

  private isSettings: boolean;
  private pendingTasks: FlowStatusTaskDto[] = [];
  private completedTasks: FlowStatusTaskDto[] = [];

  public constructor(private translationService: TranslationService) {
  }

  /**
   * Maps the FlowDto object to a FlowDiagramState object.
   * This object contains a list of nodes and links that can be used to
   * render a flow diagram.
   * @param model - the flow dto object
   * @param tasks - the flow status tasks (used to determine the status of steps)
   */
  public mapToFlowState(model: FlowDto, tasks: FlowStatusTaskDto[]): FlowDiagramState {
    console.debug(model);

    this.sortTasks(tasks);

    return {
      nodeDataArray: this.generateNodeDataList(model).toArray(),
      linkDataArray: this.generateLinkDataList(model.Links).toArray(),
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      modelData: { prop: 'value' },
      dataModel: model,
      skipsDiagramUpdate: false,
      selectedNodeData: null,
    };
  }

  /**
   * Sorts the tasks list into two lists: pending and completed
   * These are used to determine the status of steps
   * @param tasks a list of flow status tasks
   */
  private sortTasks(tasks: FlowStatusTaskDto[]): void {
    this.pendingTasks = FlowStatusTaskService.filterPendingTasks(tasks);
    this.completedTasks = FlowStatusTaskService.filterCompletedTasks(tasks);
  }

  /**
   * Return pending task for a step
   */
  private getPendingTaskForStep(step: StepDto): FlowStatusTaskDto | undefined {
    if (step == undefined)
      return undefined;

    return this.pendingTasks.find((task) => {
      return task.StepVersionsID == step.StepVersionsID;
    });
  }

  /**
   * Return completed task for a step
   */
  private getCompletedTaskForStep(step: StepDto): FlowStatusTaskDto | undefined {
    if (step == undefined)
      return undefined;

    return this.completedTasks.find((task) => {
      return task.StepVersionsID == step.StepVersionsID;
    });
  }


  /**
   * Generates the list of nodes used to draw the flow diagram
   * Requires the pending and completed tasks to be sorted
   * @param model the flow model
   */
  private generateNodeDataList(model: FlowDto): go.List<BizzFlowNodeData> {
    const nodeList = new go.List<BizzFlowNodeData>();
    model.Steps.forEach((step) => {
      const newStep = structuredClone(step);
      if (newStep.StepType == StepType.Gateway ||
        newStep.StepType == StepType.AssessmentGateway) {
        newStep.RestartFlowLink.State = UpdateDeleteState.Update;

        //Setting restart flow StepVersionsID (to and from)
        //Set From gateway
        newStep.RestartFlowLink.FromStepVersionsID = step.StepVersionsID;

        // Set to start step.
        const startStep = model.Steps.find(
          (s) => s.StepType == StepType.StartStep);
        if (startStep != undefined)
          newStep.RestartFlowLink.ToStepVersionsID = startStep.StepVersionsID;
        else
          throw new Error('Start step not found');
      }

      let category = this.getCategory(newStep);
      const pendingTask = this.getPendingTaskForStep(step);
      const completedTask = this.getCompletedTaskForStep(step);
      if (pendingTask == undefined && completedTask == undefined &&
        category === 'normal')
        category = '';


      const node: BizzFlowStepData = {
        'key': newStep.StepVersionsID.toString(),
        'name': this.getNodeName(newStep.TranslationInfo),
        'group': this.getSwimlaneForStep(newStep),
        'loc': this.getStepLocation(newStep),
        'category': category,
        'dataModel': newStep,
        'iconName': this.getIconnameByStepType(newStep.StepType, newStep.AllowAnonymous),
        'status': this.getStatusCategory(newStep, pendingTask, completedTask),
      };

      nodeList.push(node);
    });

    //Swimlanes
    // Calculate max width swimlane
    const maxWidthSwimlane = this.getMaxWidthOfNodeInSwimlane(model.Steps) + 150;

    model.Swimlanes.forEach((swimlane: SwimlaneDto, index: number) => {
      const node: BizzFlowSwimlaneData = {
        'key': 'S' + swimlane.ID,
        'name': this.getNodeName(swimlane.TranslationInfo),
        'isGroup': true,
        'size': this.getSwimlaneSize(swimlane.Height, maxWidthSwimlane),
        'loc': this.getSwimlaneLocation(model.Swimlanes, index),
        'category': 'swimlane',
        'index': index, //used to round the swimlanes correctly
        'swimlaneTotalCount': model.Swimlanes.length,
        'dataModel': swimlane,
      };
      nodeList.push(node);
    });

    return nodeList;
  }

  /**
   * Returns the node name based on translation info
   * @param translationInfo
   * @private
   */
  private getNodeName(translationInfo: TranslationInfoDto): string {
    return this.translationService.translateInfo(translationInfo,
      this.isSettings, 0);
  }

  private getSwimlaneForStep(step: StepDto): string {
    if (step.SwimlanesID !== undefined)
      return 'S' + step.SwimlanesID;
    else
      return '';
  }

  private getStepLocation(step: StepDto): string {
    return step.LeftPosition + ' ' + step.TopPosition;
  }

  /**
   * Returns the location string of the swimlane, determined by the height of the previous swimlanes.
   * @param swimlanes
   * @param swimlaneIndex
   * @private
   */
  private getSwimlaneLocation(swimlanes: SwimlaneDto[], swimlaneIndex: number): string {
    let maxHeight = 0;
    for (let i = 0; i < swimlaneIndex; i++) {
      maxHeight += swimlanes[i].Height;
    }

    return '0 ' + maxHeight;
  }

  private getSwimlaneSize(height: number, maxWidth: number): string {
    return maxWidth + ' ' + height;
  }

  private getMaxWidthOfNodeInSwimlane(swimlaneSteps: StepDto[]): number {
    const stepWithMaxSize = maxBy(swimlaneSteps, (step) => {
      return step.LeftPosition;
    });
    if (stepWithMaxSize == undefined) {
      throw new Error('Step with max size not found');
    }

    return stepWithMaxSize?.LeftPosition;
  }

  /**
   * Returns the category for the node based on the step type
   * and possible pending/completed tasks
   * @param step
   * @private
   */
  private getCategory(step: StepDto): string {
    let category = '';
    switch (step.StepType) {
      case StepType.StartStep:
        category = 'start';
        break;
      case StepType.NormalStep:
        category = 'normal';
        break;
      case StepType.EndStep:
        category = 'end';
        break;
      case StepType.Gateway:
        category = 'gateway';
        break;
      case StepType.AssessmentGateway:
        category = 'assessmentgateway';
        break;
      case StepType.AssessmentStep:
        category = 'assessment';
        break;
      case StepType.PublicationStep:
        category = 'publication';
        break;
      case StepType.DistributionStep:
        category = 'distribution';
        break;
      case StepType.TrainingAppAssessmentStep:
        category = 'trainingappassessment';
        break;
      case StepType.TrainingAppSubscribeToExamStep:
        category = 'trainingappsubscribetoexam';
        break;
      case StepType.TrainingAppExaminationStep:
        category = 'trainingappexamination';
        break;
      case StepType.TrainingAppPublishExamStep:
        category = 'trainingapppublishexam';
        break;
      default:
        throw new Error('undefined step type');
    }
    return category;
  }

  /**
   * returns the status of a step
   * Can be expiredTask, openTask, timedTask, cancelledTask, completedTask
   * @param step
   * @param pendingTask pending task linked to step
   * @param completedTask completed task linked to step
   * @private
   */
  private getStatusCategory(step: StepDto,
                            pendingTask: FlowStatusTaskDto | undefined,
                            completedTask: FlowStatusTaskDto | undefined): string {
    if (pendingTask != undefined) {
      switch (pendingTask.TaskStateType) {
        case TaskStateType.Expired:
          return 'expiredTask';
        case TaskStateType.Open:
          return 'openTask';
        case TaskStateType.Timed:
          return 'timedTask';
        default:
          return '';
      }
    } else if (completedTask != undefined) {
      // if there are no pending steps, look at the completed steps ( = completed / cancelled)
      switch (completedTask.TaskStateType) {
        case TaskStateType.Cancelled:
          return 'cancelledTask';
        case TaskStateType.Completed:
          return 'completedTask';
        default:
          return '';
      }
    }
    return '';
  }

  /**
   * check if the step can have a status
   * @param step
   * @returns true if step can have a status
   */
  private stepHasStatus(step: StepDto): boolean {
    return step.StepType === StepType.NormalStep
      || step.StepType === StepType.AssessmentStep
      || step.StepType === StepType.PublicationStep
      || step.StepType === StepType.DistributionStep
      || step.StepType === StepType.StartStep
      || step.StepType === StepType.EndStep
      || step.StepType === StepType.TrainingAppAssessmentStep
      || step.StepType === StepType.TrainingAppSubscribeToExamStep
      || step.StepType === StepType.TrainingAppExaminationStep
      || step.StepType === StepType.TrainingAppPublishExamStep;
  }

  /**
   * returns an icon name (string) based on the step type
   */
  private getIconnameByStepType(stepType: StepType,
                                isExternalStep: boolean): string {
    if (isExternalStep) {
      return 'up-right-from-square';
    }
    switch (stepType) {
      case StepType.StartStep:
      case StepType.NormalStep:
      case StepType.EndStep:
      case StepType.Gateway:
      case StepType.AssessmentGateway:
        return '';
      case StepType.TrainingAppSubscribeToExamStep:
        return 'pen-plus';
      case StepType.TrainingAppExaminationStep:
        return 'pen';
      case StepType.AssessmentStep:
        return 'file-check';
      case StepType.TrainingAppPublishExamStep:
      case StepType.PublicationStep:
        return 'envelop2';
      case StepType.DistributionStep:
        return 'share3';
      case StepType.TrainingAppAssessmentStep:
        return 'file-check';
      default:
        throw new Error('undefined step type');
    }
  }

  // --- End node data ---

  // --- Link data ----
  private generateLinkDataList(links: LinkDto[]): go.List<go.ObjectData> {
    const linkArray = new go.List<go.ObjectData>();

    for (let i = 0; i < links.length; i++) {
      const link: BizzFlowLinkData = {
        'from': links[i].FromStepVersionsID,
        'to': links[i].ToStepVersionsID,
        'toType': links[i].ToStepType,
        'fromPort': this.getPortString(links[i].FromPort),
        'toPort': this.getPortString(links[i].ToPort),
        'category': this.getLinkCategory(links[i].LinkType),
        'dataModel': links[i],
      };
      linkArray.push(link);
    }

    return linkArray;
  }

  private getPortString(fromPort: PortType): 'In' | 'Out' | 'OutBack' | '' {
    switch (fromPort) {
      case PortType.Left:
        return 'In';
      case PortType.Right:
        return 'Out';
      case PortType.Under:
        return 'OutBack';
      default:
        return '';
    }
  }

  private getLinkCategory(linkType: LinkType): 'backward' | 'forward' {
    switch (linkType) {
      case LinkType.Backward:
        return 'backward';
      default:
        return 'forward';
    }
  }

  // --- End link data ----
  //TODO: RV Implement functions to map from flow to data model
  //Needed to create new flows.

  // --------------- END MAP TO FLOW ------------------------

  // --------------- START MAP FROM FLOW ------------------------


  // private mapFromFlow(dataModel, collectionFormsID: number) {
  //   var links = dataModel.linkDataArray;
  //   var nodes = dataModel.nodeDataArray;
  //   var data = dataModel.dataModel;
  //
  //   data.Links = mapLinksFromFlow(links);
  //   data.Swimlanes = mapSwimlanesFromFlow(nodes);
  //   data.Steps = mapStepsFromFlow(nodes);
  //
  //   if (collectionFormsID > 0)
  //     setAllStepsWithFormIfOneForm(data.Steps, collectionFormsID);
  //
  //   if (dataModel.DeletedNodes !== undefined)
  //     addRemovedNodesToDataModel(dataModel, data);
  //
  //   if (dataModel.deletedActions !== undefined)
  //     mapDeletedActions(dataModel.deletedActions, data.Steps);
  //
  //   data.CollectionsID = (collectionsID === undefined ? dataModel.dataModel.CollectionsID : collectionsID);
  //   data.MethodType = (methodType === undefined ? dataModel.dataModel.MethodType : methodType);
  //
  //   data.TemplatesID = templatesID;
  //
  //   return data;
  // }
  //
  // private setAllStepsWithFormIfOneForm(steps: StepDto[], collectionFormsID: number): void {
  //   steps.forEach((step) => {
  //     if (step.CollectionFormsID == 0)
  //       step.CollectionFormsID = collectionFormsID;
  //   });
  // }
  //
  //
  // // ---- START MAP LINKS TO DB MODEL -----
  //
  // private mapLinksFromFlow(links) {
  //   let linkArray = [];
  //
  //   links.forEach((link) => {
  //     const newLink = {
  //       ID: (link.dataModel === undefined ? 0 : link.dataModel.ID),
  //       FlowVersionsID: (link.dataModel === undefined ? 0 : link.dataModel.FlowVersionsID),
  //       LinkType: getLinkType(link),
  //       FromStepVersionsID: _.cloneDeep(link.from),
  //       ToStepVersionsID: _.cloneDeep(link.to),
  //       ToStepType: _.cloneDeep(link.toType),
  //       FromPort: getLinkPortType(link.fromPort),
  //       ToPort: getLinkPortType(link.toPort),
  //       State: UpdateDeleteState.Update,
  //       Conditions: (link.dataModel === undefined ? undefined : link.dataModel.Conditions),
  //       CatchAll: (link.dataModel === undefined ? false : _.cloneDeep(link.dataModel.CatchAll)),
  //     };
  //
  //     linkArray.push(newLink);
  //   });
  //
  //   return linkArray;
  // };
  //
  // private mapDeletedLinksFromFlow(link):LinkDto {
  //   return {
  //     ID: (link.dataModel === undefined ? 0 : _.cloneDeep(link.dataModel.ID)),
  //     FlowVersionsID: (link.dataModel === undefined ? 0 : _.cloneDeep(link.dataModel.FlowVersionsID)),
  //     LinkType: getLinkType(link),
  //     FromStepVersionsID: _.cloneDeep(link.from),
  //     ToStepVersionsID: _.cloneDeep(link.to),
  //     FromPort: getLinkPortType(link.fromPort),
  //     ToPort: getLinkPortType(link.toPort),
  //     State: UpdateDeleteState.Delete
  //   };
  // }
  //
  // private getLinkType(link): LinkType {
  //   switch (link.category) {
  //     case "backward":
  //       return LinkType.Backward;
  //     case "forward":
  //       return LinkType.Forward;
  //   }
  // }
  //
  // private getLinkPortType(link: "In" | "Out" | "OutBack" | string): PortType {
  //   switch (link) {
  //     case "In":
  //       return PortType.Left;
  //     case "Out":
  //       return PortType.Right;
  //     case "OutBack":
  //       return PortType.Under;
  //     default:
  //       return PortType.None;
  //   }
  // }
  //
  // private addRemovedNodesToDataModel(dataModel, data) {
  //   dataModel.DeletedNodes.forEach((node) => {
  //     if (node === null || node.key < 0)
  //       continue;
  //
  //     // if node doesn't contain category it's a link
  //     if (node.category === undefined || node.category === "") {
  //       data.Links.push(this.mapDeletedLinksFromFlow(node));
  //       continue;
  //     }
  //
  //     if (node.category === "swimlane") {
  //       const neg = node.key.substring(1, 2);
  //       // check if deleted swimlane is already in db
  //       if (neg === "-")
  //         continue;
  //     } else {
  //       // check if deleted node is already in db
  //       if (node.key < 0)
  //         continue;
  //     }
  //
  //     node.State = UpdateDeleteState.Delete;
  //     switch (node.category) {
  //       case "swimlane":
  //         const swimlane = this.mapDeletedSwimlaneFromFlow(node, dataModel);
  //         data.Swimlanes.push(swimlane);
  //         break;
  //       case "forward":
  //       case "backward":
  //         const link = mapDeletedLinkFromFlow(node);
  //         data.Links.push(link);
  //         break;
  //       default:
  //         const step = this.mapDeletedStepFromFlow(node, dataModel.nodeDataArray);
  //         data.Steps.push(step);
  //         break;
  //     }
  //
  //   });
  // }
  //
  // // ---- END MAP LINKS TO DB MODEL -----
  //
  // // ---- START MAP SWIMLANES TO DB MODEL -----
  //
  // private mapSwimlanesFromFlow(nodes) {
  //   const swimlanes = [];
  //
  //   nodes.forEach((node) => {
  //     if (node.category == 'swimlane') {
  //       swimlanes.push({
  //         ID: (node.dataModel === undefined ? 0 : this.getSwimlaneIDFromFlow(_.cloneDeep(node.dataModel.ID))),
  //         FlowVersionsID: (node.dataModel === undefined ? 0 : _.cloneDeep(node.dataModel.FlowVersionsID)),
  //         SortIndex: this.getSortIndexForSwimlaneNode(node, nodes),
  //         Height: this.getHeightForSwimlaneNode(node),
  //         State: UpdateDeleteState.Update,
  //         TranslationInfo: this.getTranslationInfo(node),
  //         TempID: (node.dataModel === undefined ? 0 : _.cloneDeep(node.dataModel.ID))
  //       });
  //     }
  //   });
  //
  //   return swimlanes;
  // }
  //
  // private getSwimlaneIDFromFlow(swimlaneID: string): number {
  //   //TODO: RV investigate old logic , swimlaneID is string  or a number?
  //   throw new Error("Not implemented");
  //   /*if (swimlaneID > 0 || swimlaneID < 0)
  //     return swimlaneID;
  //
  //   if (swimlaneID.substring(0, 1) === "S")
  //     return parseInt(swimlaneID.substring(1, swimlaneID.length));*/
  // }
  //
  // private mapDeletedSwimlaneFromFlow(node, nodes) {
  //    return {
  //     ID: (node.dataModel === undefined ? 0 : _.cloneDeep(node.dataModel.ID)),
  //     FlowVersionsID: (node.dataModel === undefined ? 0 : _.cloneDeep(node.dataModel.FlowVersionsID)),
  //     SortIndex: this.getSortIndexForSwimlaneNode(node, nodes),
  //     Height: this.getHeightForSwimlaneNode(node),
  //     State: UpdateDeleteState.Delete,
  //     TranslationInfo: this.getTranslationInfo(node)
  //   };
  // }
  //
  // private mapDeletedActions(actions, steps): void {
  //   for (let i = 0; i < actions.length; i++) {
  //     if (actions.ActionsID < 0)
  //       continue;
  //
  //     for (let j = 0, stepsLength = steps.length; j < stepsLength; j++) {
  //       if (steps[j].StepVersionsID === actions[i].StepVersionsID)
  //         steps[j].Actions.push(actions[i]);
  //     }
  //   }
  // }
  //
  // private getSortIndexForSwimlaneNode(swimlane, nodes) {
  //   let index = 0;
  //   for (let i = 0; i < nodes.length; i++) {
  //     if (nodes[i].category == 'swimlane' && nodes[i] != swimlane)
  //       index++;
  //
  //     if (nodes[i] == swimlane) {
  //       return index;
  //     }
  //   }
  // }
  // private getHeightForSwimlaneNode(swimlane): number {
  //   let substring = swimlane.size.split(" ");
  //   return parseInt(substring[1]);
  // }
  //
  // private getTranslationInfo(item) {
  //   return (item.dataModel === undefined ?
  //     item.name : item.dataModel.TranslationInfo);
  // }
  //
  // // ---- END MAP SWIMLANES TO DB MODEL -----
  //
  // // ---- START MAP STEPS TO DB MODEL -----
  //
  // private mapStepsFromFlow(nodes) {
  //   let steps = [];
  //
  //   for (var i = 0; i < nodes.length; i++) {
  //     if (nodes[i].category !== 'swimlane') {
  //
  //       let step = {
  //         ID: nodes[i].dataModel.ID,
  //         StepVersionsID: nodes[i].key,
  //         FlowsID: nodes[i].dataModel.FlowsID,
  //         FlowVersionsID: nodes[i].dataModel.FlowVersionsID,
  //         SwimlanesID: this.getSwimlaneIDForStep(nodes[i], nodes),
  //         StepType: this.getStepType(nodes[i]),
  //         LeftPosition: this.getLeftPosition(nodes[i]),
  //         TopPosition: this.getTopPosition(nodes[i]),
  //         TranslationInfo: this.getTranslationInfo(nodes[i]),
  //         //State: nodes[i].dataModel.State,
  //         State: UpdateDeleteState.Update,
  //         Actions: getActionsForStep(nodes[i]),
  //         TempStepVersionsID: nodes[i].dataModel.TempStepVersionsID,
  //         CompletedPreviousStepType: nodes[i].dataModel.CompletedPreviousStepType,
  //         StartNextStepType: nodes[i].dataModel.StartNextStepType,
  //         CollectionFormsID: nodes[i].dataModel.CollectionFormsID,
  //         AllowAnonymous: nodes[i].dataModel.AllowAnonymous,
  //         PreviousAllowAnonymous: nodes[i].dataModel.PreviousAllowAnonymous,
  //         AnonymousCollectionFormsID: nodes[i].dataModel.AnonymousCollectionFormsID,
  //         DocSkillExpiration: nodes[i].dataModel.DocSkillExpiration
  //       };
  //
  //       //gateway, map RestartFlowLink
  //       if (step.StepType == StepType.Gateway || step.StepType == StepType.AssessmentGateway) {
  //         step.RestartFlowLink = nodes[i].dataModel.RestartFlowLink;
  //       }
  //       steps.push(step);
  //     }
  //   }
  //
  //   return steps;
  // }
  //
  // private mapDeletedLinkFromFlow(link) {
  //   return {
  //     ID: (link.dataModel === undefined ? 0 : _.cloneDeep(link.dataModel.ID)),
  //     FlowVersionsID: (link.dataModel === undefined ? 0 : _.cloneDeep(link.dataModel.FlowVersionsID)),
  //     LinkType: this.getLinkType(link),
  //     FromStepVersionsID: _.cloneDeep(link),
  //     ToStepVersionsID: _.cloneDeep((link.to),
  //     FromPort: this.getLinkPortType(link.fromPort),
  //     ToPort: this.getLinkPortType(link.toPort),
  //     State: UpdateDeleteState.Delete
  //   };
  // }
  //
  // private mapDeletedStepFromFlow(node, nodes){
  //   return {
  //     ID: (node.dataModel === undefined ? 0 : _.cloneDeep(node.dataModel.ID)),
  //     StepVersionsID: (node.dataModel === undefined ? 0 : _.cloneDeep(node.key)),
  //     FlowsID: (node.dataModel === undefined ? 0 : _.cloneDeep(node.dataModel.FlowsID)),
  //     FlowVersionsID: (node.dataModel === undefined ? 0 : _.cloneDeep(node.dataModel.FlowVersionsID)),
  //     SwimlanesID: this.getSwimlaneIDForStep(node, nodes),
  //     StepType: this.getStepType(node),
  //     LeftPosition: this.getLeftPosition(node),
  //     TopPosition: this.getTopPosition(node),
  //     TranslationInfo: this.getTranslationInfo(node),
  //     State: UpdateDeleteState.Delete,
  //     Actions: getActionsForStep(node)
  //   };
  // }
  // private getSwimlaneIDForStep(step, nodes) {
  //   if (step.group === undefined)
  //     this.findSwimlaneForNode(step, nodes);
  //   return step.group.substring(1, step.group.length);
  // }
  //
  // private getStepType (step) : StepType {
  //   switch (step.category) {
  //     case "start":
  //       return StepType.StartStep;
  //     case "normal":
  //       return StepType.NormalStep;
  //     case "end":
  //       return StepType.EndStep;
  //     case "gateway":
  //       return StepType.Gateway;
  //     case "assessmentgateway":
  //       return StepType.AssessmentGateway;
  //     case "assessment":
  //       return StepType.AssessmentStep;
  //     case "publication":
  //       return StepType.PublicationStep;
  //     case "distribution":
  //       return StepType.DistributionStep;
  //     case "trainingappassessment":
  //       return StepType.TrainingAppAssessmentStep;
  //     case "trainingappsubscribetoexam":
  //       return StepType.TrainingAppSubscribeToExamStep;
  //     case "trainingappexamination":
  //       return StepType.TrainingAppExaminationStep;
  //     case "trainingapppublishexam":
  //       return StepType.TrainingAppPublishExamStep;
  //     default:
  //       throw "Step type not defined";
  //   }
  // }
  //
  // private getLeftPosition(step): number {
  //   var splitString = step.loc.split(" ");
  //   return parseInt(splitString[0]);
  // }
  //
  // private getTopPosition(step): number {
  //   var splitString = step.loc.split(" ");
  //   return parseInt(splitString[1]);
  // }
  //
  // private getActionsForStep(step) {
  //   return (step.dataModel === undefined ? [] : step.dataModel.Actions);
  // }
  //
  // private findSwimlaneForNode(node, nodes) {
  //   let swimlanesHeight = 0;
  //   let nodeHeight = this.getTopPosition(node);
  //   let swimlanekey;
  //   for (let i = 0; i < nodes.length; i++) {
  //     if (nodes[i].category !== "swimlane")
  //       continue;
  //
  //     swimlanesHeight += this.getHeightForSwimlaneNode(nodes[i]);
  //
  //     if (nodeHeight < swimlanesHeight) {
  //       node.group = nodes[i].key;
  //       swimlanekey = nodes[i].key;
  //       break;
  //     }
  //   }
  //
  //   return swimlanekey;
  // };

  // ---- END MAP STEPS TO DB MODEL -----


  // --------------- END MAP FROM FLOW ------------------------

}