import { Component } from '@angular/core';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'bizz-grid-cell-canvas',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './grid-cell-canvas.component.html',
  styleUrl: './grid-cell-canvas.component.scss'
})
export class GridCellCanvasComponent extends GridCellBaseComponent<string> {

}
