import moment from 'moment';
import { CronGenState } from "../interfaces/crongen/cron-gen-state";
import { CronGenMinutes } from "../interfaces/crongen/cron-gen-minutes";
import { CronGenHourly } from "../interfaces/crongen/cron-gen-hourly";
import { CronGenDaily } from "../interfaces/crongen/cron-gen-daily";
import { CronGenWeekly } from "../interfaces/crongen/cron-gen-weekly";
import { CronGenMonthly } from "../interfaces/crongen/cron-gen-monthly";
import { CronGenYearly } from "../interfaces/crongen/cron-gen-yearly";

export class CronGenClass implements CronGenState{
  public minutes: CronGenMinutes;
  public hourly: CronGenHourly;
  public daily: CronGenDaily;
  public weekly: CronGenWeekly;
  public monthly: CronGenMonthly;
  public yearly: CronGenYearly;
  public advanced: {
    expression: string;
  };

  private defaultDateTime: Date = moment(moment().hours(1).minutes(0).seconds(0)).toDate();
  
  public constructor(private use24HourTime: boolean) {
   
 
      this.minutes= {
        minutes: 1,
        seconds: 0
      },
      this.hourly= {
        hours: 1,
        minutes: 0,
        seconds: 0
      },
      this.daily= {
        subTab: 'everyDays',
        everyDays: {
          days: 1,
          datetime: this.defaultDateTime,
          hours: 1,
          minutes: 0,
          seconds: 0,
          hourType: use24HourTime ? null : 'AM'
        },
        everyWeekDay: {
          datetime: this.defaultDateTime,
          hours: 1,
          minutes: 0,
          seconds: 0,
          hourType: use24HourTime ? null : 'AM'
        }
      },
      this.weekly= {
        MON: true,
        TUE: false,
        WED: false,
        THU: false,
        FRI: false,
        SAT: false,
        SUN: false,
        datetime: this.defaultDateTime,
        hours: 1,
        minutes: 0,
        seconds: 0,
        hourType: use24HourTime ? null : 'AM'
      },
      this.monthly= {
        subTab: 'specificDay',
        specificDay: {
          day: '1',
          months: 1,
          datetime: this.defaultDateTime,
          hours: 1,
          minutes: 0,
          seconds: 0,
          hourType: use24HourTime ? null : 'AM'
        },
        specificWeekDay: {
          monthWeek: '#1',
          day: 'MON',
          months: 1,
          datetime: this.defaultDateTime,
          hours: 1,
          minutes: 0,
          seconds: 0,
          hourType: use24HourTime ? null : 'AM'
        }
      },
      this.yearly= {
        subTab: 'specificMonthDay',
        specificMonthDay: {
          month: 1,
          day: '1',
          datetime: this.defaultDateTime,
          hours: 1,
          minutes: 0,
          seconds: 0,
          hourType: use24HourTime ? null : 'AM'
        },
        specificMonthWeek: {
          monthWeek: '#1',
          day: 'MON',
          month: 1,
          datetime: this.defaultDateTime,
          hours: 1,
          minutes: 0,
          seconds: 0,
          hourType: use24HourTime ? null : 'AM'
        }
      },
      this.advanced= {
        expression: '0 15 10 L-2 * ?'
      }
  }
}