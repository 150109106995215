<div>
  {{ task.CompletedBy }}
  <span
    *ngIf="task.CompletedOnBehalfOf &&
              task.CompletedOnBehalfOf !== task.CompletedBy">
                                      {{"OnBehalfOf" | translate}} {{ task.CompletedOnBehalfOf}}
              </span>
  <span
    *ngIf="((task.EndPointType == HostType.API) || (task.CompletedOnBehalfOfType == OrganizationChartItemType.API)) ">
                                    ({{"API" | translate}})
                                </span>
  <span *ngIf="task.Autocompleted">
                                   {{"Autocompleted" | translate}}
            </span>
  <span *ngIf="!task.CompletedOnBehalfOf && !task.CompletedBy">-</span>
  <span *ngIf="task.IsForwarded">
     <b>Forwarded to {{task.UserForwarded}} </b>
  </span>
</div>