import { createReducer, on } from '@ngrx/store';
import { trainingActions } from './training-actions';

import { initialTrainingStoreState } from './initial-training-store-state';
import { createEntityAdapter } from '@ngrx/entity';
import { TrainingStoreStateDto } from './training-state';


export const trainingAdapter =
  createEntityAdapter<TrainingStoreStateDto>();

export const trainingReducer = createReducer(
  initialTrainingStoreState,
  on(
    trainingActions.examFetched,
    (state, data) => {
      const updatedState = structuredClone(state) as TrainingStoreStateDto;
      updatedState.exam = data.exam;
      updatedState.config = data.config;
      return updatedState;
    }
  )
);



