@if (task) {
    <div class="mb-4 ml-8 mr-12 mt-0 flex text-xs">
        <div [ngClass]="{ truncate: task.reasonCollapsed }"
             id="understood-remark-{{ task.TasksID }}"
             class="grey-darkest text-center">
            {{ task.ReadAndUnderstoodReason }}
        </div>
        <div class="ml-auto pl-2" (click)="task.reasonCollapsed = !task.reasonCollapsed">
            @if (task.reasonCollapsed) {
                <bizz-icon [hover]="true" class="ml-auto block h-6 w-6 text-button md:ml-0 md:h-4 md:w-4"
                           iconName="chevron-down">
                </bizz-icon>
            } @else if (!task.reasonCollapsed && isCollapsible) {
                <bizz-icon [hover]="true" class="ml-auto block h-6 w-6 text-button md:ml-0 md:h-4 md:w-4"
                           iconName="chevron-up">
                </bizz-icon>
            }
        </div>
    </div>
}
