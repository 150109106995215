import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { CronGenTimeSelectComponent, CronGenTimeType } from '../cron-gen-time-select/cron-gen-time-select.component';
import { CronTimeHelpers, timeHelpers } from 'src/app/shared/constants/time-helpers';
import { CronGenService } from 'src/app/features/bizzmine/form/services/cron-gen.service';
import { CronGenMonthly } from 'src/app/features/bizzmine/form/interfaces/crongen/cron-gen-monthly';

@Component({
  selector: 'bizz-cron-monthly',
  standalone: true,
  imports: [
    TranslatePipe,
    DropDownsModule,
    CronGenTimeSelectComponent
  ],
  templateUrl: './cron-monthly.component.html',
  styleUrl: './cron-monthly.component.scss',
})
export class CronMonthlyComponent {
  public helpers: CronTimeHelpers = timeHelpers;
  @Input() public readOnly = false;

  @Input() public state: CronGenMonthly;

  public monthWeeks: Array<string>;
  public firstLast: Array<string>;
  @Output() public stateChange: EventEmitter<CronGenMonthly> = new EventEmitter<CronGenMonthly>();

  
  public constructor(public cronGenService: CronGenService) {
    this.monthWeeks = this.cronGenService.dropDownOptions.monthDaysWithLasts.map((monthDay) =>  this.cronGenService.monthDayDisplay(monthDay));
    this.firstLast = this.cronGenService.dropDownOptions.monthWeeks.map((monthWeek) => this.cronGenService.monthWeekLookups[monthWeek]);
  }


  public daysChange(state: string, subTab: 'specificDay'|'specificWeekDay') {
    if(subTab === 'specificDay'){ 
      this.state[subTab].day =  this.cronGenService.monthDayReverseDisplay(state);
    }else{
      this.state[subTab].day =  this.cronGenService.getKeyByValue(this.cronGenService.dayLookups, state) as string;
    }
    this.stateChange.emit(this.state);
  }

  public monthsChange(state: number, subTab: 'specificDay'|'specificWeekDay') {
    this.state[subTab].months = state;
    this.stateChange.emit(this.state);
  }
  
  public monthWeekChange(state: string){
    this.state.specificWeekDay.monthWeek = this.cronGenService.getKeyByValue(this.cronGenService.monthWeekLookups, state) as string;
    this.stateChange.emit(this.state);
  }

  public cronGenTimeChange(state: CronGenTimeType, subTab: 'specificDay'|'specificWeekDay') {
    Object.assign(this.state[subTab], state);
    this.stateChange.emit(this.state);
  }
}
