import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';

@Component({
  selector: 'bizz-cron-advanced',
  standalone: true,
  imports: [
    TranslatePipe,
    FormsModule
  ],
  templateUrl: './cron-advanced.component.html',
  styleUrl: './cron-advanced.component.scss',
})
export class CronAdvancedComponent{
  @Input() public readOnly = false;

  @Input() public state: string; // expression 
  @Output() public stateChange: EventEmitter<string> = new EventEmitter<string>();

public change($event: any) {
    this.state = $event.target.value;
    this.stateChange.emit($event.target.value);
  }

}
