import { CanActivateFn } from '@angular/router';
import { environment } from '../../../../environments/environment';

export const settingsGuard: CanActivateFn = (route, state) => {
  if (environment.deploymentType == 'onPremise') {
    window.location.href = environment.legacyUri + '/settings';
  } else {
    window.alert('Settings URL not configured for Cloud deployment.');
    //preview-ehs.angular.vivaldisaas.com --> preview-ehs.vivaldisaas.com/settings
  }
  return true;
};
