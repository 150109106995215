import { Component, Input,ChangeDetectionStrategy } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'bizz-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  imports: [
    NgIf,
    NgClass,
    AsyncPipe
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {

  @Input() public showLoader = false;
  @Input() public fullscreen = false;
  @Input() public backgroundClasses = 'bg-body';

}
