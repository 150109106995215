export interface OriginalFormat {
  Value: number;
  Text: string;
}

export interface NewFormat extends OriginalFormat {
  DisplayText: string;
}

export function mapToNewDelimiterTypes(originalData: OriginalFormat[]): NewFormat[] {
  return originalData.map((item) => {
    let displayText = `${item.Text} `;
    switch (item.Text.toLowerCase()) {
      case "point":
        displayText += "(.)";
        break;
      case "comma":
        displayText += "(,)";
        break;
      case "semicolon":
        displayText += "(;)";
        break;
        // Add more cases for other texts if needed
      default:
        displayText;
    }
    return {
      ...item,
      DisplayText: displayText,
    };
  });
}
