export class ControlledDocumentTemplateIntent {
  // used as a *value* placeholder for the file-upload component to know that it should start uploading based on a template
  public constructor(public selectedTemplate: {
    mediaId: number,
    sourceCollectionId: number,
    instanceId: number,
    versionId: number,
    targetCollection: number
  }) {
  }

}