import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../../../../ui/icon/icon.component';
import { TranslatePipe } from '../../../../../../pipes/translate/translate.pipe';
import { DatetimePipe } from '../../../../../../pipes/dates/datetime.pipe';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { CompareBaseModalComponent } from '../../compare-base-modal.component';
import { VersionType } from 'src/app/shared/enums/version-type';


/**
 * Modal that offers the user the choice to read the draft or published
 * version of an instance.
 */
@Component({
  selector: 'bizz-read-draft-or-published-modal',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslatePipe, DatetimePipe, TextAreaModule],
  templateUrl: './read-draft-or-published-modal.component.html',
  styleUrls: ['../../compare-base-modal.component.scss'],
})

export class ReadDraftOrPublishedModalComponent extends CompareBaseModalComponent {
  public read(event: MouseEvent, type: VersionType): void {
    const newTab = event && (event.ctrlKey || event.metaKey);
    const version = this.getVersionIdByType(type);

    if (typeof version !== 'number') {
      throw new Error('No valid version found');
    }
    
    this.modalData.onSelectVersionType(version, newTab);
  }
}