@if (formFieldSignal(); as formField) {
    <div class="input-button-group flex" #input [ngClass]="{'hidden' : formField.IsHidden}">
        <input [readonly]="readOnlyOverride || formField.IsReadOnly"
[tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0" [formControl]="formControl"
               placeholder="{{formField.Placeholder}}" id="{{formField.Id}}" type="url">
        <div class="button-group">
            <button [disabled]="disableLinkButton || formField.IsReadOnly || readOnlyOverride" class="default !px-2 !py-1" type="button" (click)="navigateToLink()">
                <bizz-icon class="h-4 w-4" iconName="globe">
                </bizz-icon>
            </button>
        </div>
    </div>
}
