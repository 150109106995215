import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { formsActions } from '../forms-actions';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, map, of } from 'rxjs';
import { selectForm, selectFormField } from '../forms-selectors';
import { CollectionFormEditApiService } from '../../../../api/bizzmine/collection-form-edit/collection-form-edit-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ReadOnlyPriority } from '../../../../features/bizzmine/form/enums/read-only-priority.enum';

@Injectable()
export class FormsEntityEffects {
  private actions$ = inject(Actions);
  private store$ = inject(Store);
  // TODO: add checks to see if form has entity
  /**
   * Clears the entity field if registeredOnBehalfOf is empty.
   */
  public clearEntity$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateRegisteredOnBehalfOfFieldValueWithEntity),
    concatLatestFrom(({
                        formId,
                        fieldId
                      }) => this.store$.select(selectFormField(formId, fieldId))),
    filter(([props, field]) => field !== undefined && field.OrgChartUnitSelector.length == 0),
    map(([props, field]) => {
      if (props.entityField) {
        return formsActions.lookupCleared({
          formId: props.formId,
          viewDataSourceId: props.entityField.ViewDataSourcesID,
        });
      } else return formsActions.entityFieldNotFound({ formId: props.formId });
    })
  ));
  private collectionFormEditApiService = inject(CollectionFormEditApiService);
  /**
   * Updates the entity field if registeredOnBehalfOf value changes (not including empty).
   */
  public updateEntity$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateRegisteredOnBehalfOfFieldValueWithEntity),
    concatLatestFrom(({
                        formId,
                        fieldId
                      }) => [this.store$.select(selectForm(formId)), this.store$.select(selectFormField(formId, fieldId))]),
    filter(([props, form, field]) => field !== undefined && field.OrgChartUnitSelector.length == 1),
    exhaustMap(([props, form, field]) => this.collectionFormEditApiService.getEntityOnBehalfOfChange(form!.data).pipe(
      map((entity) => {
        if (props.entityField) {
          if (entity) {
            return formsActions.lookupChanged({
              formId: props.formId,
              lookupFieldId: props.entityField.Id,
              lookupItem: {
                InstancesID: entity.InstanceId,
                OriginalChildInstancesID: 0,
                Text: entity.Name,
                VersionsID: entity.VersionId,
                CrossLinkInstancesID: entity.DataDesignCrossID,
                LinkedToParentVersions: []
              }
            });
          } else {
            return formsActions.lookupCleared({
              formId: props.formId,
              viewDataSourceId: props.entityField.ViewDataSourcesID,
            });
          }
        } else return formsActions.entityFieldNotFound({ formId: props.formId });
      }),
      catchError((error: HttpErrorResponse) => of({
        type: formsActions.entityUpdateFailed.type,
        formId: props.formId,
        response: error
      }))
    ))
  ));
}