import { Component, DestroyRef, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslatePipe } from '../../../pipes/translate/translate.pipe';
import { KeyCode } from '../../../enums/key-code';
import { asyncScheduler, lastValueFrom } from 'rxjs';
import { UserSettingChangedEvent } from '../../../../../models/ts/user-setting-changed-event';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserSettingsApiService } from '../../../../api/bizzmine/user-settings/user-settings-api.service';
import { SignalRService } from '../../../../core/signalR/signal-r.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'bizz-two-factor-token',
  standalone: true,
  imports: [
    TranslatePipe,
    FormsModule
  ],
  templateUrl: './two-factor-token.component.html',
  styleUrl: './two-factor-token.component.scss'
})
export class TwoFactorTokenComponent {
  public constructor(
    private settingsApi: UserSettingsApiService,
    private signalRService: SignalRService,
    private destroyRef: DestroyRef) {
    this.signalRService.filterByType(UserSettingChangedEvent, 'UserSettingChangedEvent').pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.reCheckTwoFactorSetup());
  }
  @ViewChild('input1', { static: false }) private input1Ref: ElementRef<HTMLInputElement>;
  @ViewChild('input2', { static: false }) private input2Ref: ElementRef<HTMLInputElement>;
  @ViewChild('input3', { static: false }) private input3Ref: ElementRef<HTMLInputElement>;
  @ViewChild('input4', { static: false }) private input4Ref: ElementRef<HTMLInputElement>;
  @ViewChild('input5', { static: false }) private input5Ref: ElementRef<HTMLInputElement>;
  @ViewChild('input6', { static: false }) private input6Ref: ElementRef<HTMLInputElement>;
  public tokenSegments: string[] = ['', '', '', '', '', ''];
  public twoFactorSecret: string = '';
  @Input({required: true}) public hasTwoFactorSecret: boolean;
  @Output() public confirm = new EventEmitter<string>();

  public updateToken(segmentIndex: number, event: any): void {
    if (event.key == KeyCode.BACKSPACE) {
      if (this.tokenSegments[segmentIndex] == '') {
        this.getPrevInputRef(segmentIndex).nativeElement.focus();
        return;
      }
      this.tokenSegments[segmentIndex] = '';
    }
    if (isNaN(Number(event.target.value))) {
      this.tokenSegments[segmentIndex] = '';
      return;
    }
    const newSegment = event.target.value;
    // Ensure only one digit (0-9) is entered
    this.tokenSegments[segmentIndex] = newSegment;
    this.twoFactorSecret = this.tokenSegments.join('');
    if (newSegment.length === 1 && segmentIndex < 5) {
      this.getNextInputRef(segmentIndex).nativeElement.focus();
    }
    if (segmentIndex == 5) {
      this.confirm.emit(this.twoFactorSecret);
    }
  }

  public reCheckTwoFactorSetup(): void {
    lastValueFrom(this.settingsApi.getCheckTwoFactor()).then(result => this.hasTwoFactorSecret = result);
  }

  public onPaste(event: ClipboardEvent): void {
    if (event && event.clipboardData) {
      const pastedData = (event.clipboardData).getData('text');
      asyncScheduler.schedule(() => {
        for (let i = 0; i < pastedData.length; i++) {
          this.updateToken(i, { target: { value: pastedData[i] } });
        }
      });
    }
  }
  public getNextInputRef(segmentIndex: number): ElementRef<HTMLInputElement> {
    switch (segmentIndex) {
      case 0:
        return this.input2Ref;
      case 1:
        return this.input3Ref;
      case 2:
        return this.input4Ref;
      case 3:
        return this.input5Ref;
      case 4:
        return this.input6Ref;
      default:
        return this.input1Ref;
    }
  }

  public getPrevInputRef(segmentIndex: number): ElementRef<HTMLInputElement> {
    switch (segmentIndex) {
      case 2:
        return this.input2Ref;
      case 3:
        return this.input3Ref;
      case 4:
        return this.input4Ref;
      case 5:
        return this.input5Ref;
      case 6:
        return this.input6Ref;
      default:
        return this.input1Ref;
    }
  }
}
