@if(this.model.Groups && !loading){
    <div class="modal-container">
        <!-- Close -->
        <div class="modal-close-container">
            <bizz-icon (click)="dialogRef.close()" iconName="xmark" [hover]="true"></bizz-icon>
        </div>

        <!-- Body -->
        <div class="modal-body overflow-hidden">
            <!-- Header -->
            <div class="modal-header">
                <h1>{{'SearchFilter' | translate}}</h1>
            </div>

            <hr class="w-full bg-divider my-5">

            <!-- Filter Selection -->
            @if (model.FilterList){
                <div class="grid grid-cols-10 py-2.5 items-center">
                    <h3 class="col-span-3 text-base font-bold">{{"Filters" | translate}}</h3>
                    <kendo-dropdownlist class="col-span-7"
                                        [valuePrimitive]="true"
                                        [data]="model.FilterList"
                                        textField="Name"
                                        valueField="ID"
                                        [(ngModel)]="model.currentFilterID"
                                        (selectionChange)="filterChange($event)">
                    </kendo-dropdownlist>
                </div>
            }

            <ng-scrollbar class="w-full h-full scroll-filter transition-all">
                <bizz-filter-modal-item class="w-full h-full flex flex-col" [model]="model"></bizz-filter-modal-item>
            </ng-scrollbar>

        </div>
        <!-- Modal Buttons -->
        <div class="modal-buttons">
            <button class="default" type="button" (click)="clearFilter()">
                {{"Clear" | translate}}
            </button>
            <button class="ml-auto default" type="button"
                    (click)="resetFilterToDefault()">
                {{"ResetToDefaultFilter" | translate}}
            </button>
            <button type="button" class="save" (click)="closeDialog(true)">{{"Search" | translate}}</button>
        </div>
    </div>
}