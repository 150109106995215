<div class="flex h-full w-full flex-wrap items-center justify-center gap-5 overflow-y-auto">
    <div class="hidden items-center justify-center sm:flex">
        <img class="max-h-[16rem] w-auto min-w-96 lg:max-h-[24rem]"
             src="/assets/image/exam-splash.svg"
             alt="exam-splash"/>
    </div>
    <div class="place-self-center">
        <div class="flex flex-col items-center gap-10">
            <div class="w-full text-center">
                <div class="font-title text-2xl font-semibold leading-7">
                    {{ "Info_ExaminationCompleted" | translate }}
                </div>
                <span>{{ "YouMayCloseThisWindow" | translate }}</span>
            </div>
            @if(exam.Result.Score){
                <kendo-circularprogressbar [value]="exam.Result.Score.Percentage"
                                           [min]="0"
                                           [max]="100"
                                           [progressColor]="[{from: 0, to: 100, color: '#4A9E24'}]">
                    <ng-template kendoCircularProgressbarCenterTemplate>
                        <div class="text-default">
                            <span class="text-5xl font-bold">{{ exam.Result.Score.Percentage.toString() | decimal: 0 }}</span>
                            <span class="font-bold">%</span>
                        </div>
                        <div class="mt-2.5 text-widget-header">{{ exam.Result.Score.SessionScore }}
                            / {{ exam.Result.Score.MaxPossibleScore }}
                        </div>
                    </ng-template>
                </kendo-circularprogressbar>
            }
            <div class="text-center">
                @if (exam.Result.UserSkillGrade &&
                exam.Result.UserSkillGrade.Name != '') {
                    <p> {{ 'Skill' | translate }}:
                        <strong>{{ exam.Result.UserSkillGrade.Name }}</strong></p>
                }
                @if (exam.Result.UserSkillGrade &&
                exam.Result.UserSkillGrade.SkillGradeName != '') {
                    <p class="m-auto">{{ 'AchievedSkillGrade' | translate }}:
                        <strong>{{ exam.Result.UserSkillGrade.SkillGradeName }}</strong>
                    </p>
                }
            </div>
            

            @if (exam.ShowResultToExaminee == 2) {
                <button class="save w-fit"
                        (click)="viewReport.emit()">{{ "ViewReport" | translate }}
                </button>
            }
        </div>
    </div>
</div>

<div class="flex max-h-fit w-full justify-end">
    <button class="save" (click)="close()">
        {{ "CloseExam" | translate }}
    </button>
</div>