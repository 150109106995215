import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CollectionForm } from '../../../../../../models/ts/collection-form.model';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { NgClass } from '@angular/common';
import { CollectionFormEditApiService } from '../../../../../api/bizzmine/collection-form-edit/collection-form-edit-api.service';
import { take } from 'rxjs';

@Component({
  selector: 'bizz-file-upload-cancel-checkout-modal',
  standalone: true,
  imports: [
    IconComponent,
    TranslatePipe,
    NgClass
  ],
  templateUrl: './file-upload-cancel-checkout-modal.component.html',
  styleUrl: './file-upload-cancel-checkout-modal.component.scss'
})
export class FileUploadCancelCheckoutModalComponent {
  public FileUploadCancelCheckoutModalTypes = ['FileCheckedOut_NoStayOnForm', 'FileCheckedOut_YesKeepCheckOut', 'FileCheckedOut_YesCancelCheckout'] as const;
  public selectedType: string | null = null;

  public constructor(@Inject(DIALOG_DATA) public data: {
    form: CollectionForm
  }, public dialogRef: DialogRef, private collectionFormEditApiService: CollectionFormEditApiService) {

  }

  public confirm(): void {
    switch (this.selectedType) {
      case this.FileUploadCancelCheckoutModalTypes[0]:
        this.dialogRef.close(false);
        break;
      case this.FileUploadCancelCheckoutModalTypes[1]:
        this.dialogRef.close(true);
        break;
      case this.FileUploadCancelCheckoutModalTypes[2]:
        this.cancelCheckout();
        break;
      default:
        this.dialogRef.close(false);
    }
  }

  public cancelCheckout(): void {
    this.collectionFormEditApiService.undoCheckOut({
      versionId: this.data.form.VersionsID,
      instanceId: this.data.form.InstancesID,
      collectionId: this.data.form.CollectionsID
    })
      .pipe(take(1))
      .subscribe(() =>
        this.dialogRef.close(true)
      );

  }
}
