import { HybridSearchSourceDocument } from 'src/models/ts/hybrid-search-source-document';
import { CopilotWidgetMessageType } from './copilot-widget-message-type';
import { CopilotMessageActionType } from './copilot-message-action-type';
import { ChatResultStatus } from '../../../../../../models/ts/chat-result-status';

export interface CopilotWidgetMessage {
  id: number;
  rawContent?: string;
  content: string;
  status?: ChatResultStatus;
  type: CopilotWidgetMessageType;
  action: CopilotMessageActionType;
  references?: HybridSearchSourceDocument[];
  relevantDocuments?: HybridSearchSourceDocument[];
  followUpQuestions?: string[];
}

export class ChatStatusUpdated {
  conversationId: string;
  dateCreated: Date;
  status: string
}
export class TemporaryChatResult{
  conversationId: string;
  dateCreated: Date;
  messageId: string
  text: string
}

export class UserLoginSignalRMessage {

}

export class PasswordResetMessage {

}