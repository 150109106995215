import { Injectable } from '@angular/core';
import { FormFieldLocation } from '../classes/form-field-location';
import { isEmpty } from 'lodash';
import { Update } from '@ngrx/entity';
import { CollectionForm } from '../../../../../models/ts/collection-form.model';
import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';
import { CollectionFormAccordion } from '../../../../../models/ts/collection-form-accordion.model';
import { CollectionFormRow } from '../../../../../models/ts/collection-form-row.model';
import { AccordionStatusType } from '../../../../../models/ts/accordion-status-type.model';
import { StoreCollectionForm } from '../../../../store/features/forms/forms-state';
import { ViewDataSource } from '../../../../../models/ts/view-data-source.model';
import { CollectionFormFieldGridLookupData } from '../../../../../models/ts/collection-form-field-grid-lookup-data.model';
import { TableFieldDataType } from '../../../../../models/ts/table-field-data-type.model';
import { SchedulerDto } from 'src/models/ts/scheduler-dto.model';
import { FormFieldType } from 'src/models/ts/form-field-type.model';
import { Record } from 'src/models/ts/collection-form-field.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ViewDataSourcesInstance } from 'src/models/ts/view-data-sources-instance.model';
import { UpdateDeleteState } from 'src/models/ts/update-delete-state.model';
import { OrganizationChartItemType } from 'src/models/ts/organization-chart-item-type.model';
import { FORM_CONSTANTS } from '../constants/form-constants';
import { CollectionLockType } from '../../../../../models/ts/collection-lock-type.model';
import { ViewDataSourceService } from './view-data-source.service';
import { CollectionFormReadOnlyService } from './collection-form-read-only.service';
import { ReadOnlyPriority } from '../enums/read-only-priority.enum';
import { SkillIntegrityCheck } from '../../../../../models/ts/skill-integrity-check.model';
import { fieldDataTypeIsFocusable } from '../functions/field-is-focusable';
import { LinkedCollectionStorageType } from '../../../../../models/ts/linked-collection-storage-type.model';
import { LinkedCollectionType } from '../../../../../models/ts/linked-collection-type.model';
import { LookupItem } from '../../../../../models/ts/collection-list-summary-item.model';
import { LookupService } from '../../../../shared/services/lookup/lookup.service';
import { OrgChartItem } from '../../org-chart/interfaces/org-chart-item';
import { ProtectedFieldType } from '../../../../../models/ts/protected-field-type.model';
import { TrnFieldProperties } from '../../../../../models/ts/trn-field-properties-dto.model';

/**
 * Service that provides various (static) functions for common operations involving CollectionForms and it's fields.
 */
@Injectable({
  providedIn: 'root'
})
export class CollectionFormService {

  public static setFieldReadOnly(form: CollectionForm, fieldId: number, readOnly: boolean, readOnlyPriority: ReadOnlyPriority = ReadOnlyPriority.Design): CollectionForm {
    const field = CollectionFormService.getField(form, f => f.Id === fieldId);
    if (field) {
      CollectionFormReadOnlyService.setReadOnly(field, readOnly, readOnlyPriority);
      return form;
    } else {
      throw new Error(`Attempted to modify isReadOnly of field ${fieldId} but field was not found in form.`);
    }
  }

  public static setOrgChartValue(form: CollectionForm, fieldId: number, value: Array<OrgChartItem>, ignoreMissingField = false): CollectionForm {
    const field = CollectionFormService.getField(form, f => f.Id === fieldId);
    if (field) {
      field.State = value != field.OrgChartUnitSelector ? UpdateDeleteState.Update : field.State;
      field.OrgChartUnitSelector = value;
      return form;
    } else if (!ignoreMissingField) throw new Error(`Attempted to set value of field ${fieldId} but field was not found in form.`);
    else return form;
  }

  public static setGridOrgChartValue(form: CollectionForm, gridFieldId: number, recordId: number, recordFieldId: number, value: Array<OrgChartItem>, ignoreMissingData = false): CollectionForm {
    const record = CollectionFormService.getRecord(form, gridFieldId, recordId);
    if (record) {
      const field = CollectionFormService.getRecordField(record, field => field.Id == recordFieldId);
      if (field) {
        field.State = value != field.OrgChartUnitSelector ? UpdateDeleteState.Update : field.State;
        field.OrgChartUnitSelector = value;
        return form;
      } else if (!ignoreMissingData) throw new Error(`Attempted to set value of field ${recordFieldId} in record ${recordId} of grid field ${gridFieldId} but field was not found in record.`);
      else return form;
    } else if (!ignoreMissingData) throw new Error(`Attempted to set value of field ${recordFieldId} in record ${recordId} of grid field ${gridFieldId} but record was not found in grid.`);
    else return form;
  }

  public static setRecordEditMode(form: CollectionForm, fieldId: number, recordId: number, editMode: boolean): CollectionForm {
    const record = CollectionFormService.getRecord(form, fieldId, recordId);
    if (record) {
      record.EditMode = editMode;
      // TODO: refactor out the use of LookupService
      LookupService.enableDisableFields(record.Fields, form.ViewDataSources, record.RowDataDesignCrossID ?? record.CrossLinkedInstancesID);
      return form;
    } else {
      console.error(`Could not find record in form with field: ${fieldId}, record: ${recordId}`);
      return form;
    }
  }

  public static setRecordInstanceId(form: CollectionForm, fieldId: number, recordId: number, instanceId: number): CollectionForm {
    const record = CollectionFormService.getRecord(form, fieldId, recordId);
    if (record) {
      record.CrossLinkedInstancesID = instanceId;
      return form;
    } else {
      console.error(`Could not find record in form with field: ${fieldId}, record: ${recordId}`);
      return form;
    }
  }

  public static clearRecordData(form: CollectionForm, fieldId: number, recordId: number): CollectionForm {
    const record = CollectionFormService.getRecord(form, fieldId, recordId);
    const field = CollectionFormService.getField(form, field => field.Id === fieldId);
    const vds = form.ViewDataSources.find(v => v.ViewDataSourcesID == field?.ViewDataSourcesID);

    if (vds?.SingleOrMany == LinkedCollectionType.GridRecord) {
      if (record) {
        const parentDsFields = record?.Fields;
        record.Fields.forEach(rec => {
          if (!rec.IsCrossLinkedField && rec.ViewDataSourcesID == vds.ViewDataSourcesID) {
            rec = this.clearFieldValue(rec);
          }
        });
        LookupService.enableDisableFields(record.Fields, form.ViewDataSources);
      }
      return form;
    } else {
      console.error(`Could not find record in form with field: ${fieldId}, record: ${recordId}`);
      return form;
    }
  }

  public static clearFieldValue(field: CollectionFormField): CollectionFormField {
    switch (field.ComponentType) {
      case TableFieldDataType.HyperLink:
      case TableFieldDataType.AlphaNumeric:
      case TableFieldDataType.RadioGroup:
      case TableFieldDataType.Combobox:
      case TableFieldDataType.Email:
      case TableFieldDataType.SimpleList:
      case TableFieldDataType.TextValue:
      case TableFieldDataType.Memo:
      case TableFieldDataType.Numeric:
      case TableFieldDataType.TimePicker:
      case TableFieldDataType.DateTimePicker:
      case TableFieldDataType.DatePicker:
      case TableFieldDataType.OrganizationChartUnitSelector:
      case TableFieldDataType.EnumList:
        field.OrgChartUnitSelector = [];
        field.Value = '';
        break;
      case TableFieldDataType.ControlledDocument:
        field.Value = '';
        field.Text = '';
        break;
      case TableFieldDataType.Checkbox:
        field.Value = null;
        break;
    }

    if (field.ComponentType == TableFieldDataType.Numeric)
      field.Value = '';

    return field;
  }

  public static setFieldEnumValue(form: CollectionForm, fieldId: number, enumValue: number): CollectionForm {
    const field = CollectionFormService.getField(form, f => f.Id === fieldId);
    const enumList = field?.EnumList;
    if (field) {
      field.Value = enumList?.find(item => item.Value == enumValue)?.Text;
      field.State = enumValue != field.EnumValue ? UpdateDeleteState.Update : field.State;
      field.EnumValue = enumValue;
      return form;
    } else return form;
  }

  public static setFieldValue(form: CollectionForm, fieldId: number, value: unknown, ignoreMissingField = false): CollectionForm {
    const field = CollectionFormService.getField(form, f => f.Id === fieldId);
    if (field) {
      field.State = value != field.Value ? UpdateDeleteState.Update : field.State;
      field.Value = value;
      return form;
    } else if (!ignoreMissingField) throw new Error(`Attempted to set value of field ${fieldId} but field was not found in form.`);
    else return form;
  }

  public static setFieldSubscriberData(form: CollectionForm, fieldId: number, orgChart: Array<OrgChartItem>, users: Array<TrnFieldProperties>, deletedUsers: Array<TrnFieldProperties>): CollectionForm {
    const field = CollectionFormService.getField(form, f => f.Id === fieldId);
    if (field) {
      field.State = orgChart != field.OrgChartUnitSelector || users != field.TrnUsers || deletedUsers != field.TrnDeletedUsers ? UpdateDeleteState.Update : field.State;
      field.OrgChartUnitSelector = orgChart;
      field.TrnUsers = users;
      field.TrnDeletedUsers = deletedUsers;
      if(form.TrainingAssessment) {
        form.TrainingAssessment.DeletedUsers = deletedUsers?.map(d => d.UsersID) ?? [];
      }
      return form;
    }
    else return form;
  }


  public static setFieldCaption(form: CollectionForm, fieldId: number, value: unknown, ignoreMissingField = false): CollectionForm {
    const field = CollectionFormService.getField(form, f => f.Id === fieldId);
    if (field && value) {
      field.Caption = value.toString();
      return form;
    } else if (!ignoreMissingField) throw new Error(`Attempted to set caption of field ${fieldId} but field was not found in form.`);
    else return form;
  }

  public static setFieldValuePredicate(form: CollectionForm, predicate: (field: CollectionFormField) => boolean, value: unknown, ignoreMissingField = false): CollectionForm {
    const field = CollectionFormService.getField(form, predicate);
    if (field) {
      field.Value = value;
      return form;
    } else if (!ignoreMissingField) throw new Error(`Attempted to set value of field with predicate ${predicate} but field was not found in form.`);
    else return form;
  }

  public static deleteLock(form: CollectionForm): CollectionForm {
    form.ReadMode = false;
    form.IsLocked = false;
    form.LockType = CollectionLockType.Unlocked;
    //this.enableDisableFields(form);
    return form;
  }

  public static setGridFieldValue(form: CollectionForm, gridFieldId: number, recordId: number, recordFieldId: number, value: unknown, ignoreMissingData = false): CollectionForm {
    const record = CollectionFormService.getRecord(form, gridFieldId, recordId);
    if (record) {
      const field = CollectionFormService.getRecordField(record, field => field.CollectionFieldsID == recordFieldId);
      if (field) {
        field.State = value != field.Value ? UpdateDeleteState.Update : field.State;
        field.Value = value;
        return form;
      } else if (!ignoreMissingData) throw new Error(`Attempted to set value of field ${recordFieldId} in record ${recordId} of grid field ${gridFieldId} but field was not found in record.`);
      else return form;
    } else if (!ignoreMissingData) throw new Error(`Attempted to set value of field ${recordFieldId} in record ${recordId} of grid field ${gridFieldId} but record was not found in grid.`);
    else return form;
  }

  public static setGridFieldOrgChartValue(form: CollectionForm, gridFieldId: number, recordId: number, recordFieldId: number, value: Array<OrgChartItem>, ignoreMissingData = false): CollectionForm {
    const record = CollectionFormService.getRecord(form, gridFieldId, recordId);
    if (record) {
      const field = CollectionFormService.getRecordField(record, field => field.CollectionFieldsID == recordFieldId);
      if (field) {
        field.State = value != field.OrgChartUnitSelector ? UpdateDeleteState.Update : field.State;
        field.OrgChartUnitSelector = value;
        return form;
      } else if (!ignoreMissingData) throw new Error(`Attempted to set value of field ${recordFieldId} in record ${recordId} of grid field ${gridFieldId} but field was not found in record.`);
      else return form;
    } else if (!ignoreMissingData) throw new Error(`Attempted to set value of field ${recordFieldId} in record ${recordId} of grid field ${gridFieldId} but record was not found in grid.`);
    else return form;
  }

  public static setListFieldValue(form: CollectionForm, fieldId: number, value: Record[], ignoreMissingField = false): CollectionForm {
    const field = CollectionFormService.getField(form, f => f.Id === fieldId);
    if (field) {
      field.State = value != field.Records ? UpdateDeleteState.Update : field.State;
      field.Records = value;
      return form;
    } else if (!ignoreMissingField) throw new Error(`Attempted to set value of field ${fieldId} but field was not found in form.`);
    else return form;
  }

  public static updateRecordData(form: CollectionForm, gridFieldId: number, recordId: number, lookupData: CollectionFormFieldGridLookupData): CollectionForm {
    const record = CollectionFormService.getRecord(form, gridFieldId, recordId);
    if (record) {
      lookupData = structuredClone(lookupData);
      LookupService.enableDisableFields(lookupData.FormFields, form.ViewDataSources, record.RowDataDesignCrossID ?? record.CrossLinkedInstancesID);
      const dataFields = new Map(lookupData.FormFields.map(field => [field.CollectionFieldsID, field]));
      record.Fields = record.Fields.map(recordField => {
        return dataFields.get(recordField.CollectionFieldsID) ?? recordField;
      });
      return form;
    } else return form;
  }

  public static updateGridRecord(form: CollectionForm, viewDatasourceId: number, lookupData: CollectionFormFieldGridLookupData, recordId: number, newRecordId?: number): CollectionForm {
    let gridField = CollectionFormService.getField(form, field => field.ViewDataSourcesID == viewDatasourceId && CollectionFormService.fieldIsGrid(field));
    if (gridField !== undefined) {
      if (newRecordId !== undefined && newRecordId !== recordId) {
        CollectionFormService.setRecordInstanceId(form, gridField.Id, recordId, newRecordId);
      }
      CollectionFormService.updateRecordData(form, gridField.Id, newRecordId ?? recordId, lookupData);
    }
    return form;
  }

  public static updateRecordWithLookup(form: CollectionForm, gridFieldId: number, recordId: number, lookupData: CollectionFormFieldGridLookupData, lookupItem: LookupItem): CollectionForm {
    const record = CollectionFormService.getRecord(form, gridFieldId, recordId);
    if (record) {
      const field = CollectionFormService.getField(form, f => f.Id === gridFieldId);
      const vds = form.ViewDataSources.find(v => v.ViewDataSourcesID == field?.ViewDataSourcesID);


      if (vds) {
        if (vds?.ParentDataSourcesID > 0 && lookupData.ViewDataSources != undefined && lookupData.ViewDataSources.length > 0) {
          form = CollectionFormService.updateViewDataSources(form, lookupData.ViewDataSources);
          return CollectionFormService.updateRecordData(form, gridFieldId, recordId, lookupData);
        }
        const instances = vds.Instances.filter(i => i.CrossLinkedInstancesID == recordId);
        if (instances) {
          instances.forEach(i => {
            i.State = UpdateDeleteState.Delete;
          });
          if (recordId < 0) {
            vds.Instances = vds.Instances.filter(i => i.CrossLinkedInstancesID != recordId);
          }
          vds.Instances = vds.Instances.filter(i => ((i.isNew ?? false) !== true || i.CrossLinkedInstancesID != recordId));
          vds.Instances.push({
            CrossLinkedInstancesID: recordId,
            ChildInstancesID: lookupItem.InstancesID,
            OriginalChildInstancesID: lookupItem.OriginalChildInstancesID ?? lookupItem.InstancesID,
            ChildVersionsID: lookupItem.VersionsID,
            ParentInstancesID: lookupItem.InstancesID,
            ParentVersionsID: lookupItem.VersionsID,
            State: UpdateDeleteState.Update,
            isNew: true
          } as ViewDataSourcesInstance);
          return CollectionFormService.updateRecordData(form, gridFieldId, recordId, lookupData);
        }
      }


      //instanceChange.ChildInstancesID =
      return CollectionFormService.updateRecordData(form, gridFieldId, recordId, lookupData);
    } else return form;
  }

  public static fillRecordWithLookupData(record: Record, lookupData: CollectionFormFieldGridLookupData, instanceId: number): Record {
    const dataFields = new Map(lookupData.FormFields.map(field => [field.CollectionFieldsID, field]));
    record.Fields = record.Fields.map(recordField => {
      return dataFields.get(recordField.CollectionFieldsID) ?? recordField;
    });
    record.CrossLinkedInstancesID = instanceId;
    record.RowDataDesignCrossID = instanceId;
    record.EditMode = false;
    return record;
  }

  public static updateViewDataSource(form: CollectionForm, viewDataSource: ViewDataSource): CollectionForm {
    const vdsIndex = form.ViewDataSources.findIndex(v => v.ViewDataSourcesID === viewDataSource.ViewDataSourcesID);
    if (vdsIndex != -1) {
      form.ViewDataSources[vdsIndex].Instances = viewDataSource.Instances;
    }
    return form;
  }

  public static updateViewDataSources(form: CollectionForm, viewDataSources: ViewDataSource[]): CollectionForm {
    viewDataSources.forEach(vds => {
      form = CollectionFormService.updateViewDataSource(form, vds);
    });
    return form;
  }

  public static addRecords(form: CollectionForm, gridFieldId: number, records: Record[]): CollectionForm {
    records = structuredClone(records);
    records.forEach(record => {
      LookupService.enableDisableFields(record.Fields, form.ViewDataSources, record.RowDataDesignCrossID ?? record.CrossLinkedInstancesID);
    });
    const gridField = this.getField(form, field => field.Id == gridFieldId);
    if (gridField !== undefined && gridField.Records !== undefined) {
      gridField.Records = gridField.Records.concat(records);
      return form;
    } else return form;
  }

  // TODO: merge ViewDataSourceService.addRecordInstancesToViewDataSource into this
  public static addRecord(form: CollectionForm, gridFieldId: number, record: Record): CollectionForm {
    const gridField = this.getField(form, field => field.Id == gridFieldId);
    if (gridField !== undefined && gridField.Records !== undefined) {
      gridField.Records.push(record);
      return form;
    } else return form;
  }

  public static removeRecord(form: CollectionForm, gridFieldId: number, recordId: number): CollectionForm {
    const gridField = CollectionFormService.getField(form, f => f.Id === gridFieldId);
    if (gridField && gridField.Records && gridField.Records.length > 0) {

      const record = CollectionFormService.getRecordFromField(gridField, record => {
        return record.CrossLinkedInstancesID === recordId;
      });
      if (record) {
        if (record.CrossLinkedInstancesID < 0) {
          gridField.Records.splice(gridField.Records.findIndex(r => r.CrossLinkedInstancesID == recordId), 1);
          const vds = form.ViewDataSources.find(v => v.ViewDataSourcesID === gridField.ViewDataSourcesID);
          if (vds != undefined) {
            vds.Instances = vds.Instances?.filter(instance => instance.CrossLinkedInstancesID != recordId);
          }
        } else {
          record.State = UpdateDeleteState.Delete;

          const vds = form.ViewDataSources.find(v => v.ViewDataSourcesID === gridField.ViewDataSourcesID);
          if (vds != undefined) {
            const instance =
              CollectionFormService.getViewDataSourceInstanceByCrossLinkedInstanceId(vds?.Instances, recordId);
            if (instance != undefined) {
              instance.State = UpdateDeleteState.Delete;

              // Attempt to clear the parent cross instances too
              const parentCross = form.ViewDataSources.find(v =>
                v.DataDesignViewDataSourcesID == vds.ViewDataSourcesID && v.IsSnapshotRelation);
              if (parentCross != undefined) {
                const parentCrossInstance = parentCross.Instances.find(i => i.RowDataDesignCrossID == instance.RowDataDesignCrossID);
                if (parentCrossInstance != undefined) {
                  parentCross.Instances.splice(parentCross.Instances.indexOf(parentCrossInstance), 1);
                  //
                  // if (parentCrossInstance.CrossLinkedInstancesID < 0)
                  //   parentCross.Instances.splice(parentCross.Instances.indexOf(parentCrossInstance), 1);
                  // else
                  //   parentCrossInstance.State = UpdateDeleteState.Delete;
                }
              }
            }
          }

        }
      }
      return form;
    } else return form;
  }

  public static clearGrid(form: CollectionForm, gridFieldId: number): CollectionForm {
    let gridField = CollectionFormService.getField(form, field => field.Id == gridFieldId);
    if (gridField !== undefined) {
      gridField.Records = [];
    }
    return form;
  }

  /**
   * Returns a flat array of all fields in provided form.
   * @param form
   */
  public static extractFieldsFromForm(form: CollectionForm): CollectionFormField[] {
    let fields: CollectionFormField[] = [];
    form.Accordions.forEach((accordion) => {
      fields = fields.concat(CollectionFormService.extractFieldsFromAccordion(accordion));
    });
    return fields;
  }

  public static extractRecordsByVds(form: CollectionForm, viewDataSource: ViewDataSource): Record[] {
    let gridField = CollectionFormService.getField(form, field => field.ViewDataSourcesID == viewDataSource.ViewDataSourcesID && CollectionFormService.fieldIsGrid(field));
    if (gridField !== undefined) {
      return gridField.Records ?? [];
    } else throw new Error(`No grid field found associated with VDS ${viewDataSource.ViewDataSourcesID}`);
  }

  /**
   * Returns the first focusable field in the provided form.
   * @param form
   */
  public static getFirstFocusableFieldFromForm(form: CollectionForm): CollectionFormField | undefined {
    //Conditions:
    // Is a focusable field => accept a TableFieldDataType
    // Is NOT Readonly
    // Is NOT Hidden (by scripting =>  isHidden)
    return CollectionFormService.extractFieldsFromForm(form).find((f: CollectionFormField) => {
        return !form.IsLocked && !CollectionFormService.fieldIsGrid(f) &&
          fieldDataTypeIsFocusable(f.ComponentType) &&
          (!f.IsReadOnly) &&
          (!f.IsHidden);
      }
    );
  }

  /**
   * Returns a flat array of all fields in provided accordion.
   * @param accordion
   */
  public static extractFieldsFromAccordion(accordion: CollectionFormAccordion): CollectionFormField[] {
    let fields: CollectionFormField[] = [];
    accordion.Rows.forEach((row) => {
      fields = fields.concat(CollectionFormService.extractFieldsFromRow(row));
    });
    return fields;
  }

  /**
   * Returns a flat array of all fields in provided row.
   * @param row
   */
  public static extractFieldsFromRow(row: CollectionFormRow): CollectionFormField[] {
    const fields: CollectionFormField[] = [];
    row.Containers.forEach((container) => {
      container.Fields.forEach((field) => {
        // Ignore non collection fields => UI elements and lists are not part of the form
        if (field.Bookmark != null && field.Bookmark.toLowerCase() == 'mailinglist' && field.Value != null && field.Value != '') {
          fields.push(field);
        }
        if (field.CollectionFieldsID != 0 || field.ListSourceCollectionsID !== 0)
          fields.push(field);
      });
    });
    return fields;
  }


  /**
   * Toggles the given accordion and returns the updated form.
   * The original form is not modified. (immutable)
   * @param form
   * @param accordionId
   */
  public static toggleFormAccordion(form: CollectionForm, accordionId: number): CollectionForm {
    if (!form) {
      throw new Error(`Form is undefined`);
    } else {
      const index = form.Accordions.findIndex((a) => a.Id == accordionId);
      if (index >= 0) {
        const updatedForm = structuredClone(form);
        updatedForm.Accordions[index].AccordionStatus =
          updatedForm.Accordions[index].AccordionStatus == AccordionStatusType.Open ? AccordionStatusType.Closed : AccordionStatusType.Open;
        return updatedForm;
      } else {
        throw new Error(
          `Accordion with ID ${accordionId} not found in form ${form.CollectionsID}`
        );
      }
    }
  }

  /**
   * Returns the location object of the given fieldId in the provided form.
   * If the field is not found, returns undefined.
   * @param {CollectionForm} form
   * @param {number} fieldId
   * @return {FormFieldLocation | undefined}
   */
  public static getLocationByFieldId(form: CollectionForm, fieldId: number): FormFieldLocation | undefined {
    let location: FormFieldLocation | undefined = undefined;
    form.Accordions.forEach((accordion) => {
      accordion.Rows.forEach((row, rowIndex) => {
        row.Containers.forEach((container) => {
          container.Fields.forEach((field) => {
            if (field.Id == fieldId) {
              location = new FormFieldLocation(accordion.Id, rowIndex, container.Id, field.Id);
            }
          });
        });
      });
    });
    return location;
  }

  /**
   * Returns the field of the given form that matches the provided predicate.
   * @param {CollectionForm} form
   * @param {(field: CollectionFormField) => boolean} predicate
   * @return {CollectionFormField | undefined}
   */
  public static getField(form: CollectionForm, predicate: (field: CollectionFormField) => boolean): CollectionFormField | undefined {
    for (let a = 0; a < form.Accordions.length; a++) {
      const accordion = form.Accordions[a];
      for (let r = 0; r < accordion.Rows.length; r++) {
        const row = accordion.Rows[r];
        for (let c = 0; c < row.Containers.length; c++) {
          const container = row.Containers[c];
          for (let f = 0; f < container.Fields.length; f++) {
            const field = container.Fields[f];
            if (predicate(field)) {
              return field;
            }
          }
        }
      }
    }
    return undefined;
  }

  public static getRecordField(record: Record, predicate: (field: CollectionFormField) => boolean): CollectionFormField | undefined {
    return record.Fields.find(predicate);
  }

  public static getRecordFromField(field: CollectionFormField, predicate: (record: Record) => boolean): Record | undefined {
    if (field.Records)
      return field.Records.find(predicate);
    else return undefined;
  }

  /**
   * Gets a record from given field by ID.
   * First checks for RowDataDesignCrossID, but falls back on CrossLinkedInstancesID if the former is not present.
   * @param {CollectionForm} form
   * @param {number} fieldId
   * @param {number} recordId
   * @return {Record | undefined}
   */
  public static getRecord(form: CollectionForm, fieldId: number, recordId: number): Record | undefined {
    const field = CollectionFormService.getField(form, field => {
      return field.Id == fieldId;
    });
    if (field)
      return CollectionFormService.getRecordFromField(field, record => {
        return (record.RowDataDesignCrossID ?? record.CrossLinkedInstancesID) == recordId;
      });
    else return undefined;
  }

  public static getFields(form: CollectionForm, predicate: (field: CollectionFormField) => boolean): Array<CollectionFormField> {
    const fields = CollectionFormService.extractFieldsFromForm(form);
    return fields.filter(field => predicate(field));
  }

  public static setLoading(storeForm: StoreCollectionForm, loading: boolean): Update<StoreCollectionForm> {
    const updatedForm = structuredClone(storeForm);
    updatedForm.loading = loading;
    return { id: storeForm.id, changes: updatedForm };
  }

  public static getFormFieldRecordFormGroup(fieldControl: FormControl<unknown>, record: Record): FormGroup {
    return fieldControl.get(record.CrossLinkedInstancesID.toString()) as FormGroup;
  }

  /**
   * Maps a form to a StoreCollectionForm. Useful for NgRx reducers.
   * @param form
   * @param id
   */
  public static toStoreCollectionForm(form: CollectionForm, id: string): StoreCollectionForm {
    return { id, data: form };
  }

  /**
   * Maps a form to an Update for NgRx Reducer.
   * @param form
   * @param id
   */
  public static toStoreUpdate(form: CollectionForm, id: string): Update<StoreCollectionForm> {
    return { id, changes: CollectionFormService.toStoreCollectionForm(form, id) };
  }

  /**
   * Maps a form to an Update for NgRx Reducer.
   * @param form
   * @param id
   */
  public static toStoreUpdateScheduler(form: CollectionForm, id: string, scheduler: SchedulerDto): Update<StoreCollectionForm> {
    return { id, changes: { id, data: form, schedulerData: scheduler } };
  }

  /**
   * @param {CollectionForm} form
   * @param {string} id
   * @param {Map<number, SkillIntegrityCheck> | undefined} integrity
   * @returns {Update<StoreCollectionForm>}
   */
  public static toStoreUpdateIntegrity(form: CollectionForm, id: string, integrity: Map<number, SkillIntegrityCheck> | undefined): Update<StoreCollectionForm> {
    let value: Array<{ fieldId: number, value: SkillIntegrityCheck }> | undefined = undefined;
    if (integrity != undefined) {
      value = Array.from(integrity, ([fieldId, value]) => ({ fieldId, value }));
    }
    return { id, changes: { id, data: form, integrity: value } };
  }

  /**
   * Returns a JSON string representation of the given form.
   * @param form
   * @deprecated
   */
  public static stringify(form: CollectionForm): string {
    // Flat array of all fields
    const fields = CollectionFormService.extractFieldsFromForm(form);

    // Fields belonging to the form itself
    const baseFields = fields.filter(f => f.ViewDataSourcesID == 0 && f.Bookmark != null);

    // Output to stringify
    const jsonObject: { [key: string]: unknown } = {};

    if (form.VersionsID > 0)
      jsonObject['VersionsID'] = form.VersionsID;
    jsonObject['DocumentProperties'] = form.DocumentProperties;

    // Base form fields
    baseFields.forEach((field) => {
        const value = CollectionFormService.getValueForField(field);
        if (value != null) {
          Object.assign(jsonObject, { [field.Bookmark]: value });
        }

      }
    );

    // Relations
    if (form.ViewDataSources && form.ViewDataSources.length > 0) {
      const formVds = structuredClone(form.ViewDataSources);
      const nonSnapshotDataSources = formVds.filter(vds => vds.IsSnapshotRelation == false);
      const linkedFields = structuredClone(fields.filter(f => f.ViewDataSourcesID > 0));
      ViewDataSourceService.addLinkedCollectionFieldsToJson(jsonObject, 0, nonSnapshotDataSources, linkedFields, formVds);
    }

    // Fixed fields
    jsonObject['DocumentProperties'] = form.DocumentProperties;
    jsonObject['InheritType'] = form.InheritType;
    jsonObject['DisplayFieldsInheritType'] = form.DisplayFieldsInheritType;
    jsonObject['ExamSubscriptions'] = form.ExamSubscriptions;
    jsonObject['TrainingAssessment'] = form.TrainingAssessment;

    return CollectionFormService.stringifyFormJson(jsonObject);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getValueForField(field: CollectionFormField): Object | null {
    switch (field.ComponentType) {
      case TableFieldDataType.OrganizationChartUnitSelector:
      case TableFieldDataType.OrganizationChartUnitSelectorForTrnApp:
        return CollectionFormService.getOrgChartArrayForJson(field.OrgChartUnitSelector);
      case TableFieldDataType.RadioGroup:
      case TableFieldDataType.Combobox:
        return (isNaN(field.Value) || field.Value == 0) ? null : { value: field.Value };
      case TableFieldDataType.EnumList:
        return { value: field.EnumValue };
      case TableFieldDataType.Memo:
        return { value: isEmpty(field.Value) ? null : field.Value };
      case TableFieldDataType.Numeric:
        return { value: Number.isNaN(field.Value) || field.Value == Infinity ? null : field.Value };
      //case TableFieldDataType.MailingList:
      //TODO: RV check if mailinglist needs to be handled differently
      //return { "value": $scope.mails };
      default:
        if (field.Value !== undefined)
          return { value: field.Value };
        else return null;
    }
  }

  public static getOrgChartArrayForJson(list: OrgChartItem[]): {
    type: OrganizationChartItemType,
    value: number,
    text: string
  }[] {
    const array: { type: OrganizationChartItemType, value: number, text: string }[] = [];
    list.forEach(val => {
      array.push({
        type: val.ItemType,
        value: val.VersionId,
        text: val.Name
      });
    });
    return array;
  }

  public static getFieldsWithValue(fields: CollectionFormField[]): CollectionFormField[] {
    return fields.filter((field) => {
      return ((field.Value != undefined && field.Value != null && field.Value !== '')
          || field.State == UpdateDeleteState.Update)
        || field.OrgChartUnitSelector.length > 0
        || (field.IsHidden || field.IsRequired || field.Evaluations.length > 0)
        || (field.Bookmark != null && field.Bookmark.toLowerCase() == 'mailinglist');
    });
  }

  /**
   * Get the ViewDataSource linked to a field
   * @param {CollectionForm} form
   * @param {CollectionFormField} field
   * @return {ViewDataSource | undefined}
   */
  public static getViewDataSourceForField(form: CollectionForm, field: CollectionFormField): ViewDataSource | undefined {
    return form.ViewDataSources.find(v => v.ViewDataSourcesID === field.ViewDataSourcesID);
  }

  public static getViewDataSourceByCrossLinkId(form: CollectionForm, crossLinkCollectionId: number): ViewDataSource | undefined {
    return form.ViewDataSources.find(v => v.CrossLinkCollectionsID === crossLinkCollectionId);
  }

  /**
   * Get the ViewDataSource linked to a field by id
   * @param {CollectionForm} form
   * @param {number} fieldId
   * @return {ViewDataSource | undefined}
   */
  public static getViewDataSourceForFieldById(form: CollectionForm, fieldId: number): ViewDataSource | undefined {
    const vds: ViewDataSource | undefined = undefined;
    const field = CollectionFormService.getField(form, f => {
      return f.Id === fieldId;
    });
    if (field) {
      return CollectionFormService.getViewDataSourceForField(form, field);
    }
    return vds;
  }

  public static getViewDataSourceInstanceByCrossLinkedInstanceId
  (instances: ViewDataSourcesInstance[], crossLinkedInstancesId: number): ViewDataSourcesInstance | undefined {
    return instances.find(i => i.CrossLinkedInstancesID === crossLinkedInstancesId);
  }

  /**
   * Returns a boolean indicating if a linked field should be readonly.
   * @param {CollectionFormField} field
   * @return {boolean}
   */
  public static isLinkedFieldReadOnly(form: CollectionForm, field: CollectionFormField): boolean {
    if (CollectionFormService.fieldIsGrid(field)) return CollectionFormService.isLinkedGridReadOnly(form, field);
    const vds = form.ViewDataSources.find(v => v.ViewDataSourcesID === field.ViewDataSourcesID);
    if (vds) {
      if (vds.EntityRelatedFieldID !== null && field.ProtectedFieldType == ProtectedFieldType.DepartmentName) {
        let entityDependencyOrgChartField = CollectionFormService.getField(form, field => field.CollectionFieldsID == vds.EntityRelatedFieldID);
        if (entityDependencyOrgChartField !== undefined) {
          return entityDependencyOrgChartField.OrgChartUnitSelector.length == 0;
        } else return true;
      }
      if (vds.ParentDataSourcesID !== 0) {
        if (vds.LinkedCollectionStorageType == LinkedCollectionStorageType.Relational || (vds.LinkedCollectionStorageType == LinkedCollectionStorageType.Historical && field.MustBeInList)) {
          if (field.IsLookupField) {
            // Lookup, Relational, editable if parents have instances
            return !ViewDataSourceService.viewDataSourceHasParentInstances(form.ViewDataSources, field.ViewDataSourcesID);
          } else {
            // Non lookup, Relational, never editable
            return true;
          }
        } else {
          if (field.IsLookupField) {
            // Lookup, Historical, editable if parents have instances
            return !ViewDataSourceService.viewDataSourceHasParentInstances(form.ViewDataSources, field.ViewDataSourcesID);
          } else {
            // Non lookup, Historical, editable if an instance is selected
            return !ViewDataSourceService.viewDataSourceHasInstance(form.ViewDataSources, field.ViewDataSourcesID);
          }
        }
      } else {
        if (field.IsLookupField) {
          // Lookup, Top level => always editable
          return false;
        } else {
          // Non lookup, only editable if Historical and instance selected
          return !(ViewDataSourceService.viewDataSourceHasInstance(form.ViewDataSources, field.ViewDataSourcesID) && (vds.LinkedCollectionStorageType == LinkedCollectionStorageType.Historical && !field.MustBeInList));
        }
      }
    } else return true;
  }

  public static isLinkedGridReadOnly(form: CollectionForm, field: CollectionFormField): boolean {
    const vds = form.ViewDataSources.find(v => v.ViewDataSourcesID === field.ViewDataSourcesID);
    if (vds) {
      // TODO: double check if Reversed grids are ever editable
      if (vds.IsReversedRelation) return true;
      if (vds.ParentDataSourcesID !== 0) {
        // 1x...xN, editable if parent has VDS
        return !ViewDataSourceService.viewDataSourceHasParentInstances(form.ViewDataSources, field.ViewDataSourcesID);
      } else {
        // 1xN, editable
        return false;
      }
    } else return true;
  }

  public static fieldIsGrid(field: CollectionFormField): boolean {
    return field.FormFieldType == FormFieldType.List ||
      field.FormFieldType == FormFieldType.LinkedCollection ||
      field.FormFieldType == FormFieldType.ReversedUserCollection;
  }

  /**
   * TODO: Backend Should fix this.
   * Function that returns a JSON string representation of the given form values.
   * It's main purpose is to replace certain keys with their lowercase counterparts.
   * Because the backend sometimes requires json keys to start with lowercase letters.
   * @param json The JSON object to stringify
   * @returns
   */
  private static stringifyFormJson(json: { [key: string]: unknown }): string {
    const parsedJson = JSON.stringify(json, (key, value) => {
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const replacement: any = {};
        for (const k in value) {
          if (Object.hasOwnProperty.call(value, k) && k in FORM_CONSTANTS) {
            replacement[FORM_CONSTANTS[k]] = value[k];
          } else
            replacement[k] = value[k];
        }
        return replacement;
      }
      return value;
    });
    return parsedJson;
  }
}