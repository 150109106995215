<div class="modal-container modal-sm sm:w-[640px]">

    <div class="flex flex-row justify-between">
        <div class="modal-header font-title font-bold">
            <h2>
                <span>{{ "SelectMailTemplate" | translate }}</span>
            </h2>
        </div>
        <bizz-icon class="ml-auto block size-5 text-button md:ml-0"
                   (click)="close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>

    <div class="modal-body w-full gap-5 !px-0">
        <div class="relative mr-2 box-border flex h-8 w-full flex-row items-center justify-end">
            <input class="border-widget-header size-8 w-full rounded text-transparent placeholder:text-transparent focus:border-3 focus:border-form-control-focus focus:text-button focus:shadow-inner focus:transition-all placeholder:focus:text-widget-button/90"
                   placeholder="{{'Search' | translate}}" type="text" [ngModel]="searchTerm"
                   (ngModelChange)="search$.next($event)">
            <bizz-icon
                    class="pointer-events-none absolute mr-2 size-4 cursor-pointer text-button sm:pointer-events-auto"
                    iconName="magnifying-glass"></bizz-icon>
        </div>

        <div class="flex min-w-full flex-col gap-3">
            <ng-container *ngTemplateOutlet="selectableTemplate; context: {$implicit: null}"></ng-container>

            <hr class="my-3">

            <div class="flex max-h-96 min-w-full flex-col gap-3 overflow-auto">
                @for (template of filteredTemplates$ | async; track template.ID) {
                    <ng-container class
                                  *ngTemplateOutlet="selectableTemplate; context: {$implicit: template}"></ng-container>
                }
            </div>

        </div>


    </div>
    <div class="modal-buttons">
        <div class="flex flex-row justify-end gap-3 text-center">
            <button [disabled]="selectedTemplate === undefined" class="save" (click)="close(true)">
                {{ "SelectMailTemplate" | translate }}
            </button>
        </div>

    </div>


</div>

<ng-template #selectableTemplate let-template class="h-full w-full min-w-full">
    <div class="relative flex w-full cursor-pointer flex-row gap-3 rounded border-2 border-l-4 border-card border-l-blue-500 bg-card p-4 shadow-[0_0px_4px_2px_rgba(0,0,0,0.1)] hover:border-blue-300 hover:bg-blue-600/25 hover:shadow-xl"
         [ngClass]="{ 'bg-blue-600/25 border-blue-500' : selectedTemplate === template?.ID }"
         (click)="selectedTemplate = template?.ID">
        <div class="mx-2.5">
            @if (template == null) {
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.3188 28.0969C20.3273 28.8703 19.1602 29.257 18 29.257C16.8398 29.257 15.6755 28.8733 14.6841 28.1011L0 16.6781V32.625C0 34.4883 1.51102 36 3.375 36H32.625C34.489 36 36 34.489 36 32.6883V16.6781L21.3188 28.0969ZM34.868 14.7023C35.7778 13.9852 35.8446 12.5831 35.0359 11.7527C34.9339 11.6478 34.824 11.5495 34.7065 11.4585C33.8484 10.7859 33.0609 10.1672 31.5 9.00703V17.325C31.5 17.325 34.868 14.7094 34.868 14.7023ZM18.0281 0H17.9719C16.6753 0 14.9513 1.28883 13.7067 2.25H22.2989C21.0516 1.28883 19.3289 0 18.0281 0ZM1.13766 14.7094L4.5 17.325V9.00703C2.93906 10.1672 2.14875 10.7859 1.29234 11.4609C1.17422 11.5523 1.06453 11.6508 0.962578 11.7562C0.154055 12.5227 0.227812 13.9922 1.13766 14.7094C1.14258 14.7164 1.13273 14.7094 1.13766 14.7094Z"
                          fill="#009DD8"/>
                    <path opacity="0.4"
                          d="M29.25 2.25366H6.75C5.50758 2.25366 4.5 3.26124 4.5 4.44038V17.3287L16.0664 26.3216C17.2055 27.2076 18.7995 27.2076 19.9378 26.3216L31.5 17.3287V4.50366C31.5 3.26124 30.4945 2.25366 29.25 2.25366Z"
                          fill="#009DD8"/>
                </svg>
            } @else {
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2241_21663)">
                        <path d="M21.3188 28.0969C20.3273 28.8703 19.1602 29.257 18 29.257C16.8398 29.257 15.6755 28.8733 14.6841 28.1011L0 16.6781V32.625C0 34.4883 1.51102 36 3.375 36H32.625C34.489 36 36 34.489 36 32.6883V16.6781L21.3188 28.0969ZM34.868 14.7023C35.7778 13.9852 35.8446 12.5831 35.0359 11.7527C34.9339 11.6478 34.824 11.5495 34.7065 11.4585C33.8484 10.7859 33.0609 10.1672 31.5 9.00703V17.325C31.5 17.325 34.868 14.7094 34.868 14.7023ZM18.0281 0H17.9719C16.6753 0 14.9513 1.28883 13.7067 2.25H22.2989C21.0516 1.28883 19.3289 0 18.0281 0ZM1.13766 14.7094L4.5 17.325V9.00703C2.93906 10.1672 2.14875 10.7859 1.29234 11.4609C1.17422 11.5523 1.06453 11.6508 0.962578 11.7562C0.154055 12.5227 0.227813 13.9922 1.13766 14.7094C1.14258 14.7164 1.13273 14.7094 1.13766 14.7094ZM12.375 15.6867H23.625C24.2463 15.6867 24.75 15.183 24.75 14.5617C24.75 13.9403 24.2463 13.5 23.625 13.5H12.375C11.7537 13.5 11.25 14.0036 11.25 14.5617C11.25 15.2438 11.7563 15.6867 12.375 15.6867ZM12.375 11.1867H23.625C24.2463 11.1867 24.75 10.683 24.75 10.125C24.75 9.50358 24.2463 9.06328 23.625 9.06328H12.375C11.7537 9.06328 11.25 9.56686 11.25 10.125C11.25 10.7437 11.7563 11.1867 12.375 11.1867Z"
                              fill="#009DD8"/>
                        <path opacity="0.4"
                              d="M29.25 2.25H6.75C5.50758 2.25 4.5 3.25758 4.5 4.43672V17.325L16.0664 26.318C17.2055 27.2039 18.7995 27.2039 19.9378 26.318L31.5 17.325V4.5C31.5 3.25758 30.4945 2.25 29.25 2.25ZM23.625 15.6867H12.375C11.7537 15.6867 11.25 15.183 11.25 14.5617C11.25 13.9403 11.7537 13.5 12.375 13.5H23.625C24.2463 13.5 24.75 14.0036 24.75 14.5617C24.75 15.2438 24.2437 15.6867 23.625 15.6867ZM23.625 11.1867H12.375C11.7537 11.1867 11.25 10.683 11.25 10.125C11.25 9.50358 11.7537 9.06328 12.375 9.06328H23.625C24.2463 9.06328 24.75 9.56686 24.75 10.125C24.75 10.7437 24.2437 11.1867 23.625 11.1867Z"
                              fill="#009DD8"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_2241_21663">
                            <rect width="36" height="36" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            }
        </div>

        <div class="mail-template-content flex w-full min-w-0 flex-col">
            <div class="flex flex-row justify-between">
                <p class="select-none font-semibold text-blue-500">{{ template == null ? ("BlankEmailTemplate" | translate) : template.Name }}</p>
                @if (selectedTemplate === template?.ID) {
                    <bizz-icon class="absolute right-2 top-2 size-4 text-blue-500" iconName="circle-check"></bizz-icon>
                }
            </div>

            <small class="min-w-0 select-none truncate text-sm" kendoTooltip [tooltipTemplate]="tooltipTemplate" filter="a[title]"
                   [title]="template == null ? ('CreateAnEmailFromScratch' | translate) : template.Description">{{ template == null ? ("CreateAnEmailFromScratch" | translate) : template.Description }}</small>

        </div>
    </div>
</ng-template>

<ng-template #tooltipTemplate let-anchor>
    <span>{{ anchor.nativeElement.getAttribute("title") }}</span>
</ng-template>