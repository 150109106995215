<div class="modal-container modal-sm">
    <div class="modal-close-container">
        <bizz-icon iconName="xmark" (click)="close(undefined)"></bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1>
                {{ "AddUserToQueue" | translate }}
            </h1>
            <hr class="m-auto mb-5 mt-5 h-0 w-full border-t-2 border-divider">
        </div>
        <div class="w-full grow overflow-auto" style="padding: 0 34px 0 34px">
            <p>{{ "RemoveUser_Info" | translate }}</p>
        </div>
    </div>

    <div class="modal-buttons">
        <button class="default" (click)="close(undefined)">{{"Cancel" | translate}}</button>
        <button class="default" (click)="close(false)">{{"No" | translate}}</button>
        <button class="save" (click)="close(true)">{{"Yes" | translate}}</button>
    </div>
</div>

