import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TaskPostponeDto } from '../../../../models/ts/task-postpone-dto.model';
import { PeriodicRevisionDto } from '../../../../models/ts/periodic-revision-dto.model';

@Injectable({
  providedIn: 'root'
})
export class CollectionApiService {

  public constructor(private http: HttpClient) { }
  public getRevisionTaskInfo(request: {
    collectionId: number,
    instanceId: number,
    revisionId: number,
    taskId: number
  }): Observable<PeriodicRevisionDto> {
    return this.http.get<PeriodicRevisionDto>(`settings/api/collection/${request.collectionId}/instance/${request.instanceId}/revision/${request.revisionId}/task/${request.taskId}`);
  }

  public validatePostponeTask(intent: TaskPostponeDto) : Observable<void> {
    return this.http.post<void>(`settings/api/postponetask/validate`, intent);
  }

  public postponeTask(intent: {
    postpone: TaskPostponeDto,
    collectionId: number,
    instanceId: number,
    versionId: number,
    revisionId: number
  }) : Observable<void> {
    return this.http.post<void>(`settings/api/collection/${intent.collectionId}/instance/${intent.instanceId}/version/${intent.versionId}/revision/${intent.revisionId}/postpone`, intent.postpone);
  }


  // TODO: implement
}
