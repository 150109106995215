import { Component, computed, DestroyRef, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KENDO_TEXTAREA, RadioButtonModule } from '@progress/kendo-angular-inputs';
import { SharedFilterModule } from '@progress/kendo-angular-grid';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { CollectionForm } from '../../../../../../models/ts/collection-form.model';
import { AssessmentProperties } from '../../../../../../models/ts/assessment-properties.model';
import { AssessmentApiService } from '../../../../../api/bizzmine/assessment/assessment-api.service';
import { AssessmentDto } from '../../../../../../models/ts/assessment-dto.model';
import { FormAssessmentsModalComponent } from '../form-assessments-modal/form-assessments-modal.component';
import { AssessmentType } from 'src/models/ts/assessment-type.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectFormLockState } from 'src/app/store/features/forms/forms-selectors';
import { Store } from '@ngrx/store';
import { EditorModule } from '@progress/kendo-angular-editor';
import { CollectionListApiService } from 'src/app/api/bizzmine/collection-list/collection-list-api.service';
import { SafePipe } from 'safe-pipe';

@Component({
  selector: 'bizz-form-assessment',
  standalone: true,
  imports: [CommonModule, TranslatePipe, IconComponent, RadioButtonModule, SharedFilterModule, EditorModule, SafePipe, KENDO_TEXTAREA],
  templateUrl: './form-assessment.component.html',
  styleUrls: ['./form-assessment.component.scss'],
})
export class FormAssessmentComponent implements OnInit {
  @Input({ required: true }) public formId: string;
  @Input({ required: true }) public formData: CollectionForm;
  @Output() public assessmentPropertiesChange: EventEmitter<AssessmentProperties> = new EventEmitter<AssessmentProperties>();
  public assessmentForm: FormGroup;
  protected readonly AssessmentType = AssessmentType;
  public showCompareAiSummary: boolean = false;
  public constructor(private fb: FormBuilder, 
    private assessmentApiService: AssessmentApiService, 
    private dialog: Dialog, 
    private store$: Store,
    private destroyRef: DestroyRef,
    private collectionListApiService: CollectionListApiService,

    ) {

  }

  public ngOnInit(): void {
    this.assessmentForm = this.initAssessmentForm(this.formData);
    this.assessmentForm.valueChanges.pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.emitAssessmentPropertiesChange();
    });

    this.store$.select(selectFormLockState(this.formId)).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (locked) => locked ? this.assessmentForm.disable() : this.assessmentForm.enable()
    });
  }

  public openAssessmentsModal(): void {
    if (this.formData.TasksID) {
      this.assessmentApiService.getAssessmentsByTaskId(this.formData.TasksID).subscribe({
        next: (assessments) => {
          this.dialog.open<Array<AssessmentDto>>(FormAssessmentsModalComponent, { data: assessments });
        },
      });
    }
  }

  public emitAssessmentPropertiesChange(): void {
    this.assessmentPropertiesChange.emit(this.assessmentForm.getRawValue() as AssessmentProperties);
  }

  public downloadComparisonFile(): void {
    //TOOD: Implement download comparison file
    this.collectionListApiService.downloadCompareFile(this.formData)
  }

  public toggleCompareAiSummary(): void {
    this.showCompareAiSummary = !this.showCompareAiSummary;
  }

  private initAssessmentForm(formData?: CollectionForm): FormGroup {
    return this.fb.group({
      CompareAiSummary: new FormControl(formData?.DocumentProperties?.DocumentComparison?.CompareAiSummary),
      Reason: new FormControl(formData?.DocumentProperties.AssessmentProperties.Reason),
      AssessmentType: new FormControl(formData?.DocumentProperties.AssessmentProperties.AssessmentType),
      IsVeto: new FormControl(formData?.DocumentProperties.AssessmentProperties.IsVeto),
    });
  }
}
