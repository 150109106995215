<button class="macro-button default no-underline" #macroButton
   [ngClass]="{'cursor-default pointer-events-none': disabled}"
   (click)="navigateToUrl($event)"
   [class]="style">
    @if (showIcon) {
        <bizz-icon class="size-4"
                   [iconLibrary]="icon.Library"
                   [iconName]="icon.Name"></bizz-icon>
    }
    @if (showCaption) {
        <span>{{ caption }}</span>
    }
</button>