export enum GridColumnType {
  Text = 0,
  Actions = 1,
  Status = 2,
  Size = 3,
  Title = 4,
  Extension = 5,
  Checkbox = 6,
  Date = 7,
  Time = 8,
  DateTime = 9,
  File = 10,
  Numeric = 11,
  Hyperlink = 12,
  MacroButton = 13,
  Memo = 14,
  MemoWithHyperlink = 15,
  TaskStatus = 16,
  EventType = 17,
  ExecutedByBehalf = 18,
  AuditLogInfo = 19,
  AuditLogNewValue = 20,
  NotificationAndReminderStatus = 21,
  NotificationAndReminderActions = 22,
  TaskActions = 23,
  MailActions = 24,
  MailAttachment = 25,
  SchedulerActions = 26,
  TrnQueueActions = 27,
  Canvas = 28,
  MailStatus = 29,
  AiButton = 30
}
