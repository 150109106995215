<div class="flex h-full w-full flex-col px-[0.94rem] items-center gap-2">
    @for(block of skeletonBlocks; track block.id) {
        <!-- Sidebar block kendo skeleton -->
        <div class="rounded-lg p-2.5 w-full">
            <div class="flex flex-row">
                <div class="flex flex-row gap-4 w-full text-sidebar-icon/75">
                    <kendo-skeleton
                            shape="circle"
                            animation="wave"
                            class="mt-0.5 size-6 min-w-[1.25rem] sm:size-5"
                    ></kendo-skeleton>

                    <kendo-skeleton
                            shape="text"
                            animation="wave"
                            class="w-full mr-4"
                    ></kendo-skeleton>
                </div>

            </div>
            <!-- Sidebar block inside links kendo skeleton -->
            @for(link of block.items; track link.id) {
                <div class="mt-2 flex flex-col overflow-hidden max-h-64">
                    <div class="group my-1 ml-[2.27rem] flex cursor-pointer items-start whitespace-normal pr-4 text-xs text-sidebar-link/50 no-underline">
                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="mr-2.5 mt-0.5 size-3.5 min-w-3.5">
                            ></kendo-skeleton>

                        <kendo-skeleton
                                shape="text"
                                animation="wave"
                                class="w-full mr-5">
                            ></kendo-skeleton>
                    </div>
                </div>
            }
        </div>
    }
</div>

