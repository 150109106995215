import { createReducer, on } from '@ngrx/store';
import { initialHeaderNotificationsStoreState } from './header-notifications-state';
import { headerNotificationsActions } from './header-notifications-actions';

export const headerNotificationsReducer = createReducer(
  initialHeaderNotificationsStoreState,
  on(
    headerNotificationsActions.fetchNotifications,
    headerNotificationsActions.readNotifications,
    headerNotificationsActions.readNotification,
    headerNotificationsActions.unreadNotification,
    headerNotificationsActions.updateSearch,
    (state) => {
      if (!state.loading) {
        const updatedState = structuredClone(state);
        updatedState.loading = true;
        return updatedState;
      }
      return state;
    }
  ),
  on(headerNotificationsActions.notificationsFetched, (state, { notifications }) => {
    const updatedState = structuredClone(state);
    updatedState.notifications = notifications.Notifications;
    updatedState.count = notifications.NumberOfNotifications;
    updatedState.loading = false;
    return updatedState;
  }),
  on(headerNotificationsActions.updateSearch, (state, { search }) => {
    const updatedState = structuredClone(state);
    updatedState.search = search;
    return updatedState;
  })
);
