<div class="modal-container justify-between md:justify-normal">
  <div class="modal-close-container">
    <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
               (click)="dialogRef.close()"
               iconName="xmark" [hover]="true">
    </bizz-icon>
  </div>
  <div class="modal-body">
    <div class="modal-header">
      <h1>
        {{'RestoreInstance' | translate}}</h1>
      <p class="text-sm font-normal">
        {{'AreYouSureYouWantToRestoreThisInstance' | translate }}
      </p>
    </div>
    <hr class="my-5" />
    <div>
      <label class="mb-2 inline-block text-sm text-default">{{'ReasonRestore' | translate }}
        <span class="ml-0.5 text-red-400">*</span></label>
          <kendo-textarea
            [rows]="15"
            size="large"
            resizable="vertical"
            [(value)]="restoreReason"
            fillMode="solid"
            rounded="large"
          ></kendo-textarea>
    </div>
  </div>
  <div class="modal-buttons">
    <button class="default" (click)="dialogRef.close()">{{'Cancel' | translate }}</button>
    <button class="delete" (click)="restoreInstance()" [disabled]="restoreReason == ''">
      {{'Restore' | translate}}
    </button>
  </div>
</div>
