<div class="modal-container justify-between md:justify-normal">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="dialogRef.close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1>
                {{ 'Info_ChooseWhichVersionToDownload' | translate }}</h1>
            <p class="text-center text-sm font-normal">{{ 'Info_YouHaveAccessToMultipleVersionsOfThisDocument' | translate }}
                <br/>
                {{ 'Info_TellUsWhichFileToFetch' | translate }}
            </p>
        </div>
        <hr class="my-5"/>
        @if (hasData) {
            <div class="flex max-h-[80%] w-full flex-col overflow-y-auto">
                @if(hasPublic) {
                    <!-- Public-->
                    <div class="flex flex-row items-start gap-5 self-stretch">
                        <div class="hidden md:flex">                        
                            <bizz-icon
                                class="h-7 w-8"
                                iconName="file-circle-check">
                            </bizz-icon>
                        </div>
                        <div class="flex flex-grow flex-col items-start justify-center gap-5">
                            <div class="flex items-start justify-between self-stretch md:items-center">
                                <div class="flex flex-col items-start gap-1 font-bold">
                                    <div class="items-left flex flex-col justify-between gap-y-3 md:flex-row md:items-center md:gap-x-3">
                                        <h2 class="items-left flex flex-row gap-x-3 md:items-center">
                                            <div>{{ 'PublicVersion' | translate }}</div>
                                            @if(model.PublicVersion['Version']){
                                                <div class="gap-2.5 rounded-md bg-button px-2 py-1 text-center leading-none text-default">
                                                    {{ model.PublicVersion['Version'].Value }}
                                                </div>
                                            }                                    
                                        </h2>                                
                                        @if (model.PublicVersion['ArchiveDate']) {
                                            <div class="text-xs text-danger">
                                                <b>{{ 'WillBeArchivedOn' | translate }}</b>
                                                {{ model.PublicVersion['ArchiveDate'] | datetime }}
                                            </div>
                                        }
                                    </div>
                                    <span class="text-xs">{{ model.PublicVersion['Title']?.Value }}</span>
                                </div>
                                @if(model.PublicVersion['VersionDate']) {
                                    <div class="gap-2.5 rounded-md bg-button px-2 py-1 text-center text-[14px] font-bold leading-none text-default">
                                        {{model.PublicVersion['VersionDate'].Value | datetime}}
                                    </div>
                                }
                               
                            </div>
                            <div class="mb-4 flex flex-col justify-between gap-4 self-stretch md:flex-row">
                                <button class="version-button flex w-full items-center" (click)="download(VersionType.Public, 'file')">
                                    <div class="accent absolute left-0 top-0 h-full w-[7px] rounded-bl rounded-tl bg-green-500"></div>
                                    <div class="flex items-center gap-4">
                                        <bizz-extension-icon class="flex w-9" [extension]="getFileExtension(VersionType.Public) ?? ''" [size]="'large'" />
                                        <h2>{{ 'OriginalFile' | translate }}</h2>
                                        @if(model.PublicVersion['Extension']){
                                            <span class="absolute right-3 top-3 items-center justify-center gap-2.5 rounded-md bg-button px-2 py-1 text-center text-[14px] font-bold leading-none text-default">
                                                {{ model.PublicVersion['Extension'].Value }}
                                            </span>
                                        }
                                    </div>
                                </button>
                                @if (model.PublicVersion['PdfFile']?.Value) {
                                    <button class="version-button w-full" (click)="download(VersionType.Public, 'pdffile')">
                                        <div class="accent absolute left-0 top-0 h-full w-[7px] rounded-bl rounded-tl bg-green-500"></div>
                                        <div class="flex items-center gap-4">
                                            <bizz-extension-icon [extension]="'.pdf'" [size]="'large'" />
                                            <h2>{{ 'PDFVersion' | translate }}</h2>
                                        </div>
                                    </button>
                                }                                
                            </div>
                        </div>
                    </div>
                }
                @if(hasDraft && hasPublic) {
                    <hr class="my-5"/>
                }
                @if(hasDraft) {
                    <!-- Draft-->
                    <div class="flex flex-row items-start gap-5 self-stretch">
                        <div class="hidden md:flex">                        
                            <bizz-icon
                                class="h-7 w-8"
                                iconName="file-pen">
                            </bizz-icon>
                        </div>
                        <div class="flex flex-grow flex-col items-start justify-center gap-5">
                            <div class="flex items-start justify-between self-stretch md:items-center">
                                <div class="flex flex-col items-start gap-1 font-bold">
                                    <div class="items-left flex flex-col justify-between gap-y-3 md:flex-row md:items-center md:gap-x-3">
                                        <h2 class="items-left flex flex-row gap-x-3 md:items-center">
                                            <div>{{ 'DraftVersion' | translate }}</div>                                   
                                            <div class="gap-2.5 rounded-md bg-button px-2 py-1 text-center leading-none text-default">
                                                {{ model.DraftVersion['Version']?.Value }}
                                            </div>
                                        </h2>                                
                                        @if (model.DraftVersion['ArchiveDate']) {
                                            <div class="text-xs text-danger">
                                                <b>{{ 'WillBeArchivedOn' | translate }}</b>
                                                {{ model.DraftVersion['ArchiveDate'] | datetime }}
                                            </div>
                                        }
                                    </div>
                                    <span class="text-xs">{{ model.DraftVersion['Title']?.Value }}</span>
                                </div>
                                @if(model.DraftVersion['VersionDate']?.Value) {
                                    <div class="gap-2.5 rounded-md bg-button px-2 py-1 text-center text-[14px] font-bold leading-none text-default">
                                        {{model.DraftVersion['VersionDate']?.Value | datetime}}
                                    </div>
                                }
                            </div>
                            <div class="mb-4 flex flex-col justify-between gap-4 self-stretch md:flex-row">
                                <button class="version-button flex w-full items-center" (click)="download(VersionType.Draft, 'file')">
                                    <div class="accent absolute left-0 top-0 h-full w-[7px] rounded-bl rounded-tl bg-blue-500"></div>
                                    <div class="flex items-center gap-4">
                                        <bizz-extension-icon class="flex w-9" [extension]="getFileExtension(VersionType.Draft) ?? ''" [size]="'large'" />
                                        <h2>{{ 'OriginalFile' | translate }}</h2>
                                        @if(model.DraftVersion['Extension']){
                                            <span class="absolute right-3 top-3 items-center justify-center gap-2.5 rounded-md bg-button px-2 py-1 text-center text-[14px] font-bold leading-none text-default">
                                                {{ model.DraftVersion['Extension'].Value }}
                                            </span>
                                        }
                                    </div>
                                </button>
                                @if (model.DraftVersion['PdfFile']?.Value) {
                                    <button class="version-button w-full" (click)="download(VersionType.Draft, 'pdffile')">
                                        <div class="accent absolute left-0 top-0 h-full w-[7px] rounded-bl rounded-tl bg-blue-500"></div>
                                        <div class="flex items-center gap-4">
                                            <bizz-extension-icon [extension]="'.pdf'" [size]="'large'" />
                                            <h2>{{ 'PDFVersion' | translate }}</h2>
                                        </div>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
</div>
