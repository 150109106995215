export enum FlowDiagramTools {
  //selection mode
  Select = 0,
  //clicking the diagram will add a new block at the clicked position
  AddBlock = 1,
  AddGateway = 2,
  AddStart = 3,
  AddStop = 4,
  addPublicationBlock = 5,
  addAssessmentBlock = 6,
  addDistributionBlock = 7,
  addTrainingAppAssessmentBlock = 8,
  addTrainingAppSubscribeToExamBlock = 9,
  addTrainingAppExaminationBlock = 10,
  addTrainingAppPublishExamBlock = 11
}

