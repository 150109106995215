import { createSelector } from '@ngrx/store';
import { ApplicationState } from './app-state';
import { appFeature } from './app-feature';

export const selectRenderState = createSelector(
  appFeature.selectAppState,
  (state: ApplicationState) => {
    return state.translationsLoaded && state.userSettingsLoaded;
  }
)
