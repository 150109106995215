@defer (when permissions) {
    <span class="flex w-fit justify-between pl-1 sm:w-full">
        <div class="hidden w-auto items-center gap-2 text-list-row-action sm:flex">
            @if (permissions.showFolderShortcut) {
                <span class="actions-cell-button text-list-row-action/50" kendoTooltip
                      [title]="'Info_FolderShortCut' | translate">
                    <bizz-icon class="actions-cell-icon" iconName="share"
                               (click)="onFolderShortcutClicked($event)"></bizz-icon>
                </span>
            }
            @if (permissions.showForwardTask) {
                <span class="actions-cell-button" kendoTooltip [title]="'ForwardTask' | translate">
                  <bizz-icon class="actions-cell-icon" (click)="onForwardTask($event)" iconName="share"></bizz-icon>
                </span>
            }
            @if (permissions.showRead || permissions.showReadTrashCan) {
                <span class="actions-cell-button" kendoTooltip [title]="'Read' | translate">
                  <bizz-icon class="actions-cell-icon" (click)="onOpenClicked($event)" iconName="eye"></bizz-icon>
                </span>
            }
            @if (permissions.showEdit) {
                <span class="actions-cell-button" kendoTooltip [title]="'Edit' | translate">
                  <bizz-icon class="actions-cell-icon" (click)="onEditClicked($event)" iconName="pencil"></bizz-icon>
                </span>
            }
            @if (permissions.showExecuteSteps) {
                <span class="actions-cell-button" kendoTooltip [title]="'ExecuteSteps' | translate">
                  <bizz-icon class="actions-cell-icon" (click)="onActionClicked($event)" iconName="bolt"></bizz-icon>
                </span>
            }
            @if (permissions.showRecover) {
                <span class="actions-cell-button" kendoTooltip [title]="'RecoverFromTrashCan' | translate">
                    <bizz-icon class="actions-cell-icon" (click)="onRecoverInstance($event)"
                               iconName="recycle"></bizz-icon>
                </span>
            }
            @if (permissions.showSubscribeToTraining) {
                <span class="actions-cell-button" kendoTooltip [title]="'SubscribeToTraining' | translate">
                  <bizz-icon class="actions-cell-icon" (click)="onSubscribeToTraining($event)"
                             iconName="briefcase"></bizz-icon>
                </span>
            }
            @if (permissions.showSubscribeToExam) {
                <span class="actions-cell-button" kendoTooltip [title]="'SubscribeToExam' | translate">
                  <bizz-icon class="actions-cell-icon" (click)="onSubscribeToExam($event)"
                             iconName="graduation-cap"></bizz-icon>
                </span>
            }
        </div>
        <span class="actions-cell-button text-list-row-action"
              id="actions-menu-icon-{{data.ID}}"
              (click)="onActionMenuIconClick($event)"
              [hidden]="!permissions.showDropdownMenu"
              #menuIcon>
              <bizz-icon class="actions-cell-menu-icon" iconName="ellipsis-vertical"></bizz-icon>
        </span>
    </span>
}
