import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { SelectEvent, StepperActivateEvent, StepperModule, TabStripModule } from '@progress/kendo-angular-layout';
import { TranslatePipe } from '../../../pipes/translate/translate.pipe';
import { StepType } from '@progress/kendo-angular-layout/stepper/models/step-type';
import { SafePipe } from 'safe-pipe';
import { StepperStep } from './interfaces/stepper-step';
import { IconLibrary } from 'src/models/ts/icon-library.model';

@Component({
  selector: 'bizz-stepper',
  standalone: true,
  imports: [CommonModule, IconComponent, StepperModule, TranslatePipe, TabStripModule, SafePipe],
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnChanges {

  /**
   * The index of the current step
   */
  @Input({ required: true }) public currentStep = 0;
  @Output() public currentStepChange = new EventEmitter<number>();

  /**
   * The steps to display
   * A step is an object with a label and an iconName and iconLibrary.
   */
  @Input({ required: true }) public steps: StepperStep[] = [];

  /**
   * Whether the user can navigate to all steps
   * or only the next/previous step
   */
  @Input() public linear = false;

  /**
   * Fired when the user clicks on a step
   */
  @Output() public activate = new EventEmitter<StepperStep>();

  /**
   * If true the activate event will not be fired, and the current step will not change
   */
  @Input() public disabled = false;

  /**
   * If true the stepper will be displayed as a wizard otherwise a row of buttons is shown.
   */
  @Input() public wizard = true;

  /**
  * If true the tab will be scrollable.
  */
  @Input() public scrollable = false;

  @Output() public validate = new EventEmitter<number>();

  public stepType: StepType = 'full';

  public maxWidthCss: string = 'max-width: 100%';

  public iconLibrary = IconLibrary;


  public ngOnInit(): void {
    this.stepType = this.wizard ? 'full' : 'label';
    this.maxWidthCss = `max-width: ${100 / this.steps.length}%;`;
  }

  public onStepActivate($event: StepperActivateEvent): void {
    if (!this.disabled) {
      if(!$event.originalEvent.defaultPrevented){
        this.currentStep = $event.index;
        this.currentStepChange.emit(this.currentStep);
        this.activate.emit(this.steps[this.currentStep]);
      }else{
        $event.preventDefault();
        this.validate.emit($event.index);
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      this.steps.forEach((step) => {
        step.disabled = this.disabled;
      });
    }
  }

  public onTabSelect($event: SelectEvent): void {
    this.currentStep = $event.index;
    this.currentStepChange.emit(this.currentStep);
    this.activate.emit(this.steps[this.currentStep]);
}

}



