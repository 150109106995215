import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, take } from 'rxjs';
import { GridDataDto } from 'src/models/ts/grid-data-dto.model';
import { CollectionListDataInstance } from 'src/app/shared/interfaces/collection-list-data-instance';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { FileHistoryDto } from 'src/models/ts/file-history-dto.model';

@Injectable({
  providedIn: 'root'
})
export class ArchiveApiService {
  public constructor(private http: HttpClient, private downloadService: DownloadService) { }

   /***
   * Returns the archive data for the given collection, instance.
   * @param collectionsId  The ID of the collection.
   * @param instancesId The ID of the instance.
   * @returns {Observable<GridDataDto>} The archive data.
   */
  public getArchiveData(collectionsId: number, instancesId: number): Observable<GridDataDto> {
    return this.http.post<GridDataDto>(`api/collection/${collectionsId}/instance/${instancesId}/history`, {});
  }

  /***
   * Returns the contents of a downloaded file version.
   * @param dataItem The data item of the file to download.
   * @returns {Observable<ArrayBuffer>} The file contents
   */
  public downloadFile(dataItem: CollectionListDataInstance): Subscription | undefined {
    const {Title: title, Extension: extension, CollectionsID: collectionsId, ID: instanceId, VersionsID: versionsId, File: file} = dataItem;

    if (!collectionsId || !instanceId || !versionsId || !file) {
      throw new Error('CollectionsID, InstanceID, VersionsID and FileID are required to download a file');
    }

    const fileName = title && extension ? title + extension : undefined;

    return this.http.post(
      `api/collection/${collectionsId}/instance/${instanceId}/version/${versionsId}/media/${file}/download`, 
      {},
      { responseType: 'arraybuffer' })
    .pipe(take(1))
    .subscribe((data: ArrayBuffer) => {
      this.downloadService.startDownload(data, fileName);
    });      
  }

  /***
   * Returns the history of a specific version of a file.
   * @param dataItem The data item of the file to download.
   * @returns {Observable<FileHistoryDto>} The file history data
   */
  public getFileVersions(dataItem: CollectionListDataInstance): Observable<FileHistoryDto> {
    const {CollectionsID: collectionsId, ID: instanceId, VersionsID: versionsId,} = dataItem;

    if (!collectionsId || !instanceId || !versionsId) {
      throw new Error('CollectionsID, InstanceID and VersionsID are required to get the versions of a file');
    }

    return this.http.get<FileHistoryDto>(
      `api/collection/${collectionsId}/instance/${instanceId}/version/${versionsId}/files`,
      {}
    ).pipe(take(1));
  }

  /***
   * Compares two versions of a file.
   * @param dataItem The data item of the file to download.
   * @returns {Observable<any>} The file comparison
   */
  public compareFile(request: {collectionId: number, instanceId:number}): Observable<void>{

    return this.http.get<void>(
      `api/collection/${request.collectionId}/instance/${request.instanceId}/compare`);
  }
}
