import { AfterViewInit, Component, EventEmitter, inject, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { FormAccordionHeaderComponent } from '../form-accordion-header/form-accordion-header.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { CollectionForm } from '../../../../../../models/ts/collection-form.model';
import { CollectionLockType } from '../../../../../../models/ts/collection-lock-type.model';
import { Store } from '@ngrx/store';
import { userSettingsFeature } from '../../../../../store/features/user-settings/user-settings-feature';
import { UserType } from 'src/models/ts/user-type.model';
import { DatetimePipe } from 'src/app/shared/pipes/dates/datetime.pipe';
@Component({
  selector: 'bizz-form-lock',
  templateUrl: './form-lock.component.html',
  styleUrls: ['./form-lock.component.scss'],
  imports: [
    TitleCasePipe,
    DatetimePipe,
    TranslatePipe,
    IconComponent,
    CommonModule,
    FormAccordionHeaderComponent,
  ],
  standalone: true,
})
export class FormLockComponent implements AfterViewInit {
  private store$ = inject(Store);
  public userType = this.store$.selectSignal(userSettingsFeature.selectUserType);
  public readonly UserType = UserType;

  public activeLockTemplateRef: TemplateRef<any>;
  @Input({ required: true }) public formData: CollectionForm;
  @Output() public formUnlocked = new EventEmitter<boolean>();
  @ViewChild('selfLock') public selfLock: TemplateRef<any>;
  @ViewChild('lock') public lock: TemplateRef<any>;
  @ViewChild('managerLock') public managerLock: TemplateRef<any>;

  public ngAfterViewInit(): void {
    // Set the correct lock template
    if (this.formData.LockType == CollectionLockType.Selflock) {
      this.activeLockTemplateRef = this.selfLock;
    } else if (this.formData.Permissions['Manager']) {
      this.activeLockTemplateRef = this.managerLock;
    } else {
      this.activeLockTemplateRef = this.lock;
    }
  }

  public unlock(asManager = false): void {
    this.formUnlocked.emit(asManager);
  }
}
