import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { formsActions } from '../forms-actions';
import { exhaustMap, filter, map, of, zip } from 'rxjs';
import { selectForm, selectFormField, selectFormFieldByPredicate, selectGridRecordField } from '../forms-selectors';
import { Store } from '@ngrx/store';
import { FormulaService } from '../../../../features/bizzmine/form/services/formula/formula.service';
import { concatLatestFrom } from '@ngrx/operators';
import { CollectionFormService } from '../../../../features/bizzmine/form/services/collection-form.service';
import { CollectionFormFormulaDto } from '../../../../../models/ts/collection-form-formulas-tag-dto.model';

@Injectable()
export class FormsFormulaEffects {
  private actions$ = inject(Actions);
  private formulaService = inject(FormulaService);
  private store$ = inject(Store);
  public calculateFieldFormulas$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.calculateFormulaField),
    concatLatestFrom(({ formId, fieldId }) => this.store$.select(selectForm(formId))),
    map(([{ formId, fieldId }, form]) => {
      if (form && form.data)
        this.formulaService.calculateField(formId, structuredClone(form.data), fieldId);
    })
  ), { dispatch: false });
  public calculateGridFieldFormulas$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.calculateGridFormulaField),
    concatLatestFrom(({ formId, fieldId }) => this.store$.select(selectForm(formId))),
    map(([{ formId, fieldId }, form]) => {
      if (form && form.data)
        this.formulaService.calculateField(formId, structuredClone(form.data), fieldId);
    })
  ), { dispatch: false });
  public calculateFormFormulas$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.calculateFormulas),
    concatLatestFrom(({ formId }) => this.store$.select(selectForm(formId))),
    map(([{ formId }, form]) => {
      if (form && form.data)
        this.formulaService.calculate(formId, form.data);
    })
  ), { dispatch: false });
  public calculateSetFormulas$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.calculateSetFormulas),
    concatLatestFrom((props) => this.store$.select(selectForm(props.formId))),
    map(([props, form]) => {
      if (form && form.data) {
        // Replace form formulas with the new set of formulas provided in the action
        let formData = structuredClone(form.data);
        formData.Formulas = Array.from(props.formulas);
        this.formulaService.calculate(props.formId, form.data);
      }
    })
  ), { dispatch: false });
  public checkForFormula$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateFormFieldValue),
    exhaustMap(({ formId, fieldId }) => {
      return zip([
        of({ formId: formId, fieldId: fieldId }),
        this.store$.select(selectForm(formId)),
        this.store$.select(selectFormField(formId, fieldId))
      ]);
    }),
    filter(([{
      fieldId
    }, form, field]) => form !== undefined && field !== undefined && FormulaService.fieldFormulas(form!.data.Formulas, fieldId).length > 0),
    map(([{ formId, fieldId }]) => ({
      type: formsActions.calculateFormulaField.type,
      formId: formId,
      fieldId: fieldId
    }))
  ));
  public checkGridForFormula$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateGridFormFieldValue),
    concatLatestFrom((props) => [
      this.store$.select(selectForm(props.formId)),
      this.store$.select(selectGridRecordField(props.formId, props.gridFieldId, props.recordId, field => field.CollectionFieldsID == props.recordFieldId))
    ]),
    filter(([props, form, field]) =>
      form !== undefined &&
      field !== undefined &&
      FormulaService.fieldFormulas(form.data.Formulas, field.Id).length > 0),
    map(([props, form, field]) => ({
      type: formsActions.calculateGridFormulaField.type,
      formId: props.formId,
      gridFieldId: props.gridFieldId,
      recordId: props.recordId,
      fieldId: field!.Id
    }))
  ));
  public checkGridRecordForFormulaOnUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateGridRecord),
    concatLatestFrom((props) => [
      this.store$.select(selectForm(props.formId)),
      this.store$.select(selectFormFieldByPredicate(props.formId, field => field.ViewDataSourcesID == props.viewDataSourceId && CollectionFormService.fieldIsGrid(field)))
    ]),
    map(([props, form, gridField]) => {
      if (form !== undefined && gridField !== undefined) {
        return { props, form, gridField, formulas: FormulaService.gridFormulas(form.data.Formulas, gridField) }
      } else {
        return { props, form, gridField, formulas: new Set<CollectionFormFormulaDto>() };
      }
    }),
    filter(({props, form, gridField, formulas}) => formulas.size > 0),
    map(({props, form, gridField, formulas}) =>
      formsActions.calculateSetFormulas({
        formId: props.formId,
        formulas: formulas
      })
    )
  ));
  public checkGridRecordForFormulaOnDelete$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.removeRecordFromGrid),
    concatLatestFrom((props) => [
      this.store$.select(selectForm(props.formId)),
      this.store$.select(selectFormField(props.formId, props.gridFieldId))
    ]),
    map(([props, form, gridField]) => {
      if (form !== undefined && gridField !== undefined) {
        return { props, form, gridField, formulas: FormulaService.gridFormulas(form.data.Formulas, gridField) }
      } else {
        return { props, form, gridField, formulas: new Set<CollectionFormFormulaDto>() };
      }
    }),
    filter(({props, form, gridField, formulas}) => formulas.size > 0),
    map(({props, form, gridField, formulas}) =>
      formsActions.calculateSetFormulas({
        formId: props.formId,
        formulas: formulas
      })
    )
  ));
  public checkForFormulaOnOutput$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateFormulaOutputField),
    exhaustMap(({ formId, collectionFieldId }) => {
      return zip([
        of({ formId: formId, collectionFieldId: collectionFieldId }),
        this.store$.select(selectForm(formId)),
        this.store$.select(selectFormFieldByPredicate(formId, field => field.CollectionFieldsID == collectionFieldId))
      ]);
    }),
    filter(([{
      collectionFieldId
    }, form, field]) => form !== undefined && field !== undefined && FormulaService.fieldFormulas(form!.data.Formulas, field.Id).length > 0),
    map(([{ formId }, form, field]) => ({
      type: formsActions.calculateFormulaField.type,
      formId: formId,
      fieldId: field!.Id
    }))
  ));
}