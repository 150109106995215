@if (formFieldSignal(); as formField) {
    <kendo-timepicker #input
            [formControl]="formControl"
            [ngClass]="{'hidden' : formField.IsHidden}"
            [placeholder]="''"
            [allowCaretMode]="true"
            [readonly]="readOnlyOverride || formField.IsReadOnly"
            [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0"
    ></kendo-timepicker>
}
