import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { AuditLogInfoDto } from 'src/models/ts/audit-log-info-dto.model';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { AuditLogEventType } from 'src/models/ts/audit-log-event-type.model';
import { IconComponent } from 'src/app/shared/components/ui/icon/icon.component';
import { PopperComponent } from 'src/app/shared/components/ui/popper/popper.component';
import { DatetimePipe } from 'src/app/shared/pipes/dates/datetime.pipe';
import { ModalComponent } from 'src/app/shared/components/modals/modal/modal.component';
import { GridModule } from '@progress/kendo-angular-grid';

@Component({
  selector: 'bizz-auditlog-info-modal',
  standalone: true,
  imports: [CommonModule, TextAreaModule, TranslatePipe, IconComponent, PopperComponent, DatetimePipe, ModalComponent, GridModule],
  templateUrl: './auditlog-info-modal.component.html',
  styleUrls: ['./auditlog-info-modal.component.scss'],
})

/**
 * A Generic Dialog Modal which shows a title, description
 * and optionally a confirmation input.
 * @returns the button that was pressed.
 */
export class AuditlogInfoModalComponent {
  public activeContentTemplateRef: TemplateRef<any>;
  public modalSize: string = 'sm';

  public constructor(
    @Inject(DIALOG_DATA) public auditLogInfo: AuditLogInfoDto) {
  }

  protected readonly AuditLogEventType = AuditLogEventType;

  public ngOnInit(): void {

  }
}
