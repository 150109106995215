import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ThemeDto } from 'src/models/ts/theme-dto.model';

@Component({
  selector: 'bizz-theme-preview-icon-simple',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './theme-preview-icon-simple.component.html',
  styleUrl: './theme-preview-icon-simple.component.scss'
})
export class ThemePreviewIconSimpleComponent {
  @ViewChild('iconComponent', { static: false }) public iconComponent: ElementRef;
  
  public internalTheme: ThemeDto;

  @Input({ required: true }) public set theme(theme: ThemeDto) {  
    this.internalTheme = theme;
    this.applyTheme(this.internalTheme);
  }
  @Input() public layoutType: 'horizontal' | 'vertical' = 'vertical';
  @Input() public isReadOnly = false;
  @Input() public isSelected: boolean = false;
  @Input() public isInput = false;
  @Output() public themeSelected: EventEmitter<ThemeDto> = new EventEmitter<ThemeDto>();

  public ngAfterViewInit(): void {
    this.applyTheme(this.internalTheme);
  }

  private applyTheme(theme: ThemeDto): void {
    if (theme?.BrandingV2) {
      const branding = theme.BrandingV2;
      const el = this.iconComponent?.nativeElement;
      if(!el) {
        return;
      }
      for (const [key, value] of Object.entries(branding)) {
        el.style.setProperty(key, value);
      }
    }
  }

  public onClick(): void {
    this.themeSelected.emit(this.internalTheme);
  }
}
