<kendo-grid [data]="assessmentList" class="max-w-[1000px] text-start font-medium text-widget">
    <!-- Mobile single column-->
    <kendo-grid-column [media]="'(max-width: ' + MAX_MOBILE_WIDTH + 'px)'"
                       class="text-xs" headerClass="hidden">
      <ng-template kendoGridCellTemplate let-assessment>
        <div class="my-1 flex flex-col gap-2">
          <div class="text-sm font-bold text-default"> {{ 'Assessment' | translate }}</div>
          <div class="word-wrap flex w-full items-center gap-2.5 text-start text-xs">
            <span>{{assessment.AssessmentTypeText}}</span> &nbsp;
            @if(assessment.IsVeto) {
                <span>( {{ "Veto" | translate }})</span>
            }
          </div>
          <div class="text-sm font-bold text-default"> {{ 'AssignedTo' | translate }}</div>
          <div class="word-wrap flex w-full items-center gap-2.5 text-start text-xs">
            <span>
              {{assessment.UsersName}}
              @if(assessment.IsFallback) {
                <span>
                    <br />
                    @if(assessment.NumberOfOriginalAssignedTo > 0) {
                        {{"AsFallbackFor"| translate }} {{assessment.OriginalAssignedTo}}
                    }
                    @if(assessment.NumberOfOriginalAssignedTo == 0) {
                        {{"AsFallbackForNoAssignedUser"| translate }}
                    }
                </span>
            }
          </span>
          </div>
          <div class="text-sm font-bold text-default"> {{ 'CompletedBy' | translate }}</div>
          <div class="word-wrap flex w-full items-center gap-2.5 text-start text-xs">
            @if(assessment.CompletedByID > 0 && assessment.CompletedByID == assessment.CompletedOnBehalfOfID) {
                {{assessment.UsersName}}
            }
            @if(assessment.CompletedByID > 0 && assessment.CompletedByID != assessment.CompletedOnBehalfOfID) {
                {{assessment.ExecutedBy}} {{"OnBehalfOf" | translate }} {{assessment.UsersName}}
            }
          </div>
          <div class="text-sm font-bold text-default"> {{ 'CompletedOn' | translate }}</div>
          <div class="word-wrap flex w-full items-center gap-2.5 text-start text-xs">
            @if(assessment.AssessmentTypeText  !== null) {
                {{assessment.Date | utcDateTime }}
            } @else if (assessment.AssessmentTypeText  == null) {
                <span>-</span>
            }
          </div>
          <div class="text-sm font-bold text-default"> {{ 'Remarks' | translate }}</div>
          <div class="word-wrap flex w-full items-center gap-2.5 text-start text-xs">
            @if(assessment.Remarks  !== null) {
                <span > {{ assessment.Remarks }}</span>
            }
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{ 'Assessment' | translate }}" [media]="'(min-width: ' + MAX_MOBILE_WIDTH + 'px)'"
                       class="text-xs">
      <ng-template kendoGridCellTemplate let-assessment>
        <span>{{assessment.AssessmentTypeText}}</span> &nbsp;
        @if(assessment.IsVeto) {
            <span>( {{ "Veto" | translate }})</span>
        }
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{ 'AssignedTo' | translate }}" [media]="'(min-width: ' + MAX_MOBILE_WIDTH + 'px)'"
                       class="text-xs">
      <ng-template kendoGridCellTemplate let-assessment>
        <span>
            {{assessment.UsersName}}
            @if(assessment.IsFallback) {
                <br />
                @if(assessment.NumberOfOriginalAssignedTo > 0) {
                    {{"AsFallbackFor"| translate }} {{assessment.OriginalAssignedTo}}
                }
                @if(assessment.NumberOfOriginalAssignedTo == 0) {
                    {{"AsFallbackForNoAssignedUser"| translate }}
                }
            }
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{ 'CompletedBy' | translate }}" [media]="'(min-width: ' + MAX_MOBILE_WIDTH + 'px)'"
                       class="text-xs">
      <ng-template kendoGridCellTemplate let-assessment>
        @if(assessment.CompletedByID > 0 && assessment.CompletedByID == assessment.CompletedOnBehalfOfID)
        {
            <span>{{assessment.UsersName}}</span>
        }
        @else if(assessment.CompletedByID > 0 && assessment.CompletedByID != assessment.CompletedOnBehalfOfID)
        {
            <span>{{assessment.ExecutedBy}} {{"OnBehalfOf" | translate }} {{assessment.UsersName}}</span>
        }
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{ 'CompletedOn' | translate }}" [media]="'(min-width: ' + MAX_MOBILE_WIDTH + 'px)'"
                       class="text-xs">
      <ng-template kendoGridCellTemplate let-assessment>
        @if(assessment.AssessmentTypeText  !== null) {
            {{assessment.Date | utcDateTime }}
        } @else if (assessment.AssessmentTypeText  == null) {
            <span>-</span>
        }
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{ 'Remarks' | translate }}" field="Remarks"
                       [media]="'(min-width: ' + MAX_MOBILE_WIDTH + 'px)'"
                       [width]="200"
                       class="text-xs">
    </kendo-grid-column>
  </kendo-grid>