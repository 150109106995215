import { inject, Injectable } from '@angular/core';
import { VersionStateType } from '../../../../models/ts/version-state-type.model';
import { CollectionInstanceCompareDto } from '../../../../models/ts/collection-instance-compare-dto.model';
import { InstanceComparisonModalModel } from '../../../shared/components/modals/data-collection/compare-base-modal/compare-modal-model';
import { EditDraftOrPublishedModalComponent } from '../../../shared/components/modals/data-collection/compare-base-modal/variants/edit-draft-or-published-modal/edit-draft-or-published-modal.component';
import {
  FileUploadEditOnlineModalComponentData,
  FileUploadEditOnlineModalComponentMode
} from '../../../../models/ts/file-upload-edit-online-modal-component-mode';
import { FileUploadEditOnlineModalComponent } from '../../../shared/components/file-upload/file-upload/file-upload-edit-online-modal/file-upload-edit-online-modal.component';
import { take } from 'rxjs';
import { GridService } from '../../../features/bizzmine/widgets/collection-list-widget/services/grid/grid.service';
import { Dialog } from '@angular/cdk/dialog';

@Injectable({
  providedIn: 'root'
})
export class EditOnlineService {
  private gridservice = inject(GridService);
  private dialog = inject(Dialog);

  /**
   * Opens the view online modal - checks for draft (with compare) and or DocumentSatus.
   * @param {{CollectionsID: number, ID: number, VersionsID: number, DraftsID: number, CanReadDrafts: boolean, DocumentStatus: VersionStateType}} dataItem
   */
  public openViewOnline(dataItem: {
    CollectionsID: number,
    ID: number,
    VersionsID: number,
    DraftsID: number,
    CanReadDrafts: boolean,
    DocumentStatus:VersionStateType
  }): void {
    if(dataItem.CanReadDrafts){
      if(dataItem.DraftsID != null && dataItem.DraftsID > 0){
        this.gridservice.getInstanceComparisonData(dataItem.CollectionsID, 0, dataItem.ID, dataItem.VersionsID, dataItem.DraftsID, false).subscribe({
          next: (comparisonData: CollectionInstanceCompareDto) => {
            const dlgRef = this.dialog.open<InstanceComparisonModalModel>(
              EditDraftOrPublishedModalComponent,
              {
                data: {
                  CollectionsID: dataItem.CollectionsID,
                  OriginalFoldersID: 0,
                  ID: dataItem.ID,
                  VersionsID: dataItem.VersionsID,
                  DraftsID: dataItem.DraftsID,
                  comparison: comparisonData,
                  onSelectVersionType: (version: number, newTab: boolean) => {
                    this.openViewOnlineWithoutDraftValidation({
                      ID: dataItem.ID,
                      VersionsID: version,
                      CollectionsID: dataItem.CollectionsID
                    });
                    dlgRef.close();
                  }
                }
              },
            )
          },
        });
      } else {
        this.openViewOnlineWithoutDraftValidation({
          ID: dataItem.ID,
          VersionsID: dataItem.VersionsID,
          CollectionsID: dataItem.CollectionsID
        });
      }
    } else if(dataItem.DocumentStatus == null || dataItem.DocumentStatus == VersionStateType.Active){
      this.openViewOnlineWithoutDraftValidation({
        ID: dataItem.ID,
        VersionsID: dataItem.VersionsID,
        CollectionsID: dataItem.CollectionsID
      });
    } else {
      throw new Error('Cannot view Instance online (no edit permission and no public version)');
    }

  }

  /**
   * Opens the view online modal without checking if the instance has a draft version.
   * @param {{CollectionsID: number, ID: number, VersionsID: number}} dataItem
   */
  public openViewOnlineWithoutDraftValidation(dataItem: {
    CollectionsID: number,
    ID: number,
    VersionsID: number
  }): void {
    const params: FileUploadEditOnlineModalComponentData = {
      mode: FileUploadEditOnlineModalComponentMode.View,
      docheckoutcheckin: true,
      fromGrid: {collectionId: dataItem.CollectionsID,
        instanceId: dataItem.ID,
        versionId: dataItem.VersionsID},
      mediaId: 0,
      filename: '',
      modificationreason: '',
      closeModalMethod: (): void => {
        dlgRef.close();
      },
      finishMethod: (data: any): void => {
        if(data.openedOneDriveWindow){
          data.openedOneDriveWindow.close();
        }
        dlgRef.close();
      }
    };
    const dlgRef = this.dialog.open(FileUploadEditOnlineModalComponent, {
      data: params,
      disableClose: true
    });
    dlgRef.closed.pipe(take(1)).subscribe();
  }
}
