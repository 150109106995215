import { Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { IconComponent } from '../../../../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../../../../shared/pipes/translate/translate.pipe';
import { AsyncPipe, NgClass } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { TrnExamQuestion } from '../../../../../../../../../models/ts/trn-exam-question.model';
import { FormsModule } from '@angular/forms';
import { EditorModule } from '@progress/kendo-angular-editor';
import { UpdateDeleteState } from 'src/models/ts/update-delete-state.model';
import { TrnExamAnswer } from '../../../../../../../../../models/ts/trn-exam-answer.model';
import { CheckBoxModule, NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'bizz-exam-creator-add-question-control',
  standalone: true,
  imports: [
    IconComponent,
    TranslatePipe,
    NgClass,
    AsyncPipe,
    FormsModule,
    EditorModule,
    CheckBoxModule,
    NumericTextBoxModule,
    TooltipModule
  ],
  templateUrl: './exam-creator-add-question-control.component.html',
  styleUrl: './exam-creator-add-question-control.component.scss'
})
export class ExamCreatorAddQuestionControlComponent {
  public question$ = new BehaviorSubject<TrnExamQuestion>({} as TrnExamQuestion);
  public hasQuestionErrors = false;
  public hasAnswerErrors = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UpdateDeleteState = UpdateDeleteState;

  public constructor(private dialogRef: DialogRef) {
    // setting value or init a new one for create
    this.question$.next(structuredClone(this.dialogRef.config.data) ?? { Answers: [] });
  }

  public close(): void {
    this.dialogRef.close(undefined);
  }

  public saveQuestion(): void {
    if (this.validate()) {
      this.dialogRef.close(this.question$.value);
    }

  }

  public delete(index: number): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const found = this.question$.value;
    found.Answers[index].State = UpdateDeleteState.Delete;
    this.question$.next(found);
  }

  public addAnswer(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const found = this.question$.value;
    found.Answers.push({ Score: 0 } as TrnExamAnswer);
    this.question$.next(found);
  }

  public validate(): boolean {

    this.hasQuestionErrors = this.question$.value.Caption == null || this.question$.value.Caption == '';
    this.hasAnswerErrors = false;
    this.question$.value.Answers
      .filter(el=> el.State != UpdateDeleteState.Delete) // dont validate deleted records (duh xD)
      .forEach((answer) => {
        if (answer.Caption == null || answer.Caption == '') {
          this.hasAnswerErrors = true;
        }
      }
    );
    if (this.question$.value.Answers.filter(answers => answers.Score > 0).length <= 0) {
      this.hasAnswerErrors = true;
    }
    return !this.hasQuestionErrors && !this.hasAnswerErrors;
  }

}
