<ng-container>
  <gojs-diagram
      #bizzDiagram
      class="w-full h-full min-h-[inherit] block border-button"
      [divClassName]="'min-h-[inherit] w-full h-full block'"
  ></gojs-diagram>
  <div class="relative flex gap-1.5 bottom-[calc(100%_-_10px)] left-[calc(100%_-_116px)] z-10">
    <button
      class="default w-8 h-8 flex justify-center items-center hover:cursor-pointer"
      (click)="zoomIn()">
      <bizz-icon iconName="magnifying-glass-plus" class="min-h-[16px] min-w-[16px]"></bizz-icon>
    </button>
    <button
      class="default w-8 h-8 flex justify-center items-center hover:cursor-pointer"
      (click)="zoomOut()">
      <bizz-icon iconName="magnifying-glass-minus" class="min-h-[16px] min-w-[16px]"></bizz-icon>
    </button>
    <button
      class="default w-8 h-8 flex justify-center items-center hover:cursor-pointer"
      (click)="zoomToFit()">
      <bizz-icon iconName="square-dashed" class="min-h-[16px] min-w-[16px]"></bizz-icon>
    </button>
  </div>

</ng-container>