<div class="h-6 w-5 w-6 sm:h-5" #anchor (click)="togglePopup()">
    <bizz-icon class="header-popup-icon h-6 w-5 sm:h-5" [hover]="true" iconName="circle-question"></bizz-icon>
</div>

@if(showPopup){
<kendo-popup [anchor]="anchor"
             [animate]="true"
             [popupClass]="'flex w-screen sm:w-auto h-auto sm:justify-center sm:rounded'"
             class="z-[1004] mt-12 sm:mt-0 sm:rounded maxw-sm:!top-0"
             [positionMode]="'absolute'"
             [anchorAlign]="anchorAlign"
             [popupAlign]="popupAlign" #popup>
  <div class="bizz-popup-content">
    <button (click)="openHelpCenter()">
      <bizz-icon [title]="'HelpCenter' | translate"
                 class="mr-2 size-6 sm:size-4" [hover]="true"
                 iconName="circle-question">
      </bizz-icon>
      {{'HelpCenter' | translate}}
    </button>
    <button (click)="openSupport()">
      <bizz-icon [title]="'GetSupport' | translate"
                 class="mr-2 size-6 sm:size-4" [hover]="true"
                 iconName="comments-question">
      </bizz-icon>
      {{'GetSupport' | translate}}
    </button>
  </div>

</kendo-popup>
}
