import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TaskPopUpListDto } from '../../../../models/ts/task-pop-up-list-dto.model';

export const headerTasksActions = createActionGroup({
  source: 'Header Tasks',
  events: {
    fetchTasks: emptyProps(),
    tasksFetched: props<{tasks: TaskPopUpListDto}>(),
    updateSearch: props<{search: string}>()
  }
});
