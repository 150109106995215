@if (fileSelectionError) {
    <small class="text-danger">{{ fileSelectionError }}</small>
}
<div class="h-full w-full rounded border-2 border-form-control"
     [ngClass]="{'hidden' : formFieldSignal()?.IsHidden}"
     [ngStyle]="(maxWidth > 0) ? {'max-width': maxWidth + 'px'} : {}">
    @if (canUseToolbar()) {

        <kendo-toolbar class="w-full" [overflow]="isInGrid"
                       [ngStyle]="(maxWidth > 0) ? {'max-width': maxWidth + 'px'} : {}">
            <ng-container>
                @if (canEditImage) {
                    <bizz-custom-canvas-file-upload (fileSelected)="onImageUpload($event)"
                                                    (error)="onImageUploadError($event)"
                                                    [extensions]="['image/png', 'image/gif', 'image/jpeg']"></bizz-custom-canvas-file-upload>
                }
                @if (canDraw) {

                    <bizz-custom-canvas-toolbar-draw-line [(value)]="strokeWidth"
                                                          (valueChange)="onStrokeWidthChange($event)"></bizz-custom-canvas-toolbar-draw-line>
                    <bizz-custom-canvas-toolbar-colorpicker #colorPickerToolbar [(value)]="color"
                                                            (valueChange)="setNewColor($event)"></bizz-custom-canvas-toolbar-colorpicker>
                }
                @if (actions != null && actions.length > 0) {

                    <bizz-custom-canvas-actions
                            [actions]="actions"
                            (revertImage)="revertImage()"
                            (removeAll)="removeAll()"
                            (removeImage)="removeImage()"
                            (removeStrokes)="removeStrokes()"
                    ></bizz-custom-canvas-actions>
                }
                @if (canDraw) {
                    <bizz-custom-canvas-toolbar-button iconName="rotate-left" tooltip="Undo"
                                                       (click)="undo()"></bizz-custom-canvas-toolbar-button>
                    <bizz-custom-canvas-toolbar-button iconName="eraser"
                                                       (click)="color = ''; setNewColor('')"
                                                       tooltip="Erase"></bizz-custom-canvas-toolbar-button>
                }

            </ng-container>
        </kendo-toolbar>
    }

    <div class="relative h-full w-full select-none rounded-b">
        <img class="z-0 h-auto w-full pointer-events-none"
             [ngStyle]="(maxWidth > 0) ? {'max-width': maxWidth + 'px'} : {}"
             [style.visibility]="hasImg ? '' : 'hidden'" #SignaturePadImageElement/>

        <canvas class="z-1 absolute left-0 top-0 h-auto w-full cursor-crosshair"
                [ngStyle]="canDraw? {} : {'display': 'none'}" #SignaturePadElement>

        </canvas>
    </div>

    <canvas class="hidden" [id]="hiddenProcessCanvasId">

    </canvas>
</div>