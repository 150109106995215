import { Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ControlOptions } from '../../interfaces/control-options.interface';

/**
 * Represents a control that allows the user to select a date.
 */
@Component({
  selector: 'bizz-shared-date-picker-control',
  standalone: true,
  imports: [CommonModule, DatePickerModule, ReactiveFormsModule],
  templateUrl: './date-picker-control.component.html',
  styleUrls: ['./date-picker-control.component.scss']
})
export class SharedDatePickerControlComponent {
  
  @Input() control: FormControl;
  @Input() options: ControlOptions;

}
export interface DatePickerOptions extends ControlOptions {

}