import { flowStatusActions } from './flow-status-actions';
import { exhaustMap, map, tap } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StoreFlowStatus } from './flow-status-state';
import { ViewStackService } from '../../../shared/services/view-stack/view-stack.service';
import { Injectable } from '@angular/core';
import { ViewStackItem } from '../../../shared/classes/view-stack-item';
import { CollectionFlowStatusApiService } from '../../../api/bizzmine/collection-flow-status/collection-flow-status-api.service';
import { ViewStackFlowStatusComponent } from '../../../features/bizzmine/flow-status/components/view-stack-flowstatus/view-stack-flow-status.component';

@Injectable()
export class FlowStatusEffects {

  public getFlowStatus$ = createEffect(() => this.actions$.pipe(
    ofType(flowStatusActions.getFlowStatus),
    exhaustMap(({ collectionsID, instancesID, versionsID, methodType }) =>
      this.collectionFlowStatusApiService.getFlowStatus(collectionsID, instancesID, versionsID, methodType)
        .pipe(
          map(data => ({ type: flowStatusActions.setFlowStatus.type, data: new StoreFlowStatus(data) }))
        )
    )
  ));
  public getFlowStatusForViewStack$ = createEffect(() => this.actions$.pipe(
    ofType(flowStatusActions.getFlowStatusForViewStack),
    exhaustMap(({ collectionsID, instancesID, versionsID, methodType }) =>
      this.collectionFlowStatusApiService
        .getFlowStatus(collectionsID, instancesID, versionsID, methodType)
        .pipe(
          map(data => ({ type: flowStatusActions.flowStatusFetched.type, data: new StoreFlowStatus(data) }))
        )
    )
  ));

  public flowStatusFetched$ = createEffect(() => this.actions$.pipe(
    ofType(flowStatusActions.flowStatusFetched),
    map(({ data }) => ({
      type: flowStatusActions.addFlowStatusToViewStack.type,
      data: data
    }))
  ));

  public addFlowStatusToViewStack$ = createEffect(() => this.actions$.pipe(
    ofType(flowStatusActions.addFlowStatusToViewStack),
    tap(({ data }) => {
      this.viewStackService.addItemToStack(new ViewStackItem(ViewStackFlowStatusComponent, data.id));
    })
  ), { dispatch: false });

  public constructor(
    private actions$: Actions,
    private collectionFlowStatusApiService: CollectionFlowStatusApiService,
    private viewStackService: ViewStackService) {
  }


}