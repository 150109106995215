import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GridDataDto } from 'src/models/ts/grid-data-dto.model';
import { TableViewItemInListDto } from 'src/models/ts/table-view-item-in-list-dto.model';
import { SchedulerDto } from 'src/models/ts/scheduler-dto.model';
import { GridSearchDto } from 'src/models/ts/grid-search-dto.model';

@Injectable({
  providedIn: 'root'
})
export class CollectionSchedulerApiService {
  private route: string = 'api/scheduler';
  constructor(private http: HttpClient) { }

  public searchScheduler(widgetsId: number, state: number, search: Partial<GridSearchDto>): Observable<GridDataDto> {
    return this.http.post<GridDataDto>(`${this.route}/${widgetsId}/state/${state}/search`, search);
  }

  public getList(widgetsId: number): Observable<Array<TableViewItemInListDto>> {
    return this.http.get<Array<TableViewItemInListDto>>(`${this.route}/${widgetsId}/views`);
  }

  public postScheduler(model: SchedulerDto){
    return this.http.post<void>(`${this.route}`, model);
  }

  public deleteScheduler(schedulersId: number){
    return this.http.delete<void>(`${this.route}/${schedulersId}`);
  }

  public restoreScheduler(schedulersId: number){
    return this.http.post(`${this.route}/${schedulersId}/restore`, null);
  }
  
  public validateScheduler(model: SchedulerDto) {
    return this.http.post(`${this.route}/validate`, model);
  }

}
