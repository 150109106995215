import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerModule, TimePickerComponent, TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { DatePickerControlComponent } from '../date-picker-control/date-picker-control.component';
import {
  placeholderDateTimeFormat,
  placeholderTimeFormat
} from '../../../../../../shared/constants/date-time-placeholders';

/**
 * Represents a control that allows the user to select a time value.
 */
@Component({
  selector: 'bizz-time-picker-control',
  standalone: true,
  imports: [CommonModule, TimePickerModule, ReactiveFormsModule, DatePickerModule],
  templateUrl: './time-picker-control.component.html',
  styleUrls: ['./time-picker-control.component.scss']
})
export class TimePickerControlComponent extends DatePickerControlComponent {
  protected override placeholderFormat = placeholderTimeFormat;
  @ViewChild('input') timePickerInputElement: TimePickerComponent;

  // Implement the abstract member from TimePickerControlComponent
  protected override focus(): void {
    // Add your implementation here
    this.timePickerInputElement.focus();
  }
}
