import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupModule } from '@progress/kendo-angular-popup';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';

@Component({
  selector: 'bizz-flow-status-header',
  standalone: true,
  imports: [CommonModule, IconComponent, PopupModule, TranslatePipe],
  templateUrl: './flow-status-header.component.html',
  styleUrls: ['./flow-status-header.component.scss'],
})
export class FlowStatusHeaderComponent {

  @Input({ required: true }) public title: string;
  @Input({ required: true }) public flowVersion: number;
  @Output() public backClick = new EventEmitter<void>();

  public back(): void {
    this.backClick.emit();
  }
}
