<div class="mt-5 rounded bg-section p-5">
    <div class="grid w-full grid-cols-2 gap-2 lg:inline-flex lg:items-center">
        <p>{{ 'Every' | translate }}</p>
        <kendo-combobox class="w-full lg:w-16" [clearButton]="false"
                        [readonly]="readOnly"
                        [data]="cronGenService.dropDownOptions.hours" [value]="state.hours"
                        (valueChange)="changeHours($event)" [valuePrimitive]="true"></kendo-combobox>
        <p>{{ 'hour_sOnMinute' | translate }}</p>
        <kendo-combobox class="w-full lg:w-16" [clearButton]="false"
                        [readonly]="readOnly"
                        [data]="cronGenService.dropDownOptions.fullMinutes" [value]="state.minutes"
                        (valueChange)="changeMinutes($event)" [valuePrimitive]="true"></kendo-combobox>
        <p>{{ 'onSecond' | translate }}</p>
        <kendo-combobox class="w-full lg:w-16" [clearButton]="false"
                        [readonly]="readOnly"
                        [data]="cronGenService.dropDownOptions.seconds" [value]="state.seconds"
                        (valueChange)="changeSeconds($event)" [valuePrimitive]="true"></kendo-combobox>

    </div>

</div>