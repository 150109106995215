import {Component, Input} from '@angular/core';
import {DashboardBand} from '../../../../../../models/ts/dashboard-band.model';
import { WidgetComponent } from '../../../../../features/bizzmine/widgets/widget/widget.component';

@Component({
  selector: 'bizz-dashboard-band',
  templateUrl: './dashboard-band.component.html',
  styleUrls: ['./dashboard-band.component.scss'],
  standalone: true,
  imports: [WidgetComponent]
})
export class DashboardBandComponent {

  @Input() dashboardBand: DashboardBand;

}
