import { AlertOptions } from '../classes/alert-options';

// TODO: translate Maybe not needed ? @GL

export const NOT_YET_IMPLEMENTED_ALERT: AlertOptions = {
  title: 'Not Yet Implemented',
  type: 'error',
  icon: 'triangle-exclamation',
  content: [
    'This error is shown because this feature is not yet implemented. No functional or technical issue has occurred. This is not translated.'
  ],
  timed: true,
  timer: 5000,
  dismissable: true
}

export const SAVED_ALERT: AlertOptions = {
  title: 'Saved',
  type: 'success',
  content: [],
  icon: 'check',
  timed: true,
  timer: 1000,
}

export const FORM_INFO_ALERT: AlertOptions = {
  title: 'Form updated',
  type: 'info',
  content: [],
  icon: 'check',
  timed: true,
  timer: 1000,
}

export const DIFFERENT_BROWSER_LOGIN_ALERT: AlertOptions = {
  title: 'Login',
  type: 'info',
  content: ['a login occurred from another browser.'],
  icon: 'triangle-exclamation',
  timed: true,
  timer: 10000,
}