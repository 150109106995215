import {
  Component, ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ToolBarModule, ToolBarToolComponent } from '@progress/kendo-angular-toolbar';
import { SliderModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonModule, DropDownButtonModule } from '@progress/kendo-angular-buttons';
import { FormsModule } from '@angular/forms';
import { PopupModule } from '@progress/kendo-angular-popup';
import { NgClass } from '@angular/common';
import { IconComponent } from '../../../../../../../../shared/components/ui/icon/icon.component';

@Component({
  selector: 'bizz-custom-canvas-toolbar-draw-line',
  standalone: true,
  imports: [
    SliderModule,
    DropDownsModule,
    ToolBarModule,
    DropDownButtonModule,
    FormsModule,
    ButtonModule,
    PopupModule,
    NgClass,
    IconComponent
  ],
  templateUrl: './custom-canvas-toolbar-draw-line.component.html',
  styleUrl: './custom-canvas-toolbar-draw-line.component.scss',
  providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => CustomCanvasToolbarDrawLineComponent) }]
})
export class CustomCanvasToolbarDrawLineComponent extends ToolBarToolComponent {
  @ViewChild('toolbarTemplate', { static: true }) public override toolbarTemplate: TemplateRef<any>;
  @ViewChild('popupTemplate', { static: true }) public override popupTemplate: TemplateRef<any>;
  @Input({required: true}) public value : number;
  @Output() public valueChange = new EventEmitter<number>();

  @HostListener("document:click", ["$event"])
  public documentClick(event: KeyboardEvent): void {
    if(event && event.target){
      if (!this.contains(event.target)) {
        this.show = false;
      }
    }

  }

  @ViewChild("anchor", { read: ElementRef }) public anchor: ElementRef;
  @ViewChild("popup", { read: ElementRef }) public popup: ElementRef;
  private contains(target: EventTarget): boolean {
    return (
      this.anchor.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }
  public constructor() {
    super();
  }

  public onValueChange(): void {
    this.valueChange.emit(this.value);
  }

  public show = false;
  public onToggle(): void {
   this.show = !this.show;
  }
}