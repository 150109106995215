<span class="flex w-fit justify-between sm:w-full">
        <div class="w-auto items-center gap-2 text-list-row-action flex">
            @if (isCancelled === false && completed === false) {
                <!-- Execute Steps -->
                <bizz-icon (click)="executeStep()" kendoTooltip [title]="'ExecuteSteps' | translate"
                           iconName="bolt"
                           class="actions-cell-icon"></bizz-icon>

            }
            @if (canForwardTasks === true && isCancelled === false && completed === false) {
                <!-- Forward Task -->
                <bizz-icon (click)="forwardStep()" kendoTooltip [title]="'ForwardTask' | translate"
                           iconName="share"
                           class="actions-cell-icon"></bizz-icon>

            }
        </div>
</span>