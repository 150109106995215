import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { IconComponent } from '../../../ui/icon/icon.component';
import { ToggleArrowComponent } from 'src/app/shared/components/ui/toggle-arrow/toggle-arrow.component';
import { KendoPopupContains } from 'src/app/shared/functions/kendo/kendo-popup-contains';
import { Align, PopupModule } from '@progress/kendo-angular-popup';
import { CommonModule } from '@angular/common';
import { CellActionType } from '../../../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';

@Component({
  selector: 'bizz-grid-cell-notification-and-reminder-actions',
  standalone: true,
  templateUrl: './grid-cell-notification-and-reminder-actions.component.html',
  styleUrl: './grid-cell-notification-and-reminder-actions.component.scss',
  imports: [CommonModule, TranslatePipe, IconComponent, PopupModule, ToggleArrowComponent]
})
export class GridCellNotificationAndReminderActionsComponent extends GridCellBaseComponent<string> {
  public showSavePopup = false;
  public popupAnchorAlign: Align = { horizontal: 'left', vertical: 'bottom' };

  @ViewChild('saveAnchor', { static: true }) public saveAnchor: ElementRef;
  @ViewChild('savePopup', { static: true }) public savePopup: ElementRef;

  public constructor() {
    super();
  }

  public openNotificationDetail(id: number): void {
    this.onCellAction.emit({ action: CellActionType.NOTIFICATION_OPEN, data: { data: this.data } });
  }

  public readNotification(): void {
    this.onCellAction.emit({ action: CellActionType.NOTIFICATION_READ, data: { data: this.data } });
  }

  public unreadNotification(): void {
    this.onCellAction.emit({ action: CellActionType.NOTIFICATION_UNREAD, data: { data: this.data } });
  }

  public toggleSavePopup(event?: MouseEvent, value?: boolean): void {
    this.showSavePopup = value != undefined ? value : !this.showSavePopup;
    if (event)
      event.stopImmediatePropagation();
  }

  /**
   * Closes the popup if the user clicks outside the popup or anchor.
   * @param event
   */
  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (this.showSavePopup && event.target && !KendoPopupContains(event.target, this.saveAnchor, this.savePopup)) {
      this.toggleSavePopup(undefined, false);
    }
  }
}
