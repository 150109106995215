import { Injectable, OnInit } from '@angular/core';
import { DecimalSeparatorType } from '../../../../models/ts/decimal-separator-type.model';
import { Store } from '@ngrx/store';
import { userSettingsFeature } from '../../../store/features/user-settings/user-settings-feature';

/**
 * Decimal Filter Service is used for converting numbers to defined user settings (seperators, delimitters,...).
 */

@Injectable({
  providedIn: 'root'
})
export class DecimalService implements OnInit {

  // TODO: BR refactor (ESLINT)

  public constructor(private store$: Store) {
  }

  public commaSeparatorType = this.store$.selectSignal(
    userSettingsFeature.selectDecimalSeparatorType
  );
  public commaSeparator = this.store$.selectSignal(
    userSettingsFeature.selectDecimalSeparatorString
  );
  public thousandSeparator = this.store$.selectSignal(
    userSettingsFeature.selectThousandSeparatorString
  );

  public ngOnInit(): void {
  }

  /**
   * 'improved' Math.round() that supports more decimals
   */
  public customRound(number: number, decimals: number = 0): number {
    if (arguments.length === 1) {
      return Math.round(number);
    }

    const multiplier = Math.pow(10, decimals);
    return Math.round(number * multiplier) / multiplier;
  }


  /**
   * Rounds a number to a given number of decimals and returns it as a string.
   * @param {number} input
   * @param {number} maxDecimals
   * @returns {string}
   */
  public roundDecimals(input: number, maxDecimals: number): string {
    /*
     1) Multiply the original number by 10^x (10 to the power of x)
     2) Apply Math.round() to the result
     3) Divide result by 10^x
     */
    const rounded = this.customRound(input * Math.pow(10, maxDecimals)) / Math.pow(10, maxDecimals);
    return Number(rounded).toFixed(maxDecimals);
  }


  /**
   * Formats a number string according to the user settings with the correct separators and delimiters.
   * @param {string} value
   * @returns {string}
   */
  public getFormattedValue(value: string): string {
    if (value == null || value == 'null' || value === '')
      return '';

    value = '' + value;


    const split = value.split('&');

    if (split.length > 1) {
      // number of decimals given
      value = '' + this.customRound(parseFloat(split[0]), parseFloat(split[1]));
    }

    let formattedValue = '';

    if (this.commaSeparatorType() === DecimalSeparatorType.Comma)
      value = '' + this.getAlteredCommaFormat(value);
    else
      value = '' + this.getAlteredPointFormat(value);

    // Add formatting
    const nonCommaValueArray = value.split(this.commaSeparator());
    const nonCommaValue = nonCommaValueArray[0];
    const reversed = nonCommaValue.split('').reverse().join('');
    const spliced = reversed.match(/.{1,3}/g);
    if (spliced) {
      const separated = spliced.join(this.thousandSeparator());
      const concatenatedAndOrdered = separated.split('').reverse().join('');

      if (nonCommaValueArray.length == 1)
        formattedValue = concatenatedAndOrdered;
      else
        formattedValue = concatenatedAndOrdered + this.commaSeparator() + nonCommaValueArray[1];

      // a number string cannot begin with '-' and a comma afterward
      if (formattedValue.indexOf('-' + this.commaSeparator()) == 0 ||
          formattedValue.indexOf('-' + this.thousandSeparator()) == 0) {
        formattedValue = formattedValue.slice(0, 1) + formattedValue.slice(2);
      }

      return formattedValue;
    }
    return '';

  }

  /**
   * Replaces the point with the comma separator.
   * @param {string} input
   * @returns {string}
   */
  public getAlteredPointFormat(input: string): string {
    const index = input.indexOf(this.commaSeparator());
    if (index >= 0)
      return this.replaceAt(input, index, '.');
    else return input;
  }

  /**
   * Replaces the comma with the point separator.
   * @param {string} input
   * @returns {string}
   */
  public getAlteredCommaFormat(input: string): string {
    const index = input.indexOf('.');
    if (index >= 0)
      return this.replaceAt(input, index, this.commaSeparator());
    else return input;
  }

  /**
   * Replaces a character at a given index in a string.
   * @param {string} input
   * @param {number} index
   * @param {string} character
   * @returns {string}
   */
  public replaceAt(input: string, index: number, character: string): string {
    return input.substring(0, index) + character + input.substring(index + character.length);
  }
}
