import { StoreFlowStatus } from './flow-status-state';
import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { flowStatusActions } from './flow-status-actions';

export const flowStatusAdapter =
  createEntityAdapter<StoreFlowStatus>();

export const flowStatusReducer = createReducer(
  flowStatusAdapter.getInitialState(),
  on(flowStatusActions.setFlowStatus,
    (state, { data }) => {
      return flowStatusAdapter.addOne(data, state);
    }),
  on(flowStatusActions.flowStatusFetched, (state, { data }) => {
    return flowStatusAdapter.addOne(data, state);
  }),
);
