import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { TimePipe } from '../../../../pipes/dates/time.pipe';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { UtcDatePipe } from '../../../../pipes/dates/utc-date.pipe';
import { ArchiveItemVersionComponent } from './archive-item-version/archive-item-version.component';
import { VersionStateType } from 'src/models/ts/version-state-type.model';
import { ExtensionIconComponent } from '../../../ui/icon/extension-icon/extension-icon.component';
import { UtcDateTimePipe } from 'src/app/shared/pipes/dates/utc-date-time.pipe';
import { IconComponent } from '../../../ui/icon/icon.component';
import { GridContextPermissions } from 'src/app/features/bizzmine/widgets/collection-list-widget/classes/grid-context-permissions';
import { DocumentCheckinType } from 'src/models/ts/document-checkin-type.model';
import { CollectionListDataInstance } from 'src/app/shared/interfaces/collection-list-data-instance';
import { ArchiveApiService } from 'src/app/api/bizzmine/archive/archive-api.service';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { DELETESTATES } from 'src/app/shared/constants/file';

@Component({
  selector: 'bizz-archive-item',
  standalone: true,
  imports: [CommonModule, ArchiveItemVersionComponent, TimePipe, TranslatePipe, UtcDatePipe, ExtensionIconComponent, UtcDateTimePipe, IconComponent],
  templateUrl: './archive-item.component.html',
  styleUrl: './archive-item.component.scss'
})
export class ArchiveItemComponent {
  @ViewChild('menuIcon') menuIcon!: ElementRef;

  @Input({ required: true }) public dataItem: CollectionListDataInstance;
  @Input({ required: true }) public permissions: GridContextPermissions;
  @Input({ required: true }) public item: any;
  @Input({ required: true }) public isLastItem: boolean;

  @Output() public readonly archiveContextMenuAnchorClick = new EventEmitter<{
    dataItem: CollectionListDataInstance, 
    permissions: GridContextPermissions, 
    anchor: ElementRef
  }>();

  public constructor(private archiveService: ArchiveApiService, private downloadService: DownloadService) {
  }

  public versionStateType = VersionStateType;

  public get isDraft(): boolean {
    return this.item.DocumentStatus === VersionStateType.Draft;
  }

  public get fullItem(): CollectionListDataInstance {
    return Object.assign(this.dataItem, this.item);
  }

  public get isDeleted(): boolean {
    return DELETESTATES.includes(this.item.DocumentStatus);
  }

  public get isMinorVersion(): boolean {
    return this.item.Version.split('.').slice(-1)[0] !== '0';
  }

  public get iconAnchor(): ElementRef {
    return this.menuIcon;
  }

   /* 
    The action menu in the archive modal only displays a certain set of actions. 
    Here, we create a new instance of GridContextPermissions and set only the permissions we need.
  */ 
  public get filePermissions(): GridContextPermissions {
    const filteredPermissions = new GridContextPermissions();

    filteredPermissions.canConsultRecord = true;
    filteredPermissions.showDownload = this.permissions.showDownload && this.showDownload();
    filteredPermissions.showViewOnline = this.permissions.showViewOnline && this.showDownload();
    filteredPermissions.showFlowStatus = this.permissions.showFlowStatus;
    filteredPermissions.showMonitor = this.permissions.showMonitor && this.showMonitor();
    filteredPermissions.viewFileHistory = this.hasFiles();
    filteredPermissions.copyLinkToRecord = false;

    return filteredPermissions;
  }

  public download(): void {
    if (!this.dataItem.CollectionsID || !this.dataItem.File) {
      throw new Error('CollectionsID and FileID are required to download a file');
    }

    this.archiveService.downloadFile(this.fullItem);
  }

  public showDownload(): boolean {
    if (!this.hasFiles()) {
      return false;
    }

    if (!this.item.Extension || this.item.Extension === ".") {
      return false;
    }

    return true;
  }

  private hasFiles(): boolean {
    if (!this.item) {
      return false;
    }

    if (!this.item.File) {
        return false;
    }
    if (this.item.DocumentStatus === undefined) {
        return false;
    }

    // a) Public Version: Read Permissions
    if (this.item.DocumentStatus == VersionStateType.Active && (!this.dataItem.Permissions?.CanReadProperties && !this.dataItem.Permissions?.CanReadDocPDF)) {
      return false;
    }

    // b) Draft Version: Edit Permissions + Document must not be checked out
    if ((this.item.DocumentStatus == VersionStateType.Draft || this.item.DocumentStatus == VersionStateType.DraftDeleted) && (this.item.DocumentCheckinStatus == DocumentCheckinType.CheckedOut || !this.dataItem.Permissions?.CanEditProperties)) {
      return false;
    }

    // c) no pdf found and you only have read as pdf permission then hide download
    const hasPdf = this.item.PDFMediaID > 0 && this.item.UsePDFConverter;

    if ((!hasPdf && this.dataItem.Permissions?.CanReadDocPDF) && !this.dataItem.Permissions?.CanReadProperties) {
      return false;
    }

    return true;
  }

  private showMonitor():boolean {
    if (this.dataItem.HasMonitor) {
      return true;
    }
    return false
  }
}
