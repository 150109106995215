<div class="my-2 flex flex-col gap-2.5">
  <div class="flex"
       [class]="{ 'order-last': column.GridColumnType == GridColumnType.Actions }"
       *ngFor="let column of gridColumns">
    <ng-container *ngIf="column.GridColumnType == GridColumnType.Text">
      <div class="w-full text-sm font-medium text-default">
        <span> {{ column.title | translate }}</span>
      </div>
      <div class="word-wrap flex w-full items-center text-start">
        <bizz-grid-cell
          [data]="data"
          [gridColumn]="column"
          class="text-xs font-normal text-widget">
        </bizz-grid-cell>
      </div>
    </ng-container>
    <ng-container *ngIf="column.GridColumnType == GridColumnType.Status">
      <div class="w-full text-sm font-medium text-default">
        <span> {{ 'Status' | translate }}</span>
      </div>
      <div class="word-wrap flex w-full items-center gap-2.5 text-start">
        <bizz-status-icon [statusType]="data.InstanceStatusType!">
        </bizz-status-icon>
        {{ data[column.field]  }}
      </div>
    </ng-container>
    <ng-container *ngIf="column.GridColumnType == GridColumnType.Actions">
      <div class="word-wrap flex w-full items-center text-start">
        <bizz-grid-cell
          [data]="data"
          [gridColumn]="column"
          class="h-5 w-full text-base font-normal text-widget">
        </bizz-grid-cell>
      </div>
    </ng-container>
  </div>
</div>
