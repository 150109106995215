import { Component, DestroyRef, inject, OnInit, Signal } from '@angular/core';
import { HeaderPopupBase } from './header-popup-base';
import { Store } from '@ngrx/store';
import { debounceTime, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  template: '',
  standalone: true,
  imports: []
})
export abstract class HeaderPopupListBase<T> extends HeaderPopupBase implements OnInit {
  public abstract data: Signal<T[]>;
  public abstract count: Signal<number>;
  public abstract loading: Signal<boolean>;

  public margin = { horizontal: -25, vertical: 25 };
  public searchTerm = new Subject<string>();
  protected store$ = inject(Store);
  protected destroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    this.searchTerm.pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(300)
    ).subscribe({
      next: (search) => this.search(search)
    });
  }

  public abstract open(id: number): void;

  public abstract refresh(): void;

  protected abstract search(searchTerm: string): void;
}