/**
 * Represents the type of event that occurred on the view stack.
 * @see ViewStackEvent
 */
export enum ViewStackEventType {
  ADDED,
  REMOVED,
  CLEAR,
  ADD_LOADER,
  ADD_LOADER_SKELETON,
  REMOVE_LOADER
}

export enum ViewStackLoaderType {
  DEFAULT,
  SKELETON
}
