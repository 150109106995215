import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { PermissionAccessExceptionDto } from 'src/models/ts/permission-access-exception-dto.model';
import { ModalComponent } from 'src/app/shared/components/modals/modal/modal.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { AuditLogEventType } from 'src/models/ts/audit-log-event-type.model';
import { GridModule } from '@progress/kendo-angular-grid';
import { PermissionHistoryDto } from 'src/models/ts/permission-history-dto.model';

@Component({
  selector: 'bizz-permission-history-details',
  standalone: true,
  imports: [TranslatePipe, ModalComponent, GridModule],
  templateUrl: './permission-history-details.component.html',
  styleUrl: './permission-history-details.component.scss'
})

export class PermissionHistoryDetailsComponent {
  protected readonly AuditLogEventType = AuditLogEventType;
  public constructor(@Inject(DIALOG_DATA) public data: {permissionHistory: PermissionHistoryDto, historyDetails: PermissionAccessExceptionDto[]}) {
   }

}
