import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationApiService } from '../notification-api.service';
import { NotificationsDto } from '../../../../../models/ts/notifications-dto.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsResolver  {

  public constructor(private notificationApiService: NotificationApiService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public resolve(): Observable<NotificationsDto> {
    return this.notificationApiService.getNotifications();
  }
}
