import { Component } from '@angular/core';
import { LoaderComponent } from '../../../../../shared/components/ui/loader/loader.component';

@Component({
  selector: 'bizz-view-stack-loader',
  standalone: true,
  imports: [
    LoaderComponent
  ],
  templateUrl: './view-stack-loader.component.html',
  styleUrl: './view-stack-loader.component.scss'
})
export class ViewStackLoaderComponent  {
}
