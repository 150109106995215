<div class="modal-container justify-between md:justify-normal">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="dialogRef.close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header">
            <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
                {{ "NotAllSkillsMatch" | translate }}</h1>
        </div>
        <hr class="my-5"/>
        @if (exams != null && exams.length > 0) {
            <div class="flex justify-center flex-col text-center gap-2">
                <div><b>{{ "ExamSkill" | translate }}</b></div>
                @for (exam of exams; track $index) {
                    <div>{{exam?.Name}}</div>
                }
            </div>
        }

        @if (trainings != null && trainings.length > 0) {
            <div class="flex justify-center flex-col text-center gap-2">
                <div><b>{{ "TrainingSkill" | translate }}</b></div>
                @for (training of trainings; track $index) {
                    <div>{{training?.Name}}</div>
                }
            </div>
        }
    </div>
    <div class="modal-buttons">
        <button class="default" (click)="dialogRef.close()">
            {{ 'Close' | translate }}
        </button>
    </div>
</div>
