import { Component } from '@angular/core';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { IconComponent } from '../../../ui/icon/icon.component';
import { MacroButtonComponent } from '../../../ui/macro-button/macro-button.component';
import { ButtonDisplayType } from '../../../../../../models/ts/button-display-type.model';
import { MacroButtonType } from '../../../ui/macro-button/macro-button-type.enum';

@Component({
  selector: 'bizz-grid-cell-macrobutton',
  standalone: true,
  templateUrl: './grid-cell-macro-button.component.html',
  styleUrl: './grid-cell-macro-button.component.scss',
  imports: [IconComponent, MacroButtonComponent]
})
export class GridCellMacroButtonComponent extends GridCellBaseComponent<string> {

  protected readonly ButtonDisplayType = ButtonDisplayType;
  protected readonly MacroButtonType = MacroButtonType;
}
