import { Component, OnInit } from '@angular/core';
import { CellActionType } from '../../../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TaskCompletedType } from '../../../../../../models/ts/task-completed-type.model';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';

@Component({
  selector: 'bizz-grid-cell-task-actions',
  standalone: true,
  imports: [
    TooltipModule,
    TranslatePipe,
    IconComponent
  ],
  templateUrl: './grid-cell-task-actions.component.html',
  styleUrl: './grid-cell-task-actions.component.scss'
})
export class GridCellTaskActionsComponent
  extends GridCellBaseComponent<string>
  implements OnInit {
  public ngOnInit(): void {
    this.apply();
  }

  private apply(): void {
    this.isCancelled = <boolean><unknown>this.data['IsCancelled'];
    this.completed = (<TaskCompletedType><unknown>this.data['Completed']) == TaskCompletedType.Completed;
    this.canForwardTasks = <boolean><unknown>this.data['CanForwardTasks'];
  }

  public override onDataChanged(): void {
    this.apply();
  }


  public isCancelled: boolean | undefined = undefined;
  public completed: boolean | undefined = undefined;
  public canForwardTasks: boolean | undefined = undefined;

  public executeStep(): void {
    this.onCellAction.emit({ action: CellActionType.TASK_EXECUTE, data: { data: this.data } });
  }

  public forwardStep(): void {
    this.onCellAction.emit({ action: CellActionType.TASK_FORWARD, data: { data: this.data } });
  }
}
