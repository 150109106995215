import { LinkedCollectionType } from './linked-collection-type.model';
import { StateType } from './state-type.model';

export interface CollectionFormExternalAccessDto {
  ID: number;
  State: StateType;
  ViewsID: number;
  IsDefaultView: boolean;
  ViewDataSourcesID: number;
  FormID: number;
  FormCollectionID: number;
  FormName: string;
  AllowCreateRecord: boolean;
  ChildFormID: number;
  AllowLookup: boolean;
  ChildListID: number;
  ChildFormName: string;
  ChildListName: string;
  ChildCollectionsID: number;
  CrossLinkCollectionsID: number;
  ParentCollectionsID: number;
  SourceParentCollectionsID: number;
  SourceCollectionsID: number;
  Level: number;
  AllowDeletionLinkedRecords: AllowDeletionLinkedRecordsType | null;
  SingleOrMany: LinkedCollectionType;
}

export enum AllowDeletionLinkedRecordsType
{
  No = 0,
  Yes = 1
}


