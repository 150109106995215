import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CollectionLockApiService {

  constructor(private http: HttpClient) { }

  public deleteLock(collectionId: number, lockId: number): Observable<unknown> {
    return this.http.delete<unknown>(`settings/api/collection/${collectionId}/lock/${lockId}`);
  }

  public deleteLockTimeExceeded(collectionId: number, lockId: number): Observable<unknown> {
    return this.http.delete<unknown>(`settings/api/collection/${collectionId}/locktimeexceeded/${lockId}`);
  }

  public deleteSelfLock(lockId: number): Observable<unknown> {
    return this.http.post<unknown>(`settings/api/collectionlock/${lockId}/removeselflock`, {});
  }




}
