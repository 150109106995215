import {createActionGroup, props} from "@ngrx/store";
import {TrnExamDto} from "../../../../models/ts/trn-exam-dto.model";
import {TrainingEffectConfig} from "./training-effects";
import { TrnExamQuestion } from '../../../../models/ts/trn-exam-question.model';
import { BehaviorSubject } from 'rxjs';

export const trainingActions = createActionGroup({
    source: 'training',
    events: {
        'Fetch Exam': props<{
            collectionsId: number,
            versionsId: number,
            config: TrainingEffectConfig
        }>(),
        'Exam Fetched': props<{exam: TrnExamDto, config: TrainingEffectConfig}>()
    }

});