@if(metaData.managerTrainingQueue){

    <div class="flex gap-4 items-center justify-center">
        @if(!metaData.trashFilterActive){
            <input type='checkbox' [checked]="data['checked']" (click)="checked()">
            <bizz-icon iconName="xmark" class="size-5 cursor-pointer hover:text-red-400" (click)="delete()"></bizz-icon>
        } @else {
            <bizz-icon iconName="rotate-right" class="size-5 cursor-pointer hover:text-blue-500" (click)="recover()"></bizz-icon>
        }

    </div>
}

