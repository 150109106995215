import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, LOCALE_ID, isDevMode } from '@angular/core';
import { provideRouter, Router, RouteReuseStrategy, TitleStrategy, withRouterConfig } from '@angular/router';

import { routes } from './app.routes';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { OAuthModule, OAuthStorage, provideOAuthClient } from 'angular-oauth2-oidc';
import { RootStoreModule } from './store/root-store.module';
import { CldrIntlService, IntlModule } from '@progress/kendo-angular-intl';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { MenuModule } from '@progress/kendo-angular-menu';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth/auth.interceptor';
import { ApiInterceptor } from './core/interceptors/api/api.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { DialogModule } from '@angular/cdk/dialog';
import '@angular/common/locales/global/en';
import '@angular/common/locales/global/nl';
import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/sv';
import '@angular/common/locales/global/lt';
import '@angular/common/locales/global/de';
import '@angular/common/locales/global/pt';
import '@angular/common/locales/global/it';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/pl';
import '@progress/kendo-angular-intl/locales/bg/all';
import '@progress/kendo-angular-intl/locales/en/all';
import '@progress/kendo-angular-intl/locales/nl/all';
import '@progress/kendo-angular-intl/locales/fr/all';
import '@progress/kendo-angular-intl/locales/sv/all';
import '@progress/kendo-angular-intl/locales/lt/all';
import '@progress/kendo-angular-intl/locales/de/all';
import '@progress/kendo-angular-intl/locales/pt/all';
import '@progress/kendo-angular-intl/locales/it/all';
import '@progress/kendo-angular-intl/locales/es/all';
import '@progress/kendo-angular-intl/locales/pl/all';
import * as Sentry from '@sentry/angular';
import 'hammerjs';
import { BizzMineSessionStorageService } from './shared/services/localStorage/bizzmine-session-storage.service';
import { BizzTitleStrategy } from './bizz-title-strategy';
import { MessageService } from '@progress/kendo-angular-l10n';
import { MessageTranslationService } from './core/services/kendo-translations/message-translation.service';
import { provideServiceWorker } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { BizzRouteReuseStrategy } from './core/classes/route-reuse-strategy';


export const APP_CONFIG: ApplicationConfig = {
  providers: [
    provideRouter(routes, withRouterConfig({ paramsInheritanceStrategy: 'always', onSameUrlNavigation: 'reload' })),
    { provide: RouteReuseStrategy, useClass: BizzRouteReuseStrategy},
    importProvidersFrom(BrowserModule, DialogModule, OAuthModule.forRoot(),
      RootStoreModule, IntlModule, HammerModule, TooltipsModule, DateInputsModule, ExcelExportModule, MenuModule, NgScrollbarModule, ListViewModule),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: CldrIntlService, useClass: CldrIntlService },
    {
      provide: OAuthStorage,
      useFactory: (bizzMineSessionStorageService: BizzMineSessionStorageService) => bizzMineSessionStorageService,
      deps: [BizzMineSessionStorageService]
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideOAuthClient(),
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
    { provide: MessageService, useClass: MessageTranslationService },
    { provide: Sentry.TraceService, deps: [Router] },
    { //Force instantiate Tracing, see: https://docs.sentry.io/platforms/javascript/guides/angular/
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true
    },
    { provide: TitleStrategy, useClass: BizzTitleStrategy },
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
};
