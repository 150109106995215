<div class="mt-5 rounded bg-section p-5">
    <div class="flex flex-wrap items-center gap-2">
        <p>{{'CronExpression' | translate}}</p>
        <input class="ml-1 w-52" type="text" [value]="state" [readonly]="readOnly" (change)="change($event)">
        <p>{{'CronExpressionLink' | translate}} <span>
            <a class="ml-1"
                href="https://www.quartz-scheduler.net/documentation/quartz-3.x/tutorial/crontriggers.html#example-cron-expressions"
                target="_blank"> {{ 'Link' | translate}}</a></span></p>
    </div>
   
</div>