import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ViewStackEvent } from '../../classes/view-stack-event';
import { ViewStackEventType, ViewStackLoaderType } from '../../enums/view-stack-event-type.enum';
import { ViewStackItem } from '../../classes/view-stack-item';
import { ViewStackComponent } from '../../components/ui/view-stack/view-stack.component';

/**
 * Service responsible for managing the view stack through events.
 * Events are emitted through the viewStackEvents subject.
 * Subject can be subscribed to as an observable to receive events.
 * @see ViewStackComponent
 * @see ViewStackEvent
 * @see ViewStackEventType
 */
@Injectable({
  providedIn: 'root'
})
export class ViewStackService {

  public viewStackReference: ViewStackComponent;

  private viewStackEvents: Subject<ViewStackEvent> = new Subject<ViewStackEvent>();

  public getViewStackEventsObservable(): Observable<ViewStackEvent> {
    return this.viewStackEvents.asObservable();
  }

  public addItemToStack(item: ViewStackItem): void {
    this.viewStackEvents.next(new ViewStackEvent(ViewStackEventType.ADDED, item));
  }

  public removeLastItemFromStack(): void {
    this.viewStackEvents.next(new ViewStackEvent(ViewStackEventType.REMOVED));
  }

  public clearViewStack(): void {
    this.viewStackEvents.next(new ViewStackEvent(ViewStackEventType.CLEAR));
  }

  public setLoadingState(type?: ViewStackLoaderType): void {
    if(type == null || type == ViewStackLoaderType.DEFAULT){
      this.viewStackEvents.next(new ViewStackEvent(ViewStackEventType.ADD_LOADER));
    } else if (type == ViewStackLoaderType.SKELETON){
      this.viewStackEvents.next(new ViewStackEvent(ViewStackEventType.ADD_LOADER_SKELETON));
    }

  }
  public removeLoadingState(): void {
    this.viewStackEvents.next(new ViewStackEvent(ViewStackEventType.REMOVE_LOADER));
  }
}