<div class="button-group" #saveAnchor>
    <button class="whitespace-nowrap rounded border border-r-0 border-form-control bg-form-control text-sm leading-4 hover:bg-button-hover" type="button" (click)="openNotificationDetail(data.ID)">
        <span>{{ 'Detail' | translate }}</span>
    </button>
    <button class="rounded border border-form-control bg-form-control hover:bg-button-hover" type="button" (click)="toggleSavePopup($event)">
        <bizz-toggle-arrow class="h-4 w-4" [direction]="showSavePopup ? 'up' : 'down'"></bizz-toggle-arrow>
    </button>
</div>
@if (showSavePopup) {
    <kendo-popup
            #savePopup
            class="z-[1004] w-full sm:w-auto maxw-sm:!left-0"
            (anchorViewportLeave)="toggleSavePopup($event)"
            [anchor]="saveAnchor"
            [anchorAlign]="popupAnchorAlign">
        <div class="bizz-popup-content">
            @if (data['Read']) {
                <button (click)="unreadNotification()">
                    <span>{{ 'MarkAsUnread' | translate }}</span>
                </button>
            }
            @if (!data['Read']) {
                <button (click)="readNotification()">
                    <span>{{ 'MarkAsRead' | translate }}</span>
                </button>
            }
        </div>
    </kendo-popup>
}
