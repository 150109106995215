<kendo-grid  #kendoGrid [data]="gridData | async">
    <kendo-grid-column field="PermissionTypeString" [title]="'PermissionType' | translate"> </kendo-grid-column>
    <kendo-grid-column [title]="'NewValue' | translate">
        <ng-template kendoGridCellTemplate let-dataItem>
            @if(dataItem.ItemType == AuditLogItemType.Permission){
                <span>
                    {{dataItem.AccessTypeString}}
                    @if(dataItem.Value){
                        <span> except</span>
                    }
                </span>
            }
            {{dataItem.Value}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="UserName" [title]="'ChangedBy' | translate"> </kendo-grid-column>
    <kendo-grid-column field="TimeStamp" [title]="'ChangedOn' | translate">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.TimeStamp | datetime}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [title]="'Actions' | translate">
        <ng-template kendoGridCellTemplate let-dataItem>
            @if(dataItem.ShowDetails){
                <button class="default w-fit" (click)="showDetails(dataItem)">
                    {{ 'Details' | translate }}</button>
                }
        </ng-template>
    </kendo-grid-column>
</kendo-grid>
