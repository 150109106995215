import { Component, forwardRef, Host, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { ToolBarToolComponent } from '@progress/kendo-angular-toolbar';
import { DialogActionsComponent, DialogComponent } from '@progress/kendo-angular-dialog';
import { ButtonComponent } from '@progress/kendo-angular-buttons';
import { LabelComponent } from '@progress/kendo-angular-label';
import { EditorComponent } from '@progress/kendo-angular-editor';

@Component({
  selector: 'kendo-toolbar-dropdownlist[bizzEditorFontSize]',
  templateUrl: './editor-font-size.component.html',
  styleUrls: ['./editor-font-size.component.css'],
  providers: [
    {
      provide: ToolBarToolComponent,
      useExisting: forwardRef(() => EditorFontSizeComponent)
    }
  ],
  imports: [
    DialogComponent,
    DialogActionsComponent,
    DropDownListComponent,
    ButtonComponent,
    LabelComponent
  ],
  standalone: true
})
export class EditorFontSizeComponent extends ToolBarToolComponent implements OnInit {

  @ViewChild('toolbarTemplate', { static: true })
  public override toolbarTemplate: TemplateRef<unknown>;

  @ViewChild('dropdownlist', { read: DropDownListComponent, static: false })
  public dropdownlist: DropDownListComponent;

  public value: string | null = null;
  public items: Array<string> = ['8px', '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '36px', '48px', '72px'];
  public tabindex = -1;
  public defaultItemText = 'Select a fontsize';

  public constructor(@Host() private editor: EditorComponent) {
    super();
  }

  public ngOnInit(): void {
  }

  public selectionChange(value: string): void {
    this.editor.exec('fontSize', value);
    this.editor.focus();
  }
}
