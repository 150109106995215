@if (this.data.FormFieldType != FormFieldType.ReversedUserCollection) {
    <div class="mx-4 flex w-full flex-col">
        <hr class="hidden sm:flex">
        <div class="my-2 flex flex-col gap-2 overflow-auto sm:my-4 sm:flex-row sm:gap-0">
            <kendo-grid [data]="data.TrnUsers" [resizable]="true" class="min-w-full" #trainingSubscribedUsersKendoGrid>
                @if (userType != UserType.AnonymousUser && userType != UserType.ExternalUser) {
                    <kendo-grid-column [resizable]="false" [width]="50" [minResizableWidth]="50">
                        <ng-template kendoGridHeaderTemplate let-dataItem>

                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            @if (!data.IsReadOnly && !readOnlyOverride) {
                                <bizz-icon (click)="delete(dataItem)" iconName="trash-can"
                                           class="m-auto ml-4 h-3.5 w-3.5 cursor-pointer text-danger"></bizz-icon>
                            }

                        </ng-template>
                    </kendo-grid-column>
                }
                <kendo-grid-column [autoSize]="false" [width]="250" [minResizableWidth]="250">
                    <ng-template kendoGridHeaderTemplate let-dataItem>
                        <b>{{ "Name" | translate }}</b>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <b class="text-xs">{{ dataItem.Name }}</b>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column [autoSize]="false" [resizable]="false" [width]="60" [minResizableWidth]="60">
                    <ng-template kendoGridHeaderTemplate let-dataItem>
                        <b>{{ "Present" | translate }}</b>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <input type="checkbox" class="disabled" disabled kendoCheckBox
                               [ngModel]="dataItem.Present">
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column [autoSize]="false" [resizable]="false" [width]="60" [minResizableWidth]="60">
                    <ng-template kendoGridHeaderTemplate let-dataItem>
                        <b>{{ "Grade" | translate }}</b>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        @if (getScore(dataItem.Grade, true); as skillGrade) {
                            <kendo-circulargauge
                                    [value]="skillGrade.Score"
                                    [scale]="{ max: 100 }"
                                    [colors]="colors"
                                    class="size-14">
                                <kendo-arcgauge-scale [rangeSize]="5">
                                    <ng-template kendoCircularGaugeCenterTemplate let-value="value">
                                        <span class="absolute left-2/4 top-2/4 flex -translate-x-2/4 -translate-y-2/4 gap-0.5 text-xs font-bold">
                                            @if (dataItem.Expiracy == null) {
                                                {{ skillGrade.Score }}<span
                                                        class="text-xxs">%</span>
                                            } @else {
                                                @switch (dataItem.Expiracy.ExpiracyStatus) {
                                                    @case (ExpiracyStatus.NotExpired) {
                                                        {{ skillGrade.Score }}
                                                        <span class="text-xxs">%</span>
                                                    }
                                                    @case (ExpiracyStatus.AlmostExpired) {
                                                        <bizz-icon
                                                                class="size-3 text-yellow-500"
                                                                iconName="circle-exclamation"></bizz-icon>
                                                    }
                                                    @case (ExpiracyStatus.Expired) {
                                                        <bizz-icon
                                                                class="size-3 text-red-500"
                                                                iconName="xmark"></bizz-icon>
                                                    }
                                                    @case (ExpiracyStatus.ExpirationPrediction) {
                                                        <bizz-icon
                                                                class="size-3 text-red-500"
                                                                iconName="xmark"></bizz-icon>
                                                    }
                                                }
                                            }
                                        </span>
                                    </ng-template>
                                </kendo-arcgauge-scale>
                            </kendo-circulargauge>

                        }
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column [width]="500">
                    <ng-template kendoGridHeaderTemplate let-dataItem>
                        <b>{{ "Remarks" | translate }}</b>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                            <textarea
                                    class="form-control w-full rounded border border-form-control bg-form-control bg-none text-sm text-widget"
                                    rows="1"
                                    [ngModel]="dataItem.Remarks"
                                    disabled></textarea>
                    </ng-template>
                </kendo-grid-column>

            </kendo-grid>
        </div>
        @if (userType != UserType.AnonymousUser && userType != UserType.ExternalUser) {
            <div class="flex w-full justify-end px-4">
                @if (!data.IsReadOnly && !readOnlyOverride) {
                    <bizz-icon (click)="addUser()" iconName="circle-plus"
                               class="size-6 cursor-pointer text-green-500"></bizz-icon>
                }
            </div>
        }
    </div>
}


