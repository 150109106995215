import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckBoxModule, RadioButtonModule, TextAreaModule } from '@progress/kendo-angular-inputs';
import { IconComponent } from '../../ui/icon/icon.component';
import { NgIf } from '@angular/common';
import { SharedModule } from '@progress/kendo-angular-dropdowns';
import { TranslatePipe } from '../../../pipes/translate/translate.pipe';
import { CollectionFormValidatorResult } from '../../../../features/bizzmine/form/classes/collection-form-validator-result';

@Component({
  selector: 'bizz-validation-summary',
  templateUrl: './validation-summary.component.html',
  styleUrls: ['./validation-summary.component.scss'],
  imports: [
    CheckBoxModule,
    IconComponent,
    NgIf,
    RadioButtonModule,
    SharedModule,
    TextAreaModule,
    TranslatePipe,
  ],
  standalone: true,
})
export class ValidationSummaryComponent {

  @Input() public validation: CollectionFormValidatorResult | undefined;
  @Output() public validationLinkClick = new EventEmitter<number>();
}
