import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import { PDFViewerComponent, PDFViewerDownloadEvent, PDFViewerModule, PDFViewerTool } from '@progress/kendo-angular-pdfviewer';
import { BizzMineUploadType } from '../../../../../models/ts/bizz-mine-upload-type.model';
import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';
import { FilePreviewDisplayType } from '../../../../../models/ts/file-preview-display-type.model';
import { AsyncPipe, DatePipe, DecimalPipe, NgClass, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { TranslatePipe } from '../../../pipes/translate/translate.pipe';
import { IconComponent } from '../../ui/icon/icon.component';
import { MediaCommitDto } from '../../../../../models/ts/media-commit-dto.model';

import { saveAs } from '@progress/kendo-file-saver';
import { FileInfo, UploadModule } from '@progress/kendo-angular-upload';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { SliderModule } from '@progress/kendo-angular-inputs';
import { ExtensionIconComponent } from '../../ui/icon/extension-icon/extension-icon.component';
import { CollectionFormFileUploadApiService } from '../../../../api/bizzmine/collection-form-file-upload/collection-form-file-upload-api.service';
import { MailingAttachmentsUploadComponent } from '../../../../features/bizzmine/form/components/controls/form-mailing-list/mailing-attachments-upload/mailing-attachments-upload.component';
import { DragFileUploadDirective } from '../../../directives/drag-file-upload.directive';
import {
  FileChunkUpload,
  FileUpload,
  FileUploadService,
  FormDataWithMeta
} from '../../../../core/services/file-upload/file-upload.service';
import {
  asyncScheduler,
  BehaviorSubject,
  catchError,
  debounceTime,
  filter,
  finalize,
  from,
  lastValueFrom,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectForm,
  selectFormFieldsByProtectedFieldType,
  selectFormFieldValues,
  selectFormLockState,
  selectFormViewDataSources,
  selectRecordFields,
  selectRecordInstance
} from '../../../../store/features/forms/forms-selectors';
import { SystemMediaSettingsDto } from '../../../../../models/ts/system-media-settings-dto.model';

import { FilePreviewDto } from '../../../../../models/ts/file-preview-dto.model';
import { MimeTypeModalComponent } from '../../modals/mime-type-modal/mime-type-modal.component';
import { Dialog } from '@angular/cdk/dialog';
import { MimeTypeModalData } from '../../modals/mime-type-modal/mime-type-modal-data';
import { FileSelectMultiComponent } from '../../file-select-multi/file-select-multi.component';
import { formsActions } from 'src/app/store/features/forms/forms-actions';
import { ProtectedFieldType } from '../../../../../models/ts/protected-field-type.model';
import { userSettingsFeature } from '../../../../store/features/user-settings/user-settings-feature';
import { DocumentCheckinType } from '../../../../../models/ts/document-checkin-type.model';
import { FileUploadModificationReasonModalComponent } from './file-upload-modification-reason-modal/file-upload-modification-reason-modal.component';
import { CollectionListApiService } from '../../../../api/bizzmine/collection-list/collection-list-api.service';
import { FileUploadDeleteModalComponent } from './file-upload-delete-modal/file-upload-delete-modal.component';
import { ArchiveApiService } from '../../../../api/bizzmine/archive/archive-api.service';
import { getFileExtension } from '../../../functions/helpers/file-name-helpers';
import { UserType } from '../../../../../models/ts/user-type.model';
import { FileUploadSelectTemplateDialogComponent } from './file-upload-select-template-dialog/file-upload-select-template-dialog.component';
import { SimpleDocumentTemplateDto } from '../../../../../models/ts/simple-document-template-dto.model';
import { BytesPipe } from '../../../pipes/bytes/bytes.pipe';
import { LoaderComponent } from '../../ui/loader/loader.component';
import { FileUploadEditOnlineModalComponent } from './file-upload-edit-online-modal/file-upload-edit-online-modal.component';
import { EditOnlineApiServiceService } from '../../../../api/bizzmine/edit-online/edit-online-api-service.service';
import {
  FileUploadEditOnlineModalComponentData,
  FileUploadEditOnlineModalComponentMode
} from 'src/models/ts/file-upload-edit-online-modal-component-mode';
import { FileUploadCheckinService } from '../../../../core/services/file-upload/file-upload-checkin.service';
import { DirectEditMode } from '../../../../../models/ts/direct-edit-mode.model';
import { LinkedCollectionType } from '../../../../../models/ts/linked-collection-type.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Actions, ofType } from '@ngrx/effects';
import { GridService } from '../../../../features/bizzmine/widgets/collection-list-widget/services/grid/grid.service';
import { ControlledDocumentTemplateIntent } from '../../../../features/bizzmine/form/components/controls/controlled-document-control/controlled-document-template-intent';
import { ControlledDocumentUploadIntent } from '../../../../features/bizzmine/form/components/controls/controlled-document-control/controlled-document-upload-intent';
import { CollectionFormExternalAccessDto } from '../../../../../models/ts/collection-form-external-access-dto';
import { SignalRService } from '../../../../core/signalR/signal-r.service';
import { CompleteStepMessageCallBackState } from '../../../../../models/ts/complete-step-message';
import {
  DocumentComparisonProperties,
  DocumentComparisonPropertiesSignalRResult
} from '../../../../../models/ts/document-comparison-properties.model';
import { LoaderButtonComponent } from '../../loader-button/loader-button/loader-button.component';
import { DownloadService } from '../../../../core/services/download/download.service';

export interface DocumentCompareRequest {
  CollectionsId: number;
  InstancesId: number;
}

@Component({
  selector: 'bizz-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  imports: [
    PDFViewerModule,
    NgSwitch,
    NgSwitchCase,
    NgIf,
    TranslatePipe,
    IconComponent,
    UploadModule,
    ProgressBarModule,
    SliderModule,
    ExtensionIconComponent,
    MailingAttachmentsUploadComponent,
    DragFileUploadDirective,
    AsyncPipe,
    FileSelectMultiComponent,
    DecimalPipe,
    NgClass,
    BytesPipe,
    LoaderComponent,
    DatePipe,
    LoaderButtonComponent
  ],
  standalone: true
})

export class FileUploadComponent implements OnInit, AfterViewInit {
  public readonly FilePreviewDisplayType = FilePreviewDisplayType;
  public backendFile: MediaCommitDto = {} as MediaCommitDto;
  public frontendFile: FileInfo;

  public fileName: string;
  public fileSize: number;
  public fileUid: string = crypto.randomUUID().toString();
  public base64File: string;
  @ViewChild('pdfViewer')
  private pdfViewerElement: PDFViewerComponent;
  public isHoveringDragDrop = false;
  public extencionNotAllowed = false;

  public filePreview: FilePreviewDto | null = {} as FilePreviewDto;
  public viewerHeight: number;
  public fileUploads: Array<FileUpload> = [];
  public completedUploads: Array<{
    fileId: string,
    fileUpload: FileChunkUpload
  }> = [];
  public uploadOverSize = false;
  public uploaded = false;
  public uploadFailed = false;
  @Input() public instancesId: number;
  @Input() public versionsId: number;
  @Input() public fileTitle: string;
  @Input() public externalAccess: CollectionFormExternalAccessDto | undefined;

  // AngularJS scope properties
  @Input() public collectionsId: number;
  @Input() public previewEnabled: boolean;
  @Input() public formId: string;
  public recordId = input<number>();
  public gridFieldId = input<number>();
  @Input() public uploadFile$: BehaviorSubject<ControlledDocumentUploadIntent | ControlledDocumentTemplateIntent | undefined> = new BehaviorSubject<ControlledDocumentUploadIntent | ControlledDocumentTemplateIntent | undefined>(undefined);


  @Output() public fileChanged = new EventEmitter<MediaCommitDto | undefined>();

  // scope.field (sometimes a field, other times a custom file object)
  @Input() public field: CollectionFormField | undefined;

  // scope.flowState => optional, never passed through templates
  // scope.readMode => (used in settings)
  // scope.extensions => (used in settings)

  // scope.directEditOnGrid => true in the case of edit online
  @Input() public directEditOnGrid: boolean;

  // $scope.uploadType => CollectionInstance (in most uses) or CSV import (in settings)
  @Input() public uploadType: BizzMineUploadType;
  @Input() public disabled = false;
  public isInGrid = input<boolean>(false);

  public gridFileName = computed(() => {
    if (!this.isInGrid()) {
      return null;
    }
    if (this.recordId() == null || this.formId == null) {
      return null;
    }

    const fields = this.store.selectSignal(selectRecordFields(this.formId, this.gridFieldId()!, this.recordId()!, [ProtectedFieldType.File, ProtectedFieldType.Title, ProtectedFieldType.Extension]))();
    if (fields != null && fields[ProtectedFieldType.Title]?.Value != null && fields[ProtectedFieldType.Extension]?.Value != null) {
      return `${fields[ProtectedFieldType.Title].Value}${fields[ProtectedFieldType.Extension].Value}`;
    } else {
      return null;
    }
  })

  public instance = computed(() => {
    if (!this.isInGrid()) {
      return null;
    }
    const inst = this.store.selectSignal(selectRecordInstance(this.formId!, this.collectionsId, this.recordId() ?? 0))();
    return inst;
  });

  //TODO: commented unneeded inputs from old bizz here
  // $scope.base => the form
  /* @Input() form: CollectionForm;*/
  // scope.fileData => the file
  //@Input() file: unknown;
  public uploading = false;
  public lastKnownFile = signal<MediaCommitDto | undefined>(undefined);

  public maxFileSize = this.store.selectSignal(userSettingsFeature.selectUploadFileSize)();
  public errors: {
    oversize?: {
      maxFileSize: number,
      fileSize: number
    } | null,
    genericError?: boolean | undefined,
    emptyFile?: boolean | undefined,
    uploadError?: boolean | undefined,
    emptyExtension?: boolean | undefined,
    fileExtension?: string | undefined,
    fileNotAllowed?: boolean | undefined
  } | null = null;
  public isLocked = computed(() => {
    if (this.formId != null) {
      return this.store.selectSignal(selectFormLockState(this.formId))();
    }
    return false;
  });

  public currentFile = computed(() => {
    if (this.formId && this.field) {
      const found = this.store.selectSignal(selectFormFieldValues(this.formId, { viewDataSourceId: this.field.ViewDataSourcesID }, [ProtectedFieldType.Title, ProtectedFieldType.File, ProtectedFieldType.Extension]))();

      if (found) {
        const fileId = found[ProtectedFieldType.File] as number | undefined;
        if (fileId != undefined && fileId > 0) {
          return found[ProtectedFieldType.Title] as string + found[ProtectedFieldType.Extension] as string;
        }
      }
    }
    const lastKnownFile = this.lastKnownFile();
    if (lastKnownFile != undefined) {
      return lastKnownFile.FileName;
    }
    return null;
  });
  public documentTemplate = input<SimpleDocumentTemplateDto>();
  public documentTemplateSettings = computed(() => {
    if (this.documentTemplate() != null) {
      return this.documentTemplate();
    }
    if (this.formId == null) return null;
    if (this.isInGrid()) {
      const viewDataSources = this.store.selectSignal(selectFormViewDataSources(this.formId))();
      if (viewDataSources == null || viewDataSources == false || viewDataSources.length == 0) {
        return null;
      }
      const vds = viewDataSources.find(v => v.ChildCollectionsID == this.collectionsId);
      if (vds == null) {
        return null;
      }
      return {
        TemplateViewsID: vds.TemplateViewsID,
        TemplateViewListID: vds.TemplateViewListID,
        StartFromTemplate: vds.StartFromTemplate
      }
    }
    const form = this.store.selectSignal(selectForm(this.formId))();
    return form?.data?.DocumentTemplateSettings;
  });
  public forceStartFromTemplate = computed(() => {
    if (this.formId == null) {
      return false;
    }
    const settings = this.documentTemplateSettings();
    return settings != null && settings.StartFromTemplate && (this.instancesId == null || this.instancesId <= 0);
  });
  public documentProperties = computed(() => this.store.selectSignal(selectForm(this.formId))()?.data?.DocumentProperties);
  public fetchedDocumentComparison = signal<DocumentComparisonProperties | undefined>(undefined);
  public documentComparison = computed(() => {
    const fetched = this.fetchedDocumentComparison();
    return fetched ?? this.documentProperties()?.DocumentComparison;
  })
  public tools = computed(() => {
    const result: PDFViewerTool[] = [
      'pager',
      'spacer',
      'zoomInOut',
      'zoom',
      'spacer',
      'search',
    ];
    if (this.formId != null) {
      const properties = this.store.selectSignal(selectForm(this.formId))()?.data?.Properties;
      if (properties != null) {
        if (properties.PdfPermissions != null && properties.PdfPermissions.length == 4) {
          if (properties.PdfPermissions[3].Allow) {
            //push selection after "zoom" tool 
            result.splice(4, 0, 'selection');
          }
          if (properties.PdfPermissions[0].Allow) {
            result.push('print');
          }
        }

        if (properties.UsePDFConverter) {
          result.push('download');
        }
      }
    }

    return result;
  });
  public validatingFile = false;
  public canDownload = computed((): boolean => {
    if (this.formId == null) {
      return !this.isLocked();
    }
    const fileField = this.store.selectSignal(selectFormFieldsByProtectedFieldType(this.formId, this.collectionsId, [ProtectedFieldType.Title, ProtectedFieldType.Extension, ProtectedFieldType.File, ProtectedFieldType.Size]))() as Array<CollectionFormField>;
    return !!fileField[ProtectedFieldType.File].Value && !!this.versionsId && fileField[ProtectedFieldType.Title].Value && !!fileField[ProtectedFieldType.Extension].Value && !!this.collectionsId && !!this.instancesId && !this.isLocked();
  });
  public refreshingPreview = false;
  public fileSizeLargePreview = computed(() => {
    if (this.formId) {
      const field = this.store.selectSignal(selectFormFieldsByProtectedFieldType(this.formId, this.collectionsId, [ProtectedFieldType.Size]))()[ProtectedFieldType.Size] as CollectionFormField;
      return field?.Value;
    }
  });
  public modificationReasonFromOnlineEdit: string;
  protected readonly ondragover = ondragover;
  protected readonly FileUploadEditOnlineModalComponentMode = FileUploadEditOnlineModalComponentMode;
  private _startState: DocumentCheckinType | null = null;
  private invokeRefreshWhenNeeded = signal<number>(0);
  public showStartFromTemplate = computed(() => {
    this.invokeRefreshWhenNeeded();
    const userType = this.store.selectSignal(userSettingsFeature.selectUserType)();
    if (userType == null || userType == UserType.ExternalUser || userType == UserType.AnonymousUser) {
      return false;
    }

    const settings = this.documentTemplateSettings();
    return settings != null && settings.TemplateViewsID != null && settings.TemplateViewsID > 0;
  });

  public downloadKendoPreviewFile($event: PDFViewerDownloadEvent): void {
    $event.preventDefault();
    const documentProperties = this.documentProperties();
    const form = this.store.selectSignal(selectForm(this.formId))()?.data;
    if (form != undefined && documentProperties != undefined) {
      this.gridService.downloadFromInstance({
        PDFMediaID: documentProperties.PDFMediaID ?? 0,
        UsePDFConverter: form.Properties.UsePDFConverter ?? false,
        Permissions: form.Permissions,
        ID: this.instancesId,
        CollectionsID: this.collectionsId,
        VersionsID: this.versionsId,
        DraftsID: 0,
        OriginalFoldersID: form.FoldersID,
        CanReadDrafts: true
      });
    }
  }

  public showCheckIn = computed(() => {
    this.invokeRefreshWhenNeeded();
    const userType = this.store.selectSignal(userSettingsFeature.selectUserType)();
    if (userType == null || userType == UserType.ExternalUser || userType == UserType.AnonymousUser) {
      return false;
    }
    if (this.field == null || this.formId == null) {
      return false;
    }
    if (this.field.IsReadOnly) {
      return false;
    }
    if (this.disabled) {
      return false;
    }
    const form = this.store.selectSignal(selectForm(this.formId))();
    const documentCheckinStatusType = form?.data?.DocumentProperties?.DocumentCheckinStatus;
    if (documentCheckinStatusType) {
      const userId = this.store.selectSignal(userSettingsFeature.selectUserID)();
      return (documentCheckinStatusType == DocumentCheckinType.CheckedOut) && this.documentProperties()?.CheckedOutByID == userId;
    }
    return false;
  }
  );
  public showViewOnline = computed(() => {
    this.invokeRefreshWhenNeeded();
    if (this.formId == null || this.field == null) {
      return false;
    }

    const form = this.store.selectSignal(selectForm(this.formId))();
    if (form?.data == null) {
      return false;
    }
    if (form.data.DirectEditMode == DirectEditMode.Disabled) {
      return false;
    }

    if (!form.data.DirectEditAdminConsented) {
      return false;
    }

    if (this.showCheckIn()) {
      return false;
    }

    const userType = this.store.selectSignal(userSettingsFeature.selectUserType)();

    if (userType == UserType.ExternalUser || userType == UserType.AnonymousUser) {
      return false;
    }
    const fileField = this.store.selectSignal(selectFormFieldsByProtectedFieldType(this.formId, this.collectionsId, [ProtectedFieldType.File]))()[ProtectedFieldType.File] as CollectionFormField;

    if (fileField.Value == null || fileField.Value <= 0) {
      return false;
    }

    if (form.data.Permissions == null || form.data.DocumentProperties == null) {
      return false;
    }

    return form.data.Permissions['CanReadProperties'] || (form.data.Permissions['CanReadDocPDF'] && form.data.DocumentProperties.PDFMediaID > 0 && form.data.Properties.UsePDFConverter);

  });
  public startState = computed(() => {
    this.invokeRefreshWhenNeeded();
    if (this._startState != null) {
      return this._startState;
    }
    const form = this.store.selectSignal(selectForm(this.formId))();
    const docProp = form?.data?.DocumentProperties;
    this._startState = docProp?.DocumentCheckinStatus ?? null;
    return this._startState;
  });
  public showCheckOut = computed(() => {
      this.invokeRefreshWhenNeeded();
      const userType = this.store.selectSignal(userSettingsFeature.selectUserType)();
      if (userType == null || userType == UserType.ExternalUser || userType == UserType.AnonymousUser) {
        return false;
      }
      if (this.field == null || this.formId == null) {
        return false;
      }
      if (this.field.IsReadOnly) {
        return false;
      }
      if (this.disabled) {
        return false;
      }
      const startState = this.startState();
      if (startState == null || startState == DocumentCheckinType.NoDocument) {
        return false;
      }
      const form = this.store.selectSignal(selectForm(this.formId))();
      const hasWorkFlow = form?.data?.HasWorkflow ?? false;
      const documentCheckinStatusType = form?.data?.DocumentProperties?.DocumentCheckinStatus;
      if (documentCheckinStatusType) {
        if (hasWorkFlow && documentCheckinStatusType == DocumentCheckinType.Published) {
          return false;
        }
        if (documentCheckinStatusType == DocumentCheckinType.CheckedIn || documentCheckinStatusType == DocumentCheckinType.PreviousData || documentCheckinStatusType == DocumentCheckinType.Uploaded) {
          return true;
        }
      }
      return false;
    }
  );
  public showEditOnline = computed(() => {
    this.invokeRefreshWhenNeeded();
    if (this.formId == null || this.field == null) {
      return false;
    }

    const form = this.store.selectSignal(selectForm(this.formId))();
    if (form?.data == null) {
      return false;
    }
    if (form.data.DirectEditMode == DirectEditMode.Disabled) {
      return false;
    }

    if (!form.data.DirectEditAdminConsented) {
      return false;
    }

    if (!this.showCheckOut() && form.data.HasWorkflow) {
      return false;
    }

    const userType = this.store.selectSignal(userSettingsFeature.selectUserType)();

    if (userType == UserType.ExternalUser || userType == UserType.AnonymousUser) {
      return false;
    }
    const fileField = this.store.selectSignal(selectFormFieldsByProtectedFieldType(this.formId, this.collectionsId, [ProtectedFieldType.File]))()[ProtectedFieldType.File] as CollectionFormField;

    if (fileField.Value == null || fileField.Value <= 0) {
      return false;
    }
    return true;

  });
  private mediaSettings: SystemMediaSettingsDto | null;

  public showPdfViewer: boolean = true;
  public zoomLevel: number | "fitToPage" | "fitToWidth" = "fitToWidth";
  private resizeObserver: ResizeObserver;
  private lastObservedElementWidth: number = 0;
  private gridWidth$ = new BehaviorSubject<number>(0);

  public constructor(
    public collectionFormFileUploadApiService: CollectionFormFileUploadApiService,
    private elRef: ElementRef,
    public fileUploadCheckinService: FileUploadCheckinService,
    public fileUploadService: FileUploadService,
    public store: Store,
    private dialog: Dialog,
    private destroyRef: DestroyRef,
    private collectionListApiService: CollectionListApiService,
    private archiveApiService: ArchiveApiService,
    private editOnlineApiServiceService: EditOnlineApiServiceService,
    private actions: Actions,
    private gridService: GridService,
    private signalRService: SignalRService,
    private cdr: ChangeDetectorRef,

  ) {
  }

  public startFromTemplate(): void {
    if (this.isLocked()) {
      return;
    }
    this.dialog.open<any>(FileUploadSelectTemplateDialogComponent, {
      data: { viewListId: this.documentTemplateSettings()?.TemplateViewListID },
      disableClose: true
    })
      .closed.pipe(
        take(1)
      ).subscribe(selectedTemplate => {
        // when we set we may NOT forget to emit a file changed event also -> needed for outside form use (checkin modal etc)
        console.error('TODO selectedTemplate - select instance not yet implemented - work in progress', selectedTemplate);
        if (selectedTemplate != null) {
          this.copyFileToCurrent({
            mediaId: selectedTemplate.File,
            sourceCollectionId: selectedTemplate.CollectionsID,
            instanceId: selectedTemplate.ID,
            versionId: selectedTemplate.VersionsID,
            targetCollection: this.collectionsId
          });
        }
      });
  }

  public copyFileToCurrent(fileCopyIntent: {
    mediaId: number,
    sourceCollectionId: number,
    instanceId: number,
    versionId: number,
    targetCollection: number
  }): void {
    if (this.isInGrid() && this.recordId() != null && this.gridFieldId() != null && this.field) {
      // snatch the file away and do nothing - we must open a new form where the user must complete the file upload
      // WRONG ACTION DO STUFF
      //formFieldId: number, formId: string, gridFieldId: number, recordId: number, relationType: LinkedCollectionType, file: File
      this.store.dispatch(formsActions.getGridLinkedFormWithTemplate({
        formId: this.formId,
        formFieldId: this.field.CollectionFieldsID,
        gridFieldId: this.gridFieldId()!,
        recordId: this.recordId()!,
        relationType: LinkedCollectionType.GridRecord,
        selectedTemplate: fileCopyIntent,
        externalAccess: this.externalAccess
      }));
      return;
    }
    this.collectionFormFileUploadApiService.copyFile(fileCopyIntent).pipe(take(1)).subscribe(mediaDto => {
      const mediaCommitDto = {
        MediasID: mediaDto.ID,
        Size: mediaDto.Size,
        Type: mediaDto.MediaContentType,
        ChunkIDs: [],
        Identifier: mediaDto.Location,
        VersionsID: mediaDto.VersionsID,
        LastModifiedDate: mediaDto.UploadTimestamp?.toString(),
        FileName: mediaDto.OriginalFileName,
        CollectionsID: mediaDto.CollectionsID,
        MimeValidation: '',
        ContentType: ''
      }
      if (this.formId && this.field) {
        this.store.dispatch(formsActions.updateFileFields({
          formId: this.formId,
          file: mediaCommitDto,
          field: this.field as CollectionFormField
        }));
      }

      this.emitFileChange(mediaCommitDto);
    });
  }
  public ngAfterViewInit(): void {
    //Resize observer to update the width of the columns when the window or grid is resized.
    this.resizeObserver = new ResizeObserver(([entry]) => {
      if (this.lastObservedElementWidth != entry.contentRect.width) {
        this.lastObservedElementWidth = entry.contentRect.width;
        this.gridWidth$.next(entry.contentRect.width);
      }
    });
    this.resizeObserver.observe(this.elRef.nativeElement);
    this.gridWidth$.pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(150),
      map(() => {
        this.showPdfViewer = false;
        this.zoomLevel = "fitToWidth";
        this.cdr.detectChanges();
        this.showPdfViewer = true;
      })).subscribe();
  }
  public ngOnInit(): void {
    if (this.uploadFile$ != null) {
      this.uploadFile$.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(intent => {
          if (intent != null) {
            const fileUploadIntent = intent as ControlledDocumentUploadIntent;
            if (fileUploadIntent?.files != null && fileUploadIntent?.files.length > 0) {
              this.filesDropped(fileUploadIntent.files);
            }

            const templateIntent = intent as ControlledDocumentTemplateIntent;
            if (templateIntent?.selectedTemplate != null) {
              this.copyFileToCurrent(templateIntent.selectedTemplate);
            }
          }


        });
    }
    this.uploaded = false;
    this.base64File = '';

    this.store.select(selectForm(this.formId))
      .pipe(take(1)).subscribe(form => {
        this.mediaSettings = form?.data?.MediaSettings ?? null;
      }
      );
    this.store.select(selectForm(this.formId))
      .pipe(take(1)).subscribe({
        next: form => {
          this.instancesId = form?.data.InstancesID ?? 0;
          this.versionsId = form?.data.VersionsID ?? 0;
          if (this.previewEnabled) {
            this.viewerHeight = this.field?.Height ?? 1138;
          }
        }
      });
    if (this.previewEnabled) {

      this.refreshFilePreview();

    }

    //init startState for checking/checkout values
    this.startState();
    if (this.formId != null && this.field != null) {
      this.actions.pipe(takeUntilDestroyed(this.destroyRef), ofType(formsActions.updateForm), filter(event => event.update?.changes?.reset == true))
        .subscribe((event) => {
          this.instancesId = event.update.changes.data?.InstancesID ?? 0;
          this.versionsId = event.update.changes.data?.VersionsID ?? 0;
          this.viewerHeight = this.field?.Height ?? 1138;
          this._startState = null;
          this.invokeRefreshWhenNeeded.set(this.invokeRefreshWhenNeeded() + 1);
        });
    }
  }

  public ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }
  public refreshFilePreview(ignoreSize: boolean = false): void {
    if (this.instancesId !== 0) {
      this.refreshingPreview = true;
      this.collectionFormFileUploadApiService.previewFile({
        collectionsId: this.collectionsId,
        instancesId: this.instancesId,
        versionsId: this.versionsId,
        ignoreSize: ignoreSize,
        hidePreviewWatermarks: false,
        isPrint: true
      }
      )
        .pipe(take(1))
        .subscribe({
          next: filePreview => {
            this.filePreview = filePreview;

            asyncScheduler.schedule(() => {
              this.refreshingPreview = false;

            }, 1000);


          }
        });

    } else {
      this.filePreview = null;
    }
  }

  public openOnline(mode: FileUploadEditOnlineModalComponentMode): void {
    this.collectionListApiService.getModificationReason({
      instanceId: this.instancesId,
      collectionId: this.collectionsId,
      versionId: this.versionsId
    }).pipe(take(1), switchMap((currentReason) => {
      const formDataformData = this.store.selectSignal(selectFormFieldsByProtectedFieldType(this.formId, this.collectionsId, [ProtectedFieldType.File, ProtectedFieldType.Extension, ProtectedFieldType.Title]))() as Array<CollectionFormField>;
      const params: FileUploadEditOnlineModalComponentData = {
        mode: mode,
        docheckoutcheckin: this.instancesId > 0,
        mediaId: formDataformData[ProtectedFieldType.File].Value,
        filename: formDataformData[ProtectedFieldType.Title].Value + formDataformData[ProtectedFieldType.Extension].Value,
        modificationreason: currentReason,
        closeModalMethod: (): void => {
          dlgRef.close();
        },
        finishMethod: (data): void => {
          if (data.openedOneDriveWindow != null) {
            data.openedOneDriveWindow.close();
          }

            this.editOnlineApiServiceService.retrieveOnlineMediaId({
              mediaId: data.mediasID!,
              oneDriveKey: data.oneDriveKey,
              lastModified: data.lastModified
            }).pipe(take(1),
              switchMap(response => {
                if (response.body != null && response.body.byteLength > 0){
                  const headers = response.headers;
                  const fileName = DownloadService.cleanupFileName(headers.get('x-filename'));
                  const contentType = headers.get('contentType') ?? '';
                  const file = new File([response.body], fileName, { type: contentType });

                return this.editOnlineApiServiceService.removeCheckOutOnlineMedia({
                  mode: mode,
                  oneDriveKey: data.oneDriveKey,
                  undoCheckout: this.instancesId > 0,
                  mediasId: data.mediasID!
                }).pipe(finalize(() => dlgRef.close({ file: file, modificationreason: data.modificationReasonFromOnlineEdit })))
              } else {
                return this.editOnlineApiServiceService.removeCheckOutOnlineMedia({
                  mode: mode,
                  oneDriveKey: data.oneDriveKey,
                  undoCheckout: true,
                  mediasId: data.mediasID!
                }).pipe(finalize(() => dlgRef.close()))
              }
            })).subscribe();
        }
      };
      const dlgRef = this.dialog.open(FileUploadEditOnlineModalComponent, {
        data: params,
        disableClose: true
      });
      return dlgRef.closed;
    })
    ).subscribe((_: any) => {
      if (_ != null && _.file != null) {
        this.modificationReasonFromOnlineEdit = _.modificationreason;
        this.filesDropped([_.file]);
      }
    });
  }

  public checkOut(): void {
    const documentCheckinStatusTypeField = this.store.selectSignal(selectFormFieldsByProtectedFieldType(this.formId, this.collectionsId, [ProtectedFieldType.DocumentCheckinStatus]))()[ProtectedFieldType.DocumentCheckinStatus] as CollectionFormField;
    if (documentCheckinStatusTypeField) {
      this.fileUploadCheckinService.checkOutDocument({
        checkOut$: this.collectionFormFileUploadApiService.checkOut({
          collectionId: this.collectionsId,
          instanceId: this.instancesId,
          versionId: this.versionsId
        }),
        storeData: {
          store: this.store,
          formId: this.formId,
          documentCheckInStatusFieldId: documentCheckinStatusTypeField.Id
        }
      });
    }
  }

  public validateFile(formData: FormDataWithMeta): Observable<FormDataWithMeta> {
    if (this.mediaSettings?.MustBeValidated == false) {
      return of(formData);
    }
    const file = formData.formData[0].get('file') as File;
    const fileToBase64 = new Promise<string>(resolve => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(file);
    });
    return from(fileToBase64)
      .pipe(
        tap(() => this.validatingFile = true),
        switchMap(text => {
          return this.collectionFormFileUploadApiService.validateChunkBlob(
            {
              Media: text,
              Extension: this.fileUploadService.getFileExtension(formData.meta.fileName)
            }
          ).pipe(catchError((error) => {
            this.errors = {
              uploadError: true
            };
            throw error;
          }));
        }),
        switchMap(response => {
          if (!response.ValidExtension) {
            this.uploadFailed = true;
            this.extencionNotAllowed = true;

            throw ('Invalid extension!');
          } else if (!response.ValidMimeType) {
            const dialogRef = this.dialog.open<boolean>(MimeTypeModalComponent, {
              data: new MimeTypeModalData(response),
              disableClose: true
            });
            return dialogRef.closed.pipe(
              tap(validExtension => {
                if (!validExtension) {
                  throw new Error('Invalid extension - cancelled by user!');
                }
              })
            );
          } else {
            return of(response);
          }
        }),
        map(() => formData),
        finalize(() => this.validatingFile = false)
      );
    // perform your custom validation
    // throw an exception if the file is not valid -> this will cancel the upload
  }

  public filesDropped(files: Array<File>): void {
    if (this.forceStartFromTemplate()) {
      return;
    }
    if (this.isInGrid() && this.recordId() != null && this.gridFieldId() != null && this.field) {
      // snatch the file away and do nothing - we must open a new form where the user must complete the file upload
      // WRONG ACTION DO STUFF
      //formFieldId: number, formId: string, gridFieldId: number, recordId: number, relationType: LinkedCollectionType, file: File
      this.store.dispatch(formsActions.getGridLinkedFormWithFile({
        formId: this.formId,
        formFieldId: this.field.CollectionFieldsID,
        gridFieldId: this.gridFieldId()!,
        recordId: this.recordId()!,
        relationType: LinkedCollectionType.GridRecord,
        files: files,
        externalAccess: this.externalAccess
      }));
      return;
    }
    this.uploadOverSize = false;
    this.errors = null;
    if (this.uploading) return;
    this.uploading = true;
    this.frontendFile = files[0];
    files = [files[0]];
    if (files[0].size <= 0) {
      this.uploading = false;
      this.errors = {
        emptyFile: true
      };
      return;
    }

    const ext = getFileExtension(files[0].name);
    if (files[0].name == null || files[0].name.trim() == '' || ext == null || ext.trim() == '' || ext.trim() == '.') {
      this.uploading = false;
      this.errors = {
        emptyExtension: true
      };
      return;
    }

    if (this.maxFileSize > 0 && files[0].size > this.maxFileSize) {
      this.uploadOverSize = true;
      this.uploading = false;
      this.errors = {
        oversize: {
          maxFileSize: this.maxFileSize,
          fileSize: files[0].size
        }
      };
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (): void => {
      this.base64File = reader.result as string;
    };

    const fileUploads = this.fileUploadService.uploadFiles({
      upload$: (formData) => this.collectionFormFileUploadApiService.uploadFileFromFormData(formData),
      files: files,
      chunkSize: 1024 * 1024,
      maxConcurrentUploads: 4,
      preValidation$: (data) => this.validateFile(data)
    });

    fileUploads.forEach(fileUpload => {
      fileUpload.onError = (error: any): void => {
        this.errors = {
          oversize: null,
          genericError: true
        };
        if (error != null && error.message == 'Invalid extension - cancelled by user!') {
          this.errors = null;
        }
        this.cancelUpload(fileUpload.fileId);
      };
      fileUpload.onCompleted = (): void => {
        if (fileUpload.fileChunkUpload) {
          let fileName = fileUpload.fileChunkUpload.fileName;
          this.latestUploadFileName = fileName;
          if (this.formId) {
            const titleField = this.store.selectSignal(selectFormFieldsByProtectedFieldType(this.formId, this.collectionsId, [ProtectedFieldType.Title]))() as Array<CollectionFormField>;
            if (titleField[ProtectedFieldType.Title]) {
              const value = titleField[ProtectedFieldType.Title].Value;
              if (value != null && value.trim() != '') {
                const extension = getFileExtension(fileName);
                fileName = value + extension;
              }
            }

          }
          const mediaCommitDto: MediaCommitDto = {
            FileName: fileName,
            LastModifiedDate: fileUpload.fileChunkUpload.lastModifiedDate.toString(),
            Identifier: fileUpload.fileId,
            Type: fileUpload.fileChunkUpload.type,
            Size: fileUpload.fileChunkUpload.size,
            ChunkIDs: fileUpload.fileChunkUpload.chunkIds,
            MediasID: 0,
            CollectionsID: this.collectionsId,
            ContentType: '',
            MimeValidation: '',
            VersionsID: this.versionsId
          };
          lastValueFrom(this.collectionFormFileUploadApiService.commitFile(mediaCommitDto))
            .then((response) => {
              this.uploading = false;
              mediaCommitDto.MediasID = response;
              this.finishUpload(fileUpload.fileId, response, fileUpload.fileChunkUpload as FileChunkUpload, mediaCommitDto);
            }).catch((error) => {
              this.errors = {
                uploadError: true
              };
              this.cancelUpload(fileUpload.fileId);
            });
        }
      };
    });

    this.fileUploads = this.fileUploads.concat(fileUploads);
  }

  public latestUploadFileName: string | undefined;

  public onFileSelected(event: any): void {
    if (event == null || event.target == null || event.target.files == null || event.target.files.length === 0) return;
    const file = event.target.files[0];
    this.filesDropped([file]);
  }

  public finishUpload(fileId: string, mediaId: number, file: FileChunkUpload, mediaCommitDto: MediaCommitDto): void {
    this.uploaded = true;
    this.fileUploads = this.fileUploads.filter(file => file.fileId != fileId);
    const completedUpload = { fileId: fileId, fileUpload: file };
    this.completedUploads.push(completedUpload);

    // perform check for modificationReason
    if (this.formId) {
      if(this.modificationReasonFromOnlineEdit != null && this.modificationReasonFromOnlineEdit.trim() != ''){
        const changeReasonField = this.store.selectSignal(selectFormFieldsByProtectedFieldType(this.formId, this.collectionsId, [ProtectedFieldType.ChangeReason]))()[ProtectedFieldType.ChangeReason] as CollectionFormField;
        this.store.dispatch(formsActions.updateFormField({
          formId: this.formId,
          value: this.modificationReasonFromOnlineEdit,
          fieldId: changeReasonField.Id
        }));
        return
      }
      if (this.instancesId !== 0 && (this.documentProperties()?.DocumentCheckinStatus != DocumentCheckinType.NoDocument || this.showCheckOut())) {
        const changeReasonField = this.store.selectSignal(selectFormFieldsByProtectedFieldType(this.formId, this.collectionsId, [ProtectedFieldType.ChangeReason]))()[ProtectedFieldType.ChangeReason] as CollectionFormField;
        if (changeReasonField?.Value == null || changeReasonField?.Value.trim() == '') {
          this.collectionListApiService.getModificationReason({
            instanceId: this.instancesId,
            collectionId: this.collectionsId,
            versionId: this.versionsId
          })
            .pipe(take(1)).subscribe((currentReason) => this.validateReason(currentReason, changeReasonField));
        } else {
          this.validateReason(changeReasonField.Value as string, changeReasonField);
        }

      }
      this.store.dispatch(formsActions.updateFileFields({
        formId: this.formId,
        file: mediaCommitDto,
        field: this.field as CollectionFormField
      }));

      const fileField = this.store.selectSignal(selectFormFieldsByProtectedFieldType(this.formId, this.collectionsId, [ProtectedFieldType.Title, ProtectedFieldType.Extension, ProtectedFieldType.File, ProtectedFieldType.Size]))() as Array<CollectionFormField>;

      this.store.dispatch(formsActions.updateFormCheckedIn({
        formId: this.formId,
        file: {
          title: fileField[ProtectedFieldType.Title].Value as string,
          extension: fileField[ProtectedFieldType.Extension].Value as string,
          id: fileField[ProtectedFieldType.File].Value as number,
          size: fileField[ProtectedFieldType.File].Value as number
        }
      }));
    }
    this.emitFileChange(mediaCommitDto);
  }

  public emitFileChange(file: MediaCommitDto | undefined): void {
    if (file == undefined) {
      this.latestUploadFileName = undefined;
    }
    this.lastKnownFile.set(file);
    this.fileChanged.emit(file);
    this.errors = null;
  }

  public cancelUploadByUid(uid: string, uploadFailed: boolean): void {
    this.fileUploads = this.fileUploads.filter(file => file.fileId != uid);
  }

  public downloadFromGrid(): void {
    const instance = this.instance();
    if (instance) {
      this.gridService.downloadFromInstance({
        CanReadDrafts: true,
        CollectionsID: this.collectionsId,
        VersionsID: instance.ChildVersionsID,
        ID: instance.ChildInstancesID,
        DraftsID: 0
      });
    }

  }
  public downloadFromDataSource(): void {
    if (this.formId == null) {
      return;
    }
    if (this.field == null || this.field.ViewDataSourcesID == null || this.field.ViewDataSourcesID <= 0) {
      return;
    }
    const form = this.store.selectSignal(selectForm(this.formId))();
    if (form == null) return;
    const instances = form.data?.ViewDataSources?.find(vds => vds.ViewDataSourcesID == this.field?.ViewDataSourcesID)?.Instances;
    if (instances == null || instances.length <= 0) return;
    const instance = instances[0];
    this.gridService.downloadFromInstance({
      CanReadDrafts: true,
      CollectionsID: this.collectionsId,
      VersionsID: instance.ChildVersionsID,
      ID: instance.ChildInstancesID,
      DraftsID: 0
    });
  }

  public startListenForPdfCompletedMessage(collectionsId: number, instancesId: number): void {
    this.signalRService.callBackMessageWithRequest<DocumentCompareRequest, DocumentComparisonPropertiesSignalRResult>('DocumentCompareRequest')
      .pipe(
        filter(response => response != null && response.request.CollectionsId == collectionsId && response.request.InstancesId == instancesId),
        take(1)
      ).subscribe({
        next: (result) => {
          switch (result.state) {
            case CompleteStepMessageCallBackState.Error: {
              this.fetchedDocumentComparison.set(undefined);
              this.loadingCompare = false;
              break;
            }
            case CompleteStepMessageCallBackState.Success: {
              if (result.item?.compareFileMediaID != null && result.item.compareFileMediaID > 0) {
                this.downloadComparisonFile(result.item.compareFileMediaID);
                this.fetchedDocumentComparison.set({
                  CompareFileMediaID: result.item.compareFileMediaID,
                  CompareAiSummary: result.item.compareAiSummary,
                  CompareAction: result.item.compareAction,
                  CompareDate: result.item.compareDate,
                  InstanceCanBeCompared: result.item.instanceCanBeCompared,
                  SourceTitle: result.item.sourceTitle,
                  SourceVersion: result.item.sourceVersion,
                  SourceVersionsID: result.item.sourceVersionsID,
                  TargetTitle: result.item.targetTitle,
                  TargetVersion: result.item.targetVersion,
                  TargetVersionsID: result.item.targetVersionsID
                });
              }
              this.loadingCompare = false;
              break;
            }
            default: {
              this.loadingCompare = false;
              break;
            }
          }
        }
      });
  }

  public download(): void {
    if (this.canDownload()) {
      if (this.field?.IsReadOnly) {
        const form = this.store.selectSignal(selectForm(this.formId))();
        this.gridService.downloadFromInstance({
          CanReadDrafts: true,
          CollectionsID: this.collectionsId,
          VersionsID: this.versionsId,
          ID: this.instancesId,
          PDFMediaID: form?.data?.DocumentProperties?.PDFMediaID,
          UsePDFConverter: form?.data?.Properties?.UsePDFConverter,
          OriginalFoldersID: form?.data?.FoldersID,
          Permissions: form?.data.Permissions,
          DraftsID: 0
        })
      } else {
        const fileField = this.store.selectSignal(selectFormFieldsByProtectedFieldType(this.formId, this.collectionsId, [ProtectedFieldType.Title, ProtectedFieldType.Extension, ProtectedFieldType.File, ProtectedFieldType.Size]))() as Array<CollectionFormField>;
        this.archiveApiService.downloadFile({
          File: fileField[ProtectedFieldType.File].Value as number,
          VersionsID: this.versionsId,
          Title: fileField[ProtectedFieldType.Title].Value as string,
          Extension: fileField[ProtectedFieldType.Extension].Value as string,
          CollectionsID: this.collectionsId,
          ID: this.instancesId
        });
        return;
      }
    }

    const lastKnownFile = this.lastKnownFile();
    if (lastKnownFile != null) {
      this.archiveApiService.downloadFile({
        File: lastKnownFile.MediasID,
        VersionsID: this.versionsId,
        Title: this.fileTitle ?? lastKnownFile.FileName,
        Extension: getFileExtension(lastKnownFile.FileName),
        CollectionsID: this.collectionsId,
        ID: this.instancesId
      });
    }
  }

  public downloadComparisonFile(fileId: number): void {
    this.collectionListApiService.downloadCompareFileBase64({
      instanceId: this.instancesId,
      collectionsId: this.collectionsId,
      versionsId: this.versionsId,
      compareFileMediaId: fileId,
      fileName: 'test.pdf'
    }).subscribe(arrayBuffer => {
      this.fetchedCompareFile = btoa(
        new Uint8Array(arrayBuffer)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      this.compareFile = undefined;
      this.compare();
    });
  }

  public compareFile: string | undefined;
  public fetchedCompareFile: string | undefined;
  public loadingCompare = false;
  public compare(): void {
    if (this.loadingCompare) {
      return;
    }
    if (this.compareFile != undefined) {
      this.compareFile = undefined;
      return;
    }
    if (this.fetchedCompareFile != undefined) {
      this.compareFile = this.fetchedCompareFile;
      return;
    }

    const documentComparison = this.documentComparison();
    if (documentComparison == undefined) return;

    if (documentComparison?.CompareFileMediaID != undefined && documentComparison.CompareFileMediaID > 0) {
      this.downloadComparisonFile(documentComparison.CompareFileMediaID);

    } else {
      this.generatePdfCompare();
    }
  }

  public generatePdfCompare(): void {
    if (this.loadingCompare) {
      return;
    }
    this.loadingCompare = true;
    this.archiveApiService.compareFile({
      collectionId: this.collectionsId,
      instanceId: this.instancesId
    }).pipe(take(1)).subscribe({
      next: () => {
        this.startListenForPdfCompletedMessage(this.collectionsId, this.instancesId);
      },
      error: () => {
        this.loadingCompare = false;
      }
    });
  }

  public deleteFile(): void {
    this.dialog.open<boolean>(FileUploadDeleteModalComponent, {
      disableClose: true
    }).closed.pipe(take(1)).subscribe(comfirm => {
      if (comfirm) {
        if (this.formId) {
          this.store.dispatch(formsActions.clearFileFields({
            formId: this.formId,
            field: this.field as CollectionFormField
          }));
        }
        this.emitFileChange(undefined);
      }

    });
  }

  public cancelUpload(fileId: string): void {
    this.uploading = false;
    this.fileUploads = this.fileUploads.filter(fileUpload => fileUpload.fileId != fileId);
    // perform your logic to be applied AFTER the upload has been cancelled
    // for either an error or an actual unsub (cancel)
  }

  public triggerButtonClick(): void {
    if (this.isLocked()) {
      return;
    }
    const fileUploadInput = document.getElementById('fileUpload_' + this.fileUid)?.getElementsByTagName('input')[0] as HTMLInputElement;
    if (fileUploadInput) {
      fileUploadInput.showPicker();
    }
  }

  public downloadFile(): void {
    this.collectionFormFileUploadApiService.downloadFile(this.backendFile).subscribe(data => saveAs(data, this.backendFile.FileName));
  }

  private validateReason(currentReason: string, changeReasonField: CollectionFormField): void {
    this.dialog.open<string | undefined>(FileUploadModificationReasonModalComponent, {
      data: { modificationReason: currentReason },
      disableClose: true
    }).closed.pipe(take(1)).subscribe(modificationReason => {
      this.store.dispatch(formsActions.updateFormField({
        formId: this.formId,
        value: modificationReason,
        fieldId: changeReasonField.Id
      }));
    });
  }

}

