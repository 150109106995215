import { Component, Input, OnInit } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { GridModule } from '@progress/kendo-angular-grid';
import { TranslatePipe } from "../../../../../../../shared/pipes/translate/translate.pipe";
import { UtcDateTimePipe } from "../../../../../../../shared/pipes/dates/utc-date-time.pipe";

@Component({
    standalone: true,
    selector: 'bizz-assessment-grid',
    templateUrl: './assessment-grid.component.html',
    styleUrls: ['./assessment-grid.component.scss'],
    imports: [NgScrollbarModule, GridModule, TranslatePipe, UtcDateTimePipe]
})
export class AssessmentGridComponent implements OnInit {

  @Input() public assessmentList: any[] = [];
  public readonly MAX_MOBILE_WIDTH = 1024;

  public constructor() { 

  }

  public ngOnInit(): void {
  }

}
