<div class="flex flex-row justify-between px-4 pb-4 pt-2.5">
    <div class=" ">
        {{ element.Caption }}
    </div>


    <button class="default group text-button" (click)="selectMailTemplate()"
            [disabled]="(formData$ | async)?.IsLocked || element.IsReadOnly">
        <bizz-icon
                class="header-popup-icon text-button group-hover:text-blue-500"
                iconName="envelope">
        </bizz-icon>
        <span class="group-hover:text-blue-500">{{ "CreateMail" | translate }}</span>
    </button>
</div>

<div class="flex flex-row justify-between">

    @if (gridOptions$ | async; as go) {
        <bizz-grid class="bizz-grid flex h-full max-w-full flex-col overflow-hidden rounded"
                   [currentPage]="filter$.value.Page"
                   [loading]="isLoading"
                   [gridOptions]="gridOptions"
                   [totalItems]="totalItems"
                   [autoFitColumns]="true"
                   [gridData]="(data$ | async) ?? []"
                   (cellAction)="onCellAction($event)"
                   (pageChange)="onPageChange($event)"
                   [showPager]="true"
                   (sortChange)="onSortChange($event)"
                   (groupChange)="onGroupChange($event)">

        </bizz-grid>
    }
</div>