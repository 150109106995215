
<div class="modal-container modal-sm sm:w-[640px]">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="dialogRef.close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>

    <div class="modal-body w-full gap-5 !px-0">
        <div class="modal-header gap-4">
            <h1>
                @if(data.mode == TrainingSubscribeModalComponentMode.Subscribe){
                    {{'SubscribeToTraining' | translate}} {{ data.data.Name }}
                }

                @if(data.mode == TrainingSubscribeModalComponentMode.Queue){
                    {{ "AddUsersToSession" | translate}}
                }

            </h1>
            @if(data.mode == TrainingSubscribeModalComponentMode.Subscribe){
                <p>{{ "IfPossiblePleaseChooseYourPreferredSessionAndClickTheSubscribeButtonToConfirm" | translate }}</p>
            }
            @if(data.mode == TrainingSubscribeModalComponentMode.Queue){
                <p class="text-default">
                    {{"AddTemplateTrainingQueue" | translate}} <b>{{data.checkedUsers?.length}}</b> {{"UsersForTraining" | translate}} <b>{{ data.data.Name }}</b>
                </p>
            }
            <hr class="border-box m-auto mb-4 h-0 w-full border-t-2 border-divider">

        </div>

        @if(data.mode == TrainingSubscribeModalComponentMode.Subscribe && data.data.Description && data.data.Description.length > 0){
            <div class="rounded bg-widget p-4 text-default" [innerHTML]="data.data.Description | safe: 'html'">
            </div>
        }

        <div class="flex min-w-full flex-col gap-3">
            <div class="flex max-h-96 min-w-full flex-col gap-5 overflow-auto">
                @for (session of data.data.Sessions; track session.ID) {
                    <ng-container class
                                  *ngTemplateOutlet="sessionTemplate; context: {$implicit: session}"></ng-container>
                }
                @if(data.enableNoPreference){
                    <ng-container *ngTemplateOutlet="sessionTemplate; context: {$implicit: null}"></ng-container>
                }
                @if(data.enableCreateNewSession){
                    <div class="h-full w-full min-w-full" (click)="createNewSession()">
                        <div class="relative flex w-full cursor-pointer flex-row gap-3 rounded border-2 border-l-4 border-button border-l-blue-500 bg-card p-4 shadow hover:border-blue-300 hover:bg-blue-600/25">

                            <div class="mail-template-content flex w-full min-w-0 flex-col">

                                    <div class="flex flex-col">
                                        <div class="flex items-center gap-4">
                                            <bizz-icon iconName="plus" class="size-8"></bizz-icon>
                                            <span>{{ "AddNewSession" | translate }}</span>
                                        </div>
                                    </div>



                            </div>
                        </div>
                    </div>
                }

            </div>

        </div>


    </div>
    <div class="modal-buttons">
        <div class="flex flex-row justify-end gap-3 text-center">
            <button class="save" (click)="close()" [disabled]="!data.enableNoPreference && selectedSession == null">
                @if(data.mode == TrainingSubscribeModalComponentMode.Subscribe){
                    {{ "Subscribe" | translate }}
                }
                @if(data.mode == TrainingSubscribeModalComponentMode.Queue){
                    {{ "AddToSession" | translate }}
                }
            </button>
        </div>

    </div>


</div>

<ng-template #sessionTemplate let-template class="h-full w-full min-w-full">
    <div class="relative flex w-full cursor-pointer flex-row gap-3 rounded border-2 border-l-4 border-section border-l-blue-500 bg-card p-4 shadow drop-shadow-md hover:border-blue-300 hover:bg-blue-600/25"
         [ngClass]="{ '!bg-blue-600/25 !border-blue-500' : selectedSession?.ID === template?.ID }"
         (click)="selectedSession = template">

        <div class="mail-template-content flex w-full min-w-0 flex-col">
            <div class="flex flex-row justify-between">
                @if (selectedSession?.ID == template?.ID) {
                    <bizz-icon class="absolute right-2 top-2 size-4 text-blue-500" iconName="circle-check"></bizz-icon>
                }
            </div>

            @if (template != null) {
                <div class="grid grid-cols-5">
                <div class="col-span-4 flex grid-cols-subgrid flex-col">
                    <div class="flex items-center gap-4">
                        <span class="flex w-8 items-center justify-center"><bizz-icon iconName="clock" class="size-4"></bizz-icon></span>
                        <span>{{ template.From | utcDate }}</span>  <span>{{ template.From  | time }}</span>
                    </div>
                    @if(template.To){
                    <div class="flex items-center gap-4">
                        <span class="flex w-8 items-center justify-center"><bizz-icon iconName="flag-checkered" class="size-4"></bizz-icon></span>
                        <span [class.invisible]="isSameDate(template.To, template.From)">{{ template.To  | utcDate }}</span>  <span>{{ template.To  | time }}</span>
                    </div>
                    }
                </div>
                @if(template.MaxParticipants>0){
                    <div class="flex items-center gap-2">
                        <bizz-icon iconName="user" class="size-4"></bizz-icon>
                        <p>{{template.NumberOfParticipants}}/{{template.MaxParticipants}}</p>
                    </div>
                }
            </div>
            } @else {
                <div class="flex flex-col">
                    <div class="flex items-center gap-4">
                        <bizz-icon iconName="user-check" class="size-8" [class.text-blue-500]="selectedSession?.ID == template?.ID"></bizz-icon>
                        <span>{{ "NoPreference" | translate }}</span>
                    </div>
                </div>
            }


        </div>
    </div>
</ng-template>