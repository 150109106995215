import { createReducer, on } from '@ngrx/store';
import { initialUserSettingsStoreState } from './user-settings-state';
import { userSettingsActions } from './user-settings-actions';

export const userSettingsReducer = createReducer(
  initialUserSettingsStoreState,
  on(
    userSettingsActions.userSettingsFetched,
    (state, {userSettings}) => {
      return {...userSettings, AnonymousPortalUserLanguage: state.AnonymousPortalUserLanguage}
    }
  ),
  on(userSettingsActions.setAnonymousPortalLanguage,    (state, {language}) => {
    const userSettings = state;
    return {...userSettings, AnonymousPortalUserLanguage: language}
  }),
  on(userSettingsActions.setAnonymousPortalTheme,    (state, {theme}) => {
    const userSettings = state;
    return {...userSettings, ThemesID: theme ?? 0}
  })
);

