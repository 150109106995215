import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrnExamDto } from '../../../../../../../models/ts/trn-exam-dto.model';
import { LoaderButtonComponent } from '../../../../../../shared/components/loader-button/loader-button/loader-button.component';
import { SafePipe } from 'safe-pipe';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';

@Component({
  selector: 'bizz-training-exam-cover-content',
  standalone: true,
  imports: [
    LoaderButtonComponent,
    SafePipe,
    TranslatePipe,
    IconComponent
  ],
  templateUrl: './training-exam-cover-content.component.html',
  styleUrl: './training-exam-cover-content.component.scss'
})
export class TrainingExamCoverContentComponent {
  @Input({required: true}) public exam: TrnExamDto;
  @Input({required: true}) public isLoading: boolean;
  @Output() public startExam = new EventEmitter<void>();
}
