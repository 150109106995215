export enum ElectronicSignatureType {
	Read = 1,
	Edit = 2,
	Create = 3,
	Export = 4,
	Delete = 5,
	RecoverTrashCan = 6,
	DefaultWorkflow = 7,
	CheckIn = 8,
	Download = 9
}