@if (text) {
    <div kendoTooltip class="flex" tooltipClass="!z-[9000]" [tooltipWidth]="500">
        <bizz-icon
                class="h-3 w-3"
                title="{{text}}"
                [hover]="false"
                iconName="circle-info">
        </bizz-icon>
    </div>
}
