import { Component, Input, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { CollectionHistoryApiService } from 'src/app/api/bizzmine/collection-history/collection-history-api.service';
import { AuditLogItemType } from 'src/models/ts/audit-log-item-type.model';
import { GridDataDto } from 'src/models/ts/grid-data-dto.model';
import { GridFilterDto } from 'src/models/ts/grid-filter-dto.model';
import { PermissionHistoryGridSearchDto } from 'src/models/ts/permission-history-grid-search-dto.model';
import { GridOptions } from 'src/app/shared/classes/list/grid-options';
import { GridGroupSortDto } from 'src/models/ts/grid-group-sort-dto.model';
import { ListDesignGridOptionsDto } from 'src/models/ts/list-design-grid-options-dto.model';
import { CollectionType } from 'src/models/ts/collection-type.model';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { BehaviorSubject, filter, of, shareReplay, switchMap, tap } from 'rxjs';
import { DatetimePipe } from 'src/app/shared/pipes/dates/datetime.pipe';
import { Dialog } from '@angular/cdk/dialog';
import { PermissionHistoryDetailsComponent } from './permission-history-details/permission-history-details.component';
import { PermissionAccessExceptionDto } from 'src/models/ts/permission-access-exception-dto.model';
import { PermissionHistoryDto } from 'src/models/ts/permission-history-dto.model';

@Component({
  selector: 'bizz-permission-history-grid',
  templateUrl: './permission-history-grid.component.html',
  styleUrl: './permission-history-grid.component.scss',
  imports: [AsyncPipe, GridModule, TranslatePipe, DatetimePipe
  ],
  standalone: true
})

export class PermissionHistoryGridComponent implements OnInit {
  @Input() public foreignId: number;
  @Input() public itemType: AuditLogItemType;

  public gridOptions: GridOptions;
  public listOptions: ListDesignGridOptionsDto;
  public collectionType: CollectionType;

  public loading = true;
  public currentPage = 1;
  public totalItems = 0;
  public listSearchFilter$: BehaviorSubject<Partial<PermissionHistoryGridSearchDto> | undefined> = new BehaviorSubject<Partial<PermissionHistoryGridSearchDto> | undefined>(undefined);

  public gridData: BehaviorSubject<GridDataDto[] | any[]> = new BehaviorSubject<GridDataDto[] | any[]>([]);

  public filterData: GridFilterDto;


  /**
 * When the list search filter updates and is not undefined,
 * we fetch the data from the server,
 * update the gridData
 * and update the totalItems and wheter we have an activeFilter
 */
  public gridResultData$ = this.listSearchFilter$.pipe(
    filter((search) => search != undefined),
    tap(() => this.loading = true),
    switchMap((search) => this.collectionHistoryApiService.searchPermissionhistoryAuditlogSettings(this.foreignId, search!)),
    tap((value) => {
      this.loading = false;
      this.gridData.next(value.Data);
    }),
    switchMap((value) => {
      return of({
        totalItems: value.TotalRecords,
        activeFilter: value.ActiveSearchfilter,
      });
    }),
    shareReplay(1)).subscribe();

    protected readonly AuditLogItemType = AuditLogItemType;

  public constructor(private collectionHistoryApiService: CollectionHistoryApiService, private dialog: Dialog) {
  }


  public ngOnInit(): void {

    this.collectionHistoryApiService.getPermissionHistoryGridOptions(this.itemType).subscribe((gridOptionsDto) => {
      this.gridOptions = new GridOptions(gridOptionsDto);
      this.fetchSearchData();
    });

  }

  public fetchSearchData(): void {
    this.listSearchFilter$.next({
      SearchFilters: this.filterData,
      Page: this.currentPage,
      Records: this.gridOptions.pageSize,
      Group: this.gridOptions.dataSource.group as GridGroupSortDto[],
      Sort: this.gridOptions.dataSource.sort as GridGroupSortDto[],
    });
  }

  
  public showDetails(permissionHistory: PermissionHistoryDto): void {
    this.collectionHistoryApiService.getPermissionHistoryDetails(permissionHistory.PermissionHistoryID).subscribe(
      (historyDetails: PermissionAccessExceptionDto []) => {
        this.dialog.open(PermissionHistoryDetailsComponent, {data: {
          permissionHistory,
          historyDetails
        }})
      })
  }


}
