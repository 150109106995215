import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { formsActions } from '../forms-actions';
import { exhaustMap, filter, map, of, zip } from 'rxjs';
import { selectForm, selectFormField } from '../forms-selectors';
import { FormScriptingService } from '../../../../features/bizzmine/form/services/scripting/form-scripting.service';
import { CollectionFormService } from '../../../../features/bizzmine/form/services/collection-form.service';
import { concatLatestFrom } from '@ngrx/operators';

/**
 * Effects specific to scripting
 */
@Injectable()
export class FormsScriptingEffects {
  private actions$ = inject(Actions);
  private store$ = inject(Store);

  public checkForScripting$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateFormFieldValue, formsActions.updateOrgChartFieldValue, formsActions.updateFormFieldEnumValue),
    concatLatestFrom(({formId, fieldId}) => [
      this.store$.select(selectFormField(formId, fieldId)),
    ]),
    filter(([props, field]) => field !== undefined && FormScriptingService.hasScripting(field)),
    map(([props, field]) => formsActions.scriptingTriggered({
      formId: props.formId,
      fieldId: props.fieldId
    }))
  ));

  private formScriptingService = inject(FormScriptingService);
  public performScripting$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.scriptingTriggered),
    concatLatestFrom(({formId, fieldId}) => [
      this.store$.select(selectForm(formId)),
      this.store$.select(selectFormField(formId, fieldId)),
    ]),
    map(([props, form, field]) => {
      if (form?.data !== undefined && field !== undefined) {
        return formsActions.updateForm({
          update: {
            id: props.formId,
            changes: CollectionFormService.toStoreCollectionForm(this.formScriptingService.performScripting(structuredClone(form.data), field.Evaluations), props.formId)
          }
        })
      } else return formsActions.scriptingFailed({
        formId: props.formId
      })
    })
  ));

  public performOnOpenScripting$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.performOnOpenScripting),
    map(({ formId, form }) => formsActions.updateScriptingForm({
      update: {
        id: formId,
        changes: CollectionFormService.toStoreCollectionForm(this.formScriptingService.performOnOpenScripting(structuredClone(form)), formId)
      }
    }))
  ));
}