import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslatePipe } from '../../../pipes/translate/translate.pipe';
import { InstanceSelectionListComponent } from '../../../../features/bizzmine/form/components/lookup-search-modal/instance-selection-list/instance-selection-list/instance-selection-list.component';
import { ModalComponent } from '../modal/modal.component';
import { ProgressBarComponent } from '@progress/kendo-angular-progressbar';
import { IconComponent } from '../../ui/icon/icon.component';

@Component({
  selector: 'bizz-user-session-idle-modal',
  standalone: true,
  imports: [
    TranslatePipe,
    InstanceSelectionListComponent,
    ModalComponent,
    ProgressBarComponent,
    IconComponent
  ],
  templateUrl: './user-session-idle-modal.component.html',
  styleUrl: './user-session-idle-modal.component.scss'
})
export class UserSessionIdleModalComponent {

  public remaining: number = 60;
  public totalTime: number = 60;

  public progressStyles: { [key: string]: string } = {
    color: '',
    background: '#428bca',
  };
}
