import { Component, Inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../ui/icon/icon.component';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { TranslatePipe } from '../../../pipes/translate/translate.pipe';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { SafePipe } from 'safe-pipe';
import { TableViewItemInListDto } from 'src/models/ts/table-view-item-in-list-dto.model';
import { CollectionButtonComponent } from '../../ui/collection-button/collection-button.component';
import { FormsModule } from '@angular/forms';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { CollectionSchedulerApiService } from 'src/app/api/bizzmine/collection-scheduler/collection-scheduler-api.service';
import { GridModule, PageChangeEvent, SharedModule } from '@progress/kendo-angular-grid';
import { SkeletonModule } from '@progress/kendo-angular-indicators';
import { GridOptions } from 'src/app/shared/classes/list/grid-options';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { PagerModule } from '@progress/kendo-angular-pager';


@Component({
  selector: 'bizz-collection-modal',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    GridModule,
    PagerModule,
    TextAreaModule,
    TranslatePipe,
    SafePipe,
    FormsModule,
    CollectionButtonComponent,
    SkeletonModule,
    SharedModule,
    InputsModule,
    LabelModule
  ],
  templateUrl: './collection-modal.component.html',
  styleUrls: ['./collection-modal.component.scss'],
})

/**
 * A Generic Dialog Modal which shows a title, description
 * and optionally a confirmation input.
 * @returns the button that was pressed.
 */
export class CollectionModalComponent implements OnInit {
  protected dataItems: Array<TableViewItemInListDto>;
  protected filteredDataItems: Array<TableViewItemInListDto>;

  public isLoading = true;
  public search: Subject<string> = new Subject<string>();
  private destroy: Subject<void> = new Subject<void>();

  public currentPageInternal: number = 1;

  /**
   * The total number of items in the grid.
   * @required
   */
  @Input({ required: true }) public totalItems: number;

  /**
   * Page sizes to show in the pager.
   * Defaults to 1, 2, 5, 10, 20, 50 and 200
   */
  @Input() public pageSizes = [1, 2, 5, 10, 20, 50, 200];
  /**
   * Items showed a page.
   */
  @Input() public pageSize: number = 12;
  /**
    * The grid options.
    * Can be created from GridOptionsDto
    * Holds information about the column options, pagesize, skip, etc.
    * @required
    **/

  @Input({ required: true }) public gridOptions: GridOptions;

  public constructor(public dialogRef: DialogRef<TableViewItemInListDto>,
    @Inject(DIALOG_DATA) public model: Array<TableViewItemInListDto>, private collectionSchedulerApiServiceService: CollectionSchedulerApiService) {
    this.dataItems = this.filteredDataItems = model;
    this.totalItems = this.dataItems.length;
  }

  public ngOnInit(): void {
    this.subscribeToSearch();

  }

  /**
  * Subscribes to the search subject and updates the search property of the workspaces, then calls the API to search for workspaces
  * @private
  */
  private subscribeToSearch(): void {
    this.search.pipe(debounceTime(500), takeUntil(this.destroy)).subscribe({
      next: (search) => {
        this.filteredDataItems = this.dataItems.filter((item) => item.ViewName.toLowerCase().includes(search.toLowerCase()));
      },
    });
  }

  public onPageChange(event: PageChangeEvent): void {
    this.currentPageInternal = event.skip / event.take + 1;
    this.pageSize = event.take;
    this.filteredDataItems = this.dataItems.slice(event.skip, event.skip + event.take);
  }


}
