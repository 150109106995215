import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { asyncScheduler, catchError, exhaustMap, filter, finalize, map, Observable, of, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { checkUrlForAiProxy, checkUrlForProxy } from '../../functions/check-url-for-proxy';
import { Dialog } from '@angular/cdk/dialog';
import {
  ElectronicSignatureDialogComponent,
  ElectronicSignatureHeader
} from '../../../shared/components/electronic-signature-dialog/electronic-signature-dialog.component';
import { AlertService } from '../../../features/bizzmine/alerts/alert.service';
import { DialogModalComponent } from '../../../shared/components/modals/dialog-modal/dialog-modal.component';
import { DialogModalButtons } from '../../../shared/enums/dialog-modal-buttons';
import { DialogModalModelMode } from '../../../shared/components/modals/dialog-modal/dialog-modal-model';
import { TranslationService } from '../../services/translation/translation.service';
import { formsActions } from '../../../store/features/forms/forms-actions';
import { Store } from '@ngrx/store';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  public fileMap = [];

  private alertService = inject(AlertService);
  private dialog = inject(Dialog);
  private translationService = inject(TranslationService);
  private store$ = inject(Store);

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let req: HttpRequest<unknown>;

    if (checkUrlForProxy(request.url)) {
      if (checkUrlForAiProxy(request.url)) {
        req = request.clone({ url: `${environment.apiAiUri}/${request.url}` });
      } else {
        req = request.clone({ url: `${environment.apiUri}/${request.url}` });
      }
    } else {
      req = request.clone();
    }


    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.store$.dispatch(formsActions.failedFetch({}));
        switch (error.status) {
          case 0:
            if (this.isValidationResult(error)) {
              break;
            }
            this.alertService.setAlert({
              type: 'error',
              content: [error.message],
              title: this.translationService.translate('NoServerConnection'),
              icon: 'triangle-exclamation',
              dismissable: true,
              timed: false
            });
            // Stop the request
            return of();
          case 400:
            if (this.isValidationResult(error)) {
              break;
            }
            // Bad Request => should be handled on a per request basis
            this.alertService.setAlert({
              type: 'error',
              content: [error.message],
              title: this.translationService.translate('BadRequest'),
              icon: 'triangle-exclamation',
              dismissable: true,
              timed: false
            });
            break;
          case 401:
            // Unauthorized
            break;
          case 403:
            // Forbidden
            break;
          case 404:
            // Not Found
            break;
          case 405:
            // Method Not Allowed
            break;
          case 409:
            this.dialog.open(DialogModalComponent, {
              data: {
                mode: DialogModalModelMode.simple,
                title: 'FileInProcess',
                message: 'FileInProcessBody',
                confirmationtext: '',
                buttons: DialogModalButtons.Ok,
                showCancelButton: false
              }
            });
            break;
          case 410:
            this.dialog.open(DialogModalComponent, {
              data: {
                mode: DialogModalModelMode.simple,
                title: 'FileInProcess',
                message: 'FileAlreadyCheckedOut',
                confirmationtext: '',
                buttons: DialogModalButtons.Ok,
                showCancelButton: false
              }
            });
            break;
          case 412:
            // Precondition Failed
            // Electronic Signature required
            return this.handleSignature(req, next, error);
          case 429:
            break;
          case 500:
            // Internal Server error
            this.alertService.setAlert({
              type: 'error',
              content: [error.message],
              title: this.translationService.translate('BadRequest'),
              icon: 'triangle-exclamation',
              dismissable: true,
              timed: false
            });
            break;
          case 504:
            // Gateway Timeout
            break;
        }

        return new Observable<HttpEvent<unknown>>((subscriber) => {
          subscriber.error(error);
        });
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public isIE(): RegExpMatchArray {
    return <RegExpMatchArray>window.navigator.userAgent.match(/(MSIE|Trident)/);
  }

  private isValidationResult(response: HttpErrorResponse | undefined): boolean {
    return (response?.error['IsValid'] == false || response?.error['isValid'] == false) && response?.error?.Errors != undefined && Array.isArray(response?.error?.Errors) && response?.error?.Errors?.length > 0;
  }

  private handleSignature(request: HttpRequest<unknown>, next: HttpHandler, error: HttpErrorResponse, previousDialogRef: ElectronicSignatureDialogComponent | null = null): Observable<HttpEvent<any>> {
    const ref = this.dialog.open<Array<ElectronicSignatureHeader>>(ElectronicSignatureDialogComponent, {
      data: {
        request: request,
        next: next,
        error: error
      }
    });
    const electronicSignatureDialogComponent = (ref.componentInstance as ElectronicSignatureDialogComponent);
    return electronicSignatureDialogComponent.newRequest$.pipe(
      tap(() => {
        if (previousDialogRef) {
          asyncScheduler.schedule(() => {
            previousDialogRef.close();
          });
        }
      }),
      filter(httpRequest => httpRequest != null),
      map(httpRequest => httpRequest as HttpRequest<unknown>),
      exhaustMap(result => {
        return next.handle(result)
          .pipe(
            catchError((newError: HttpErrorResponse) => {
              return this.handleSignature(request, next, newError, electronicSignatureDialogComponent);
            }),
            finalize(() => {
                asyncScheduler.schedule(() => {
                  electronicSignatureDialogComponent.isLoading = false;
                  electronicSignatureDialogComponent.close();
                });
              }
            ));
      }));
  }
}
