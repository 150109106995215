<div class="modal-container modal-md !max-w-[768px]">
    <div class="modal-close-container">
        <bizz-icon iconName="xmark" (click)="dialogRef.close(false)"></bizz-icon>
    </div>
    <div class="modal-body overflow-y-scroll">
        <div class="modal-header"><h1>{{"ResetPassword" | translate}}</h1></div>
        <hr>

        <div class="flex w-full max-w-full pt-5">
            @if (combinedMessage.length > 0){
                <bizz-alert type="error" [message]="combinedMessage"></bizz-alert>
            }
        </div>
        <!--TODO: GL/BV/Geert Backend need to change to be able to have errors per input field (see mockups rené)-->
        <div class="flex flex-col gap-4 pt-5">
            <span class="flex w-full flex-col items-center gap-2 sm:justify-between md:flex-row">
                <label class="w-full text-sm md:w-2/5">{{"OldPassword" | translate}}</label>
                <div class="items-center w-full relative flex">
                    <input class="w-full pl-8 pr-8" [ngClass]="{'border-2 border-danger' : combinedMessage.length > 0}" [(ngModel)]="resetPasswordDto.OldPassword" [type]="oldPasswordVisisble ? 'text' : 'password'" autocomplete="off">
                    <bizz-icon iconName="lock" class="absolute left-2 h-4 w-4"></bizz-icon>
                    <bizz-icon (click)="oldPasswordVisisble = !oldPasswordVisisble" iconName="eye" class="absolute right-2 h-4 w-4"></bizz-icon>
                </div>
            </span>
            <span class="flex w-full flex-col items-center gap-2 sm:justify-between md:flex-row">
                <label class="w-full text-sm md:w-2/5">{{"NewPassword" | translate}}</label>
                <div class="items-center w-full relative flex">
                    <input class="w-full pl-8 pr-8" [ngClass]="{'border-2 border-danger' : combinedMessage.length > 0}" [(ngModel)]="resetPasswordDto.NewPassword" [type]="newPasswordVisible ? 'text' : 'password'" autocomplete="off">
                    <bizz-icon iconName="lock" class="absolute left-2 h-4 w-4"></bizz-icon>
                    <bizz-icon (click)="newPasswordVisible = !newPasswordVisible" iconName="eye" class="absolute right-2 h-4 w-4"></bizz-icon>
                </div>
            </span>
            <span class="flex w-full flex-col items-center gap-2 sm:justify-between md:flex-row">
                <label class="w-full text-sm md:w-2/5">{{"ConfirmNewPassword" | translate}}</label>
                <div class="items-center w-full relative flex">
                    <input class="w-full pl-8 pr-8" [ngClass]="{'border-2 border-danger' : combinedMessage.length > 0}" [(ngModel)]="resetPasswordDto.NewPasswordConfirmed" [type]="confirmPasswordVisisble ? 'text' : 'password'" autocomplete="off">
                    <bizz-icon iconName="lock" class="absolute left-2 h-4 w-4"></bizz-icon>
                    <bizz-icon (click)="confirmPasswordVisisble = !confirmPasswordVisisble" iconName="eye" class="absolute right-2 h-4 w-4"></bizz-icon>
                </div>
            </span>
        </div>
    </div>
    <div class="modal-buttons">
        <button class="save" (click)="setNewPassword(resetPasswordDto)">{{"ResetPassword" | translate}}</button>
    </div>
</div>