import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Service holding the state of the global loader and providing methods to change it
 */
@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get isLoading(): boolean {
    return this._isLoading.getValue();
  }

  public set isLoading(value) {
    this._isLoading.next(value);
  }

  public getObservable(): Observable<boolean> {
    return this._isLoading.asObservable();
  }
}
