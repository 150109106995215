import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, Observable } from 'rxjs';
import { TaskPopUpListDto } from '../../../../models/ts/task-pop-up-list-dto.model';
import { FlowStatusTaskDto } from '../../../../models/ts/flow-status-task-dto.model';
import { GridDataDto } from '../../../../models/ts/grid-data-dto.model';
import { TasksSearchDto } from '../../../../models/ts/tasks-search-dto.model';
import { TasksDto } from '../../../../models/ts/tasks-dto.model';
import { GridSearchDto } from '../../../../models/ts/grid-search-dto.model';
import { GridSearchFilterSearchDto } from '../../../../models/ts/grid-search-filter-search-dto.model';
import { ForwardTaskDto } from '../../../../models/ts/forward-task-dto.model';
import { FilterItemType } from 'src/models/ts/filter-item-type.model';
import { GridFilterDto } from 'src/models/ts/grid-filter-dto.model';
import { TaskInfoDto } from 'src/models/ts/task-info-dto.model';

@Injectable({
  providedIn: 'root'
})
export class TaskApiService {
  public FilterItemType: FilterItemType
  public constructor(private http: HttpClient) {

  }

  public getPopupTasks(search: string): Observable<TaskPopUpListDto> {
    return this.http.get<TaskPopUpListDto>('api/task/popup', {params: {'search': search}});
  }

  public forwardTask(intent: {
    task: ForwardTaskDto
  }): Observable<void> {
    return this.http.post<void>(`api/tasks/${intent.task.TasksID}/forward`, intent.task);
  }

  public getTasksFiltered(search: TasksSearchDto): Observable<GridDataDto> {
    return this.http.post<GridDataDto>('api/tasks/search/false', search);
  }

  public getTasksListFiltered(request: {
    widgetId: number,
    filter: Partial<GridSearchFilterSearchDto>
  }): Observable<GridDataDto> {
    return this.http.post<GridDataDto>(`api/tasklist/${request.widgetId}/search`, request.filter);
  }
  /*
  * Retrieve the FlowStatusTaskDto (Open steps) of the instance
  * */
  public getInstanceOpenSteps(collectionsID: number, instancesID: number, versionsID: number): Observable<FlowStatusTaskDto[]> {
    return this.http.get<FlowStatusTaskDto[]>(`api/collectionflow/collection/${collectionsID}/instance/${instancesID}/version/${versionsID}/pendingsteps`);
  }

  public getTaskOptions(everyone: boolean): Observable<TasksDto> {
    return this.http.get<TasksDto>(`api/tasks/${everyone}`);
  }

  public getTasks(search: Partial<TasksSearchDto>): Observable<GridDataDto> {
    return this.http.post<GridDataDto>('api/tasks/search/false', search);
  }

  public getRevisionTask(taskId: number): Observable<TaskPopUpListDto> {
    return this.http.get<TaskPopUpListDto>(`api/revisiontask/${taskId}`);
  }

  public getTasksSearchFilter(): Observable<GridFilterDto> {
    return this.http.get<GridFilterDto>(`api/filter/${FilterItemType.Tasks}`);
  }

  public getTaskInfo(tasksID: number): Observable<TaskInfoDto> {
    return this.http.get<TaskInfoDto>(`api/task/${tasksID}/gettaskinfo`);
  }
}
