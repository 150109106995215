import { ReminderPopupDto } from '../../../../models/ts/reminder-popup-dto.model';
import { NotificationSearchDto } from '../../../../models/ts/notification-search-dto.model';
import { ReminderSearchDto } from '../../../../models/ts/reminder-search-dto.model';
import { ReadFilterType } from '../../../../models/ts/read-filter-type.model';

// TODO: add search once API supports it
export type HeaderRemindersState = {
  reminders: ReminderPopupDto[],
  count: number,
  loading: boolean,
  search: string
}

export const initialHeaderRemindersStoreState: HeaderRemindersState = {
  reminders: [],
  count: 0,
  loading: false,
  search: ''
};

