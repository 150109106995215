import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReportDto } from 'src/models/ts/report-dto.model';

@Injectable({
  providedIn: 'root'
})
export class ReportApiService {

  public constructor(private http: HttpClient) { }

  public getDetails(reportId: number): Observable<ReportDto> {
    return this.http.get<ReportDto>(`api/report/${reportId}/detail`);
  }

  public exportListReportPdf(reportId: number, listId: number, folderId:number,  details: ReportDto): Observable<ArrayBuffer>{
    return this.http.post(`api/report/${reportId}/list/${listId}/folder/${folderId}/pdf`, details, {responseType: 'arraybuffer'});
  }
  public exportReportPdf(reportId: number, instanceId: number, versionId: number,  details: ReportDto): Observable<ArrayBuffer>{
    return this.http.post(`api/report/${reportId}/instance/${instanceId}/version/${versionId}/pdf`, details, {responseType: 'arraybuffer'});
  }


}
