<div class="flex h-full flex-col gap-4 bg-body p-4">
    <div class="flex h-full flex-col rounded-lg bg-widget p-2">
        @if (searchedNotificationDto) {
            <div class="flex shrink-0 flex-wrap items-center justify-between gap-1 pb-1">
                <div class="my-1 truncate font-title text-base font-bold text-widget-header sm:m-2">
                    {{ "Notifications" | translate }}
                </div>
                <div class="flex flex-grow items-center justify-end gap-2 py-2 sm:w-96">
                    <kendo-dropdownlist class="h-full w-28 sm:w-48"
                                        [data]="searchedNotificationDto.ReadTypes"
                                        textField="Text"
                                        valueField="Value"
                                        [value]="readType"
                                        [valuePrimitive]="true"
                                        (valueChange)="onReadTypeChanged($event)">
                        <ng-template kendoDropDownListItemTemplate let-type>
                            <span>{{ type.Text }}</span>
                        </ng-template>
                    </kendo-dropdownlist>
                    <bizz-search [searchTerm]="searchTerm" (searchTermChange)="searchTermChanged($event)">
                    </bizz-search>
                </div>
            </div>
        }

        @if (gridOptions && notifications) {
            <bizz-grid
                    class="max-h-[calc(100%_-_40px)]"
                    [currentPage]="currentPage"
                    [totalItems]="totalRecords"
                    [gridOptions]="gridOptions"
                    [loading]="isLoading"
                    [gridData]="notifications.Data"
                    (pageChange)="onPageChange($event)"
                    (cellAction)="onCellAction($event)">
            </bizz-grid>
        }
    </div>
</div>
