import { AfterViewInit, Component, HostListener, Input, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafePipe } from 'safe-pipe';
import { MenuAction } from '../../collection-list-widget/classes/menu.action';
import { ContextMenuItem } from '../../collection-list-widget/classes/context-menu.item';
import { ContextMenuItemService } from '../../collection-list-widget/services/context-menu-item.service';
import { Store } from '@ngrx/store';
import { workspaceSidebarFeature } from '../../../../../store/features/workspace-sidebar/workspace-sidebar-feature';
import {
  ContextMenuComponent as KendoContextMenuComponent,
  ContextMenuModule,
  ContextMenuSelectEvent
} from '@progress/kendo-angular-menu';
import {
  ActionSheetComponent as KendoActionSheetComponent,
  ActionSheetItemClickEvent,
  ActionSheetModule
} from '@progress/kendo-angular-navigation';
import { Subject } from 'rxjs';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { CollectionListDataInstance } from '../../../../../shared/interfaces/collection-list-data-instance';
import { GridService } from '../../collection-list-widget/services/grid/grid.service';
import * as Sentry from '@sentry/angular';
import { GridContextPermissions } from '../../collection-list-widget/classes/grid-context-permissions';
import { Dialog } from '@angular/cdk/dialog';
import { EditOnlineService } from '../../../../../core/services/file-upload/edit-online.service';

/**
 * kendo contextmenu containing all actions for an instance.
 */
@Component({
  selector: 'bizz-archive-context-menu',
  standalone: true,
  imports: [CommonModule, ContextMenuModule, IconComponent, SafePipe, ActionSheetModule],
  templateUrl: '../context-menu.component.html',
  styleUrls: ['../context-menu.component.scss'],
})
@Sentry.TraceClass({ name: 'ArchiveContextMenuComponent' })
export class ArchiveContextMenuComponent implements AfterViewInit, OnDestroy {
  @ViewChild('menu', { static: false }) public menu!: KendoContextMenuComponent;

  @ViewChild('actionsheet', { static: true }) public actionsheet!: KendoActionSheetComponent;

  @Input() public dataItem: CollectionListDataInstance | any;
  @Input() public permissions: GridContextPermissions | undefined;
  @Input() public anchor: any;
  @Input() public useSeparators: boolean;

  public menuItems: Partial<ContextMenuItem>[] = [];
  private workspaceIdSignal = this.store$.selectSignal(workspaceSidebarFeature.selectID);
  public isMobileView = false;

  /**
   * Subject used to unsubscribe from all subscriptions when the component is destroyed.
   */
  private destroy: Subject<void> = new Subject<void>();

  public constructor(private gridService: GridService,
    private contextMenuItemService: ContextMenuItemService,
    private store$: Store,
    private dialog: Dialog,
    private editOnlineService: EditOnlineService) {
  }

  public ngAfterViewInit(): void {
    this.isMobileView = window.innerWidth <= 640;
  }

  /**
   * Listen to window resizing to resize the last grid column.
   */
  @HostListener('window:resize')
  public onResize(): void {
    //640px is the tailwindcss sm breakpoint (maybe make a class for this)
    this.isMobileView = window.innerWidth <= 640;
  }

  @Sentry.TraceMethod({ name: 'open' })
  public open(): void {
    if (!this.dataItem || !this.permissions || !this.anchor) {
      throw new Error('Missing data for context menu');
    }

    this.menuItems = this.contextMenuItemService.getMenuItems(this.permissions, this.isMobileView, this.useSeparators);

    if (this.isMobileView) {
      this.actionsheet.toggle(true);
    } else this.menu.show(this.anchor);
  }

  @Sentry.TraceMethod({ name: 'close' })
  public close(): void {
    return;
  }

  public onItemSelected($event: ContextMenuSelectEvent | ActionSheetItemClickEvent): void {
    const item = $event.item as Partial<ContextMenuItem>;
    if (!item || typeof item.action === 'undefined')
      return;
    this.handleMenuAction(item.action);
  }

  private handleMenuAction(action: MenuAction): void {
    switch (action) {
      case MenuAction.ConsultRecord:
        this.gridService.readInstance(this.dataItem.CollectionsID, this.dataItem.ID, this.dataItem.VersionsID, this.dataItem.GuidChecksum, this.dataItem.DraftsID, this.dataItem.OriginalFoldersID, undefined, undefined, true);
        break;
      case MenuAction.DownloadFile:
        this.gridService.downloadArchiveFile(this.dataItem);
        break;
      case MenuAction.FlowStatus:
        this.gridService.openFlowStatusPage(this.dataItem.CollectionsID, this.dataItem.ID, this.dataItem.VersionsID,  this.dataItem.MethodType ?? this.dataItem.DocumentFlow, this.workspaceIdSignal(), false, true);
        break;
      case MenuAction.Monitor:

        this.gridService.openMonitorModal(this.dataItem.CollectionsID, this.dataItem.ID, this.dataItem.VersionsID);
        break;
      case MenuAction.FileHistory:
        this.gridService.openFileHistoryModal(this.dataItem);
        break;
      case MenuAction.ViewOnline:
        this.editOnlineService.openViewOnlineWithoutDraftValidation(this.dataItem);
        break;
      default:
        throw new Error('Unhandled context menu action');
    }
  }

  @Sentry.TraceMethod({ name: 'ngOnDestroy' })
  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.unsubscribe();
  }
}

