import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Subject } from 'rxjs';
import { TranslatePipe } from '../../../../../pipes/translate/translate.pipe';
import { FormsModule } from '@angular/forms';
import { IconComponent } from '../../../../ui/icon/icon.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { UserSettings2faSetupModalData } from './user-settings-2fa-setup-modal-data';
import { UserSettingsIndexDto } from '../../../../../../../models/ts/user-settings-index-dto.model';
import { UserSettingsApiService } from '../../../../../../api/bizzmine/user-settings/user-settings-api.service';

@Component({
  selector: 'user-settings-2fa-setup-modal',
  templateUrl: './user-settings-2fa-setup-modal.component.html',
  styleUrls: ['./user-settings-2fa-setup-modal.component.scss'],
  standalone: true,
  imports: [TranslatePipe, FormsModule, IconComponent, CommonModule, NgOptimizedImage],
})
export class UserSettings2faSetupModalComponent implements OnInit, OnDestroy {

  // TODO: BV use formgroup
  public search: string;
  public index: UserSettingsIndexDto;
  public error: string[] = [];
  private destroy = new Subject<boolean>();
  public TwoFactorCode: string;
  public twoFaLink: string;
  public imageToShow: any;
  public isImageLoading: boolean;
  public twoFaCode: string;

  public constructor(public dialogRef: DialogRef<boolean>,
              @Inject(DIALOG_DATA) public data: UserSettings2faSetupModalData,
              private userSettingsApiService: UserSettingsApiService) {
    this.dialogRef.disableClose = true;
  }

  public ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  public ngOnInit(): void {
    this.index = this.data.index;
    this.twoFaLink = 'api/twofactor/qrcode?secret=' + this.index.TwoFactorSecret + '&name=' + this.data.name;
    this.getQrCode();
  }

  public createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  public getQrCode(): void {
    this.isImageLoading = true;
    this.userSettingsApiService.getQrcode(this.twoFaLink).subscribe(data => {
      this.createImageFromBlob(data);
      this.isImageLoading = false;
    }, error => {
      this.isImageLoading = false;
      console.log(error);
    });
  }

  public saveTwoFactor(): void {
    console.log(this.twoFaCode);
    this.userSettingsApiService.saveTwoFactor(this.index).subscribe({
      complete: () => {
        this.dialogRef.close(true);
      },
    });
  }

}
