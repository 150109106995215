import { proxyUrls } from '../constants/proxy-urls';

/**
 * Compares given url string's first segment with defined strings that require a proxy
 * Strings are defined in proxy-urls.ts
 * @param url
 */
export function checkUrlForProxy(url: string): boolean {
  return proxyUrls.includes(url.split('/')[0])
}

export function checkUrlForAiProxy(url: string): boolean {
  return url.split('/')[1] === 'ai';
}