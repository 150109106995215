import { Component } from '@angular/core';
import { AuthService } from '../../../../../../core/services/auth/auth.service';
import { HeaderPopupBase } from '../../classes/header-popup-base';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { PopupModule } from '@progress/kendo-angular-popup';
import { IconComponent } from '../../../icon/icon.component';
import { TranslatePipe } from '../../../../../pipes/translate/translate.pipe';
import { environment } from '../../../../../../../environments/environment';
import { UserSettingsApiService } from 'src/app/api/bizzmine/user-settings/user-settings-api.service';
import { UserSettingsDto } from 'src/models/ts/user-settings-dto.model';
import { Observable } from 'rxjs';
import { PwaService } from 'src/app/core/services/pwa/pwa.service';

@Component({
  selector: 'bizz-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    PopupModule,
    IconComponent,
    TranslatePipe,
  ],
})
export class HeaderUserComponent extends HeaderPopupBase {
  public userSettings: UserSettingsDto;

  constructor(private authService: AuthService, private userSettingsApiService: UserSettingsApiService, public pwaService: PwaService) {
    super();
    this.getUserSettings();
  }

  private getUserSettings(): void {
    this.userSettingsApiService.getUserSettings().subscribe(settings => {
      this.userSettings = settings;
    })
  }

  public logout(): void {
    this.authService.logoutViaIdentityServer();
  }

  public openHelpCenter(): void {
    window.open('https://portal.bizzmine.com/', '_blank');
  }

  public openSupport(): void {
    window.open("https://www.bizzmine.com/en/supportticket", "_blank");
  }

  protected readonly environment = environment;
}
