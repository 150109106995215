import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlowStatusTaskDto } from '../../../../../../../../../models/ts/flow-status-task-dto.model';
import { TranslatePipe } from '../../../../../../../../shared/pipes/translate/translate.pipe';
import { HostType } from 'src/models/ts/host-type.model';
import { OrganizationChartItemType } from 'src/models/ts/organization-chart-item-type.model';


@Component({
  selector: 'bizz-flow-status-tasks-grid-completed-by-column',
  standalone: true,
  imports: [CommonModule, TranslatePipe],
  templateUrl: './flow-status-tasks-grid-completed-by-column.component.html',
  styleUrls: ['./flow-status-tasks-grid-completed-by-column.component.scss'],
})
export class FlowStatusTasksGridCompletedByColumnComponent {

  @Input({ required: true }) public task: FlowStatusTaskDto;

  protected readonly HostType = HostType;
  protected readonly OrganizationChartItemType = OrganizationChartItemType;
}
