import { Injectable } from '@angular/core';

@Injectable()
export class CollectionsEffects {
 

  public constructor(

  ) {}
}
