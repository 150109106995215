import { NgModule } from '@angular/core';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { ToolBarModule, ToolBarToolComponent } from '@progress/kendo-angular-toolbar';
import { CanvasCustomToolbarComponent } from '../../canvas-custom-toolbar-component/canvas-custom-toolbar/canvas-custom-toolbar.component';

@NgModule({
  declarations: [CanvasCustomToolbarComponent],
  imports: [
    DropDownListModule,
    ToolBarModule
  ],

  exports: [CanvasCustomToolbarComponent, ToolBarToolComponent]
})
export class CanvasCustomToolbarModule {
}