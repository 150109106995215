@if(!isMobileView) {
<kendo-contextmenu [items]="menuItems" [alignToAnchor]="true"
    [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }" [popupAlign]="{ horizontal: 'left', vertical: 'top'}"
    [collision]="{ horizontal: 'flip', vertical: 'flip'}" (select)="onItemSelected($event)" [size]="'medium'"
    (popupClose)="close()" #menu>
    <ng-template kendoMenuItemTemplate let-item="item">
        @if (item.separator) {
        <hr class="w-full" />
        } @else {
        <span class="flex items-center">
            <bizz-icon class="mr-2 size-3 max-w-3" [iconName]="item.iconName"></bizz-icon>
            <p class="font-sans text-xs font-medium">{{ item.text }}</p>
        </span>
        }
    </ng-template>
</kendo-contextmenu>
}
<kendo-actionsheet #actionsheet (overlayClick)="actionsheet.toggle(false)" (itemClick)="onItemSelected($event); close()"
    [items]="menuItems" [animation]="false">
    <ng-template kendoActionSheetItemTemplate let-item>
        @if (item.separator) {
        <hr class="mx-2.5 my-1.5 w-full" />
        } @else {
        <span class="flex items-center px-3 py-1.5" [ngClass]="item.cssClass">
            <bizz-icon class="mr-4 size-4" [iconName]="item.iconName"></bizz-icon>
            <p class="p-0 font-sans text-base font-medium">{{ item.text }}</p>
        </span>
        }
    </ng-template>
</kendo-actionsheet>