import { Injectable } from '@angular/core';
import { saveAs } from '@progress/kendo-file-saver';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  public constructor() {
  }

  public static cleanupFileName(fileName: string | undefined | null): string {
    let result = fileName ?? '';
    while (result.includes('%20')){
      result = result.replace('%20', ' ');
    }
    return result;
  }
  /**
   * Starts the download of the given byte array.
   * @param {ArrayBuffer} byteArray
   * @param {string} fileName
   */
  public startDownload(byteArray: ArrayBuffer, fileName?: string): void {
    if (!byteArray || byteArray.byteLength === 0)
      return;
    if (fileName) {
      const name = DownloadService.cleanupFileName(fileName);
      saveAs(new Blob([byteArray]), name);
    } else {
      //Logic from R41 check if still works.
      //Download from azure blob url
      const enc = new TextDecoder();

      let url = enc.decode(byteArray);
      url = url.substring(1, url.length);
      url = url.substring(0, url.length - 1);

      const link = document.createElement('a');
      link.target = '_blank';
      link.href = url;
      link.setAttribute('download', '');
      link.click();
    }
  }
}



