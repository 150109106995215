import * as go from 'gojs';
import { Part } from 'gojs';
import { maxBy } from 'lodash';
import { environment } from 'src/environments/environment';
import { FlowDiagramIcons } from '../constants/flow-diagram-icons';
import { FlowDiagramTools } from '../constants/flow-diagram-tools';
import { EventEmitter, Inject, inject } from '@angular/core';
import { GroupResizeEvent, GroupResizingTool } from './group-resizing-tool';
import { PoolLayout } from './pool-layout';
import { FlowDiagramState } from '../interfaces/flow-diagram-state';
import { FlowStatusShapes } from '../constants/flow-status-shapes';
import { FLOW_DIAGRAM_CATEGORY_ICON_NAMES } from '../constants/flow-diagram-category-icon-names';
import { FLOW_DIAGRAM_TOOL_CATEGORIES } from '../constants/flow-diagram-tool-categories';
import { BehaviorSubject } from 'rxjs';
import { BizzFlowSwimlaneData } from '../interfaces/bizz-flow-swimlane-data';
import { BizzFlowLinkData } from '../interfaces/bizz-flow-link-data';
import { BizzFlowStepData } from '../interfaces/bizz-flow-step-data';
import { FlowDiagramColors } from '../interfaces/flow-diagram-colors';


const $ = go.GraphObject.make;
go.Diagram.licenseKey = environment.gojsLicenseKey;

/**
 * FlowDiagram class
 * Responsible for drawing a flow diagram with the goJS library
 */
export class FlowDiagram {
  /**
   * Callback event whenever a block is created
   */
  public onBlockCreated: EventEmitter<go.ObjectData>;

  public onLinkCreated: EventEmitter<go.ObjectData>;

  public onBlockMoved: EventEmitter<go.DraggingTool>;

  //ID of the canvas element
  //private diagramComponent: DiagramComponent;
  private diagram: go.Diagram;
  private config: FlowDiagramConfig;
  private currentTool: FlowDiagramTools | undefined;
  private isDragging = false;
  private templateMap = new go.Map<string, Part>();
  //private nodeDataList = new go.List<go.ObjectData>();

  /**
   * Mostly variables to calculate the position of the new block, lanes
   */
  private maxSwimlaneWidthSubject = new BehaviorSubject<number>(0);

  public get maxSwimlaneWidth(): number {
    return this.maxSwimlaneWidthSubject.getValue();
  }

  private set maxSwimlaneWidth(value: number) {
    this.maxSwimlaneWidthSubject.next(value);
  }

  public static readonly minSwimlaneWidth = 1000; //was not defined in R40
  public static readonly minSwimlaneHeight = 150; //Previously minbreadth from flowDiagramClasses.js
  private maxLocation: go.Point = new go.Point(10000, 10000);

  private readonly toolTipHoverDelay = 150; //in milliseconds
  private readonly gridSnapSize = new go.Size(10, 10);

  private static readonly BLOCK_WIDTH = 120;
  private static readonly BLOCK_HEIGHT = 50;
  private static readonly ZOOM_FACTOR = 1.15;
  private initialScale: number = 0;

  private flowDiagramColors: FlowDiagramColors;


  public constructor() {
    //Ta add rounded rectangles
    FlowStatusShapes.defineCustomRoundShapes();
  }

  //TODO: RV implement this logic to update the diagram
  /*public modelChange(changes: go.IncrementalData) {
    if (!changes)
      return;
    this.state = produce(this.state, draft => {
      // set skipsDiagramUpdate: true since GoJS already has this update
      // this way, we don't log an unneeded transaction in the Diagram's undoManager history
      draft.skipsDiagramUpdate = true;
      draft.diagramNodeData = DataSyncService.syncNodeData(changes, draft.diagramNodeData, this.diagram.model);
      draft.diagramLinkData = DataSyncService.syncLinkData(changes, draft.diagramLinkData, this.diagram.model);
      draft.diagramModelData = DataSyncService.syncModelData(changes, draft.diagramModelData);
      // If one of the modified nodes was the selected node used by the inspector, update the inspector selectedNodeData object
      const modifiedNodeDatas = changes.modifiedNodeData;
      if (modifiedNodeDatas && draft.selectedNodeData) {
        for (let i = 0; i < modifiedNodeDatas.length; i++) {
          const mn = modifiedNodeDatas[i];
          const nodeKeyProperty = this.diagramComponent.diagram.model.nodeKeyProperty as string;
          if (mn[nodeKeyProperty] === draft.selectedNodeData[nodeKeyProperty]) {
            draft.selectedNodeData = mn;
          }
        }
      }
    });
  }*/

  public zoomToFit(): void {
    this.diagram.zoomToFit();
    this.diagram.contentAlignment = go.Spot.None;
    this.diagram.allowVerticalScroll = false;
  }

  public zoomIn(): void {
    const zoomFactor = this.diagram.commandHandler.zoomFactor;
    this.diagram.scale = zoomFactor * this.diagram.scale;

    //Only allow vertical scroll if the diagram is bigger than the viewport
    this.diagram.allowVerticalScroll =
      this.diagram.documentBounds.height > this.diagram.viewportBounds.height;
  }

  public zoomOut(): void {
    const zoomFactor = this.diagram.commandHandler.zoomFactor;
    this.diagram.scale = this.diagram.scale * (2 - zoomFactor);

    //Only allow vertical scroll if the diagram is bigger than the viewport
    this.diagram.allowVerticalScroll =
      this.diagram.documentBounds.height > this.diagram.viewportBounds.height;
  }

  public setDiagramScale(scale: number): void {
    this.diagram.scale = Math.min(Math.max(scale, 0.05), 2);

    //Only allow vertical scroll if the diagram is bigger than the viewport
    this.diagram.allowVerticalScroll =
      this.diagram.documentBounds.height > this.diagram.viewportBounds.height;
  }


  public save(): go.Model {
    return structuredClone(this.diagram.model);
  }

  public load(state: FlowDiagramState): void {
    if (this.diagram == undefined) {
      throw new Error('FlowDiagram is not initialized!');
    }
    this.diagram.model = new go.GraphLinksModel({
      //IMPORTANT! to prevent bug related to the handle not releasing on resize,
      // linkKeyproperty must be set even if we don't use it.
      // https://forum.nwoods.com/t/graphlinksmodel-linkkeyproperty-must-not-be-an-empty-string-for-toincrementaldata-to-succeed/13612/6
      linkKeyProperty: 'id',
      nodeDataArray: state.nodeDataArray,
      linkDataArray: state.linkDataArray,
      linkFromPortIdProperty: state.linkFromPortIdProperty,
      linkToPortIdProperty: state.linkToPortIdProperty,
      modelData: state.modelData,
    });

    this.maxSwimlaneWidth = FlowDiagram.getMaxWidthOfNodeInSwimlane(this.diagram.model.nodeDataArray);
    this.setMaxLocationOnInit(new go.List(this.diagram.model.nodeDataArray));

  }

  public changeTool(tool: FlowDiagramTools | undefined): void {
    this.currentTool = tool;
  }

  public init(config: FlowDiagramConfig): void {
    if (config == undefined)
      throw 'Should provide a config model';

    this.config = config;
    this.flowDiagramColors = config.colors;
  }

  public initDiagram(): go.Diagram {

    const groupResizingTool = new GroupResizingTool(
      FlowDiagram.minSwimlaneHeight,
      this.maxSwimlaneWidthSubject.asObservable());
    const poolLayout = new PoolLayout(
      FlowDiagram.minSwimlaneHeight,
      this.maxSwimlaneWidthSubject.asObservable());

    groupResizingTool.onResize.subscribe((e: GroupResizeEvent) => {
      this.setMaxLocationOnResize(e.nodes, e.newRect.width);
    });
    this.diagram = $(go.Diagram, {
      // use a custom ResizingTool (along with a custom ResizeAdornment on each Group)
      resizingTool: groupResizingTool,
      // use a simple layout that ignores links to stack the top-level Groups on top of each other
      allowDelete: this.config.draft,
      allowLink: this.config.draft,
      allowRelink: this.config.draft,
      allowInsert: this.config.draft,
      allowDragOut: false,
      allowMove: !this.config.flowStatus,
      layout: poolLayout,
      allowCopy: false,
      allowVerticalScroll: false,
      hasHorizontalScrollbar: false,
      hasVerticalScrollbar: false,
      allowUndo: true,
      mouseDrop: () => {
        // when the selection is dropped in the diagram's background,
        // make sure the selected Parts no longer belong to any Group
        const ok = this.diagram.commandHandler.addTopLevelParts(this.diagram.selection, true);
        if (!ok)
          this.diagram.currentTool.doCancel();
      },

      // enable undo & redo
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'undoManager.isEnabled': true,
      initialAutoScale: go.Diagram.Uniform,
      initialContentAlignment: go.Spot.LeftCenter,
    });

    if (this.config.draft) {
      this.diagram.grid = $(go.Panel, 'Grid',  // a simple 10x10 grid
        //$(go.Shape, 'LineH', { stroke: 'lightgray', strokeWidth: 0.5 }),
        //$(go.Shape, 'LineV', { stroke: 'lightgray', strokeWidth: 0.5 }),
        { gridCellSize: new go.Size(10, 40) },
        $(go.Shape, 'LineH', {
          stroke: this.flowDiagramColors.backgroundDotColor,
          strokeDashArray: [3, 40],
          strokeWidth: 3,
          strokeCap: 'round',
          strokeJoin: 'round',
        }),
      );
    }


    this.initTemplateMap();
    this.initLinkTemplateMap();
    this.initSwimlanes();

    //various properties
    this.diagram.animationManager.isEnabled = false;
    this.diagram.toolManager.linkingTool.temporaryLink.routing = go.Link.AvoidsNodes;
    this.diagram.toolManager.hoverDelay = this.toolTipHoverDelay;

    this.diagram.toolManager.draggingTool.gridSnapCellSize = this.gridSnapSize;

    const draggingTool = this.diagram.toolManager.draggingTool;
    draggingTool.doActivate = (): void => {
      this.isDragging = true;
      this.diagram.currentCursor = 'grabbing';
      go.DraggingTool.prototype.doActivate.call(draggingTool);
    };
    draggingTool.doDeactivate = (): void => {
      this.onBlockMoved.emit(draggingTool);
      this.diagram.currentCursor = 'default';
      go.DraggingTool.prototype.doDeactivate.call(draggingTool);
      this.isDragging = false;
    };
    //Enable grid snapping
    this.diagram.toolManager.draggingTool.isGridSnapEnabled = false;
    this.diagram.toolManager.resizingTool.isGridSnapEnabled = false;
    this.initialScale = this.diagram.scale;
    this.diagram.commandHandler.zoomFactor = FlowDiagram.ZOOM_FACTOR;

    //Only allow vertical scroll if the diagram is bigger than the viewport
    this.diagram.allowVerticalScroll = this.diagram.documentBounds.height > this.diagram.viewportBounds.height;

    return this.diagram;
  }

  private initSwimlanes(): void {
    this.diagram.groupTemplate =
      $(go.Group, 'Horizontal',
        // because there's no Placeholder, and thus no Placeholder.padding property,
        // the locationSpot offset determines the effective padding inside the group/lane shape
        { layerName: 'Background', locationObjectName: 'SHAPE', locationSpot: new go.Spot(0, 0, 10, 10) },
        this.selectionAdornment(),
        this.resizeAdornment(),
        new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
        {
          movable: false, copyable: false, deletable: true,  // can't move or copy or delete lanes
          avoidable: false,
          //selectionObjectName: 'SHAPE',  // selecting a lane causes the body of the lane to be highlighted, not the label
          resizable: true, resizeObjectName: 'SHAPE',  // allow lanes to be resized, but the custom resizeAdornmentTemplate only permits one kind of resizing
          mouseDrop: this.onGroupMouseDropped.bind(this),
          click: this.onGroupClicked.bind(this),
        },
        // the lane header consisting of a Shape and a TextBlock
        $(go.Panel, 'Auto',
          {
            name: 'HEADER',
            angle: 270,  // maybe rotate the header to read sideways going up
            alignment: go.Spot.Center,
          },
          $(go.Shape,
            {
              name: 'HEADER_SHAPE', // needs to be ignored by the resize tool
              fill: this.flowDiagramColors.swimlaneHeader,
              stroke: this.flowDiagramColors.swimlaneHeader,
              figure: 'Rectangle',
              strokeWidth: 2,
              parameter1: 10,
              minSize: new go.Size(FlowDiagram.computeLaneSize(null, this.maxSwimlaneWidth, FlowDiagram.minSwimlaneHeight).height, 50),
            },
            new go.Binding('figure', '', (data) => {
              return this.getSwimlaneFigure(data, true);
            }),
            new go.Binding('desiredSize', 'size', (size: string) => {
              const swimlaneSize = go.Size.parse(size);
              return new go.Size(swimlaneSize.height, 50);
            })),
          $(go.TextBlock,  // the lane label
            { font: 'normal 600 11pt Noto Sans', stroke: this.flowDiagramColors.textColor },
            new go.Binding('text', 'name').makeTwoWay(),
            this.addTooltip(),
          ),
        ),  // end Horizontal Panel
        $(go.Panel, 'Auto',  // the lane consisting of a background Shape representing the subgraph
          $(go.Shape,
            {
              name: 'SHAPE',
              fill: 'transparent',
              figure: 'Rectangle',
              stroke: this.flowDiagramColors.swimlaneBorder,
              strokeWidth: 2,
              minSize: FlowDiagram.computeLaneSize(null, this.maxSwimlaneWidth, FlowDiagram.minSwimlaneHeight),
            },
            new go.Binding('figure', '', (data) => {
              return this.getSwimlaneFigure(data);
            }),
            new go.Binding('desiredSize', 'size', go.Size.parse).makeTwoWay(go.Size.stringify),
            new go.Binding('fill', 'color')),
        ),  // end Auto Panel
      );  // end Group
  }

  /**
   * Function that returns the figure type for swimlane depending on if it's a header, selection or normal
   * @param data
   * @param isHeader
   * @param isSelection
   * @private
   */
  private getSwimlaneFigure(data: BizzFlowSwimlaneData, isHeader = false, isSelection = false): string {
    if (isSelection) {
      if (data.index == 0) { //first
        return data.swimlaneTotalCount > 1 ? 'RoundedTopRectangle' : 'RoundedRectangle';
      } else if (data.index == data.swimlaneTotalCount - 1) { //last
        return 'RoundedBottomRectangle';
      } else return 'Rectangle'; //middle
    } else {
      if (data.index == 0) { //first
        return data.swimlaneTotalCount > 1 ? 'RoundedTopRightRectangle' :
          (isHeader ? 'RoundedTopRectangle' : 'RoundedRightRectangle');
      } else if (data.index == data.swimlaneTotalCount - 1) {  //last
        return isHeader ? 'RoundedTopLeftRectangle' : 'RoundedBottomRightRectangle';
      } else return 'Rectangle'; //middle
    }
  }

  /**
   * This function is called whenever a swimlane is clicked
   * Will add a block to the swimlane if a tool is selected
   * @param e
   * @param graphObject
   * @private
   */
  private onGroupClicked(e: go.InputEvent, graphObject: go.GraphObject): void {
    const group = graphObject as go.Group;
    if (group == null || group.diagram == null || this.currentTool == null)
      return;

    //Todo: RV Check for node default names and translations?
    let name = '';
    if (this.currentTool == FlowDiagramTools.AddStart)
      name = 'Start';
    else if (this.currentTool == FlowDiagramTools.AddStop)
      name = 'Stop';

    const category = FLOW_DIAGRAM_TOOL_CATEGORIES[this.currentTool];
    const node = this.createNodeData(
      group.data.key,
      e.documentPoint,
      category,
      name);
    group.diagram.startTransaction(`Add ${category} Block`);
    group.diagram.model.addNodeData(node);
    group.diagram.commitTransaction(`Add ${category} Block`);
    //add to data model
    this.onBlockCreated.emit(node);

    if (this.currentTool === FlowDiagramTools.addAssessmentBlock) {
      // Draw gateway
      const assessmentGateway = this.createNodeData(
        group.data.key,
        new go.Point(e.documentPoint.x + 150, e.documentPoint.y),
        'assessmentgateway',
        name);
      group.diagram.startTransaction(`Add ${category} Block`);
      group.diagram.model.addNodeData(assessmentGateway);
      group.diagram.commitTransaction(`Add ${category} Block`);
      this.onBlockCreated.emit(assessmentGateway);

      // Create link model
      const link: BizzFlowLinkData = {
        from: node['key'],
        fromPort: 'Out',
        to: assessmentGateway['key'],
        toPort: 'In',
        toType: 4,
        category: 'forward',
      };

      // Add link to go js model + draw
      group.diagram.startTransaction('Create link');
      (group.diagram.model as go.GraphLinksModel).addLinkData(link);
      group.diagram.commitTransaction('Create link');
      // Add extra info to the gojs model to map it later to our backend models
      this.onLinkCreated.emit(link);
    }

    this.select(node);
    this.changeTool(undefined);
  }

  /**
   * Function is called when a graphobject is dropped (dragged) on a swimlane
   * @param e
   * @param graphObject
   * @private
   */
  private onGroupMouseDropped(e: go.InputEvent, graphObject: go.GraphObject): void {  // dropping a copy of some Nodes and Links onto this Group adds them to this Group
    const group = graphObject as go.Group;
    if (group != null && group.diagram != null) {
      const ok = group.addMembers(group.diagram.selection, true);
      if (!ok)
        group.diagram.currentTool.doCancel();
    }
  }

  /**
   * Creates a node data object for a new node.
   * @param groupKey the key of the swimlane to which the new node will belong.
   * @param location the initial location for the new node.
   * @param category the category for the new node.
   * @param name the name for the new node. (optional)
   * @private
   * @return {go.ObjectData} the new node data.
   */
  //(ignore any it's the type given by gojs)
  //eslint-disable-next-line
  private createNodeData(groupKey: any, location: go.Point, category: string, name: string): go.ObjectData {
    return {
      name: name,
      group: groupKey,
      loc: go.Point.stringify(location),
      'category': category,
      'iconName': FLOW_DIAGRAM_CATEGORY_ICON_NAMES[category],
    };
  }

  private getNodeBlockStyle(): go.ObjectData[] {
    return [
      {
        zOrder: 2,
        isShadowed: true,
        shadowColor: this.flowDiagramColors.shadowColor,
        shadowOffset: new go.Point(0, 0),
        shadowBlur: 5,
      },
      // The Node.location comes from the "loc" property of the node data,
      // converted by the Point.parse static method.
      // If the Node.location is changed, it updates the "loc" property of the node data,
      // converting back using the Point.stringify static method.
      new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
      {
        // the Node.location is at the center of each node
        locationSpot: go.Spot.Center,
        mouseEnter: (e: MouseEvent, obj: go.Node): void => {
          this.showPorts(obj.part, true);
        },
        mouseLeave: (e: MouseEvent, obj: go.Node): void => {
          this.showPorts(obj.part, false);
        },
      },
    ];
  }

  private getStatusIcon(size = 12, alignment = go.Spot.Center): go.Shape {
    return $(go.Shape, 'Circle', {
      width: size, height: size,
      stroke: null,
      alignment: alignment,
    },
      new go.Binding('fill', 'status', (s: string): string => {
        switch (s) {
          case 'expiredTask':
            return this.flowDiagramColors.expiredTask;
          case 'openTask':
            return this.flowDiagramColors.openTask;
          case 'timedTask':
            return this.flowDiagramColors.timedTask;
          case 'cancelledTask':
            return this.flowDiagramColors.cancelledTask;
          case 'completedTask':
            return this.flowDiagramColors.completedTask;
          default:
            return 'transparent';
        }
        //return this.flowStatusColors[s] as string;
      }),
    );
  }

  private initCircleBlock(fillColor: string, borderColor: string): go.Node {
    return $(go.Node, 'Auto', this.getNodeBlockStyle(),
      $(go.Panel, 'Auto',
        $(go.Shape, 'Circle', {
          minSize: new go.Size(40, 40),
          fill: fillColor,
          stroke: borderColor,
          strokeWidth: 2,
        }, this.addTooltip()),
      ),
      this.getStatusIcon(),
      this.makeOutPort(false),
      this.getMaxLocationOfNodes(),
      this.selectionAdornment(),
    );
  }

  private initGateway(fillcolor: string = this.flowDiagramColors.gatewayBlockColor,
    strokeColor: string = this.flowDiagramColors.gatewayBorderColor): go.Node {
    return $(go.Node, 'Auto', this.getNodeBlockStyle(),
      $(go.Panel, 'Auto',
        $(go.Shape,
          {
            geometryString: FlowDiagramIcons['round-diamond'],
            minSize: new go.Size(50, 50),
            fill: fillcolor,
            stroke: strokeColor,
            strokeWidth: 2,
          }),
        this.addTooltip(),
      ),
      this.makeInPort(true),
      this.makeOutPort(true),
      this.makeOutbackPort(true),
      this.getMaxLocationOfNodes(),
      this.selectionAdornment(),
    );
  }


  private initNormalBlock(fillColor: string = this.flowDiagramColors.blockColor,
    strokeColor: string = this.flowDiagramColors.blockBorderColor,
    addOutBackPort = true): go.Node {
    const nodePorts = [this.makeInPort(false), this.makeOutPort(false)];
    if (addOutBackPort) {
      nodePorts.push(this.makeOutbackPort(false));
    }

    //templates - 2 types of blocks
    return $(go.Node, 'Auto', this.getNodeBlockStyle(),
      new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
      $(go.Shape, 'RoundedRectangle', {
        fill: fillColor,
        stroke: strokeColor,
        width: FlowDiagram.BLOCK_WIDTH,
        height: FlowDiagram.BLOCK_HEIGHT, strokeWidth: 2,
      }),
      nodePorts,
      // spot bepaalt op welke manier de tekst in de node komt te staan
      this.addTextLabel(),
      this.getStatusIcon(10, new go.Spot(0.068, 0.85, 0, 0)),
      this.addIcon(strokeColor),
      this.getMaxLocationOfNodes(),
      this.selectionAdornment(),
    );
  }

  private initForwardLink(): go.Link {
    //link template
    return $(go.Link,
      {
        fromSpot: go.Spot.RightSide,  // coming out from right side
        toSpot: go.Spot.LeftSide, // going into at left side
        toEndSegmentLength: 15,
        fromEndSegmentLength: 15,
        zOrder: -1,
      },
      this.selectionLinkAdornment(),

      { routing: go.Link.AvoidsNodes, corner: 5 }, //avoidsNodes = Orthogonal, but with added functionality of avoiding crossing over nodes
      { relinkableFrom: true, relinkableTo: true }, //can redrag links and change connections
      $(go.Shape, { strokeWidth: 2, stroke: this.flowDiagramColors.linkColor }),
      $(go.Shape, {
        strokeWidth: 1,
        stroke: this.flowDiagramColors.linkColor,
        toArrow: 'RoundedTriangle',
        fill: this.flowDiagramColors.linkColor,
      }));
  }

  private initBackwardLink(): go.Link {
    //link template
    return $(go.Link,
      {
        fromSpot: go.Spot.Bottom,  // coming out from right side
        toSpot: go.Spot.Bottom,
        toEndSegmentLength: 25,
        fromEndSegmentLength: 25,
        zOrder: -1,
      },
      this.selectionLinkAdornment(),
      { routing: go.Link.AvoidsNodes, corner: 6 }, //avoidsNodes = Orthogonal, but with added functionality of avoiding crossing over nodes
      { relinkableFrom: true, relinkableTo: true }, //can redrag links and change connections?
      $(go.Shape, { strokeWidth: 2, stroke: this.flowDiagramColors.backLinkColor }),
      $(go.Shape, {
        strokeWidth: 1.5,
        stroke: this.flowDiagramColors.backLinkColor,
        toArrow: 'RoundedTriangle',
        fill: this.flowDiagramColors.backLinkColor,
      }));
  }

  private initTemplateMap(): void {
    //block template map
    this.templateMap = new go.Map<string, Part>();
    this.templateMap.add('', this.initNormalBlock());
    this.templateMap.add('assessment', this.initNormalBlock(
      this.flowDiagramColors.assessmentBlockColor,
      this.flowDiagramColors.assessmentBlockBorderColor));
    this.templateMap.add('trainingappassessment', this.initNormalBlock(
      this.flowDiagramColors.assessmentBlockColor,
      this.flowDiagramColors.assessmentBlockBorderColor, false));
    this.templateMap.add('trainingappsubscribetoexam',
      this.initNormalBlock(
        this.flowDiagramColors.subscribeToExamBlockColor,
        this.flowDiagramColors.subscribeToExamBlockBorderColor, false));
    this.templateMap.add('trainingappexamination', this.initNormalBlock(
      this.flowDiagramColors.examinationBlockColor,
      this.flowDiagramColors.examinationBlockBorderColor, false));
    this.templateMap.add('trainingapppublishexam', this.initNormalBlock(
      this.flowDiagramColors.publicationBlockColor,
      this.flowDiagramColors.publicationBlockBorderColor, false));
    this.templateMap.add('publication', this.initNormalBlock(
      this.flowDiagramColors.publicationBlockColor,
      this.flowDiagramColors.publicationBlockBorderColor));
    this.templateMap.add('distribution', this.initNormalBlock(
      this.flowDiagramColors.distributionBlockColor,
      this.flowDiagramColors.distributionBlockBorderColor));

    this.templateMap.add('gateway', this.initGateway());
    this.templateMap.add('assessmentgateway', this.initGateway(
      this.flowDiagramColors.assessmentGatewayBlockColor,
      this.flowDiagramColors.assessmentBlockBorderColor));
    this.templateMap.add('start', this.initCircleBlock(
      this.flowDiagramColors.startBlockColor,
      this.flowDiagramColors.startBorderColor));
    this.templateMap.add('end', this.initCircleBlock(
      this.flowDiagramColors.endBlockColor,
      this.flowDiagramColors.endBorderColor,
    ));

    this.diagram.nodeTemplateMap = this.templateMap;
  }

  private initLinkTemplateMap(): void {
    const linkTemplateMap = new go.Map<string, go.Link>();
    linkTemplateMap.add('forward', this.initForwardLink());
    linkTemplateMap.add('backward', this.initBackwardLink());
    this.diagram.linkTemplateMap = linkTemplateMap;
  }

  public findNode(key: go.Key): go.ObjectData | null {
    return this.diagram.model.findNodeDataForKey(key);
  }

  private selectionAdornment(): { selectionAdornmentTemplate: go.Adornment } {
    return {
      selectionAdornmentTemplate:
        $(go.Adornment, 'Auto',
          {
            isShadowed: true,
            shadowColor: this.flowDiagramColors.selectedStep,
            shadowBlur: 5,
            shadowOffset: new go.Point(0, 0),
          },
          this.getSelectionAdornmentShape(),
          $(go.Placeholder),
        ),
    };
  }

  /**
   * Returns the correct shaped for the selection based on the category of the node
   * Gateways need special geometrystrings and the swimlanes need rounded corners,
   * start and stop steps are circles
   * @private
   */
  private getSelectionAdornmentShape(): go.Shape {
    return $(go.Shape,
      {
        fill: null,
        stroke: this.flowDiagramColors.selectedStep, strokeWidth: 2,
        parameter1: 10,  // corner size (default 10)
        //spot1 and spot2 define where the content of this shape is placed
        //we set it to use the full size (no margins)
        spot1: new go.Spot(0, 0, 0, 0), spot2: new go.Spot(1, 1, 0, 0),
      },
      new go.Binding('figure', '', (data: BizzFlowSwimlaneData) => {
        if (data.category === 'start' || data.category === 'end') {
          return 'Circle';
        } else if (data.category === 'gateway' || data.category === 'assessmentgateway') {
          return undefined; // will set geometrystring
        } else if (data.category === 'swimlane') {
          return this.getSwimlaneFigure(data, false, true);
        } else {
          return 'RoundedRectangle';
        }
      }),
      new go.Binding('geometryString', 'category', (category: string) => {
        if (category === 'gateway' || category === 'assessmentgateway') {
          return FlowDiagramIcons['round-diamond'];
        }
        return undefined;
      }),
    );
  }

  private resizeAdornment(): { resizeAdornmentTemplate: go.Adornment } {
    return {
      resizeAdornmentTemplate:  // specify what resize handles there are and how they look
        $(go.Adornment, 'Spot',
          $(go.Placeholder),  // takes size and position of adorned object
          $(go.Shape,   // right resize handle
            {
              geometryString: FlowDiagramIcons['round-diamond'],
              alignment: go.Spot.Right,
              alignmentFocus: new go.Spot(1, 0, -8.5, 0),
              cursor: 'col-resize',
              desiredSize: new go.Size(15, 15),
              fill: 'lightblue',
              stroke: this.flowDiagramColors.selectedStep,
              strokeWidth: 2,
            }),
          $(go.Shape,   // bottom resize handle
            {
              geometryString: FlowDiagramIcons['round-diamond'],
              alignment: go.Spot.Bottom,
              alignmentFocus: new go.Spot(0, 1, 0, -8.5),
              cursor: 'col-resize',
              desiredSize: new go.Size(15, 15),
              fill: 'lightblue',
              stroke: this.flowDiagramColors.selectedStep,
              strokeWidth: 2,
            }),
        ),
    };
  }

  /**
   * Returns the selection adornment for links
   * @private
   */
  private selectionLinkAdornment(): { selectionAdornmentTemplate: go.Adornment } {
    return {
      selectionAdornmentTemplate:
        $(go.Adornment, 'Auto',
          {
            isShadowed: true,
            shadowColor: this.flowDiagramColors.selectedStep,
            shadowBlur: 5,
            shadowOffset: new go.Point(0, 0),
          },
          $(go.Shape,
            {
              isPanelMain: true, stroke: this.flowDiagramColors.selectedStep, strokeWidth: 2,
            }),
          $(go.Shape,
            { toArrow: 'RoundedTriangle', fill: this.flowDiagramColors.selectedStep, stroke: null }),
          $(go.Placeholder),
        ),  // end Adornment
    };
  }

  // Define a function for creating a "port" that is normally transparent.
  // The "name" is used as the GraphObject.portId, the "spot" is used to control how links connect
  // and where the port is positioned on the node, and the boolean "output" and "input" arguments
  // control whether the user can draw links from or to the port.
  private makePort(name: string, spot: go.Spot,
    fSpot: go.Spot, tSpot: go.Spot,
    fromLinkable: boolean, toLinkable: boolean, toMax: number, fromMax: number): go.Shape {
    // the port is basically just a small circle that has a white stroke when it is made visible
    return $(go.Shape, 'Circle',
      {
        fill: 'transparent',
        stroke: null,  // this is changed to "white" in the showPorts function
        desiredSize: new go.Size(9, 9),
        alignment: spot, alignmentFocus: spot,  // align the port on the main Shape
        portId: name,  // declare this object to be a "port"
        fromSpot: fSpot, toSpot: tSpot,  // declare where links may connect at this port
        fromLinkable: fromLinkable, toLinkable: toLinkable,  // declare whether the user may draw links to/from here
        toMaxLinks: toMax, fromMaxLinks: fromMax, // declare how many links can be drawn to/from this port
        cursor: 'pointer',  // show a different cursor to indicate potential link point
      });
  }

  private makeInPort(isGateWay = false): go.Shape {
    return this.makePort('In', go.Spot.Left, go.Spot.Right, go.Spot.None,
      false, true,
      isGateWay ? Infinity : 1, isGateWay ? Infinity : 1);
  }

  private makeOutPort(isGateWay = false): go.Shape {
    return this.makePort('Out', go.Spot.Right, go.Spot.None, go.Spot.Left,
      true, false,
      isGateWay ? Infinity : 1,
      isGateWay ? Infinity : 1);
  }

  private makeOutbackPort(isGateWay = false): go.Shape {
    return this.makePort('OutBack', go.Spot.Bottom, go.Spot.Bottom, go.Spot.Bottom,
      true, false, isGateWay ? Infinity : 1, isGateWay ? Infinity : 1);
  }

  // Make all ports on a node visible when the mouse is over the node
  private showPorts(node: go.GraphObject | null, show: boolean): void {
    if (node instanceof go.Node) {
      const diagram = node.diagram;
      if (!diagram || diagram.isReadOnly || !diagram.allowLink) return;
      node.ports.each((port: go.GraphObject) => {
        if (port instanceof go.Shape) {
          port.stroke = (show ? this.flowDiagramColors.portColor : null);
          port.fill = (show ? this.flowDiagramColors.portColor : null);
        }
      });
    }
  }

  private addIcon(iconColor: string): go.Panel {

    const iconAlignment = new go.Spot(0.07, 0.25, 0, 0);
    //Icon is put in a viewbox
    return $(go.Panel, go.Panel.Viewbox,
      {
        alignment: iconAlignment,
        width: 14, height: 16,
      },
      new go.Binding('visible', 'iconName', (iconName: string) => {
        return !!iconName; //show icon only if there is an icon name
      }),
      $(go.Shape,
        {
          fill: iconColor, strokeWidth: 0, geometryStretch: go.GraphObject.Uniform,
          alignment: new go.Spot(0.5, 0.5, 0, 0),
        }
        , new go.Binding('geometryString', 'iconName', (iconName: string) => {
          if (FlowDiagramIcons[iconName])
            return go.Geometry.fillPath(FlowDiagramIcons[iconName]);
          else
            return '';
        })),
    );
  }

  private addTextLabel(): go.Panel {
    const defaultMargin = new go.Margin(6, 10, 6, 10);

    return $(go.Panel, 'Spot', { height: FlowDiagram.BLOCK_HEIGHT, width: FlowDiagram.BLOCK_WIDTH },
      $(go.TextBlock, {
        alignment: go.Spot.Center, textAlign: 'left', margin: defaultMargin,
        font: 'normal 600 8pt Noto Sans', stroke: this.flowDiagramColors.textColor, verticalAlignment: go.Spot.Center,
      }, new go.Binding('text', 'name'),
        new go.Binding('margin', '', //if no sourceprop is passed the full node is returned
          function (node: BizzFlowStepData) {
            return node.iconName || node.status ? new go.Margin(6, 10, 6, 30) : defaultMargin;
          }),
      ),
      this.addTooltip(),
    );
  }

  /**
   * Returns a tooltip for the node
   * @param targetTextProp - the property of the node that will be displayed in the tooltip
   * @private
   * @returns object with tooltip adornment.
   */
  private addTooltip(targetTextProp: string = 'text'): { toolTip: go.Adornment } {
    return {
      toolTip:   // define a tooltip for each node that displays the color as text
        $(go.Adornment, 'Auto',
          {
            isShadowed: true,
            shadowColor: 'rgba(0, 0, 0, .4)',
            shadowOffset: new go.Point(0, 3),
            shadowBlur: 3,
          },
          $(go.Shape, {
            name: 'Border',
            figure: 'RoundedRectangle',
            parameter1: 3, //rounding
            parameter2: 1,
            fill: this.flowDiagramColors.popupBackgroundColor,
            stroke: this.flowDiagramColors.popupBackgroundColor,
            spot1: new go.Spot(0, 0, 4, 6),
            spot2: new go.Spot(1, 1, -4, -4),
          }), $(go.TextBlock,
            {
              margin: new go.Margin(2, 4),
              text: 'verticalAlignment: Top',
              width: 200,
              font: 'normal 600 12pt Noto Sans',
              stroke: this.flowDiagramColors.popupTextColor,
              wrap: go.TextBlock.WrapFit,
            },
            new go.Binding(targetTextProp, 'name')),
          //show popup only if there's text
          new go.Binding('visible', 'name',
            function (name) {
              return !!name;
            }),
        ),
    };
  }

  private countGroups(): number {
    let result = 0;
    this.diagram.nodes.each((node: go.Node) => {
      if (node instanceof go.Group)
        result++;
    });
    return result;
  }

  /* private addSwimLane(name: string, dataModel: SwimlaneDto): go.ObjectData {
     let groupCount = this.countGroups();
     this.diagram.startTransaction('NewGroup');
     const node: BizzFlowSwimlaneData =
       { key: 'S-' + ++groupCount,
         name: name,
         isGroup: true,
         dataModel: dataModel,
         category: 'swimlane',
         size: `${this.maxSwimlaneWidth} ${this.minSwimlaneHeight}`,
       };
     this.diagram.model.addNodeData(node);
     this.diagram.commitTransaction('NewGroup');
     this.select(node);
     return node;
   }*/

  private setNodeProperty(node: go.ObjectData, property: string, value: go.ObjectData): void {
    this.diagram.model.setDataProperty(node, property, value);
  }

  private cancel(): void {
    this.diagram.currentTool.doCancel();
  }

  //TODO: RV implement
  /*private linkValidation(fromNode: go.ObjectData, fromPort: go.ObjectData,
                         toNode: go.ObjectData, toPort: go.ObjectData): boolean {
     if (fromPort.portId === 'OutBack' && toPort.portId === 'OutBack')
       return this.backLinkValidation(fromNode, fromPort, toNode, toPort);

     if (fromPort.portId === 'Out' && toPort.portId === 'In')
       return this.forwardLinkToPreviousStepValidation(fromNode, fromPort, toNode, toPort);

     if (fromPort.portId === 'Out') return toPort.portId === 'In';
     if (fromPort.portId === 'In') return toPort.portId === 'Out';
     return fromPort.portId === toPort.portId;
   }*/

  private makeLinkForwardLink(link: go.Link): void {
    (this.diagram.model as go.GraphLinksModel).setCategoryForLinkData(link, 'forward');
  }

  private makeLinkBackwardLink(link: go.Link): void {
    (this.diagram.model as go.GraphLinksModel).setCategoryForLinkData(link, 'backward');
  }

  // as it.value.data.key is of type any so is nodeKey
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getNodeForKey(nodeKey: any): go.Node | undefined {
    const it = this.diagram.nodes.iterator;
    while (it.next()) {
      if (it.value.data.key === nodeKey)
        return it.value;
    }
    return undefined;
  }

  // as it.value.data.key is of type any so is nodeKey
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private select(node: go.ObjectData | null): void {
    const it = this.diagram.nodes.iterator;
    while (it.next()) {
      if (it.value.data === node)
        this.diagram.select(it.value);
    }
  }

  // as it.value.data.key is of type any so is groupKey
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private addNodeToGroup(node: go.Node, groupKey: any): void {
    for (const it = this.diagram.nodes; it.next();) {
      const group = it.value;
      if (!(group instanceof go.Group))
        continue;
      if (group.data.key === groupKey) {
        const nodeItem = this.getNodeForKey(node.key);
        if (nodeItem != undefined) {
          const templateMap = new go.Map<string, go.Node>();
          templateMap.add('node', nodeItem);
          //TODO: RV Investigate if this works
          group.addMembers(templateMap.iteratorValues, true);
        }
      }
    }
  }

  /*
   * compute the minimum length needed to hold all the subgraphs
   * @param {go.Diagram} diagram
   * In Js was called computeMinSize
   */
  public static computeMinSwimLaneWidth(diagram: go.Diagram): number {
    let len = FlowDiagram.minSwimlaneWidth;
    for (const it = diagram.nodes; it.next();) {
      const group = it.value;
      if (!(group instanceof go.Group))
        continue;
      const size = diagram.computePartsBounds(group.memberParts);
      if (!size.isReal())
        continue;
      if (group.location.isReal())
        size.unionPoint(group.location);
      len = Math.max(len, size.width + 2 * group.locationSpot.offsetX);
    }
    return len;
  }

  /*
   * Get the minimum size for a particular Group,
   * when group is null, return the minimum size.
   * In js was called computeSize
   * @param {go.Group} group (usually the swimlane group)
   * @param {number} maxSwimlaneWidth (the maximum width of the swimlane)
   * @param {number} minSwimlaneHeight (the minimum height of the swimlane)
   */
  public static computeLaneSize(swimlaneGroup: go.GraphObject | null,
    maxSwimlaneWidth: number,
    minSwimlaneHeight: number): go.Size {
    if (swimlaneGroup instanceof go.Group && swimlaneGroup.diagram != null) {
      const size = swimlaneGroup.diagram.computePartsBounds(swimlaneGroup.memberParts);
      if (size.isReal()) {
        if (swimlaneGroup.location.isReal()) size.unionPoint(swimlaneGroup.location);
        return new go.Size(size.width + 2 * swimlaneGroup.locationSpot.offsetX, size.height + 2 * swimlaneGroup.locationSpot.offsetY);
      }
    }
    //TODO: RV investigate why 1000 is used
    return new go.Size(maxSwimlaneWidth < 1000 ? 1000 : maxSwimlaneWidth, minSwimlaneHeight);
  }

  /**
   * Calculates the maximum width of the swimlane.
   * @param nodes
   */
  public static getMaxWidthOfNodeInSwimlane(nodes: go.ObjectData[]): number {
    const stepWithMaxSize = maxBy(nodes, (node) => {
      if (!node['category'].startsWith('swimlane')) {
        return go.Point.parse(node['loc']).x;
      } else
        return 0;
    });
    if (stepWithMaxSize == undefined) {
      throw new Error('Step with max size not found');
    }

    //150 is step width?
    return go.Point.parse(stepWithMaxSize['loc']).x + 150;
  }

  /**
   * Calculates the maximum y position a node can be placed based on the swimlanes.
   * @param nodes
   */
  public static calculateMaxVerticalLocation(nodes: go.ObjectData[]): number {
    let maxLocation = 0;
    nodes.forEach((node) => {
      if (node['category'].startsWith('swimlane')) {
        const loc = go.Point.parse(node['loc']);
        const size = go.Point.parse(node['size']);
        //TODO: RV Investigate: I think this only works because x is always 0
        const sum = loc.x + size.y;
        maxLocation += sum;
      }
    });
    return maxLocation > FlowDiagram.BLOCK_HEIGHT ? (maxLocation - FlowDiagram.BLOCK_HEIGHT) : maxLocation;
  }

  /**
   * Sets the maxLocation for all nodes when the swimlane is resized.
   * @param nodes
   * @param maxSwimlaneWidth
   * @private
   */
  private setMaxLocationOnResize(nodes: go.List<go.Node>, maxSwimlaneWidth: number): void {
    this.maxSwimlaneWidth = maxSwimlaneWidth;
    this.maxLocation = new go.Point(this.maxSwimlaneWidth - (FlowDiagram.BLOCK_WIDTH / 2),
      FlowDiagram.calculateMaxVerticalLocation(this.diagram.model.nodeDataArray));
    this.setMaxLocationForEachNode(nodes, this.maxLocation);
  }

  private setMaxLocationOnInit(nodes: go.List<go.ObjectData>): void {
    const maxVerticalLocation = FlowDiagram.calculateMaxVerticalLocation(nodes.toArray());
    //TODO: RV Why magic nr 1000/900 => make constant
    this.maxLocation = new go.Point(this.maxSwimlaneWidth < 1000 ? 900 : this.maxSwimlaneWidth, maxVerticalLocation);

    if (this.templateMap != undefined && this.maxLocation != undefined) {
      const it = this.templateMap.iterator;
      while (it.next()) {
        it.value.maxLocation = this.maxLocation;
      }
    }
  }

  /**
   * Iterates over all nodes and map Parts and sets the maxLocation for
   * each node.
   * @param nodes
   * @param maxLocation
   * @private
   */
  private setMaxLocationForEachNode(nodes: go.List<go.Node>, maxLocation: go.Point): void {
    for (const it = nodes.iterator; it.next();) {
      const node = it.value;
      if (node instanceof go.Group)
        continue;
      node.maxLocation = maxLocation;
    }

    if (this.templateMap != undefined && this.maxLocation != undefined) {
      const it = this.templateMap.iterator;
      while (it.next()) {
        it.value.maxLocation = this.maxLocation;
      }
    }
  }

  /*private setMaxLocationOnAddSwimlane(nodeDataArray: go.List<go.Node>): void {
    const maxLoc = FlowDiagram.calculateMaxVerticalLocation(nodeDataArray);
    this.diagram.nodes = nodeDataArray.iterator;
    //TODO: RV Why magic nr 1850? => make constant
    this.maxLocation = new go.Point(1850, maxLoc);

    const nodes = this.diagram.nodes;
    this.setMaxLocationForEachNode(new go.List(nodes), this.maxLocation);
  }*/

  /**
   * Determines the max location a node can be moved to.
   * @private
   */
  private getMaxLocationOfNodes(): { maxLocation: go.Point, minLocation: go.Point } {
    return {
      maxLocation: this.maxLocation,
      minLocation: new go.Point(FlowDiagram.BLOCK_HEIGHT, FlowDiagram.BLOCK_HEIGHT),
    };
  }

}

export interface FlowDiagramConfig {
  draft: boolean;
  flowStatus: boolean;
  colors: FlowDiagramColors;
}