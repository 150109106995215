<bizz-modal [headerTemplate]="headerTemplateRef" [contentTemplate]="contentTemplateRef"></bizz-modal>
<ng-template #headerTemplateRef>
    <div class="modal-header">
        <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
{{ 'Info' | translate }}: {{data.auditLogInfo.EventTypeString}}</h1>
    </div>
    <hr class="my-5" />
</ng-template>
<ng-template #contentTemplateRef>
    <div class="forward-info">
        <h2 class="font-bold">{{'ForwardedTo'| translate}}: <span class="font-normal">{{data.auditLogItem.TaskForwardedTo}}</span></h2>
        <h2 class="font-bold">{{'ForwardedFrom'| translate}}: <span class="font-normal">{{data.auditLogItem.ExecutedBy}}</span></h2>
        <h2 class="font-bold">{{'ForwardReason'| translate}}:</h2>
        <div [innerHTML]="data.auditLogItem.ForwardedReason"></div>
    </div>
</ng-template>