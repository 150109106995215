import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { ThemeDto } from 'src/models/ts/theme-dto.model';
import { IconComponent } from "../../../../../ui/icon/icon.component";

@Component({
    selector: 'bizz-theme-preview-icon-full',
    standalone: true,
    templateUrl: './theme-preview-icon-full.component.html',
    styleUrl: './theme-preview-icon-full.component.scss',
    imports: [CommonModule, IconComponent]
})
export class ThemePreviewIconFullComponent implements AfterViewInit {
  @ViewChild('iconComponent', { static: false }) public iconComponent: ElementRef;
  @ViewChild('readOnlyIconComponent', { static: false }) public readOnlyIconComponent: ElementRef;
  
  public internalTheme: ThemeDto;

  private readonly previewThemeProperties = [
    "--header-bg",
    "--header-text",
    "--header-accent-text",
    "--workspace-selector-bg",
    "--workspace-selector-text",
    "--sidebar-bg",
    "--sidebar-header-text",
    "--body-bg",
    "--widget-bg",
    "--widget-header-text",
    "--widget-text",
  ];

  @Input({ required: true }) public set theme(theme: ThemeDto) {  
    this.internalTheme = theme;
    this.applyTheme(this.internalTheme);
  }
  @Input() public isReadOnly = false;
  @Input() public isSelected: boolean = false;
  @Output() public themeSelected: EventEmitter<ThemeDto> = new EventEmitter<ThemeDto>();

  public ngAfterViewInit(): void {
    this.applyTheme(this.internalTheme);
  }

  private applyTheme(theme: ThemeDto): void {
    if (theme?.BrandingV2) {
      const branding = theme.BrandingV2;
      const el = this.isReadOnly ? this.readOnlyIconComponent?.nativeElement : this.iconComponent?.nativeElement;
      if(!el) {
        return;
      }
      for (const [key, value] of Object.entries(branding)) {
        // Apply only the preview theme properties
        if(this.previewThemeProperties.includes(key) && value) 
          el.style.setProperty(key, value);
      }
    }
  }

  public onClick(): void {
    this.themeSelected.emit(this.internalTheme);
  }
}
