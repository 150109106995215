@if (data) {
    <div class="rounded-b bg-form shadow-md">
        <div class="h-2 w-full rounded-t bg-blue-500">
        </div>
        <div class="mx-4 my-2 flex items-center gap-2 rounded-t text-blue-500">
            <bizz-icon class="h-4 w-4" iconName="user-check"></bizz-icon>
            <span class="text-lg font-bold">{{ "TrainingAssessment" | translate }}</span>
        </div>
        <div class="mx-4 flex flex-col">
            <hr class="hidden sm:flex">
            <div class="my-2 flex flex-col gap-2 overflow-auto sm:my-4 sm:flex-row sm:gap-0">
                <kendo-grid [data]="data.Participants" [resizable]="true" class="min-w-full" #trainingAssessmentKendoGrid>
                    <kendo-grid-column [autoSize]="true">
                        <ng-template kendoGridHeaderTemplate let-dataItem>
                            <b>{{ "Participant" | translate }}</b>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <b class="text-xs">{{ dataItem.Name }}</b>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column [autoSize]="true">
                        <ng-template kendoGridHeaderTemplate let-dataItem>
                            <b>{{ "Present" | translate }}</b>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <input type="checkbox" (ngModelChange)="emitUpdate()" [disabled]="readonly" kendoCheckBox
                                   [(ngModel)]="dataItem.Present">
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column [autoSize]="true">
                        <ng-template kendoGridHeaderTemplate let-dataItem>
                            <b>{{ "CurrentGrade" | translate }}</b>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            @if (getScore(dataItem.OldUserSkillGrade, false); as skillGrade) {
                                <kendo-circulargauge
                                        [value]="skillGrade.Score"
                                        [scale]="{ max: 100 }"
                                        [colors]="colors"
                                        class="size-14">
                                    <kendo-arcgauge-scale [rangeSize]="5">
                                    <ng-template kendoCircularGaugeCenterTemplate let-value="value">
<span class="text-xs">
                                                                                        @if (dataItem.OldUserSkillGrade.Expiracy == null) {
                                                                                            {{ skillGrade.Score }}<span class="text-xxs">%</span>
                                                                                        } @else {
                                                                                            @switch (dataItem.OldUserSkillGrade.Expiracy.ExpiracyStatus) {
                                                                                                @case (ExpiracyStatus.NotExpired) {
                                                                                                    {{ skillGrade.Score }}<span class="text-xxs">%</span>
                                                                                                }
                                                                                                @case (ExpiracyStatus.AlmostExpired) {
                                                                                                    <bizz-icon
                                                                                                            class="size-3 text-yellow-500"
                                                                                                            iconName="circle-exclamation"></bizz-icon>
                                                                                                }
                                                                                                @case (ExpiracyStatus.Expired) {
                                                                                                    <bizz-icon
                                                                                                            class="size-3 text-red-500"
                                                                                                            iconName="xmark"></bizz-icon>
                                                                                                }
                                                                                                @case (ExpiracyStatus.ExpirationPrediction) {
                                                                                                    <bizz-icon
                                                                                                            class="size-3 text-red-500"
                                                                                                            iconName="xmark"></bizz-icon>
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                    </span>
                                    </ng-template>
                                    </kendo-arcgauge-scale>
                                </kendo-circulargauge>

                            }
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column [width]="300">
                        <ng-template kendoGridHeaderTemplate let-dataItem>
                            <b>{{ "SetSkillGradeFor" | translate }} '{{ data.Skill.Text }}'</b>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            @if (dataItem.Present) {
                                <div class="w-4/6">
                                    <bizz-form-trainingapp-assessment-slider class="w-4/6" [value]="dataItem.NewUserSkillGrade" (valueChanged)="onSkillChanged($event,dataItem)" [skillGrades]="data.SkillGrades"></bizz-form-trainingapp-assessment-slider>
                                </div>

                            } @else {
                                <div class="flex items-center gap-2 text-xs">
                                    <input type="checkbox" kendoCheckBox (ngModelChange)="emitUpdate()"
                                           [disabled]="readonly"
                                           [(ngModel)]="dataItem.AddToTrainingsQueue"> {{ "AddUserAgainToQueue" | translate }}.
                                </div>

                            }
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column [autoSize]="true">
                        <ng-template kendoGridHeaderTemplate let-dataItem>
                            <b>{{ "NewGrade" | translate }}</b>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            @if (dataItem.Present && getScore(dataItem.NewUserSkillGrade, true); as skillGrade) {

                                <kendo-circulargauge
                                        [value]="skillGrade.Score"
                                        [scale]="{ max: 100 }"
                                        [colors]="colors"
                                        class="size-14">
                                    <kendo-arcgauge-scale [rangeSize]="5">
                                    <ng-template kendoCircularGaugeCenterTemplate let-value="value">
                                                                                    <span class="text-xs">
                                                                                        @if (dataItem.OldUserSkillGrade.Expiracy == null) {
                                                                                            {{ skillGrade.Score }}<span class="text-xxs">%</span>
                                                                                        } @else {
                                                                                            @switch (dataItem.OldUserSkillGrade.Expiracy.ExpiracyStatus) {
                                                                                                @case (ExpiracyStatus.NotExpired) {
                                                                                                    {{ skillGrade.Score }}<span class="text-xxs">%</span>
                                                                                                }
                                                                                                @case (ExpiracyStatus.AlmostExpired) {
                                                                                                    <bizz-icon
                                                                                                            class="size-3 text-yellow-500"
                                                                                                            iconName="circle-exclamation"></bizz-icon>
                                                                                                }
                                                                                                @case (ExpiracyStatus.Expired) {
                                                                                                    <bizz-icon
                                                                                                            class="size-3 text-red-500"
                                                                                                            iconName="xmark"></bizz-icon>
                                                                                                }
                                                                                                @case (ExpiracyStatus.ExpirationPrediction) {
                                                                                                    <bizz-icon
                                                                                                            class="size-3 text-red-500"
                                                                                                            iconName="xmark"></bizz-icon>
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                    </span>
                                    </ng-template>
                                        </kendo-arcgauge-scale>
                                </kendo-circulargauge>

                            }
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column [width]="500">
                        <ng-template kendoGridHeaderTemplate let-dataItem>
                            <b>{{ "Remarks" | translate }}</b>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <textarea class="form-control w-full rounded border border-form-control bg-form-control bg-none text-sm text-widget" rows="1"
                                      (ngModelChange)="emitUpdate()"
                                      [(ngModel)]="dataItem.Remarks"
                                      [disabled]="!dataItem.Present || readonly"></textarea>
                        </ng-template>
                    </kendo-grid-column>

                </kendo-grid>
            </div>
        </div>
    </div>

}
