import { Component } from '@angular/core';
import { FormLandingPageComponent } from '../form-landing-page/form-landing-page.component';
import { formsActions } from '../../../../store/features/forms/forms-actions';

@Component({
  selector: 'bizz-task-landing-page',
  standalone: true,
  imports: [],
  templateUrl: './task-landing-page.component.html',
  styleUrl: './task-landing-page.component.scss'
})
export class TaskLandingPageComponent extends FormLandingPageComponent{
  public override routeApplied() : void {
    this.store$.dispatch(formsActions.getTaskForm({
     taskId: this.taskId!,
    }));
  }

}
