import { Pipe, PipeTransform } from '@angular/core';
import { DateFilterService } from '../../services/filter/date-filter-service';

@Pipe({
  name: 'time',
  standalone: true,
})
export class TimePipe implements PipeTransform {
  private _dateFilterService: DateFilterService;

  constructor(dateFilterService: DateFilterService) {
    this._dateFilterService = dateFilterService;
  }

  transform(value: string | null | undefined | unknown): string {
    if (!value) {
      return '';
    } else if (typeof value === 'string')
      return this._dateFilterService.convertTime(value);
    else {
      throw new Error('TimePipe: Invalid value type');
    }
  }
}
