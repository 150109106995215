import { filter, take } from 'rxjs';
import { selectFormViewDataSource } from '../../../../store/features/forms/forms-selectors';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BaseControlComponent } from './base-form-control.component';
import { CollectionFormExternalAccessDto } from '../../../../../models/ts/collection-form-external-access-dto';

@Component({
  template: '',
  imports: [],
  standalone: true
})
export abstract class LinkedBaseFormControlComponent<T extends AbstractControl> extends BaseControlComponent<T> {

  public isLookupAllowedExtForm = true;
  public isLookupAllowed = true;
  public isCreateRecordAllowedExtForm = true;
  @Input({}) public externalAccess: CollectionFormExternalAccessDto | undefined;

  public showCreate = true;
  public showLookup = true;
  public showNewItem = true;

  public override ngOnInit(): void {
    super.ngOnInit();
    this.formField$.pipe(filter(field => field != undefined && field.ViewDataSourcesID > 0), take(1)).subscribe(field => {
      if (field != undefined && field.ViewDataSourcesID > 0) {
        let viewDataSource = this.store$.selectSignal(selectFormViewDataSource(this.formId, field.ViewDataSourcesID))();

        this.externalAccess = this.externalUserService.externalAccess(this.formId, (field.ViewDataSourcesID ?? 0));
        this.isLookupAllowedExtForm = this.externalUserService.isLookupAllowedExtForm(this.formId, (field.ViewDataSourcesID ?? 0));
        this.isCreateRecordAllowedExtForm = this.externalUserService.isCreateRecordAllowedExtForm(this.formId, (field.ViewDataSourcesID ?? 0));

        // Move to linked form control and form list control? Not really base functionality
        if (viewDataSource !== undefined) {
          this.isLookupAllowed = !viewDataSource.IsLookupDisabled;
          this.showCreate = viewDataSource.ParentDataSourcesID == 0;

        } else throw new Error(`VDS ${field.ViewDataSourcesID} not found trying to set isLookupAllowed property`);

        this.showLookup = this.isLookupAllowedExtForm && this.isLookupAllowed;
        this.showNewItem = this.isLookupAllowedExtForm && this.isLookupAllowed && this.isCreateRecordAllowedExtForm;
      }
    });
  }
}
