import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReminderSearchDto } from '../../../../models/ts/reminder-search-dto.model';
import { GridDataDto } from '../../../../models/ts/grid-data-dto.model';
import { ReminderPopupListDto } from '../../../../models/ts/reminder-popup-list-dto.model';
import { ReminderDto } from '../../../../models/ts/reminder-dto.model';
import { ReminderGridOptionsDto } from 'src/models/ts/reminder-grid-options-dto.model';

@Injectable({
  providedIn: 'root'
})
export class ReminderApiService {

  constructor(private http: HttpClient) {
  }


  public getReminder(ID: number): Observable<ReminderDto> {
    return this.http.get<ReminderDto>(`api/reminder/${ID}`);
  }

  public getPopupReminders(search: string): Observable<ReminderPopupListDto> {
    return this.http.get<ReminderPopupListDto>(`api/reminder/popup`, { params: { search } });
  }

  public readReminder(id: number): Observable<void> {
    return this.http.post<void>(`api/reminder/${id}/read`, {});
  }

  public unreadReminder(id: number): Observable<void> {
    return this.http.post<void>(`api/reminder/${id}/unread`, {});
  }

  public readReminders(ids: number[]): Observable<void> {
    return this.http.post<void>(`api/reminders/read`, ids);
  }

  public searchReminders(search: ReminderSearchDto): Observable<GridDataDto> {
    return this.http.post<GridDataDto>(`api/reminders/search`, search);
  }

  public getReminderGridOptions(): Observable<ReminderGridOptionsDto> {
    return this.http.get<ReminderGridOptionsDto>(`api/reminders/gridoptions`);
  }

}
