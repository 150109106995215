import { inject, Injectable } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { ReminderDto } from '../../../../../models/ts/reminder-dto.model';
import { headerRemindersActions } from '../../../../store/features/header-reminders/header-reminders-actions';
import { HeaderPopupBaseService } from '../header-popup-base-service';
import { ReminderApiService } from '../../../../api/bizzmine/reminder/reminder-api.service';
import { take } from 'rxjs';
import { HeaderNotificationDetailModalComponent } from '../../../components/modals/header/header-notification-detail/header-notification-detail-modal.component';
import { NotificationDetailDto } from '../../../../../models/ts/notification-detail-dto.model';
import { HeaderNotificationDetailModalData } from '../../../components/modals/header/header-notification-detail/header-notification-detail-modal-data';
import { headerNotificationsActions } from '../../../../store/features/header-notifications/header-notifications-actions';

/**
 * Header Reminders Service is used for handling the opening of reminder detail component.
 */

@Injectable({
  providedIn: 'root'
})
export class HeaderRemindersService extends HeaderPopupBaseService {
  protected fetchAction = headerRemindersActions.fetchReminders();
  private reminderApiService = inject(ReminderApiService);
  private dialog = inject(Dialog);

  public openReminder(id: number): void {
    this.reminderApiService.getReminder(id).pipe(take(1)).subscribe({
      next: reminder => {
        const dialogRef = this.dialog.open<ReminderDto>(HeaderNotificationDetailModalComponent, { data: { notificationDetail: <unknown>reminder as NotificationDetailDto } as HeaderNotificationDetailModalData });
        dialogRef.closed.subscribe({
          next: () => {
            this.readReminder(id);
          }
        });
      }
    });
  }

  public readReminder(id: number): void {
    this.store$.dispatch(headerRemindersActions.readReminder({ id }));
  }

  public refreshReminders(): void {
    this.store$.dispatch(headerRemindersActions.fetchReminders());
  }
}
