import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ModalComponent } from 'src/app/shared/components/modals/modal/modal.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { TrnTrainingQueueHistoryDto } from 'src/models/ts/trn-training-queue-history-dto.model';
import { GridModule } from '@progress/kendo-angular-grid';
import { TrainingAppQueueHistoryType } from 'src/models/ts/training-app-queue-history-type.model';
import { TrainingAppSubscriptionSourceType } from 'src/models/ts/training-app-subscription-source-type.model';
import { CommonModule } from '@angular/common';
import { DatetimePipe } from 'src/app/shared/pipes/dates/datetime.pipe';

@Component({
  selector: 'bizz-auditlog-queue-events',
  standalone: true,
  imports: [TranslatePipe, ModalComponent, GridModule, CommonModule, DatetimePipe],
  templateUrl: './auditlog-queue-events.component.html',
  styleUrl: './auditlog-queue-events.component.scss'
})
export class AuditlogQueueEventsComponent {
  public constructor(@Inject(DIALOG_DATA) public data: TrnTrainingQueueHistoryDto[]) {
  }

  protected readonly TrainingAppQueueHistoryType = TrainingAppQueueHistoryType;
  protected readonly TrainingAppSubscriptionSourceType = TrainingAppSubscriptionSourceType;

}
