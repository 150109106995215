import { Component, Input } from '@angular/core';
import { UIMonitorTask } from '../monitor-step/monitor-step.component';
import { NgClass } from '@angular/common';
import { IconComponent } from '../../../ui/icon/icon.component';
import { MonitorStepReasonComponent } from '../classes/monitor-step-reason.component';

@Component({
  selector: 'bizz-monitor-step-read-and-understood',
  standalone: true,
  imports: [
    NgClass,
    IconComponent
  ],
  templateUrl: './monitor-step-read-and-understood.component.html',
  styleUrl: './monitor-step-read-and-understood.component.scss'
})
export class MonitorStepReadAndUnderstoodComponent extends MonitorStepReasonComponent {
  @Input({required: true}) task: UIMonitorTask;
  protected getTextElementName(): string {
    return 'understood-remark-' + this.task.TasksID;
  }
}
