import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../../core/services/auth/auth.service';
import { Claims } from '../../../core/constants/claims';

export const workspaceGuard: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(Router).createUrlTree([inject(AuthService).getAccessTokenClaim(Claims.DefaultWorkspacesID) ? '/workspace/' + inject(AuthService).getAccessTokenClaim(Claims.DefaultWorkspacesID) : '/workspace/user-settings']);
  };

