import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { workspaceSidebarActions } from './workspace-sidebar-actions';
import { catchError, exhaustMap, map } from 'rxjs';
import { WorkspaceApiService } from '../../../api/bizzmine/workspace/workspace-api.service';
import { SidebarApiService } from '../../../api/bizzmine/sidebar/sidebar-api.service';
import { Store } from '@ngrx/store';

@Injectable()
export class WorkspaceSidebarEffects {
  public getSidebar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workspaceSidebarActions.getWorkspaceSidebar),
      exhaustMap(({ workspaceId }) => {
          return this.sidebarApiService.getWorkspaceSidebar(workspaceId).pipe(
            catchError(err => {
              this.store$.dispatch(workspaceSidebarActions.failedGetWorkspaceSidebar({ workspaceId, error: err}));
              throw err;
            }),
            map((result) => ({
              type: workspaceSidebarActions.setWorkspaceSidebar.type,
              workspaceSidebar: result
            }))
          );
        }
      )
    )
  );

  public fetchFallBackSidebar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workspaceSidebarActions.failedGetWorkspaceSidebar),
      exhaustMap(() => {
        return this.workspaceApiService.getWorkspaces()
        }
      ),
      map(workspaces => {
        return workspaceSidebarActions.getWorkspaceSidebar({ workspaceId: workspaces.Workspaces[0].ID });
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private workspaceApiService: WorkspaceApiService,
    private sidebarApiService: SidebarApiService,
    private store$: Store
  ) {
  }
}
