import { FlowStatusDto } from '../../../../models/ts/flow-status-dto.model';
import { EntityState } from '@ngrx/entity';

export type FlowStatusState = EntityState<StoreFlowStatus>;

export class StoreFlowStatus {
  public id: string;

  public constructor(public data: FlowStatusDto) {
    this.id = crypto.randomUUID();
  }
}