import { Component, Inject } from '@angular/core';
import { AuditLogItem } from '../../interfaces/auditlog.interface';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { ModalComponent } from 'src/app/shared/components/modals/modal/modal.component';
import { PermissionHistoryGridComponent } from '../permission-history-grid/permission-history-grid.component';
import { AuditLogItemType } from 'src/models/ts/audit-log-item-type.model';

@Component({
  selector: 'bizz-auditlog-permission-history',
  standalone: true,
  imports: [TranslatePipe, ModalComponent, PermissionHistoryGridComponent],
  templateUrl: './auditlog-permission-history.component.html',
  styleUrl: './auditlog-permission-history.component.scss'
})
export class AuditlogPermissionHistoryComponent {
  public itemType = AuditLogItemType.Instance;
  
  public constructor(@Inject(DIALOG_DATA) public data:{
     auditLogItem: AuditLogItem,
    }) {
  }

}
