import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { CollectionListDataInstance } from '../../../../interfaces/collection-list-data-instance';
import { CollectionListApiService } from '../../../../../api/bizzmine/collection-list/collection-list-api.service';
import { Store } from '@ngrx/store';
import { refreshActions } from 'src/app/store/features/refresh/refresh-actions';

/**
 * Modal that asks for reason and gives you the option to restore an instance.
 */
@Component({
  selector: 'bizz-restore-instance-modal',
  standalone: true,
  imports: [CommonModule, IconComponent, TextAreaModule, TranslatePipe],
  templateUrl: './restore-instance-modal.component.html',
  styleUrls: ['./restore-instance-modal.component.scss'],
})
export class RestoreInstanceModalComponent {
  protected restoreReason = '';
  protected dataItem: CollectionListDataInstance;

  public constructor(public dialogRef: DialogRef<HttpErrorResponse>,
              @Inject(DIALOG_DATA) public model: CollectionListDataInstance,
              private collectionListService: CollectionListApiService,
              private store$: Store) {
    this.dataItem = model;
    this.dialogRef.disableClose = true;
  }

  protected restoreInstance(): void {
    this.collectionListService.restoreInstance(this.dataItem.CollectionsID!, this.dataItem.ID, this.dataItem.VersionsID,
      this.restoreReason)
      .subscribe({
      next: () => {
        this.store$.dispatch(refreshActions.refreshData({ collectionId: this.dataItem.CollectionsID! }));
        this.dialogRef.close();
      }, error: (value: HttpErrorResponse | undefined) => {
        this.dialogRef.close(value);
      },
    });
  }
}
