import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from 'safe-pipe';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { DatetimePipe } from '../../../../pipes/dates/datetime.pipe';
import { TimePipe } from '../../../../pipes/dates/time.pipe';
import { GridColumnType } from 'src/models/ts/grid-column-type.model';
import { DatePipe } from 'src/app/shared/pipes/dates/date.pipe';

/**
 * Cell for date,datetime or time data.
 */
@Component({
  selector: 'bizz-grid-cell-date',
  standalone: true,
  templateUrl: './grid-cell-date.component.html',
  styleUrls: ['./grid-cell-date.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SafePipe, DatetimePipe, TimePipe, DatePipe]
})
export class GridCellDateComponent extends GridCellBaseComponent<string> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public readonly GridColumnType = GridColumnType

  public format(): string | undefined{
    if(this.value == undefined) return undefined;
    if(typeof this.value === 'string') return this.value;
    return new Date(this.value).toISOString();
  }
}
