import { createActionGroup, props } from '@ngrx/store';
import { WorkspaceSidebarState } from './workspace-sidebar-state';

export const workspaceSidebarActions = createActionGroup({
  source: 'Workspace',
  events: {
    getWorkspaceSidebar: props<{ workspaceId: number }>(),
    failedGetWorkspaceSidebar: props<{ workspaceId: number , error: any }>(),
    setWorkspaceSidebar: props<{ workspaceSidebar: WorkspaceSidebarState }>(),
  },
});
