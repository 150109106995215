@if (showLoader) {
    <div class="absolute top-0 left-0 h-full w-full max-w-full z-10">
        <div class="relative h-full w-full max-w-full ">
            @switch (loaderType) {
                @case (ViewStackLoaderType.DEFAULT) {
                    <bizz-view-stack-loader></bizz-view-stack-loader>
                }
                @case (ViewStackLoaderType.SKELETON) {
                    <bizz-view-stack-skeleton-loader></bizz-view-stack-skeleton-loader>
                }
            }

        </div>
    </div>
}
@for (item of viewStack; track $index) {
    <!--    [ngStyle]="{'z-index' : 10 + $index }-->
    <div class="absolute top-0 left-0 h-full w-full max-w-full z-10"
         [ngClass]="{'hidden': (($index < (viewStack.length - 1)) || showLoader) }">
        <div class="relative h-full w-full max-w-full ">
            <ng-template viewContainerRef>
            </ng-template>
        </div>
    </div>
}




