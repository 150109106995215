import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const appActions = createActionGroup({
  source: 'App',
  events: {
    translationsLoaded: emptyProps(),
    translationsLoading: emptyProps(),
    themeLoading: emptyProps(),
    themeLoaded: emptyProps(),
    userSettingsLoading: emptyProps(),
    userSettingsLoaded: emptyProps(),
  }
});