<bizz-modal modalSize="xs" title="DuplicateExam"
    [contentTemplate]="activeContentTemplateRef" [footerTemplate]="footerTemplateRef"></bizz-modal>

<ng-template #activeContentTemplateRef>
    <span class="mb-2 text-default">{{'NewExamName' | translate}}:</span>
    <kendo-textbox (valueChange)="onValueChange($event)">{{examName}}</kendo-textbox>
</ng-template>

<ng-template #footerTemplateRef>
    <div class="modal-buttons">
        <button class="default" (click)="dialogRef.close()"> <span>{{ 'Cancel' | translate }}</span></button>
        <bizz-loader-button buttonClass="save" [disabled]="!examName" (click)="save()" [isLoading]="isLoading">
            {{'Save' | translate}}
        </bizz-loader-button>
    </div>
</ng-template>