import { Component, DestroyRef, Inject } from '@angular/core';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { OrgChartComponent } from '../../../org-chart/org-chart.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import { BehaviorSubject, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { JsonPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { TaskApiService } from '../../../../../api/bizzmine/task/task-api.service';
import { userSettingsFeature } from '../../../../../store/features/user-settings/user-settings-feature';
import { OrgChartSelectComponent } from '../../../org-chart/org-chart-select/org-chart-select.component';
import { OrgChartItem } from '../../../org-chart/interfaces/org-chart-item';
import { OrganizationChartItemType } from 'src/models/ts/organization-chart-item-type.model';
import {TextAreaModule} from "@progress/kendo-angular-inputs";

@Component({
  selector: 'bizz-task-forward-dialog',
  standalone: true,
    imports: [
        IconComponent,
        TranslatePipe,
        OrgChartComponent,
        EditorModule,
        JsonPipe,
        OrgChartSelectComponent,
        TextAreaModule
    ],
  templateUrl: './task-forward-dialog.component.html',
  styleUrl: './task-forward-dialog.component.scss'
})
export class TaskForwardDialogComponent {
  public excludedUserIds: Array<number> = [this.store$.selectSignal(userSettingsFeature.selectUserID)()];
  public constructor(private dialogRef: DialogRef<TaskForwardDialogComponent>, private destroyRef: DestroyRef,
                     @Inject(DIALOG_DATA) public data: {taskId: number, collectionId: number},
                     private dialog: Dialog,
                     private store$: Store,
                     private taskApiService: TaskApiService) {
    this.selectedOrgChartItems$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((items) => {
      if(items.length > 0) {
      this.selectedOrgChatItem = items[0];
      }
      else {
        this.selectedOrgChatItem = null;
      }
      this.validate();
    });
  }
  public selectedOrgChatItem: OrgChartItem | null = null;

  public selectedOrgChartItems$ = new BehaviorSubject<Array<OrgChartItem>>([]);
  public forwardingReason: string = '';
  public close(): void {
    this.dialogRef.close();
  }

  public loading = false;
  public isValid = false;
  protected OrganizationChartItemType = OrganizationChartItemType;

  public validate() : boolean {
    this.isValid = this.selectedOrgChatItem != null && this.forwardingReason.trim().length > 0;
    return this.isValid;
  }
  public forwardTask(): void {
    if(!this.validate()) {
      return;
    }

    this.taskApiService.forwardTask({
      task: {
        TasksID: this.data.taskId,
        CurrentUsersID: this.store$.selectSignal(userSettingsFeature.selectUserID)(),
        Reason: this.forwardingReason,
        SelectedOrganizationChartItems: [this.selectedOrgChatItem!] // TODO -> add orgChartItem
      }
    }).pipe(take(1)).subscribe(() => {
    this.close();
    });

  }

}
