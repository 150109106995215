import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { CronGenTimeSelectComponent, CronGenTimeType } from '../cron-gen-time-select/cron-gen-time-select.component';
import { CronGenService } from 'src/app/features/bizzmine/form/services/cron-gen.service';
import { CronGenWeekly } from 'src/app/features/bizzmine/form/interfaces/crongen/cron-gen-weekly';

@Component({
  selector: 'bizz-cron-weekly',
  standalone: true,
  imports: [
    TranslatePipe,
    DropDownsModule,
    CronGenTimeSelectComponent
  ],
  templateUrl: './cron-weekly.component.html',
  styleUrl: './cron-weekly.component.scss',
})
export class CronWeeklyComponent {
  @Input() public readOnly = false;

  @Input() public state: CronGenWeekly;

  public dayLookups: any = {
    'SUN': 'Sunday',
    'MON': 'Monday',
    'TUE': 'Tuesday',
    'WED': 'Wednesday',
    'THU': 'Thursday',
    'FRI': 'Friday',
    'SAT': 'Saturday'
  };
  @Output() public stateChange: EventEmitter<CronGenWeekly> = new EventEmitter<CronGenWeekly>();

  public constructor(public cronGenService: CronGenService) {
  }

  public toggleDay(day: keyof CronGenWeekly) {
    this.state[day] = !this.state[day] as never;
    this.stateChange.emit(this.state);
  }

  public cronGenTimeChange(state: CronGenTimeType) {
    Object.assign(this.state, state);
    this.stateChange.emit(this.state);
  }
}
