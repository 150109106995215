import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../../../ui/icon/icon.component';

@Component({
  selector: 'bizz-archive-item-version',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './archive-item-version.component.html',
  styleUrl: './archive-item-version.component.scss'
})
export class ArchiveItemVersionComponent {
  @Input({ required: true }) public content: string;
  @Input() public clsName: string;
}
