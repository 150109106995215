import { createReducer, on } from '@ngrx/store';
import { initialHeaderTasksStoreState } from './header-tasks-state';
import { headerTasksActions } from './header-tasks-actions';

export const headerTasksReducer = createReducer(
  initialHeaderTasksStoreState,
  on(headerTasksActions.fetchTasks, headerTasksActions.updateSearch, (state) => {
    if (!state.loading) {
      const updatedState = structuredClone(state);
      updatedState.loading = true;
      return updatedState;
    }
    return state;
  }),
  on(headerTasksActions.tasksFetched, (state, { tasks }) => {
    const updatedState = structuredClone(state);
    updatedState.tasks = tasks.Tasks;
    updatedState.count = tasks.NumberOfTasks;
    updatedState.loading = false;
    return updatedState;
  }),
  on(headerTasksActions.updateSearch, (state, { search }) => {
    const updatedState = structuredClone(state);
    updatedState.search = search;
    return updatedState;
  })
);
