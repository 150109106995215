import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { appFeature } from './app-feature';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app-effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(appFeature),
    EffectsModule.forFeature(AppEffects)
  ]
})
export class AppFeatureStoreModule {
}